import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import readFileDialog from "./read-file-dialog";
import "./read-file.css";
import Loader from "../../app-components/loader";
import ApprovalRoleFilter from "../../app-containers/context-providers/approval-role-filter";
import ReadFileItem from "./read-file-item";
import RoleFilter from "../../app-containers/context-providers/role-filter";
import { sortBy } from "lodash";
import DisplayOnlyDialog from "./display-only-dialog";
import PilotRoleFilter from "../../app-containers/context-providers/pilot-role-filter";
import PlatformOrgFilter from "../../app-containers/context-providers/platform-org-filter";

const ReadFile = ({
  doDialogOpen,
  readFileItems,
  doReadFileFetch,
  doReadFileFetchAll,
  readFileIsLoading,
  tokenRolesJoined,
  readFileItemsParsed,
  doPlatformsFetchByOrgAndProfile,
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    doReadFileFetch();
    doReadFileFetchAll();
    doPlatformsFetchByOrgAndProfile();
  }, []);
  let userIsHqAdmin = tokenRolesJoined.includes("HQ.ADMIN");

  let items = userIsHqAdmin ? readFileItems : readFileItemsParsed;
  items = sortBy(items, (r) => r.create_date).reverse();
  let incomplete = items.filter((i) => !i.keycloak_id);
  let complete = items.filter((i) => !!i.keycloak_id);

  const _renderPlatformOrgFilter = (item, idx) => {
    return (
      <PlatformOrgFilter
        allowPlatforms={
          item.allow_aircraft ? JSON.parse(item.allow_aircraft) : ["*"]
        }
        key={idx}>
        {!item.allow_aircraft ||
        !!item.allow_roles ||
        !!item.allow_flight_roles ? (
          _renderApprovalRoleFilter(item, idx)
        ) : (
          <ReadFileItem
            item={item}
            key={idx}
            onView={() => {
              doDialogOpen({
                content: DisplayOnlyDialog,
                props: { data: { ...item } },
                size: "lg",
              });
            }}
            onEdit={() => {
              doDialogOpen({
                content: readFileDialog,
                props: { data: { ...item } },
                size: "lg",
              });
            }}
          />
        )}
      </PlatformOrgFilter>
    );
  };

  const _renderPilotRoleFilter = (item, idx) => {
    return (
      <PilotRoleFilter
        allowRoles={
          item.allow_flight_roles ? JSON.parse(item.allow_flight_roles) : ["*"]
        }>
        <ReadFileItem
          item={item}
          key={idx}
          onView={() => {
            doDialogOpen({
              content: DisplayOnlyDialog,
              props: { data: { ...item } },
              size: "lg",
            });
          }}
          onEdit={() => {
            doDialogOpen({
              content: readFileDialog,
              props: { data: { ...item } },
              size: "lg",
            });
          }}
        />
      </PilotRoleFilter>
    );
  };

  const _renderApprovalRoleFilter = (item, idx) => {
    return (
      <ApprovalRoleFilter
        allowRoles={item.allow_roles ? JSON.parse(item.allow_roles) : ["*"]}
        alt={
          item.allow_flight_roles
            ? () => _renderPilotRoleFilter(item, idx)
            : null
        }
        key={item.title}
        useProfile>
        {!item.allow_roles ? (
          _renderPilotRoleFilter(item, idx)
        ) : (
          <ReadFileItem
            item={item}
            key={idx}
            onView={() => {
              doDialogOpen({
                content: DisplayOnlyDialog,
                props: { data: { ...item } },
                size: "lg",
              });
            }}
            onEdit={() => {
              doDialogOpen({
                content: readFileDialog,
                props: { data: { ...item } },
                size: "lg",
              });
            }}
          />
        )}
      </ApprovalRoleFilter>
    );
  };

  return (
    <div
      className="d-flex flex-column w-100 align-items-center container"
      style={{ marginTop: 64 }}>
      <div className="d-flex justify-content-between w-100 py-3">
        <h3 className="m-0">
          <i className="mdi mdi-book mdi-32px mr-2" />
          Read File
        </h3>
      </div>
      <RoleFilter allowRoles={["HQ.ADMIN"]}>
        <ReadFileItem
          newItem
          onAdd={() => {
            doDialogOpen({
              content: readFileDialog,
              props: {},
              size: "lg",
            });
          }}
        />
      </RoleFilter>
      {readFileIsLoading && (
        <div className="d-flex flex-column w-100 align-items-center">
          <Loader />
        </div>
      )}
      {!items.length && !readFileIsLoading && (
        <div className="d-flex flex-column align-items-center">
          <i className="mdi mdi-information-outline mdi-48px" />
          <p style={{ fontSize: 32 }}>No Read Files Uploaded!</p>
        </div>
      )}
      {/*
      IMPORTANT LOGIC TO NOTE: Every Read file item has to go through a check based on the target groups. 
      1. first check if there are aircraft limitations.
        a. if a user is in an org that has that aircraft, then we move on to the next filter check. if not in an org with that aircraft , render nothing. (no alt)
        b. We also check the next filter if no aircraft limits are applied. Thats why we have the condition in the child as well as the alt prop.
      2. Repeat process for Approval ROle Filter. We do have an ALt here, as this read file could target both flight roles and approval roles within that org. 
      3. Final filter does its checks, if no target group / roles pass then render ReadFileItem.
      */}
      {!readFileIsLoading && incomplete.map(_renderPlatformOrgFilter)}

      <button
        className="btn btn-ghost w-100 d-flex justify-content-start align-items-center mb-4 border-bottom"
        style={{ boxShadow: "none" }}
        type="button"
        aria-expanded={show}
        onClick={() => setShow(!show)}>
        <i
          className={`mdi ${
            show ? "mdi-chevron-up" : "mdi-chevron-down"
          } mdi-24px mr-2 `}
        />{" "}
        <b className="mr-2">Completed</b> ({complete.length})
      </button>
      <div
        className={show ? "collapse show w-100 pb-3" : "collapse w-100 pb-3"}
        id="collapseExample">
        {complete.map((item, idx) => (
          <ReadFileItem
            item={item}
            key={idx}
            onView={() => {
              doDialogOpen({
                content: DisplayOnlyDialog,
                props: { data: { ...item } },
                size: "lg",
              });
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default connect(
  "doDialogOpen",
  "selectReadFileItems",
  "selectReadFileIsLoading",
  "doReadFileFetch",
  "doReadFileFetchAll",
  "selectTokenRolesJoined",
  "selectReadFileItemsParsed",
  "doPlatformsFetchByOrgAndProfile",
  "selectPlatformsByOrgProfile",
  ReadFile
);
