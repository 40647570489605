import { createSelector } from "redux-bundler";

export default {
  name: "transfersStatus",

  getReducer: () => {
    const initialData = {};

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  selectTransferStatusBadgeCount: createSelector(
    "selectOrgsByRoute",
    "selectTransfersItems",
    (org, transfers) => {
      let cnt = 0;
      if (!org) return 0;
      if (org.name === "HQ") {
        transfers.forEach((t) => {
          if (t.to_status === "Complete" && t.hq_status === "Pending") cnt++;
        });
      } else {
        transfers.forEach((t) => {
          if (t.from_status === "Complete" && t.to_status === "Pending") cnt++;
        });
      }
      return cnt;
    }
  ),

  selectTransferStatusItems: createSelector(
    "selectOrgsByRoute",
    "selectTransfersItems",
    (org, transfers) => {
      if (!org) return [];
      if (org.name === "HQ") {
        return transfers.filter(
          (t) => t.to_status === "Complete" && t.hq_status === "Pending"
        );
      } else {
        return transfers.filter(
          (t) => t.from_status === "Complete" && t.to_status === "Pending"
        );
      }
    }
  ),

  selectTransfersStatusStep: createSelector(
    "selectTransfersByRoute",
    (transfer) => {
      if (!transfer) return null;
      if (transfer.from_status === "In-Progress") {
        // if the from-status is in progress then the sender status has to be pending
        return 1;
      } else if (transfer.from_status === "Complete") {
        if (transfer.to_status === "Pending") {
          return 2;
        } else if (transfer.to_status === "In-Progress") {
          return 3;
        } else if (transfer.to_status === "Complete") {
          if (transfer.hq_status === "Pending") {
            return 4;
          } else {
            return 5;
          }
        }
      } else {
        // something is weird with the data...
        return 0;
      }

      return transfer;
    }
  ),
};
