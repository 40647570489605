import React from "react";
import { connect } from "redux-bundler-react";
import Grid from "../_shared/grid";
import Loader from "../../app-components/loader";
import { capitalize } from "lodash";
const ApprovedComponentsCard = ({
  aircraftConfigurationComponentByRoute: componentByRoute,
  componentTypeToList,
  orgsByRoute,
  aircraftConfigurationIsLoading: _isLoading,
  aircraftConfigurationIsSaving: _isSaving,
  aircraftConfigurationItemsByComponentRoute: approvedConfigurations,
}) => {
  const listedKeyPrefix = `${
    componentTypeToList !== "aircraft" ? componentTypeToList + "_" : ""
  }`;
  const config = {
    domLayout: "autoHeight",
    tooltipShowDelay: 0,
    suppressScrollOnNewData: true,
    defaultColDef: { resizable: true },
    columnDefs: [
      {
        headerName: "Make",
        field: [`${listedKeyPrefix}make`],
      },
      {
        headerName: "Model",
        field: [`${listedKeyPrefix}model`],
        cellRenderer: (params) => {
          return (
            <a
              href={`/${orgsByRoute.slug}/platforms/${
                params.data[`${listedKeyPrefix}slug`]
              }`}
            >
              {params.data[`${listedKeyPrefix}model`]}
            </a>
          );
        },
      },
    ],
  };
  const renderCardBody = () => {
    const { componentType } = componentByRoute;
    let configurations =
      approvedConfigurations &&
      approvedConfigurations.length > 0 &&
      approvedConfigurations.filter(
        (item) => !!item[`${componentTypeToList}_id`]
      );
    if (_isLoading || _isSaving) return <Loader />;
    else if (configurations && configurations.length > 0) {
      return (
        <>
          <small className="text-muted">
            This {componentType} is approved for the following{" "}
            {componentTypeToList}s...
          </small>
          <Grid
            config={config}
            data={configurations}
            style={{ width: "100%" }}
          />
        </>
      );
    } else {
      return (
        <div className="list-group">
          <div className="list-group-item">
            No Approved {capitalize(componentTypeToList)}
            {componentTypeToList !== "aircraft" && "s"}...
          </div>
        </div>
      );
    }
  };
  return (
    <div className="card approved-components-card">
      <div className="card-header">
        <i className="mdi mdi-airplane" />{" "}
        <span>Approved {capitalize(componentTypeToList)}</span>
      </div>
      <div className="card-body">{renderCardBody()}</div>
    </div>
  );
};
export default connect(
  "selectAircraftConfigurationComponentByRoute",
  "selectAircraftConfigurationItemsByComponentRoute",
  "selectAircraftConfigurationIsSaving",
  "selectAircraftConfigurationIsLoading",
  "selectOrgsByRoute",
  ApprovedComponentsCard
);
