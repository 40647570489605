import React from "react";
import { connect } from "redux-bundler-react";

export default connect(
  "selectMissionsByRoute",
  "selectOrgsByRoute",
  "doMissionsSave",
  "doUpdateRelativeUrl",
  ({
    missionsByRoute: mission,
    orgsByRoute: org,
    doMissionsSave,
    doUpdateRelativeUrl,
  }) => {
    const archiveMission = () => {
      if (
        window.confirm("Are you sure you would like to archive this mission?")
      ) {
        mission.archived = 1;
        doMissionsSave(mission);
        doUpdateRelativeUrl(`/${org.slug}/archive`);
      }
    };

    const restoreMission = () => {
      mission.archived = 0;
      doMissionsSave(mission);
      doUpdateRelativeUrl(`/${org.slug}/archive`);
    };

    return (
      <span className="pl-3">
        {mission.archived === 0 ? (
          <button className="btn btn-sm btn-warning" onClick={archiveMission}>
            Archive Mission
          </button>
        ) : (
          <button className="btn btn-sm btn-secondary" onClick={restoreMission}>
            Restore Mission
          </button>
        )}
      </span>
    );
  }
);
