import { sortBy } from "lodash";
import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import ApprovalRoleFilter from "../../../app-containers/context-providers/approval-role-filter";
import classnames from "../../../utils/classnames";
import atpmPersonnelModal from "../atpm-personnel-modal";
import editPersonnelModal from "../edit-personnel-modal";
import LoadingList from "./loading-list";

const _Date = (date) => new Date(date.replace(/-/g, "/").replace(/T.+/, ""));
const _nDaysAway = (n) =>
  new Date(new Date().setDate(new Date().getDate() + n));

const roleColor = {
  RP: "indigo",
  RPI: "teal",
  SRP: "cyan",
};

const modeIcon = {
  Day: "mdi-weather-sunny",
  "Night*": "mdi-weather-night",
  "BVLOS*": "mdi-binoculars",
  "Single RPI Operates Multiple UAS*": "mdi-airplane",
};
const modeColor = {
  Day: "orange",
  "Night*": "blue",
  "BVLOS*": "green",
  "Single RPI Operates Multiple UAS*": "red",
};

const CurrencyCard = ({
  atpmDashboardItemsFull: data,
  doProfileActiveFetch,
  doDialogOpen,
  isLoading,
  domainsItemsByGroup,
  profileId,
  activeAtpm,
  atpmDashboardIsFetching,
  doReadFileTrackingFetch,
  doReadFileTableFetch,
}) => {
  let flightModes = domainsItemsByGroup["flight_mode"].filter((obj) =>
    ["Day", "Night*", "BVLOS*", "Single RPI Operates Multiple UAS*"].includes(
      obj.val
    )
  );

  useEffect(() => {
    doReadFileTableFetch();
    doReadFileTrackingFetch();
  }, []);

  const alertClass = (red, yellow, green) => {
    return classnames({
      mdi: true,
      "mdi-clipboard": !red && !yellow && !green,
      "mdi-clipboard-alert": red || yellow,
      "mdi-clipboard-check": green,
      "text-success": green,
      "text-warning": yellow,
      "text-danger": red,
      "text-secondary": !red && !yellow && !green,
      "mdi-18px": true,
    });
  };

  const rpLastNinety = (item) => {
    const { rp_last_ninety, most_recent_rp, atpm_sig, suac_sig } = item;
    if (!atpm_sig && !suac_sig) return alertClass(false, false, false);
    let red = parseInt(rp_last_ninety) === 0;
    let expires;
    if (most_recent_rp) {
      expires = new Date(
        _Date(most_recent_rp).setDate(_Date(most_recent_rp).getDate() + 90)
      );
    }
    let yellow = most_recent_rp ? _nDaysAway(30) >= expires : false;
    let green = !red && !yellow;
    return alertClass(red, yellow, green);
  };

  const voLast180 = (item) => {
    const { vo_last_oneeighty, most_recent_vo, atpm_sig, suac_sig } = item;
    if (!atpm_sig && !suac_sig) return alertClass(false, false, false);
    let red = parseInt(vo_last_oneeighty) === 0;
    let expires;
    if (most_recent_vo) {
      expires = new Date(
        _Date(most_recent_vo).setDate(_Date(most_recent_vo).getDate() + 180)
      );
    }
    let yellow = !red && most_recent_vo ? _nDaysAway(30) >= expires : false;
    let green = !red && !yellow;
    return alertClass(red, yellow, green);
  };

  const voSemiClass = ({
    vo_first_semi_yr,
    vo_second_semi_yr,
    atpm_sig,
    suac_sig,
    first_semi_end,
    second_semi_end,
    first_semi_start,
    req_flights_second_vo,
    req_flights_first_vo,
    vo_last_oneeighty,
    ...t
  }) => {
    if (!atpm_sig && !suac_sig) return alertClass(false, false, false);
    let currentSemiYear =
      new Date() >= new Date(first_semi_start) &&
      new Date() <= new Date(first_semi_end)
        ? "first"
        : "second";
    let red = !atpm_sig || parseInt(vo_last_oneeighty) === 0;
    let yellow = red
      ? false
      : currentSemiYear === "first"
      ? parseInt(req_flights_first_vo) > parseInt(vo_first_semi_yr)
      : parseInt(req_flights_second_vo) > parseInt(vo_first_semi_yr);
    yellow =
      yellow &&
      (currentSemiYear === "first"
        ? _nDaysAway(30) >= _Date(first_semi_end)
        : _nDaysAway(30) >= _Date(second_semi_end));
    let green =
      red || yellow
        ? false
        : currentSemiYear === "first"
        ? parseInt(req_flights_first_vo) <= parseInt(vo_first_semi_yr) ||
          _nDaysAway(30) < _Date(first_semi_end)
        : parseInt(req_flights_second_vo) <= parseInt(vo_second_semi_yr) ||
          _nDaysAway(30) < _Date(second_semi_end);
    return alertClass(red, yellow, green);
  };

  const rpSemiClass = ({
    rp_first_semi_yr,
    rp_second_semi_yr,
    atpm_sig,
    suac_sig,
    first_semi_end,
    second_semi_end,
    first_semi_start,
    req_flights_second_rp,
    req_flights_first_rp,
    rp_last_ninety,
    ...t
  }) => {
    if (!atpm_sig && !suac_sig) return alertClass(false, false, false);
    let currentSemiYear =
      new Date() >= new Date(first_semi_start) &&
      new Date() <= new Date(first_semi_end)
        ? "first"
        : "second";
    // RED if no signature.. which wont happen because this will be gray.
    let red = !atpm_sig;
    let yellow = red
      ? false
      : currentSemiYear === "first"
      ? parseInt(req_flights_first_rp) > parseInt(rp_first_semi_yr)
      : parseInt(req_flights_second_rp) > parseInt(rp_second_semi_yr);
    yellow =
      yellow &&
      (currentSemiYear === "first"
        ? _nDaysAway(30) >= _Date(first_semi_end)
        : _nDaysAway(30) >= _Date(second_semi_end));
    let green =
      red || yellow
        ? false
        : currentSemiYear === "first"
        ? parseInt(req_flights_first_rp) <= parseInt(rp_first_semi_yr) ||
          _nDaysAway(30) < _Date(first_semi_end)
        : parseInt(req_flights_second_rp) <= parseInt(rp_second_semi_yr) ||
          _nDaysAway(30) < _Date(second_semi_end);
    return alertClass(red, yellow, green);
  };

  const sqcClass = (item) => {
    const { sqc_date, sqc_atpm_sig, sqc_suac_sig } = item;
    return classnames({
      mdi: true,
      "mdi-clipboard-alert":
        !sqc_date || (sqc_date && (!sqc_atpm_sig || !sqc_suac_sig)),
      "mdi-clipboard-check": sqc_date && sqc_atpm_sig && sqc_suac_sig,
      "text-success": sqc_date && sqc_atpm_sig && sqc_suac_sig,
      "text-warning": sqc_date && (!sqc_atpm_sig || !sqc_suac_sig),
      "text-danger": !sqc_date,
      "mdi-18px": true,
    });
  };

  const readFileClass = (count) => {
    return classnames({
      mdi: true,
      "mdi-book": true,
      "text-success": count === 0,
      "text-danger": count > 0,
      "mdi-18px": true,
    });
  };

  const healthAssessmentClass = (item) => {
    const {
      health_assessment_atp_year,
      health_assessment_routing_order,
      atp_from,
      atp_to,
      atp_month,
    } = item;

    let expiration_date = new Date(`${atp_month}/01/${atp_to}`);
    let is_partial_signed =
      typeof health_assessment_routing_order === "number" &&
      health_assessment_routing_order === 0;
    let is_fully_signed =
      typeof health_assessment_routing_order === "number" &&
      health_assessment_routing_order === 1;
    let expires_soon = _nDaysAway(60) > expiration_date;
    let expired = new Date() > expiration_date;

    let success = is_fully_signed && !expires_soon && !expired;
    let warning = is_partial_signed || expires_soon;
    let danger = !health_assessment_atp_year || expired;

    return classnames({
      mdi: true,
      "mdi-account-box": true,
      "mdi-18px": true,
      "text-success": success && !danger && !warning,
      "text-warning": !success && !danger && warning,
      "text-danger": !success && danger && !warning,
    });
  };
  let sortedData = sortBy(data, "name");

  return (
    <div className="currency">
      <div className="custom-card-title">
        <h5 className="text-black m-0">Crewmember Status</h5>
        <ApprovalRoleFilter allowRoles={["ATPM"]}>
          {activeAtpm.value === profileId && (
            <button
              className="btn btn-sm btn-ghost-dark"
              onClick={() => {
                doDialogOpen({
                  content: atpmPersonnelModal,
                  props: {},
                });
              }}
            >
              <i className="mdi mdi-plus-circle icon-inline mdi-18px"></i>
              Add New
            </button>
          )}
        </ApprovalRoleFilter>
      </div>
      <div className="currency-body">
        {isLoading || atpmDashboardIsFetching ? (
          <div className="mt-2">
            <LoadingList />
          </div>
        ) : data.length === 0 || !data ? (
          <div className="d-flex flex-column h-100 align-items-center justify-content-center ">
            <p className="text-muted h5 text-center">
              You currently are not managing any crewmembers. Click 'Add New' to
              add them to your roster!
            </p>
          </div>
        ) : (
          <div className="d-grid">
            <div className="border-bottom list-item">
              <p
                className="m-0 d-flex align-items-center pl-1"
                style={{ color: "#808488" }}
              >
                Name
              </p>
              <div className="d-flex flex-column">
                <p
                  className="m-0 d-flex justify-content-center w-100 border-bottom"
                  style={{ color: "#808488" }}
                >
                  <b>RP</b>
                </p>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: 8,
                  }}
                >
                  <div
                    className="m-0 d-flex justify-content-center"
                    style={{ color: "#808488" }}
                  >
                    90
                  </div>
                  <div
                    className="m-0 d-flex justify-content-center"
                    style={{ color: "#808488" }}
                  >
                    Semi
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <p
                  className="m-0 d-flex justify-content-center w-100 border-bottom"
                  style={{ color: "#808488" }}
                >
                  <b> VO</b>
                </p>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: 8,
                  }}
                >
                  <div
                    className="d-flex justify-content-center"
                    style={{ color: "#808488" }}
                  >
                    180
                  </div>
                  <div
                    className="d-flex justify-content-center"
                    style={{ color: "#808488" }}
                  >
                    Semi
                  </div>
                </div>
              </div>
              <div
                className="m-0 d-flex align-items-center justify-content-center"
                style={{ color: "#808488" }}
              >
                <b>SQC</b>
              </div>
              <div
                className="m-0 d-flex align-items-center justify-content-center"
                style={{ color: "#808488" }}
              >
                <div className="d-flex flex-column align-items-center justify-content-center text-align-center">
                  <b>Read</b>
                  <b>File</b>
                </div>
              </div>
              <div
                className="m-0 d-flex align-items-center justify-content-center"
                style={{ color: "#808488" }}
              >
                <div className="d-flex flex-column align-items-center justify-content-center text-align-center">
                  <b>Health</b>
                  <b>Assess</b>
                </div>
              </div>
            </div>

            {sortedData &&
              sortedData.map((person) => {
                let userRoles = !person.atpm_sig
                  ? []
                  : person.duties
                  ? domainsItemsByGroup.duties
                      .filter((d) => person.duties.split(".").includes(d.id))
                      .map((o) => o.val)
                  : [];
                let userModes = !person.atpm_sig
                  ? []
                  : person.flight_mode
                  ? flightModes
                      .filter((d) =>
                        person.flight_mode.split(".").includes(d.id)
                      )
                      .map((o) => o.val)
                  : [];
                return (
                  <div
                    className="border-bottom list-item"
                    key={person.name}
                    onClick={() =>
                      doProfileActiveFetch(person.profile_id, () =>
                        doDialogOpen({
                          content: editPersonnelModal,
                          props: { currency: person },
                          size: "xl",
                        })
                      )
                    }
                  >
                    <div className="d-flex justify-content-start">
                      <div className="d-flex flex-column">
                        <div className="d-flex mb-1">
                          <p className="m-0 mr-1 name">{person.name}</p>{" "}
                          {person.backup_atpm_id === profileId && (
                            <i className="mdi mdi-account-switch icon-inline mdi-12px text-info ml-1"></i>
                          )}
                        </div>
                        <div className="d-flex roles">
                          {userRoles.length === 0 && (
                            <p className="m-0 text-muted">
                              <small>No roles for this user</small>
                            </p>
                          )}
                          {userRoles.map((role) => (
                            <span
                              key={role}
                              className={`badge badge-pill badge-${roleColor[role]} mr-1 mb-auto`}
                              title={role}
                            >
                              {role}
                            </span>
                          ))}
                        </div>
                        <div className="d-flex roles mt-1 mb-1">
                          {userModes.length === 0 && (
                            <p className="m-0 text-muted">
                              <small>No flight modes for this user</small>
                            </p>
                          )}
                          {userModes.map((mode) => (
                            <i
                              className={`mdi mdi-18px ${modeIcon[mode]} mr-1`}
                              title={mode}
                              style={{ color: modeColor[mode] }}
                              key={mode}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: 8,
                      }}
                    >
                      <div className="d-flex justify-content-center">
                        <i className={rpLastNinety(person)} />
                      </div>
                      <div className="d-flex justify-content-center">
                        <i className={rpSemiClass(person)} />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: 8,
                      }}
                    >
                      <div className="d-flex justify-content-center">
                        <i className={voLast180(person)} />
                      </div>
                      <div className="d-flex justify-content-center">
                        <i className={voSemiClass(person)} />
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <i className={sqcClass(person)} />
                    </div>
                    <div className="d-flex justify-content-center">
                      <i className={readFileClass(person.read_file_count)} />
                    </div>
                    <div className="d-flex justify-content-center">
                      <i className={healthAssessmentClass(person)} />
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(
  "selectAtpmDashboardItemsFull",
  "doProfileActiveFetch",
  "doDialogOpen",
  "selectAtpmDashboardIsFetching",
  "selectDomainsItemsByGroup",
  "selectProfileId",
  "selectActiveAtpm",
  "doReadFileTrackingFetch",
  "doReadFileTableFetch",
  CurrencyCard
);
