import React from "react";
import DatePicker from "react-datepicker";
import { isEmpty } from "lodash";
import "react-datepicker/dist/react-datepicker.css";

class MaintenanceForm extends React.Component {
  constructor(props) {
    super(props);
    const input = Object.assign({}, props.item);
    if (!isEmpty(input)) {
      this.state = input;
    } else {
      this.state = {
        id: "",
        title: "",
        service_date: new Date(),
        description: ""
      };
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.serialize = this.serialize.bind(this);
  }

  serialize() {
    return Object.assign({}, this.state);
  }

  handleChange(e) {
    const key = e.currentTarget.dataset.key;
    const val = e.currentTarget.value;
    this.setState({
      [key]: val
    });
  }

  handleDateChange(e) {
    const val = e.toISOString();
    this.setState({
      service_date: val
    });
  }

  render() {
    let { title, service_date, description } = this.state;
    service_date = new Date(service_date);
    return (
      <div>
        <div className="row">
          <div className="col-sm-7">
            <label>Title</label>
            <input
              className="form-control"
              type="text"
              value={title}
              data-key="title"
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-5">
            <label>Date</label>
            <DatePicker
              className="form-control"
              data-key="service_date"
              onChange={this.handleDateChange}
              selected={service_date}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <label>Description</label>
            <textarea
              className="form-control"
              data-key="description"
              value={description}
              onChange={this.handleChange}
              type="text"
              rows="4"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MaintenanceForm;
