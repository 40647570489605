import { Marked } from "marked";
import { body, pages, pageKeysOrdered } from "../modules/documentation/content"
import customComponents from "../modules/documentation/marked-extension";

export default {
  name: "documentation",
  getReducer: () => {
    const initialData = {
      prevPageKey: '',
      nextPagekey: '',
      pageKey: "overview",
      page: {},
      focusedSectionKey: "overview",
      pages: {},
      body: {},
      marked: null,
      pageKeysOrdered: []
    }
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "DOCUMENTATION_CREATE_MARKED_INSTANCE":
          return Object.assign({}, state, payload);
        case "DOCUMENTATION_UPDATE_STATE":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    }
  },
  init: (store) => {
    store.doDocumentationCreateMarkedInstance();
    store.doDocumentationUpdateState({
      page: body["overview"],
      nextPageKey: pageKeysOrdered[1],
      pages: pages,
      body: body,
      pageKeysOrdered: pageKeysOrdered
    });
  },
  doDocumentationCreateMarkedInstance: () =>
    // Creating new instance of marked so it doesn't affect anything globally
    async ({ dispatch }) => {
      dispatch({
        type: "DOCUMENTATION_CREATE_MARKED_INSTANCE",
        payload: {
          marked: new Marked({ extensions: [customComponents] })
        }
      });
  },
  doDocumentationUpdateState: (update, callback) => ({ dispatch }) => {
    dispatch({
      type: "DOCUMENTATION_UPDATE_STATE",
      payload: { ...update }
    });
    callback && callback();
  },
  doDocumentationChangePage: (key, isRedirect=false, callback) => ({ dispatch, store }) => {
    let documentationState = store.selectDocumentationState();
    let { pageKey: currentKey, pages, body, pageKeysOrdered } = documentationState;

    let currentSection = pages[currentKey];
    let selectedSection = pages[key];
    let selectedSectionIdx = pageKeysOrdered.indexOf(key);
    let selectedSectionIsOnCurrentPage = currentSection.sections.includes(key) || currentKey === key;
    let updatedSections = pages;
    let parentPage = selectedSection.parentPage ? pages[selectedSection.parentPage] : null;
    if (parentPage) {
      updatedSections = {
        ...updatedSections,
        [parentPage._key]: {
          ...parentPage,
          subMenuShowing: isRedirect ? true : parentPage.subMenuShowing
        }
      }
    }
    // if selected section is a parent page
    if (selectedSection.hasOwnProperty("subMenuShowing")) {
      updatedSections = {
        ...updatedSections,
        [key]: {
          ...selectedSection,
          subMenuShowing: isRedirect? true : !selectedSection.subMenuShowing
        }
      }
    }
    if (selectedSectionIsOnCurrentPage) {
      dispatch({
        type: "DOCUMENTATION_UPDATE_STATE",
        payload: {
          focusedSectionKey: key,
          pages: updatedSections
        }
      });
    }
    else {
      dispatch({
        type: "DOCUMENTATION_UPDATE_STATE",
        payload: {
          pageKey: selectedSection.parentPage ? selectedSection.parentPage : key,
          prevPageKey: selectedSectionIdx === 0 ? '' : pageKeysOrdered[selectedSectionIdx - 1],
          nextPageKey: selectedSectionIdx === pageKeysOrdered.length - 1 ? '' : pageKeysOrdered[selectedSectionIdx + 1],
          page: selectedSection.parentPage ? body[selectedSection.parentPage] : body[key],
          focusedSectionKey: key,
          pages: updatedSections
        }
      });
    }
    callback && callback(selectedSection);
  },
  selectDocumentationFocusedSectionKey: state => {
    return state.documentation.focusedSectionKey;
  },
  selectDocumentationCurrentPage: state => {
    return state.documentation.page;
  },
  selectDocumentationState: state => {
    return state.documentation;
  },
  selectDocumentationMarked: state => {
    return state.documentation.marked;
  },
  selectDocumentationCurrentPageKey: (state) => {
    return state.documentation.pageKey;
  }
}