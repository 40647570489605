import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { connect } from "redux-bundler-react";

const AtpmPersonnelModal = ({
  doDialogClose,
  doAtpmControlSave,
  profileId,
  profilesUncontrolled,
  doFetchProfilesByOrg,
  orgsByRoute,
  doFetchAtpmDashboardById,
  doAtpmControlFetch,
}) => {
  const [added, setAdded] = useState([]);
  useEffect(doFetchProfilesByOrg, []);
  const save = () => {
    doAtpmControlSave(
      { atpm_id: profileId, pilot_id: added.value, org_id: orgsByRoute.id },
      () => {
        doDialogClose(() =>
          doAtpmControlFetch(() => doFetchAtpmDashboardById(profileId))
        );
      }
    );
  };

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Add Crewmember to Manage</h5>
        <button
          onClick={doDialogClose}
          className="close"
          type="button"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div className="modal-body">
        <div className="mb-2">Select Crewmember</div>
        <ReactSelect
          name="personnel"
          options={profilesUncontrolled.map((person) => ({
            label: person.name,
            value: person.profile_id,
          }))}
          onChange={setAdded}
        />
      </div>

      <div className="modal-footer">
        <button
          onClick={doDialogClose}
          className="btn btn-sm btn-secondary"
          type="button"
        >
          Close
        </button>
        <button onClick={save} className="btn btn-sm btn-success" type="button">
          Save
        </button>
      </div>
    </div>
  );
};

export default connect(
  "doDialogClose",
  "doAtpmControlSave",
  "selectProfileId",
  "selectProfilesUncontrolled",
  "doFetchProfilesByOrg",
  "selectProfilesByOrg",
  "selectOrgsByRoute",
  "doFetchAtpmDashboardById",
  "doAtpmControlFetch",
  AtpmPersonnelModal
);
