import React from "react";
import { connect } from "redux-bundler-react";
import Loader from "../../../../app-components/loader";
import FlightsForm from "./flight-new";

class FlightDetails extends React.Component {
  render() {
    const {
      doUpdateInnerRoute,
      flightsTabRouterParams,
      missionsFlightsIsLoading,
      missionsFlightsAircraftIsLoading,
      missionsFlightsGcsIsLoading,
      missionsFlightsMmsIsLoading,
      missionsFlightsBatteriesIsLoading,
      misisonsFlightsPayloadsIsLoading,
      missionsFlightsPersonnelIsLoading
    } = this.props;
    const params = flightsTabRouterParams;
    if (
      missionsFlightsIsLoading ||
      missionsFlightsAircraftIsLoading ||
      missionsFlightsGcsIsLoading ||
      missionsFlightsMmsIsLoading ||
      missionsFlightsBatteriesIsLoading ||
      misisonsFlightsPayloadsIsLoading ||
      missionsFlightsPersonnelIsLoading
    ) {
      return <Loader opt="dissolve-cube" />;
    } else {
      return (
        <FlightsForm {...params} doUpdateInnerRoute={doUpdateInnerRoute} />
      );
    }
  }
}

export default connect(
  "selectFlightsTabRouterParams",
  "selectMissionsFlightsIsLoading",
  "selectMissionsFlightsBatteriesIsLoading",
  "selectMissionsFlightsPayloadsIsLoading",
  "selectMissionsFlightsPersonnelIsLoading",
  FlightDetails
);
