import React from "react";
import NoteListItem from "./note-list-item";

export default ({ items }) => {
  const renderContent = () => {
    if (!items.length)
      return (
        <li className="list-group-item">
          <span className="mx-auto">No notes yet...</span>
        </li>
      );

    const sorted = items.sort((a, b) => {
      if (a.create_date > b.create_date) return -1;
      if (a.create_date < b.create_date) return 1;
      return 0;
    });

    return (
      <>
        {sorted.map((item, i) => {
          return <NoteListItem key={`${i}-${item.id}`} item={item} />;
        })}
      </>
    );
  };
  return <ul className="list-group">{renderContent()}</ul>;
};
