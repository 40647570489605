import { find, sortBy } from "lodash";
import { createSelector } from "redux-bundler";
import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "atpmControl",
  uid: "id",
  prefetch: true,
  staleAfter: 0,
  persist: false,
  routeParam: null,
  getTemplate: "/atpmControl",
  putTemplate: "/orgs/:orgSlug/atpmControl/:item.id",
  postTemplate: "/orgs/:orgSlug/atpmControl",
  deleteTemplate: "/orgs/:orgSlug/atpmControl/:item.id",
  fetchActions: [
    "AUTH_LOGGED_IN",
    "ATPMCONTROL_DELETE_FINISHED",
    "URL_UPDATED",
  ],
  addons: {
    selectProfilesUncontrolled: createSelector(
      "selectProfilesByOrg",
      "selectAtpmControlItems",
      (profilesByOrg, atpmControlItems) => {
        return sortBy(
          profilesByOrg.filter(
            (p) =>
              !find(atpmControlItems, (obj) => obj.pilot_id === p.profile_id)
          ),
          "name"
        );
      }
    ),
    selectActiveAtpmControl: createSelector(
      "selectProfileActiveData",
      "selectAtpmControlItems",
      "selectProfileId",
      (profileActiveData, atpmControlItems, profileId) => {
        // Returns the atpm_control row where the current viewed profile is managed  && where the current logged in profile is that person's primary ATPM
        return atpmControlItems && atpmControlItems.length > 0
          ? atpmControlItems.filter(
            (item) =>
              item.pilot_id === profileActiveData.id &&
              item.atpm_id === profileId
          ).length > 0 &&
          atpmControlItems.filter(
            (item) =>
              item.pilot_id === profileActiveData.id &&
              item.atpm_id === profileId
          )[0]
          : null;
      }
    ),
  },
});
