import React from "react";
import classnames from "classnames";
import { connect } from "redux-bundler-react";
import JobCommonTags from "./_shared/job-common-tags";
import { defaultJobParams } from "./job-new/job-new";
import {
  ItemBoardQueryControls,
  ItemBoardQueryStatusBar,
  ItemBoardQuerySearchBar,
} from "../_shared/item-board/item-board";

const sortTextTranslations = {
  upcoming: "Upcoming",
  relevant: "Most Active",
  published_date: "New",
};
const JobQueryPanel = ({
  jobsUserHasAtpmRoles,
  children,
  doUpdateRelativeUrl,
  jobsAllTags,
  jobsState,
  doJobsUpdateQueryString,
  doJobsNewFormStateUpdate,
  jobsFlags,
}) => {
  let renderFiltersBtnGroup = () => {
    let { _onlyFetchStatus } = jobsState;
    let activeStatusCls = classnames({
      btn: true,
      "btn-primary": !_onlyFetchStatus,
      "btn-outline-primary": _onlyFetchStatus,
    });
    let favoriteStatusCls = classnames({
      btn: true,
      "btn-primary": _onlyFetchStatus === "favorited",
      "btn-outline-primary": _onlyFetchStatus !== "favorited",
    });
    let expiredStatusCls = classnames({
      btn: true,
      "btn-primary": _onlyFetchStatus === "expired",
      "btn-outline-primary": _onlyFetchStatus !== "expired",
    });

    return (
      <div className="btn-group">
        <button
          name="active"
          className={activeStatusCls}
          onClick={updateFilterBtnClicked}
        >
          <span className="mdi mdi-timer-outline" />
          <span className="ml-2 d-none d-sm-inline">Active</span>
        </button>
        <button
          name="favorited"
          className={favoriteStatusCls}
          onClick={updateFilterBtnClicked}
        >
          <span className="mdi mdi-bookmark-check" />
          <span className="ml-2 d-none d-sm-inline">My Favorites</span>
        </button>
        <button
          name="expired"
          className={expiredStatusCls}
          onClick={updateFilterBtnClicked}
        >
          <span className="mdi mdi-timer-off-outline" />
          <span className="ml-2 d-none d-sm-inline">Expired</span>
        </button>
      </div>
    );
  };
  const updateQueryField = (update) => {
    doJobsUpdateQueryString(update);
  };
  let updateFilterBtnClicked = (e) => {
    updateQueryField({
      _onlyFetchStatus:
        e.currentTarget.name === "active" ? null : e.currentTarget.name,
    });
  };
  const sortOptions = [
    {
      update: { _sortBy: "upcoming" },
      icon: "mdi mdi-clock-alert-outline",
      text: "Upcoming",
      handler: updateQueryField,
      displayValue: "upcoming",
      exitOnClick: true,
    },
    {
      update: { _sortBy: "published_date" },
      icon: "mdi mdi-update",
      text: "New",
      handler: updateQueryField,
      displayValue: "published_date",
      exitOnClick: true,
    },
    {
      update: { _sortBy: "relevant" },
      icon: "mdi mdi-update",
      text: "Most Active",
      handler: updateQueryField,
      displayValue: "relevant",
      exitOnClick: true,
    },
  ];

  const searchBar = {
    placeholder: "Search MARS jobs ...",
    searchDropdownTitle: "Tags",
    searchDropdownOptions: jobsAllTags.map((tag) => {
      return {
        update: { _onlyFetchTag: tag },
        text: tag,
        handler: updateQueryField,
        displayValue: tag,
        exitOnClick: true,
      };
    }),
  };

  const renderPrimaryControls = () => {
    let favoriteJobsSelected = jobsState._onlyFetchStatus === "drafted";
    return (
      <div className="clearfix d-flex flex-row">
        {jobsUserHasAtpmRoles && (
          <div className="border-secondary border-right pr-2 mr-2 pr-sm-3 mr-sm-3">
            <button
              className="btn btn-primary mr-2"
              type="button"
              onClick={async () => {
                await doJobsNewFormStateUpdate({ ...defaultJobParams });
                await doUpdateRelativeUrl("/job-board/new");
              }}
            >
              <i className="mdi mdi-open-in-new mr-lg-2" />
              <span className="d-lg-inline d-none">Create Job</span>
            </button>
            <button
              name="drafted"
              className={`btn btn${
                favoriteJobsSelected ? "" : "-outline"
              }-primary`}
              onClick={updateFilterBtnClicked}
            >
              <i className="mdi mdi-pencil-outline mr-lg-2" />
              <span className="d-lg-inline d-none">My Drafts</span>
            </button>
          </div>
        )}
        {renderFiltersBtnGroup()}
      </div>
    );
  };
  let queryControlsProps = {
    renderPrimaryControls,
    sortOptions,
    flags: jobsFlags,
    bundleState: jobsState,
    doItemsUpdateQueryString: doJobsUpdateQueryString,
  };
  return (
    <>
      {children}
      <ItemBoardQuerySearchBar {...queryControlsProps} searchBar={searchBar}>
        <JobCommonTags updateQueryField={updateQueryField} />
      </ItemBoardQuerySearchBar>
      <ItemBoardQueryControls {...queryControlsProps} />
      <ItemBoardQueryStatusBar
        doItemsUpdateQueryString={doJobsUpdateQueryString}
        sortTextTranslations={sortTextTranslations}
        bundleState={jobsState}
        queryStatusParams={[
          { label: "Status", name: "_onlyFetchStatus", undoVal: null },
          { label: "Tag", name: "_onlyFetchTag", undoVal: null },
        ]}
      ></ItemBoardQueryStatusBar>
    </>
  );
};

export default connect(
  "selectJobsState",
  "doJobsUpdateQueryString",
  "doJobsNewFormStateUpdate",
  "doUpdateRelativeUrl",
  "selectJobsAllTags",
  "selectJobsFlags",
  "selectJobsUserHasAtpmRoles",
  JobQueryPanel
);
