export default {
  name: "airspace",

  getReducer: () => {
    const layerNames = {
      // adhp: "ADHP",
      airport: "Airport Areas",
      class_b: "Class B Airspace",
      class_c: "Class C Airspace",
      class_d: "Class D Airspace",
      class_e0: "Class E0 Airspace",
      class_e1: "Class E1 Airspace",
      class_e2: "Class E2 Airspace",
      class_e3: "Class E3 Airspace",
      class_e4: "Class E4 Airspace",
      class_e5: "Class E5 Airspace",
      faa_facilities: "FAA Facilities",
      prohibited: "UAS Prohibited",
      sua: "Special Use Airspace",
      park: "Parks",
      power_plant: "Power Plants",
      school: "Schools",
    };

    const layerKeys = Object.keys(layerNames).filter(
      (name) => name !== "class_e5"
    );

    const initialState = {
      lyr: null,
      layerNames: layerNames,
      activeLayerNames: [...layerKeys],
      api_key:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVkZW50aWFsX2lkIjoiY3JlZGVudGlhbHxNS21vWldnVDVLNDdPR1RNWm53OWJIYWt4eGE3IiwiYXBwbGljYXRpb25faWQiOiJhcHBsaWNhdGlvbnxFWndlWGRYRkF2a1dYQUZYRWFKRE9mQm84Nm16Iiwib3JnYW5pemF0aW9uX2lkIjoiZGV2ZWxvcGVyfHZEYmEydmxIV3l6MHFLaVFncWQ1QXN3RXBZb0EiLCJpYXQiOjE1NTM3OTE4ODZ9.fQG5wTnfD2nIj-dL-dA1gmLmvUvFVMNY8BK1uFenBmg",
      client_id: "11af55ad-7768-43b9-9104-0bd23f2ad7ea",
      shouldInitialize: false,
      shouldSetStyle: false,
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case "AIRSPACE_INITIALIZE_STARTED":
        case "AIRSPACE_INITIALIZE_FINISHED":
        case "AIRSPACE_IDENTIFY_STARTED":
        case "AIRSPACE_SET_ACTIVE_LAYERS":
          return Object.assign({}, state, payload);
        case "MAP_INITIALIZED":
          return Object.assign({}, state, { shouldInitialize: true });
        default:
          return state;
      }
    };
  },

  doAirspaceSetActiveLayers:
    (activeLayerNames) =>
    ({ dispatch }) => {
      dispatch({
        type: "AIRSPACE_SET_ACTIVE_LAYERS",
        payload: {
          activeLayerNames: [...activeLayerNames],
        },
      });
    },

  selectAirspaceLayerNames: (state) => {
    return state.airspace.layerNames;
  },

  selectAirspaceActiveLayerNames: (state) => {
    return state.airspace.activeLayerNames;
  },

  selectAirmapApiKey: (state) => {
    return state.airspace.api_key;
  },

  selectAirpsaceUrl: (state) => {
    return state.airspace.url;
  },

  selectAirspaceLayer: (state) => {
    return state.airspace.lyr;
  },
};
