import React from "react";
import "../documentation.css";
import ComponentTree from "../component-tree";
import RoleFilter from "../../../app-containers/context-providers/role-filter";
const Chapter = ({ childComponents = [], _key, title, allowRoles = ["*.*"] }) => {
  return (
    <RoleFilter allowRoles={allowRoles}>
      <div className="row">
        <div className="col">
          <h1 className="documentation-page display-6" id={_key}>{title}</h1>
          {childComponents.map((child) => {
            return <ComponentTree obj={child} />;
          })}
        </div>
      </div>
    </RoleFilter>
  );
}
export default Chapter;