import React from "react";
import { connect } from "redux-bundler-react";

/**
 * Mission Status Filter
 * takes a prop of allowStatus which should be an object like:
 * {
 *   approval_status: ['draft', 'pending approval', 'approved'],
 *   schedule_status: ['pending', 'active', 'expired'],
 *   debrief_status: ['pending', 'in progress', 'complete'],
 *   mission_status: ['draft', 'pending approval', 'active', 'schedule expired', 'complete]
 * }
 *
 * or a prop of denyStatus with the same schema
 *
 * if any of the conditions specified exist then the filter will pass, otherwise we will render nothing
 * or whatever is passed in through the alt prop.
 */

class MissionStatusFilter extends React.Component {
  render() {
    const {
      missionsByRoute: mission,
      children,
      alt: Alt,
      allowStatus,
      denyStatus,
    } = this.props;

    let pass = false;

    if (allowStatus) {
      const keys = Object.keys(allowStatus);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (allowStatus[key].indexOf(mission[key]) !== -1) {
          pass = true;
          break;
        }
      }
    }

    if (!allowStatus) pass = true;
    if (denyStatus) {
      const keys = Object.keys(denyStatus);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (denyStatus[key].indexOf(mission[key]) !== -1) {
          pass = false;
          break;
        }
      }
    }

    if (!pass) {
      if (Alt) {
        return <Alt {...this.props} />;
      } else {
        return null;
      }
    }
    return <>{children}</>;
  }
}

export default connect("selectMissionsByRoute", MissionStatusFilter);
