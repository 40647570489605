import React from "react";
import classnames from "classnames";

class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      property: props.property,
      value: props.value || "",
      valid: true,
      msg: "",
    };
    this.renderHelper = this.renderHelper.bind(this);
    this.validate = this.validate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  componentDidMount() {
    this.validate();
  }

  componentDidUpdate(newProps) {
    if (newProps.value && newProps.value !== this.state.value) {
      this.setState(
        {
          property: newProps.property,
          value: newProps.value || "",
        },
        this.validate
      );
    }
  }

  handleChange(e) {
    const { onChange } = this.props;
    const val = e.currentTarget.value;
    if (onChange) {
      onChange({ ...this.state, value: val });
    } else {
      this.setState(
        {
          value: val,
        },
        this.validate
      );
    }
  }

  isValid() {
    return this.state.valid;
  }

  validate() {
    const { required, schema, onChange } = this.props;
    const { title, maxLength } = schema;
    const { value } = this.state;

    let valid = true;
    let msg = "";

    const len = value.length || 0;

    if (required && len < 1) {
      valid = false;
      msg += `${title} is required`;
    }

    if (len > maxLength) {
      valid = false;
      msg += `Length of ${title} is limited to ${maxLength} characters`;
    }

    this.setState({
      valid: valid,
      msg: msg,
    });
  }

  renderHelper() {
    const { msg } = this.state;
    const { schema } = this.props;
    const { description } = schema;
    if (msg) return <small className="invalid-feedback">{msg}</small>;
    if (!description) return null;
    return <small className="form-text text-muted">{description}</small>;
  }

  render() {
    const { valid, value: stateValue } = this.state;
    const {
      schema,
      inline,
      displayOnly,
      placeholder,
      onChange,
      value: propValue,
    } = this.props;
    let { title, readOnly } = schema;
    let value = onChange ? propValue : stateValue;

    if (displayOnly) readOnly = displayOnly;

    const groupClass = classnames({
      "form-group": true,
      row: inline,
    });

    const labelClass = classnames({
      "col-sm-3": inline,
      "col-form-label": inline,
      "text-right": inline,
    });

    const inputWrapperClass = classnames({
      "col-sm-9": inline,
    });

    const validClass = classnames({
      "form-control": true,
      "is-invalid": !valid,
    });

    return (
      <div className={groupClass}>
        {title && <label className={labelClass}>{title}</label>}
        <div className={inputWrapperClass}>
          <input
            value={value}
            onChange={this.handleChange}
            readOnly={readOnly}
            type="text"
            className={validClass}
            placeholder={
              placeholder ? placeholder : !readOnly ? `Enter ${title}...` : ""
            }
          />
          {this.renderHelper()}
        </div>
      </div>
    );
  }
}

export default TextInput;
