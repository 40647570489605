import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import TextInput from "../../../../app-components/schema-form/text-input";
import PrintButton from "../../../../app-components/print-button/print-button";
import PreparedBySigItem from "../../../../modules/missions/details/approvals-tab/prepared-by-sig-item";
import DateInput from "../../../../app-components/schema-form/date-input";
import Loader from "../../../../app-components/loader";
import { find } from "lodash";

const formHeaders = [
  {
    headers: [
      {
        title: "Authority",
        description:
          "14 CFR Part 107, Federal Aviation Administration (FAA), Army Regulation (AR) 40-8, AR 95-1, and Title 14 U.S.C.",
      },
      {
        title: "Principal Purpose",
        description:
          "The purpose of this form is clearly document the self-assessment of functional and physiological health to operate a USACE sUAS, while serving as a USACE sUAS Operator or Visual Observer.",
      },
      {
        title: "Routine Uses",
        description:
          "Information will be retained for one year inside the local individual training aircrew folder controlled by the MSC/FOA/Lab Aircrew Training Program Manager. This form will be destroyed in compliance with Army Records Retention Schedule.",
      },
      {
        title: "Disclosure",
        description:
          "Voluntary. However, failure to complete the form could result in the employee not being able to operate a USACE sUAS.",
      },
    ],
  },
  {
    headers: [
      {
        title: "Applicability",
        description:
          "This is applicable to all USACE federal employees and active duty service members who are assigned through additional duty orders as a USACE sUAS Operator or Visual Observer IAW FAA Part 107.",
      },
      {
        title: "Purpose",
        description:
          "The purpose of this self-assessment tool is for a sUAS Operator or Visual Observer to self-assess their health for sUAS operations and reinforce their awareness of the health factors that might affect the performance for safe sUAS flight operations.",
      },
      {
        title: "Instructions",
        description:
          "This health self-assessment should be performed annually or as needed when a sUAS Operator or Visual Observer requires reinforcement of the health factors for the performance of safe flight operations.",
      },
    ],
  },
];
const requirements = [
  "Able to operate sUAS weighing up to 55 pounds to an absolute maximum speed of 100 miles per hour",
  "Able to operate sUAS to 400 feet above the ground or within 100 feet of an object higher than 400 feet",
  "Able to avoid all manned aircraft in a controlled manner by keeping the sUAS within visual line of sight",
  "Able to operate from the hours of daylight to twilight (30 minutes before/after offical sunrise/sunset)",
  "Able to communicate clearly with speech to a visual observer who is assisting with watching the sUAS",
  "Able to operate a sUAS outside unprotected from the elements such as heat, cold, rain, snow, and/or wind.",
  "Able to operate the sUAS within the proper airspace distant from all aerial and ground hazards.",
];
const physioFactors = [
  {
    title: "Physical/Mental Condition",
    description:
      "I have no physical or mental incapacitation that could render myself incapable of performing sUAS duties (e.g., migraine, headache, moderate/severe body ache(s) or pain(s), or seizures) (5.6.1, 5.6.4)",
  },
  {
    title: "Communication",
    description:
      "I have the ability to speak, hear, and see a visual observer over typical mission distances (5.6.5.)",
  },
  {
    title: "Situational Awareness",
    description:
      "I have the ability to maintain proper situational awareness of all sUAS operations and have no illness and/or medication(s), that interfere with my ability to maintain proper situational awareness (5.6.3)",
  },
  {
    title: "Hand/Finger dexterity",
    description:
      "I have the dexterity ability to successfully operate the controls, buttons, and switches in a controlled and timely manner for the safe operation of the sUAS control station (5.6.1)",
  },
  {
    title: "Vision",
    description:
      'I have the sufficient distant vision corrected to be able to view the sUAS at distance, maintain visual line of sight, "see and avoid" obstacles, and maintain a continuous scan for obstacles (5.6.2, 5.7)',
  },
];
const humanFactors = [
  {
    title: "Illness",
    description:
      "I do not know (or have reason to know) of any medical condition that would make me unable to meet the requirement for safe and controlled sUAS operations (Title 14 CFR) (5.15)",
  },
  {
    title: "Medication/Drug",
    description:
      'I will not take medication(s)/drugs or receive other treatment for a medical condition that is in any way contrary to safety. I understand virtually all medications (over-the-counter and prescribed), herbal, dietary supplements, sports/energy boosters and "natural" products have the potential for adverse side effects; I have reviewed the warnings for the products I use and there is no chance for the sedation or the lowering of physical/mental performance. Any new product should have a 48-hour test period before flying to determine any adverse effects. (14 CFR Part 107 and 14 CFR Part 91, Sections 91.17 and 91.19), (FAA AC 107-2, 5.15) (FAA-G-8082-22, 49)',
  },
  {
    title: "Stress",
    description:
      "I will take measures to control stress and reduce or eliminate distractions during sUAS operations. (FAA-G-8082-22, 46)",
  },
  {
    title: "Alcohol consumption",
    description:
      "I will not consume alcohol within 8-hours of flight operations (FAA AC 107-2, 5.15). I understand that it is unlawful for me to use any form of marijuana or other Federally identified illegal substance while designated as a sUAS operator (14 CFR Part 107).",
    note: () => {
      return (
        <div className="d-inline-flex text-muted ml-5">
          <strong className="mr-2">NOTE:</strong>
          <p>
            (While operating on a military installation Army Regulation 40-8 is
            more restrictive and requires no alcohol consumed within the last
            12-hours of flight operations). (AR 40-8, 6c)
          </p>
        </div>
      );
    },
  },
  {
    title: "Fatigue",
    description:
      "I will have sufficient sleep, will not over-exercise, will not feel physically or mentally exhausted, or have a degradation of attention, concentration, impaired coordination, or decreased communication prior to flight operations. (FAA-G-8082-22, 46, 47)",
  },
  {
    title: "Eating",
    description:
      "I will be well hydrated with water and well fed with healthy well-balanced foods to ensure that I will not become distracted by dehydration or hunger. (FAA-G-8082-22, 47-48)",
  },
];

const HealthAssessment = ({
  doProfileAlertFetch,
  healthAssessmentApprovalItems: approvals,
  routeInfo,
  healthAssessmentIsSaving: isSaving,
  healthAssessmentIsLoading: isLoading,
  healthAssessmentApprovalIsSaving: approvalsIsSaving,
  healthAssessmentApprovalIsLoading: approvalsIsLoading,
  profilesAll,
  healthAssessmentSelectedForm,
  doHealthAssessmentUpdateSelectedForm,
  tokenUsername,
  doNestedDialogClose,
  doHealthAssessmentSave,
  doHealthAssessmentApprovalSave,
  doHealthAssessmentApprovalDelete,
}) => {
  const onSelfAssessBoxClicked = (e) => {
    let value = e.currentTarget.value === "true";
    if (value) {
      let { waiver_approved_date, waiver_submitted_date } =
        healthAssessmentSelectedForm;
      let waiver_deselected = e.currentTarget.name === "waiver_needed" && value;

      doHealthAssessmentUpdateSelectedForm({
        [e.currentTarget.name]: !value,
        waiver_approved_date: waiver_deselected ? null : waiver_approved_date,
        waiver_submitted_date: waiver_deselected ? null : waiver_submitted_date,
      });
    } else {
      let { meets_all_requirements, waiver_needed, permanent_condition } =
        healthAssessmentSelectedForm;
      let updatedChoices = {
        meets_all_requirements,
        waiver_needed,
        permanent_condition,
      };
      Object.keys(updatedChoices).forEach((key) => {
        updatedChoices[key] = key === e.currentTarget.name;
      });
      doHealthAssessmentUpdateSelectedForm({
        ...updatedChoices,
        waiver_approved_date: null,
        waiver_submitted_date: null,
      });
    }
  };
  const onAcknowledgeClicked = (e) => {
    let value = e.currentTarget.value === "true";
    doHealthAssessmentUpdateSelectedForm({ acknowledged: !value });
  };
  const onSave = () => {
    const { waiver_approved_date, waiver_submitted_date } =
      healthAssessmentSelectedForm;
    doHealthAssessmentSave(
      {
        ...healthAssessmentSelectedForm,
        waiver_approved_date: waiver_approved_date || null,
        waiver_submitted_date: waiver_submitted_date || null,
      },
      doNestedDialogClose
    );
  };

  const onInputElementValueChanged = (input) => {
    doHealthAssessmentUpdateSelectedForm({ [input.property]: input.value });
  };

  const onSign = (signer) => {
    const { id } = healthAssessmentSelectedForm;
    if (signer.sigColumn === "preparer_sig") {
      const { waiver_approved_date, waiver_submitted_date } =
        healthAssessmentSelectedForm;
      doHealthAssessmentSave(
        Object.assign(
          {},
          {
            ...healthAssessmentSelectedForm,
            waiver_approved_date: waiver_approved_date || null,
            waiver_submitted_date: waiver_submitted_date || null,
          }
        ),
        (result) => {
          doHealthAssessmentApprovalSave(
            { ...signer, formId: id || result.id, username: tokenUsername },
            () => {
              if (!id) doHealthAssessmentUpdateSelectedForm({ ...result });
            },
            true
          );
        },
        true
      );
    } else if (signer.sigColumn === "atpm_sig" && !approvals[1])
      doHealthAssessmentApprovalSave({
        ...signer,
        formId: id,
        username: tokenUsername,
      });
  };
  useEffect(() => {
    return () => {
      doProfileAlertFetch();
    };
  }, []);
  useEffect(() => {
    if (approvals) {
      let update = {};
      let [preparer, atpm] = approvals;
      let preparer_sig = null;
      let preparer_sig_date = null;
      let preparer_sig_time = null;
      let atpm_sig = null;
      let atpm_sig_date = null;
      let atpm_sig_time = null;
      let atpm_name = null;
      if (preparer && preparer.sig) {
        preparer_sig = JSON.parse(atob(preparer.sig.split(".")[0]));
        preparer_sig_date = new Date(preparer_sig.date).toLocaleDateString();
        preparer_sig_time = new Date(preparer_sig.date).toLocaleTimeString();
      }
      if (atpm && atpm.sig) {
        atpm_sig = JSON.parse(atob(atpm.sig.split(".")[0]));
        let atpmProfile = find(profilesAll, {
          keycloak_id: atpm_sig.keycloak_id,
        });
        atpm_name = atpmProfile.name;
        atpm_sig_date = new Date(atpm_sig.date).toLocaleDateString();
        atpm_sig_time = new Date(atpm_sig.date).toLocaleTimeString();
      }
      doHealthAssessmentUpdateSelectedForm({
        ...update,
        preparer_sig: preparer_sig,
        preparer_sig_date: preparer_sig_date,
        preparer_sig_time: preparer_sig_time,
        atpm_sig: atpm_sig,
        atpm_sig_date: atpm_sig_date,
        atpm_sig_time: atpm_sig_time,
        atpm_name: atpm_name,
      });
    }
  }, [approvals]);

  const onUnlockFormBtnClicked = () => {
    approvals.forEach((approval) => {
      doHealthAssessmentApprovalDelete(Object.assign({}, { ...approval }));
    });
  };
  const renderApprovals = () => {
    const {
      foa,
      acknowledged,
      meets_all_requirements,
      waiver_needed,
      permanent_condition,
    } = healthAssessmentSelectedForm;
    let disabledByInputs =
      !acknowledged ||
      (!meets_all_requirements && !waiver_needed && !permanent_condition);
    let disableAtpmSigBtn =
      (routeInfo.url && routeInfo.url.includes("/profile") && !approvals[1]) ||
      approvals.length === 0;
    if (isSaving || isLoading || approvalsIsSaving || approvalsIsLoading)
      return <Loader />;
    return (
      <div className="col-lg mt-3">
        <PreparedBySigItem
          title="Preparer Signature"
          sig={approvals[0] ? approvals[0].sig : ""}
          item={{}}
          onSign={() => {
            onSign({ sigColumn: "preparer_sig", routing_order: 0 });
          }}
          disabled={disabledByInputs}
        />
        <div
          className="position-absolute ml-auto mr-0 mt-0 mb-auto"
          style={{ right: "3rem", top: 0 }}
        >
          <div className="d-inline-flex py-1">
            <TextInput
              placeholder=""
              value={foa}
              property="foa"
              onChange={(input) => onInputElementValueChanged(input)}
              schema={{ title: "MSC/FOA/LAB" }}
              displayOnly={disabledByInputs || approvals.length > 0}
            />
          </div>
        </div>
        <PreparedBySigItem
          title="ATPM Signature"
          sig={approvals[1] ? approvals[1].sig : ""}
          onSign={() => {
            onSign({ sigColumn: "atpm_sig", routing_order: 1 });
          }}
          item={{}}
          disabled={disableAtpmSigBtn}
        />
        <strong
          className="position-absolute ml-auto mr-0 mt-auto mb-2 text-nowrap"
          style={{ bottom: "3rem", right: "3rem" }}
        >
          Signature Acknowledges form is placed in local individual aircrew
          training file.
        </strong>
      </div>
    );
  };
  const {
    waiver_needed,
    waiver_submitted_date,
    waiver_approved_date,
    meets_all_requirements,
    permanent_condition,
    acknowledged,
    atp_year,
    expiration_date,
  } = healthAssessmentSelectedForm;
  let disabledByProfile = !routeInfo.url.includes("/profile");
  return (
    <div className="modal-content">
      <div className="modal-header">
        <div className="d-flex flex-column">
          <h5 className="modal-title">Health Self-Assessment Tool</h5>
          <h6 className="text-muted mt-2">
            The proponent agency is CESO-MED/USACE Command Surgeon.
          </h6>
        </div>
        <div className="d-flex flex-column h-100 mx-auto">
          <h5>ATP Year: {atp_year}</h5>
        </div>
        <div className="d-flex flex-column h-100 ml-auto mr-2 ">
          <button
            className="btn btn-sm btn-outline-primary mt-auto mb-0"
            disabled={approvals.length === 0}
            onClick={onUnlockFormBtnClicked}
          >
            <span className="mdi mdi-lock-open-variant-outline mr-2" />
            Unlock Form
          </button>
        </div>
        <div className="d-flex flex-column h-100">
          <button
            className="close"
            type="button"
            aria-label="Close"
            onClick={doNestedDialogClose}
          >
            <span aria-hidden="true">×</span>
          </button>
          <PrintButton
            className="btn-sm mt-auto mb-0"
            state={healthAssessmentSelectedForm}
            options={[
              {
                resultFileName: "Health Assessment",
                file: "health-assessment.template.json",
              },
            ]}
          >
            <i className="mdi mdi-printer mr-2" />
            Fill & Print Health Assessment
          </PrintButton>
        </div>
      </div>
      <div className="modal-body">
        <div className="container-fluid">
          {formHeaders.map((grp, gIdx) => {
            return (
              <div className="col-lg border-bottom pt-3" key={gIdx}>
                {grp.headers.map((header, hIdx) => {
                  return (
                    <div className="d-inline-flex pb-2" key={hIdx}>
                      <p className="mr-2 text-nowrap">
                        <strong>{header.title}</strong>
                      </p>
                      <p className="m-0">{header.description}</p>
                    </div>
                  );
                })}
              </div>
            );
          })}
          <div className="col-lg bg-secondary">
            <div className="d-flex flex-column mx-auto">
              <span className="mx-auto">
                <strong>SECTION I</strong>
                <span className="mx-2">-</span>
                <u className="mr-2">FUNCTIONAL</u>
                Federal Aviation Administration REQUIREMENTS FOR sUAS OPERATORS{" "}
                <i>(FAA Part 107)</i>
              </span>
              <span className="mx-auto">A sUAS Operator must be able to</span>
            </div>
          </div>
          {requirements.map((req, idx) => {
            return (
              <div className="col-lg border-bottom" key={idx}>
                <div className="d-inline-flex py-1">
                  <p className="mb-0 mr-2 text-nowrap">{req}</p>
                </div>
              </div>
            );
          })}
          <div className="col-lg bg-secondary ">
            <div className="d-flex flex-column mx-auto">
              <span className="mx-auto">
                <strong>SECTION II</strong>
                <span className="mx-2">-</span>
                <u className="mr-2">
                  Physiological Factors Affecting Pilot Performance
                </u>
                <i>(FAA AC 107-2, dated 6/21/16)</i>
              </span>
              <span className="mx-auto">A sUAS Operator must be able to</span>
            </div>
          </div>
          {physioFactors.map((factor, idx) => {
            return (
              <div className="col-lg border-bottom" key={idx}>
                <div className="d-inline-flex py-1">
                  <p className="mr-2 text-nowrap">
                    <strong className="mr-2">{factor.title}</strong>
                  </p>
                  <p>{factor.description}</p>
                </div>
              </div>
            );
          })}
          <div className="col-lg bg-secondary py-2">
            <div className="d-flex flex-column mx-auto">
              <span className="mx-auto">
                <strong>SECTION III</strong>
                <span className="mx-2">-</span>
                <span className="mr-2">Federal Aviation Administration</span>
                <u className="mr-2">"IMSAFE" CHECKLIST</u>
                <i>(Human Factors Risk Assessment)</i>
              </span>
            </div>
          </div>
          {humanFactors.map((factor, idx) => {
            return (
              <div className="col-lg border-bottom" key={idx}>
                <div className="d-inline-flex py-1">
                  <p className="mr-2 text-nowrap">
                    <strong className="mr-2">{factor.title}</strong>
                  </p>
                  <p>{factor.description}</p>
                </div>
                {factor.note && factor.note()}
              </div>
            );
          })}
          <div className="col-lg bg-secondary py-2">
            <div className="d-flex flex-column mx-auto">
              <span className="mx-auto">
                <strong>SECTION IV</strong>
                <span className="mx-2">-</span>
                <u className="mr-2">USACE sUAS Operator's Self-Assessment:</u>
                <span>
                  I voluntarily self-assess by selecting one box below that
                </span>
              </span>
            </div>
          </div>
          <div className="col-lg border-bottom">
            <div className="d-inline-flex py-1">
              <p className="mr-2 text-nowrap">
                <strong className="mr-2">1)</strong>
              </p>
              <input
                className="mb-2 mr-4"
                type="checkbox"
                name="meets_all_requirements"
                style={{ transform: "scale(1.5)" }}
                checked={meets_all_requirements}
                value={meets_all_requirements || ""}
                onChange={onSelfAssessBoxClicked}
                disabled={approvals.length > 0 || disabledByProfile}
              />
              <strong>
                <u>I MEET all Health Self-Assessment requirements/factors</u>{" "}
                for a USACE sUAS Operator / Visual Observer.{" "}
              </strong>
            </div>
          </div>
          <div className="col-lg border-bottom">
            <div className="d-inline-flex py-1">
              <p className="mr-2 text-nowrap">
                <strong className="mr-2">2)</strong>
              </p>
              <input
                className="mb-2 mr-4"
                name="waiver_needed"
                type="checkbox"
                style={{ transform: "scale(1.5)" }}
                checked={waiver_needed}
                value={waiver_needed || ""}
                onChange={onSelfAssessBoxClicked}
                disabled={approvals.length > 0 || disabledByProfile}
              />
              <p>
                <strong>
                  <u>
                    I DO NOT FULLY MEET all Health Self-Assessment
                    requirements/factors for a USACE sUAS Operator / Visual
                    Observer.
                  </u>
                </strong>
                <span className="ml-2">
                  All requests for waivers will be submitted through the USACE
                  Medical Authority by encrypted email to
                  hqmedical@usace.army.mil
                </span>
              </p>
            </div>
          </div>
          <div className="col-lg border-bottom">
            <div className="d-inline-flex py-1 ml-2">
              <div className="d-flex ml-5">
                <span className="my-auto mr-2">Waiver submitted on:</span>
                <DateInput
                  value={waiver_submitted_date}
                  property="waiver_submitted_date"
                  name="waiver_submitted_date"
                  schema={{
                    title: "Date Waiver was Submitted",
                    readOnly:
                      !waiver_needed ||
                      approvals.length > 0 ||
                      disabledByProfile,
                  }}
                  onChange={(input) => onInputElementValueChanged(input)}
                  placeholder="Enter date..."
                />
              </div>
            </div>
          </div>
          <div className="col-lg border-bottom">
            <div className="d-inline-flex py-1">
              <div className="d-flex">
                <span className="my-auto mr-2">
                  Approved Waiver received on:
                </span>
                <DateInput
                  value={waiver_approved_date}
                  property="waiver_approved_date"
                  name="waiver_approved_date"
                  schema={{
                    title: "Date Waiver Reponse By USACE Medical Authority",
                    readOnly:
                      !waiver_needed ||
                      approvals.length > 0 ||
                      disabledByProfile,
                  }}
                  onChange={(input) => onInputElementValueChanged(input)}
                  placeholder="Enter date..."
                />
              </div>
            </div>
          </div>
          <div className="col-lg border-bottom">
            <div className="d-inline-flex py-1">
              <p className="mr-2 text-nowrap">
                <strong className="mr-2">3)</strong>
              </p>
              <input
                className="mb-2 mr-4"
                name="permanent_condition"
                type="checkbox"
                style={{ transform: "scale(1.5)" }}
                checked={permanent_condition}
                value={permanent_condition || ""}
                onChange={onSelfAssessBoxClicked}
                disabled={approvals.length > 0 || disabledByProfile}
              />
              <p>
                <strong>
                  <u>
                    I DO NOT FULLY MEET all requirements and factors for USACE
                    sUAS Operations due to a permanent condition and will
                    voluntarily not perform flight operations.
                  </u>
                </strong>
              </p>
            </div>
          </div>
          <div className="col-lg bg-secondary py-2">
            <div className="d-flex flex-column mx-auto">
              <span className="mx-auto">
                <strong>SECTION V</strong>
                <span className="mx-2">-</span>
                <span>
                  USACE sUAS CERTIFICATION <i>(required)</i>
                </span>
              </span>
            </div>
          </div>
          <div className="col-lg border-bottom">
            <div className="d-inline-flex py-1 pl-1">
              <input
                className="ml-4 mb-2 mr-4 mt-2 mb-auto"
                type="checkbox"
                style={{ transform: "scale(1.5)" }}
                checked={acknowledged}
                value={acknowledged || ""}
                onChange={onAcknowledgeClicked}
                disabled={approvals.length > 0 || disabledByProfile}
              />
              <p>
                <strong className="mr-1">
                  <u>I ACKNOWLEDGE by the signature below:</u>
                </strong>
                That I am obligated to perform to a self-assessment of my
                fitness for duty before{" "}
                <strong>
                  <i>each mission</i>
                </strong>{" "}
                and recognize the importance of voluntarily declining duties as
                the remote pilot or visual observer when I am unable to
                reasonably maintain safe operations. I understand that in the
                event of an aircraft accident (Class A through Class C), all
                crewmembers and any other personnel who may have contributed to
                the accident will be quickly evacuated to medical facilities for
                physical examinations and blood and urine testing according to
                AR 40 -8, AR 40 -21, AR 40 -501, AR 600 -105, and DA Pam 385
                -40. I understand that AR 40-8 restricts flying duty for 12
                hours after an immunization or a local or regional anesthesia,
                24 hours after a plasma donation, 48 hours after general,
                spinal, or epidural anesthesia, and 72 hours after blood
                donation greater than 200 mL.
              </p>
            </div>
          </div>
          {renderApprovals()}
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-between align-items-center w-100">
          <h6 className="ml-0 mr-auto">
            <span className="mr-2">
              Expire{new Date() > new Date(expiration_date) ? "d" : "s"} on:{" "}
            </span>
            <small className="text-muted">{expiration_date}</small>
          </h6>
          <div className="ml-auto mr-0">
            <button
              className="mr-2 btn btn-sm btn-secondary"
              onClick={doNestedDialogClose}
            >
              Cancel
            </button>
            <button className="btn btn-sm btn-success" onClick={onSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  "selectHealthAssessmentIsLoading",
  "selectHealthAssessmentIsSaving",
  "selectHealthAssessmentApprovalIsLoading",
  "selectHealthAssessmentApprovalIsSaving",
  "selectTokenUsername",
  "selectHealthAssessmentApprovalItems",
  "doHealthAssessmentSave",
  "doHealthAssessmentApprovalSave",
  "doHealthAssessmentApprovalDelete",
  "doNestedDialogClose",
  "selectHealthAssessmentSelectedForm",
  "doHealthAssessmentUpdateSelectedForm",
  "selectProfilesAll",
  "selectRouteInfo",
  "doProfileAlertFetch",
  HealthAssessment
);
