import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import { formatRelative } from "date-fns";
import NoteEditor from "./note-editor";
import RoleFilter from "../../../app-containers/context-providers/role-filter";
import readOnlyNotification from "../read-only-notification";

export default connect(
  "selectProfileActive",
  "doNotesDelete",
  ({ profileActive, item, doNotesDelete }) => {
    const rel = formatRelative(new Date(item.create_date), new Date());
    const myNote = item.last_updated_by === profileActive.keycloak_id;

    const [editing, setEditing] = useState(false);
    const [confirming, setConfirming] = useState(false);

    const toggleEditing = () => {
      setEditing(!editing);
    };

    const renderDeleteButton = () => {
      if (confirming) {
        return (
          <div className="btn-group">
            <button
              onClick={() => {
                doNotesDelete(item);
              }}
              className="btn btn-sm btn-danger"
            >
              Confirm
            </button>
            <button
              onClick={() => {
                setConfirming(false);
              }}
              className="btn btn-sm btn-secondary"
            >
              Cancel
            </button>
          </div>
        );
      } else {
        return (
          <button
            disabled={editing}
            onClick={() => {
              setConfirming(true);
            }}
            className="btn btn-sm btn-outline-danger"
          >
            Delete
          </button>
        );
      }
    };

    const renderEditButton = () => {
      if (myNote)
        return (
          <span>
            {renderDeleteButton()}
            <button
              onClick={toggleEditing}
              disabled={editing || confirming}
              className="btn btn-sm btn-outline-primary ml-2"
            >
              Edit
            </button>
          </span>
        );
      else {
        return (
          <RoleFilter allowRoles={[`:ORG.ADMIN`]} alt={readOnlyNotification}>
            <span>
              {renderDeleteButton()}
              <button
                onClick={toggleEditing}
                disabled={editing || confirming}
                className="btn btn-sm btn-outline-primary ml-2"
              >
                Edit
              </button>
            </span>
          </RoleFilter>
        );
      }
    };

    const renderContent = () => {
      if (editing) return <NoteEditor item={item} onComplete={toggleEditing} />;
      return <div className="display-linebreak">{`${item.note}`}</div>;
    };

    return (
      <li className="list-group-item">
        <div className="clearfix">
          <span className="float-right mb-1">{renderEditButton()}</span>
          <span className="text-muted">
            <small>{`${myNote ? "You" : item.name} entered ${rel}`}</small>
          </span>
        </div>
        {renderContent()}
      </li>
    );
  }
);
