import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import SelectorCard from "../../_shared/selector-card-mission-filter/card";

const Personnel = (props) => {
  const {
    personnelNotHidden,
    missionsPersonnelItems,
    missionsPersonnelIsLoading,
    doMissionsPersonnelSave,
    doMissionsPersonnelDelete,
    doMissionsPersonnelFetch,
  } = props;

  useEffect(() => {
    if (!missionsPersonnelIsLoading) doMissionsPersonnelFetch();
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col">
          <SelectorCard
            allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
            allowStatus={{
              approval_status: ["draft"],
            }}
            items={missionsPersonnelItems}
            isLoading={missionsPersonnelIsLoading}
            pickFrom={personnelNotHidden}
            onAdd={doMissionsPersonnelSave}
            onRemove={doMissionsPersonnelDelete}
            title="Personnel"
            iconClass="mdi mdi-account-multiple-outline"
            itemRenderFn={(item) => {
              return `${item.name} ${
                item.adjudicated ? " - Added Post Approval" : ""
              }`;
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(
  "selectPersonnelNotHidden",
  "selectMissionsPersonnelItems",
  "selectMissionsPersonnelIsLoading",
  "doMissionsPersonnelSave",
  "doMissionsPersonnelDelete",
  "doMissionsPersonnelFetch",
  Personnel
);
