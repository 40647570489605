import xhr from "xhr";
import { createSelector } from "redux-bundler";
import disjoint from "@turf/boolean-disjoint";

export default {
  name: "advisories",

  getReducer: () => {
    const initialState = {
      layer: null,
      data: null,
      url: "https://api.airmap.com/advisory/v1/airspace",
      shouldFetch: false,
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case "ADVISORIES_FETCH_STARTED":
        case "ADVISORIES_FETCH_FINISHED":
        case "ADVISORIES_INITIALIZE_STARTED":
        case "ADVISORIES_INITIALIZE_FINISHED":
          return Object.assign({}, state, payload);
        case "MAP_AOI_BUFFER_AOI":
          return Object.assign({}, state, { shouldFetch: true });
        case "MAP_INITIALIZED":
          return Object.assign({}, state, { shouldInitialize: true });
        default:
          return state;
      }
    };
  },

  doAdvisoriesAddToMap:
    () =>
    ({ dispatch, store }) => {},

  doAdvisoriesInitialize:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "ADVISORIES_INITIALIZE_STARTED",
        payload: { shouldInitialize: false },
      });
      dispatch({
        type: "ADVISORIES_INITIALIZE_FINISHED",
        payload: { layer: null },
      });
    },

  doAdvisoriesFetch:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "ADVISORIES_FETCH_STARTED",
        payload: { shouldFetch: false },
      });
      const url = store.selectAdvisoriesUrl();
      const api_key = store.selectAirmapApiKey();
      // const layers = store.selectAdvisoriesApiLayers();
      const geojson = store.selectMapAoiAsGeoJSON();
      if (geojson.features && geojson.features.length) {
        const geometry = geojson.features[0].geometry;
        xhr.post(
          {
            url: `${url}`,
            headers: {
              accept: "application/json",
              "x-api-key": api_key,
            },
            json: {
              start: new Date().toISOString(),
              end: new Date().toISOString(),
              geometry: geometry,
              rulesets:
                "usa_part_107,usa_sec_91,usa_national_marine_sanctuary,usa_national_park,usa_fish_wildlife_refuge,usa_wilderness_area",
            },
          },
          (err, response, body) => {
            const res = typeof body === "string" ? JSON.parse(body) : body;
            if (res && res.data) {
              dispatch({
                type: "ADVISORIES_FETCH_FINISHED",
                payload: { data: res.data },
              });
            }
          }
        );
      }
    },

  selectAdvisoriesData: (state) => {
    return state.advisories.data;
  },

  selectAdvisoriesDistinct: createSelector("selectAdvisoriesData", (data) => {
    if (!data) return [];
    if (!data.advisories || !data.advisories.length) return [];
    const rules = {};
    const advisories = [];
    data.advisories.forEach((a) => {
      if (!rules.hasOwnProperty(a.rule_id)) {
        rules[a.rule_id] = true;
        advisories.push(a);
      }
    });
    return advisories.sort((a, b) => {
      if (a.color > b.color) return -1;
      if (a.color < b.color) return 1;
      return 0;
    });
  }),

  selectAdvisoriesClassified: createSelector(
    "selectMapAoiAsGeoJSON",
    "selectAdvisoriesData",
    (aoi, data) => {
      if (!data || !data.length) return null;
      const aoiFeature = aoi.features[0];
      if (!aoiFeature) return null;
      const classified = {
        aoi: {},
        other: {},
      };
      data.forEach((d) => {
        const bucket = disjoint(aoiFeature, {
          type: "Feature",
          geometry: d.geometry,
        })
          ? "other"
          : "aoi";
        if (!classified[bucket].hasOwnProperty(d.type))
          classified[bucket][d.type] = [];
        classified[bucket][d.type].push(d);
      });
      return classified;
    }
  ),

  selectAdvisoriesLayer: (state) => {
    return state.advisories.layer;
  },

  selectAdvisoriesApiLayers: (state) => {
    return state.advisories.apilayers;
  },

  selectAdvisoriesUrl: (state) => {
    return state.advisories.url;
  },

  // reactAdvisoriesShouldFetch: state => {
  //   if (state.advisories.shouldFetch)
  //     return { actionCreator: "doAdvisoriesFetch" };
  // },

  // reactAdvisoriesShouldInitialize: state => {
  //   if (state.advisories.shouldInitialize)
  //     return { actionCreator: "doAdvisoriesInitialize" };
  // }
};
