import { countBy, find, groupBy } from "lodash";
import { createSelector } from "redux-bundler";
import { roleAbbrevReverse } from "../modules/_shared/helper";

export default {
  name: "atpmDashboard",
  getReducer() {
    const initialData = {
      _backupAtpms: [],
      _isFetching: false,
      _activeDashboard: [],
      _activeAtpm: {}
    }
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "BACKUPATPM_FETCH_STARTED":
        case "BACKUPATPM_FETCH_FINISHED":
        case "BACKUPATPM_FETCH_ERROR":
        case "ATPMDASHBOARD_FETCH_BYID_STARTED":
        case "ATPMDASHBOARD_FETCH_BYID_ERROR":
        case "ATPMDASHBOARD_FETCH_BYID_FINISHED":
        case "ATPMDASHBOARD_FETCH_BYID_ABORT":
        case "SET_ACTIVE_ATPM":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    }
  },
  doFetchAtpmDashboardById: (id, cb) => ({ store, dispatch, apiGet }) => {
    dispatch({
      type: "ATPMDASHBOARD_FETCH_BYID_STARTED",
      payload: { _isFetching: true }
    })
    if (!store.selectOrgsByRoute()) {
      dispatch({
        type: "ATPMDASHBOARD_FETCH_BYID_ABORT",
        payload: {
          _isFetching: false,
        },
      });
      return;
    }
    apiGet(`/orgs/${store.selectOrgsByRoute().slug}/atpmDashboard/${id}`,
      (err, response, body) => {
        if (err || response.statusCode !== 200) {
          dispatch({
            type: "ATPMDASHBOARD_FETCH_BYID_ERROR",
            payload: {
              _err: { err: err, response: response },
              notification: {
                statusCode: response.statusCode,
              },
            },
          });
        } else {
          const data = JSON.parse(body);
          dispatch({
            type: "ATPMDASHBOARD_FETCH_BYID_FINISHED",
            payload: {
              _isFetching: false,
              _activeDashboard: data,
            },
          });
          cb && cb();
        }
      })
  },
  doFetchByApprovalRole:
    (approval_role_id) =>
      ({ dispatch, store, apiGet }) => {
        dispatch({
          type: "BACKUPATPM_FETCH_STARTED",
          payload: { _isFetching: true },
        });
        apiGet(
          `/orgs/${store.selectOrgsByRoute().slug
          }/approvalroles/${approval_role_id}`,
          (err, response, body) => {
            if (err || response.statusCode !== 200) {
              dispatch({
                type: "BACKUPATPM_FETCH_ERROR",
                payload: {
                  _err: { err: err, response: response },
                  notification: {
                    statusCode: response.statusCode,
                  },
                },
              });
            } else {
              const data = JSON.parse(body);
              // this data includes duplicates if someone is an atpm and a backup, need to remove those here
              let filtered = store
                .selectProfilesByOrg()
                .map((p) => find(data, (obj) => obj.name === p.name))
                .filter((item) => !!item);
              dispatch({
                type: "BACKUPATPM_FETCH_FINISHED",
                payload: {
                  _isFetching: false,
                  _backupAtpms: filtered,
                },
              });
            }
          }
        );
      },

  doSetActiveAtpm: obj => ({ dispatch }) => {
    dispatch({
      type: "SET_ACTIVE_ATPM",
      payload: { _activeAtpm: obj }
    })
  },

  selectActiveAtpm: state => state.atpmDashboard._activeAtpm,
  selectAtpmDashboardItemsActive: state => state.atpmDashboard._activeDashboard,
  selectBackupAtpms: (state) => state.atpmDashboard._backupAtpms,
  selectAtpmDashboardIsFetching: (state) => state.atpmDashboard._isFetching,
  selectAtpmDashboardItemsFull: createSelector(
    "selectReadFileTrackingData",
    'selectReadFileTableData',
    "selectAtpmDashboardItemsActive",
    "selectDomainsItems",
    "selectAircraftItems",
    (trackingData, readFileData, atpmDashboardItemsActive, domains, aircraftItems) => {
      let arr = atpmDashboardItemsActive.map((person) => {
        let profileApprovalRoles = person.approval_roles;
        let markedReadFilesByPerson = groupBy(trackingData, 'keycloak_id');
        markedReadFilesByPerson = markedReadFilesByPerson ? markedReadFilesByPerson[person.keycloak_id] ?? [] : [];
        let items = readFileData.filter(r => !find(markedReadFilesByPerson, { read_file_id: r.id }));
        let countArr = items.filter((item) => {
          let { allow_roles, allow_flight_roles, allow_aircraft } = item;
          if (allow_aircraft) {
            let allowPlatforms = allow_aircraft
              ? JSON.parse(allow_aircraft)
              : ["*"];
            let userInAircraftOrgList = aircraftItems.map(a => a.platform_id).some((item) =>
              allowPlatforms.includes(item)
            ) || allowPlatforms.includes("*");
            // if user is in org with aircraft check other filters
            if (userInAircraftOrgList) {
              if (allow_roles) {
                let fullRoleNames = JSON.parse(allow_roles).map(
                  (role) => roleAbbrevReverse[role]
                );
                return (
                  profileApprovalRoles &&
                  profileApprovalRoles.some((r) => fullRoleNames.includes(r))
                );
              } else if (allow_flight_roles) {
                let allowRoles = allow_flight_roles
                  ? JSON.parse(allow_flight_roles)
                  : ["*"];
                let flightDuties = person.duties;
                flightDuties = flightDuties ? flightDuties.split(".") : [];
                let dutiesNames = domains
                  .filter((d) => flightDuties.includes(d.id))
                  .map((item) => item.val);
                return (
                  (dutiesNames.some((r) => allowRoles.indexOf(r) >= 0) ||
                    allowRoles.includes("*"))
                );
              } else return true; // if no flight roles or approval roles return true
            } else return false; // if there are aircraft limitations, and youre not in it, return false
          } else if (allow_roles) {
            let fullRoleNames = JSON.parse(allow_roles).map(
              (role) => roleAbbrevReverse[role]
            );
            return (
              profileApprovalRoles &&
              profileApprovalRoles.some((r) => fullRoleNames.includes(r))
            );
          } else if (allow_flight_roles) {
            let allowRoles = allow_flight_roles
              ? JSON.parse(allow_flight_roles)
              : ["*"];
            let flightDuties = person.duties;
            flightDuties = flightDuties ? flightDuties.split(".") : [];
            let dutiesNames = domains
              .filter((d) => flightDuties.includes(d.id))
              .map((item) => item.val);
            return (
              (dutiesNames.some((r) => allowRoles.indexOf(r) >= 0) ||
                allowRoles.includes("*"))
            );
          } else return true;
        });
        let initialStatusObject = countBy(countArr, "initial_status");
        if (!initialStatusObject.red) initialStatusObject.red = 0;
        if (!initialStatusObject.yellow) initialStatusObject.yellow = 0;
        return {
          ...person,
          read_file_count: initialStatusObject.red || initialStatusObject.yellow
            ? initialStatusObject.red + initialStatusObject.yellow
            : 0,
        };
      });
      return arr;
    }
  ),
}
