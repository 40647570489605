import React, { useState, useRef, useEffect } from "react";
import { connect } from "redux-bundler-react";
import SchemaForm from "../../../app-components/schema-form/schema-form";
import { profile } from "../../../models";
import Loader from "../../../app-components/loader";
import CheckboxInput from "../../../app-components/schema-form/checkbox-input";
import { keyBy, startCase, sortBy } from "lodash"
const UserInfo = (props) => {
  let {
    tokenExpiresIn,
    profileActiveData,
    profileActiveIsFetching: isFetching,
    forumUserPermissionsIsFetching: permissionsIsFetching,
    forumUserPermissionsByLoggedIn,
    forumUserPermissionsAllExists
  } = props;
  const myProfile = profileActiveData;
  let form = useRef();

  const [permissions, setPermissions] = useState({ fields: { ...keyBy(forumUserPermissionsByLoggedIn, 'forum_name') }, _stateChanged: false })
  const handleSubmit = () => {
    const { doProfileSave, doForumUserPermissionsSaveByLoggedIn } = props;
    if (form.isValid()) {
      const data = form.serialize();
      doProfileSave(data);
      if (permissions._stateChanged || !forumUserPermissionsAllExists) {
        doForumUserPermissionsSaveByLoggedIn(Object.assign({}, { ...permissions.fields }))
      }
    }
  };

  useEffect(() => {
    if (!forumUserPermissionsAllExists) {
      props.doForumUserPermissionsSaveByLoggedIn(Object.assign({}, { ...permissions.fields }))
    }
  }, [])

  useEffect(() => {
    if (forumUserPermissionsByLoggedIn) setPermissions({ ...permissions, fields: { ...keyBy(forumUserPermissionsByLoggedIn, 'forum_name') }, _stateChanged: false })
  }, [JSON.stringify(forumUserPermissionsByLoggedIn)]);


  const renderPermissionsForm = () => {
    return (
      <div>
        <h6 className="row ml-5 mr-0 my-1">
          Notify me about new postings on:
        </h6>
        {sortBy(Object.values(permissions.fields), 'forum_name').map((perm, idx) => {
          let value = permissions.fields[perm.forum_name].allow_notifications
          let readOnly = !myProfile.allow_email && !myProfile.allow_sms
          return (
            <CheckboxInput ref={(el) => permissionFormRefs[idx] = el} key={idx} inline={true} property={perm.forum_name} value={value} prop={perm} schema={{ readOnly: readOnly, title: startCase(perm.forum_name) }}
              onChange={(state) => {
                if (perm.allow_notifications !== state.value) {
                  setPermissions({
                    ...permissions,
                    fields: {
                      ...permissions.fields,
                      [perm.forum_name]: { ...permissions.fields[perm.forum_name], allow_notifications: state.value },
                    },
                    _stateChanged: permissions.fields[perm.forum_name].allow_notifications !== state.value
                  })
                }
              }} />
          )
        })}
      </div>

    )
  }
  let permissionFormRefs = {}
  if (isFetching || permissionsIsFetching)
    return (
      <div className="d-flex align-items-center" style={{ height: 300 }}>
        <Loader opt="dissolving-cube" />
      </div>
    );
  return (
    <div>
      <SchemaForm
        ref={(el) => {
          form = el;
        }}
        inline={true}
        displayOnly={false}
        schema={profile}
        data={myProfile}
      />
      {renderPermissionsForm()}

      <div className="border-top d-flex justify-content-between">
        <span className="text-muted mt-3">{`Current Session Expires in ${tokenExpiresIn}`}</span>
        <button
          onClick={handleSubmit}
          className="btn btn-sm btn-success mt-3"
          type="button"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default connect(
  "doProfileSave",
  "selectProfileItems",
  "selectTokenEdipi",
  "selectTokenRaw",
  "selectTokenExpiresIn",
  "selectProfileActiveData",
  "selectProfileActiveIsFetching",
  "doForumUserPermissionsSaveByLoggedIn",
  "selectForumUserPermissionsByLoggedIn",
  "selectForumUserPermissionsIsFetching",
  "selectForumUserPermissionsAllExists",
  UserInfo
);
