import React from "react";
import { connect } from "redux-bundler-react";
import HazardTableRow from "./hazard-table-row";
import HazardTableNew from "./hazard-table-new";
import { sortBy } from "lodash";
import RoleFilter from "../../../../../app-containers/context-providers/role-filter";
import MissionStatusFilter from "../../../_shared/mission-status-filter";

class HazardTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editItem: null,
    };
    this.addNew = this.addNew.bind(this);
    this.startEditing = this.startEditing.bind(this);
    this.stopEditing = this.stopEditing.bind(this);
  }

  addNew() {
    this.startEditing({
      id: null,
      raw_id: null,
      mission_task_id: null,
      hazard_name: "",
      initial_risk_id: null,
      control: "",
      who: "",
      how: "",
      final_risk_id: null,
    });
  }

  startEditing(editItem) {
    this.setState({
      editItem: editItem,
    });
  }

  stopEditing() {
    this.setState({
      editItem: null,
    });
  }

  render() {
    const {
      missionsRawHazardsItems: hazards,
      missionsRawHazardsResidualRisk,
    } = this.props;
    const { editItem } = this.state;
    const sortedHazards = sortBy(hazards, ["sort_by"]);
    return (
      <div className="clearfix">
        <table className="table table-bordered" style={{ fontSize: "0.8rem" }}>
          <thead>
            <tr>
              <th>Subtask / Substep of Mission</th>
              <th>Hazard</th>
              <th>Initial Risk Level</th>
              <th>Control</th>
              <th>How to implement</th>
              <th>Who will implement</th>
              <th>Residual Risk Level</th>
              <th style={{ minWidth: "116px" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sortedHazards.map((item, i) => {
              return (
                <HazardTableRow
                  key={i}
                  item={item}
                  editItem={editItem}
                  onStartEditing={this.startEditing}
                  onStopEditing={this.stopEditing}
                />
              );
            })}
            <HazardTableNew
              editItem={editItem}
              onStopEditing={this.stopEditing}
            />
          </tbody>
        </table>
        <RoleFilter allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}>
          <MissionStatusFilter
            allowStatus={{
              approval_status: ["draft"],
            }}
          >
            <div className="float-right">
              <span className="pt-1 mr-3">
                Overall Residual Risk: <b>{missionsRawHazardsResidualRisk}</b>
              </span>
              <button
                className="btn btn-outline-primary"
                disabled={!!editItem}
                onClick={this.addNew}
              >
                Add New
              </button>
            </div>
          </MissionStatusFilter>
          <MissionStatusFilter
            allowStatus={{
              approval_status: ["pending approval"],
            }}
          >
            <div className="float-right">
              Overall Residual Risk: <b>{missionsRawHazardsResidualRisk}</b>
            </div>
          </MissionStatusFilter>
        </RoleFilter>
      </div>
    );
  }
}

export default connect(
  "selectMissionsRawHazardsItems",
  "selectMissionsRawHazardsResidualRisk",
  HazardTable
);
