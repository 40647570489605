import { createSelector } from "redux-bundler";
import createRestBundle from "./create-rest-bundle";
export default createRestBundle({
  name: "mms",
  uid: "slug",
  prefetch: true,
  staleAfter: 30000,
  persist: false,
  routeParam: "mmsSlug",
  getTemplate: "/orgs/:orgSlug/mms",
  putTemplate: "/orgs/:orgSlug/mms/:item.slug",
  postTemplate: "/orgs/:orgSlug/mms",
  deleteTemplate: "/orgs/:orgSlug/mms/:item.slug",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN", "TRANSFERS_SAVE_FINISHED"],
  forceFetchActions: ["MMS_SAVE_FINISHED"],
  initialData: {
    _userIsATPM: false,
  },
  reduceFurther: (state, { type, payload }) => {
    switch (type) {
      case "MMS_CHECK_USER_ROLES":
        return Object.assign({}, state, { ...payload, _shouldFetch: true });
      case "URL_UPDATED":
        return Object.assign({}, state, { _shouldFetchStats: true });
      default:
        return state;
    }
  },
  addons: {
    doMmsCheckUserApprovalRoles:
      () =>
      ({ dispatch, store, apiGet }) => {
        const approvalRolesByLoggedIn = store.selectApprovalRolesByLoggedIn();
        const domainsATPM = store.selectDomainsATPM();
        dispatch({
          type: "MMS_CHECK_USER_ROLES",
          payload: {
            _userIsATPM: approvalRolesByLoggedIn.includes(domainsATPM.id),
          },
        });
      },
    selectMmsItemsNotInConfiguration: createSelector(
      "selectMmsItems",
      "selectMissionsConfigurationsActiveRow",
      (mmsItems, missionsConfigurationsActiveRow) => {
        if (
          missionsConfigurationsActiveRow &&
          missionsConfigurationsActiveRow.mms_ids
        ) {
          return mmsItems.filter(
            (a) =>
              !missionsConfigurationsActiveRow.mms_ids.split(".").includes(a.id)
          );
        } else return mmsItems;
      }
    ),
  },
});
