import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";

class Container extends React.Component {
  render() {
    const {
      dialogContent,
      doDialogClose,
      dialogProps,
      dialogSize,
      nestedDialogContent,
      nestedDialogProps,
    } = this.props;
    if (!dialogContent && !nestedDialogContent) return null;
    let dProps = dialogProps ? dialogProps : nestedDialogProps;
    const Content = dialogContent;
    const NestedContent = nestedDialogContent;
    const dialogCls = classnames({
      "modal-dialog": true,
      "modal-dialog-scrollable": dProps.scrollable,
      "modal-sm": dialogSize === "sm",
      "modal-lg": dialogSize === "lg",
      "modal-xl": dialogSize === "xl",
    });
    return (
      <>
        <div
          onClick={doDialogClose}
          className="modal fade show"
          style={{
            display: "block",
            backgroundColor: "#ccc",
            opacity: 0.5,
            overflow: "auto",
          }}
        ></div>
        <div
          className="modal fade show"
          style={{ display: "block", overflow: "auto" }}
        >
          {nestedDialogContent && (
            <div className={dialogCls}>
              <NestedContent {...nestedDialogProps} />
            </div>
          )}
          {dialogContent && (
            <div className={dialogCls}>
              <Content {...dialogProps} />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default connect(
  "doDialogClose",
  "selectDialogContent",
  "selectDialogProps",
  "selectNestedDialogContent",
  "selectNestedDialogProps",
  "selectDialogSize",
  Container
);
