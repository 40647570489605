import React from "react";
import { connect } from "redux-bundler-react";

class OrgListItem extends React.Component {
  render() {
    const { org, orgsForUser } = this.props;
    if (!orgsForUser) return null;
    const isMember = orgsForUser.indexOf(org) !== -1;
    const hasRequested = org.has_requested;
    const theme = isMember ? "success" : hasRequested ? "primary" : "secondary";
    return (
      <div className="col-sm-6 col-lg-3 mb-4">
        <div className="card h-100">
          <div className="card-body p-3 d-flex align-items-center">
            <i className={`fa fa-cogs bg-${theme} p-4 font-2xl mr-3`}></i>
            <div>
              <div className={`text-value-sm text-${theme} pb-0`}>
                {org.name}
              </div>
              <div className="text-muted text-uppercase font-weight-bold small">
                {org.alias.split(",")[0]}
              </div>
            </div>
          </div>
          <div className="card-footer px-3 py-2">
            <a
              className="btn-block text-muted d-flex justify-content-between align-items-center"
              href={`/${org.slug}`}
            >
              <span className="small font-weight-bold">Open</span>
              <i className="mdi mdi-chevron-right"></i>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default connect("selectOrgsForUser", OrgListItem);
