import { sortBy } from "lodash";
import React from "react";
import { connect } from "redux-bundler-react";

const AdjudicationsList = ({ items = [], overrideClass, doFileIoDownload }) => {
  let list = sortBy(items, "create_date");
  return (
    <div className={overrideClass ? overrideClass : "mt-4"}>
      <h6 className="alert-subheading">Past Adjudications</h6>
      {list &&
        list.map((adj, i) => {
          return (
            <div key={i}>
              <div>
                <small>
                  <strong>
                    <i className={`mdi mdi-alert-rhombus text-danger mr-1`}></i>
                    {`${new Date(adj.last_updated_date).toLocaleString()}`}
                  </strong>
                </small>
              </div>
              <div className="d-flex align-items-center mb-3">
                {adj.note && (
                  <p
                    className="pl-3 m-0"
                    dangerouslySetInnerHTML={{ __html: adj.note }}
                    style={{ whiteSpace: "pre-wrap" }}></p>
                )}
                {adj.filename && (
                  <div
                    className={`d-flex pl-${
                      adj.note ? "2" : "0"
                    } align-items-center`}
                    style={{ cursor: "pointer" }}
                    onClick={() => doFileIoDownload(adj.s3_key)}>
                    <i className="mdi mdi-file mdi-18px text-info" />
                    <p className="my-0 ml-1 text-muted">
                      <small>{adj.filename}</small>
                    </p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default connect(
  "selectMissionsAdjudicationsItems",
  "doFileIoDownload",
  AdjudicationsList
);
