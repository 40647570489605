import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import classnames from "../../../utils/classnames";
import Widget from "../_shared/widget";
import APIDataLayer from "../_shared/api-data-layer";
import PersonnelList from "../../_shared/personnel-list";

const OrgRegion = ({ orgName, items, expanded }) => {
  const [exp, setExpanded] = useState(!!expanded);

  const toggleExpanded = () => {
    setExpanded(!exp);
  };

  const iconClass = classnames({
    mdi: true,
    "mdi-plus-box": !exp,
    "mdi-minus-box": exp,
    "pr-2": true
  });

  const renderList = () => {
    if (!exp) return null;
    return <PersonnelList items={items} />;
  };

  return (
    <div>
      <div className="mb-1">
        <strong onClick={toggleExpanded}>
          <i className={iconClass}></i>
          {orgName}
        </strong>
      </div>
      {renderList()}
    </div>
  );
};

const OrgGroup = connect("selectOrgsByRoute", ({ items, orgsByRoute: org }) => {
  const itemsByOrg = {};
  items.forEach(i => {
    if (i.approval_role) {
      if (!itemsByOrg.hasOwnProperty(i.org_name)) itemsByOrg[i.org_name] = {};

      const org = itemsByOrg[i.org_name];
      if (!org.hasOwnProperty(i.id)) org[i.id] = {};

      const person = org[i.id];
      if (!person.hasOwnProperty("approval_roles")) person.approval_roles = [];
      Object.assign(person, i);
      person.approval_roles.push(i.approval_role);

      let uniqueChars = [...new Set(person.approval_roles)];
      person.approval_roles = uniqueChars;
      delete person.approval_role;
    }
  });

  Object.keys(itemsByOrg).forEach(key => {
    itemsByOrg[key] = Object.values(itemsByOrg[key]);
  });

  return (
    <div>
      <OrgRegion
        orgName={org.name}
        items={itemsByOrg[org.name]}
        expanded={true}
      />
      {Object.keys(itemsByOrg)
        .sort((a, b) => {
          if (a > b) return 1;
          if (a < b) return -1;
          return 0;
        })
        .map((key, i) => {
          if (key === org.name) return null;
          return <OrgRegion key={i} orgName={key} items={itemsByOrg[key]} />;
        })}
    </div>
  );
});

export default connect("selectOrgsByRoute", ({ orgsByRoute: org }) => {
  if (!org) return null;
  return (
    <Widget>
      <strong>Approval Personnel</strong>
      <APIDataLayer
        path={`/orgs/${org.slug}/personnel/dashboard?rollup=true`}
        component={OrgGroup}
      ></APIDataLayer>
    </Widget>
  );
});
