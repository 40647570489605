import React from "react";

export default ({ title, children }) => {
  const renderHeader = () => {
    if (!title) return null;
    return <div className="card-header">{title}</div>;
  };

  return (
    <div className="card">
      {renderHeader()}
      <div className="card-body">{children}</div>
    </div>
  );
};
