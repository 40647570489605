import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "missionsLz",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/missions/:missionsSlug/lz",
  putTemplate: "/orgs/:orgSlug/missions/:missionsSlug/lz/:item.id",
  postTemplate: "/orgs/:orgSlug/missions/:missionsSlug/lz",
  deleteTemplate: "/orgs/:orgSlug/missions/:missionsSlug/lz/:item.id",
  fetchActions: ["AUTH_LOGGED_IN"],
  forceFetchActions: ["URL_UPDATED"],
});
