
// Answer options for location fields
const siteLocationOptions = [
    { label: "Not Specified", value: "Not Specified" },
    { label: "Navigation/Sidebar", value: "Navigation/Sidebar" },
    { label: "Reference Library", value: "Reference Library" },
    { label: "Account Mgmt", value: "Account Mgmt" },
    { label: "Org. - General", value: "Org. - General" },
    { label: "Org. - Dashboard", value: "Org. - Dashboard" },
    { label: "Org. - Equipment/Personnel", value: "Org. - Equipment/Personnel" },
    { label: "Mission Planning", value: "Mission Planning" },
    { label: "Mission Planning - AOI Map", value: "Mission Planning - AOI Map" },
    { label: "Mission Planning - Risk Assessment", value: "Mission Planning - Risk Assessment" },
    { label: "Reports - Equipment/Personnel/Missions", value: "Reports - Equipment/Personnel/Missions" }
]




// Every possible field to be rendered in the help-dialog. Designed so that more can be added.
export const allFields = {
    "browser": {
        forTypes: "*", display_order: 0, name: "browser", label: "Browser", title: "Browser", options: [
            { label: "Google Chrome (recommended)", value: "Google Chrome" },
            { label: "Mozilla Firefox", value: "Mozilla Firefox" },
            { label: "Microsoft Edge", value: "Microsoft Edge" },
            { label: "Internet Explorer", value: "Internet Explorer" },
        ]
    },
    "importance": {
        forTypes: "*", display_order: 1, name: "importance", label: "Importance", title: "Importance", options: [
            { label: "Very Low", value: "Very Low" },
            { label: "Low", value: "Low" },
            { label: "Moderate", value: "Moderate" },
            { label: "High", value: "High" },
            { label: "Very High", value: "Very High" },
        ]
    },
    "category": {
        forTypes: "question", display_order: 0, name: "category", label: "Categories", title: "Categories", options: [
            { label: "Not Specified", value: "Not Specified" },
            { label: "Looking for...", value: "Looking for..." },
            { label: "Access Request", value: "Access Request" },
        ]
    },
    "location": {
        forTypes: "question,issue", display_order: 1, name: "location", label: "Location", title: "Location", options: siteLocationOptions
    },
    "issueType": {
        forTypes: "issue", display_order: 0, name: "issueType", label: "Type of Issue", title: "Type of Issue", options: [
            { label: "Not Specified", value: "Not Specified" },
            { label: "Typo/Misspelling", value: "Typo/Misspelling" },
            { label: "Unable to see content", value: "Unable to see content" },
            { label: "Page Crashed", value: "Page Crashed" },
        ]
    },
    "aoi": {
        forTypes: "recommendation", display_order: 0, name: "aoi", label: "Area of Interest", options: siteLocationOptions
    }
}


export const requestTypeOptions = [
    { label: "Question", value: "question" },
    { label: "Issue", value: "issue" },
    { label: "Recommendation", value: "recommendation" },
]

// Field Types ( *, Question, Issue, Recommendation), * represents global
export const formTemplates = {
    "*": {
        value: "*",
        name: "*",
        label: "(Not Specified)",
        defaultAnswers: {
            browser: "notspecific", importance: "notspecific"
        }
    },
    "question": {
        value: "question",
        name: "question",
        label: "Question",
        defaultAnswers: {
            category: "notspecific", location: "notspecific"
        }
    },
    "issue": {
        value: "issue",
        name: "issue",
        label: "Issue",
        defaultAnswers: {
            issueType: "notspecific", location: "notspecific"
        }
    },
    "recommendation": {
        value: "recommendation",
        name: "recommendation",
        label: "Recommendation",
        defaultAnswers: {
            aoi: "notspecific"
        }
    }
}