import React from "react";

class DomainValueEditor extends React.Component {
  constructor(props) {
    super(props);
    const domain = Object.assign(
      {},
      {
        id: null,
        grp: props.grp || null,
        val: "",
        tooltip: "",
        display_order: 0
      },
      props.domain
    );
    this.state = domain;
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  handleSave(e) {
    const { onSave } = this.props;
    onSave(this.state);
  }

  handleCancel() {
    const { onCancel } = this.props;
    onCancel();
  }

  handleChange(e) {
    const key = e.target.dataset.key;
    this.setState({
      [key]: e.target.value
    });
  }

  handleKeyUp(e) {
    if (e.keyCode === 13) this.handleSave();
  }

  render() {
    const { val, display_order, tooltip } = this.state;
    return (
      <li className="list-group-item">
        <div className="row">
          <div className="col-md-2">
            <input
              onChange={this.handleChange}
              data-key="display_order"
              value={display_order}
              type="number"
              className="form-control"
              placeholder="Display Order..."
            ></input>
          </div>
          <div className="col-md-3">
            <input
              onChange={this.handleChange}
              data-key="tooltip"
              value={tooltip || ""}
              type="text"
              className="form-control"
              placeholder="Tooltip..."
            ></input>
          </div>
          <div className="col-7">
            <div onKeyUp={this.handleKeyUp} className="input-group">
              <input
                onChange={this.handleChange}
                data-key="val"
                value={val}
                type="text"
                className="form-control"
                placeholder="Picklist value..."
              ></input>
              <span className="input-group-append">
                <button
                  onClick={this.handleCancel}
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button onClick={this.handleSave} className="btn btn-primary">
                  Save
                </button>
              </span>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default DomainValueEditor;
