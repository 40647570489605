import React, { useState } from "react";
import { connect } from "redux-bundler-react";

import { ItemBoardQueryControls, ItemBoardQueryStatusBar, ItemBoardQuerySearchBar } from "../_shared/item-board/item-board"
import MessageNew from "./message-new/message-new-dialog";

const sortTextTranslations = {
    'relevant': 'Most Active',
    'posted_date': 'New',
    'rating': 'Rating',
    'comment_count': 'Most Discussed'
}

const MessageQueryPanel = ({ messagesForum, messagesState, doMessagesUpdateQueryString, messagesFlags, doDialogOpen }) => {
    const [state, setState] = useState({
        forum: messagesForum,
        _includeComments: messagesState._includeComments,
        _sortDir: messagesState._sortDir,
    })
    const updateQueryField = (update) => {
        doMessagesUpdateQueryString(update)
    }

    const createNewMessageBtnClicked = () => {
        doDialogOpen({ content: MessageNew, props: { state: messagesState }, size: 'lg' });
    }

    const handleIncludeCommentsChecked = (e) => {
        doMessagesUpdateQueryString({ _includeComments: e.currentTarget.checked })
    }

    let categoryOptions = [
        { update: { _filterByType: 'none' }, icon: "mdi mdi-close", text: "None", handler: updateQueryField, displayValue: 'none', exitOnClick: true },
        { update: { _filterByType: 'General' }, icon: "mdi mdi-message-outline", text: "General", displayValue: "General", handler: updateQueryField, exitOnClick: true },
        { update: { _filterByType: 'Inquiry' }, icon: "mdi mdi-help-circle-outline", text: "Inquiry", displayValue: "Inquiry", handler: updateQueryField, exitOnClick: true },
        { update: { _filterByType: 'Alert' }, icon: "mdi mdi-alert-outline", text: "Alert", displayValue: "Alert", handler: updateQueryField, exitOnClick: true },
    ]

    let sortOptions = [
        { update: { _sortBy: 'relevant' }, icon: "mdi mdi-update", text: "Most Active", handler: updateQueryField, displayValue: 'relevant', exitOnClick: true },
        { update: { _sortBy: 'posted_date' }, icon: "mdi mdi-update", text: "New", handler: updateQueryField, displayValue: 'posted_date', exitOnClick: true },
        { update: { _sortBy: 'rating' }, icon: "mdi mdi-thumbs-up-down", text: "Rating", handler: updateQueryField, displayValue: 'rating', exitOnClick: true },
        { update: { _sortBy: 'comment_count' }, icon: "mdi mdi-message-outline", text: "Most Discussed", handler: updateQueryField, displayValue: 'comment_count', exitOnClick: true },
    ]

    const renderPrimaryControls = () => {
        return (
            <div className="clearfix d-flex flex-row">
                <button className="btn btn-primary mr-2" type="button" onClick={createNewMessageBtnClicked}>
                    <i className="mdi mdi-open-in-new mr-lg-2" />
                    <span className="d-lg-inline d-none">Create Message</span>
                </button>
            </div>
        )
    }
    const searchBar = {
        placeholder: `Search ${messagesForum} messages ...`,
        searchDropdownTitle: "Filter",
        searchDropdownOptions: categoryOptions,
    }

    let queryStatusParams = [{ label: "Filter", name: "_filterByType", undoVal: "none" }];
    let queryControlsProps = { renderPrimaryControls, sortOptions, flags: messagesFlags, bundleState: messagesState, doItemsUpdateQueryString: doMessagesUpdateQueryString }
    let queryStatusBarProps = { doItemsUpdateQueryString: doMessagesUpdateQueryString, queryStatusParams, sortTextTranslations, bundleState: messagesState, }

    return (
        <>
            <ItemBoardQuerySearchBar {...queryControlsProps} searchBar={searchBar}>
                <div className="px-1" style={{ position: "relative", marginLeft: "5rem" }}>
                    <div className="d-inline mr-2">Include comments</div>
                    <input className="p-1 align-middle" type="checkbox" value={state._includeComments} onClick={handleIncludeCommentsChecked}></input>
                </div>
            </ItemBoardQuerySearchBar>
            <ItemBoardQueryControls {...queryControlsProps}>
                <h5 className="d-block text-truncate">
                    {messagesForum === 'SHARED' ? "You are viewing messages accessible to all MARS organizations" : `You are viewing messages exclusive to ${messagesForum} organization`}
                </h5>
            </ItemBoardQueryControls>
            <ItemBoardQueryStatusBar {...queryStatusBarProps} />
        </>
    )
}

export default connect(
    "doDialogOpen",
    "selectMessagesFlags",
    "doMessagesUpdateQueryString",
    "selectMessagesForum",
    "selectMessagesState",
    MessageQueryPanel
)