import React from "react";
import { connect } from "redux-bundler-react";

const PlatformOrgFilter = ({
  platformsIdByOrgProfile,
  allowPlatforms,
  children,
  Alt,
  ...props
}) => {
  let found =
    platformsIdByOrgProfile.some((item) => allowPlatforms.includes(item)) ||
    allowPlatforms.includes("*");

  if (found) return <>{children}</>;
  else if (Alt) return <Alt {...props} />;
  else return null;
};

export default connect("selectPlatformsIdByOrgProfile", PlatformOrgFilter);
