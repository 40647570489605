import React from "react";

class ToastPrompt extends React.Component {
    constructor(props) {
        super(props);
        let responseMap = props.choices.map(choice => [choice.text, choice.contentAfter])
        this.state = {
            bodyText: props.text,
            choiceResults: Object.fromEntries(new Set(responseMap)),
            showLayer: props.stage
        }
        this.onChoice = this.onChoice.bind(this)
    }

    onChoice(e) {
        const { _onChange, numLayers } = this.props
        this.setState({ bodyText: this.state.choiceResults[e.target.value], showLayer: (this.state.showLayer + 1) % numLayers })
        _onChange(e.target.value)

    }
    render() {
        const { choices, centered, mainIcon } = this.props

        return (
            <div className={`toast show w-auto h-auto mt-3 ${centered ? "mx-auto" : ""}`}>

                <div className="toast-body">
                    <span className={`${mainIcon}`} />
                    <strong className="mr-auto">{this.state.bodyText}</strong>
                    <div className="clearfix d-inline ml-2">
                        {choices.map((obj, idx) => {
                            return (
                                <button key={idx} className={`btn btn-sm bg-${obj.color} ml-2 ${obj.layer == this.state.showLayer ? "" : "d-none"}`} style={{ pointerEvents: "all", width: "fit-content" }} onClick={this.onChoice} value={obj.text}>
                                    {obj.text}
                                </button>
                            )
                        })}
                        <button type="button" className="ml-2 mb-1 close float-right" data-dismiss="toast" onClick={e => { e.currentTarget.parentElement.parentElement.parentElement.classList.remove("show") }}>
                            <span>&times;</span>
                        </button>

                    </div>

                </div>
            </div>
        )

    }
}
export default ToastPrompt;