import React, { useEffect, useState, useRef } from "react";
import { connect } from "redux-bundler-react";
import ComponentTree from "./component-tree";
import "./documentation.css";
const DocumentationBody = ({ documentationState, doDocumentationChangePage, doDocumentationUpdateState, documentationCurrentPage: currentPage }) => {
  const bodyRef = useRef();
  const [atBottom, setAtBottom] = useState(false);
  const { prevPageKey, nextPageKey, page, body, pages } = documentationState;
  const onScroll = (e) => {
    let container = e.currentTarget;
    let offsetTop = container.offsetTop;
    let atBottom = Math.abs(container.scrollHeight - container.clientHeight - container.scrollTop) <= 1;
    document.querySelectorAll(".section-item").forEach(section => {
      let boundingRect = section.getBoundingClientRect();
      if (section.id) {
        let navItem = document.getElementsByName(section.id)[0];
        let itemInView = boundingRect.top - 30 <= offsetTop && offsetTop <= boundingRect.bottom -30 ;
        if (atBottom) {
          document.querySelectorAll(".sub-nav-item button").forEach(link => {
            link.classList.remove("selected");
          })
        }
        else if (navItem && itemInView) {
          navItem.classList.add("selected");
          doDocumentationUpdateState({ focusedSectionKey: section.id });
        }
        else if (navItem) navItem.classList.remove("selected");
        setAtBottom(atBottom);
      }
    })
  }
  const onMouseEnterSection = e => {
    if (atBottom) {
      let navItem = document.getElementsByName(e.currentTarget.id)[0];
      navItem.classList.add("selected");
    }
  }
  const onMouseLeaveSection = e => {
    if (atBottom) {
      let navItem = document.getElementsByName(e.currentTarget.id)[0];
      navItem.classList.remove("selected");
    }
  } 
  const onPageChange = (e) => {
    if (e.currentTarget.name === "prevBtn") {
      doDocumentationChangePage(prevPageKey);
    }
    else if (e.currentTarget.name === "nextBtn") {
      doDocumentationChangePage(nextPageKey);
    }
  }
  useEffect(() => {
    if (currentPage) {
      const { focusedSectionKey } = documentationState;
      let focusedElement = document.getElementById(focusedSectionKey);
      if (focusedElement) {
        focusedElement.scrollIntoView();
      }
    }
    if (bodyRef.current) {
      setAtBottom(Math.abs(bodyRef.current.scrollHeight - bodyRef.current.clientHeight - bodyRef.current.scrollTop) <= 1);
    }
  }, [currentPage]);

  useEffect(() => {
    document.querySelectorAll('.redirect-button').forEach(button => {
      button.addEventListener("click", (e) => {
        doDocumentationChangePage(e.currentTarget.name);
      });
    })
  }, []);

  return (
    <div className="main documentation-body" ref={bodyRef} onScroll={onScroll}>
      <ul className="list-group h-100">
        <li className="list-group-item section-item">
          <ComponentTree obj={page} />
        </li>
        {
          page.sections && page.sections.map((section, idx) =>
            <li id={section} className="list-group-item section-item border-top" key={idx}
              onMouseEnter={onMouseEnterSection}
              onMouseLeave={onMouseLeaveSection}
            >
              <ComponentTree obj={body[section]} />
            </li>
          )
        }
        <li className="list-group-item h-100">
          <div className="row my-4">
            <div className="col">
              {prevPageKey  && <button className="btn btn-lg btn-link d-flex flex-column" name="prevBtn" onClick={onPageChange} disabled={!prevPageKey}>
                <span>Previous Page:</span>
                <span style={{ fontSize: "smaller" }}>{pages[prevPageKey].title}</span>
              </button>}
            </div>
            <div className="col-4 justify-content-end d-flex">
              {nextPageKey  && <button className="btn btn-lg btn-link d-flex flex-column" name="nextBtn" onClick={onPageChange} disabled={!nextPageKey}>
                <span>Next Page:</span>
                <span style={{fontSize:"smaller"} }>{pages[nextPageKey].title}</span>
              </button>}
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
export default connect(
  "doDocumentationUpdateState",
  "doDocumentationChangePage",
  "selectDocumentationState",
  "selectDocumentationCurrentPage",
  DocumentationBody
);