import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import TextInput from "../../../../../app-components/schema-form/text-input";
import SelectInput from "../../../../../app-components/schema-form/select-input";
import ReactSelect from "react-select"
import PreparedBySigItem from "../../../../../modules/missions/details/approvals-tab/prepared-by-sig-item";
import { every,find } from "lodash";
import GradeSlipEntryTable from "./grade-slip-entry-table";
import { defaultGradeSlip } from "./grade-slip";
import PrintButton from "../../../../../app-components/print-button/print-button";
const formHeaders = [
    { title: "Authority", description: "33 USC § 576c, Corps of Engineers Operation of Small Unmanned Aircraft Systems; AR 95-1, Flight Regulations; USACE Aviation Policy Letter 95-1-1" },
    { title: "Principal Purpose", description: "To record Small Unmanned Aircraft System Crewmember performance during evaluation and training events." },
    { title: "Routine Uses", description: "This form will controlled by the FOA ATPM and stored electronically in the MIS for Aviation and Remote Systems (MARS) as part of each crewmember’s Flight Training Folder. ATPMs may also retain hard copies of this and all FTF forms." },
    { title: "Disclosure", description: "Voluntary; this form is not intended for use in personnel actions outside of SUAS Crewmember assignments and designations." }
];

const GradeSlipForm = ({
    routeInfo,
    gradeSlipLinkedMission,
    gradeSlipEntryItemsPrintable,
    onBeforeFormClose,
    onFormSave,
    doGradeSlipUpdateState,
    gradeSlipSelectedForm,
    gradeSlipAllTypes,
    gradeSlipSelectedFormLastSave,
    gradeSlipIsSigning: _isSigning,
    gradeSlipIsLoading: _isLoading,
    gradeSlipIsSaving: _isSaving,
    doGradeSlipSave,
    isProfileActiveAtpm,
    gradeSlipEditedEntries,
    doGradeSlipAtpmModifySign,
    doGradeSlipUpdateFormViewed,
    gradeSlipSelectableMissions : missions
}) => {
    const { atpm_sig, name, summary, foa, event, overall_grade, suac_debrief_complete, additional_training, mission_id } = gradeSlipSelectedForm;
    const disabled = atpm_sig && atpm_sig.length > 0 || !isProfileActiveAtpm;
    const onSave = async () => {
      await onFormSave({ ...gradeSlipSelectedForm });
    }
    const onApprovalChange = async ({remove}) => {
        await doGradeSlipAtpmModifySign({...gradeSlipSelectedForm, removeSig:remove}, (result) => {
          doGradeSlipSave(Object.assign({}, { ...gradeSlipSelectedForm, atpm_sig: result.sig }));
        });
    }

    const onInputComponentChange = (input) => {
        doGradeSlipUpdateState({ _selected: { ...gradeSlipSelectedForm, [input.property]: input.value } })
    }
    const onMissionSelectChange = (input) => {
      if (input) onInputComponentChange(input)
      else onInputComponentChange({ property: "mission_id", value: null })
    }
    const onOverallGradeBoxClicked = (e) => {
      doGradeSlipUpdateState({
        _selected: {
          ...gradeSlipSelectedForm,
          overall_grade: e.currentTarget.name,
          overall_grade_sat: e.currentTarget.name === 'sat',
          overall_grade_unsat: e.currentTarget.name === 'unsat',
          overall_grade_na: e.currentTarget.name === 'na'
        }
      })
    }
    const onYesNoBoxClicked = (e) => {
        let [attr, value] = e.currentTarget.name.split('-');
        doGradeSlipUpdateState({
          _selected: {
            ...gradeSlipSelectedForm,
            [attr]: value,
            [attr + '_yes']: value === 'yes',
            [attr + '_no']: value === 'no'
          }
        });
    }

    const renderAtpmSig = () => {
        return (
            <div className="d-inline-flex flex-column border-top pt-3 w-100">
                <h5>ATPM Signature:</h5>
                {_isLoading || _isSaving || _isSigning ?
                    <div className="spinner-border text-primary" />
                    : <PreparedBySigItem title="" item={{}} sig={atpm_sig} onSign={onApprovalChange} disabled={Object.keys(gradeSlipEditedEntries).length > 0} />
                }
            </div>
        )
    }

    const renderUnsavedTag = () => {
        let properties = ['name', 'event', 'mission_id', 'foa', 'summary', 'overall_grade', 'suac_debrief_complete', 'additional_training'];
        let saved = !gradeSlipSelectedFormLastSave || every(properties.map(p => gradeSlipSelectedForm[p] === gradeSlipSelectedFormLastSave[p]))
        if(saved) return null;
        else {
          return <small className="ml-3 text-info">
            <span className="mdi mdi-alert mr-1" style={{ fontSize: "larger" }} />
            Fields have been edited
          </small>
        } 
    }

    useEffect(() => {
      return () => {
        if (!isProfileActiveAtpm) {
          doGradeSlipUpdateFormViewed(gradeSlipSelectedForm);
        }
      }
    }, []);
    const backBtnClicked = (e) => {
      doGradeSlipUpdateState({
        _selected: defaultGradeSlip,
        _editedEntries: {},
        _entriesLastSave: {},
      });
    }
    
    return (
      <>
        <div className="row mb-2 mt-0">
          <div className="col-2 mr-auto">
            <button className="btn btn-sm btn-outline-primary mb-auto mt-0" onClick={backBtnClicked}>
              <span className="mdi mdi-arrow-left-circle-outline mr-2"  />
              Back
            </button>
          </div>
          <div className="col-auto">
            {!routeInfo.url.includes("/profile") && (
                <button
                  className="btn btn-sm btn-outline-primary mb-auto mt-0 ml-auto mr-2"
                  disabled={!atpm_sig || !isProfileActiveAtpm}
                  onClick={() => onApprovalChange({remove:true})}
                >
                  <span className="mdi mdi-lock-open-variant-outline mr-2" />
                  Unlock Form
                </button>
            )}
            <PrintButton className="btn btn-sm mb-auto mt-0 mr-0 ml-auto"
              state={{ ...gradeSlipSelectedForm, mission_name: gradeSlipLinkedMission ? gradeSlipLinkedMission.name : '', rows: gradeSlipEntryItemsPrintable }}
              options={[{ resultFileName: "Grade Slip", file: "grade-slip.template.json" }]}
            >
              <i className="mdi mdi-printer mr-2" />
              Fill & Print Grade Slip
            </PrintButton>
            </div>
          </div>
          <div className="col-sm border-bottom">
              {formHeaders.map((h, idx) => {
                  return (
                      <div key={idx} className="d-inline-flex py-2">
                          <p className="mr-2 text-nowrap">
                              <strong>{h.title}</strong>
                          </p>
                          <p className="m-0">
                              {h.description}
                          </p>
                      </div>
                  )
              })}
          </div>

          <div className="col-sm mt-2">
              <div className="position-absolute w-100 mr-3 pr-3 text-right float-right">
                  {renderUnsavedTag()}
              </div>
              <TextInput value={summary} property="summary" placeholder='Enter Summary...' schema={{ title: "Summary" }} onChange={(input) => onInputComponentChange(input)} displayOnly={disabled} />
          </div>
          <div className="col-sm">
            <div className="form-group">
              <label>Mission</label>
              <ReactSelect
                options={missions}
                defaultValue={find(missions, ['value', mission_id])}
                isClearable={true}
                isDisabled={disabled}
                onChange={onMissionSelectChange} />
            </div>
          </div>

          <div className="container">
              <div className="row mt-2">
                  <div className="col-sm">
                      <TextInput value={name} property="name" placeholder='Enter Name...' schema={{ title: "Name" }} onChange={(input) => onInputComponentChange(input)} displayOnly={disabled} />
                  </div>
                  <div className="col-sm">
                      <TextInput value={foa} property="foa" placeholder='Enter FOA...' schema={{ title: "FOA" }} onChange={(input) => onInputComponentChange(input)} displayOnly={disabled} />
                  </div>
                  <div className="col-sm">
                      <SelectInput value={event} property="event" options={Object.values(gradeSlipAllTypes)} schema={{ title: "Event" }} onChange={(input) => onInputComponentChange(input)} displayOnly={disabled} />
                  </div>
              </div>
              <GradeSlipEntryTable disabled={disabled} />
              <div className="row no-gutters">
                  <div className="col-12 col-lg-7 mb-2 mb-lg-0">
                      <div className="row">
                          <div className="col-lg-3 col-8 pr-0 ml-lg-0">
                              <strong>Overall Grade:</strong>
                          </div>
                          <div className="col-lg-9 col-4">
                            <div className="row flex-column flex-lg-row">
                              <div className="col-lg-3 pl-0 d-flex d-lg-block justify-content-lg-start justify-content-end">
                                <span>Satisfactory</span>
                                <input className="mb-2 ml-2" name="sat" type="checkbox" style={{ transform: "scale(1.5)" }}
                                  checked={overall_grade === 'sat'}
                                  value={overall_grade === 'sat'}
                                  onChange={onOverallGradeBoxClicked}
                                  disabled={disabled}
                                />
                              </div>
                              <div className="col-lg-4 pl-0 d-flex d-lg-block justify-content-lg-start justify-content-end">
                                <span>Unsatisfactory</span>
                                <input className="mb-2 ml-2" name="unsat" type="checkbox" style={{ transform: "scale(1.5)" }}
                                  checked={overall_grade === 'unsat'}
                                  value={overall_grade === 'unsat'}
                                  onChange={onOverallGradeBoxClicked}
                                  disabled={disabled}
                                />
                              </div>
                              <div className="col-lg-2 pl-0 d-flex d-lg-block justify-content-lg-start justify-content-end">
                                <span>N/A</span>
                                <input className="mb-2 ml-2" name="na" type="checkbox" style={{ transform: "scale(1.5)" }}
                                  checked={!overall_grade || overall_grade === 'na'}
                                  value={!overall_grade || overall_grade === 'na'}
                                  onChange={onOverallGradeBoxClicked}
                                  disabled={disabled}
                                />
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-12 col-lg-5">
                      <div className="row">
                          <div className="col-lg-5 col-8 pr-0 ml-lg-0">
                              <strong>SUAC Debrief Complete:</strong>
                          </div>
                          <div className="col-lg-7 col-4">
                            <div className="row flex-column flex-lg-row">
                              <div className="col-lg-2 pl-0 d-flex d-lg-block justify-content-lg-start justify-content-end mx-lg-2 px-lg-0">
                                <span>Yes</span>
                                <input className="mb-2 ml-2" name="suac_debrief_complete-yes" type="checkbox" style={{ transform: "scale(1.5)" }}
                                    checked={suac_debrief_complete === 'yes'}
                                    value={suac_debrief_complete === 'yes'}
                                    onChange={onYesNoBoxClicked}
                                    disabled={disabled}
                                />
                              </div>
                              <div className="col-lg-2 pl-0 d-flex d-lg-block justify-content-lg-start justify-content-end mx-lg-2 px-lg-0">
                                <span>No</span>
                                <input className="mb-2 ml-2" name="suac_debrief_complete-no" type="checkbox" style={{ transform: "scale(1.5)" }}
                                    checked={suac_debrief_complete === 'no'}
                                    value={suac_debrief_complete === 'no'}
                                    onChange={onYesNoBoxClicked}
                                    disabled={disabled}
                                />
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-sm">
                      <small><i>(Make corresponding entry(ies) on EF 7122)</i></small>
                  </div>
              </div>
              <div className="row border-top pt-3 pb-2 no-gutters" >
                  <div className="col-lg-8 col-8 pr-0 ml-lg-0">
                    <strong>SUAC requires additional training or re-evaluation of tasks listed above:</strong>
                  </div>
                  <div className="col-lg-4 col-4">
                    <div className="row flex-column flex-lg-row">
                      <div className="col-lg-2 pl-0 d-flex d-lg-block justify-content-lg-start justify-content-end mx-lg-2 px-lg-0">
                          <span>Yes</span>
                          <input className="mb-2 ml-2" name="additional_training-yes" type="checkbox" style={{ transform: "scale(1.5)" }}
                              checked={additional_training === 'yes'}
                              value={additional_training === 'yes'}
                              onChange={onYesNoBoxClicked}
                              disabled={disabled}
                          />
                      </div>
                      <div className="col-lg-2 pl-0 d-flex d-lg-block justify-content-lg-start justify-content-end mx-lg-2 px-lg-0">
                          <span>No</span>
                          <input className="mb-2 ml-2" name="additional_training-no" type="checkbox" style={{ transform: "scale(1.5)" }}
                              checked={additional_training === 'no'}
                              value={additional_training === 'no'}
                              onChange={onYesNoBoxClicked}
                              disabled={disabled}
                          />
                      </div>
                    </div>
                  </div>
              </div>
              {renderAtpmSig()}
              <div className="d-flex justify-content-between align-items-center w-100">
                <div className="ml-auto mr-0">
                  <button className="mr-2 btn btn-sm btn-secondary" onClick={onBeforeFormClose}>
                    Cancel
                  </button>
                  <button className="btn btn-sm btn-success" onClick={onSave} disabled={disabled}>
                      Save
                  </button>
                </div>
              </div>
          </div>
        </>
    )
}



export default connect(
    "selectRouteInfo",
    "selectGradeSlipLinkedMission",
    "selectGradeSlipEntryItemsPrintable",
    "selectIsProfileActiveAtpm",
    "selectGradeSlipIsLoading",
    "selectGradeSlipIsSaving",
    "selectGradeSlipIsSigning",
    "doGradeSlipSave",
    "doGradeSlipAtpmModifySign",
    "selectGradeSlipEditedEntries",
    "selectGradeSlipAllTypes",
    "selectGradeSlipSelectedForm",
    "doGradeSlipUpdateState",
    "selectGradeSlipSelectedFormLastSave",
    "doGradeSlipUpdateFormViewed",
    "selectGradeSlipSelectableMissions",
    GradeSlipForm
)