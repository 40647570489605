import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import "./associated-item-dropdown.css";
import Loader from "../../app-components/loader";

const AssociatedItemDropdown = ({
  data,
  aircraftConfigurationIsLoading: isLoading,
  aircraftConfigurationIsSaving: isSaving,
  aircraftConfigurationItems,
  onItemClicked,
  updateDropdownItems,
  totalRows,
  displayOnly,
  type,
}) => {
  const [state, setState] = useState({
    items: [],
    numSelected: 0,
    direction:
      totalRows > 1 && (data.rowIndex + 1) / totalRows >= 0.8 ? "dropup" : "",
  });
  useEffect(() => {
    if (aircraftConfigurationItems) {
      let update = updateDropdownItems(data, displayOnly, type);
      setState({ ...state, ...update });
    }
  }, [aircraftConfigurationItems]);

  const renderDropdownItem = (item, idx) => {
    return (
      <div
        key={idx}
        className={`dropdown-item ${
          item.selected ? "selected" : ""
        } d-flex justify-content-between py-1 px-2`}
        onClick={() => {
          if (onItemClicked) onItemClicked(item, data);
        }}>
        <span
          className="d-inline-block text-truncate"
          style={{ width: "8rem" }}>
          {item.model}
        </span>
        {item.selected && (
          <span
            className="mdi mdi-check-bold text-white"
            style={{ fontSize: "larger" }}
          />
        )}
      </div>
    );
  };

  const renderDropdownItems = () => {
    if (isLoading || isSaving) return <Loader />;
    else if (displayOnly) {
      return (
        <div className="list-group">
          {state.items.map((item, idx) => {
            return (
              <div className="list-group-item" key={idx}>
                {item.model}
              </div>
            );
          })}
        </div>
      );
    } else
      return (
        <>{state.items.map((item, idx) => renderDropdownItem(item, idx))}</>
      );
  };

  const toggleDropdown = (e) => {
    let thisElement = e.currentTarget.previousElementSibling;
    document
      .querySelectorAll(".platform-dropdown-menu.show")
      .forEach((element) => {
        if (element !== thisElement) element.classList.remove("show");
      });
    thisElement.classList.toggle("show");
  };
  const onBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget))
      e.currentTarget.firstElementChild.classList.remove("show");
    setState((prevState) => ({ ...prevState }));
  };
  return (
    <div className={`platform-dropdown ${state.direction}`} onBlur={onBlur}>
      <div className="dropdown-menu dropdown-menu-right platform-dropdown-menu">
        {renderDropdownItems()}
      </div>
      <button
        className="btn btn-outline-success dropdown-toggle"
        id={`button_${data.id}`}
        disabled={displayOnly && state.items.length === 0}
        onClick={toggleDropdown}>
        {`${state.numSelected} selected`}
      </button>
    </div>
  );
};
export default connect(
  "selectAircraftConfigurationIsLoading",
  "selectAircraftConfigurationIsSaving",
  "selectAircraftConfigurationItems",
  AssociatedItemDropdown
);
