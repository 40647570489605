import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
    name: "jobsDocs",
    uid: "s3_key",
    prefetch: true,
    routeParam: null,
    getTemplate: "/job-board/:jobSlug/docs",
    putTemplate: "/job-board/:jobSlug/docs/:item.id",
    postTemplate: "/job-board/:jobSlug/docs",
    deleteTemplate: "/job-board/:jobSlug/docs/:item.id",
    fetchActions: [],
    forceFetchActions: [
        "JOBS_FETCH_STARTED",
        "JOBSDOCS_UPLOAD_FINISHED",
        "JOBSDOCS_DELETE_FINISHED",
        "FILE_IO_UPLOAD_FINISHED",
        "FILE_IO_DELETE_FINISHED"
    ],
});