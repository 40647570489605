import React from "react";
import classnames from "classnames";
import SelectOption from "./select-input-option";
import { find } from "lodash";

class SelectInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      property: props.property,
      valid: true,
      value: props.value || "",
      msg: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.isValid = this.isValid.bind(this);
    this.validate = this.validate.bind(this);
    this.renderHelper = this.renderHelper.bind(this);
  }

  componentDidUpdate(newProps) {
    if (newProps.value && newProps.value !== this.state.value) {
      this.setState(
        {
          property: newProps.property,
          value: newProps.value || "",
        },
        this.validate
      );
    }
  }

  componentDidMount() {
    this.validate();
  }

  handleChange(e) {
    const { onChange } = this.props;
    const val = e.currentTarget.value;
    this.setState(
      {
        value: val,
      },
      this.validate
    );
    onChange && onChange({ ...this.state, value: val });
  }

  isValid() {
    return this.state.valid;
  }

  validate() {
    const { required, schema, onChange } = this.props;
    const { title } = schema;
    const { value } = this.state;
    let valid = true;
    let msg = "";

    const len = value.length || 0;

    if (required && len < 1) {
      valid = false;
      msg += `${title} is required`;
    }

    this.setState({
      valid: valid,
      msg: msg,
    });
  }

  renderHelper() {
    const { msg } = this.state;
    const { schema } = this.props;
    const { description } = schema;
    return (
      <>
        {msg ? (
          <small className="form-text invalid-feedback">{msg}</small>
        ) : null}
        {description ? (
          <small
            className="form-text text-muted"
            dangerouslySetInnerHTML={{ __html: description }}
          ></small>
        ) : null}
      </>
    );
  }

  render() {
    const { valid, value } = this.state;
    const { schema, inline, displayOnly, options } = this.props;
    let { title, readOnly } = schema;

    if (displayOnly) readOnly = displayOnly;

    const option = find(options, { value: value });
    const readOnlyValue = option ? option.label : "";

    const groupClass = classnames({
      "form-group": true,
      row: inline,
    });

    const labelClass = classnames({
      "col-sm-3": inline,
      "col-form-label": inline,
      "text-right": inline,
    });

    const inputWrapperClass = classnames({
      "col-sm-9": inline,
    });

    const validClass = classnames({
      "form-control": true,
      "is-invalid": !valid,
    });

    return (
      <div className={groupClass}>
        <label className={labelClass}>{title}</label>
        <div className={inputWrapperClass}>
          <input
            value={readOnlyValue}
            readOnly={true}
            type="text"
            className={`${validClass} ${!readOnly ? "d-none" : ""}`}
          />
          <select
            value={value || ""}
            onChange={this.handleChange}
            className={`${validClass} ${readOnly ? "d-none" : ""}`}
          >
            <option value="" disabled hidden>
              Please Choose...
            </option>
            {options.map((opt, i) => {
              return <SelectOption key={i} opt={opt} />;
            })}
          </select>
          {this.renderHelper()}
        </div>
      </div>
    );
  }
}

export default SelectInput;
