import React from "react";
import classnames from "classnames";
import ToastPrompt from "./toast-prompt";
import { connect } from "redux-bundler-react";
import { find, flatten } from "lodash";
import Loader from "../../app-components/loader";

import { marked } from "marked";

import purify from "dompurify";
import "./faqs-group-list-item.css";

const FaqsGroupListItem = ({
  dispatch,
  state,
  itemDragHandler,
  dropBoxOnDragEnter,
  itemDragEndHandler,
  dropBoxOnMouseDown,
  dropBoxOnDrop,
  dropBoxOnMouseOver,
  dropBoxOnMouseLeave,
  dropBoxOnDragOver,
  _key,
  doFaqsRatingsSave,
  tokenPayload,
  handleCRUD,
}) => {
  const {
    activeItem,
    items,
    orderedTabNames,
    activeTab,
    dragOccuring,
    mode,
    faqsRatings,
  } = state;
  const tokenKeyCloakId = tokenPayload.sub;
  const currentItem = items[_key]
    ? items[_key]
    : {
        id: null,
        status: "",
        lastSaveState: { status: "" },
        question: "",
        answer: "",
      }; // The FAQ Item represented by this function component

  let currentItemAlreadyRated = find(faqsRatings, {
    keycloak_id: tokenKeyCloakId,
    faq_id: currentItem.id,
  });
  const category = orderedTabNames[activeTab]; // The category name of the current tab

  let itemsInCategory = Object.keys(items).filter(
    (key) =>
      items[key].category == orderedTabNames[activeTab] &&
      items[key].deleted == 0
  );
  let disableUpArrow = currentItem.display_order == 0;
  let disableDownArrow =
    currentItem.display_order == itemsInCategory.length - 1;

  // Text and style data for each possible status of a FAQ Item

  const statusStyle = {
    "": { text: "Loading", classname: "badge-danger" },
    new: { text: "New", classname: "badge-primary" },
    edited: { text: "Edited", classname: "badge-warning" },
    deleted: { text: "Are you sure?", classname: "badge-light" },
    saved: { text: "Saved", classname: "badge-light" },
  };

  // FAQ Item class
  const faqItemCls = classnames({
    "d-flex flex-row my-0": mode == "editor",
    "flex-grow-1": mode == "preview",
    "border border-dark rounded-0-lg":
      mode == "editor" && currentItem.id == activeItem.id,
  });

  // FAQ Item-Body class and style
  const faqItemBodyCls = classnames({
    "flex-column flex-grow-1": mode == "editor",
    card: mode == "preview",
    "my-0 border": true,
    active: mode == "editor" && currentItem.id == activeItem.id,
  });

  const faqItemBodyStyle = {
    height: mode == "preview" ? "fit-content" : "164.6px", // for editor mode, used to be 150px
    pointerEvents: "all",
    cursor: mode == "editor" ? "grab" : "default",
    userSelect: "none",
    backgroundColor:
      mode == "editor" ? "rgb(240,243, 245)" : "rgb(255,255,255)",
    resize: mode == "editor" ? "vertical" : "none",
  };

  // Category Dropdown class and style
  const dropdownBtnCls = classnames({
    "btn btn-sm btn-secondary dropdown-toggle all-pointer-events": true,
    disabled: currentItem.status == "deleted",
    invisible: currentItem.status == "deleted",
  });

  // Delete button class
  const deleteBtnCls = classnames({
    "btn btn-sm btn-danger all-pointer-events": true,
    disabled: currentItem.status == "deleted",
  });

  // Cancel button class
  const cancelBtnCls = classnames({
    "btn btn-sm btn-secondary all-pointer-events": true,
    disabled:
      currentItem.status == "new" ||
      currentItem.status == "saved" ||
      currentItem.lastSaveState.status == null,
  });

  // Confirm button class
  const confirmBtnCls = classnames({
    "btn btn-sm bg-primary all-pointer-events": true,
    disabled: currentItem.status == "saved",
  });

  // Checkbox style
  const checkboxStyle = {
    pointerEvents: "all",
    msTransform: "scale(2)",
    WebkitTransform: "scale(2)",
    draggable: true,
  };
  const dropBoxStyle = {
    height: "25px",
    opacity: dragOccuring ? 1 : 0,
    pointerEvents: "all",
    border: dragOccuring ? "1px dashed blue" : "1px solid blue",
  };

  const handleItemMoveByBtn = (e) => {
    let direction = e.currentTarget.name == "moveItemUp" ? -1 : 1;
    dispatch({
      type: "HANDLE_ITEM_MOVE_BY_BTN",
      direction: direction,
      item: currentItem,
    });
    dispatch({
      type: "HANDLE_UNSAVED_ITEMS",
      direction: direction,
      item: currentItem,
    });
  };

  const handleChange = (e) => {
    dispatch({ type: "HANDLE_ACTIVE_ITEM_CHANGED", item: currentItem });
  };

  const handleSelectChange = (e) => {
    dispatch({
      type: "HANDLE_ITEM_SELECTED",
      itemId: currentItem.id,
      currentTarget: e.currentTarget,
    });
    dispatch({ type: "HANDLE_ANY_SELECTED" });
  };

  // Confirm-self handler
  const _confirm = async (e) => {
    if (e.currentTarget.classList.contains("disabled")) return;
    e.currentTarget.parentElement.parentElement.parentElement.classList.remove(
      "show"
    );
    let operation = currentItem.status == "deleted" ? "deleted" : "saved";

    dispatch({
      type: "HANDLE_ITEM_STATUS_CONFIRM",
      itemId: currentItem.id,
      status: currentItem.status,
    });

    await handleCRUD(
      { operation: operation, id: currentItem.id },
      state.items[currentItem.id].category
    );
  };

  // Delete-self handler
  const _delete = async (e) => {
    if (e.currentTarget.classList.contains("disabled")) return;

    // If currentItem has empty question and answer fields, do an immediate delete
    if (
      currentItem.question == "" &&
      currentItem.answer == "" &&
      !currentItem.fromStorage
    ) {
      e.currentTarget.parentElement.parentElement.parentElement.classList.remove(
        "show"
      );
      dispatch({
        type: "HANDLE_ITEM_STATUS_CONFIRM",
        itemId: currentItem.id,
        status: "deleted",
      });
    }
    // Else, wait for confirm clicked
    else
      dispatch({
        type: "HANDLE_ITEM_STATUS_DELETE",
        itemId: currentItem.id,
        status: currentItem.status,
      });
  };

  // Reverse the current status of the FAQ (Editor Mode)
  const _cancel = (e) => {
    if (e.currentTarget.classList.contains("disabled")) return;
    e.currentTarget.parentElement.parentElement.parentElement.classList.remove(
      "show"
    );
    dispatch({
      type: "HANDLE_ITEM_STATUS_CANCEL",
      itemId: currentItem.id,
      prevStatus: currentItem.prevStatus,
    });
  };

  // Prevents drag effects; onDragStart handler for clickable-components that have a draggable parent (Editor Mode)
  const inputPreventDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // Handlers for the Category dropdown in each FAQ Item (Editor Mode)
  const categoryChangedByDropdown = async (e) => {
    // Put item at very end of its new tab
    let newTabIdx = state.orderedTabNames.indexOf(e.target.name);
    dispatch({
      type: "HANDLE_ITEM_CATEGORY_CHANGED",
      value: e.target.name,
      newTabIdx: newTabIdx,
      itemId: currentItem.id,
    });
    await handleCRUD(
      { operation: "changeCategory", id: currentItem.id },
      orderedTabNames[newTabIdx]
    );
  };

  // Each FAQ is an Accordion in 'Preview' Mode
  const accordionItemClicked = (e) => {
    dispatch({
      type: "HANDLE_ACCORDION_CLICKED",
      itemId: currentItem.id,
      status: !currentItem.expanded,
    });

    // Only record the view of the Faq if the window location is the user-facing FAQ page
    if (window.location.pathname == "/faqs") {
      if (!currentItemAlreadyRated) {
        doFaqsRatingsSave(
          Object.assign(
            {},
            {
              faq_id: currentItem.id,
              keycloak_id: tokenKeyCloakId,
              rating: 0,
              deleted: 0,
            }
          )
        );
      }
    }
  };

  const preventDropItem = (e) => {
    e.preventDefault();
    return false;
  };

  // Each FAQ has a Dropbox prepended in front of it, used for both drag-and-drop and insert operations (Editor Mode)
  const renderDropBox = () => {
    return (
      <div
        className="card w-100 row mx-auto my-0"
        style={dropBoxStyle}
        onDragStart={inputPreventDrag}
        onDragOver={dropBoxOnDragOver}
        onDragEnter={dropBoxOnDragEnter}
        onDrop={(e) => {
          dropBoxOnDrop(e, currentItem);
        }}
        onClick={(e) => {
          dropBoxOnMouseDown(e, currentItem);
        }}
        onMouseOver={dropBoxOnMouseOver}
        onMouseLeave={dropBoxOnMouseLeave}
      >
        <div className="mx-auto">
          <strong className="no-pointer-events">
            {dragOccuring ? "Drop" : "Insert"}
          </strong>
          <i className={`mdi mdi-plus-circle nav-icon`}></i>
        </div>
      </div>
    );
  };
  const listItemDropdownClicked = (e) => {
    e.currentTarget.nextSibling.classList.toggle("show");
  };

  const onTextFieldBlur = (e) => {
    if (e.target.title == "question") {
      if (currentItem.question != e.target.innerText)
        dispatch({
          type: "HANDLE_ACTIVE_QUESTION_CHANGED",
          itemId: currentItem.id,
          value: e.target.innerText,
        });
    }
    if (e.target.title == "answer") {
      if (currentItem.answer != e.target.innerText)
        dispatch({
          type: "HANDLE_ACTIVE_ANSWER_CHANGED",
          itemId: currentItem.id,
          value: e.target.innerText,
        });
    }
  };
  const onTextFieldInput = (e) => {
    if (e.target.title == "question") {
      dispatch({
        type: "HANDLE_ACTIVE_QUESTION_CHANGED",
        itemId: currentItem.id,
      });
    }
    if (e.target.title == "answer") {
      dispatch({
        type: "HANDLE_ACTIVE_ANSWER_CHANGED",
        itemId: currentItem.id,
      });
    }
  };

  const renderFaq = () => {
    let categoryDropdownItems = () => {
      return orderedTabNames
        .filter((cat) => cat != category)
        .map((cat, idx) => (
          <a
            key={idx}
            className="category-dropdown-item dropdown-item"
            name={cat}
            onClick={categoryChangedByDropdown}
          >
            {cat}
          </a>
        ));
    };
    if (mode == "editor") {
      return (
        <div className="container-fluid ml-0 p-1 h-100 w-100">
          <div className="d-flex justify-content-between h-100 no-pointer-events">
            <div
              className="d-flex btn-group-vertical no-pointer-events my-auto p-1 ml-3 mr-1"
              role="group"
            >
              <button
                className="moveItemBtn btn btn-outline-dark btn-lg"
                name="moveItemUp"
                type="button"
                onClick={handleItemMoveByBtn}
                disabled={disableUpArrow}
              >
                <span className="mdi mdi-arrow-up no-pointer-events"></span>
              </button>
              <button
                className="moveItemBtn btn btn-outline-dark btn-lg"
                name="moveItemDown"
                type="button"
                onClick={handleItemMoveByBtn}
                disabled={disableDownArrow}
              >
                <span className="mdi mdi-arrow-down no-pointer-events"></span>
              </button>
            </div>

            <div className="d-flex flex-column flex-grow p-2 h-100 w-100">
              <div className="questionFieldContainer d-flex justify-content-start w-auto mt-1 mb-1">
                <h4 className="m-0">
                  <span className="badge badge-light d-inline-flex mr-1">
                    Q:
                  </span>
                </h4>
                <div className="d-flex flex-fill flex-grow h-auto w-auto">
                  <div
                    className="questionField bg-white text-dark border border-dark w-100 h-auto text-wrap text-break p-1 "
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                    title="question"
                    draggable={true}
                    autoComplete="off"
                    onDragStart={inputPreventDrag}
                    onDrop={preventDropItem}
                    onKeyDown={onTextFieldInput}
                    onBlur={onTextFieldBlur}
                    dangerouslySetInnerHTML={{
                      __html: purify.sanitize(marked(currentItem.question)),
                    }}
                  />
                </div>
              </div>

              <div className="answerFieldContainer d-flex flex-fill justify-content-start w-100 mt-1 mb-1">
                <h4 className="m-0">
                  <span className="badge badge-light d-inline-flex mr-1">
                    A:
                  </span>
                </h4>
                <div className="d-flex flex-fill h-auto w-auto">
                  <div
                    className="answerField bg-white text-dark border border-dark w-100 h-auto text-wrap text-break p-1"
                    onKeyDown={onTextFieldInput}
                    onBlur={onTextFieldBlur}
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                    title="answer"
                    draggable={true}
                    autoComplete="off"
                    onDragStart={inputPreventDrag}
                    onDrop={preventDropItem}
                    dangerouslySetInnerHTML={{
                      __html: purify.sanitize(marked(currentItem.answer)),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column p-2 mb-3 flex-fill">
              <div className="d-flex mb-1 mr-0">
                <h2
                  className="text-nowrap mt-0 flex-row float-right no-pointer-events"
                  align="right"
                >
                  <span
                    className={`badge ${[
                      statusStyle[currentItem.status]["classname"],
                    ]}`}
                  >
                    {statusStyle[currentItem.status].text}
                  </span>
                </h2>
              </div>
              <div className="btn-group w-auto mb-3">
                <button
                  className={deleteBtnCls}
                  onClick={_delete}
                  onDragStart={inputPreventDrag}
                  draggable={true}
                >
                  <i className="mdi mdi-delete-forever"></i>
                </button>
                <button
                  className={cancelBtnCls}
                  onClick={_cancel}
                  onDragStart={inputPreventDrag}
                  draggable={true}
                >
                  {currentItem.status == "deleted" ? (
                    "No"
                  ) : (
                    <i className="mdi mdi-cancel"></i>
                  )}
                </button>
                <button
                  className={confirmBtnCls}
                  type="button"
                  onClick={_confirm}
                  onDragStart={inputPreventDrag}
                  draggable={true}
                >
                  {currentItem.status == "deleted" ? (
                    "Yes"
                  ) : (
                    <i className="mdi mdi-content-save"></i>
                  )}
                </button>
              </div>
              <div className="flex-column mb-1 input-group">
                <div
                  className="btn-group d-block float-right mb-2"
                  role="group"
                >
                  <button
                    name="categoryBtn"
                    onDragStart={inputPreventDrag}
                    draggable={true}
                    className={dropdownBtnCls}
                    onClick={listItemDropdownClicked}
                  >
                    Category
                  </button>
                  <div
                    className="category-dropdown-menu dropdown-menu"
                    x-placement="bottom-start"
                  >
                    <h6 className="dropdown-header">Move to...</h6>
                    {categoryDropdownItems()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      const ratingPromptChoices = [
        {
          text: "Yes",
          color: "primary",
          contentAfter: "Thank you for your feedback.",
          layer: 0,
        },
        {
          text: "No",
          color: "secondary",
          contentAfter: "Thank you for your feedback.",
          layer: 0,
        },
        {
          text: "Undo",
          color: "info",
          contentAfter: "Was this helpful?",
          layer: 1,
        },
      ];

      const onPromptChoice = (val) => {
        let choiceVals = { Yes: 1, No: -1, Undo: 0 };

        // Only save the Faq rating if the window location is the user-facing FAQ page
        if (window.location.pathname == "/faqs") {
          // Only create FAQ rating record if the user has never 'seen' the FAQ. If it has, a record should already exist
          if (currentItemAlreadyRated) {
            doFaqsRatingsSave(
              Object.assign(
                {},
                {
                  id: currentItemAlreadyRated.id,
                  faq_id: currentItem.id,
                  keycloak_id: tokenKeyCloakId,
                  rating: choiceVals[val],
                  deleted: 0,
                }
              )
            );
          } else {
            doFaqsRatingsSave(
              Object.assign(
                {},
                {
                  faq_id: currentItem.id,
                  keycloak_id: tokenKeyCloakId,
                  rating: choiceVals[val],
                  deleted: 0,
                }
              )
            );
          }
          dispatch({ type: "RECALC_FAQS_RATINGS" });
        }
      };

      let dateString = new Date(currentItem.last_updated_content_date);

      let toastIcon =
        currentItemAlreadyRated && currentItemAlreadyRated.rating != 0
          ? ""
          : "mdi mdi-thumb-up-outline";

      let toastText =
        currentItemAlreadyRated && currentItemAlreadyRated.rating != 0
          ? `You rated this as ${
              currentItemAlreadyRated.rating == -1 ? "not" : ""
            } helpful`
          : "Was this helpful?";
      let toastStage =
        currentItemAlreadyRated && currentItemAlreadyRated.rating != 0 ? 1 : 0;
      let dateStringParts = dateString.toLocaleString().split(",");
      return (
        <>
          <div
            className="card-header p-1"
            onDragStart={inputPreventDrag}
            draggable={true}
          >
            <h2 className="mb-0">
              <button
                className="btn btn-block d-inline-block justify-content-start pb-0"
                type="button"
                aria-expanded="false"
                onClick={accordionItemClicked}
              >
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-grow-1">
                    <strong
                      className="faq-accordion-question text-left text-break"
                      dangerouslySetInnerHTML={{
                        __html: purify.sanitize(marked(currentItem.question)),
                      }}
                    ></strong>
                  </div>

                  <div
                    className="d-flex justify-content-end align-self-start mx-2"
                    style={{ minWidth: "7rem", flexDirection: "column" }}
                  >
                    <div className="text-right">Last Updated:</div>
                    <div className="text-right">{dateStringParts[0]}</div>
                    <div className="text-right">{dateStringParts[1]}</div>
                  </div>
                  <h4 className="d-flex justify-content-center">
                    <span
                      className={`float-right pl-3 mdi mdi-chevron-${
                        currentItem.expanded ? "up" : "down"
                      } nav-icon`}
                    ></span>
                  </h4>
                </div>
                {state.mode == "preview" && renderInteractBox()}
              </button>
            </h2>
          </div>
          <div
            className={`collapse ${currentItem.expanded ? "show" : ""}`}
            onDragStart={inputPreventDrag}
            draggable={true}
          >
            <div className="card-body">
              <div
                dangerouslySetInnerHTML={{
                  __html: purify.sanitize(marked(currentItem.answer)),
                }}
              />
              <ToastPrompt
                stage={toastStage}
                numLayers={2}
                mainIcon={toastIcon}
                centered={true}
                text={toastText}
                choices={ratingPromptChoices}
                _onChange={onPromptChoice}
              />
            </div>
          </div>
        </>
      );
    }
  };
  const renderFaqCheckBox = () => {
    if (mode == "editor") {
      return (
        <div
          className="d-flex border p-4 align-content-md-around flex-shrink-1"
          onDragStart={inputPreventDrag}
        >
          <input
            type="checkbox"
            checked={currentItem.isSelected || ""}
            value={currentItem.isSelected}
            style={checkboxStyle}
            onChange={handleSelectChange}
          ></input>
        </div>
      );
    } else return null;
  };
  const renderInteractBox = () => {
    let ratingIconCls = classnames({
      "mdi mdi-thumb-down-outline":
        currentItemAlreadyRated && currentItemAlreadyRated.rating == -1,
      "mdi mdi-thumb-up-outline":
        currentItemAlreadyRated && currentItemAlreadyRated.rating == 1,
    });
    let ratingCls = classnames({
      "flex-fill text-right": true,
      "text-danger":
        currentItemAlreadyRated && currentItemAlreadyRated.rating == -1,
      "text-success":
        currentItemAlreadyRated && currentItemAlreadyRated.rating == 1,
    });
    let helpRating = currentItem.helpfulness;
    let viewCount = currentItem.view_count;
    let badgeList = [
      {
        value: helpRating,
        color:
          helpRating > 0 ? "success" : helpRating < 0 ? "danger" : "primary",
        icon: "mdi mdi-thumbs-up-down ml-1",
      },
      {
        value: viewCount,
        valueSign: "",
        color: "secondary",
        icon: "mdi mdi-eye ml-1",
      },
    ];
    return (
      <div className="d-flex flex-row align-items-start mb-0 mr-1 p-1 pt-0">
        {badgeList.map((badge, idx) => {
          return (
            <h5 className="mb-0" key={idx}>
              <div className={`badge badge-${badge.color} mb-0 mr-2`}>
                {badge.value}
                <span className={badge.icon} />
              </div>
            </h5>
          );
        })}
        <div className={ratingCls}>
          {currentItemAlreadyRated && currentItemAlreadyRated.rating != 0 && (
            <span className={ratingIconCls} />
          )}
          {currentItemAlreadyRated &&
            currentItemAlreadyRated.rating != 0 &&
            `You rated this as ${
              currentItemAlreadyRated.rating == -1 ? "not" : ""
            } helpful`}
        </div>
      </div>
    );
  };

  const faqItemWrapperCls = classnames({
    "faqItem d-flex flex-row pb-4 mt-4": mode == "preview",
    "container-fluid w-100 h-auto": mode == "editor",
  });
  const renderLoader = () => {
    return (
      <div
        className="container-fluid ml-0 p-1 h-100 w-100"
        style={faqItemBodyStyle}
      >
        <div className="brand-card h-25 w-25 mx-auto">
          <div className="brand-card-header bg-facebook">
            <Loader opt="dissolve-cube" color="#ffffff" />
          </div>
        </div>
      </div>
    );
  };
  // container-fluid ml-0 p-1 h-100 w-100
  return (
    <div
      className={faqItemWrapperCls}
      title={mode == "editor" ? currentItem.id : null}
    >
      {mode == "editor" && renderDropBox()}
      <div className={faqItemCls}>
        {currentItem.isSavingOrLoading || currentItem.id == null ? (
          renderLoader()
        ) : (
          <div
            className={faqItemBodyCls}
            style={faqItemBodyStyle}
            draggable={true}
            onDragStart={(e) => {
              itemDragHandler(e, currentItem);
            }}
            onDragEnd={itemDragEndHandler}
            onClick={handleChange}
          >
            {renderFaq()}
          </div>
        )}
        {currentItem.isSavingOrLoading || currentItem.id == null
          ? null
          : renderFaqCheckBox()}
      </div>
    </div>
  );
};
export default connect(
  "doFaqsRatingsSave",
  "selectTokenEdipi",
  "selectTokenPayload",
  FaqsGroupListItem
);
