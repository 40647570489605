import React, { useState, useEffect } from "react";
import classnames from 'classnames'
import { connect } from "redux-bundler-react";
import { find, flatten, keyBy, union, uniqBy, reduceRight } from "lodash";
import { format, differenceInMinutes } from "date-fns";

import Loader from "../loader";
import ef176Data from "./templates/ef-seventysix.template.json"
import ef177Data from "./templates/ef-seventyseven.template.json"
import ef178Data from "./templates/ef-seventyeight.template.json"
import riskAssessmentData from "./templates/risk-assessment.template.json"

const expChoices = { 0: '< 30 days', 1: '30 - 45 days', 2: '45 - 60 days', 3: '> 60 days' }
const visChoices = {
    0: { 'visibility': '> 7 miles vis', 'ceiling': "> 2,000'" },
    1: { 'visibility': '5 - 7 miles vis', 'ceiling': "1,500' - 2,000'" },
    2: { 'visibility': '3 - 5 miles vis', 'ceiling': "1,000' - 1,500'" }
}
const yesNoKeys = ["injury_crew", "injury_others", "private_property_affected", "public_property_affected", "mid_air_manned", "mid_air_ua"];
const renderCostStr = (x) => {
    let xStr = x.toString()
    let result = reduceRight(xStr.split(''),
        (flat, other, idx) => {

            if (xStr.length - idx - 1 !== 0 && (xStr.length - idx - 1) % 3 === 0) return other + ',' + flat
            return other + flat

        }, []);
    return `$ ${result}`
}

const MissionPrintModal = ({ doPrintResourcesFetch, aircraftItems, payloadsItems, batteriesItems, missionsAreaDataItem, doPrintByTemplate, printFetchingResources, missionsDailyRawItemsObject, doPrintUpdateTemplate, printState, doDialogClose }) => {
    const [state, setState] = useState({
        selected: null,
        options: [
            { resultFileName: "EF 176 SUAS Air Mission Plan", file: "ef-seventysix.template.json" },
            { resultFileName: "EF 177 Daily Flight and SUAS Status Log", file: "ef-seventyseven.template.json" },
            { resultFileName: "EF 178 SUAS Mishap and Incident Report", file: "ef-seventyeight.template.json", subItemsPath: "context.flights" },
            { resultFileName: "Deliberate Risk Assessment Worksheet (DD 2977)", file: "risk-assessment.template.json" }
        ],
        optionsExpanded: false,
        templates: {
            "EF 176 SUAS Air Mission Plan": { ...ef176Data },
            "EF 177 Daily Flight and SUAS Status Log": { ...ef177Data },
            "EF 178 SUAS Mishap and Incident Report": { ...ef178Data },
            "Deliberate Risk Assessment Worksheet (DD 2977)": { ...riskAssessmentData }
        },
        apiEndpoint: { url: "/orgs/:orgSlug/missions/:missionsSlug/printForm" },
        selectAll: true,
        disablePrint: false
    })
    const handleConfirm = async (e) => {
        let subChoices = []
        state.options.forEach(opt => {
            if (opt.subItems) {
                let selectedSubItems = opt.subItems.filter(item => item.selected)
                selectedSubItems = selectedSubItems.map(item => { return { ...opt, ...item } });
                subChoices = subChoices.concat(selectedSubItems)
            }
        })
        let printChoices = state.options.filter(opt => opt.selected && !opt.subItems).concat(subChoices)

        let selectedDocTemplates = [];
        let resultFilePrefix = printChoices.length === 1 ? printChoices[0].resultFileName : 'MISSION-PACKET'
        printChoices.forEach(choice => {
            let temp = state.templates[choice.resultFileName];
            selectedDocTemplates.push({ ...temp, ...choice, _filename: choice.file, _resultFileName: choice.resultFileName, stateFields: choice.stateFields })
        })
        doPrintUpdateTemplate(state, selectedDocTemplates)
        await doPrintByTemplate(resultFilePrefix)
        await doDialogClose()
    }

    const processEf178Data = () => {
        let { context } = printState
        let { mishapsByFlight, flights } = context;
        let allMishaps = mishapsByFlight ? union(flatten(Object.values(mishapsByFlight))) : []

        flights = uniqBy(flights, 'id');
        let flight_num = 1;
        let currentDailyRawId = null;
        flights.forEach((f) => {
            if (currentDailyRawId !== f.daily_raw_id) flight_num = 1
            currentDailyRawId = f.daily_raw_id
            f['flight_label'] = `Flight ${flight_num}: ${f.name}`;
            f['flight_num'] = flight_num;
            ++flight_num;
        })
        let flightsById = keyBy(flights, 'id')
        allMishaps = allMishaps.map(m => {
            let flight = flightsById[m.flight_id];
            let dailyRaw = missionsDailyRawItemsObject[m.daily_raw_id];
            let dailyRawDate = format(new Date(dailyRaw.date), "MMMM d, yyyy HH:mm");
            let name = `${m.summary}       Daily Raw Date: ${dailyRawDate}`
            let update = { ...missionsAreaDataItem, ...m }

            yesNoKeys.forEach(key => {
                update[`${key}_yes`] = update[key];
                update[`${key}_no`] = !update[key];
            })
            let { mission_environment_id } = update;

            let missionEnvironmentItems = mission_environment_id ? mission_environment_id.split('.') : []
            let flightStartTime = new Date(flight.start_time);
            let flightEndTime = new Date(flight.end_time);
            update['mishap_date'] = new Date(flightStartTime).toDateString();
            update['mishap_time'] = new Date(flightEndTime).toLocaleTimeString();
            update[`flight_start_time`] = format(flightStartTime, "MMMM d, yyyy HH:mm");
            update[`flight_end_time`] = format(flightEndTime, "MMMM d, yyyy HH:mm");
            update[`flight_minutes`] = differenceInMinutes(flightEndTime, flightStartTime);
            update[`flight_number`] = flight.flight_num ? flight.flight_num.toString() : '';
            if (missionEnvironmentItems.length === 3) {
                update[`critical_infrastructre`] = true
                update[`defense_critical_infrastructre`] = true
            }
            flight.personnel.forEach((personnel, idx) => {
                const { flight_role, name } = personnel;
                update[`crewmember_name_${idx + 1}`] = name
                update[`crewmember_position_${idx + 1}`] = flight_role;

                if (dailyRaw) {
                    if (flight_role === 'RP') {
                        update[`crewmember_daysSinceLastFlight_${idx + 1}`] = expChoices[dailyRaw.experience_rpi]
                    }
                    else if (flight_role === 'VO') {
                        update[`crewmember_daysSinceLastFlight_${idx + 1}`] = expChoices[dailyRaw.experience_vo]
                    }
                }
            })

            if (dailyRaw) {
                update['visibility'] = visChoices[dailyRaw.visibility]['visibility']
                update['ceiling'] = visChoices[dailyRaw.visibility]['ceiling']
            }

            if (printState.context['ua'].length > 0) {
                let mishap_aircraft = find(aircraftItems, ['id', flight.aircraft_id]);
                update['affected_ua_name'] = mishap_aircraft ? mishap_aircraft.name : '';
                update['affected_ua_cost'] = mishap_aircraft && mishap_aircraft.cost > 0 ? mishap_aircraft.cost : '';
                update[`mishap_ua_name_1`] = mishap_aircraft ? mishap_aircraft.name : '';
                update[`mishap_ua_serial_no_1`] = mishap_aircraft ? mishap_aircraft.serial_no : '';
            }
            if (printState.context['payload'].length > 0) {
                let mishap_payload = find(payloadsItems, ['id', flight.payload_id]);
                update['affected_payload_name'] = mishap_payload ? mishap_payload.name : '';
                update['affected_payload_cost'] = mishap_payload && mishap_payload.cost > 0 ? mishap_payload.cost : '';
                update[`mishap_payload_name_1`] = mishap_payload ? mishap_payload.name : '';
                update[`mishap_payload_serial_no_1`] = mishap_payload ? mishap_payload.serial_no : '';
            }
            if (printState.context['battery'].length > 0) {
                let mishap_battery = find(batteriesItems, ['id', flight.battery_id]);
                update['affected_battery_name'] = mishap_battery ? mishap_battery.name : '';
                update['affected_battery_cost'] = mishap_battery && mishap_battery.cost > 0 ? mishap_battery.cost : '';
                update[`mishap_battery_name_1`] = mishap_battery ? mishap_battery.name : '';
                update[`mishap_battery_serial_no_1`] = mishap_battery ? mishap_battery.serial_no : '';
            }
            update['summary'] = m.summary
            update['est_cost'] = renderCostStr(m.est_cost);
            return { ...m, ...update, selected: state.selectAll, name: name, flight: flight }
        })
        return allMishaps
    }
    useEffect(() => {
        if (state.apiEndpoint) {
            doPrintResourcesFetch(state.apiEndpoint);
        }
        setState({
            ...state,
            options: state.options.map(opt => {
                return { ...opt, selected: state.selectAll }
            }),
            templates: {
                "EF 176 SUAS Air Mission Plan": { ...ef176Data },
                "EF 177 Daily Flight and SUAS Status Log": { ...ef177Data },
                "EF 178 SUAS Mishap and Incident Report": { ...ef178Data },
                "Deliberate Risk Assessment Worksheet (DD 2977)": { ...riskAssessmentData }
            }
        })
    }, []);
    useEffect(() => {
        if (printState) {
            setState({
                ...state, options: state.options.map(opt => {
                    if (opt.subItemsPath) {
                        let allMishaps = processEf178Data();
                        return { ...opt, selected: state.selectAll, subItems: allMishaps }
                    }
                    else return { ...opt, selected: state.selectAll }
                })
            })
        }
    }, [printState])

    const fileChoiceSelected = (e, parentIdx) => {
        let parent = e !== -1 ? e.currentTarget.parentNode : e;
        let index = e !== -1 ? Array.prototype.indexOf.call(parent.children, e.currentTarget) : -1
        if (e !== -1 && parentIdx) {
            setState({
                ...state, options: state.options.map((opt, idx) => {
                    if (idx === parentIdx) {
                        return {
                            ...opt,
                            subItems: opt.subItems.map((subOpt, subIdx) => {
                                if (subIdx === index) {
                                    return { ...subOpt, selected: !subOpt.selected }
                                }
                                else return { ...subOpt }
                            })
                        }
                    }
                    return opt
                })
            })
        }
        else {
            let targetIdx = e === -1 ? parentIdx : index
            setState({
                ...state, options: state.options.map((opt, idx) => {
                    if (idx === targetIdx) {
                        return { ...opt, selected: !opt.selected }
                    }
                    return opt
                })
            })
        }

    }
    let printBtnCls = classnames({
        "btn btn-primary": true,
        "disabled": state.disablePrint
    })
    useEffect(() => {
        if (state.options) {
            let subChoices = []
            state.options.forEach(opt => {
                if (opt.subItems) {
                    let selectedSubItems = opt.subItems.filter(item => item.selected)
                    selectedSubItems = selectedSubItems.map(item => { return { ...opt, ...item } });
                    subChoices = subChoices.concat(selectedSubItems)
                }
            })
            let printChoices = state.options.filter(opt => opt.selected && !opt.subItems).concat(subChoices)
            setState({ ...state, disablePrint: printChoices.length === 0 })
        }

    }, [state.options])
    return (
        <div className="modal-content mx-auto">
            <div className="px-4 py-3">
                <div className="modal-header">
                    <h5 className="modal-title">Fill & Print</h5>
                    <button className="close" type="button" aria-label="close" onClick={() => {
                        doDialogClose()
                    }}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body" style={{ overflow: "visible" }}>
                    <div className="d-flex flex-row mb-1">
                        <small className="ml-0 mr-auto">Select form(s) below to fill & print</small>
                    </div>

                    <div className="list-group">
                        {
                            state.options.map((opt, key) => {
                                if (opt.subItemsPath) {
                                    let subItems = opt.subItems
                                    if (!subItems) return <Loader key={key} />
                                    let numSelected = subItems.filter(s => s.selected).length
                                    return (
                                        <div key={key}>
                                            <div style={{ borderRadius: 0 }} className="d-flex list-group-item list-group-item-action" onClick={(e) => fileChoiceSelected(-1, key)}>

                                                <strong className="ml-2 mr-auto">
                                                    {opt.resultFileName}
                                                </strong>
                                                <span className="float-right">
                                                    Selected ({numSelected}/{subItems.length})
                                                </span>
                                                <span className={`float-right pl-3 mdi mdi-chevron-${opt.selected ? "up" : "down"} nav-icon`}></span>


                                            </div>
                                            <div className={`collapse ${opt.selected ? "show" : ""}`} style={{ borderRadius: 0 }}>
                                                <div className="list-group">
                                                    {subItems.map((subItem, subKey) => {
                                                        return <div key={subKey} value={subItem.name} className="d-flex list-group-item list-group-item-action" onClick={(e) => fileChoiceSelected(e, key)}
                                                            style={{ borderRadius: 0 }}
                                                        >
                                                            <p className="pl-5">{subItem.name}</p>
                                                            <span className={`mdi mdi-checkbox-${subItem.selected ? 'marked' : 'blank-outline'} text-${subItem.selected ? 'primary' : 'dark'} ml-auto mr-2 my-auto`}
                                                                style={{ fontSize: "20px" }} />
                                                        </div>
                                                    })}

                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                else return (
                                    <div key={key} className="d-flex list-group-item list-group-item-action" onClick={fileChoiceSelected}>
                                        <strong className="ml-2 mr-auto">
                                            {opt.resultFileName}
                                        </strong>
                                        <span className={`mdi mdi-checkbox-${opt.selected ? 'marked' : 'blank-outline'} text-${opt.selected ? 'primary' : 'dark'} ml-auto mr-2 my-auto`} style={{ fontSize: "20px" }} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="modal-footer d-flex flex-column">
                    {state.selected ? <strong className="mr-0 ml-auto pt-1 pb-2">Print "{state.selected.resultFileName}..." ?</strong> : null}
                    <div className="d-flex flex-row justify-content-between w-100 ">
                        <button className="btn btn-sm btn-secondary" type="button" name="no" onClick={() => {
                            doDialogClose();
                        }}>
                            Cancel
                        </button>
                        {printFetchingResources ?
                            <div className="d-flex">
                                <strong className="ml-auto mr-2">Fetching Resources</strong>
                                <div className="ml-auto mr-0 spinner-border text-primary" />
                            </div>
                            : <button className={printBtnCls} type="button" name="yes" onClick={handleConfirm} style={{ pointerEvents: state.disablePrint ? 'none' : 'all' }}>
                                Print
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default connect(
    "doPrintByTemplate",
    "doPrintResourcesFetch",
    "doPrintUpdateTemplate",
    "selectPrintFetchingResources",
    "doDialogClose",
    "selectPrintState",
    "selectMissionsDailyRawItemsObject",
    "selectMissionsAreaDataItem",
    "selectAircraftItems",
    "selectPayloadsItems",
    "selectBatteriesItems",
    MissionPrintModal);