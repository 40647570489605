import ReactSelect from "react-select";
import { connect } from "redux-bundler-react";
import Grid from "../_shared/grid";
import { useEffect, useState } from "react";
import { sortBy } from "lodash";

const Tag = ({ text, color }) => {
  return <div className={`badge badge-pill badge-${color} ml-2`}>{text}</div>;
};

const AccordionItem = ({ item, data }) => {
  const [open, setOpen] = useState(true);
  const config = {
    columnDefs: [
      {
        resizable: true,
        sortable: true,
        headerName: "Type",
        field: "type",
        filter: "agTextColumnFilter",
      },
      {
        resizable: true,
        sortable: true,
        headerName: "Make",
        field: "make",
        filter: "agTextColumnFilter",
      },
      {
        resizable: true,
        sortable: true,
        headerName: "Model",
        field: "model",
        filter: "agTextColumnFilter",
      },
    ],
  };

  return (
    <div className="accordion-item py-2 border-bottom mb-2">
      <h2 className="accordion-header" id="accordion-header">
        <button
          className="accordion-button d-flex justify-content-between align-items-center w-100 p-0 btn btn-ghost btn-lg"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded={open}
          aria-controls="collapseOne"
          onClick={() => setOpen(!open)}
        >
          <span className="d-flex align-items-center">
            {item}
            <Tag
              text={
                data["usace_approved"] ? "USACE Approved" : "Not USACE Approved"
              }
              color={data["usace_approved"] ? "success" : "danger"}
            />
            <Tag
              text={
                new Date(data["awr_expire_date"]) >= new Date()
                  ? "AWR Current"
                  : "Missing or Expired AWR"
              }
              color={
                new Date(data["awr_expire_date"]) >= new Date()
                  ? "success"
                  : "danger"
              }
            />
          </span>
          <i className={`mdi mdi-chevron-${open ? "up" : "down"} mdi-24px`} />
        </button>
      </h2>
      <div
        id="collapseOne"
        className={`accordion-collapse collapse ${open ? "show" : ""}`}
        aria-labelledby="accordion-header"
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body">
          <Grid
            config={config}
            data={data.configurations}
            style={{ height: 200 }}
          />
        </div>
      </div>
    </div>
  );
};
const ConfigurationsPage = ({
  platformsItems = [],
  platformsPayloadsItems,
  platformsGcsItems,
  doPlatformsFetch,
  aircraftConfigurationsByPlatform,
  doFilterAircraftConfigurations,
  aircraftConfigurationFilters,
}) => {
  useEffect(() => doPlatformsFetch(), []);
  return (
    <div className="container" style={{ marginTop: 80 }}>
      <div className="card">
        <div className="card-header">
          <i className="mdi mdi-puzzle-outline" />
          Aircraft Configurations
        </div>
        <div className="card-body">
          <div className="d-flex align-items-start w-100 mb-3">
            <div className="d-flex flex-column  w-100 mr-2">
              <label>Aircraft</label>
              <ReactSelect
                isClearable
                onChange={(arr) =>
                  doFilterAircraftConfigurations(arr, "aircraft_id")
                }
                options={sortBy(platformsItems, "make").map((item) => ({
                  value: item.id,
                  label: `${item.make} ${item.model}`,
                }))}
              />
            </div>
            <div className="d-flex flex-column  w-100 mr-2">
              <label>Payloads</label>
              <ReactSelect
                isClearable
                isDisabled={
                  aircraftConfigurationFilters &&
                  aircraftConfigurationFilters["gcs_id"]
                }
                onChange={(arr) =>
                  doFilterAircraftConfigurations(arr, "payload_id")
                }
                options={sortBy(platformsPayloadsItems, "make").map((item) => ({
                  value: item.id,
                  label: `${item.make} ${item.model}`,
                }))}
              />
              <small className="text-muted">
                Dropdown is disabled if filtering by GCS
              </small>
            </div>
            <div className="d-flex flex-column  w-100 mr-2">
              <label>GCS</label>
              <ReactSelect
                isClearable
                isDisabled={
                  aircraftConfigurationFilters &&
                  aircraftConfigurationFilters["payload_id"]
                }
                onChange={(arr) =>
                  doFilterAircraftConfigurations(arr, "gcs_id")
                }
                options={sortBy(platformsGcsItems, "make").map((item) => ({
                  value: item.id,
                  label: `${item.make} ${item.model}`,
                }))}
              />
              <small className="text-muted">
                Dropdown is disabled if filtering by Payloads
              </small>
            </div>
          </div>
          <h5 className="border-bottom pb-2">Platforms</h5>
          <div className="accordion" id="accordionExample">
            {Object.keys(aircraftConfigurationsByPlatform).length === 0 ? (
              <div className="w-100 d-flex justify-content-center p-3">
                <h5 className="text-muted">
                  No configurations at the selected parameters
                </h5>
              </div>
            ) : (
              Object.keys(aircraftConfigurationsByPlatform).map((makeModel) => {
                return (
                  <AccordionItem
                    item={makeModel}
                    data={aircraftConfigurationsByPlatform[makeModel]}
                    key={makeModel}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  "selectPlatformsItems",
  "selectPlatformsPayloadsItems",
  "selectPlatformsGcsItems",
  "selectAircraftConfigurationItems",
  "doPlatformsFetch",
  "selectAircraftConfigurationsByPlatform",
  "doFilterAircraftConfigurations",
  "selectAircraftConfigurationFilters",
  ConfigurationsPage
);
