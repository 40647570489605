import React, { useEffect, useRef, useState } from "react";
import { connect } from "redux-bundler-react";
import TabContainer from "../../modules/missions/_shared/tab-container/tab-container";
import ProfileAlert from "./profile-alert";
import Documents from "./tabs/documents";
import Ftf from "./tabs/ftf";
import UserInfo from "./tabs/user-info";

const tabConfig = {
  "User Info": UserInfo,
  "Flight Training Folder": (props) => {
    return (
      <div className="w-100 h-100 d-flex flex-column">
        <ProfileAlert tab="ftf" />
        <Ftf {...props} />
      </div>
    );
  },
  Documents: Documents,
};
const Profile = ({ doFetchProfilesAll }) => {
  useEffect(doFetchProfilesAll, []);

  return (
    <div className="container" style={{ marginTop: "80px" }}>
      <h4 className="text-dark">My Profile</h4>
      <TabContainer config={tabConfig} />
    </div>
  );
};

export default connect(
  "doProfileSave",
  "selectProfileId",
  "selectTokenExpiresIn",
  "selectPreviousRoute",
  "doUpdateRelativeUrl",
  "doFetchProfilesAll",
  Profile
);
