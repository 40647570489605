import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";
import moment from "moment";

export default createRestBundle({
  name: "missions",
  uid: "slug",
  prefetch: true,
  persist: false,
  routeParam: "missionsSlug",
  getTemplate: "/orgs/:orgSlug/missions",
  putTemplate: "/orgs/:orgSlug/missions/:item.slug",
  postTemplate: "/orgs/:orgSlug/missions",
  deleteTemplate: "/orgs/:orgSlug/missions/:item.slug",
  fetchActions: ["AUTH_LOGGED_IN"],
  forceFetchActions: [
    "MISSIONS_COPY_FINISHED",
    "MISSIONS_SAVE_FINISHED",
    "URL_UPDATED",
  ],
  addons: {
    doMissionsCopy:
      (item, callback, deferCallback) =>
      ({ dispatch, store, apiPost }) => {
        dispatch({
          type: "MISSIONS_SAVE_STARTED",
          payload: {
            _isSaving: true,
          },
        });

        let url = store.selectMissionsPostUrl();

        apiPost(`${url}/${item.slug}`, item, (err, response, body) => {
          if (err || response.statusCode !== 200) {
            dispatch({
              type: "MISSIONS_ERROR",
              payload: {
                _err: { err: err, response: response },
                notification: {
                  statusCode: response.statusCode,
                },
                _isSaving: false,
              },
            });
          } else {
            // Make sure we're sending save_finished when we're done
            dispatch({
              type: "MISSIONS_SAVE_FINISHED",
              payload: {
                _isSaving: false,
              },
            });

            dispatch({
              type: "MISSIONS_COPY_FINISHED",
            });

            if (deferCallback && callback) callback();
          }
          // if we get a callback, go ahead and fire it
          if (!deferCallback && callback) callback();
        });
      },
    selectMissionsSoonDate: createSelector("selectMissionsItems", (items) => {
      if (!items || !items.length) return null;
      let today = moment();
      let fortnightAway = today.clone().add(2, "week");
      const soonCount = items.filter((a) => {
        let date = a.date_start;
        return moment(date).isBetween(today, fortnightAway);
      });
      return soonCount;
    }),
    selectMissionsApproval: createSelector("selectMissionsItems", (items) => {
      if (!items || !items.length) return null;
      const needApprovalCount = items.filter((a) => {
        return a.approval_status === "pending approval";
      });
      return needApprovalCount;
    }),
  },
});
