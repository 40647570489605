export default {
    name: "tabs",

    getReducer: () => {
        const initialData = {
            activeTab: 0
        };

        return (state = initialData, { type, payload }) => {
            switch (type) {
                case "ACTIVE_TAB_UPDATE":
                    return Object.assign({}, state, payload);
                case "URL_UPDATED":
                    return {
                        ...state,
                        activeTab: 0
                    }
                default:
                    return state;
            }
        };
    },

    doUpdateActiveTab: idx => ({ dispatch }) => {
        dispatch({
            type: "ACTIVE_TAB_UPDATE",
            payload: {
                activeTab: idx
            }
        })
    },

    selectActiveTab: state => state.tabs.activeTab
};
