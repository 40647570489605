import React from "react";
import { connect } from "redux-bundler-react";

class ApprovalNotesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      note: "",
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.updateNote = this.updateNote.bind(this);
  }

  handleCancel() {
    const { onCancel } = this.props;
    onCancel();
  }

  updateNote(e) {
    this.setState({
      note: e.target.value,
    });
  }

  handleSave() {
    const {
      doMissionsApprovalNotesSave,
      doMissionsApprovalNotesFetch,
      onSave,
      item,
      isRejecting,
    } = this.props;
    const { note } = this.state;
    const newNote = {
      mission_approval_id: item.id,
      note: note,
      is_rejected: isRejecting,
      last_updated_by_name: "Saving...",
      last_updated_date: new Date(),
    };
    doMissionsApprovalNotesSave(newNote, doMissionsApprovalNotesFetch, true);
    onSave();
  }

  render() {
    const { isRejecting } = this.props;
    const { note } = this.state;
    return (
      <div>
        <textarea
          value={note}
          onChange={this.updateNote}
          className="form-control mb-2"
          rows="4"
          placeholder="Add any comments..."
        />
        <button
          onClick={this.handleCancel}
          className="btn btn-sm btn-secondary mr-2"
        >
          Cancel
        </button>
        <button
          onClick={this.handleSave}
          className={`btn btn-sm btn-${isRejecting ? "danger" : "success"}`}
        >
          Continue
        </button>
      </div>
    );
  }
}

export default connect(
  "doMissionsApprovalNotesSave",
  "doMissionsApprovalNotesFetch",
  ApprovalNotesForm
);
