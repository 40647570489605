import React from "react";
import { format } from "date-fns";

class MaintenanceListItem extends React.Component {
  render() {
    const { item, enableEditing } = this.props;
    let { title, service_date, description } = item;
    if (service_date)
      service_date = format(new Date(service_date), "dd-MMM-yyyy");
    return (
      <li className="list-group-item">
        <div className="float-right">
          <div className="pointer">
            <i
              className="mdi mdi-pencil text-info"
              onClick={() => {
                enableEditing(item);
              }}
            ></i>
          </div>
        </div>
        <div
          className="overflow-ellipsis"
          title={title}
        >{`${service_date} - ${title}`}</div>
        <hr />
        <div>
          <small>{description}</small>
        </div>
      </li>
    );
  }
}

export default MaintenanceListItem;
