import React, { useEffect, useReducer } from "react";
import { connect } from "redux-bundler-react";
import { find, reduce, filter, range, rearg, flatten, without, mergeWith } from "lodash";

import TabsContainer from "./tabs-container";
import RoleFilter from "../../app-containers/context-providers/role-filter";

const FaqsEditor = ({ faqsItems: faqs, tokenRolesJoined, doFaqsSave, doFaqsDelete, doDialogOpen, faqsRatingsItems: faqsRatings, tokenPayload }) => {
    const tokenKeyCloakId = tokenPayload.sub
    const canEdit = tokenRolesJoined.includes("HQ.ADMIN")
    const initState = {
        initLoad: false,            // True if items have been initialized, False if not
        itemsBeforeDrag: {},        // A copy of items before drag occurs, used as a backup if drag operation fails
        unsavedItems: {},         // Holds items that aren't saved yet 
        items: {},                  // Object to store all items, both stored and newly created
        activeItem: {},             // Item that currently has focus
        mode: "preview",            // Either 'preview' or 'editor'
        orderedTabNames: [],        // Holds each tab name (category) in its
        editingTabNameIdx: null,
        tabsAreSaving: false,
        mostRecentTabNameChange: "",
        mostRecentTabMove: [],
        mostRecentOrderChange: [],
        mostRecentDragDrop: [],
        mostRecentMoveByBtn: [],
        activeTab: 0,               // Index of the current tab
        dragOccuring: false,        // True whenever a drag is occuring, else false
        allSelected: false,         // Toggles true/false everytime a select/deselect-all action is triggered
        anySelected: false,         // True if any FAQs are selected, else false,
        categoryViewMode: true,     // Designates how a user wants to view the FAQs (If true, users will see FAQs in arranged in tabs, else all FAQs will be in one list')
        sortDirection: null,        // Either ascending or descending
        sortOperation: null,        // Either by Helpfulness, View Count, or Most Recent
        faqsRatings: faqsRatings
    }

    const newItemTemplate = { isSavingOrLoading: false, fromStorage: false, expanded: false, isSelected: false, question: "", answer: "", status: "new", prevStatus: "new", deleted: 0, selected: false, lastSaveState: { display_order: -1, question: "", answer: "", status: "new", reverted: true } }

    // Generates a temporary tab name whenever a new tab is created. i.e Untitled{x}
    const generateTemporaryTabName = () => {
        let dateStr = Number(new Date()).toString();
        let reversedDateStr = dateStr.split('').reverse().join('')      // reverse date string sequence so that sequence looks distinct everytime 
        return `Untitled_${reversedDateStr}`
    }

    // Returns FAQ ids sorted by their display_order for a specified category
    const orderedKeysinCategory = (items, category) => {
        let result = Object.keys(items)
            .filter(key => items[key].category == category)
            .sort((a, b) => {
                if (items[a].display_order > items[b].display_order) return 1;
                if (items[a].display_order < items[b].display_order) return -1;
                return 0;
            })
        return result;
    }

    // Returns FAQ objects in category with updated 'display_order' while deleting specified items
    const reorderItemsInCategory = (prevItems, category, removeIds = [], removalActionObj = null) => {          // Similar function can be utilized in the future for search function
        let reorderedItems = Object.keys(prevItems)
            .filter(item => prevItems[item].category == category && !removeIds.includes(item))
            .sort((a, b) => {
                if (prevItems[a].display_order > prevItems[b].display_order) return 1;
                if (prevItems[a].display_order < prevItems[b].display_order) return -1;
                return 0;
            })
            .map((id, idx) => [id, { ...prevItems[id], display_order: idx }])

        let objsRemoved = {};
        if (removalActionObj.type == "delete") {
            objsRemoved = removeIds.length > 0 ? Object.fromEntries(new Map(removeIds.map(id => [id, { ...prevItems[id], deleted: 1 }]))) : {}
        }
        else if (removalActionObj.type == "changeCategory") {
            objsRemoved = removeIds.length > 0 ? Object.fromEntries(new Map(removeIds.map(id => [id, { ...prevItems[id], category: removalActionObj.value, category_order: removalActionObj.order }]))) : {}
        }

        let itemsObjForCategory = Object.fromEntries(new Map(reorderedItems))
        return { ...itemsObjForCategory, ...objsRemoved }

    }

    const argsToArray = (...array) => { return [array] }        // Function built specifically for use in lodash.rearg, which rearranges items in an array

    const mainReducer = (state, action) => {
        // Variables below are used in multiple cases and couldn't be re-declared every time
        let itemsCopy = { ...state.items }
        let orderedTabNamesCopy = [...state.orderedTabNames]
        let newTabName = '';
        let newItem = {}
        let itemsInCategory = []
        let srcTabReordered = {}
        switch (action.type) {

            case "HANDLE_VIEW_MODE_CHANGE":
                return { ...state, categoryViewMode: action.value }

            // Preview Mode Only Operations

            // Handles when an FAQ item is clicked in 'preview mode'
            case "HANDLE_ACCORDION_CLICKED":
                return { ...state, items: { ...state.items, [action.itemId]: { ...state.items[action.itemId], expanded: action.status } } }

            // Tab Operations

            // Moves a tab's position to the LEFT or RIGHT depending on which is pressed; triggered by a tab's LEFT and RIGHT arrow buttons
            case "HANDLE_TAB_MOVE":
                // 'src' is activeTab (the index of the current tab); 'dest' is the tab index being moved to
                orderedTabNamesCopy[action.dest] = state.orderedTabNames[action.src];
                orderedTabNamesCopy[action.src] = state.orderedTabNames[action.dest];

                // Every FAQ item has column for its category's display_order. 
                // Tab-indices of items from one tab are being flip-flopped with tab-indices of items in the other tab
                Object.keys(itemsCopy)
                    .forEach(key => {
                        if (itemsCopy[key].category == state.orderedTabNames[action.dest]) itemsCopy[key].category_order = action.src
                    })
                Object.keys(itemsCopy)
                    .forEach(key => {
                        if (itemsCopy[key].category == state.orderedTabNames[action.src]) itemsCopy[key].category_order = action.dest
                    })
                return { ...state, items: { ...itemsCopy }, orderedTabNames: orderedTabNamesCopy, activeTab: action.dest, mostRecentTabMove: [action.src, action.dest] }

            // Handles case when delete button on tab is clicked. Deletes all FAQs in that category (tab)
            case "HANDLE_DELETE_TAB":
                let deletedIds = Object.keys(itemsCopy)
                    .filter(key => itemsCopy[key].category == state.orderedTabNames[action.idx])
                    .map((id) => [id, { ...itemsCopy[id], deleted: 1 }])

                let deletedObjs = Object.fromEntries(new Map(deletedIds))
                let nextActiveTab = action.idx;
                if (orderedTabNamesCopy.length > 0) {
                    if (action.idx == 0) ++nextActiveTab;
                    else --nextActiveTab;
                }
                orderedTabNamesCopy.splice(action.idx, 1);
                return { ...state, orderedTabNames: orderedTabNamesCopy, items: { ...itemsCopy, ...deletedObjs }, activeTab: nextActiveTab }

            // Appends new tab to tab container and generates temporary tab name
            case "HANDLE_NEW_TAB":
                newTabName = action.value ? action.value : generateTemporaryTabName()
                return { ...state, activeTab: state.orderedTabNames.length, orderedTabNames: [...state.orderedTabNames, newTabName] }

            // Handles case of tab name being left blank and generates temporary tab name; triggered when tab name is left blank
            case "HANDLE_BLANK_TAB_NAME":
                newTabName = generateTemporaryTabName()    // If tab name is blank, replace it with a temporary name 'Untitled{x}'
                let newOrderedTabNames = [];
                if (state.orderedTabNames.length == 1) {
                    newOrderedTabNames = [newTabName]
                }
                else {
                    if (action.idx == 0) {
                        newOrderedTabNames = [newTabName]
                        newOrderedTabNames = newOrderedTabNames.concat(state.orderedTabNames.slice(1))
                    }
                    else {
                        newOrderedTabNames = newOrderedTabNames.concat(state.orderedTabNames.slice(0, action.idx));
                        newOrderedTabNames = newOrderedTabNames.concat(newTabName)
                        newOrderedTabNames = newOrderedTabNames.concat(state.orderedTabNames.slice(action.idx + 1))
                    }
                }
                Object.keys(itemsCopy).forEach((key) => {
                    if (itemsCopy[key].category == "") {
                        itemsCopy[key] = { ...itemsCopy[key], category: newTabName }
                    }
                })
                return { ...state, orderedTabNames: [...newOrderedTabNames], items: { ...itemsCopy } }

            // Updates list of tab names
            case "HANDLE_UPDATE_TAB_NAMES":
                return { ...state, orderedTabNames: [...action.items] }

            // Updates index of current tab; triggered when the active tab changes
            case "HANDLE_ACTIVE_TAB_CHANGED":
                return { ...state, activeTab: action.value }

            // Editor Operations

            // Appends new item to end of current tab; triggered from primary 'New' button, 
            case "HANDLE_NEW_ITEM":
                itemsInCategory = Object.keys(itemsCopy)
                    .filter(key => itemsCopy[key].category == state.orderedTabNames[state.activeTab])

                newItem = { ...newItemTemplate, id: action.itemId, category: state.orderedTabNames[state.activeTab], category_order: state.activeTab, display_order: itemsInCategory.length, helpfulness: 0, view_count: 0, ratedByUser: false }
                return {
                    ...state,
                    activeItem: { ...newItem },
                    items: { ...state.items, [action.itemId]: { ...newItem } },
                    unsavedItems: { ...state.unsavedItems, [action.itemId]: { ...newItem } }
                }

            // Inserts new item at index in current tab; triggered from clicking any 'Insert' button
            case "HANDLE_INSERT_ITEM":
                let newId = Number(new Date()).toString();
                newItem = { ...newItemTemplate, id: newId, category: state.orderedTabNames[state.activeTab], category_order: state.activeTab, display_order: action.currentItem.display_order, helpfulness: 0, view_count: 0, rated: false }  // Give the new FAQ Item the index of 'currentItem'
                let keysToShift = orderedKeysinCategory(itemsCopy, state.orderedTabNames[state.activeTab])

                // Shift all FAQ Items after and including 'currentItem' by 1
                keysToShift.slice(action.currentItem.display_order)
                    .forEach((key) => {
                        itemsCopy[key].lastSaveState.display_order = itemsCopy[key].display_order
                        ++itemsCopy[key].display_order

                    })
                return { ...state, items: { ...itemsCopy, [newId]: { ...newItem } }, unsavedItems: { ...state.unsavedItems, [newId]: { ...newItem } } }

            // Switches mode between 'preview' and 'editor'; triggered from Mode button
            case "HANDLE_MODE_CHANGED":
                if (action.value == "editor") {
                    return { ...state, mode: action.value, categoryViewMode: true, sortDirection: null, sortOperation: null }
                }
                return { ...state, mode: action.value }

            // Sets 'dragOccuring' for when a drag occurs.
            case "HANDLE_DRAG_OCCURING":
                return { ...state, dragOccuring: action.value }

            // Updates list of items of all categories
            case "HANDLE_UPDATE_ITEMS":
                orderedTabNamesCopy.forEach(tab => {
                    Object.keys(action.items)
                        .filter(key => action.items[key].category == tab)
                        .sort((a, b) => {
                            if (action.items[a].display_order > action.items[b].display_order) return 1;
                            if (action.items[a].display_order < action.items[b].display_order) return -1;
                            return 0;
                        })
                        .forEach((key, idx) => {
                            if (action.items[key].display_order) action.items[key].lastSaveState.display_order = action.items[key].display_order;
                            action.items[key].display_order = idx
                        })
                })
                return { ...state, items: action.items }

            // Initializes FAQ list; Adjusts display_order
            case "HANDLE_BUILD_ITEM_ARRAY":
                function mergeWithHelper(objValue, srcValue) {
                    return { ...objValue, ...srcValue }
                }


                let itemsSelected = {}
                let unsavedItemsToKeep = {}
                if (action.keepSelection) {
                    itemsSelected = Object.fromEntries(new Map(Object.keys(state.items).map(key => {
                        return [key, { isSelected: state.items[key].isSelected ? true : false }]
                    })))
                }
                Object.keys(action.items).forEach(key => {
                    if (state.unsavedItems[key] && (state.mostRecentDragDrop.includes(action.items[key].id) || state.mostRecentMoveByBtn.includes(action.items[key].id))) {
                        unsavedItemsToKeep[key] = state.unsavedItems[key]
                    }

                })

                // Merge previously saved items from database with unsaved items from session. If previously saved items were modified, keep the modified versions.
                let combinedItems = { ...state.unsavedItems, ...action.items, ...unsavedItemsToKeep }
                if (action.keepSelection) mergeWith(combinedItems, itemsSelected, mergeWithHelper)

                orderedTabNamesCopy.forEach(tab => {
                    Object.keys(combinedItems)
                        .filter(key => combinedItems[key].category == tab)
                        .sort((a, b) => {
                            if (combinedItems[a].display_order > combinedItems[b].display_order) return 1;
                            if (combinedItems[a].display_order < combinedItems[b].display_order) return -1;
                            return 0;
                        })
                        .forEach((key, idx) => {
                            if (combinedItems[key].display_order) combinedItems[key].lastSaveState.display_order = combinedItems[key].display_order;
                            combinedItems[key].display_order = idx
                        })
                })
                return { ...state, initLoad: action.init ? action.init : state.initLoad, items: { ...combinedItems } }

            case "HANDLE_DESELECT_ITEMS":
                Object.keys(itemsCopy)
                    .forEach(key => itemsCopy[key].isSelected = action.value)
                return { ...state, allSelected: !state.allSelected, items: { ...itemsCopy } }

            case "HANDLE_UPDATE_STATUS_SELECTED":
                let selection = { ...action.selection }
                Object.keys(selection).forEach(item => {
                    selection[item].status = action.value
                    selection[item].isSelected = false
                })
                return { ...state, allSelected: false, items: { ...state.items, ...selection } }


            // Item Operations

            // Handles an item's checkbox being toggled; triggered when an item's checkbox is clicked
            case "HANDLE_ANY_SELECTED":
                return { ...state, anySelected: find(Object.keys(itemsCopy), (key) => itemsCopy[key].isSelected) != undefined }

            case "HANDLE_ITEM_SELECTED":
                return { ...state, items: { ...state.items, [action.itemId]: { ...state.items[action.itemId], isSelected: action.currentTarget.checked } } }

            // Handles an FAQ Item's category being changed via it's dropdown; appends FAQ to FAQ list of category
            case "HANDLE_ITEM_CATEGORY_CHANGED":
                let prevCategory = itemsCopy[action.itemId].category    // Category that the item is being moved away from
                let removalActionObj = { type: "changeCategory", value: action.value, order: action.newTabIdx }
                srcTabReordered = reorderItemsInCategory(itemsCopy, prevCategory, [action.itemId], removalActionObj);        // Adjust the display_order of items in previous category after FAQ is moved
                let numInNewCategory = orderedKeysinCategory(itemsCopy, action.value).length                                                // Get length of list in new category in order to append to it
                return { ...state, items: { ...state.items, ...srcTabReordered, [action.itemId]: { ...state.items[action.itemId], category: action.value, category_order: action.newTabIdx, display_order: numInNewCategory } } }

            // Changes the active FAQ (having focus) whenever the FAQ is clicked;
            case "HANDLE_ACTIVE_ITEM_CHANGED":
                return { ...state, activeItem: { ...action.item } }

            // Changes the active FAQ's question field; triggered by key input
            case "HANDLE_ACTIVE_QUESTION_CHANGED":
                return {
                    ...state, items: {
                        ...state.items, [action.itemId]: {
                            ...state.items[action.itemId],
                            status: "edited",
                            question: action.value ? action.value : state.items[action.itemId].question,
                            lastSaveState: { ...state.items[action.itemId].lastSaveState, reverted: false }
                        }
                    }
                }

            // Changes the active FAQ's answer field; triggered by key input
            case "HANDLE_ACTIVE_ANSWER_CHANGED":
                return {
                    ...state, items: {
                        ...state.items, [action.itemId]: {
                            ...state.items[action.itemId],
                            status: "edited",
                            answer: action.value ? action.value : state.items[action.itemId].answer,
                            lastSaveState: { ...state.items[action.itemId].lastSaveState, reverted: false }
                        }
                    }
                }

            // Either confirms to delete an FAQ or to save it; triggered when an FAQ Item's Confirm button is clicked
            case "HANDLE_ITEM_STATUS_CONFIRM":
                if (action.status == "deleted") {   // User has already marked it as delete, now they are confirming to delete
                    let reorderedItems = {}
                    if (state.items[action.itemId] && !state.items[action.itemId].fromStorage) {
                        let newlySortedEntries = Object.keys(state.items)
                            .filter(key => state.items[action.itemId].category == state.items[key].category && key != action.itemId)
                            .sort((a, b) => {
                                if (state.items[a].display_order > state.items[b].display_order) return 1;
                                if (state.items[a].display_order < state.items[b].display_order) return -1;
                                return 0;
                            })
                            .map((key, idx) => {
                                return [key, { ...state.items[key], display_order: idx, lastSaveState: { ...state.items[key].lastSaveState, display_order: state.items[key].display_order } }]
                            })
                        reorderedItems = Object.fromEntries(new Map(newlySortedEntries))
                    }
                    return { ...state, items: { ...state.items, ...reorderedItems, [action.itemId]: { ...state.items[action.itemId], deleted: 1 } } }
                }
                else {                              // User is confirming the FAQ is correct as it is
                    return { ...state, items: { ...state.items, [action.itemId]: { ...state.items[action.itemId], isSavingOrLoading: true } } };
                }

            // Changes an FAQ Item's status to deleted, but doesn't delete right away. Confirm must be clicked after; triggered when an FAQ Item's Delete button is clicked
            case "HANDLE_ITEM_STATUS_DELETE":
                return { ...state, items: { ...state.items, [action.itemId]: { ...state.items[action.itemId], status: "deleted", lastSaveState: { ...state.items[action.itemId].lastSaveState, status: state.items[action.itemId].status } } } }

            // Reverses the status of the FAQ Item; triggered when an FAQ Item's Cancel button is clicked
            case "HANDLE_ITEM_STATUS_CANCEL":
                if (state.items[action.itemId].status == "deleted") {
                    return { ...state, items: { ...state.items, [action.itemId]: { ...state.items[action.itemId], status: state.items[action.itemId].lastSaveState.status } } }
                }
                else {
                    if (state.items[action.itemId].lastSaveState.reverted) return state;
                    return {
                        ...state, items: {
                            ...state.items,
                            [action.itemId]: {
                                ...state.items[action.itemId],
                                status: state.items[action.itemId].lastSaveState.status,
                                question: state.items[action.itemId].lastSaveState.question,
                                answer: state.items[action.itemId].lastSaveState.answer,
                                lastSaveState: { ...state.items[action.itemId].lastSaveState, reverted: true }
                            }
                        }
                    }
                }
            // Sets state of 'itemsBeforeDrag', which is a backup copy of items for if the drag 'fails'
            case "HANDLE_DRAG_ITEM_OCCURING":
                return { ...state, itemsBeforeDrag: { ...state.items } }

            case "HANDLE_EDITING_TAB_NAME":
                if (action.value == null) {
                    return { ...state, editingTabNameIdx: action.value, mostRecentTabNameChange: "" }
                }
                return { ...state, editingTabNameIdx: action.value, mostRecentTabNameChange: state.orderedTabNames[action.value] }

            // Moves the FAQ Item UP or DOWN, depending on which of the two is pressed; triggered by an FAQ Item's UP and DOWN buttons 
            case "HANDLE_ITEM_MOVE_BY_BTN":
                let targetIdx = action.item.display_order + action.direction
                itemsInCategory = orderedKeysinCategory(itemsCopy, state.orderedTabNames[state.activeTab])
                //Don't do anything if UP button of first item was clicked or DOWN button of bottom item was clicked
                if (targetIdx < 0 || targetIdx > Object.keys(itemsInCategory).length - 1) return state;

                let target = itemsCopy[itemsInCategory[targetIdx]];

                return {
                    ...state, mostRecentMoveByBtn: [action.item.id, target.id], items: {
                        ...state.items,
                        [action.item.id]: { ...state.items[action.item.id], display_order: target.display_order, lastSaveState: { ...action.item.lastSaveState, display_order: action.item.display_order } },
                        [target.id]: { ...state.items[target.id], display_order: action.item.display_order, lastSaveState: { ...target.lastSaveState, display_order: target.display_order } },
                    }
                }
            case "HANDLE_MOVE_ITEMS":
                const { orderedTabNames, activeTab } = state
                let moveChangedTabs = action.src.category != action.dest.category;        // True if the move stayed in same tab, False, if move changed tabs

                if (!moveChangedTabs && action.src.display_order == action.dest.display_order) return state;      // Exit if dragged FAQ was dropped in its own dropbox above in the same tab
                if (!moveChangedTabs && action.dest.display_order - action.src.display_order == 1) return state;   // Exit if dragged FAQ was dropped in its own dropbox below in the same tab

                itemsInCategory = orderedKeysinCategory(itemsCopy, action.dest.category)
                let draggedToEnd = action.dest.display_order == -1;
                let droppedAboveBy1 = (action.src.display_order - action.dest.display_order) == 1 || (action.src.display_order - action.dest.display_order) == 0;
                let droppedBelowBy1 = action.dest.display_order - action.src.display_order == 2
                let droppedAboveByX = action.dest.display_order < action.src.display_order && !droppedBelowBy1 && !droppedAboveBy1
                let droppedBelowByX = action.dest.display_order > action.src.display_order && !droppedBelowBy1 && !droppedAboveBy1

                let rearrangedIndices = range(itemsInCategory.length)
                let srcIdx = action.src.display_order
                let destIdx = action.dest.display_order

                let idsWithChangedDisplayOrder = []

                if (moveChangedTabs) {      // Add item's id to itemId list
                    itemsInCategory.push(action.src.id)
                    srcIdx = itemsInCategory.length - 1
                }
                else rearrangedIndices = without(rearrangedIndices, srcIdx)    // Remove the item's old index from list of indices

                if (draggedToEnd) { rearrangedIndices.push(srcIdx) }    // Item was dropped at the end, so simply append it

                else if (droppedAboveBy1 || droppedAboveByX) {      // Item was dropped at X (X>=1) positions above itself
                    rearrangedIndices = [...rearrangedIndices.slice(0, destIdx), srcIdx, ...rearrangedIndices.slice(destIdx)]
                }
                else if (droppedBelowBy1) {     // Dropping item below one position is special case, due to how FAQ items are designed
                    rearrangedIndices = [...rearrangedIndices.slice(0, srcIdx), destIdx - 1, srcIdx, ...rearrangedIndices.slice(destIdx - 1)]
                }
                else if (droppedBelowByX) {     // Item was dropped at X (X>1) positions below itself
                    rearrangedIndices = [...rearrangedIndices.slice(0, destIdx - 1), srcIdx, ...rearrangedIndices.slice(destIdx - 1)]
                }

                // Creates a function that invokes 'argsToArray' ( which just converts args to array). The array returned from 'argsToArray' is rearranged according to indices in 'rearrangedIndices'
                let rearged = rearg(argsToArray, [...rearrangedIndices])
                let destTabReordered = {}
                let rearranged = flatten(rearged(...itemsInCategory))

                if (moveChangedTabs) {
                    let newCategoryIdx = orderedTabNames.indexOf(action.dest.category)
                    // After inserting dragged item in destination tab, reorder the items in destination tab 
                    destTabReordered = Object.fromEntries(new Map(rearranged.map((id, idx) => [id, { ...itemsCopy[id], display_order: idx, category: action.dest.category, category_order: newCategoryIdx, lastSaveState: { ...itemsCopy[id], display_order: itemsCopy[id].display_order } }])))

                    // Remove dragged item from source tab
                    let removalActionObj = { type: "changeCategory", value: orderedTabNames[activeTab], order: activeTab }
                    srcTabReordered = reorderItemsInCategory(itemsCopy, action.src.category, [action.src.id], removalActionObj)
                    let srcIds = Object.keys(srcTabReordered).filter(key => key != action.src.id).map(key => {
                        let obj = srcTabReordered[key]
                        if (obj.display_order != obj.lastSaveState.display_order) return key;
                        return null
                    })

                    let destIds = Object.keys(destTabReordered).map(key => {
                        let obj = destTabReordered[key]
                        if (obj.id == action.src.id) return key
                        if (obj.display_order != obj.lastSaveState.display_order) return key;
                        return null
                    })

                    // In src tab, detecting which FAQ items were shifted so that they can be saved later
                    idsWithChangedDisplayOrder.push({ category: action.src.category, ids: srcIds })
                    idsWithChangedDisplayOrder.push({ category: action.dest.category, ids: destIds })
                }
                else {
                    destTabReordered = Object.fromEntries(new Map(rearranged.map((id, idx) => [id, {
                        ...itemsCopy[id], display_order: idx,
                        lastSaveState: { ...itemsCopy[id], display_order: itemsCopy[id].display_order }
                    }])))
                    let destIds = Object.keys(destTabReordered).map(key => {
                        let obj = destTabReordered[key]
                        if (obj.id == action.src.id) return key
                        if (obj.display_order != obj.lastSaveState.display_order) return key;
                        return null
                    })
                    idsWithChangedDisplayOrder.push({ category: action.dest.category, ids: destIds })
                }

                // 'itemsCopy' consists of items unaffected by operation, 'srcTabReordered' consists of reordered items from source tab (empty if not applicable), 'destTabReordered' consists of reordered items in dest tab after insertion
                return { ...state, items: { ...itemsCopy, ...srcTabReordered, ...destTabReordered }, mostRecentDragDrop: idsWithChangedDisplayOrder }
            case "HANDLE_SAVE_REORDER_FINISHED":
                return { ...state, mostRecentOrderChange: null };

            case "HANDLE_SORT_DIRECTION_CHANGE":
                return { ...state, sortDirection: action.value }

            case "HANDLE_SORT_OPERATION_CHANGE":
                if (action.value == "None") {
                    return { ...state, sortOperation: null, sortDirection: null }
                }
                else {
                    return { ...state, sortOperation: action.value, sortDirection: state.sortDirection ? state.sortDirection : "Ascending" }
                }

            case "RECALC_FAQS_RATINGS":
                Object.keys(itemsCopy).forEach(key => {
                    let ratingsExist = filter(faqsRatings, { faq_id: key });
                    itemsCopy[key].helpfulness = reduce(ratingsExist, (sum, obj) => { return sum + obj.rating }, 0);
                    itemsCopy[key].view_count = ratingsExist.length

                })
                return { ...state, items: { ...itemsCopy }, faqsRatings: { ...faqsRatings } }

            case "HANDLE_RESET_ALL_ITEMS_RENDER_STATUS":
                Object.keys(itemsCopy).forEach((key) => {
                    itemsCopy[key] = { ...itemsCopy[key], isSavingOrLoading: false }
                })
                return { ...state, items: { ...itemsCopy } }


            case "HANDLE_UNSAVED_ITEMS":
                return {
                    ...state, unsavedItems: Object.fromEntries(new Map(Object.keys(itemsCopy).filter(key => ["deleted", "edited", "new"].includes(itemsCopy[key].status) && key != action.targetId).map(id => [id, itemsCopy[id]])))
                }
            case "HANDLE_SAVING_TAB_DATA":
                return { ...state, tabsAreSaving: action.value }

            default:
                return state;
        }
    }
    const [state, dispatch] = useReducer(mainReducer, initState);


    const handleModeBtnClicked = (e) => {
        if (e.currentTarget.name == "editorModeBtn") dispatch({ type: "HANDLE_MODE_CHANGED", value: "editor" })
        else if (e.currentTarget.name == "previewModeBtn") dispatch({ type: "HANDLE_MODE_CHANGED", value: "preview" })
    }
    const buildOrderedTabNames = (items) => {
        // Categories are not a table in database. Instead, fetched items are looped through to find unique categories. Each FAQ also has a column for category order, which is the order for their category
        // Find unique categories
        let uniqueCategories = [];
        Object.keys(items).forEach((item_id) => {
            let category = items[item_id].category
            if (category != undefined && !uniqueCategories.includes(category)) {
                uniqueCategories.push(category)
            }
        })
        // For each unique category, find its tab-order from the first available FAQ, then break loop and move to next category
        let tabNames = []
        let itemList = Object.entries(items).map(x => { return { ...x[1], id: x[0] } })
        uniqueCategories.forEach(cat => {
            let firstItemInCategory = find(itemList, (item) => { return (item.category == cat) })
            if (firstItemInCategory) tabNames.push([firstItemInCategory.category_order, cat])
        })

        // Put Categories in order
        tabNames.sort((a, b) => {
            if (a[0] > b[0]) return 1;
            if (a[0] < b[0]) return -1;
            return 0;
        });
        tabNames = tabNames.map(cat => cat[1])
        dispatch({ type: 'HANDLE_UPDATE_TAB_NAMES', items: tabNames })
    }

    const handleCrudOnTab = async (target, tabName = null) => {
        let operation = target.operation;
        let targetItemId = target.id
        if (["saved", "deleted"].includes(operation)) dispatch({ type: "HANDLE_UNSAVED_ITEMS", targetId: targetItemId })
        if (operation == "itemMove") dispatch({ type: "HANDLE_UNSAVED_ITEMS" })

        if (operation == "changeCategory" && state.items[targetItemId].fromStorage) {
            doFaqsSave({ id: targetItemId, category_order: state.orderedTabNames.indexOf(tabName), category: tabName, display_order: Object.keys(state.items).filter(item => item.category == tabName).length })
            return;
        }
        let itemsToSave = Object.keys(state.items).filter(i => state.items[i].category == tabName).map(key => {
            let contentChanged = state.items[key].lastSaveState.question != state.items[key].question || state.items[key].lastSaveState.answer != state.items[key].answer
            let orderChanged = state.items[key].lastSaveState.display_order != state.items[key].display_order

            if (operation == "tabMove" && state.items[key].fromStorage) {
                let { id, category, category_order } = state.items[key]
                if (!state.items[key].deleted && state.items[key].fromStorage) {     // Item is already in database so update it with any possible new data 
                    return { fn: doFaqsSave, args: { id, category, category_order } }
                }
            }
            else if (operation == 'deleted' && targetItemId == key && state.items[key].fromStorage) {
                let { id } = state.items[key]
                return { fn: doFaqsDelete, args: { id } }
            }
            else if (operation == 'deleted' && targetItemId == "selected" && state.items[key].isSelected && state.items[key].fromStorage) {
                let { id } = state.items[key]
                return { fn: doFaqsDelete, args: { id } }
            }
            else if (operation == 'deleted' && targetItemId == "all" && state.items[key].fromStorage) {
                let { id } = state.items[key]
                return { fn: doFaqsDelete, args: { id } }
            }
            else if (operation == "itemMove" && (!["deleted", "new", "edited"].includes(state.items[key].status))) {
                let { id, question, answer, category, deleted, category_order, display_order } = state.items[key]
                if ((!contentChanged || orderChanged) && !state.items[key].deleted && state.items[key].fromStorage) {     // Item is already in database so update it with any possible new data 
                    return { fn: doFaqsSave, args: { id, question, answer, category, category_order, display_order, deleted } }
                }
            }

            else if (operation == "saved") {
                if (targetItemId == key || (!["deleted", "new", "edited"].includes(state.items[key].status))) {
                    let { id, question, answer, category, deleted, category_order, display_order } = state.items[key]
                    if ((contentChanged || orderChanged) && !state.items[key].deleted && state.items[key].fromStorage) {     // Item is already in database so update it with any possible new data 
                        return { fn: doFaqsSave, args: { id, question, answer, category, category_order, display_order, deleted } }
                    }
                    else if ((contentChanged || orderChanged) && !state.items[key].deleted && !state.items[key].fromStorage) {       // Item is a new FAQ but only save it if it wasn't deleted 
                        return { fn: doFaqsSave, args: { question, answer, category, category_order, display_order, deleted, last_updated_content_date: new Date(), last_updated_by: tokenKeyCloakId } }
                    }
                }
            }
            else return { fn: async () => { }, args: null }
        })
        await Promise.all(itemsToSave.map(item => {
            if (item) item.fn(Object.assign({}, item.args))
        }))
    }

    // Handler function for doDialogOpen
    const handleCRUD = async (target, tabName = null, onlyTarget = false) => {
        const { orderedTabNames } = state
        let targetOperation = target.operation;
        let targetItemId = target.id ? target.id : null;
        if (onlyTarget) {
            let { id, question, answer, category, deleted, category_order, display_order } = state.items[targetItemId]
            if (targetOperation == "itemMove") {
                if (state.items[targetItemId].status == 'saved' && !state.items[targetItemId].deleted && state.items[targetItemId].fromStorage) {     // Item is already in database so update it with any possible new data 
                    doFaqsSave(Object.assign({}, { id, question, answer, category, category_order, display_order, deleted }))
                }
            }
            else if (targetOperation == "saved") {
                if (!state.items[targetItemId].deleted && state.items[targetItemId].fromStorage) {     // Item is already in database so update it with any possible new data 
                    doFaqsSave(Object.assign({}, { id, question, answer, category, category_order, display_order, deleted }))
                }
                else if (!state.items[targetItemId].deleted && !state.items[targetItemId].fromStorage) {       // Item is a new FAQ but only save it if it wasn't deleted 
                    doFaqsSave(Object.assign({}, { question, answer, category, category_order, display_order, deleted }))
                }
            }

        }
        else if (tabName) await handleCrudOnTab({ operation: targetOperation, id: targetItemId }, tabName)
        else {
            // Iterate through each tab, find which ones are marked for deletion and update them. If they are stored and marked for deletion, call 'doFaqsDelete'
            for (const tab of orderedTabNames) {
                // Two 'forEach' loops are used because the index of each item in list becomes the display_order. Deleted items need to be separated in order for this to be correct.
                await handleCrudOnTab({ operation: targetOperation, id: targetItemId }, tab)
            }
        }
    }

    const handleViewModeChange = (e) => {
        dispatch({ type: "HANDLE_VIEW_MODE_CHANGE", value: !state.categoryViewMode })
    }


    const renderHeader = () => {
        return (
            <div className="card-header">
                <div className="d-flex">
                    <i className="mdi mdi-view-list mr-2" />
                    Frequently Asked Questions
                </div>
            </div>
        );
    }

    // Executes only once upon page load. Handles process of fetching FAQs from database
    useEffect(() => {
        if (!state.initLoad) {
            // For every FAQ Item fetched from database, set 'fromStorage' to true
            let itemsFromStorage = faqs
                .filter(item => {
                    if (item.statusCode == 404) return -1;
                    return 1;
                })
                .map(item => {
                    return [item.id, { ...item, status: "saved", fromStorage: true, lastSaveState: { display_order: item.display_order, question: item.question, answer: item.answer, status: "saved", reverted: true } }]
                })

            // If FAQ Items are found in database, initialize them into state.items
            if (itemsFromStorage.length > 0) {
                itemsFromStorage = Object.fromEntries(new Map(itemsFromStorage));
                buildOrderedTabNames(itemsFromStorage)
                dispatch({ type: "HANDLE_BUILD_ITEM_ARRAY", items: { ...itemsFromStorage }, initLoad: true })
                dispatch({ type: "RECALC_FAQS_RATINGS" })
            }
        }
    }, [faqs,]);



    // Saves arbitrary number of items after a drag-and-drop operation
    useEffect(() => {
        const asyncItemsMove = async () => {
            for (const move of state.mostRecentDragDrop) {
                for (const id of move.ids) {
                    if (id && state.items[id].status == 'saved') await handleCRUD({ operation: 'itemMove', id: id }, move.category)
                }
            }
        }
        if (state.mostRecentDragDrop) {
            asyncItemsMove().catch(() => { })
        }
    }, [state.mostRecentDragDrop])

    // Saves pair of items when their order is changed by their arrow buttons
    useEffect(() => {
        const asyncItemsMove = async () => {
            let id1 = state.mostRecentMoveByBtn[0];
            let id2 = state.mostRecentMoveByBtn[1];
            if (id1 && state.items[id1].status == 'saved') await handleCRUD({ operation: 'itemMove', id: id1 }, state.items[id1].category, true)
            if (id2 && state.items[id2].status == 'saved') await handleCRUD({ operation: 'itemMove', id: id2 }, state.items[id2].category, true)
        }
        if (state.mostRecentMoveByBtn) {
            asyncItemsMove().catch(() => { })
        }
    }, [state.mostRecentMoveByBtn])

    useEffect(() => {
        const asyncTabsMove = async () => {
            let cat1 = state.orderedTabNames[state.mostRecentTabMove[0]];
            let cat2 = state.orderedTabNames[state.mostRecentTabMove[1]];
            await handleCRUD({ operation: 'tabMove', id: 'all' }, cat1)
            await handleCRUD({ operation: 'tabMove', id: 'all' }, cat2)
        }
        if (state.mostRecentTabMove) {
            asyncTabsMove().catch(() => { })
        }

    }, [state.mostRecentTabMove])


    const renderControls = () => {

        return (
            <div className="clearfix mt-2 mb-4 d-block">
                {state.mode == "preview" &&
                    <div className="float-left">
                        <div className="btn-group mr-1 p-2" role="group">
                            <button type="button" className={`btn btn-${!state.categoryViewMode ? 'primary' : 'outline-primary'}`} onClick={handleViewModeChange}>
                                All
                            </button>
                            <button type="button" className={`btn btn-${state.categoryViewMode ? 'primary' : 'outline-primary'}`} onClick={handleViewModeChange}>
                                By Category
                            </button>
                        </div>
                    </div>
                }
                <div className="float-right">
                    <RoleFilter allowRoles={["HQ.ADMIN"]}>
                        <div className="btn-group mr-2 p-2" role="group">
                            <button name="previewModeBtn" type="button" className={`btn btn-sm btn-${state.mode == 'preview' ? 'primary' : 'outline-primary'}`} onClick={handleModeBtnClicked}>
                                <i className="mdi mdi-eye icon-inline" />
                                Preview
                            </button>
                            <button name="editorModeBtn" type="button" className={`btn btn-sm btn-${state.mode == 'editor' ? 'primary' : 'outline-primary'}`} onClick={handleModeBtnClicked}>
                                <i className="mdi mdi-pencil icon-inline" />
                                Editor
                            </button>
                        </div>
                    </RoleFilter>
                </div>
            </div>
        )

    }

    return (

        <div className="container-fluid w-75 h-auto" style={{ marginTop: "80px" }}>
            <div className="card">
                {renderHeader()}
                <div className="card-body">
                    {renderControls()}
                    <TabsContainer state={state} dispatch={dispatch} canEdit={canEdit} faqsRatings={faqsRatings} handleCRUD={handleCRUD} />
                </div>
            </div>
        </div>
    );
}
export default connect(
    "doFaqsSave",
    "doFaqsDelete",
    "doDialogOpen",
    "selectFaqsItems",
    "selectFaqsRatingsItems",
    "selectTokenPayload",
    FaqsEditor
);