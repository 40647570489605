import React from "react";
import { connect } from "redux-bundler-react";


const DeleteSelectionConfirm = ({ mode, doDialogClose, deleteHandler, tabIdx, selection }) => {
    const displayDeletedItems = () => {
        return Object.keys(selection).map((item, idx) => {
            return <li key={idx}>Category={selection[item].category}, Index={selection[item].display_order}</li>
        })

    }
    const bodyTextOptions = {
        "tab": `WARNING: This will delete every FAQ contained in this category. Before proceeding, be sure to move any FAQs that you wish to keep in different categories.`,
        "selection": "WARNING: This will delete every FAQ listed below: "
    }
    const cancel = (e) => {
        doDialogClose(e);
    }
    const confirm = async (e) => {
        if (mode == "tab") await deleteHandler(tabIdx)
        else if (mode == "selection") await deleteHandler(selection)
        doDialogClose(e);
    }

    return (
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Are you sure?</h5>
                <button
                    onClick={doDialogClose}
                    className="close"
                    type="button"
                    aria-label="Close"
                >
                    <span aria-hidden="true">×</span>
                </button>
            </div>

            <div className="modal-body">
                <p>
                    {bodyTextOptions[mode]}
                </p>
                {mode == "selection" &&
                    <ul >
                        {displayDeletedItems()}
                    </ul>

                }
            </div>

            <div className="modal-footer">
                <button className="btn btn-sm btn-secondary" type="button" onClick={cancel}>
                    Cancel
                </button>
                <button className="btn btn-sm btn-danger" type="button" onClick={confirm}>
                    Delete
                </button>
            </div>
        </div>
    );
}


export default connect(
    "doDialogClose",
    DeleteSelectionConfirm
);