import React from "react";
import classnames from "classnames";
const NavBarItemDrawing = ({ title, iconClsName, isDropdown, isDropdownItem  }) => {
  const iconCls = classnames({
    [iconClsName]: iconClsName,
    "nav-icon": true,
  });
  return (
    <div className={`menu-item-drawing ${isDropdownItem ? "in-dropdown" : ""}`}>
      <i className={iconCls} /> {title} {isDropdown && <i class="mdi mdi-menu-down" />}
    </div>
  );
}
export default NavBarItemDrawing;