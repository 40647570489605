import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "gcsSoftware",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/gcs/:gcsSlug/software",
  putTemplate: "/orgs/:orgSlug/gcs/:gcsSlug/software/:item.id",
  postTemplate: "/orgs/:orgSlug/gcs/:gcsSlug/software",
  deleteTemplate: "/orgs/:orgSlug/gcs/:gcsSlug/software/:item.id",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN"]
});
