import { find, groupBy } from "lodash";
import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";
export default createRestBundle({
  name: "aircraftConfiguration",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/aircraft-configurations",
  putTemplate: "/aircraft-configurations/:item.id",
  postTemplate: "/aircraft-configurations",
  deleteTemplate: "/aircraft-configurations/:item.id",
  fetchActions: [
    "AUTH_LOGGED_IN",
    "URL_UPDATED",
    "PLATFORMS_FETCH_FINISHED",
    "AIRCRAFTCONFIGURATION_SAVE_FINISHED",
    "AIRCRAFTCONFIGURATION_DELETE_FINISHED",
  ],
  reduceFurther: (state, { type, payload }) => {
    switch (type) {
      case "SET_FILTERED_CONFIGURATIONS":
        return Object.assign({}, state, payload);
      default:
        return state;
    }
  },
  addons: {
    selectAircraftConfigurationItemsByComponentRoute: createSelector(
      "selectAircraftConfigurationComponentByRoute",
      "selectAircraftConfigurationItems",
      (aircraftConfigurationComponentByRoute, aircraftConfigurationItems) => {
        if (!aircraftConfigurationComponentByRoute) return [];
        const { componentType } = aircraftConfigurationComponentByRoute;
        let platformIdKey =
          componentType === "aircraft" ? "platform_id" : "parent_id";
        if (aircraftConfigurationComponentByRoute[platformIdKey]) {
          return aircraftConfigurationItems.filter(
            (item) =>
              item[`${componentType}_id`] ===
              aircraftConfigurationComponentByRoute[platformIdKey]
          );
        }
        return null;
      }
    ),
    selectAircraftConfigurationComponentByRoute: createSelector(
      "selectAircraftByRoute",
      "selectPayloadsByRoute",
      "selectGcsByRoute",
      (aircraftByRoute, payloadsByRoute, gcsByRoute) => {
        if (!gcsByRoute && !aircraftByRoute && payloadsByRoute) {
          return { ...payloadsByRoute, componentType: "payload" };
        } else if (!gcsByRoute && aircraftByRoute && !payloadsByRoute) {
          return { ...aircraftByRoute, componentType: "aircraft" };
        } else if (gcsByRoute && !aircraftByRoute && !payloadsByRoute) {
          return { ...gcsByRoute, componentType: "gcs" };
        }
        return {};
      }
    ),

    selectAircraftConfigurationsByPlatform: createSelector(
      'selectAircraftConfigurationItems',
      "selectFilteredAircraftConfigurations",
      "selectPlatformsItems",
      (aircraftConfigurationItems, filteredAircraftConfigurations, platformsItems) => {
        let aircraftConfigurations = filteredAircraftConfigurations ?? aircraftConfigurationItems;
        let configsByPlatform = {};
        let groupByPlatform = groupBy(aircraftConfigurations, 'aircraft_id');
        // Building object
        Object.keys(groupByPlatform).forEach(platformId => {
          let platform = find(platformsItems, { id: platformId });
          if (!platform) return {}; // no configuration for this platform
          let configKey = `${platform.make} ${platform.model}`;
          if (!configsByPlatform[configKey]) configsByPlatform[configKey] = { ...platform, configurations: [] };
          groupByPlatform[platformId].forEach(configurations => {
            if (configurations.gcs_id) {
              if (configsByPlatform[configKey]) {
                configsByPlatform[configKey].configurations = [
                  ...configsByPlatform[configKey].configurations,
                  {
                    platform_id: platformId,
                    type: "GCS",
                    make: configurations.gcs_make,
                    model: configurations.gcs_model,
                    equipment_id: configurations.gcs_id
                  }
                ]
              } else {
                configsByPlatform[configKey].configurations = [
                  {
                    platform_id: platformId,
                    type: "GCS",
                    make: configurations.gcs_make,
                    model: configurations.gcs_model,
                    equipment_id: configurations.gcs_id
                  }
                ];
              }
            }
            if (configurations.payload_id) {
              if (configsByPlatform[configKey]) {
                configsByPlatform[configKey].configurations = [
                  ...configsByPlatform[configKey].configurations,
                  {
                    platform_id: platformId,
                    type: "Payload",
                    make: configurations.payload_make,
                    model: configurations.payload_model,
                    equipment_id: configurations.payload_id
                  }
                ]
              } else {
                configsByPlatform[configKey].configurations = [
                  {
                    platform_id: platformId,
                    type: "Payload",
                    make: configurations.payload_make,
                    model: configurations.payload_model,
                    equipment_id: configurations.payload_id
                  }
                ];
              }
            }
          })
        });
        let sorted = {};
        Object.keys(configsByPlatform).sort().forEach(key => {
          sorted[key] = configsByPlatform[key];
        });
        return sorted;
      }
    ),

    selectFilteredAircraftConfigurations: state => state.aircraftConfiguration.filtered,
    selectAircraftConfigurationFilters: state => state.aircraftConfiguration.filters,

    doFilterAircraftConfigurations: (obj, filterType) => ({ store, dispatch }) => {
      let configurations = store.selectAircraftConfigurationItems();
      let filters = store.selectAircraftConfigurationFilters() ?? {};
      let updatedFilters = { ...filters, [filterType]: obj ? obj.value : null };
      let arr = configurations;
      Object.keys(updatedFilters).forEach(idType => {
        if (!updatedFilters[idType]) return;
        arr = arr.filter(config => (config[idType] === updatedFilters[idType]))
      })
      dispatch({
        type: "SET_FILTERED_CONFIGURATIONS",
        payload: { filtered: arr, filters: updatedFilters }
      })
    },
  },
});
