import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "personnelDocs",
  uid: "s3_key",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/personnel/:id/docs",
  fetchActions: [
    "URL_UPDATED",
    "AUTH_LOGGED_IN",
    "PERSONNEL_FETCH_FINISHED",
    "FILE_IO_UPLOAD_FINISHED",
    "FILE_IO_DELETE_FINISHED"
  ],
  urlParamSelectors: ["selectPersonnelByRoute"]
});
