import React from "react";
import { connect } from "redux-bundler-react";
import equipmentModal from "./equipment-modal";

const ModalButton = ({ children, doDialogOpen, data }) => {
  return (
    <a
      className="link text-dark p-0 m-0"
      style={{ fontWeight: "bold" }}
      onClick={() =>
        doDialogOpen({
          content: equipmentModal,
          props: { transfer: data },
        })
      }>
      {children}
    </a>
  );
};
const EquipmentRenderer = ({ doDialogOpen, ...props }) => {
  let equipObj = {};
  equipObj.Aircraft = props.data.equipment_status.filter(
    (e) => e.type === "aircraft"
  ).length;
  equipObj.Batteries = props.data.equipment_status.filter(
    (e) => e.type === "batteries"
  ).length;
  equipObj.Payloads = props.data.equipment_status.filter(
    (e) => e.type === "payload"
  ).length;
  equipObj.Mms = props.data.equipment_status.filter(
    (e) => e.type === "mms"
  ).length;
  equipObj.Gcs = props.data.equipment_status.filter(
    (e) => e.type === "gcs"
  ).length;

  if (props.data.equipment_status.length > 0) {
    return (
      <ModalButton doDialogOpen={doDialogOpen} data={props.data}>
        <div className="d-flex flex-wrap align-items-center">
          {Object.keys(equipObj).map((key, i) => {
            if (equipObj[key] > 0) {
              return (
                <div className="d-flex m-0 mr-1" key={key}>
                  <b className="mr-1">{equipObj[key]}</b> {key}
                </div>
              );
            }
          })}
          <i className="mdi mdi-chevron-right text-dark mdi-14px d-flex" />
        </div>
      </ModalButton>
    );
  } else return <span />;
};
export default connect("doDialogOpen", EquipmentRenderer);
