import React, { useEffect, useState } from "react"
import classnames from "classnames"
import { connect } from "redux-bundler-react";
import List from "../_shared/item-list/item-list";
import { keyBy, find } from "lodash";

const EquipmentRequestsCardSection = ({
  items, equipmentRequestCountsTotals, doEquipmentRequestUpdateSelected, show, platformsAll,
  equipmentRequestCountsItemsObject, equipmentRequestQueryString, listProps,
  onSectionClick, name, stateAttr, routeInfo, doUpdateRelativeUrl
}) => {
  const [state, setState] = useState({
    items: items.map((item, key) => {
      let platform = keyBy(platformsAll, 'id')[item.platform_id];
      return {
        ...item, title: (
          <div key={key} className="col d-flex flex-column">
            {platform && <span className="text-muted">{platform.make} {platform.model}</span>}
            <h6>{item.title}</h6>
          </div>
        )
      }
    }),
  });
  const { _orgId, _type } = equipmentRequestQueryString;

  const _onClick = (e) => {
    const { orgSlug} = routeInfo.params;
    let name = e.currentTarget.getAttribute("name");
    let selected = find(state.items, ['slug', name]);
    doUpdateRelativeUrl(`/${orgSlug}/equipment-requests/${selected.slug}`)
    doEquipmentRequestUpdateSelected({ ...selected, parent_id: selected.platform_id });
  }

  const btnCls = classnames({
    "btn btn-lg btn-light d-inline-flex justify-content-start mt-2": true,
    "border border-bottom-0": show
  });
  const sectionCls = classnames({
    "collapse border border-top-0 rounded-bottom": true,
    "show": show
  });
  
  const { _totalAdd, _totalDeactivate } = equipmentRequestCountsTotals;
  let newCount = 0;
  let orgKey = _orgId ? _orgId : 'All';
  let countkey = `${name.toLowerCase()}_count`;

  if (equipmentRequestCountsItemsObject[orgKey]) {
    newCount = equipmentRequestCountsItemsObject[orgKey][countkey];
  }
  else if (countkey === 'add_count') newCount = _totalAdd;
  else if (countkey === 'deactivate_count') newCount = _totalDeactivate;
  useEffect(() => {
    if (items) {
      setState({
        ...state, items: items.map((item, key) => {
          let platform = keyBy(platformsAll, 'id')[item.platform_id];
          return {
            ...item, title: (
              <div key={key} className="col d-flex flex-column">
                {platform && <span className="text-muted">{platform.make} {platform.model}</span>}
                {item.title}
              </div>
            )
          }
        })
      })
    }
  }, [items]);

  listProps = {
    ...listProps,
    itemProps: {
      ...listProps.itemProps,
      onItemClick:_onClick
    }
  }
  return (
    <>
      <button name={stateAttr} className={btnCls} onClick={onSectionClick}>
        {name}
        {_type === 'All' && <span className="badge badge-danger badge-pill ml-2">{newCount}</span>}
        <span className={`mdi mdi-chevron-${show ? "up" : "down"} ml-auto`} />
      </button>
      <div className={sectionCls} style={{ marginTop: "-5px" }}>
        <div className="card-body d-flex flex-column  align-items-between">
          <List {...listProps} items={state.items || []} />
        </div>
      </div>
    </>
  )
}

export default connect(
  "doUpdateRelativeUrl",
  "selectRouteInfo",
  "selectEquipmentRequestCountsTotals",
  "selectEquipmentRequestCountsItemsObject",
  "doEquipmentRequestUpdateSelected",
  "selectPlatformsAll",
  "selectEquipmentRequestQueryString",
  EquipmentRequestsCardSection
)