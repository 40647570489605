import xhr from "xhr";
import { createSelector } from "redux-bundler";
import { formatDistanceToNow } from "date-fns";
import Keycloak from "../utils/Keycloak";

const keycloakHost = import.meta.env.VITE_KEYCLOAK_HOST;
const keycloakCHost = import.meta.env.VITE_KEYCLOAK_HOST_CAC;
const keycloakRealm = import.meta.env.VITE_KEYCLOAK_REALM;
const keycloakClient = import.meta.env.VITE_KEYCLOAK_CLIENT;
let keycloak = null;

const getTokenPart = function (token, part) {
  const splitToken = token.split(".");
  return splitToken[part];
};

export default {
  name: "auth",

  getReducer() {
    const initialData = {
      token: "",
      isloggingIn: false,
      err: null,
      _shouldCheckToken: true,
      _lastChecked: 0,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "AUTH_LOGGED_IN":
        case "AUTH_LOGGING_IN":
        case "AUTH_LOGGED_ERROR":
        case "AUTH_REFRESH":
        case "AUTH_CHECK_TOKEN":
          return Object.assign({}, state, payload);
        case "AUTH_LOGGED_OUT":
          return Object.assign({}, state, {
            token: "",
            err: null,
          });
        case "APP_IDLE":
          return Object.assign({}, state, {
            _shouldCheckToken: true,
          });
        default:
          return state;
      }
    };
  },
  init: (store) => {
    keycloak = new Keycloak({
      keycloakUrl: keycloakHost,
      keycloakCUrl: keycloakCHost,
      realm: keycloakRealm,
      client: keycloakClient,
      redirectUrl: `${document.location.origin}${document.location.pathname}`,
      refreshInterval: 600,
      sessionEndWarning: 1800,
      onAuthenticate: (token) => {
        store.doLogin(token);
      },
      onError: (err) => {
        store.doLoginError(null);
      },
      onSessionEnding: (remainingTime) => {
        if (remainingTime > 0) {
          store.doFireNotification(
            "warning",
            `Your session is expiring in ${Math.round(
              remainingTime / 60
            )} minutes.`
          );
        }
      },
    });
    keycloak.checkForSession();
  },

  doLoginError:
    (e) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "AUTH_LOGGED_ERROR",
        payload: { token: null, roles: null, err: e },
      });
    },

  doLogin:
    (token) =>
    ({ dispatch, store }) => {
      const isLoggedIn = store.selectIsLoggedIn();

      if (!isLoggedIn) {
        dispatch({
          type: "AUTH_LOGGED_IN",
          payload: { token: token, err: null },
        });
      } else {
        dispatch({
          type: "AUTH_REFRESH",
          payload: { token: token, err: null },
        });
      }

      const roles = store.selectTokenRoles();
      const root = store.selectApiRoot();
      const keycloakId = store.selectTokenKeyCloakId();
      if (!roles || (roles && roles.length < 1)) {
        const url = `${root}/roles/${keycloakId}`;
        xhr.get(
          {
            url: url,
            headers: {
              Authorization: "Bearer " + token,
            },
          },
          (err, res, body) => {
            if (err) {
              dispatch({
                type: "AUTH_LOGGED_ERROR",
                payload: { roles: [], err: err },
              });
            } else {
              try {
                const tokenParsed = JSON.parse(body);

                // if we're still alive we should be ok.
                dispatch({
                  type: "AUTH_LOGGED_IN",
                  payload: {
                    token: token,
                    isLoggingIn: false,
                    roles: JSON.parse(body),
                    err: null,
                  },
                });
              } catch (e) {
                dispatch({
                  type: "AUTH_LOGGED_ERROR",
                  payload: { token: null, roles: null, err: e },
                });
              }
            }
          }
        );
      }
    },

  doLogout:
    () =>
    ({ dispatch, store }) => {
      store.doUpdateRelativeUrl("/");
      keycloak.logout();
      dispatch({ type: "AUTH_LOGGED_OUT" });
    },

  doAuthCheckToken:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "AUTH_CHECK_TOKEN",
        payload: {
          _shouldCheckToken: false,
          _lastChecked: new Date(),
        },
      });
      const isExpired = store.selectIsTokenExpired();
      if (isExpired) store.doLogout();
    },

  selectIsLoggedIn: (state) => {
    return !!state.auth.token;
  },

  selectTokenRaw: (state) => {
    return state.auth.token;
  },
  selectIsLoggingIn: (state) => {
    return state.auth.isLoggingIn;
  },
  selectTokenRoles: (state) => {
    if (!state.auth.hasOwnProperty("roles")) return [];
    return state.auth.roles;
  },
  // selectTokenRolesJoined: state => {
  //   return state.auth.roles;
  // },
  selectAuthLastChecked: (state) => {
    return state.auth._lastChecked;
  },

  selectIsTokenExpired: createSelector(
    "selectIsLoggedIn",
    "selectTokenPayload",
    (isLoggedIn, payload) => {
      if (!isLoggedIn) return false;
      return payload.exp < Math.floor(Date.now() / 1000);
    }
  ),

  selectTokenExpiresIn: createSelector(
    "selectIsLoggedIn",
    "selectTokenPayload",
    (isLoggedIn, payload) => {
      if (!isLoggedIn) return false;
      return formatDistanceToNow(payload.exp * 1000);
    }
  ),

  selectTokenHeader: createSelector("selectTokenRaw", (token) => {
    if (!token) return {};
    return JSON.parse(window.atob(getTokenPart(token, 0)));
  }),

  selectTokenPayload: createSelector("selectTokenRaw", (token) => {
    if (!token) return {};
    return JSON.parse(window.atob(getTokenPart(token, 1)));
  }),

  selectTokenUsername: createSelector("selectTokenPayload", (payload) => {
    if (!payload.hasOwnProperty("preferred_username")) return null;
    return payload.preferred_username.slice(0, -10).toUpperCase();
  }),

  selectTokenEdipi: createSelector("selectTokenPayload", (payload) => {
    if (!payload.hasOwnProperty("edipi")) return null;
    return payload.edipi;
  }),
  selectTokenKeyCloakId: createSelector("selectTokenPayload", (payload) => {
    if (!payload.hasOwnProperty("sub")) return null;
    return payload.sub;
  }),

  selectTokenRolesJoined: createSelector("selectTokenRoles", (roles) => {
    if (!roles) return null;
    return roles;
  }),

  selectTokenGroups: createSelector("selectTokenRoles", (roles) => {
    if (!roles) return null;
    return roles.map((role) => {
      const groupRole = role.split(".");
      return groupRole[0];
    });
  }),

  selectTokenGroupRoles: createSelector("selectTokenRoles", (roles) => {
    if (!roles) return null;
    const groupRoles = {};
    roles.forEach((role) => {
      const groupRole = role.split(".");
      if (!groupRoles.hasOwnProperty(groupRole[0]))
        groupRoles[groupRole[0]] = [];
      groupRoles[groupRole[0]].push(groupRole[1]);
    });
    return groupRoles;
  }),

  reactAuthShouldCheckToken: (state) => {
    if (state.auth._shouldCheckToken) {
      if (new Date() - state.auth._lastChecked > 60000)
        return { actionCreator: "doAuthCheckToken" };
    }
  },

  doKeycloakAuthenticate:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "AUTH_LOGGING_IN",
        payload: { isLoggingIn: true },
      });
      keycloak.directGrantX509Authenticate();
    },
  doKeycloakAuthenticateCode:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "AUTH_LOGGING_IN",
        payload: { isLoggingIn: true },
      });
      keycloak.authenticate();
    },
  // persistActions: ["AUTH_LOGGED_IN", "AUTH_LOGGED_OUT"]
};
