import React from "react";

class ApprovalItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleSign = this.handleSign.bind(this);
  }

  handleSign() {
    const { onSign, item } = this.props;
    onSign(item);
  }

  renderButton() {
    const { sig, disabled } = this.props;
    if(disabled) {
      return (
        <button
          disabled={disabled}
          className="btn btn-lg btn-outline-secondary disabled"
        >
          <i className="mdi mdi-certificate mr-1"></i>Sign
        </button>
      );
    } else if (!sig) {
      return (
        <button
          onClick={this.handleSign}
          className="btn btn-lg btn-outline-success"
        >
          <i className="mdi mdi-certificate mr-1"></i>Sign
        </button>
      );
    } else {
      const sigInfo = JSON.parse(atob(sig.split(".")[0]));
      return (
        <button className="btn btn-lg btn-success" active="true">
          <i className="mdi mdi-certificate float-left mr-1"></i>
          <div className="text-left ml-4">
            <small>
              <div>{sigInfo.username}</div>
              <div>
                <em>{sigInfo.date}</em>
              </div>
            </small>
          </div>
        </button>
      );
    }
  }

  render() {
    const { title, statement } = this.props;
    return (
      <div>
        <h5>{title}</h5>
        <div className="clearfix">
          <div className="float-left mr-3">{this.renderButton()}</div>
          <p>{statement}</p>
        </div>
        <hr />
      </div>
    );
  }
}

export default ApprovalItem;
