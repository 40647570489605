import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";

class AoiButton extends React.Component {
  constructor(props) {
    super(props);
    this.updateInteraction = this.updateInteraction.bind(this);
  }

  updateInteraction() {
    const {
      mapAoiInteractionsActive,
      doMapAoiAddInteraction,
      doMapInteractionsClear
    } = this.props;
    if (mapAoiInteractionsActive) {
      doMapInteractionsClear();
    } else {
      doMapAoiAddInteraction();
    }
  }

  render() {
    const { mapAoiInteractionsActive, doMapAoiUndo } = this.props;
    const btnClass = classnames({
      btn: true,
      "btn-primary": true,
      active: mapAoiInteractionsActive,
      "mr-2": true
    });
    return (
      <div className="btn-group">
        <button
          className={`btn btn-outline-primary`}
          onClick={doMapAoiUndo}
          title="Undo last Added/Deleted LZ points"
        >
          <i className="mdi mdi-undo"></i>
        </button>
        <button className={btnClass} onClick={this.updateInteraction}>
          Add/Modify AOI
        </button>
      </div>
    );
  }
}

export default connect(
  "selectMapAoiInteractionsActive",
  "doMapAoiAddInteraction",
  "doMapInteractionsClear",
  "doMapAoiUndo",
  AoiButton
);
