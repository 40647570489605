import React from "react";
import { connect } from "redux-bundler-react";
import SchemaForm from "../../app-components/schema-form/schema-form";
import { allowedIds } from "../_shared/sysadmin-users";

class PlatformsNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      org_id: props.orgsByRoute.id,
    };
    this.save = this.save.bind(this);
  }

  save() {
    const { doSave, orgsByRoute, doUpdateRelativeUrl, doDialogClose } =
      this.props;
    if (this.form.isValid()) {
      doDialogClose();
      const data = this.form.serialize();
      doSave(
        data,
        (newItem) => {
          doUpdateRelativeUrl(`/${orgsByRoute.slug}/platforms/${newItem.slug}`);
        },
        true
      );
    }
  }

  render() {
    const { isSaving, schema, tokenKeyCloakId, doDialogClose } = this.props;
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">New Platform</h5>
          <button
            onClick={doDialogClose}
            className="close"
            type="button"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="card-body">
            <SchemaForm
              ref={(el) => {
                this.form = el;
              }}
              inline={true}
              displayOnly={false}
              schema={schema}
              data={this.state}
              conditionalInputs={[
                {
                  name: "awr_number",
                  readOnly: !allowedIds.includes(tokenKeyCloakId),
                },
                {
                  name: "awr_expire_date",
                  readOnly: !allowedIds.includes(tokenKeyCloakId),
                },
              ]}
            />
            <div className="modal-footer">
              <button
                disabled={isSaving}
                onClick={doDialogClose}
                className="btn btn-secondary mr-2"
              >
                Cancel
              </button>
              <button
                disabled={isSaving}
                onClick={this.save}
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "doPlatformsGcsSave",
  "selectOrgsByRoute",
  "selectTokenKeyCloakId",
  "doDialogClose",
  PlatformsNew
);
