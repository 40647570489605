import React from "react";
import { connect } from "redux-bundler-react";

const CustomCheckbox = ({
  checked,
  item,
  readFileIsDeleting,
  readFileIsSaving,
  doReadFileMarkAsRead,
  doReadFileMarkAsUnread,
  keycloakId,
  label,
  onChange,
}) => {
  return (
    <div className="d-flex align-items-center">
      <div
        className={`d-flex align-items-center  ${
          !checked ? "checkbox-wrapper" : "checkbox-wrapper checked"
        }`}
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (onChange) {
            onChange();
          } else {
            if (checked) doReadFileMarkAsUnread(item.marked_id);
            else
              doReadFileMarkAsRead({
                keycloak_id: keycloakId,
                read_file_id: item.id,
              });
          }
        }}
      >
        {readFileIsSaving || readFileIsDeleting ? (
          <i className="mdi mdi-36px mdi-loading mdi-spin" />
        ) : (
          <input
            className={`custom-checkbox status-${
              checked ? "green" : item.initial_status
            }`}
            type="checkbox"
          />
        )}
      </div>
      {label && <label className="m-0 pl-2">{label}</label>}
    </div>
  );
};

export default connect(
  "selectReadFileIsDeleting",
  "selectReadFileIsSaving",
  "doReadFileMarkAsRead",
  "doReadFileMarkAsUnread",
  CustomCheckbox
);
