import React from "react";

class SidebarNav extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <nav className="sidebar-nav">
        <ul className="nav">{children}</ul>
      </nav>
    );
  }
}

export default SidebarNav;
