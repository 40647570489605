import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";

export default createRestBundle({
  name: "missionsPayloads",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/missions/:missionsSlug/payloads",
  putTemplate: "/orgs/:orgSlug/missions/:missionsSlug/payloads/:item.id",
  postTemplate: "",
  deleteTemplate: "/orgs/:orgSlug/missions/:missionsSlug/payloads/:item.id",
  fetchActions: ["AUTH_LOGGED_IN"],
  forceFetchActions: ["ACTIVE_TAB_UPDATE", "URL_UPDATED"],
  addons: {
    selectMissionsPayloadsApproved: createSelector(
      "selectMissionsPayloadsItems",
      (missionsPayloadsItems) => {
        return missionsPayloadsItems.filter((item) => !item.adjudicated);
      }
    ),
    selectMissionsPayloadsAdjudicated: createSelector(
      "selectMissionsPayloadsItems",
      "selectMissionsConfigurationsActiveRow",
      (items, activeConfiguration) => {
        if (activeConfiguration && activeConfiguration.payloads_ids)
          return items.filter(
            (item) =>
              !!item.adjudicated &&
              activeConfiguration.payloads_ids.includes(item.id)
          );
        return items.filter((item) => !!item.adjudicated);
      }
    ),
  },
});
