const StatusCell = ({ status }) => {
  if (!!status) {
    let dateFormat =
      typeof status === "string" ? status : status.toDateString();
    return (
      <div className="d-flex">
        <p className="text-success">{dateFormat.split("T")[0]}</p>
      </div>
    );
  }
};

export default StatusCell;
