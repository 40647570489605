import { sortBy } from "lodash";
import React, { useState } from "react";
import ReactSelect from "react-select";
import { connect } from "redux-bundler-react";
const AddNewSelector = ({
  disabled,
  doFetch,
  doSave,
  items,
  doMissionsAlertFetch,
  // ignoreItems,
  doMissionsConfigurationsSave,
  missionsConfigurationsActiveRow: activeConfiguration,
  doMissionsConfigurationsFetchTable,
  doMissionsConfigurationsFetch,
  equipmentType,
}) => {
  const [newItem, setNewItem] = useState(false);

  const _doFetch = () => {
    doMissionsConfigurationsFetch();
    doMissionsConfigurationsFetchTable();
    doFetch(doMissionsAlertFetch);
  };

  const _onAdd = (obj) => {
    let item = items.filter((p) => p.id === obj.value)[0];
    if (activeConfiguration && !!equipmentType) {
      doMissionsConfigurationsSave(
        {
          ...activeConfiguration,
          configuration_id: activeConfiguration.id,
          [equipmentType]: activeConfiguration[equipmentType]
            ? [activeConfiguration[equipmentType], item.id].join(".")
            : item.id,
          adjudicated_ids: activeConfiguration.adjudicated_ids
            ? [activeConfiguration.adjudicated_ids, item.id].join(".")
            : item.id,
        },
        () => {
          doSave({ ...item, adjudicated: true }, _doFetch, true);
        },
        true
      );
    } else {
      doSave({ ...item, adjudicated: true }, _doFetch, true);
    }
  };

  let options = sortBy(
    items
      // .filter((p) => !ignoreIds.includes(p.id))
      .map((o) => ({ value: o.id, label: o.name })),
    "label"
  );

  if (disabled) return null;
  return (
    <div className="d-flex flex-column">
      <button
        className="btn btn-sm btn-primary"
        style={{ width: 88 }}
        onClick={() => setNewItem(true)}
      >
        Add Other
      </button>
      {newItem && (
        <ReactSelect
          className="w-100 mt-1"
          onChange={_onAdd}
          options={options}
        />
      )}
    </div>
  );
};

export default connect(
  "doMissionsAlertFetch",
  "doMissionsConfigurationsSave",
  "selectMissionsConfigurationsActive",
  "selectMissionsConfigurationsActiveRow",
  "doMissionsConfigurationsFetchTable",
  "doMissionsConfigurationsFetch",
  AddNewSelector
);
