import React from "react";

export default (props) => {

  const { data, colDef } = props;
  const disableEditing = data.categories && data.categories.includes("Read File Docs");
  const onEditClicked = (e) => {
    if (data.editing) colDef.onBtnClick(data);
    else e.preventDefault();
  }

  return (
    <div className="d-flex justify-content-center w-100 h-100">
      <div className="d-flex h-100 w-100">
        {(!disableEditing && data.editing) && <button className="btn btn-sm mr-1 my-auto btn-ghost-primary" onClick={onEditClicked}>Edit</button>}
        {data.categories && data.categories.split("~,~").map((cat, idx) => {
          return <span key={idx} className="badge badge-secondary my-auto ml-2 p-2">{cat}</span>
        })}
      </div>
    </div>
  )
}