import React from "react";
import { connect } from "redux-bundler-react";
import DomainsGroupList from "./domains-group-list";
import DomainsValueList from "./domains-value-list";

class DomainEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      grp: ""
    };
    this.setGroup = this.setGroup.bind(this);
  }

  setGroup(grp) {
    this.setState({
      grp: grp
    });
  }

  render() {
    const { grp } = this.state;
    const {
      domainsEditableGroups: groups,
      domainsEditableItems: domains
    } = this.props;
    const filteredDomains = domains.filter(domain => {
      return domain.grp === grp;
    });
    return (
      <div className="row">
        <div className="col-3">
          <h4>Picklist Group</h4>
          <DomainsGroupList
            groups={groups}
            onSelect={this.setGroup}
            activeGroup={grp}
          />
        </div>
        <div className="col-9">
          <h4>Picklist Options</h4>
          <DomainsValueList group={grp} domains={filteredDomains} />
        </div>
      </div>
    );
  }
}

export default connect(
  "selectDomainsEditableItems",
  "selectDomainsEditableGroups",
  DomainEditor
);
