import React, { useState, useEffect, useCallback } from "react";
import { connect } from "redux-bundler-react";
import { marked } from "marked";
import purify from "dompurify";
import { find } from "lodash";
import {
  renderUserApprovalRoles,
  renderName,
  setHyperlinkTargetsOnPage,
} from "./_shared/utils";
import ItemPage, {
  ItemPageNav,
  ItemPageBody,
} from "../_shared/item-board/item-page";
import CommentsSection from "../_shared/item-board/comments-section";
import ItemPageComment from "../_shared/item-board/item-page-comment";
import RatingsBox from "./_shared/ratings-box";

import "../_shared/item-board/item-board.css";
import "./message-board.css";

const MessageItemPage = ({
  messagesCommentsFlags,
  forumUserPermissionsItems,
  messagesState,
  messagesCurrentPageItem,
  messagesAdjacentInQueue,
  messagesCommentsItems,
  messagesRatingsItems,
  ...props
}) => {
  const { tokenPayload, routeInfo, orgsByRoute } = props;
  const [state, setState] = useState({
    forumUrlIsShared: null,
    comments: messagesCommentsItems,
    rating: 0,
    ratings: messagesRatingsItems,
    is_shared: false,
    messageData: messagesCurrentPageItem,
    prevMessage: null,
    nextMessage: null,
    commentsBoxReduced: true,
    posterData: null,
    activeUserRating: find(messagesRatingsItems ? messagesRatingsItems : [], {
      keycloak_id: tokenPayload.sub,
    }),
    ratingVal: 0,
    reducedComments: [],
    people: null,
    activeUser: null,
    disableMessagePageNavBtns: false,
    commentText: "",
    commentCols: {
      created_by: tokenPayload.sub,
      created_by_name: null,
      org_name: orgsByRoute.name,
      message_id: "",
      rating: 0,
      is_shared: false,
    },
    posterPermissions: find(forumUserPermissionsItems, {
      created_by: tokenPayload.sub,
      forum_name: "message-board",
    }),
  });

  useEffect(() => {
    if (messagesCurrentPageItem) {
      let messageData = messagesCurrentPageItem;
      let posterData =
        messageData && messageData.people
          ? messageData.people[messageData.created_by]
          : null;

      let activeUser =
        messageData &&
        messageData.people &&
        messageData.people[tokenPayload.sub]
          ? messageData.people[tokenPayload.sub]
          : {
              keycloak_id: tokenPayload.sub,
              name: tokenPayload.preferred_username,
              approval_roles: {},
            };

      let people = messageData && messageData.people ? messageData.people : [];
      let comments = messagesCommentsItems;
      setState({
        ...state,
        commentCols: {
          ...state.commentCols,
          is_shared: messageData.is_shared,
          message_id: messageData.id,
          forum: routeInfo.params.orgSlug.toUpperCase(),
          org_name: orgsByRoute.name,
        },
        is_shared: messageData.is_shared,
        ratings: messagesRatingsItems,
        ratingVal: messagesRatingsItems
          .map((obj) => obj.rating)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
        messageData: messageData,
        posterData: posterData,
        forum: messageData ? messageData.org_name : null,

        prevMessage: messagesAdjacentInQueue
          ? messagesAdjacentInQueue.previous
          : null,
        nextMessage: messagesAdjacentInQueue
          ? messagesAdjacentInQueue.next
          : null,
        comments: comments,
        reducedComments:
          comments.length > 5 ? comments.slice(comments.length - 5) : comments,
        activeUserRating: find(
          messagesRatingsItems ? messagesRatingsItems : [],
          { keycloak_id: tokenPayload.sub }
        ),
        people: people,
        activeUser: activeUser,
        disableMessagePageNavBtns: false,
        posterPermissions: find(forumUserPermissionsItems, {
          created_by: messageData.created_by,
          forum_name: "message-board",
        }),
      });
    }
  }, [messagesCurrentPageItem, messagesAdjacentInQueue]);

  const toBoardBtnClicked = (e) => {
    const { routeInfo, doUpdateRelativeUrl } = props;
    doUpdateRelativeUrl(`/${routeInfo.params.orgSlug}/message-board`);
  };

  const prevMessageBtnClicked = useCallback(
    (e) => {
      const { messageData, prevMessage } = state;
      const { doMessagesMovingToAdjacentPage, doUpdateRelativeUrl } = props;
      if (prevMessage && prevMessage.slug) {
        let newRoute = routeInfo.url.replace(
          messageData.slug,
          prevMessage.slug
        );
        doMessagesMovingToAdjacentPage();
        doUpdateRelativeUrl(newRoute);
      }
    },
    [state.messageData, state.prevMessage, routeInfo]
  );

  const nextMessageBtnClicked = useCallback(
    (e) => {
      const { messageData, nextMessage } = state;
      const { doMessagesMovingToAdjacentPage, doUpdateRelativeUrl } = props;
      if (nextMessage && nextMessage.slug) {
        let newRoute = routeInfo.url.replace(
          messageData.slug,
          nextMessage.slug
        );
        doMessagesMovingToAdjacentPage();
        doUpdateRelativeUrl(newRoute);
      }
    },
    [state.messageData, state.nextMessage, routeInfo]
  );
  const renderBanner = () => {
    const { messageData } = state;
    const { routeInfo } = props;
    let bannerStyle = {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    };
    if (messageData) {
      return (
        <h5
          className="d-inline-block text-truncate alert alert-info text-center"
          style={bannerStyle}
        >
          {messageData.is_shared
            ? "You are viewing a message accessible to all MARS organizations"
            : `You are viewing a message exclusive to the ${routeInfo.params.orgSlug.toUpperCase()} organization`}
        </h5>
      );
    }
    return null;
  };
  const renderPostInfo = () => {
    const { posterData, messageData } = state;
    const { routeInfo } = props;
    let user =
      messageData &&
      messageData.people &&
      messageData.people[messageData.created_by]
        ? messageData.people[messageData.created_by]
        : {
            phone: null,
            email: null,
            name: messageData.created_by_name,
            slug: {},
          };
    let posterApprovalRoles =
      posterData && posterData.approval_roles
        ? posterData.approval_roles[messageData.org_name]
        : "";
    let postedDateSplit = messageData
      ? new Date(messageData.posted_date).toLocaleString().split(", ")
      : [];

    return (
      <div
        className="order-last order-sm-2 mx-5 mx-sm-auto col-sm-pull-0 col-push-0 post-info-card card d-inline-flex flex-column p-2 float-sm-right mb-auto"
        style={{ top: 0 }}
      >
        <div className="container px-0 mx-0 px-sm-3 mx-sm-auto">
          <h6 className="row px-0 mx-0">
            <span className="col-auto px-0 mr-2">
              <span className="mdi mdi-account mr-1" />
              <span className="d-none d-sm-inline">Posted by:</span>
            </span>
            <span
              className="col-auto px-0 ml-auto mr-0 text-truncate"
              style={{ overflow: "visible" }}
            >
              {renderName(
                messageData,
                routeInfo,
                user,
                state.posterPermissions
              )}
            </span>
          </h6>
          {posterData && posterApprovalRoles ? (
            <h6 className="mb-sm-3 border-bottom row px-0 mx-0 pb-sm-3">
              <span className="approval-role-tags col-auto px-0 ml-auto mr-0">
                {renderUserApprovalRoles(messageData, state.posterPermissions)}
              </span>
            </h6>
          ) : null}
          <h6 className="row px-0 mb-1 mx-0">
            <span className="col-auto px-0 mr-auto ml-0">
              <span className="mdi mdi-calendar mr-1" />
              <span className="d-none d-sm-inline">Date Posted:</span>
            </span>
            <span className="col-auto px-0 ml-auto mr-0 font-weight-lighter">
              {postedDateSplit[0]}
            </span>
          </h6>
          <h6 className="row px-0 mb-1 mx-0">
            <span className="col-auto px-0 ml-auto mr-0 font-weight-lighter">
              {postedDateSplit[1]}
            </span>
          </h6>
        </div>
      </div>
    );
  };
  const renderMainCard = () => {
    const { messageData } = state;
    return (
      <div
        className="item-page-main-card card h-auto"
        style={{ borderTopLeftRadius: "0" }}
      >
        <div className="card-body p-0">
          <div className="d-flex flex-column p-0">
            {renderBanner()}
            <div className="d-sm-inline d-flex flex-column p-3">
              <h5 className="d-inline card-title text-break p-1">
                {messageData ? messageData.title : ""}
              </h5>
              {messageData && renderPostInfo()}
              <div className="p-2 card-text message-body">
                {messageData && messageData.body && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: purify.sanitize(
                        marked(messageData.body ? messageData.body : "")
                      ),
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (messagesCommentsItems.length > 0) {
      setState({
        ...state,
        comments: messagesCommentsItems,
        reducedComments:
          messagesCommentsItems.length > 5
            ? messagesCommentsItems.slice(messagesCommentsItems.length - 5)
            : messagesCommentsItems,
        people: state.people,
      });
      setHyperlinkTargetsOnPage("comments-section-card");
    }
  }, [JSON.stringify(messagesCommentsItems)]);

  useEffect(() => {
    if (messagesRatingsItems.length > 0) {
      setState({
        ...state,
        messageData: { ...messageData, rating: state.rating },
        activeUserRating: find(messagesRatingsItems, {
          keycloak_id: tokenPayload.sub,
        }),
        ratingVal: messagesRatingsItems
          .map((obj) => obj.rating)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
      });
    }
  }, [JSON.stringify(messagesRatingsItems)]);

  const { messageData } = state;
  const {
    messagesFlags,
    messagesCommentsIsLoading,
    doMessagesInitUrlParams,
    doMessagesCommentsSave,
    doMessagesCommentsDelete,
  } = props;
  useEffect(() => {
    doMessagesInitUrlParams();
    setHyperlinkTargetsOnPage("item-page-main-card");
  }, []);

  const forumName = state.is_shared
    ? "MARS"
    : routeInfo.params.orgSlug.toUpperCase();
  let { prevMessage, nextMessage, disableMessagePageNavBtns } = state;
  let enabledPrevBtn = !disableMessagePageNavBtns && prevMessage;
  let enabledNextBtn = !disableMessagePageNavBtns && nextMessage;
  const commentProps = {
    parentItemData: messageData,
    commentCols: state.commentCols,
    sendFunc: doMessagesCommentsSave,
    deleteFunc: doMessagesCommentsDelete,
  };
  return (
    <ItemPage
      itemType="Message"
      tokenPayload={tokenPayload}
      itemFlags={messagesFlags}
      viewsItems={messagesRatingsItems}
      doItemsInitUrlParams={doMessagesInitUrlParams}
    >
      <ItemPageNav>
        <button
          className={`btn btn-primary ${enabledPrevBtn ? "" : "disabled"}`}
          type="button"
          onClick={prevMessageBtnClicked}
          style={{ pointerEvents: enabledPrevBtn ? "all" : "none" }}
        >
          <span
            className="mdi mdi-arrow-left-circle-outline mr-lg-2"
            style={{ fontSize: "18px" }}
          />
          <span className="d-none d-lg-inline d-xl-inline">
            Previous Message
          </span>
        </button>
        <button
          className="btn btn-outline-primary d-block mx-auto"
          type="button"
          onClick={toBoardBtnClicked}
        >
          <span className="mdi mdi-format-list-bulleted mr-2" />
          <span className="d-none d-lg-inline mr-2">Return to</span>
          <span className="d-inline nasa">{forumName}</span>
        </button>
        <button
          className={`btn btn-primary d-block ml-auto mr-0 ${
            enabledNextBtn ? "" : "disabled"
          }`}
          type="button"
          onClick={nextMessageBtnClicked}
          style={{ pointerEvents: enabledNextBtn ? "all" : "none" }}
        >
          <span className="d-none d-lg-inline d-xl-inline">Next Message</span>
          <span
            className="mdi mdi-arrow-right-circle-outline ml-lg-2"
            style={{ fontSize: "18px" }}
          />
        </button>
      </ItemPageNav>

      <ItemPageBody className="mx-lg-auto col-lg-10 col mx-0 px-0">
        <RatingsBox
          ratingVal={state.ratingVal}
          itemIdColName="message_id"
          itemData={messageData}
          activeUserRating={state.activeUserRating}
        />
        <div className="d-flex flex-column flex-fill w-50">
          {renderMainCard()}
          <CommentsSection
            {...commentProps}
            renderName={renderName}
            renderExtraUserInfo={renderUserApprovalRoles}
            boardUrl={{
              name: "message-board",
              params: [":orgSlug", ":messageSlug"],
              pattern: "/:orgSlug/message-board/:messageSlug",
            }}
            commentsItems={messagesCommentsItems}
            commentsIsLoading={messagesCommentsIsLoading}
            commentsBoxReduced={state.commentsBoxReduced}
            commentsFlags={messagesCommentsFlags}
          >
            {!messagesCommentsIsLoading &&
              !messagesCommentsFlags._shouldFetch && (
                <ItemPageComment
                  {...commentProps}
                  isForm={true}
                  body={state.commentText}
                />
              )}
          </CommentsSection>
        </div>
      </ItemPageBody>
    </ItemPage>
  );
};
export default connect(
  "selectForumUserPermissionsItems",
  "selectMessagesCurrentPageItem",
  "selectRouteInfo",
  "selectOrgsByRoute",
  "selectTokenPayload",
  "selectMessagesState",
  "doUpdateRelativeUrl",
  "doMessagesCommentsSave",
  "doMessagesCommentsDelete",
  "doMessagesInitUrlParams",
  "selectMessagesFlags",
  "selectMessagesRatingsItems",
  "selectMessagesCommentsItems",
  "selectMessagesCommentsFlags",
  "selectMessagesCommentsIsLoading",
  "selectMessagesAdjacentInQueue",
  "doMessagesMovingToAdjacentPage",
  MessageItemPage
);
