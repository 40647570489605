import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";
import tokml from 'tokml';

export default createRestBundle({
  name: "missionsAoi",
  uid: "id",
  prefetch: true,
  routeParam: null,
  initialData: {
    _allMissionAoi: []
  },
  getTemplate: "/orgs/:orgSlug/missions/:missionsSlug/aoi",
  putTemplate: "/orgs/:orgSlug/missions/:missionsSlug/aoi/:item.id",
  postTemplate: "/orgs/:orgSlug/missions/:missionsSlug/aoi",
  deleteTemplate: "/orgs/:orgSlug/missions/:missionsSlug/aoi/:item.id",
  fetchActions: ["AUTH_LOGGED_IN"],
  forceFetchActions: ["URL_UPDATED"],
  addons: {
    doFetchAllMissionsAoi: () => ({ store, dispatch, apiGet }) => {
      let url = `/missions/aoi`;
      apiGet(url, (err, response, body) => {
        let data = JSON.parse(body);
        if (err) {
          console.log(err);
        } else {
          dispatch({
            type: "MISSIONSAOI_FETCH_FINISHED",
            payload: { _allMissionAoi: data }
          })
        }
      })
    },
    doDownloadAoiAsKML: geojson => ({ store }) => {
      const kmlString = tokml(geojson);

      // Create a Blob from the KML string
      const blob = new Blob([kmlString], { type: 'application/vnd.google-earth.kml+xml' });

      // Create a link element
      const link = document.getElementById('downloadLink');
      link.href = URL.createObjectURL(blob);
      link.download = 'map.kml';
      link.style.display = 'block';
      link.textContent = 'Download KML';
    },
    selectMissionsAoiGeoJSON: createSelector(
      "selectMissionsAoiItems",
      (items) => {
        return {
          type: "FeatureCollection",
          features: items.map((f) => {
            return {
              type: "Feature",
              geometry: JSON.parse(f.shape),
              properties: { id: f.id },
            };
          }),
        };
      }
    ),
    selectAllMissionsAoiItems: state => state.missionsAoi._allMissionAoi,

    selectAllMissionsAoiGeoJSON: createSelector(
      "selectAllMissionsAoiItems",
      (items) => {
        return {
          type: "FeatureCollection",
          features: items.map((f) => {
            return {
              type: "Feature",
              geometry: JSON.parse(f.shape),
              properties: { id: f.id },
            };
          }),
        };
      }
    ),
  },
});
