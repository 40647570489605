import React from "react";
import classnames from "../../utils/classnames";
import { formatDistance, isBefore } from "date-fns";

// Renders Approvals column for this BacklogMissionListItem

const renderApproverCell = ({ approverTitle, isDone, data }, key) => {
  const iconClass = classnames({
    "mdi pr-1": true,
    "mdi-checkbox-blank-circle-outline": !isDone,
    "mdi-checkbox-marked-circle-outline": isDone,
  });
  return (
    <div className="flex-row" key={key}>
      <span
        className={`badge badge-pill badge-${
          isDone ? "success" : "secondary"
        } mr-1`}>
        <i className={iconClass}></i>
        {approverTitle}
      </span>
      <span className="ml-2">
        {data.sig
          ? `Signed on ${new Date(data.date).toLocaleString()}    (${
              data.name
            })`
          : null}
      </span>
    </div>
  );
};

const MissionApprovalsGroup = ({ mission }) => {
  const { next_approval_role } = mission;

  // Signatures for Mission Briefing Officer, Aircrew Training Program Manager, and Mission Approval Authority
  let mboData = mission.approvals["Mission Briefing Officer"];
  let atpmData = mission.approvals["Aircrew Training Program Manager"];

  let mboDone, atpmDone;
  switch (next_approval_role) {
    case "Aircrew Training Program Manager":
      mboDone = true;
      break;
    case "approved":
      mboDone = true;
      atpmDone = true;
      break;
    default:
      break;
  }
  let allRolesData = [
    { approverTitle: "MBO", isDone: mboDone, data: mboData },
    { approverTitle: "ATPM", isDone: atpmDone, data: atpmData },
  ];
  return (
    <div className="d-flex flex-column">
      {allRolesData.map((role, idx) => {
        return renderApproverCell(role, idx);
      })}
    </div>
  );
};
const MissionPreparerSig = ({ mission }) => {
  const preparerSigInfo = mission.plan_sig
    ? JSON.parse(atob(mission.plan_sig.split(".")[0]))
    : null;
  if (preparerSigInfo) {
    return (
      <div className="p-2">
        {new Date(preparerSigInfo.date).toLocaleString()}
      </div>
    );
  }
  return null;
};

// Renders a row representing a mission in the backlog table of missions
export default ({ mission }) => {
  const now = new Date();
  const startDateRelative = formatDistance(new Date(mission.date_start), now);
  const isBeforeNow = isBefore(new Date(mission.date_start), now);

  let startDateString = new Date(mission.date_start).toLocaleString();

  return (
    <tr>
      <td className="align-middle">
        <a href={mission.url}>{`${mission.org_name} - ${mission.name}`}</a>
      </td>
      <td className="align-middle">
        <MissionPreparerSig mission={mission} />
      </td>
      <td className="align-middle">
        {startDateString}{" "}
        <strong>{` (${
          isBeforeNow ? startDateRelative + " ago" : "in " + startDateRelative
        })`}</strong>
      </td>
      <td className="align-middle">
        <MissionApprovalsGroup mission={mission} />
      </td>
    </tr>
  );
};
