import React from "react";
import { connect } from "redux-bundler-react";
import { find, startsWith, reduce } from "lodash";
import classnames from 'classnames'
import GradeSlip from "../modals/grade-slip/grade-slip";

const GradeSlipCard = ({ isProfileActiveAtpm, gradeSlipUnviewedItemsByEvalType, gradeSlipItems, doNestedDialogOpen, profileAlertItems }) => {
    const onClick = () => {
        doNestedDialogOpen({
            content: GradeSlip, props: {
                scrollable: true
            }, size: "xl"
        })
    }
    let alert = find(profileAlertItems, (o) => startsWith(o.alert_type, 'Grade Slip'));
    let formStatus = alert && alert.type && ["danger", "warning"].includes(alert.type) ? alert.type : '';

    const renderCardContent = () => {
        let firstItem = gradeSlipItems && gradeSlipItems[0] ? gradeSlipItems[0] : null;
        let lastUpdatedDate = firstItem && firstItem.last_updated_date ? firstItem.last_updated_date.split("T")[0] : null;
        let totalUnviewedForms = reduce(Object.values(gradeSlipUnviewedItemsByEvalType), (sum, n) => {
            sum += sum[true] ? sum[true] : 0 + n[true] ? n[true] : 0
            return sum
        }, 0)
        return (
            <>
                {isProfileActiveAtpm ?
                    <h6 className="text-muted mb-2">
                        Last updated: {lastUpdatedDate}
                    </h6> 
                    : <h6 className="text-muted mb-2">
                        You have {totalUnviewedForms} unviewed grade-slips.
                    </h6>}

                <button className="btn btn-link mt-2 p-0" onClick={onClick}>
                    <p className="card-text">View Folder</p>
                </button>
            </>
        )
    }
    let cardCls = classnames({
        'card border position-relative': true,
        [`border-${formStatus}`]: true
    });
    return (
        <div className={cardCls} style={{ width: "18rem" }}>
            <div className="card-body p-3">
                <h5 className="card-title border-bottom pb-2">Grade Slip</h5>
                {renderCardContent()}
            </div>
        </div>
    )
}

export default connect(
    "doNestedDialogOpen",
    "selectProfileAlertItems",
    "selectGradeSlipItems",
    "selectGradeSlipUnviewedItemsByEvalType",
    "selectIsProfileActiveAtpm",
    GradeSlipCard
)