import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import Loader from "../../../app-components/loader";
import Grid from "../../../modules/_shared/grid";
import IconCellRenderer from "../icon-cell-renderer";
import EfTwentyCard from "./cards/efTwentyCard";
import EfTwentyTwoCard from "./cards/efTwentyTwoCard";

import HealthAssessmentCard from "./cards/healthAssessmentCard";
import GradeSlipCard from "./cards/gradeSlipCard";
const monthsObject = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  10: "October",
  11: "November",
  12: "December",
};
const FTF = ({
  doEfTwentyFetch,
  efTwentyIsLoading,
  profileCurrency: currency,
  profileCurrencyIsFetching,
}) => {
  useEffect(doEfTwentyFetch, []);

  const config = {
    columnDefs: [
      {
        resizable: true,
        sortable: true,
        headerName: "Name",
        field: "name",
        filter: "agTextColumnFilter",
        width: 100,
      },
      {
        resizable: true,
        sortable: true,
        headerName: "ATP Month",
        field: "atp_month",
        filter: "agTextColumnFilter",
        width: 105,
        cellRenderer: (params) =>
          params.data.atp_month ? monthsObject[params.data.atp_month] : "N/A",
      },
      {
        resizable: true,
        sortable: true,
        headerName: "RP Last 90",
        width: 120,
        field: "rp_last_ninety",
        filter: "agTextColumnFilter",
      },
      {
        resizable: true,
        sortable: true,
        headerName: "RP 1st Semi",
        width: 120,
        field: "rp_first_semi_yr",
        filter: "agTextColumnFilter",
        cellRenderer: (params) =>
          params.data.atp_month ? params.data.rp_first_semi_yr : "N/A",
      },
      {
        resizable: true,
        sortable: true,
        headerName: "RP 2nd Semi",
        width: 120,
        field: "rp_second_semi_yr",
        filter: "agTextColumnFilter",
        cellRenderer: (params) =>
          params.data.atp_month ? params.data.rp_second_semi_yr : "N/A",
      },
      {
        resizable: true,
        sortable: true,
        headerName: "VO Last 180",
        width: 120,
        field: "vo_last_oneeighty",
        filter: "agTextColumnFilter",
      },
      {
        resizable: true,
        sortable: true,
        headerName: "VO 1st Semi",
        width: 120,
        field: "vo_first_semi_yr",
        filter: "agTextColumnFilter",
        cellRenderer: (params) =>
          params.data.atp_month ? params.data.vo_first_semi_yr : "N/A",
      },
      {
        resizable: true,
        sortable: true,
        headerName: "VO 2nd Semi",
        width: 120,
        field: "vo_second_semi_yr",
        filter: "agTextColumnFilter",
        cellRenderer: (params) =>
          params.data.atp_month ? params.data.vo_second_semi_yr : "N/A",
      },
      // {
      //   resizable: true,
      //   sortable: true,
      //   headerName: "7120 Signature Status",
      //   field: "atpm_sig",
      //   filter: "agTextColumnFilter",
      //   cellRenderer: "iconCellRenderer",
      // },
    ],
    frameworkComponents: {
      iconCellRenderer: IconCellRenderer,
    },
  };
  if (efTwentyIsLoading || profileCurrencyIsFetching)
    return (
      <div className="d-flex align-items-center" style={{ height: 300 }}>
        <Loader opt="dissolving-cube" />
      </div>
    );
  return (
    <div>
      <div className="card">
        <h5 className="card-header">Currency Summary</h5>
        <div className="card-body p-0">
          <Grid
            config={config}
            data={[currency]}
            style={{ height: 250, width: "100%" }}
          />
        </div>
      </div>
      <div className="mt-2 w-100 d-flex">
        <div className="card w-100">
          <h5 className="card-header">APL 95-1-1 Forms</h5>
          <div className="card-body d-flex flex-wrap">
            <div className="mr-2">
              <EfTwentyCard />
            </div>
            <div className="mr-2">
              <EfTwentyTwoCard />
            </div>
            <div className="mr-2">
              <HealthAssessmentCard />
            </div>
            <GradeSlipCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  "doProfileSave",
  "selectProfileItems",
  "selectTokenEdipi",
  "selectTokenRaw",
  "selectTokenExpiresIn",
  "doNestedDialogOpen",
  "doEfTwentyFetch",
  "doEfTwentyTwoFetch",
  "selectEfTwentyItems",
  "selectEfTwentyTwoItems",
  "selectEfTwentyIsLoading",
  "selectRouteParams",
  "selectProfileCurrency",
  "selectProfileCurrencyIsFetching",
  FTF
);
