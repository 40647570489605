import React from "react";
import { connect } from "redux-bundler-react";
import FileUploadCard from "../../../_shared/file-upload-card/file-upload-card";

class Documents extends React.Component {
  render() {
    const {
      missionsByRoute,
      missionsDocsItems,
      missionsDocsIsLoading,
      doFileIoUpload,
      doFileIoDelete,
      doMissionsDocsFetch,
    } = this.props;
    return (
      <FileUploadCard
        allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
        isLoading={missionsDocsIsLoading}
        items={missionsDocsItems}
        header={true}
        searchable={true}
        onUpload={(url, file, rel, data) =>
          doFileIoUpload(url, file, rel, data, doMissionsDocsFetch)
        }
        onRemove={(url, s3_key, rel, admin) =>
          doFileIoDelete(url, s3_key, rel, admin, doMissionsDocsFetch)
        }
        rel="mission_docs"
        data={{ mission_id: missionsByRoute.id }}
      />
    );
  }
}

export default connect(
  "doFileIoUpload",
  "doFileIoDelete",
  "selectMissionsByRoute",
  "selectMissionsDocsItems",
  "selectMissionsDocsIsLoading",
  "doMissionsDocsFetch",
  Documents
);
