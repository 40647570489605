import React, { useEffect } from "react"
import { connect } from "redux-bundler-react";
import ItemBoard from "../_shared/item-board/item-board";
import EquipmentRequestsCard from "./equipment-requests-card";

const EquipmentRequests = ({
  boardClassName = "mt-4",
  className,
  doEquipmentRequestInitUrlParams,
  doEquipmentRequestUpdateQueryString: doItemsUpdateQueryString
}) => {

  useEffect(() => {
    doEquipmentRequestInitUrlParams();
  }, []);

  return (
    <ItemBoard className={boardClassName} includePagination={false} doItemsUpdateQueryString={doItemsUpdateQueryString }>
      <EquipmentRequestsCard className={className} />
    </ItemBoard>
  )
}
export default connect(
  "doEquipmentRequestInitUrlParams",
  "doEquipmentRequestUpdateQueryString",
  EquipmentRequests
);