import admin from "./admin.json";
import atpm_dashboard from "./atpm_dashboard.json";
import personnelMgmt from "./personnel_mgmt.json"
import boards from "./boards.json";
import configurations from "./configurations.json"
import docs_navigation from "./docs_navigation.json";
import equipment from "./equipment.json";
import faqs from "./faqs.json"
import ftf from "./ftf.json";
import intro from "./intro.json";
import inventory_mgmt from "./inventory_mgmt.json";
import misc from "./misc.json"
import missions from "./missions.json";
import org_dashboard from "./org_dashboard.json";
import profile_page from "./profile_page.json";
import reports from "./reports.json";
const pageKeysOrdered = [...docs_navigation.pageKeysOrdered];
const pages = { ...docs_navigation.pages };
const body = {
  ...admin,
  ...atpm_dashboard,
  ...configurations,
  ...boards,
  ...equipment,
  ...faqs,
  ...ftf,
  ...intro,
  ...inventory_mgmt,
  ...misc,
  ...missions,
  ...org_dashboard,
  ...personnelMgmt,
  ...profile_page,
  ...reports
};

export { pages, body, pageKeysOrdered };