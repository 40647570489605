import React from "react";
import { connect } from "redux-bundler-react";
import { reverse } from "lodash";
import FileUploadCard from "../_shared/file-upload-card/file-upload-card";
import { customComparator } from "../_shared/helper";
import FileEditDialog from "./file-edit-dialog";
import { useEffect } from "react";
import CategoryCell from "./category-cell";

const RefLib = ({
  doFileIoUpload,
  doFileIoDelete,
  libraryItems,
  fileIoIsUploading,
  fileIoIsDeleting,
  doLibrarySave,
  doDialogOpen,
  doFetchProfilesAll,
  profilesAll,
}) => {
  useEffect(() => {
    doFetchProfilesAll();
  }, []);
  const additionalColumns = [
    {
      headerName: "Categories",
      field: "categories",
      comparator: customComparator,
      flex: 2,
      cellRenderer: CategoryCell,
      onBtnClick: (data) => {
        doDialogOpen({
          content: FileEditDialog,
          props: {
            file: { ...data },
            handleTagsChange: async (data) => {
              await doLibrarySave(Object.assign({}, { ...data }));
            },
          },
        });
      },
    },
    {
      headerName: "Created on",
      field: "create_date",
      comparator: customComparator,
      flex: 1,
      minWidth: 90,
      maxWidth: 120,
      cellRenderer: (params) => {
        if (params.data.create_date) {
          return (
            <div className="d-flex h-100 w-100">
              <span className="my-auto">
                {params.data.create_date.split("T")[0]}{" "}
              </span>
            </div>
          );
        }
      },
    },
    {
      headerName: "Last Updated By",
      field: "last_updated_by",
      cellRenderer: (params) => {
        let userProfile = profilesAll?.filter(
          (p) => p.keycloak_id === params.data.last_updated_by
        );
        return (
          <p className="m-0">
            {userProfile && userProfile.length > 0 && userProfile[0].name}
          </p>
        );
      },
    },
  ];
  return (
    <div className="container" style={{ marginTop: "80px" }}>
      <FileUploadCard
        allowRoles={["HQ.ADMIN"]}
        items={reverse(libraryItems)}
        searchable={true}
        sortable={true}
        header={true}
        title="Reference Library"
        postUrl="/library"
        removeUrl="/library"
        onUpload={doFileIoUpload}
        onRemove={doFileIoDelete}
        enableCategories={true}
        rel="library_docs"
        data={{}}
        isLoading={fileIoIsUploading || fileIoIsDeleting}
        showDate
        additionalColumns={additionalColumns}
      />
    </div>
  );
};

export default connect(
  "doFileIoUpload",
  "doFileIoDelete",
  "selectLibraryItems",
  "selectFileIoIsUploading",
  "selectFileIoIsDeleting",
  "doLibrarySave",
  "doDialogOpen",
  "doFetchProfilesAll",
  "selectProfilesAll",
  RefLib
);
