import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";

export default createRestBundle({
  name: "missionsMms",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/missions/:missionsSlug/mms",
  putTemplate: "/orgs/:orgSlug/missions/:missionsSlug/mms/:item.id",
  postTemplate: "",
  deleteTemplate: "/orgs/:orgSlug/missions/:missionsSlug/mms/:item.id",
  fetchActions: ["AUTH_LOGGED_IN"],
  forceFetchActions: ["ACTIVE_TAB_UPDATE", "URL_UPDATED"],
  addons: {
    selectMissionsMmsApproved: createSelector(
      "selectMissionsMmsItems",
      (missionsMmsItems) => {
        return missionsMmsItems.filter((item) => !item.adjudicated);
      }
    ),
    selectMissionsMmsAdjudicated: createSelector(
      "selectMissionsMmsItems",
      "selectMissionsConfigurationsActiveRow",
      (items, activeConfiguration) => {
        if (activeConfiguration)
          return items.filter(
            (item) =>
              !!item.adjudicated &&
              activeConfiguration.mms_ids.includes(item.id)
          );
        return items.filter((item) => !!item.adjudicated);
      }
    ),
  },
});
