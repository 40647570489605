import React from "react";

class SelectOption extends React.Component {
  render() {
    const { opt } = this.props;
    const { value, label } = opt;
    return <option value={value}>{label}</option>;
  }
}

export default SelectOption;
