import React, { useState, useEffect } from "react";
import { connect } from "redux-bundler-react";

const ReverseGeocoder = ({
  doFetchReverseGeocode,
  reverseGeocodeData,
  doMapZoomTo,
}) => {
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  //   useEffect(() => {
  //     if (reverseGeocodeData) {
  //         apiParser(reverseGeocodeData)
  //     }
  // }, [reverseGeocodeData])

  // const apiParser = (json) => {
  //     if (!json || !json.features.length) doMapZoomTo(json.query); // INVALID INPUT
  //     else json.features.forEach(feature => {
  //         if (feature.id.includes("place")) {
  //             doMapZoomTo(feature.center);
  //         }
  //     });
  useEffect(() => {
    if (reverseGeocodeData) {
      doMapZoomTo(reverseGeocodeData.query);
    }
  }, [reverseGeocodeData]);

  return (
    <div className="input-group">
      <input
        style={{ width: "115px" }}
        placeholder="Latitude"
        className="form-control"
        name="lat"
        onChange={(e) => setLat(e.target.value)}
      />
      <input
        style={{ width: "115px" }}
        placeholder="Longitude"
        className="form-control"
        name="long"
        onChange={(e) => setLong(e.target.value)}
      />
      <div className="input-group-append">
        <div
          className="input-group-text"
          onClick={() => doFetchReverseGeocode(lat, long)}
        >
          <i className="mdi mdi-magnify"></i>
        </div>
      </div>
    </div>
  );
};

export default connect(
  "doMapZoomTo",
  "doFetchReverseGeocode",
  "selectReverseGeocodeData",
  ReverseGeocoder
);
