import React from "react";

class Map extends React.Component {
  componentDidMount() {
    const { initializeFn, initializeProps } = this.props;
    initializeFn(Object.assign(initializeProps, { target: this.el }));
  }

  render() {
    const { children } = this.props;
    return (
      <div
        ref={el => {
          this.el = el;
        }}
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        }}
      >
        {children}
      </div>
    );
  }
}

export default Map;
