import { find } from "lodash";
import React from "react";
import { connect } from "redux-bundler-react";
import efTwenty from "../modals/ef-twenty";
import ApprovalRolesFilter from "../../../context-providers/approval-role-filter";

const EfTwentyCard = ({
  doNestedDialogOpen,
  efTwentyApprovalItems,
  efTwentyItems,
  profileCurrency: currency,
  profileAlertItems,
}) => {
  let alert = profileAlertItems
    .filter(
      (alert) =>
        alert.alert_type === "EF-7120 Incomplete" ||
        alert.alert_type === "EF-7120 Expiring"
    )
    .map((a) => a.type);
  let formStatus =
    alert && alert.length > 0
      ? alert.includes("danger")
        ? "danger"
        : alert.includes("warning")
        ? "warning"
        : null
      : null;

  const _renderEmpty = () => {
    return (
      <ApprovalRolesFilter
        allowRoles={["ATPM", "BATPM"]}
        alt={() => (
          <h6 className="text-muted">
            You currently do not have your EF-7120 filled out by your ATPM.
          </h6>
        )}
      >
        {_renderContent()}
      </ApprovalRolesFilter>
    );
  };

  const _renderContent = () => {
    return (
      <>
        <h6 className=" mb-2 text-muted">
          Last updated:{" "}
          {efTwentyItems &&
            efTwentyItems[0] &&
            efTwentyItems[0].last_updated_date &&
            efTwentyItems[0].last_updated_date.split("T")[0]}
        </h6>
        <div>
          <b>ATPM Signature:</b>{" "}
          {efTwentyApprovalItems &&
          efTwentyApprovalItems.length > 0 &&
          find(
            efTwentyApprovalItems,
            (obj) => obj.signature && obj.routing_order === 0
          ) ? (
            <i className="mdi mdi-checkbox-marked-circle-outline text-success mdi-18px" />
          ) : (
            "N/A"
          )}
        </div>
        <div>
          <b>SUAC Signature:</b>{" "}
          {efTwentyApprovalItems &&
          efTwentyApprovalItems.length > 0 &&
          find(
            efTwentyApprovalItems,
            (obj) => obj.signature && obj.routing_order === 1
          ) ? (
            <i className="mdi mdi-checkbox-marked-circle-outline text-success mdi-18px" />
          ) : (
            "N/A"
          )}
        </div>
        <button
          className="btn btn-link p-0 mt-2"
          onClick={() => {
            doNestedDialogOpen({
              content: efTwenty,
              props: {
                scrollable: true,
                data: efTwentyItems ? efTwentyItems[0] : null,
                currency,
              },
              size: "xl",
            });
          }}
        >
          <p className="card-text">View Form</p>
        </button>
      </>
    );
  };
  return (
    <div
      className={`card border border-${formStatus} position-relative`}
      style={{ width: "18rem" }}
    >
      {["danger", "warning"].indexOf(formStatus) !== -1 && (
        <div className="position-absolute" style={{ top: 16, right: 16 }}>
          <i className={`mdi mdi-alert-outline text-${formStatus} mdi-18px`} />
        </div>
      )}
      <div className="card-body p-3">
        <h5 className="card-title pb-2 border-bottom">EF-7120</h5>
        {efTwentyItems && efTwentyItems[0] ? _renderContent() : _renderEmpty()}
      </div>
    </div>
  );
};

export default connect(
  "selectEfTwentyItems",
  "selectEfTwentyApprovalItems",
  "selectProfileCurrency",
  "doNestedDialogOpen",
  "selectProfileAlertItems",
  EfTwentyCard
);
