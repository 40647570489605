import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import Loader from "../../../app-components/loader";
import RoleFilter from "../../../app-containers/context-providers/role-filter";
import ReadOnly from "../../_shared/read-only-notification";
import NoteList from "./note-list";
import NoteEditor from "./note-editor";

export default connect(
  "selectNotesItems",
  "selectNotesIsLoading",
  ({ notesItems, notesIsLoading }) => {
    const [editing, setEditing] = useState(false);

    const toggleEditing = () => {
      setEditing(!editing);
    };

    const renderEditor = () => {
      if (!editing) return null;
      return <NoteEditor item={null} onComplete={toggleEditing} />;
    };

    const renderContent = () => {
      if (notesIsLoading) return <Loader />;
      return <NoteList items={notesItems} />;
    };

    return (
      <div className="card">
        <div className="card-header">
          <div className="float-right">
            <RoleFilter
              allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
              alt={ReadOnly}
            >
              <button
                disabled={editing}
                className="btn btn-sm btn-ghost-primary"
                onClick={toggleEditing}
              >
                <i className="mdi mdi-plus-circle-outline icon-inline"></i>
                Add New
              </button>
            </RoleFilter>
          </div>
          <i className="mdi mdi-message icon-inline" /> Notes
        </div>
        <div className="card-body">
          {renderEditor()}
          {renderContent()}
        </div>
      </div>
    );
  }
);
