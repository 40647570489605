import React from "react";
import { connect } from "redux-bundler-react";
import SchemaForm from "../../../../../app-components/schema-form/schema-form";
import damage from "../../../../../models/equipment-damage.schema.json";

class DamageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.cancel = this.cancel.bind(this);
    this.save = this.save.bind(this);
  }

  save() {
    const { item, onSave } = this.props;
    const updatedItem = { ...item, ...this.form.serialize() };
    onSave(updatedItem);
  }

  cancel() {
    const { item } = this.props;
    this.form.reset({data: item, schema: damage});
  }

  render() {
    const { item, isSaving } = this.props;
    return (
      <div>
        <SchemaForm
          ref={el => {
            this.form = el;
          }}
          inline={false}
          displayOnly={false}
          schema={damage}
          data={item}
        />
        <div className="float-right">
          <button
            disabled={isSaving}
            onClick={this.cancel}
            className="btn btn-secondary mr-2"
          >
            Cancel
          </button>
          <button
            disabled={isSaving}
            onClick={this.save}
            className="btn btn-primary"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

export default connect(DamageForm);
