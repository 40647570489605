import React from "react";
import DailyRawListItem from "./daily-raw-list-item";

class DailyRawList extends React.Component {
  constructor(props) {
    super(props);
    this.renderItems = this.renderItems.bind(this);
  }

  renderItems() {
    const { rawItems, doUpdateInnerRoute } = this.props;
    if (!rawItems.length) {
      return (
        <li className="list-group-item">
          Enter a Daily Risk Assessment to get started
        </li>
      );
    } else {
      return (
        <>
          {rawItems.map((raw, i) => {
            return (
              <DailyRawListItem
                key={i}
                raw={raw}
                doUpdateInnerRoute={doUpdateInnerRoute}
              />
            );
          })}
        </>
      );
    }
  }

  render() {
    return <ul className="list-group mb-3">{this.renderItems()}</ul>;
  }
}

export default DailyRawList;
