import { createSelector } from "redux-bundler";
import createRestBundle from "./create-rest-bundle";
import { sortBy } from "lodash";

export default createRestBundle({
	name: "coaDocs",
	uid: "s3_key",
	prefetch: true,
	routeParam: null,
	getTemplate: "/coa",
	postTemplate: "/coa",
	putTemplate: "/coa/:item.id",
	fetchActions: [
		"URL_UPDATED",
		"AUTH_LOGGED_IN",
		"FILE_IO_UPLOAD_FINISHED",
		"FILE_IO_DELETE_FINISHED",
		'COADOCS_SAVE_FINISHED'
	],
	addons: {
		selectCoaDocsByMission: createSelector("selectCoaDocsItems", "selectMissionsByRoute",
			(coaDocs, missionsByRoute) => {
				if (missionsByRoute) return coaDocs.filter(doc => doc.mission_id === missionsByRoute.id);
				else return coaDocs;
			}),
		selectCoaDocsCounter: createSelector("selectCoaDocsItems", "selectCoaApprovalDocsItems", "selectTokenRolesJoined", (coaDocsItems, coaApprovalDocsItems, tokenRolesJoined) => {
			let red = 0;
			let yellow = 0;
			if (!tokenRolesJoined.includes("HQ.ADMIN")) return { red, yellow };
			coaDocsItems.forEach(item => {
				if (coaApprovalDocsItems.filter(obj => item.id === obj.coa_doc_id).length > 0) return;
				else if (!!item.is_reviewed) yellow += 1;
				else red += 1
			})
			return { red, yellow }
		}),
		doCoaBacklogDownloadCsv: () => ({ store }) => {
			let coaDocsItems = store.selectCoaDocsItems();
			let coaApprovalDocsItems = store.selectCoaApprovalDocsItems();
			let data = sortBy(coaDocsItems, ["is_reviewed", "is_submitted"]).reverse();
			const ignoreFields = [
				'id',
				'org_id',
				"mission_id",
				's3_key',
				'filename',
				'deleted',
				'create_date',
				'last_updated_date',
				'last_updated_by',
				'mission_slug',
				'org_slug',
				'mission_name',
				'org_name',
				'is_reviewed',
				'is_submitted',
				'is_completed',
			];
			data = data.map(obj => {
				let approvalDoc = coaApprovalDocsItems.filter(
					(doc) => doc.coa_doc_id === obj.id
				);
				return {
					mission: obj.mission_name,
					organization: obj.org_name,
					review_date: obj.is_reviewed,
					submitted_date: obj.is_submitted,
					completed_date: approvalDoc && approvalDoc.length > 0 ? approvalDoc[0].create_date : ""
				}
			});
			if (!data || !data.length) return null;
			const csvRows = [];

			const fixCommas = (value) => {
				if (value && typeof value === "string" && value.indexOf(",") !== -1)
					return `"${value}"`;
				return value;
			};

			const setHeaders = (item) => {
				const head = [];
				Object.keys(item).forEach((key) => {
					if (ignoreFields.indexOf(key) === -1)
						head.push(fixCommas(key.toUpperCase()));
				});
				csvRows.push(head.join(","));
			};

			data.forEach((item, i) => {
				if (i === 0) setHeaders(item);
				const row = [];
				Object.keys(item).forEach((key) => {
					if (ignoreFields.indexOf(key) === -1) row.push(fixCommas(item[key]));
				});
				csvRows.push(row.join(","));
			});

			const csv = csvRows.join("\n");
			var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
			if (navigator.msSaveBlob) {
				// IE 10+
				navigator.msSaveBlob(blob, "COA_BACKLOG.csv");
			} else {
				var link = document.createElement("a");
				if (link.download !== undefined) {
					// feature detection
					// Browsers that support HTML5 download attribute
					var url = URL.createObjectURL(blob);
					link.setAttribute("href", url);
					link.setAttribute("download", "COA_BACKLOG.csv");
					link.style.visibility = "hidden";
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
			}
		},
	},
});
