import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
    name: "faqsRatings",
    uid: "id",
    prefetch: true,
    staleAfter: 1000 * 60 * 60 * 12,

    persist: false,
    routeParam: null,
    getTemplate: "/faqs/ratings",
    putTemplate: "/faqs/ratings/:item.id",
    postTemplate: "/faqs/ratings",
    deleteTemplate: "/faqs/ratings/:item.id",

    fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN"],
    forceFetchActions: ["FAQSRATINGS_SAVE_FINISHED", "FAQSRATINGS_DELETE_FINISHED"],
});