import React from 'react';
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInMonths, differenceInYears, add } from "date-fns";

export const setHyperlinkTargetsOnPage = (className, value = "_blank") => {
    let hyperlinks = document.querySelectorAll(`${className} a`)
    if (hyperlinks) {
        hyperlinks.forEach(link => {
            link.setAttributeNS(null, "target", value)
        })
    }
}

export const constructTimeRemainingString = (publishedDate) => {
    let published_date_epoch = new Date(publishedDate).valueOf();
    let expiration_date = add(new Date(published_date_epoch), { days: 90 });

    let diffHours = differenceInHours(expiration_date, Date.now());
    let diffDays = differenceInDays(expiration_date, Date.now());
    let diffMonths = differenceInMonths(expiration_date, Date.now());
    let diffMinutes = differenceInMinutes(expiration_date, Date.now());
    let timeRemainingString = '';
    let fontWeight = 'font-weight-normal';
    let isExpired = diffMinutes <= 0;
    let textColorSetting = isExpired ? 'text-danger' : 'text-body';

    if (diffDays <= -90) {
        if (Math.abs(diffMonths) >= 12) {
            let diffYears = Math.abs(differenceInYears(expiration_date, Date.now()));
            timeRemainingString = `${diffYears} years ago`
        }
        else timeRemainingString = `${Math.abs(diffMonths)} months ago`
        fontWeight = 'font-weight-light'
        textColorSetting = 'text-danger'
    }

    else if (Math.abs(diffDays) > 75) {
        timeRemainingString = `${Math.abs(diffMonths)} months`
        fontWeight = 'font-weight-light'
    }
    else if (Math.abs(diffDays) <= 75 && Math.abs(diffDays) > 45) {
        timeRemainingString = `${Math.abs(diffMonths)} months`
        fontWeight = 'font-weight-normal'
    }
    else if (Math.abs(diffDays) <= 45 && Math.abs(diffDays) > 32) {
        timeRemainingString = `1 month`
        fontWeight = 'font-weight-bold'
    }
    else if (Math.abs(diffDays) <= 32 && Math.abs(diffDays) > 2) {
        timeRemainingString = `${Math.abs(diffDays)} days`
        fontWeight = 'font-weight-bold'
    }
    else if (Math.abs(diffDays) <= 2 && Math.abs(diffHours) > 1) {
        timeRemainingString = `${Math.abs(diffHours)} hours`
        fontWeight = 'font-weight-bold'
        textColorSetting = 'text-danger'
    }
    else {
        timeRemainingString = `${Math.abs(diffMinutes)} minutes`
        fontWeight = 'font-weight-bold'
        textColorSetting = 'text-danger'
    }

    return { expiration_date: expiration_date, timeRemainingString: timeRemainingString, timeRemainingCls: `${fontWeight} ${textColorSetting}`, expired: isExpired }

}

export const renderName = (item) => {
    return <strong className="d-block text-wrap">{item.marked_as_deleted ? '[deleted]' : item.created_by_name}</strong>
}