import React from "react";
import DomainsEditor from "./domain-editor";

class Domains extends React.Component {
  render() {
    return (
      <div className="container-fluid mt-4">
        <div className="card">
          <div className="card-header">
            <i className="mdi mdi-format-list-bulleted"></i> Manage Picklists
          </div>
          <div className="card-body">
            <DomainsEditor />
          </div>
        </div>
      </div>
    );
  }
}

export default Domains;
