import React from "react";
import { connect } from "redux-bundler-react";
import Breadcrumb from "./breadcrumb";

class BreadcrumbSection extends React.Component {
  render() {
    const { flightsTabBreadcrumbs, doUpdateInnerRoute } = this.props;
    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {flightsTabBreadcrumbs.map((item, i) => {
            return (
              <Breadcrumb
                key={i}
                item={item}
                doUpdateInnerRoute={doUpdateInnerRoute}
              />
            );
          })}
        </ol>
      </nav>
    );
  }
}

export default connect("selectFlightsTabBreadcrumbs", BreadcrumbSection);
