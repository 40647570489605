import React from "react";
import { connect } from "redux-bundler-react";
import SearchInput from "../_shared/search-input/search-input";
import { sortBy } from "lodash";
import RoleFilter from "../../app-containers/context-providers/role-filter";
import OrgFilter from "../../app-containers/context-providers/org-filter";
import Loader from "../../app-components/loader";
import classnames from "../../utils/classnames";
import Grid from "../_shared/grid";
import { customComparator } from "../_shared/helper";

class CustomTooltip {
  init(params) {
    const eGui = (this.eGui = document.createElement("div"));
    const data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
    eGui.innerHTML = `<p>${data.operational ? data.operational : "Not Specified"
      }</p>`;
    eGui.style["width"] = "auto";
    eGui.style["height"] = "auto";
  }
  getGui() {
    return this.eGui;
  }
}

class MMS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    const { doMmsFetch, mmsIsLoading } = this.props;
    if (!mmsIsLoading) {
      doMmsFetch();
    }
  }

  handleSearch(e) {
    this.setState({
      searchTerm: e.target.value,
    });
  }

  handleSelect(slug) {
    const { doUpdateRelativeUrl, orgsByRoute } = this.props;
    doUpdateRelativeUrl(`/${orgsByRoute.slug}/mms/${slug}`);
  }

  render() {
    const {
      mmsItems: items,
      orgsByRoute,
      mmsIsSaving,
      mmsIsLoading,
    } = this.props;
    const defaultColDef = {
      resizable: true,
      sortable: true,
      filter: "agTextColumnFilter",
    };
    const config = {
      tooltipShowDelay: 0,
      suppressScrollOnNewData: true,
      domLayout: "autoHeight",
      defaultColDef: defaultColDef,
      tooltipComponent: CustomTooltip,
      columnDefs: [
        {
          headerName: "",
          field: "operational",
          tooltipField: "operational",
          tooltipComponentParams: "operational",
          width: 60,
          maxWidth: 60,
          cellStyle: (params) => {
            return { minWidth: "3rem" };
          },
          cellClass: (params) => {
            let iconClass = classnames({
              "w-auto ml-2": true,
              mdi: true,
              "mdi-checkbox-blank-circle": true,
              "text-success": params.value == "FMC",
              "text-warning": params.value == "PMC",
              "text-danger": params.value == "NMC",
            });
            return iconClass;
          },
          cellRenderer: (params) => {
            return null;
          },
        },

        {
          headerName: "Name",
          field: "name",
          comparator: customComparator,
          cellRenderer: (params) => {
            return (
              <a href={`/${orgsByRoute.slug}/mms/${params.data.slug}`}>
                {params.value}
              </a>
            );
          },
        },

        {
          headerName: "Make",
          field: "make",
          sortable: true,
        },

        {
          headerName: "Model",
          field: "model",
          sortable: true,
        },

        {
          headerName: "Hard Drive",
          field: "hard_drive",
          sortable: true,
        },

        {
          headerName: "Maps Loaded",
          field: "maps_loaded",
          comparator: customComparator,
          cellRenderer: (params) => {
            if (params.data.maps_loaded) {
              let mapNames = params.data.maps_loaded
                .filter((mapName) => mapName != null)
                .sort((a, b) => {
                  if (a.charCodeAt(1) > b.charCodeAt(1)) return 1;
                  else return -1;
                });
              return <span>{mapNames.join(", ")}</span>;
            }
            return null;
          },
        },

        {
          headerName: "Organizational Barcode",
          field: "org_barcode",
          sortable: true,
        },

        {
          headerName: "Elevation Data",
          field: "has_elevation_data",
          comparator: customComparator,
          cellRenderer: (params) => {
            if (params.data.has_elevation_data) {
              return (
                <span>{params.data.has_elevation_data ? "Yes" : "No"}</span>
              );
            }
            return null;
          },
        },
      ],
      onRowDoubleClicked: (params) => {
        this.handleSelect(params.data.slug);
      },
    };
    if (mmsIsSaving || mmsIsLoading) {
      return (
        <div className="container-fluid mt-4">
          <Loader opt="dissolve-cube" />
        </div>
      );
    }

    const { searchTerm } = this.state;
    const matcher = new RegExp(searchTerm, "ig");
    const filtered = items.filter((item) => {
      const testString = Object.values(item).join(" ");
      return testString.match(matcher);
    });

    const sorted = sortBy(filtered, ["create_date"]);
    return (
      <div className="container-fluid mt-4">
        <SearchInput
          onChange={this.handleSearch}
          value={this.state.searchTerm}
        />
        <div className="card">
          <div className="card-header">
            <i className="ms ms-laptop"></i> Mobile Map Servers
          </div>
          <div className="card-body">

            <div className="d-flex">
              <small className="text-muted mt-2">
                Double click row to open details
              </small>
              <div className="float-right ml-auto pb-3">
                <RoleFilter allowRoles={["APP.SYSADMIN"]}>
                  <a
                    className="btn btn-outline-primary"
                    href={`/${orgsByRoute.slug}/mms/new`}
                  >
                    Add New
                  </a>
                </RoleFilter>
              </div>
            </div>
            <Grid config={config} data={sorted} style={{ width: "100%" }} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "selectMmsItems",
  "selectOrgsByRoute",
  "selectMmsIsSaving",
  "doMmsCheckUserApprovalRoles",
  "doMmsFetch",
  "selectMmsIsLoading",
  MMS
);
