import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import TabContainer from "../missions/_shared/tab-container/tab-container";

import Documents from "../../app-containers/profile/tabs/documents";
import FTF from "../../app-containers/profile/tabs/ftf";
import UserInfo from "../../app-containers/profile/tabs/user-info";
import ProfileAlert from "../../app-containers/profile/profile-alert";
import DeleteButton from "../_shared/delete-button/delete-button";

const EditPersonnelModal = ({
  doDialogClose,
  currency,
  doAtpmControlDelete,
  activeAtpmControl,
  doFetchProfilesAll,
  profileId,
  doFetchAtpmDashboardById,
}) => {
  useEffect(doFetchProfilesAll, []);
  const tabConfig = {
    "Flight Training Folder": (props) => {
      return (
        <div className="w-100 h-100 d-flex flex-column">
          <ProfileAlert tab="ftf" />
          <FTF {...props} />
        </div>
      );
    },
    Documents: (props) => {
      return <Documents {...props} />;
    },
    "User Info": UserInfo,
  };
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{`${currency.name}'s Profile`}</h5>
        <button
          onClick={doDialogClose}
          className="close"
          type="button"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <TabContainer config={tabConfig} />
      </div>
      {activeAtpmControl && (
        <div className="modal-footer">
          <div className="d-flex flex-column w-100">
            <DeleteButton
              onDelete={() =>
                doAtpmControlDelete(activeAtpmControl, () => {
                  doDialogClose();
                  doFetchAtpmDashboardById(profileId);
                })
              }
              deleteText="Unassign from ATPM"
            />
            <p className="m-0 pt-1 text-muted">
              <small>
                Warning: Unassigning this user from your control will also
                delete their 7120. When assigned to a new ATPM they will need a
                new 7120 filled out for them.
              </small>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(
  "doDialogClose",
  "doClearTempProfile",
  "doAtpmControlDelete",
  "selectActiveAtpmControl",
  "doFetchProfilesAll",
  "doFetchAtpmDashboardById",
  "selectProfileId",
  EditPersonnelModal
);
