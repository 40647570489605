import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";

class AddMissionCard extends React.Component {
  constructor(props) {
    super(props);
    this.renderThumbnail = this.renderThumbnail.bind(this);
    this.state = {
      show: false
    }
  }

  renderThumbnail() {
    const { item } = this.props;
    if (item.thumbnail) {
      return (
        <div
          className="brand-card-header"
          style={{
            backgroundImage: `url(${item.thumbnail})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        />
      );
    } else {
      return (
        <div className="brand-card-header bg-facebook">
          <i className="mdi mdi-map"></i>
        </div>
      );
    }
  }

  render() {
    const { item, orgsByRoute } = this.props;
    const missionStatusClass = classnames({
      "text-uppercase": true,
      small: true,
      strong: true,
      "text-success":
        ["active", "complete", "archived"].indexOf(item.mission_status) !== -1,
      "text-warning":
        ["schedule expired", "pending start date"].indexOf(
          item.mission_status
        ) !== -1,
      "text-danger": [].indexOf(item.mission_status) !== -1,
    });
    return (
      <div className="col-sm-6 col-lg-4">
        <div className="brand-card pointer" onClick={() => { }}>
          {this.renderThumbnail()}
          <div
            className="brand-card-body"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          >
            <div className="overflow-ellipsis">
              <div
                className="text-uppercase text-muted overflow-ellipsis"
                title={item.slug}
              >
                {item.slug}
              </div>
              <div className="text-value overflow-ellipsis" title={item.name}>
                <a href={`/${orgsByRoute.slug}/mission/${item.slug}`}>
                  {item.name}
                </a>
              </div>
              <div className="text-uppercase text-muted small">
                {`${new Date(
                  item.date_start
                ).toLocaleDateString()} - ${new Date(
                  item.date_end
                ).toLocaleDateString()}`}
              </div>
              <div className="text-uppercase text-muted small">
                {`Approval Status: ${item.approval_status}`}
              </div>
              <div className="text-uppercase text-muted small">
                {`Schedule Status: ${item.schedule_status}`}
              </div>
              <div className="text-uppercase text-muted small">
                {`Debrief Status: ${item.debrief_status}`}
              </div>
              <div className={missionStatusClass}>
                <i className="mdi mdi-circle mr-2"></i>{" "}
                {`Overall Mission Status: ${item.mission_status}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect("selectOrgsByRoute", "doMissionsDelete", "doMissionsFetch", "selectMissionsIsLoading", AddMissionCard);
