import { createSelector } from "redux-bundler";
import createRestBundle from "./create-rest-bundle";
import { findKey, mapKeys, find } from "lodash";
export default createRestBundle({
  name: "jobs",
  uid: "slug",
  prefetch: true,
  persist: false,
  routeParam: "jobSlug",
  getTemplate: "/job-board?:queryString",
  putTemplate: "/job-board/:item.slug",
  postTemplate: "/job-board",
  deleteTemplate: "/job-board/:item.slug",

  fetchActions: [],
  forceFetchActions: ["JOBS_SAVE_FINISHED", "JOBS_DELETE_FINISHED"],

  initialData: {
    _queryString: {
      _pageIdx: "1",
      _sortBy: "upcoming",
      _searchTerms: "",
      _sortDir: "ASC",
      _targetId: "",
      _onlyFetchStatus: null,
      _onlyFetchTag: null,
    },
    _commonTags: [],
    _allTags: [],
    _tagsLastFetch: null,
    _shouldFetchTags: false,
    _shouldInitTags: false,

    _jobsMostRecentCount: 0,
    _jobsMostRecentLastFetch: null,
    _jobsShouldFetchMostRecent: false,
    _jobsShouldInitMostRecent: false,
    _leavingPage: true,
    _userHasAtpmRoles: false,
    _pageIdx: "1",
    _sortBy: "upcoming",
    _searchTerms: "",
    _sortDir: "ASC",
    _targetId: "",
    _onlyFetchStatus: null,
    _onlyFetchTag: null,

    _newFormState: {
      _changesMade: false,
      id: undefined,
      sectionsReady: {
        general: null,
        pocs: null,
      },
      title: "",
      body: "",
      addr: "",
      datesTentative: false,
      startDate: null,
      endDate: null,
      tags: [],
      requirements: "",
      notes: "",
      poc_name: "",
      poc_email: "",
      poc_phone: "",
      alt_poc_name: "",
      alt_poc_email: "",
      alt_poc_phone: "",
    },
  },

  urlParamSelectors: ["selectJobsFormattedQueryString"],
  reduceFurther: (state, { type, payload }) => {
    switch (type) {
      case "JOBS_NEW_COUNT_FETCH_STARTED":
      case "JOBS_NEW_COUNT_FETCH_ERROR":
      case "JOBS_NEW_COUNT_FETCH_FINISHED":
      case "JOBS_SET_LEAVING_PAGE":
      case "JOBS_COMMON_TAGS_FETCH_STARTED":
      case "JOBS_COMMON_TAGS_FETCHED":
      case "JOBS_COMMON_TAGS_FETCH_ERROR":
      case "JOBS_USER_ATPM_ROLES_FETCHED":
      case "JOBS_USER_ATPM_ROLES_FETCH_ERROR":
      case "JOBS_NEW_FORM_CHANGED":
      case "JOBS_INIT_URL_PARAMS":
      case "JOBS_QUERY_STRING_UPDATED":
        return Object.assign({}, state, payload);
      case "AUTH_LOGGED_IN":
        return Object.assign({}, state, {
          ...payload,
          _jobsShouldFetchMostRecent: true,
          _jobsShouldInitMostRecent: true,
        });
      case "URL_UPDATED":
        return Object.assign({}, state, {
          ...payload,
          _jobsShouldFetchMostRecent: true,
        });
      default:
        return state;
    }
  },
  addons: {
    doJobsFetchNewCount:
      () =>
      ({ dispatch, store, apiGet }) => {
        dispatch({
          type: "JOBS_NEW_COUNT_FETCH_STARTED",
          payload: {
            _jobsShouldFetchMostRecent: false,
            _jobsShouldInitMostRecent: false,
          },
        });
        apiGet("/job-board/new-jobs-count", (err, response, body) => {
          if (err || response.statusCode !== 200) {
            dispatch({
              type: "JOBS_NEW_COUNT_FETCH_ERROR",
              payload: {
                _err: { response: response, err: err },
                notification: {
                  statusCode: { response: response.statusCode },
                },
              },
            });
          } else {
            const newCount = JSON.parse(body);
            dispatch({
              type: "JOBS_NEW_COUNT_FETCH_FINISHED",
              payload: {
                _jobsMostRecentCount: newCount,
                _jobsMostRecentLastFetch: new Date(),
                _jobsShouldFetchMostRecent: true,
              },
            });
          }
        });
      },

    doJobsFetchUserRoles:
      () =>
      ({ dispatch, store, apiGet }) => {
        apiGet(`/job-board/user-roles`, (err, response, body) => {
          if (err || response.statusCode !== 200) {
            dispatch({
              type: "JOBS_USER_ATPM_ROLES_FETCH_ERROR",
              payload: {
                _err: { response: response, err: err },
                notification: {
                  statusCode: { response: response.statusCode },
                },
              },
            });
          } else {
            const atpmRoles = JSON.parse(body);
            dispatch({
              type: "JOBS_USER_ATPM_ROLES_FETCHED",
              payload: {
                _userHasAtpmRoles: atpmRoles.length > 0,
              },
            });
          }
        });
      },
    doJobsUpdateQueryString:
      (update, callback) =>
      ({ dispatch, store }) => {
        let q = store.selectJobsQueryString();
        let updatedQueryStr = { ...q, ...update };
        let searchTermsChanged =
          update._searchTerms && q._searchTerms !== update._searchTerms;
        let selectedStatusChanged =
          update._onlyFetchStatus &&
          q._onlyFetchStatus !== update._onlyFetchStatus;
        let selectedTagChanged =
          update._onlyFetchTag && q._onlyFetchTag !== update._onlyFetchTag;
        let sortByChanged = update._sortBy && q._sortBy !== update._sortBy;

        let queryCookie = window.localStorage.getItem(
          "uas-mgr-ui-jobs-query-data"
        );
        let queryParams = {};
        if (queryCookie) {
          queryParams = JSON.parse(queryCookie, (key, val) =>
            val === "null" ? null : val
          );
          Object.keys(update).forEach((key) => {
            queryParams[[key.substring(1)]] = `${updatedQueryStr[key]}`;
          });
        } else {
          let routeInfo = store.selectRouteInfo();
          let jobsPageIdx = store.selectJobsPageIdx();
          let jobsSearchTerms = store.selectJobsSearchTerms();
          let jobsSortSettings = store.selectJobsSortSettings();
          let jobsSortDirection = store.selectJobsSortDirection();
          let jobsOnlyFetchStatus = store.selectJobsOnlyFetchStatus();
          let jobsOnlyFetchTag = store.selectJobsOnlyFetchTag();
          queryParams = {
            targetId: routeInfo.params.jobSlug,
            pageIdx: jobsPageIdx,
            searchTerms: jobsSearchTerms,
            sortBy: jobsSortSettings["sortBy"],
            sortDir: jobsSortDirection,
            onlyFetchStatus: jobsOnlyFetchStatus,
            onlyFetchTag: jobsOnlyFetchTag,
          };
        }
        if (
          searchTermsChanged ||
          selectedStatusChanged ||
          selectedTagChanged ||
          sortByChanged
        ) {
          updatedQueryStr._pageIdx = 1;
          queryParams.pageIdx = 1;
        }

        window.localStorage.setItem(
          "uas-mgr-ui-jobs-query-data",
          JSON.stringify(queryParams)
        );
        dispatch({
          type: "JOBS_QUERY_STRING_UPDATED",
          payload: {
            _queryString: updatedQueryStr,
            ...updatedQueryStr,
            _shouldFetch: true,
          },
        });
        if (callback) callback();
      },
    doJobsUpdateLeavingPage:
      (leavingPage) =>
      ({ dispatch, store }) => {
        let pathname = store.selectPathname();
        let resetFields = {};
        if (!/job-board/.test(pathname)) {
          let defaultQueryData = {
            _pageIdx: "1",
            _sortBy: "upcoming",
            _searchTerms: "",
            _sortDir: "ASC",
            _targetId: "none",
            _onlyFetchStatus: null,
            _onlyFetchTag: null,
          };
          resetFields = { ...defaultQueryData, _queryString: defaultQueryData };
          window.localStorage.removeItem(`uas-mgr-ui-jobs-query-data`);
        }
        dispatch({
          type: "JOBS_SET_LEAVING_PAGE",
          payload: {
            _leavingPage: leavingPage,
            ...resetFields,
          },
        });
      },
    doJobsFetchCommonTags:
      () =>
      ({ dispatch, store, apiGet }) => {
        let commonTags = [];
        dispatch({
          type: "JOBS_COMMON_TAGS_FETCH_STARTED",
          payload: {
            _shouldFetchTags: false,
            _shouldInitTags: false,
          },
        });
        apiGet(`/job-board/common-tags`, (err, response, body) => {
          if (err || response.statusCode !== 200) {
            dispatch({
              type: "JOBS_COMMON_TAGS_FETCH_ERROR",
              payload: {
                _err: { response: response, err: err },
                notification: {
                  statusCode: { response: response.statusCode },
                },
              },
            });
          } else {
            let items = JSON.parse(body);
            commonTags = items.filter((pair) => pair[1] > 1);
            dispatch({
              type: "JOBS_COMMON_TAGS_FETCHED",
              payload: {
                _commonTags: commonTags.map((pair) => pair[0]).slice(0, 10),
                _allTags: items.map((pair) => pair[0]),
                _tagsLastFetch: new Date(),
                _shouldFetchTags: true,
              },
            });
            let storedItem = { timestamp: Date.now(), tags: items };
            window.localStorage.setItem(
              `uas-mgr-ui-jobs-tags`,
              JSON.stringify(storedItem)
            );
          }
        });
      },
    doJobsInitUrlParams:
      () =>
      ({ dispatch }) => {
        let defaultQueryData = {
          pageIdx: "1",
          sortBy: "upcoming",
          searchTerms: "",
          sortDir: "ASC",
          targetId: "none",
          onlyFetchStatus: null,
          onlyFetchTag: null,
        };
        let storedQueryItem = window.localStorage.getItem(
          `uas-mgr-ui-jobs-query-data`
        );
        let updatedQueryData = { ...defaultQueryData };

        if (storedQueryItem) {
          let storedQueryData = JSON.parse(storedQueryItem, (key, val) =>
            val === "null" ? null : val
          );
          updatedQueryData = { ...defaultQueryData, ...storedQueryData };
        }

        window.localStorage.setItem(
          `uas-mgr-ui-jobs-query-data`,
          JSON.stringify(updatedQueryData)
        );

        updatedQueryData = mapKeys(updatedQueryData, (val, key) => "_" + key);
        dispatch({
          type: "JOBS_INIT_URL_PARAMS",
          payload: {
            _queryString: updatedQueryData,
            ...updatedQueryData,
            _shouldFetch: true,
          },
        });
      },
    doJobsNewFormStateUpdate:
      (update) =>
      ({ dispatch, store }) => {
        const existingState = store.selectJobsNewFormState();
        let updatedState = { ...existingState, ...update };
        const { poc_name, poc_email, poc_phone, datesTentative, title, body } =
          updatedState;

        // Validate the General section
        let generalSectionReady = title.length > 0 && body.length > 0;
        let validDates = false;
        if (datesTentative) validDates = true;
        else if (
          updatedState.startDate !== null &&
          updatedState.endDate !== null
        ) {
          if (
            Date.now() <= updatedState.startDate &&
            updatedState.startDate <= updatedState.endDate
          )
            validDates = true;
        }
        generalSectionReady = validDates && generalSectionReady;

        // Validate the Point of Contact section
        let pocSectionReady = true;
        if (
          poc_name.length === 0 ||
          (poc_phone.length === 0 && poc_email.length === 0)
        )
          pocSectionReady = false;

        dispatch({
          type: "JOBS_NEW_FORM_CHANGED",
          payload: {
            _newFormState: {
              ...updatedState,
              sectionsReady: {
                ...updatedState.sectionsReady,
                general: generalSectionReady,
                pocs: pocSectionReady,
              },
            },
          },
        });
      },
    selectJobsCommonTags: (state) => {
      return state.jobs._commonTags;
    },
    selectJobsAllTags: (state) => {
      return state.jobs._allTags;
    },
    selectJobsOnlyFetchStatus: (state) => {
      return state.jobs._onlyFetchStatus || null;
    },
    selectJobsOnlyFetchTag: (state) => {
      return state.jobs._onlyFetchTag || null;
    },
    selectJobsNewFormState: (state) => {
      return state.jobs._newFormState;
    },
    selectJobsSearchTerms: (state) => {
      return state.jobs._searchTerms;
    },
    selectJobsLeavingPage: (state) => {
      return state.jobs._leavingPage;
    },
    selectJobsSortSettings: (state) => {
      return { sortBy: state.jobs._sortBy };
    },
    selectJobsPageIdx: (state) => {
      return state.jobs._pageIdx;
    },
    selectJobsQueryString: (state) => {
      return state.jobs._queryString;
    },
    selectJobsSortDirection: (state) => {
      return state.jobs._sortDir;
    },
    selectJobsUserHasAtpmRoles: (state) => {
      return state.jobs._userHasAtpmRoles;
    },
    selectJobsNewCount: (state) => {
      return state.jobs._jobsMostRecentCount;
    },
    selectJobsFormattedQueryString: createSelector(
      "selectRouteInfo",
      "selectJobsPageIdx",
      "selectJobsSearchTerms",
      "selectJobsSortSettings",
      "selectJobsSortDirection",
      "selectJobsOnlyFetchStatus",
      "selectJobsOnlyFetchTag",
      (
        routeInfo,
        jobsPageIdx,
        jobsSearchTerms,
        jobsSortSettings,
        jobsSortDirection,
        jobsOnlyFetchStatus,
        jobsOnlyFetchTag
      ) => {
        let queryItems = [];

        if (routeInfo.params.jobSlug) {
          // Assigning targetId will tell the backend to choose the pageIdx relative to the position of the targetId, instead of pageIdx
          queryItems.push(
            `targetId=${encodeURIComponent(routeInfo.params.jobSlug)}`
          );
        }

        if (jobsPageIdx) {
          queryItems.push(`pageIdx=${encodeURIComponent(jobsPageIdx)}`);
        }

        if (jobsSearchTerms.length > 0) {
          queryItems.push(`searchTerms=${encodeURIComponent(jobsSearchTerms)}`);
        }

        if (Object.keys(jobsSortSettings).length > 0) {
          Object.keys(jobsSortSettings).forEach((s) => {
            if (jobsSortSettings[s])
              queryItems.push(`${s}=${jobsSortSettings[s].toLowerCase()}`);
          });
        }

        if (jobsSortDirection) {
          queryItems.push(`sortDir=${encodeURIComponent(jobsSortDirection)}`);
        }
        if (jobsOnlyFetchStatus) {
          queryItems.push(
            `onlyFetchStatus=${encodeURIComponent(jobsOnlyFetchStatus)}`
          );
        }
        if (jobsOnlyFetchTag) {
          queryItems.push(
            `onlyFetchTag=${encodeURIComponent(jobsOnlyFetchTag)}`
          );
        }

        if (!queryItems.length) return {};
        return { queryString: queryItems.join("&") };
      }
    ),
    selectJobsTotalQueried: createSelector("selectJobsState", (jobsState) => {
      let firstJobFound = findKey(jobsState, (obj) => {
        return obj && obj.full_count && obj.created_by;
      });
      if (firstJobFound && jobsState[firstJobFound].full_count)
        return jobsState[firstJobFound].full_count;
      return "0";
    }),
    selectJobsCurrentPageItem: createSelector(
      "selectRouteInfo",
      "selectJobsState",
      (routeInfo, jobsState) => {
        return jobsState[routeInfo.params.jobSlug];
      }
    ),
    selectJobsNewFormSectionsReady: createSelector(
      "selectJobsNewFormState",
      (jobsNewFormState) => {
        const {
          title,
          body,
          startDate,
          endDate,
          datesTentative,
          poc_name,
          poc_email,
          poc_phone,
        } = jobsNewFormState;

        // Validate the General section
        let generalSectionReady = title.length > 0 && body.length > 0;
        let validDates = false;
        if (datesTentative) validDates = true;
        else if (startDate !== null && endDate !== null) {
          if (Date.now() <= startDate && startDate <= endDate)
            validDates = true;
        }
        generalSectionReady = validDates && generalSectionReady;

        // Validate the Point of Contact section
        let pocSectionReady = true;
        if (
          poc_name.length === 0 ||
          (poc_phone.length === 0 && poc_email.length === 0)
        )
          pocSectionReady = false;
        return { general: generalSectionReady, pocs: pocSectionReady };
      }
    ),
    selectJobsCurrentUserView: createSelector(
      "selectJobsViewsItems",
      "selectTokenPayload",
      (jobsViewsItems, tokenPayload) => {
        return find(jobsViewsItems, { created_by: tokenPayload.sub });
      }
    ),
    reactJobsShouldFetchMostRecentCount: (state) => {
      if (state.jobs._jobsShouldFetchMostRecent) {
        if (
          state.jobs._jobsShouldInitMostRecent ||
          state.appTime - new Date(state.jobs._jobsMostRecentLastFetch) >=
            10 * 60 * 1000
        ) {
          return { actionCreator: "doJobsFetchNewCount" };
        }
      }
    },
    reactJobsShouldFetchTags: (state) => {
      if (state.jobs._shouldFetchTags) {
        if (
          state.jobs._shouldInitTags ||
          state.appTime - new Date(state.jobs._tagsLastFetch) >= 10 * 60 * 1000
        ) {
          return { actionCreator: "doJobsFetchCommonTags" };
        }
      }
    },
  },
});
