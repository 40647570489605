import React from "react";
import { connect } from "redux-bundler-react";
import SchemaForm from "../../../../../app-components/schema-form/schema-form";
import mishapSchema from "../../../../../models/flight-mishap.schema.json";
import Loader from "../../../../../app-components/loader";

const classDescriptions = {
  A: [
    `(1) The resulting total cost of property damage is $2 million or more.`,
    `(2) An injury and/or occupational illness results in a fatality or permanent total disability.`,
  ],
  B: [
    `(1) The resulting total cost of property damage is $500,000 or more but less than $2million; or`,
    `(2) An injury and/or occupational illness results in permanent partial disability; or`,
    `(3) When three of more personnel are hospitalized as inpatients as the result of a single occurrence.`,
  ],
  C: [
    `(1) The resulting total cost of property damage is $50,000 or more but less than $500,000; or`,
    `(2) A nonfatal injury or occupational illness that causes 1 or more days away from work or training beyond the day or shift on which it occurred; or`,
    `(3) Disability at any time (that does not meet the definition of Class A or Class B and is a day(s)-away-from-work case).`,
  ],
  D: [
    `(1) The resulting total cost of property damage is $20,000 or more but less than $50,000; or`,
    `(2) A nonfatal injury or illness results in restricted work or medical treatment greater than first aid.`,
  ],
  E: [
    `(1) The resulting total cost of property damage is $5,000 or more but less than $20,000.`,
  ],
};

class IncidentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mishap: {},
      ind: 0,
    };

    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.loadData = this.loadData.bind(this);
    this.addNew = this.addNew.bind(this);
    this.selectItem = this.selectItem.bind(this);
  }

  loadData(i) {
    const { missionsFlightsMishapsItems, flightId } = this.props;
    const mishapItem = missionsFlightsMishapsItems[i];
    if (mishapItem) mishapItem.flight_id = flightId;
    if (mishapItem) {
      this.setState({
        mishap: mishapItem,
        ind: i,
      });
    }
  }

  addNew() {
    const { missionsFlightsMishapsItems } = this.props;
    this.setState({
      ind: missionsFlightsMishapsItems.length,
    });
  }

  selectItem(i) {
    this.setState({
      ind: i,
    });
  }

  save() {
    const {
      doMissionsFlightsMishapsSave,
      missionsFlightsMishapsItems,
      flightId,
    } = this.props;
    const data = this.form.serialize();
    if (missionsFlightsMishapsItems[this.state.ind])
      data.id = missionsFlightsMishapsItems[this.state.ind].id;
    data.flight_id = flightId;
    if (data.mishap_category_id && data.summary) {
      doMissionsFlightsMishapsSave(data, null, true);
    } else {
      // alert("Cant save with empty Category and/or Summary");
    }
  }

  cancel() {
    const { doUpdateInnerRoute, flightsTabRouterParams: params } = this.props;
    doUpdateInnerRoute(
      `/raw/${params.rawId}/flights/${params.flightId}/debrief`
    );
  }

  renderForm() {
    const {
      missionsFlightsMishapsItems,
      missionsFlightsMishapsIsLoading,
    } = this.props;
    if (missionsFlightsMishapsIsLoading) return <Loader />;
    const mishap = missionsFlightsMishapsItems[this.state.ind]
      ? missionsFlightsMishapsItems[this.state.ind]
      : {};
    return (
      <div>
        <SchemaForm
          ref={(el) => {
            this.form = el;
          }}
          inline={true}
          displayOnly={false}
          schema={mishapSchema}
          data={mishap}
        />
        {this.renderActions()}
      </div>
    );
  }

  renderTable() {
    const { missionsFlightsMishapsItems } = this.props;
    return (
      <div className="card">
        <div className="card-header">
          <div className="float-right">
            <button
              className="btn btn-sm btn-ghost-primary"
              onClick={this.addNew}
            >
              <i className="mdi mdi-plus-circle-outline icon-inline"></i>
              Add New
            </button>
          </div>
          <i className="mdi mdi-view-list" /> Incident List
        </div>
        <div className="card-body">
          <ul className="list-group mt-2">
            {missionsFlightsMishapsItems.map((mishap, i) => {
              return (
                <li
                  className="list-group-item list-group-item-action pointer"
                  key={i}
                >
                  <div>
                    <button
                      className="btn btn-link"
                      onClick={() => this.selectItem(i)}
                    >
                      {`${mishap.summary}    |    ${mishap.est_cost}`}
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  renderActions() {
    return (
      <div>
        <div className="float-right">
          <button
            onClick={this.cancel}
            className="btn btn-sm btn-secondary mr-2"
          >
            Cancel
          </button>
          <button onClick={this.save} className="btn btn-sm btn-success">
            Save
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="row mb-4">
          <div className="col-sm-8">{this.renderForm()}</div>
          <div className="col-sm-4">
            <small>
              {Object.keys(classDescriptions).map((key) => {
                return (
                  <div key={key} className="mb-1">
                    <div>
                      <strong>Class {key} accident</strong>
                    </div>
                    {classDescriptions[key].map((desc) => {
                      return <div key={desc}>{desc}</div>;
                    })}
                  </div>
                );
              })}
            </small>
          </div>
        </div>
        {this.renderTable()}
      </div>
    );
  }
}

export default connect(
  "selectMissionsFlightsMishapsItems",
  "selectMissionsFlightsMishapsIsLoading",
  "selectFlightsTabRouterParams",
  "doMissionsFlightsMishapsSave",
  IncidentForm
);
