import React from "react";
import { connect } from "redux-bundler-react";

class AddMissionCard extends React.Component {
  render() {
    const { doUpdateRelativeUrl, orgsByRoute } = this.props;
    return (
      <div className="col-sm-6 col-lg-4">
        <div
          className="brand-card pointer"
          onClick={() => {
            doUpdateRelativeUrl(`/${orgsByRoute.slug}/planner/new`);
          }}
        >
          <div className="brand-card-header bg-facebook">
            <i className="mdi mdi-map-plus"></i>
          </div>
          <div className="brand-card-body">
            <div>
              <div className="text-value">Add New</div>
              <div className="text-uppercase text-muted small">{`..................`}</div>
              <div className="text-uppercase text-muted small">{`.............`}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "selectOrgsByRoute",
  AddMissionCard
);
