import React from "react";
import { roleAbbreviations, roleColors } from "../../_shared/helper";

const ApprovalRoleTags = ({ rolesStr }) => {
  if (!rolesStr || rolesStr.length === 0) return <b>None</b>;
  let roles = rolesStr.split(".");
  return (
    <div className="approval-role-tags">
      {roles.map((role, i) => {
        return (
          <span
            key={i}
            className={`badge badge-pill badge-${roleColors[role]} ml-1`}>
            <i className="mdi mdi-account-check pr-1" />
            {roleAbbreviations[role] ? roleAbbreviations[role] : null}
          </span>
        );
      })}
    </div>
  );
};
export default ApprovalRoleTags;
