import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";
import UploadKMLModal from "./upload-kml-modal";

class UploadKMLButton extends React.Component {
	constructor(props) {
		super(props);
		this.openModal = this.openModal.bind(this);
	}

	openModal() {
		const { doDialogOpen } = this.props;
		doDialogOpen({
			content: UploadKMLModal,
			props: this.props
		});
	}

	render() {
		const { disabled } = this.props;
		const btnClass = classnames({
			btn: true,
			"btn-primary": true,
			"mr-2": true
		});
		return (
			<button
				className={btnClass}
				onClick={this.openModal}
				disabled={disabled}
				title="Upload a shape file to the map"
			>
				<i className="mdi mdi-upload"></i>
			</button>
		);
	}
}

export default connect(
	"selectMap",
	"selectMissionsByRoute",
	"doDialogOpen",
	UploadKMLButton
);
