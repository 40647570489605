import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";
import CaptureFigureModal from "./capture-figure-modal";

class CaptureFigureButton extends React.Component {
  constructor(props) {
    super(props);
    this.capture = this.capture.bind(this);
  }

  capture() {
    const { map, missionsByRoute: mission, doDialogOpen } = this.props;
    map.once("rendercomplete", function (event) {
      let canvas = event.context.canvas;
      // Initial quality setting
      let quality = 0.3;
      let mapImg = canvas.toDataURL("image/jpeg", quality);
      let fileSizeKB = Math.round(mapImg.length / 1024); // Convert bytes to KB
      let maxFileSizeKB = 125; // request failed at payloads > 125kb (roughly)
      // Decrease file size
      while (fileSizeKB > maxFileSizeKB && quality > 0) {
        quality -= 0.05; // Decrease quality
        mapImg = canvas.toDataURL("image/jpeg", quality); // Convert with new quality
        fileSizeKB = Math.round(mapImg.length / 1024); // Recalculate image size
      }
      doDialogOpen({
        content: CaptureFigureModal,
        props: {
          figure: {
            mission_id: mission.id,
            img: mapImg,
            width: canvas.width,
            height: canvas.height,
            caption: "",
          },
        },
      });
    });
    map.updateSize();
  }

  render() {
    const { mapAoiInteractionsActive, disabled } = this.props;
    const btnClass = classnames({
      btn: true,
      "btn-primary": true,
      active: mapAoiInteractionsActive,
      "mr-2": true,
    });
    return (
      <button
        className={btnClass}
        onClick={this.capture}
        disabled={disabled}
        title="Capture a screenshot of the map"
      >
        <i className="mdi mdi-camera-plus"></i>
      </button>
    );
  }
}

//<i className="mdi mdi-image-filter-center-focus-strong"></i>

export default connect(
  "selectMap",
  "selectMissionsByRoute",
  "doDialogOpen",
  CaptureFigureButton
);
