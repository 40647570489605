import React from "react";
import { range } from "lodash"
const PaginationBtnGrp = ({ numPages, pageIdx, _onClick }) => {
    if (pageIdx === null || isNaN(parseInt(pageIdx))) return null
    return (
        <nav>
            <ul className="pagination">
                <li className={`page-item ${parseInt(pageIdx) === 1 ? "disabled" : ""}`}>
                    <a className="page-link" onClick={(e) => {
                        _onClick({ _pageIdx: `${parseInt(pageIdx) - 1}` })
                    }}>
                        Previous
                    </a>
                </li>
                {
                    range(numPages).map(page => {
                        return (
                            <li className={`page-item ${parseInt(pageIdx) === page + 1 ? "active" : ""}`} key={page}>
                                <a className="page-link" title={`${page + 1}`} onClick={(e) => {
                                    _onClick({ _pageIdx: e.currentTarget.title })
                                }}>{page + 1}</a>
                            </li>
                        )
                    })
                }
                <li className={`page-item ${parseInt(pageIdx) === numPages ? "disabled" : ""}`}>
                    <a className="page-link" onClick={(e) => {
                        _onClick({ _pageIdx: `${parseInt(pageIdx) + 1}` })
                    }}>
                        Next
                    </a>
                </li>
            </ul>
        </nav>
    )
}
export default PaginationBtnGrp;