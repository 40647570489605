import React from "react";
import { connect } from "redux-bundler-react";
import RoleFilter from "../context-providers/role-filter";
import { SidebarNav, SidebarNavItem, SidebarNavTitle } from "./sidebar-nav";
import ApprovalRolesFilter from "../context-providers/approval-role-filter";

class Sidebar extends React.Component {
  render() {
    const {
      doUiToggleSidebarMinimized,
      isLoggedIn,
      orgsByRoute,
      transferStatusBadgeCount,
      equipmentRequestCountsTotals
    } = this.props;
    if (!isLoggedIn) return null;
    const orgActive = orgsByRoute.name.toUpperCase();
    const lCaseOrgActive = orgActive.toLowerCase();
    const { _totalNew} = equipmentRequestCountsTotals;
    return (
      <div className="sidebar">
        <SidebarNav>
          <SidebarNavTitle title={`Organization (${orgActive})`} />
          <ApprovalRolesFilter allowRoles={["ATPM", "BATPM"]}>
            <SidebarNavItem
              iconClass="mdi mdi-view-dashboard"
              title="ATPM Dashboard"
              href={`/${lCaseOrgActive}/atpm`}
            />
          </ApprovalRolesFilter>
          <SidebarNavItem
            iconClass="mdi mdi-gauge"
            title="Dashboard"
            href={`/${lCaseOrgActive}`}
          />
          {lCaseOrgActive !== "erdc" && ( // hiding equipment pages from ERDC
            <>
              <SidebarNavItem
                iconClass="mdi mdi-format-list-text"
                title="All Equipment"
                href={`/${lCaseOrgActive}/allEquipment`}
                activeForChildren={true}
              />
              <SidebarNavItem
                iconClass="mdi mdi-airplane"
                title="Aircraft"
                href={`/${lCaseOrgActive}/aircraft`}
                activeForChildren={true}
              />
              <SidebarNavItem
                iconClass="mdi mdi-car-battery"
                title="Batteries"
                href={`/${lCaseOrgActive}/batteries`}
                activeForChildren={true}
              />
              <SidebarNavItem
                iconClass="mdi mdi-camera"
                title="Payloads"
                href={`/${lCaseOrgActive}/payloads`}
                activeForChildren={true}
              />
              <SidebarNavItem
                iconClass="ms ms-drone-remote"
                title="GCS"
                href={`/${lCaseOrgActive}/gcs`}
                activeForChildren={true}
              />
              <SidebarNavItem
                iconClass="ms ms-laptop"
                title="MMS"
                href={`/${lCaseOrgActive}/mms`}
                activeForChildren={true}
              />
            </>
          )}
          <SidebarNavItem
            iconClass="mdi mdi-account-multiple-outline"
            title="Personnel"
            href={`/${lCaseOrgActive}/personnel`}
            activeForChildren={true}
          />

          <SidebarNavTitle title="Missions" />
          <SidebarNavItem
            iconClass="mdi mdi-calendar-text"
            title="Plan a Mission"
            href={`/${lCaseOrgActive}/planner`}
            activeForChildren={true}
          />
          <SidebarNavItem
            iconClass="mdi mdi-airplane-takeoff"
            title="Approved Missions"
            href={`/${lCaseOrgActive}/fly`}
            activeForChildren={true}
          />
          <SidebarNavItem
            iconClass="mdi mdi-attachment"
            title="Debrief a Mission"
            href={`/${lCaseOrgActive}/debrief`}
            activeForChildren={true}
          />
          <SidebarNavItem
            iconClass="mdi mdi-archive"
            title="Archive"
            href={`/${lCaseOrgActive}/archive`}
            activeForChildren={true}
          />
          <SidebarNavTitle title="Reports" />
          <SidebarNavItem
            iconClass="mdi mdi-account-multiple-outline"
            title="Personnel"
            href={`/${lCaseOrgActive}/reports/personnel`}
            activeForChildren={true}
          />
          <SidebarNavItem
            iconClass="mdi mdi-airplane"
            title="Aircraft"
            href={`/${lCaseOrgActive}/reports/aircraft`}
            activeForChildren={true}
          />
          <SidebarNavItem
            iconClass="mdi mdi-car-battery"
            title="Batteries"
            href={`/${lCaseOrgActive}/reports/batteries`}
            activeForChildren={true}
          />
          <SidebarNavItem
            iconClass="mdi mdi-camera"
            title="Payloads"
            href={`/${lCaseOrgActive}/reports/payloads`}
            activeForChildren={true}
          />
          <SidebarNavItem
            iconClass="ms ms-drone-remote"
            title="GCS"
            href={`/${lCaseOrgActive}/reports/gcs`}
            activeForChildren={true}
          />
          <SidebarNavItem
            iconClass="mdi mdi-airplane-takeoff"
            title="Missions"
            href={`/${lCaseOrgActive}/reports/missions`}
            activeForChildren={true}
          />

          <RoleFilter allowRoles={[`HQ.ADMIN`, `:ORG.ADMIN`]}>
            <SidebarNavTitle title="Admin" />
            <RoleFilter allowRoles={[`HQ.ADMIN`]}>
              <SidebarNavItem
                iconClass="mdi mdi-cog-outline"
                title="Platforms"
                href={`/${lCaseOrgActive}/platforms`}
                activeForChildren={true}
              />
            </RoleFilter>

            <RoleFilter allowRoles={[`HQ.ADMIN`]}>
              <SidebarNavItem
                iconClass="mdi mdi-list-status"
                title="Equipment Reqs."
                href={`/${lCaseOrgActive}/equipment-requests`}
                activeForChildren={true}
                badgeCount={_totalNew}
              />
            </RoleFilter>

            <RoleFilter allowRoles={[`:ORG.ADMIN`]}>
              <SidebarNavItem
                iconClass="mdi mdi-swap-horizontal"
                title="Prop. Transfers"
                href={`/${lCaseOrgActive}/transfers`}
                activeForChildren={true}
                badgeCount={transferStatusBadgeCount}
              />
            </RoleFilter>
           
            <RoleFilter allowRoles={[`:ORG.ADMIN`]}>
              <SidebarNavItem
                iconClass="mdi mdi-cog-outline"
                title="Org. Members"
                href={`/${lCaseOrgActive}/users`}
                activeForChildren={true}
              />
            </RoleFilter>

            <RoleFilter allowRoles={[`HQ.ADMIN`]}>
              <SidebarNavItem
                iconClass="mdi mdi-cog-outline"
                title="Approval Roles"
                href={`/${lCaseOrgActive}/roles`}
                activeForChildren={true}
              />
            </RoleFilter>

            <RoleFilter allowRoles={[`HQ.ADMIN`]}>
              <SidebarNavItem
                iconClass="mdi mdi-cog-outline"
                title="Manage Picklists"
                href={`/${lCaseOrgActive}/domains`}
                activeForChildren={true}
              />
            </RoleFilter>
            <RoleFilter allowRoles={[`HQ.ADMIN`]}>
              <SidebarNavItem
                iconClass="mdi mdi-cog-outline"
                title="Manage FAQs"
                href={`/faqs`}
                activeForChildren={true}
              />
            </RoleFilter>
          </RoleFilter>
        </SidebarNav>
        <button
          className="sidebar-minimizer brand-minimizer"
          type="button"
          onClick={doUiToggleSidebarMinimized}
        ></button>
      </div>
    );
  }
}

export default connect(
  "selectIsLoggedIn",
  "selectOrgsByRoute",
  "selectTransferStatusBadgeCount",
  "selectEquipmentRequestCountsTotals",
  "doUiToggleSidebarMinimized",
  Sidebar
);
