import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";
import awrUploadModal from "./awr-upload-modal";
import { allowedIds } from "../../_shared/sysadmin-users";

class AwrUpload extends React.Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    const { doDialogOpen } = this.props;
    doDialogOpen({
      content: awrUploadModal,
      props: this.props,
    });
  }

  render() {
    const { tokenKeyCloakId, data: platform } = this.props;
    const btnClass = classnames({
      btn: true,
      "btn-success": true,
      "btn-sm": true,
    });
    const btnEditClass = classnames({
      btn: true,
      "btn-primary": true,
      "btn-sm": true,
      "mr-1": true,
    });
    if (!platform.filename) {
      return (
        <button
          className={btnClass}
          onClick={this.openModal}
          title="Upload the AWR Waiver Here"
          style={{ padding: 2 }}
        >
          <i className="mdi mdi-upload"></i>
        </button>
      );
    } else {
      return (
        <div className="d-flex">
          {allowedIds.includes(tokenKeyCloakId) && (
            <button
              className={btnEditClass}
              onClick={this.openModal}
              title="Edit the AWR Waiver Here"
              style={{ padding: 2 }}
            >
              <i className="mdi mdi-pencil-outline"></i>
            </button>
          )}
          <p className="m-0 ml-2">{this.props.data.filename}</p>
        </div>
      );
    }
  }
}

export default connect(
  "doDialogOpen",
  "selectTokenKeyCloakId",
  "selectPlatformsAll",
  AwrUpload
);
