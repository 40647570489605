import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Draw from "ol/interaction/Draw";
import Modify from "ol/interaction/Modify";
import GeoJSON from "ol/format/GeoJSON";
import buffer from "@turf/buffer";
import { debounce } from "lodash";
import { createSelector } from "redux-bundler";

const geoJSON = new GeoJSON({
  dataProjection: "EPSG:4326",
  featureProjection: "EPSG:3857",
});

export default {
  name: "mapAoi",

  getReducer: () => {
    const initialData = {
      layer: null,
      draw: null,
      modify: null,
      bufferLayer: null,
      version: 0,
      _isLoading: false,
      _shouldAddToMap: false,
      _shouldInitialize: true,
      _shouldLoadFromMission: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "MAP_AOI_ADD_TO_MAP_STARTED":
        case "MAP_AOI_ADD_TO_MAP_FINISHED":
        case "MAP_AOI_INITIALIZE_STARTED":
        case "MAP_AOI_INITIALIZE_FINISHED":
        case "MAP_AOI_BUFFER_AOI":
        case "MAP_AOI_LOAD_FROM_MISSION_STARTED":
        case "MAP_AOI_LOAD_FROM_MISSION_FINISHED":
          return Object.assign({}, state, payload);
        case "MAP_INITIALIZED":
          return Object.assign({}, state, { _shouldAddToMap: true });
        case "MISSIONSAOI_FETCH_FINISHED":
          return Object.assign({}, state, { _shouldLoadFromMission: true });
        default:
          return state;
      }
    };
  },

  doMapAoiAddToMap:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "MAP_AOI_ADD_TO_MAP_STARTED",
        payload: {
          _shouldAddToMap: false,
        },
      });

      const map = store.selectMap();
      const aoiLayer = store.selectMapAoiLayer();
      const bufferLayer = store.selectMapAoiBufferLayer();
      map.addLayer(aoiLayer);
      map.addLayer(bufferLayer);
      const vw = map.getView();
      const src = bufferLayer.getSource();
      if (src.getFeatures().length) {
        const extent = src.getExtent();
        vw.fit(extent);
      }

      dispatch({
        type: "MAP_AOI_ADD_TO_MAP_FINISHED",
      });
    },

  doMapAoiInitialize:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "MAP_AOI_INITIALIZE_STARTED",
        payload: {
          _shouldInitialize: false,
        },
      });

      const src = new VectorSource();
      const layer = new VectorLayer({
        source: src,
        style: [
          new Style({
            stroke: new Stroke({
              color: "rgba(255,255,255,0.7)",
              width: 6,
            }),
          }),
          new Style({
            fill: new Fill({
              color: "rgba(255, 255, 255, 0.2)",
            }),
            stroke: new Stroke({
              color: "rgba(255, 38, 38, 1)",
              width: 2,
            }),
          }),
        ],
      });
      const draw = new Draw({
        source: src,
        type: "Polygon",
      });
      const modify = new Modify({
        source: src,
      });

      const bufferSrc = new VectorSource();
      const bufferLayer = new VectorLayer({
        source: bufferSrc,
        style: new Style({
          fill: new Fill({
            color: "rgba(244, 66, 226, 0.1)",
          }),
          stroke: new Stroke({
            color: "#f442e2",
            width: 2,
          }),
        }),
      });

      src.on("addfeature", store.doMapAoiSave);
      src.on("changefeature", debounce(store.doMapAoiSave, 500));

      src.on("removefeature", store.doMapAoiDelete);

      src.on("addfeature", store.doMapAoiBuffer);
      src.on("changefeature", debounce(store.doMapAoiBuffer, 200));

      draw.on("drawstart", (e) => {
        src.clear();
        bufferSrc.clear();
      });

      dispatch({
        type: "MAP_AOI_INITIALIZE_FINISHED",
        payload: {
          layer: layer,
          draw: draw,
          modify: modify,
          bufferLayer: bufferLayer,
        },
      });
    },

  doMapAoiBuffer:
    () =>
    ({ dispatch, store }) => {
      const aoiLayer = store.selectMapAoiLayer();
      const aoiSrc = aoiLayer.getSource();
      const aoiFeatures = aoiSrc.getFeatures();
      const aoiFeaturesGeoJSON = geoJSON.writeFeaturesObject(aoiFeatures, {
        rightHanded: true,
        decimals: 5,
      });
      const bufferLayer = store.selectMapAoiBufferLayer();
      const bufferSrc = bufferLayer.getSource();
      bufferSrc.clear();
      try {
        const newBuffer = buffer(aoiFeaturesGeoJSON, 5, {
          units: "miles",
          steps: 100,
        });

        const newBufferFeatures = geoJSON.readFeatures(newBuffer);
        bufferSrc.addFeatures(newBufferFeatures);
        dispatch({
          type: "MAP_AOI_BUFFER_AOI",
          payload: {
            version: new Date().toTimeString(),
          },
        });
      } catch (e) {
        console.log(e);
      }
    },

  doMapAoiZoomToBuffer:
    () =>
    ({ store }) => {
      const map = store.selectMap();
      const bufferLayer = store.selectMapAoiBufferLayer();
      const vw = map.getView();
      const src = bufferLayer.getSource();
      if (src.getFeatures().length) {
        const extent = src.getExtent();
        vw.fit(extent);
      }
    },

  doMapAoiAddInteraction:
    () =>
    ({ store }) => {
      const draw = store.selectMapAoiDraw();
      const modify = store.selectMapAoiModify();
      store.doMapInteractionsAdd([draw, modify]);
    },

  doMapAoiLoadFromMission:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "MAP_AOI_LOAD_FROM_MISSION_STARTED",
        payload: {
          _shouldLoadFromMission: false,
          _isLoading: true,
        },
      });

      const layer = store.selectMapAoiLayer();
      const src = layer.getSource();
      src.clear();

      const bufferLayer = store.selectMapAoiBufferLayer();
      const bufferSrc = bufferLayer.getSource();
      bufferSrc.clear();

      const featureCollection = store.selectMissionsAoiGeoJSON();
      const features = geoJSON.readFeatures(featureCollection);
      src.addFeatures(features);

      dispatch({
        type: "MAP_AOI_LOAD_FROM_MISSION_FINISHED",
        payload: {
          _isLoading: false,
        },
      });
    },

  doMapAoiSave:
    (e) =>
    ({ store }) => {
      const isLoading = store.selectMapAoiIsLoading();
      if (isLoading) return;
      const geojson = JSON.parse(geoJSON.writeFeature(e.feature)); // this adds the element to the map
      const data = {
        id:
          geojson.properties && geojson.properties.id
            ? geojson.properties.id
            : null,
        shape: JSON.stringify(geojson.geometry),
      };
      store.doMissionsAoiSave(data); // this stores the data into postgres.
    },

  doMapAoiDelete:
    (e) =>
    ({ store }) => {
      const isLoading = store.selectMapAoiIsLoading();
      if (isLoading) return;
      const geojson = JSON.parse(geoJSON.writeFeature(e.feature));
      const data = {
        id:
          geojson.properties && geojson.properties.id
            ? geojson.properties.id
            : null,
        shape: JSON.stringify(geojson.geometry),
      };
      if (data.id) store.doMissionsAoiDelete(data);
    },

  doMapAoiUndo:
    (e) =>
    ({ store }) => {
      store.selectMapAoiDraw().removeLastPoint();
    },

  selectMapAoiInteractionsActive: createSelector(
    "selectMapAoiDraw",
    "selectMapInteractions",
    (draw, interactions) => {
      return interactions.indexOf(draw) !== -1;
    }
  ),

  selectMapAoiAsGeoJSON: createSelector("selectMapAoiLayer", (layer) => {
    if (!layer)
      return {
        type: "FeatureCollection",
        features: [],
      };
    const src = layer.getSource();
    const geojson = JSON.parse(geoJSON.writeFeatures(src.getFeatures()));
    return geojson;
  }),

  selectMapAoiBufferAsGeoJSON: createSelector(
    "selectAppTime",
    "selectMapAoiBufferLayer",
    (t, layer) => {
      if (!layer)
        return {
          type: "FeatureCollection",
          features: [],
        };
      const src = layer.getSource();
      const geojson = JSON.parse(geoJSON.writeFeatures(src.getFeatures()));
      return geojson;
    }
  ),

  selectMapAoiBufferExtent: (state) => {
    if (!state.mapAoi.bufferLayer) return [];
    else {
      let extent = state.mapAoi.bufferLayer.getSource().getExtent();
      return {
        minX: extent[0],
        minY: extent[1],
        maxX: extent[2],
        maxY: extent[3],
      };
    }
  },

  selectMapAoiBufferPolygon: createSelector(
    "selectMapAoiBufferAsGeoJSON",
    (geojson) => {
      if (!geojson.features || !geojson.features.length)
        return { rings: [], spatialReference: { wkid: 4326 } };
      else
        return {
          rings: geojson.features[0].geometry.coordinates,
          spatialReference: { wkid: 4326 },
        };
    }
  ),

  selectMapAoiVersion: (state) => {
    return state.mapAoi.version;
  },

  selectMapAoiIsLoading: (state) => {
    return state.mapAoi._isLoading;
  },

  selectMapAoiLayer: (state) => {
    return state.mapAoi.layer;
  },

  selectMapAoiBufferLayer: (state) => {
    return state.mapAoi.bufferLayer;
  },

  selectMapAoiDraw: (state) => {
    return state.mapAoi.draw;
  },

  selectMapAoiModify: (state) => {
    return state.mapAoi.modify;
  },

  reactAoiShouldInitialize: (state) => {
    if (state.mapAoi._shouldInitialize)
      return { actionCreator: "doMapAoiInitialize" };
  },

  reactAoiShouldLoadFromMission: (state) => {
    if (state.mapAoi._shouldLoadFromMission)
      return { actionCreator: "doMapAoiLoadFromMission" };
  },

  reactAoiShouldAddToMap: (state) => {
    if (state.mapAoi._shouldAddToMap)
      return { actionCreator: "doMapAoiAddToMap" };
  },
};
