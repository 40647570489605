import React from "react";
import { connect } from "redux-bundler-react";
import { isEmpty } from "lodash";
import { mms, mmsHq } from "../../models";
import Loader from "../../app-components/loader";
import DeleteButton from "../_shared/delete-button/delete-button";
import SchemaForm from "../../app-components/schema-form/schema-form";
import PlatformCard from "../_shared/platforms-card/platforms-card";
import MmsSoftwareCard from "./software-card/software-card";
import MissionsCard from "../_shared/missions-card/missions-card";
import FileUploadCard from "../_shared/file-upload-card/file-upload-card";
import RoleFilter from "../../app-containers/context-providers/role-filter";
import ReadOnly from "../_shared/read-only-notification";
import NotesCard from "../_shared/notes-card/note-card";

class MmsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      userRoles: [],
    };
    this.save = this.save.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.enableEditing = this.enableEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    props.doMmsCheckUserApprovalRoles();
  }
  componentDidMount() {
    const { doMmsCheckUserApprovalRoles } = this.props;
    doMmsCheckUserApprovalRoles();
  }

  save() {
    const { doMmsSave } = this.props;
    if (this.form.isValid()) {
      const data = this.form.serialize();
      this.setState(
        {
          editing: false,
        },
        () => {
          doMmsSave(data);
        }
      );
    }
  }

  enableEditing() {
    this.setState({
      editing: true,
    });
  }

  cancelEditing() {
    const { mmsByRoute } = this.props;
    this.setState(
      {
        editing: false,
      },
      () => {
        this.form.reset({ data: mmsByRoute, schema: mms });
      }
    );
  }

  handleDelete() {
    const { doMmsDelete, doUpdateRelativeUrl, routeParams, mmsByRoute } =
      this.props;
    this.setState(
      {
        editing: false,
      },
      () => {
        doMmsDelete(mmsByRoute, () => {
          doUpdateRelativeUrl(`/${routeParams.orgSlug}/mms`);
        });
      }
    );
  }

  renderForm() {
    const { editing } = this.state;
    const { mmsByRoute, tokenGroups, mmsState } = this.props;
    const hqMember = tokenGroups.indexOf("HQ") !== -1;
    const exclusiveToApprovalRoles = [];
    if (mmsState && mmsState._userIsATPM)
      exclusiveToApprovalRoles.push("Aircrew Training Program Manager");

    if (isEmpty(mmsByRoute)) return <Loader />;
    return (
      <SchemaForm
        ref={(el) => {
          this.form = el;
        }}
        inline={true}
        displayOnly={!editing}
        schema={hqMember ? mmsHq : mms}
        data={mmsByRoute}
        conditionalInputs={[
          {
            name: "name",
            readOnly: !exclusiveToApprovalRoles.includes(
              "Aircrew Training Program Manager"
            ),
          },
          {
            name: "org_barcode",
            readOnly: !exclusiveToApprovalRoles.includes(
              "Aircrew Training Program Manager"
            ),
          },
          {
            name: "operational_id",
            readOnly: !exclusiveToApprovalRoles.includes(
              "Aircrew Training Program Manager"
            ),
          },
          {
            name: "cost",
            readOnly: !exclusiveToApprovalRoles.includes(
              "Aircrew Training Program Manager"
            ),
          },
        ]}
      />
    );
  }

  renderActions() {
    const { editing } = this.state;
    if (!editing) return null;
    return (
      <div>
        <div className="float-right">
          <button
            onClick={this.cancelEditing}
            className="btn btn-sm btn-secondary mr-2"
          >
            Cancel
          </button>
          <button onClick={this.save} className="btn btn-sm btn-success">
            Save
          </button>
        </div>
        <DeleteButton onDelete={this.handleDelete} />
      </div>
    );
  }

  render() {
    const { editing } = this.state;
    const {
      mmsByRoute,
      mmsPlatformsItems,
      mmsPlatformsIsLoading,
      doMmsPlatformsSave,
      doMmsPlatformsDelete,
      mmsDocsItems,
      mmsDocsIsLoading,
      mmsMissionsItems,
      mmsMissionsIsLoading,
      mmsSoftwareItems,
      mmsSoftwareIsLoading,
      doFileIoUpload,
      doFileIoDelete,
    } = this.props;

    return (
      <div className="container mt-3">
        <div className="row">
          <div className="col-sm-7">
            <div className="card">
              <div className="card-header">
                <div className="float-right">
                  <RoleFilter
                    allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
                    alt={ReadOnly}
                  >
                    <button
                      disabled={editing}
                      className="btn btn-sm btn-ghost-primary"
                      onClick={this.enableEditing}
                    >
                      <i className="mdi mdi-pencil icon-inline"></i>
                      Edit
                    </button>
                  </RoleFilter>
                </div>
                <i className="ms ms-laptop"></i> Mobile Map Server Details
              </div>
              <div className="card-body">
                {this.renderForm()}
                {this.renderActions()}
              </div>
            </div>
          </div>
          <div className="col-sm-5">
            <PlatformCard
              allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
              isLoading={mmsPlatformsIsLoading}
              items={mmsPlatformsItems}
              onAdd={doMmsPlatformsSave}
              onRemove={doMmsPlatformsDelete}
            />
            <MmsSoftwareCard
              isLoading={mmsSoftwareIsLoading}
              items={mmsSoftwareItems}
            />
            <FileUploadCard
              allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
              isLoading={mmsDocsIsLoading}
              items={mmsDocsItems}
              header={true}
              searchable={false}
              onUpload={doFileIoUpload}
              onRemove={doFileIoDelete}
              rel="mms_docs"
              data={{ mms_id: mmsByRoute ? mmsByRoute.id : null }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <MissionsCard
              isLoading={mmsMissionsIsLoading}
              items={mmsMissionsItems}
            />
            <NotesCard />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "doFileIoUpload",
  "doFileIoDelete",
  "doMmsSave",
  "doMmsDelete",
  "doMmsPlatformsSave",
  "doMmsPlatformsDelete",
  "selectRouteParams",
  "selectMmsByRoute",
  "selectMmsPlatformsItems",
  "selectMmsPlatformsIsLoading",
  "selectMmsDocsItems",
  "selectMmsDocsIsLoading",
  "selectMmsMissionsItems",
  "selectMmsMissionsIsLoading",
  "selectMmsSoftwareItems",
  "selectMmsSoftwareIsLoading",
  "selectTokenGroups",
  "selectMmsState",
  "doMmsCheckUserApprovalRoles",
  MmsDetails
);
