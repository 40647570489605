import React from "react";
import { connect } from "redux-bundler-react";
import Table from "../../../app-components/table/table";

const config = {
  Name: {
    dataKey: "name",
    dataType: "string",
  },
  FROM: {
    dataKey: "date_start",
    dataType: "string",
    renderFn: (_, item) => {
      return new Date(item.date_start).toLocaleDateString();
    },
  },
  TO: {
    dataKey: "date_end",
    dataType: "string",
    renderFn: (_, item) => {
      return new Date(item.date_end).toLocaleDateString();
    },
  },
};

class MissionsTable extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(mission) {
    const { routeParams, doUpdateRelativeUrl } = this.props;
    doUpdateRelativeUrl(
      `/${mission.org.toLowerCase()}/mission/${mission.slug}`
    );
  }

  render() {
    const { items } = this.props;
    return (
      <Table
        config={config}
        items={items}
        onRowSelect={this.handleSelect}
        selectFullItem
      />
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "selectRouteParams",
  MissionsTable
);
