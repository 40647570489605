import { createSelector } from "redux-bundler";

export default {
  name: "downtime",

  getReducer() {
    const initialData = {
      data: [],
      upcoming: [],
      isFetching: false,
      shouldFetch: false,
      upcomingIsFetching: false,
      upcomingShouldFetch: false,
      serverError: null,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "DOWNTIME_FETCH_STARTED":
        case "DOWNTIME_FETCH_ERROR":
        case "DOWNTIME_FETCH_FINISHED":
        case "DOWNTIME_SAVE_STARTED":
        case "DOWNTIME_SAVE_ERROR":
        case "DOWNTIME_UPCOMING_FETCH_STARTED":
        case "DOWNTIME_UPCOMING_FETCH_ERROR":
        case "DOWNTIME_UPCOMING_FETCH_FINISHED":
        case "SERVERSTATUS_FETCH_STARTED":
        case "SERVERSTATUS_FETCH_ERROR":
          return Object.assign({}, state, payload);
        case "DOWNTIME_SHOULD_FETCH":
        case "DOWNTIME_SAVE_FINISHED":
        case "SERVERSTATUS_FETCH_FINISHED":
          return {
            ...state,
            ...payload,
            upcomingShouldFetch: true,
            shouldFetch: true,
          };
        default:
          return state;
      }
    };
  },
  doDowntimeSave:
    (state, callback) =>
    ({ dispatch, store, apiPost, apiPut }) => {
      dispatch({
        type: "DOWNTIME_SAVE_STARTED",
        payload: { isPosting: false },
      });
      if (state.id) {
        apiPut(
          `${store.selectApiRoot()}/downtime/${state.id}`,
          state,
          (err, res, body) => {
            if (err) {
              dispatch({
                type: "DOWNTIME_SAVE_ERROR",
                payload: { isPosting: false, err },
              });
            } else {
              dispatch({
                type: "DOWNTIME_SAVE_FINISHED",
                payload: { isPosting: false },
              });
              callback && callback();
            }
          }
        );
      } else {
        apiPost(
          `${store.selectApiRoot()}/downtime`,
          state,
          (err, res, body) => {
            if (err) {
              dispatch({
                type: "DOWNTIME_SAVE_ERROR",
                payload: { isPosting: false, err },
              });
            } else {
              dispatch({
                type: "DOWNTIME_SAVE_FINISHED",
                payload: { isPosting: false },
              });
              callback && callback();
            }
          }
        );
      }
    },
  doDowntimeFetch:
    () =>
    ({ dispatch }) => {
      let url = import.meta.env.VITE_API_ROOT_BASE;
      dispatch({
        type: "DOWNTIME_FETCH_STARTED",
        payload: { isFetching: true, shouldFetch: false },
      });
      fetch(`${url}/downtime`)
        .then((res) => res.json())
        .then((j) => {
          dispatch({
            type: "DOWNTIME_FETCH_FINISHED",
            payload: { data: j, isFetching: false },
          });
        })
        .catch((err) => {
          dispatch({
            type: "DOWNTIME_FETCH_ERROR",
            payload: { isFetching: false, err },
          });
        });
    },

  doDowntimeUpcomingFetch:
    () =>
    ({ dispatch, store, apiGet }) => {
      let url = store.selectApiRoot();
      dispatch({
        type: "DOWNTIME_UPCOMING_FETCH_STARTED",
        payload: { upcomingIsFetching: true, upcomingShouldFetch: false },
      });
      apiGet(`${url}/downtimeUpcoming`, (err, res, body) => {
        if (err) {
          dispatch({
            type: "DOWNTIME_UPCOMING_FETCH_ERROR",
            payload: { upcomingIsFetching: false, err },
          });
        } else {
          dispatch({
            type: "DOWNTIME_UPCOMING_FETCH_FINISHED",
            payload: { upcoming: JSON.parse(body), upcomingIsFetching: false },
          });
        }
      });
    },

  doCheckServerStatus:
    () =>
    async ({ dispatch }) => {
      let url = import.meta.env.VITE_API_ROOT_BASE;
      let TIMEOUT = 8000;
      let controller = new AbortController();
      let timeoutId = setTimeout(() => controller.abort(), TIMEOUT);
      dispatch({
        type: "SERVERSTATUS_FETCH_STARTED",
        payload: { isCheckingStatus: true },
      });
      try {
        const response = await fetch(`${url}/heartbeat`, {
          signal: controller.signal,
        }).then((res) => res.json());
        dispatch({
          type: "SERVERSTATUS_FETCH_FINISHED",
          payload: { data: response, isCheckingStatus: false },
        });
      } catch (err) {
        dispatch({
          type: "SERVERSTATUS_FETCH_ERROR",
          payload: { serverError: err, isCheckingStatus: false },
        });
      }
    },

  selectServerError: (state) => state.downtime.serverError,
  selectIsCheckingStatus: (state) => state.downtime.isCheckingStatus,
  selectDowntimeUpcomingItems: (state) => state.downtime.upcoming,
  selectDowntimeUpcomingIsLoading: (state) => state.downtime.upcomingIsFetching,
  selectDowntimeUpcomingShouldFetch: (state) =>
    state.downtime.upcomingShouldFetch,
  selectDowntimeItems: (state) => state.downtime.data,
  selectDowntimeIsLoading: (state) => state.downtime.isFetching,
  selectDowntimeShouldFetch: (state) => state.downtime.shouldFetch,
  selectDowntimeError: (state) => state.downtime.err,

  reactDowntimeShouldFetch: createSelector(
    "selectDowntimeShouldFetch",
    (shouldFetch) => {
      if (shouldFetch) {
        return {
          actionCreator: "doDowntimeFetch",
        };
      }
    }
  ),
  reactDowntimeUpcomingShouldFetch: createSelector(
    "selectDowntimeUpcomingShouldFetch",
    (shouldFetch) => {
      if (shouldFetch) {
        return {
          actionCreator: "doDowntimeUpcomingFetch",
        };
      }
    }
  ),
};
