import React from "react";
import { connect } from "redux-bundler-react";
import downtimeDialog from "../downtime/downtime-dialog";
import RoleFilter from "./role-filter";

const dateToString = (date) =>
  new Date(date)
    .toLocaleString("sv-SE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
    .replace(" ", "T");
const Maintenance = ({ doDialogOpen, message, isEditable = true }) => {
  const {
    start_time: startTime,
    end_time: endTime,
    title,
    description,
    id,
    lock_application,
  } = message;
  let startFormat = startTime && new Date(startTime).toString();
  let endFormat = endTime && new Date(endTime).toString();
  return (
    <div className="card mt-4">
      <div className="card-body">
        <div className="card-title d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <i className="mdi mdi-wrench mdi-18px text-info" />
            <h5 className="text-muted ml-2 mb-0">{title}</h5>
          </div>
          {isEditable && (
            <RoleFilter allowRoles={["HQ.ADMIN"]}>
              <button
                className="btn btn-ghost"
                onClick={() => {
                  doDialogOpen({
                    content: downtimeDialog,
                    props: {
                      edit: true,
                      data: {
                        id,
                        title,
                        description,
                        start_time: dateToString(startTime),
                        end_time: dateToString(endTime),
                        lock_application,
                      },
                    },
                    size: "lg",
                  });
                }}
              >
                <i className="mdi mdi-pencil-outline mdi-18px text-muted" />
              </button>
            </RoleFilter>
          )}
        </div>
        <p>{description}</p>
        {startTime && endTime && lock_application && (
          <div className="border-top pt-2 pb-2">
            <h6>App Downtime</h6>
            <div className="d-flex">
              <p className="text-muted">
                {startFormat} - {endFormat}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect("doDialogOpen", Maintenance);
