import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";
import sectionalLegendModal from "./sectional-legend-modal";

const SectionalLegendButton = ({ doDialogOpen }) => {
  const btnClass = classnames({
    btn: true,
    "btn-primary": true,
    "mx-2": true,
  });
  return (
    <button
      className={btnClass}
      onClick={() => {
        doDialogOpen({
          content: sectionalLegendModal,
          props: {},
          size: "lg",
        });
      }}
      title="Show Sectional Chart Legend"
    >
      <i className="mdi mdi-map-legend"></i>
    </button>
  );
};

export default connect("doDialogOpen", SectionalLegendButton);
