import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import FileUploadCard from "../_shared/file-upload-card/file-upload-card";
import { marked } from "marked";
import purify from "dompurify";
import CustomCheckbox from "./custom-checkbox";

const DisplayOnlyDialog = ({
  data,
  doDialogClose,
  doReadFileMarkAsRead,
  tokenKeyCloakId: keycloak_id,
  readFileIsSaving,
  doReadFileDocsFetch,
  readFileDocs,
}) => {
  let checked = keycloak_id === data.keycloak_id;
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => doReadFileDocsFetch(data.id), []);
  let isComplete = !!data.keycloak_id;
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{data.title}</h5>
        <button
          className="close"
          type="button"
          aria-label="Close"
          onClick={doDialogClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div
        className="modal-body"
        style={{ overflow: "visible", minHeight: 200 }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: purify.sanitize(marked(data.description)),
          }}
        />

        {readFileDocs.length > 0 && (
          <div className="mt-2">
            <FileUploadCard
              header
              allowRoles={[]}
              title="Associated Documents"
              items={readFileDocs}
              rel="read_file_docs"
              data={{
                read_file_id: data.id,
              }}
            />
          </div>
        )}
      </div>
      <div className="modal-footer d-flex justify-content-between">
        <div className="d-flex">
          {!isComplete && data.initial_status && (
            <i
              className={`mdi mdi-alert text-${
                data.initial_status === "red"
                  ? "danger"
                  : data.initial_status === "yellow"
                  ? "warning"
                  : ""
              } mdi-24px`}
            />
          )}
        </div>

        {!isChecked && (
          <CustomCheckbox
            item={data}
            checked={isChecked || checked}
            keycloakId={keycloak_id}
            label="I certify that I have read the Read File above and associated documents."
            onChange={() => setIsChecked(!isChecked)}
          />
        )}
        {isChecked && (
          <button
            onClick={
              !isComplete
                ? () => {
                    doReadFileMarkAsRead(
                      {
                        keycloak_id,
                        read_file_id: data.id,
                      },
                      doDialogClose
                    );
                  }
                : () => doDialogClose()
            }
            className={`btn btn-sm btn-${
              isComplete ? "secondary" : "success"
            } mr-2`}
            type="button"
          >
            {readFileIsSaving ? (
              <i className="mdi mdi-18px mdi-loading mdi-spin" />
            ) : isComplete ? (
              "Close"
            ) : (
              "Mark as Read and Close"
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default connect(
  "doDialogClose",
  "selectTokenKeyCloakId",
  "doReadFileMarkAsRead",
  "selectReadFileIsSaving",
  "doReadFileDocsFetch",
  "selectReadFileDocs",
  DisplayOnlyDialog
);
