import React, { useRef, useEffect, useState } from "react";
import { connect } from "redux-bundler-react";

export default connect("doDialogClose", ({ doDialogClose, figure }) => {
  const [h, setH] = useState(0);

  const modal = useRef();

  useEffect(() => {
    if (!modal.current) return undefined;
    setH((figure.height * modal.current.offsetWidth) / figure.width);
  }, [modal.current, figure, setH]);

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Figure Preview</h5>
        <button
          onClick={doDialogClose}
          className="close"
          type="button"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div className="modal-body" ref={modal}>
        <div
          style={{
            backgroundImage: `url(${figure.img})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: h,
            width: "100%",
          }}
        />
        <div className="mt-2">{figure.caption}</div>
      </div>

      <div className="modal-footer">
        <button
          onClick={doDialogClose}
          className="btn btn-sm btn-secondary"
          type="button"
        >
          Close
        </button>
      </div>
    </div>
  );
});
