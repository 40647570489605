import React from "react";
import { connect } from "redux-bundler-react";
import LoadingList from "./loading-list";

const TransfersCard = ({
  doUpdateRelativeUrl,
  isLoading,
  transferStatusItems,
  orgsByRoute,
}) => {
  const _renderTransferItems = () => {
    let firstThree = transferStatusItems.slice(0, 3);
    return firstThree.map((item) => {
      return (
        <div
          style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}
          key={item.name}
        >
          <div className="p-2 border-right border-bottom d-flex align-items-center justify-content-start border-left">
            <a
              href={`/${orgsByRoute.slug}/transfers/${item.slug}`}
            >{`${item.from_org} -> ${item.to_org}`}</a>
          </div>
          <div className="p-2 border-right border-bottom d-flex align-items-center justify-content-start">
            {item.name}
          </div>
          <div className="p-2 border-right border-bottom d-flex align-items-center justify-content-start">
            {item.create_date && item.create_date.split("T")[0]}
          </div>
          <div className="p-2 border-right border-bottom d-flex align-items-center justify-content-start">
            {item.exp_date && item.exp_date.split("T")[0]}
          </div>
        </div>
      );
    });
  };
  let remaining = transferStatusItems.length - 3;
  return (
    <div className="currency border-transfers">
      <div className="custom-card-title transfers">
        <h5 className="text-black m-0">Equipment Transfers</h5>

        <button
          className="btn btn-sm btn-ghost-dark"
          onClick={() => {
            doUpdateRelativeUrl(`/${orgsByRoute.slug}/transfers`);
          }}
        >
          <i className="mdi mdi-swap-horizontal icon-inline mdi-18px"></i>
          Go to Transfers
        </button>
      </div>
      <div className="currency-body">
        {isLoading ? (
          <div className="mt-2">
            <LoadingList />
          </div>
        ) : (
          <div className="d-flex flex-column align-items-center h-100">
            {transferStatusItems.length === 0 ? (
              <div className="d-flex flex-column h-100 align-items-center justify-content-center">
                <p className="text-muted text-center h5">
                  No transfers that require your attention!
                </p>
              </div>
            ) : (
              <div className="d-grid pt-2">
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  }}
                  className="border-bottom border-top"
                >
                  <div className="p-2 border-right border-left">
                    <b>Transfer</b>
                  </div>
                  <div className="p-2 border-right">
                    <b>Name</b>
                  </div>
                  <div className="p-2 border-right">
                    <b>Date Created</b>
                  </div>
                  <div className="p-2 border-right">
                    <b>Exp Date</b>
                  </div>
                </div>
                {_renderTransferItems()}
                {remaining > 0 && (
                  <p className="text-muted mt-3">
                    Go to Transfers Page to view remaining{" "}
                    <b className="text-dark">{remaining}</b> transfers!
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(
  "doUpdateRelativeUrl",
  "selectProfilesByOrgIsFetching",
  "selectTransferStatusItems",
  "selectOrgsByRoute",
  TransfersCard
);
