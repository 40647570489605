import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { connect } from "redux-bundler-react";
import { find } from "lodash";
import { defaultGradeSlip } from "./grade-slip";
import ReactSelect from "react-select";
import ReactSelectCreatable from "react-select/creatable";
import "./custom-category-select.css";

const GradeSlipSideBar = ({ gradeSlipSelectedEvalType, routeInfo, gradeSlipUnviewedItemsByEvalType, isProfileActiveAtpm, gradeSlipDefaultEvalTypes, gradeSlipCustomEvalTypes, gradeSlipEvalType, doGradeSlipFetchCategoriesByOrg, gradeSlipAllTypes, doGradeSlipUpdateState, gradeSlipEditedEntries }) => {
    const [state, setState] = useState({
      newEvalType: '',
    });
    const currentCategoryIsCustom = find(gradeSlipCustomEvalTypes, ['value', gradeSlipEvalType]);
    const onEvalTypeChange = (input) => {
      if (gradeSlipEditedEntries.length > 0) {
        let choice = window.confirm("Unsaved entries... Continue?");
        if (!choice) return;
      }
      doGradeSlipUpdateState({ _evalType: input.value, _selected: defaultGradeSlip, _editedEntries: {}, _entriesLastSave: {} });
    }
    const addCustomEventType = (e) => {
        let value = state.newEvalType;
        let newItem = { label: value, value: value, property: 'event', name: value };
        e.currentTarget.previousElementSibling.value = '';
        doGradeSlipUpdateState({ _evalType: value, _customEvalTypes: gradeSlipCustomEvalTypes.concat(newItem) })
    }
    const renderEvalTypeDropdown = () => {
      if (isProfileActiveAtpm) {
        return (
          <ReactSelectCreatable className="d-inline-block d-lg-none" placeholder="Select or Type to create new"
            value={gradeSlipSelectedEvalType}
            onKeyDown={(e) => {
              if (e.key !== "Backspace") setState({ ...state, newEvalType: e.target.value + e.key })
            }}
            onChange={(type) => {
              if (type.__isNew__) addCustomEventType();
              onEvalTypeChange(type)
            }}
            style={{ overflowY: "show", zIndex: 999 }}
            options={Object.values({ ...gradeSlipAllTypes })}
          />
        )
      }
      else return (
        <ReactSelect className="d-inline-block d-lg-none" onChange={(type) => onEvalTypeChange(type)}
          value={gradeSlipSelectedEvalType}
          style={{ overflowY: "show", zIndex: 999 }}
          options={Object.values({ ...gradeSlipAllTypes })}
        />
      )
    }

    useEffect(() => {
        doGradeSlipFetchCategoriesByOrg();
    }, []);

    const { newEvalType = '' } = state;
    let currentTypeLabels = Object.values(gradeSlipAllTypes).map(t => t.label);
    let disableAddBtn = !newEvalType || (newEvalType && currentTypeLabels.includes(newEvalType) || newEvalType.length === 0);
    const customCategoryCls = classnames({
      "custom-category-select": true,
      "current-category-is-custom": currentCategoryIsCustom
    });
    return (
        <nav>
            <h6 className="nav-title my-auto mb-lg-2">Evaluation Category</h6>
            {renderEvalTypeDropdown() }
            <ul className="nav nav-tabs d-lg-flex d-none" role="tablist">
                {Object.values(gradeSlipDefaultEvalTypes).map((type, idx) => {
                    let numUnviewedItems = gradeSlipUnviewedItemsByEvalType[type.value] ? gradeSlipUnviewedItemsByEvalType[type.value][true] : 0;
                    return (
                      <li className="nav-item mr-3" key={idx}>
                        <a className={`nav-link ${gradeSlipEvalType === type.value ? 'active' : ''}`} onClick={() => onEvalTypeChange(type)}>
                          <div className="d-flex justify-content-between">
                              <span>{type.label}</span>
                              {!isProfileActiveAtpm && numUnviewedItems > 0 && <span className={`badge badge-warning badge-pill d-flex align-items-center`}>{numUnviewedItems}</span>}
                          </div>
                        </a>
                      </li>
                    )
                })}
                <li className="nav-item pl-4">
                  <ReactSelect className={customCategoryCls} onChange={(type) => onEvalTypeChange(type)}
                    value={currentCategoryIsCustom ? currentCategoryIsCustom : null}
                    placeholder="More..."
                    options={Object.values({ ...gradeSlipCustomEvalTypes })}
                  />
                </li>
                {routeInfo.url!=="/profile" && isProfileActiveAtpm &&
                    <li className="nav-item ml-auto mr-0">
                        <div className="d-flex">
                            <input className="form-control" type="text" placeholder="Add Category..." style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                onChange={(e) => {
                                    setState({ ...state, newEvalType: e.currentTarget.value })
                                }}
                            />
                            <button className="btn btn-primary" onClick={addCustomEventType} style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} disabled={disableAddBtn}><span className="mdi mdi-plus" /></button>
                        </div>
                    </li>
                }
            </ul>
        </nav>
    )
}
export default connect(
    "selectRouteInfo",
    "selectGradeSlipSelectedEvalType",
    "selectGradeSlipDefaultEvalTypes",
    "selectIsProfileActiveAtpm",
    "selectGradeSlipEditedEntries",
    "doGradeSlipUpdateState",
    "doGradeSlipFetchCategoriesByOrg",
    "selectGradeSlipEvalType",
    "selectGradeSlipCustomEvalTypes",
    "selectGradeSlipAllTypes",
    "selectGradeSlipUnviewedItemsByEvalType",
    GradeSlipSideBar);