import { find } from "lodash";
import { createSelector } from "redux-bundler";

export default {
  name: "profileActive",
  getReducer: () => {
    const initialState = {
      data: [],
      _shouldFetch: false,
      _isFetching: false,
    };
    return (state = initialState, { type, payload }) => {
      switch (type) {
        case "PROFILEACTIVE_FETCH_STARTED":
        case "PROFILEACTIVE_FETCH_FINISHED":
        case "PROFILEACTIVE_FETCH_ERROR":
        case "PROFILEACTIVE_FETCH_ABORT":
          return Object.assign({}, state, payload);
        case "PROFILE_FETCH_FINISHED":
        case "DIALOG_CLOSED":
          return { ...state, _shouldFetch: true };
        default:
          return state;
      }
    };
  },
  doProfileActiveFetch:
    (id, callback) =>
      ({ dispatch, store, apiGet }) => {
        dispatch({
          type: "PROFILEACTIVE_FETCH_STARTED",
          payload: { _isFetching: true, _shouldFetch: false },
        });
        let activeId = id ? id : store.selectProfileId();
        if (!activeId) {
          dispatch({
            type: "PROFILEACTIVE_FETCH_ABORT",
            payload: {
              _isFetching: false,
            },
          })
        }
        apiGet(`/profile/${activeId}`, (err, response, body) => {
          if (err || response.statusCode !== 200) {
            dispatch({
              type: "PROFILEACTIVE_FETCH_ERROR",
              payload: {
                _err: { err: err, response: response },
                notification: {
                  statusCode: response.statusCode,
                },
                _isFetching: false,
              },
            });
          } else {
            const data = JSON.parse(body);
            dispatch({
              type: "PROFILEACTIVE_FETCH_FINISHED",
              payload: { data, _isFetching: false },
            });
            callback && callback();
          }
        });
      },
  selectProfileActiveData: (state) => state.profileActive.data,
  selectProfileActiveIsFetching: (state) => state.profileActive._isFetching,
  selectProfileActiveShouldFetch: (state) => state.profileActive._shouldFetch,

  selectIsProfileActiveAtpm: createSelector(
    "selectAtpmTransferItems",
    "selectAtpmControlItems",
    "selectProfileActiveData",
    "selectProfileId",
    (atpmTransferItems, atpmControlItems, profileActiveData, profileId) => {
      let activeUserAtpm = find(
        atpmControlItems,
        (obj) => obj.pilot_id === profileActiveData.id
      );
      if (activeUserAtpm) {
        let activeBackupAtpms =
          atpmTransferItems && atpmTransferItems.length > 0
            ? atpmTransferItems
              .filter((item) => item.atpm_id === activeUserAtpm.atpm_id)
              .map((x) => x.backup_atpm_id)
            : [];
        return [...activeBackupAtpms, activeUserAtpm.atpm_id].includes(
          profileId
        );
      } else return false;
    }
  ),

  reactProfileActiveShouldFetch: createSelector(
    "selectProfileActiveIsFetching",
    "selectProfileActiveShouldFetch",
    (profileActiveIsFetching, profileActiveShouldFetch) => {
      if (profileActiveIsFetching) {
        return null;
      }
      if (profileActiveShouldFetch) {
        return { actionCreator: "doProfileActiveFetch" };
      }
    }
  ),
};
