import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";
import Navbar from "./navbar/navbar";
import Banner from "./banner";

class Main extends React.Component {
  render() {
    const { uiSidebarMinimized, uiSidebarShow, children } = this.props;
    const mainContainerClass = classnames({
      app: true,
      "header-fixed": true,
      "sidebar-fixed": true,
      "aside-menu-fixed": true,
      "pace-done": true,
      "sidebar-lg-show": uiSidebarShow,
      "sidebar-show": uiSidebarShow,
      "sidebar-minimized": uiSidebarMinimized,
    });

    return (
      <div className={mainContainerClass}>
        <Banner />
        <Navbar />
        {children}
      </div>
    );
  }
}

export default connect(
  "doOrgsShouldLoadDetails",
  "selectIsLoggedIn",
  "selectUiSidebarShow",
  "selectUiSidebarMinimized",
  "selectUiBreakpoint",
  Main
);
