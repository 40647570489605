import React from "react";
import { connect } from "redux-bundler-react";
import PlatformsList from "./platforms-list";
import PlatformsPicker from "./platforms-picker";
import RoleFilter from "../../../app-containers/context-providers/role-filter";
import ReadOnly from "../read-only-notification";

class PlatformsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.enableEditing = this.enableEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.renderPicker = this.renderPicker.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  enableEditing() {
    this.setState({
      editing: true,
    });
  }

  cancelEditing() {
    this.setState({
      editing: false,
    });
  }

  renderPicker() {
    const { editing } = this.state;
    const { onAdd } = this.props;
    if (!editing) return null;
    return (
      <PlatformsPicker
        onAdd={(val) => {
          onAdd(val);
        }}
      />
    );
  }

  renderActions() {
    const { editing } = this.state;
    if (!editing) return null;
    return (
      <div className="float-right mt-2">
        <button
          onClick={this.cancelEditing}
          className="btn btn-sm btn-secondary"
        >
          Done
        </button>
      </div>
    );
  }

  render() {
    const { editing } = this.state;
    const { isLoading, items, onRemove, allowRoles } = this.props;
    return (
      <div className="card">
        <div className="card-header">
          <div className="float-right">
            <RoleFilter allowRoles={allowRoles} alt={ReadOnly}>
              <button
                disabled={editing}
                className="btn btn-sm btn-ghost-primary"
                onClick={this.enableEditing}
              >
                <i className="mdi mdi-pencil icon-inline"></i>
                Edit
              </button>
            </RoleFilter>
          </div>
          <i className="ms ms-drone-quad icon-inline" /> Associated Platforms
        </div>
        <div className="card-body">
          <PlatformsList
            isLoading={isLoading}
            items={items}
            editing={editing}
            onRemove={onRemove}
          />
          {/* {this.renderPicker()} */}
          {this.renderActions()}
        </div>
      </div>
    );
  }
}

export default connect(PlatformsCard);
