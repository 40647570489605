import React, { useState, useEffect } from "react";

export default ({ title, onChange, min, max, column }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const [from, setFrom] = useState(min);
  const handleFromChange = (e) => {
    setFrom(Number(e.target.value));
  };

  const [to, setTo] = useState(max);
  const handleToChange = (e) => {
    setTo(Number(e.target.value));
  };

  const handleClear = () => {
    setFrom(min);
    setTo(max);
  };

  useEffect(() => {
    onChange({ [column]: { from: from, to: to } });
  }, [onChange, from, to]);

  const hasCondition = from !== min || to !== max;

  return (
    <div className="card mb-0">
      <div className="card-header" onDoubleClick={toggleExpanded}>
        {title}
        <i
          className={`mdi mdi-filter-variant text-${
            hasCondition ? "primary" : "muted"
          } pl-2`}
        ></i>
        <div className="card-header-actions">
          <span onClick={toggleExpanded}>
            <i
              className={`mdi mdi-${
                expanded ? "chevron-down" : "chevron-right"
              }`}
            ></i>
          </span>
        </div>
      </div>
      {expanded ? (
        <div className="card-body">
          <div className="float-right">
            <button className="btn btn-sm btn-secondary" onClick={handleClear}>
              Clear
            </button>
          </div>
          <div className="">
            <div className="slidecontainer">
              <input
                onChange={handleFromChange}
                type="range"
                min={min}
                max={to}
                value={from}
                className="slider"
                id="myRange"
              />
              <span className="pl-2">{from}</span>
            </div>
            <div>To</div>
            <div className="slidecontainer">
              <input
                onChange={handleToChange}
                type="range"
                min={from}
                max={max}
                value={to}
                className="slider"
                id="myRange"
              />
              <span className="pl-2">{to}</span>
            </div>
            <small className="form-text text-muted">
              Search for values within this range
            </small>
          </div>
        </div>
      ) : null}
    </div>
  );
};
