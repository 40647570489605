import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";

// If you need a specific domain item you can add a new selector here

export default createRestBundle({
  name: "domains",
  uid: "id",
  prefetch: true,
  staleAfter: 30000,
  persist: true,
  routeParam: null,
  getTemplate: "/domains/composite",
  putTemplate: "",
  postTemplate: "",
  deleteTemplate: "",
  fetchActions: [
    "URL_UPDATED",
    "AUTH_LOGGED_IN",
    "PLATFORMS_SAVE_FINISHED",
    "PLATFORMSPAYLOADS_SAVE_FINISHED",
    "PLATFORMSPAYLOADS_FETCH_FINISHED",
    "PLATFORMSGCS_SAVE_FINISHED",
    "PLATFORMSGCS_FETCH_FINISHED",
  ],
  forceFetchActions: [
    "DOMAINSEDITABLE_SAVE_FINISHED",
    "DOMAINSEDITABLE_DELETE_FINISHED",
  ],
  addons: {
    selectDomainsFMC: createSelector("selectDomainsItems", (items) => {
      if (!items.length) return null;
      const filtered = items.filter((d) => {
        return d.val === "FMC";
      });
      if (!filtered.length) return null;
      return filtered[0];
    }),
    selectDomainsATPM: createSelector("selectDomainsItems", (items) => {
      if (!items.length) return null;
      const filtered = items.filter((d) => {
        return d.val === "Aircrew Training Program Manager";
      });
      if (!filtered.length) return null;
      return filtered[0];
    }),
    selectDomainsBATPM: createSelector("selectDomainsItems", (items) => {
      if (!items.length) return null;
      const filtered = items.filter((d) => {
        return d.val === "Backup ATPM";
      });
      if (!filtered.length) return null;
      return filtered[0];
    }),
    selectDomainsItemsByGroup: createSelector("selectDomainsItems", (items) => {
      if (!items) return null;
      const byGroup = {};
      items.forEach((item) => {
        if (!byGroup.hasOwnProperty(item.grp)) byGroup[item.grp] = [];
        byGroup[item.grp].push(item);
      });
      return byGroup;
    }),
  },
});
