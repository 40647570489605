export default {
  name: "fileIo",

  getReducer: () => {
    const initialData = {
      file: null,
      upload_url: null,
      download_url: null,
      isUploading: false,
      isDeleting: false,
      shouldGet: false,
      shouldPut: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "FILE_IO_UPLOAD_STARTED":
        case "FILE_IO_UPLOAD_SET_URL":
        case "FILE_IO_UPLOAD_PUT_STARTED":
        case "FILE_IO_UPLOAD_FINISHED":
        case "FILE_IO_DELETE_FINISHED":
        case "FILE_IO_DELETE_STARTED":
        case "FILE_IO_DOWNLOAD_STARTED":
        case "FILE_IO_DOWNLOAD_SET_URL":
        case "FILE_IO_DOWNLOAD_GET_STARTED":
        case "FILE_IO_DOWNLOAD_FINISHED":
        case "FILE_IO_UPLOAD_ERROR":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doFileIoUpload:
    (url, file, rel, data, callback) =>
      ({ dispatch, store, apiUpload }) => {
        dispatch({
          type: "FILE_IO_UPLOAD_STARTED",
          payload: { isUploading: true },
        });
        const org = store.selectOrgsByRoute();
        const slug = org ? org.slug : "noorg";
        let postUrl = url;
        if (!postUrl) {
          postUrl = `/docs/upload/${slug}`;
        }
        let newFile = new File([file], file.name.replaceAll("'", ' '), {
          type: file.type,
          lastModified: file.lastModified
        });
        apiUpload(
          postUrl,
          { file: newFile, rel, data: JSON.stringify(data) },
          (err, response, body) => {
            if (err || response.statusCode !== 200) {
              dispatch({
                type: "FILE_IO_UPLOAD_ERROR",
                payload: {
                  isUploading: false,
                },
              });
            } else {
              dispatch({
                type: "FILE_IO_UPLOAD_FINISHED",
                payload: {
                  rel: rel,
                  file: newFile,
                  isUploading: false,
                },
              });
              if (callback && typeof callback === "function") callback();
            }
          }
        );
      },

  doFileIoPut:
    () =>
      ({ dispatch, store, apiPut }) => {
        dispatch({
          type: "FILE_IO_UPLOAD_PUT_STARTED",
          payload: { shouldPut: false },
        });
        const data = store.selectFileIoUploadData();
        fetch(data.upload_url, {
          method: "PUT",
          body: data.file,
        })
          .then((data) => {
            dispatch({
              type: "FILE_IO_UPLOAD_FINISHED",
              payload: {
                file: null,
                upload_url: null,
                isUploading: false,
              },
            });
          })
          .catch((err) => {
            console.error(err);
          });
      },


  doFileIoDownload: (s3_key, callback) => ({ dispatch, store, apiGet }) => {
    dispatch({ type: "FILE_IO_DOWNLOAD_STARTED" });

    apiGet(`/docs/download/${s3_key}`, (err, response) => {
      if (err) {
        console.error('Error downloading file:', err);
        return;
      }

      if (response.statusCode !== 200) {
        throw new Error('Network response was not ok');
      }

      const contentDisposition = response.headers['content-disposition'];
      let filename = s3_key; // Default to s3_key in case filename is not found

      if (contentDisposition && contentDisposition.indexOf('filename=') !== -1) {
        const matches = /filename=([^;]+)/.exec(contentDisposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      const blob = response.body;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename; // Use the filename from the response
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      callback && callback();
    }, { responseType: 'blob' }); // Pass 'blob' as the responseType
  },

  doFileIoGet:
    () =>
      ({ dispatch, store }) => {
        dispatch({
          type: "FILE_IO_DOWNLOAD_GET_STARTED",
          payload: {
            shouldGet: false,
          },
        });
        const url = store.selectFileIoDownloadUrl();
        window.open(url, "_blank");
        dispatch({
          type: "FILE_IO_DOWNLOAD_FINISHED",
          payload: {
            download_url: null,
          },
        });
      },

  doFileIoDelete:
    (url, s3_key, rel, admin, callback) =>
      ({ dispatch, store, apiDelete }) => {
        dispatch({
          type: "FILE_IO_DELETE_STARTED",
          payload: { isDeleting: true },
        });
        const org = store.selectOrgsByRoute();
        const slug = org ? org.slug : "noorg";
        let deleteUrl = `${url}/${s3_key}`;
        if (!url) {
          if (admin) {
            deleteUrl = `/admin/docs/${s3_key}`;
          } else {
            deleteUrl = `/docs/delete/${slug}/${s3_key}`;
          }
        }
        apiDelete(deleteUrl, { rel }, (err, response, body) => {
          if (err || response.statusCode !== 200) {
            // do something
          } else {
            dispatch({
              type: "FILE_IO_DELETE_FINISHED",
              payload: {
                rel: rel,
                isDeleting: false,
              },
            });
            if (callback && typeof callback === "function") callback();
          }
        });
      },

  selectFileIoUploadData: (state) => {
    return {
      file: state.fileIo.file,
      upload_url: state.fileIo.upload_url,
    };
  },

  selectFileIoDownloadUrl: (state) => {
    return state.fileIo.download_url;
  },

  selectFileIoIsUploading: (state) => {
    return state.fileIo.isUploading;
  },

  selectFileIoIsDeleting: (state) => {
    return state.fileIo.isDeleting;
  },

  reactFileIoShouldPut: (state) => {
    if (state.fileIo.shouldPut) return { actionCreator: "doFileIoPut" };
  },

  reactFileIoShouldGet: (state) => {
    if (state.fileIo.shouldGet) return { actionCreator: "doFileIoGet" };
  },
};
