import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "redux-bundler-react";
import moment from "moment";
import "./archive.css";
import MissionCardRegion from "../_shared/mission-card-region";

const ButtonGroup = ({ onChange, value }) => {
  const _onClick = (e) => onChange(e.target.value);
  return (
    <div
      className="btn-group mr-1 w-100"
      role="group"
      aria-label="Basic example"
    >
      <button
        type="button"
        className={`btn btn-${value === "All" ? "primary" : "outline-primary"}`}
        value="All"
        onClick={_onClick}
      >
        All time
      </button>
      <button
        type="button"
        className={`btn btn-${value === "30" ? "primary" : "outline-primary"}`}
        value="30"
        onClick={_onClick}
      >
        Last 30
      </button>
      <button
        type="button"
        className={`btn btn-${value === "60" ? "primary" : "outline-primary"}`}
        value="60"
        onClick={_onClick}
      >
        Last 60
      </button>
      <button
        type="button"
        className={`btn btn-${value === "90" ? "primary" : "outline-primary"}`}
        value="90"
        onClick={_onClick}
      >
        Last 90
      </button>
    </div>
  );
};

const Filter = ({
  startDate,
  endDate,
  onStartChange,
  onEndChange,
  onDaysChange,
  days,
  onRangeSubmit,
  onCheckChange,
}) => {
  return (
    <div className="d-flex flex-column w-100 mb-4">
      <div className="filter-body">
        <div className="d-flex flex-column w-100 align-items-center">
          <div className="d-flex mb-2 w-100">
            <b>Show Mission from (days):</b>
          </div>
          <ButtonGroup onChange={onDaysChange} value={days} />
        </div>
        <div className="d-flex w-100 h-100 align-items-center justify-content-center mt-4 mb-4">
          <b>OR</b>
        </div>
        <div>
          <div className="d-flex">
            <div className="d-flex flex-column w-100">
              <label className="mr-2">
                <b>Select Date Range</b>
              </label>
              <div className="date-range">
                <div className="w-100">
                  <ReactDatePicker
                    className="form-control"
                    selected={startDate}
                    onChange={onStartChange}
                    placeholderText="Min Date"
                  />
                </div>
                <div className="w-100">
                  <ReactDatePicker
                    className="form-control"
                    selected={endDate}
                    onChange={onEndChange}
                    placeholderText="Max Date"
                  />
                </div>
                <button className="btn btn-success" onClick={onRangeSubmit}>
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-check mt-4">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          onChange={onCheckChange}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          <b>Show Completed Missions Only</b>
        </label>
      </div>
    </div>
  );
};
const Archive = (props) => {
  const { missionsItems: items, missionsIsLoading, doMissionsFetch } = props;
  const [filtered, setFiltered] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [days, setDays] = useState("All");
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    doMissionsFetch();
  }, []);

  useEffect(() => {
    setFiltered(
      items.filter((item) => {
        return item.archived === 1;
      })
    );
  }, [items]);

  useEffect(() => {
    /* if a date range is selected ignore the button group selection */
    if (!startDate && !endDate) {
      if (days !== "All") {
        setFiltered(
          items.filter((mission) => {
            let difference = new Date() - new Date(mission.date_end).getTime();
            let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
            if (completed)
              return (
                totalDays <= parseInt(days) &&
                mission.archived === 1 &&
                mission.debrief_status === "complete"
              );
            else return totalDays <= parseInt(days) && mission.archived === 1;
          })
        );
      } else {
        if (completed)
          setFiltered(
            items.filter(
              (item) =>
                item.archived === 1 && item.debrief_status === "complete"
            )
          );
        else setFiltered(items.filter((item) => item.archived === 1));
      }
    }
  }, [days]);

  const _submitDateRange = () => {
    if (startDate && endDate) {
      setDays("");
      setFiltered(
        items.filter(
          (target) =>
            target.archived === 1 &&
            moment(target.date_end).isBetween(startDate, endDate, null, "[]")
        )
      );
    }
  };

  const _onDaysChange = (days) => {
    setStartDate();
    setEndDate();
    setDays(days);
  };

  const _setShowCompleted = (e) => {
    setCompleted(e.target.checked);
    if (e.target.checked)
      setFiltered(
        filtered.filter((item) => item.debrief_status === "complete")
      );
    else {
      if (startDate && endDate) {
        setDays("");
        setFiltered(
          items.filter((target) =>
            moment(target.date_end).isBetween(startDate, endDate, null, "[]")
          )
        );
      } else {
        if (days !== "All") {
          setFiltered(
            items.filter((mission) => {
              let difference =
                new Date() - new Date(mission.date_end).getTime();
              let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
              return totalDays <= parseInt(days) && mission.archived === 1;
            })
          );
        } else {
          setFiltered(items.filter((item) => item.archived === 1));
        }
      }
    }
  };

  return (
    <div className="container-fluid mt-4">
      <Filter
        onStartChange={setStartDate}
        onEndChange={setEndDate}
        startDate={startDate}
        endDate={endDate}
        onDaysChange={_onDaysChange}
        days={days}
        onRangeSubmit={_submitDateRange}
        onCheckChange={_setShowCompleted}
      />
      <MissionCardRegion
        isLoading={missionsIsLoading}
        items={filtered}
        allowAdd={false}
      />
    </div>
  );
};

export default connect(
  "selectMissionsItems",
  "selectMissionsIsLoading",
  "doMissionsFetch",
  Archive
);
