import { sortBy } from "lodash";
import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";

export default createRestBundle({
  name: "aircraftReport",
  uid: "id",
  prefetch: false,
  staleAfter: 300,
  persist: false,
  routeParam: "",
  getTemplate: "/orgs/:orgSlug/reports/aircraft?:queryString",
  fetchActions: [],
  forceFetchActions: [],
  initialData: {
    _queryString: {},
    _lastStatsFetch: null,
    _stats: null,
    _lastStatsResource: null,
    _statsStaleAfter: 30000,
    _shouldFetchStats: true,
    _isStatsLoading: false,
    _isLoading: false,
  },
  urlParamSelectors: ["selectAircraftReportFormattedQueryString"],
  reduceFurther: (state, { type, payload }) => {
    switch (type) {
      case "AIRCRAFTREPORT_FETCH_STATS_STARTED":
      case "AIRCRAFTREPORT_FETCH_STATS_ABORTED":
      case "AIRCRAFTREPORT_FETCH_STATS_ERROR":
      case "AIRCRAFTREPORT_QUERY_STRING_UPDATED":
      case "AIRCRAFTREPORT_INIT_ON_REPORT_PAGE":
        return Object.assign({}, state, payload);
      case "AIRCRAFTREPORT_FETCH_STATS_FINISHED":
        return Object.assign({}, payload);
      case "URL_UPDATED":
        return Object.assign({}, state, { _shouldFetchStats: true });
      default:
        return state;
    }
  },
  addons: {
    doAircraftReportDownloadAsCsv:
      () =>
        ({ store }) => {
          const ignoreFields = ["id", "aircraft_id"];
          const data = store.selectAircraftReportItems();
          if (!data || !data.length) return null;
          const csvRows = [];

          const fixCommas = (value) => {
            if (value && typeof value === "string" && value.indexOf(",") !== -1)
              return `"${value}"`;
            return value;
          };

          const setHeaders = (item) => {
            const head = [];
            Object.keys(item).forEach((key) => {
              if (ignoreFields.indexOf(key) === -1) {
                if (key === "deactivated") key = "status";
                head.push(fixCommas(key.toUpperCase()));
              }
            });
            csvRows.push(head.join(","));
          };

          data.forEach((item, i) => {
            if (i === 0) setHeaders(item);
            const row = [];
            Object.keys(item).forEach((key) => {
              if (ignoreFields.indexOf(key) === -1)
                row.push(fixCommas(item[key]));
            });
            csvRows.push(row.join(","));
          });

          const csv = csvRows.join("\n");
          var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, "export.csv");
          } else {
            var link = document.createElement("a");
            if (link.download !== undefined) {
              // feature detection
              // Browsers that support HTML5 download attribute
              var url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", "export.csv");
              link.style.visibility = "hidden";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        },

    doAircraftReportFetchStats:
      () =>
        ({ dispatch, store, apiGet }) => {
          dispatch({
            type: "AIRCRAFTREPORT_FETCH_STATS_STARTED",
            payload: {
              _shouldFetchStats: false,
              _isStatsLoading: true,
              _isLoading: true,
            },
          });

          const flags = store.selectAircraftReportFlags();
          const url = store.selectAircraftReportFetchStatsUrl();
          const isStale = store.selectAircraftReportIsStatsStale();
          const lastResource = store.selectAircraftReportLastStatsResource();

          if (url.indexOf("/:") !== -1) {
            dispatch({
              type: "AIRCRAFTREPORT_FETCH_STATS_ABORTED",
              payload: {
                _isStatsLoading: false,
              },
            });
          } else if (!isStale && url === lastResource) {
            dispatch({
              type: "AIRCRAFTREPORT_FETCH_STATS_ABORTED",
              payload: {
                _isStatsLoading: false,
              },
            });
          } else {
            apiGet(url, (err, response, body) => {
              if (err || response.statusCode !== 200) {
                dispatch({
                  type: "AIRCRAFTREPORT_FETCH_STATS_ERROR",
                  payload: {
                    _err: { err: err, response: response },
                    _isStatsLoading: false,
                    notification: {
                      statusCode: response.statusCode,
                    },
                  },
                });
              } else {
                const data = JSON.parse(body);
                dispatch({
                  type: "AIRCRAFTREPORT_FETCH_STATS_FINISHED",
                  payload: {
                    ...flags,
                    _lastStatsResource: url,
                    _lastStatsFetch: new Date(),
                    _isStatsLoading: false,
                    _isLoading: false,
                    _queryString: {},
                    _stats: data,
                  },
                });
              }
            });
          }
        },

    doAircraftReportUpdateQueryString:
      (update) =>
        ({ dispatch, store }) => {
          const q = store.selectAircraftReportQueryString();
          dispatch({
            type: "AIRCRAFTREPORT_QUERY_STRING_UPDATED",
            payload: {
              _queryString: {
                ...q,
                ...update,
              },
              _shouldFetch: true,
            },
          });
        },

    selectAircraftReportLastStatsResource: (state) => {
      return state.aircraftReport._lastStatsResource;
    },

    selectAircraftReportStatsStaleAfter: (state) => {
      return state.aircraftReport._statsStaleAfter;
    },

    selectAircraftReportLastStatsFetch: (state) => {
      return state.aircraftReport._lastStatsFetch;
    },

    selectAircraftReportIsStatsStale: createSelector(
      "selectAppTime",
      "selectAircraftReportStatsStaleAfter",
      "selectAircraftReportLastStatsFetch",
      (now, staleAfter, lastFetch) => {
        return now - new Date(lastFetch) > staleAfter;
      }
    ),

    selectAircraftReportIsStatsLoading: (state) => {
      return state.aircraftReport._isStatsLoading;
    },

    selectAircraftReportQueryString: (state) => {
      return state.aircraftReport._queryString;
    },

    selectAircraftReportFormattedQueryString: createSelector(
      "selectAircraftReportQueryString",
      "selectAircraftReportStats",
      (queryString, stats) => {
        if (!queryString || !stats) return "";
        const qsItems = [];
        for (var key in queryString) {
          const val = queryString[key];

          // if there's nothing at this key, bail
          if (!val) continue;

          // if it's an array with nothing in it it will fall to here, if nothing there then bail
          if (val.hasOwnProperty("length") && !val.length) continue;

          // if it's one of our time fields then handle that
          if (
            ["service_entry_date", "last_flight_date", "flight_time"].indexOf(
              key
            ) !== -1
          ) {
            // if there is a value in from then add that to the query string
            if (val.from)
              qsItems.push(
                `${key}_from=${encodeURIComponent(val.from.toISOString())}`
              );
            // if there is a value in the to key then add that
            if (val.to)
              qsItems.push(
                `${key}_to=${encodeURIComponent(val.to.toISOString())}`
              );
            continue;
          }

          // if it's one of our number fields then handle that
          if (["cost", "total_flight_minutes"].indexOf(key) !== -1) {
            // if there is a value in from then add that to the query string
            if (val.from)
              qsItems.push(`${key}_from=${encodeURIComponent(val.from)}`);
            // if there is a value in the to key and it's not the default then add that
            if (key === "cost" && val.to && val.to < stats.max_cost)
              qsItems.push(`${key}_to=${encodeURIComponent(val.to)}`);
            if (
              key === "total_flight_minutes" &&
              val.to &&
              val.to < stats.max_total_flight_minutes
            )
              qsItems.push(`${key}_to=${encodeURIComponent(val.to)}`);
            continue;
          }

          // otherwise, just add it to the querystring as-is
          qsItems.push(`${key}=${encodeURIComponent(val)}`);
        }

        if (!qsItems.length) return {};

        return {
          queryString: qsItems.join("&"),
        };
      }
    ),

    selectAircraftReportStats: (state) => {
      return state.aircraftReport._stats || null;
    },

    selectAircraftReportFetchStatsUrl: createSelector(
      "selectRouteParams",
      (params) => {
        const availableParams = {
          ...params,
        };
        let url = "/orgs/:orgSlug/reports/aircraft/stats";
        Object.keys(availableParams).forEach((key) => {
          url = url.replace(`:${key}`, availableParams[key]);
        });
        return url;
      }
    ),

    selectAircraftReportParameters: createSelector(
      "selectAircraftReportStats",
      "selectDomainsItemsByGroup",
      "selectOrgsByRoute",
      (stats, domainsByGroup, org) => {
        if (!stats || !org) return null;
        const parameters = [];
        parameters.push({
          type: "checkbox-search",
          column: "org_name",
          title: "Organization",
          options: stats.orgs.map((o) => {
            return { id: o, val: o, checkedByDefault: org.name === o };
          }),
        });
        parameters.push({
          type: "text-search",
          column: "name",
          title: "Name",
        });
        parameters.push({
          type: "text-search",
          column: "make",
          title: "Make",
        });
        parameters.push({
          type: "text-search",
          column: "model",
          title: "Model",
        });
        parameters.push({
          type: "checkbox-search",
          column: "usace_approved",
          title: "Usace Approved",
          options: [
            { id: "true", val: "Approved" },
            { id: "false", val: "Unapproved" },
          ],
        });
        parameters.push({
          type: "checkbox-search",
          column: "deactivated",
          title: "Status",
          options: sortBy(domainsByGroup["equipment_status"], ["display_order"]).map(t => {
            return { id: t.val, val: t.val }
          })
        });
        parameters.push({
          type: "text-search",
          column: "awr_number",
          title: "AWR Number",
        });
        parameters.push({
          type: "text-search",
          column: "serial_no",
          title: "Serial No.",
        });
        parameters.push({
          type: "text-search",
          column: "faa_id",
          title: "FAA ID",
        });
        parameters.push({
          type: "text-search",
          column: "barcode",
          title: "Barcode",
        });
        parameters.push({
          type: "text-search",
          column: "hq_avn_barcode",
          title: "HQ Barcode",
        });
        parameters.push({
          type: "checkbox-search",
          column: "group_type",
          title: "Group Type",
          options: domainsByGroup["group_type"].map((r) => {
            return { id: r.val, val: r.val };
          }),
        });
        parameters.push({
          type: "checkbox-search",
          column: "operational",
          title: "Operational",
          options: domainsByGroup["operational"].map((r) => {
            return { id: r.val, val: r.val };
          }),
        });
        parameters.push({
          type: "number-range-search",
          column: "cost",
          title: "Cost",
          min: 0,
          max: stats.max_cost || 0,
        });
        parameters.push({
          type: "date-range-search",
          column: "service_entry_date",
          title: "Service Entry Date",
        });
        parameters.push({
          type: "date-range-search",
          column: "last_flight_date",
          title: "Last Flight",
        });
        parameters.push({
          type: "number-range-search",
          column: "total_flight_minutes",
          title: "Flight Minutes",
          min: 0,
          max: stats.max_total_flight_minutes || 0,
        });
        parameters.push({
          type: "date-range-search",
          column: "flight_time",
          title: "Flight Minutes (in date range)",
        });
        return parameters;
      }
    ),

    reactAircraftReportShouldFetchStats: (state) => {
      if (state.aircraftReport._shouldFetchStats)
        return { actionCreator: "doAircraftReportFetchStats" };
    },
  },
});
