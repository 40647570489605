import React from "react";
import { connect } from "redux-bundler-react";

class Body extends React.Component {
  render() {
    const { route: Route } = this.props;
    return (
      <main className="main">
        <Route />
      </main>
    );
  }
}

export default connect(
  "selectRoute",
  Body
);
