import React from "react";
import { connect } from "redux-bundler-react";
import SearchInput from "../_shared/search-input/search-input";
import Grid from "../_shared/grid";
import { orderBy } from "lodash";
import RoleFilter from "../../app-containers/context-providers/role-filter";
import OrgFilter from "../../app-containers/context-providers/org-filter";
import Loader from "../../app-components/loader";
import classnames from "../../utils/classnames";
import { customComparator } from "../_shared/helper";
import { differenceInDays } from "date-fns";
class CustomTooltip {
  init(params) {
    const eGui = (this.eGui = document.createElement("div"));
    const data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
    eGui.innerHTML = `<p>${data.operational ? data.operational : "Not Specified"
      }</p>`;
    eGui.style["background-color"] = "white";
  }
  getGui() {
    return this.eGui;
  }
}

class GCS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      showingInventory: true,
      showingRequests: false,
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleContainerChange = this.handleContainerChange.bind(this);
  }

  componentDidMount() {
    const { doGcsFetch, gcsIsLoading, doEquipmentRequestInitUrlParams } =
      this.props;
    if (!gcsIsLoading) {
      doGcsFetch();
    }
    doEquipmentRequestInitUrlParams();
  }

  handleSearch(e) {
    this.setState({
      searchTerm: e.target.value,
    });
  }

  handleSelect(slug) {
    const { doUpdateRelativeUrl, orgsByRoute } = this.props;
    doUpdateRelativeUrl(`/${orgsByRoute.slug}/gcs/${slug}`);
  }

  handleContainerChange(e) {
    let selectedContainer = `showing${e.currentTarget.name}`;
    let otherContainer =
      e.currentTarget.name === "Inventory"
        ? "showingRequests"
        : "showingInventory";
    this.setState({
      [selectedContainer]: !this.state[selectedContainer],
      [otherContainer]: !this.state[otherContainer],
    });
  }

  render() {
    const { showingInventory } = this.state;
    const {
      gcsItemsNotDeactivated: gcsItems,
      orgsByRoute,
      gcsIsSaving,
      gcsIsLoading,
      equipmentRequestItems: requestItems,
      equipmentRequestIsSaving,
      equipmentRequestIsLoading,
      equipmentRequestUserIsAdmin: userIsAdmin,
    } = this.props;
    const defaultColDef = {
      resizable: true,
      sortable: true,
      filter: "agTextColumnFilter",
    };
    const items = showingInventory ? gcsItems : requestItems;
    const isSaving = showingInventory ? gcsIsSaving : equipmentRequestIsSaving;
    const isLoading = showingInventory
      ? gcsIsLoading
      : equipmentRequestIsLoading;

    if (isSaving || isLoading) {
      return (
        <div className="container-fluid mt-4">
          <Loader opt="dissolve-cube" />
        </div>
      );
    }

    let initConfig = {
      tooltipShowDelay: 0,
      suppressScrollOnNewData: true,
      domLayout: "autoHeight",
      defaultColDef: defaultColDef,
      tooltipComponent: CustomTooltip,
      columnDefs: [
        {
          headerName: showingInventory ? "" : "Type",
          field: showingInventory ? "operational" : "action",
          tooltipField: showingInventory ? "operational" : "action",
          tooltipComponentParams: showingInventory ? "operational" : "action",
          width: showingInventory ? 60 : 100,
          maxWidth: showingInventory ? 70 : 100,
          cellStyle: (params) => {
            return { minWidth: "3rem" };
          },
          cellRenderer: (params) => {
            const isDisplayOnly = params.data.deactivated === 'Display Only';
            const statusIconCls = classnames({
              "w-auto ml-2": true,
              mdi: true,
              "mdi-checkbox-blank-circle": showingInventory && !isDisplayOnly,
              "text-success": showingInventory && params.value == "FMC",
              "text-warning": showingInventory && params.value == "PMC",
              "text-danger": showingInventory && params.value == "NMC",
              "text-info mdi-plus":
                !showingInventory && params.data.deactivated === "Add",
              "text-info mdi-pin":
                isDisplayOnly
            });
            return (
              <>
                <span style={{ fontSize: "16px" }} className={statusIconCls} />
              </>
            );
          },
        },
        {
          headerName: "Name",
          field: "name",
          comparator: customComparator,
          cellRenderer: (params) => {
            let href = showingInventory
              ? `/${orgsByRoute.slug}/gcs/${params.data.slug}`
              : `/${orgsByRoute.slug}/gcs/equipment-requests/${params.data.slug}`;
            let isNew =
              differenceInDays(Date.now(), new Date(params.data.create_date)) <=
              30;
            return (
              <a href={href}>
                {isNew && (
                  <span
                    className="mdi mdi-new-box text-primary"
                    style={{ fontSize: "16px" }}
                  />
                )}
                {params.value}
              </a>
            );
          },
        },
        {
          headerName: "Make",
          field: "make",
          sortable: true,
        },
        {
          headerName: "Model",
          field: "model",
          sortable: true,
        },
      ],
      onRowDoubleClicked: (params) => {
        this.handleSelect(params.data.slug);
      },
    };
    if (gcsIsSaving || gcsIsLoading) {
      return (
        <div className="container-fluid mt-4">
          <Loader opt="dissolve-cube" />
        </div>
      );
    }

    let config = initConfig;
    if (this.state.showingInventory) {
      config = {
        ...initConfig,
        columnDefs: [
          ...initConfig.columnDefs,
          {
            headerName: "Form Factor",
            field: "form_factor",
            sortable: true,
          },
          {
            headerName: "OS",
            field: "os",
            sortable: true,
          },
          {
            headerName: "Serial No",
            field: "serial_no",
            sortable: true,
          },
          {
            headerName: "Barcode",
            field: "barcode",
            sortable: true,
          },
        ],
      };
    }
    const { searchTerm } = this.state;
    const matcher = new RegExp(searchTerm, "ig");
    const filtered = items.filter((item) => {
      const testString = Object.values(item).join(" ");
      return testString.match(matcher);
    });
    const inventoryBtnCls = classnames({
      btn: true,
      "btn-outline-primary": !this.state.showingInventory,
      "btn-primary": this.state.showingInventory,
    });
    const requestsBtnCls = classnames({
      btn: true,
      "btn-outline-primary": !this.state.showingRequests,
      "btn-primary": this.state.showingRequests,
    });
    const isNewIteratree = (o) => {
      return differenceInDays(Date.now(), new Date(o.create_date)) <= 30;
    };
    const sorted = orderBy(
      filtered,
      [isNewIteratree, "create_date"],
      ["desc", "desc"]
    );
    return (
      <div className="container-fluid mt-4">
        <SearchInput
          onChange={this.handleSearch}
          value={this.state.searchTerm}
        />
        <div className="card">
          <div className="card-header">
            <i className="ms ms-drone-remote"></i> Ground Control Stations
          </div>
          <div className="card-body">

            {!userIsAdmin && (
              <div className="row">
                <div className="mx-auto btn-group">
                  <button
                    name="Inventory"
                    className={inventoryBtnCls}
                    onClick={this.handleContainerChange}
                  >
                    In Inventory
                  </button>
                  <button
                    name="Requests"
                    className={requestsBtnCls}
                    onClick={this.handleContainerChange}
                  >
                    Active Requests
                  </button>
                </div>
              </div>
            )}
            <div className="d-flex">
              <small className="text-muted mt-2">
                Double click row to open details
              </small>
              <div className="float-right ml-auto pb-3">
                <a
                  className="btn btn-outline-primary"
                  href={`/${orgsByRoute.slug}/gcs/new`}
                >
                  <span>{userIsAdmin ? "Add" : "Request"} New</span>
                </a>
              </div>
            </div>
            <Grid config={config} data={sorted} style={{ width: "100%" }} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "doEquipmentRequestInitUrlParams",
  "selectEquipmentRequestItems",
  "selectEquipmentRequestIsSaving",
  "selectEquipmentRequestIsLoading",
  "selectEquipmentRequestUserIsAdmin",
  "selectGcsItemsNotDeactivated",
  "selectOrgsByRoute",
  "selectGcsIsSaving",
  "doGcsFetch",
  "selectGcsIsLoading",
  GCS
);
