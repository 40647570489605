const customComponents = {
  name: "customComponents",
  level: "inline",
  start(src) {
    return src.match(/\[className/)?.index
  },
  tokenizer(src, tokens) {
    let rule = /^(?<obj>\[(?<props>className\=.*?)\])/ig
    let match = rule.exec(src);
    if (match) {
      let propsObj = {}
      let { props } = match.groups;
      let propsSplit = props.split(/(?<!style\=\"[\.\-|\:|\,|\w|\s|\d|\)|\(]*?);(?![\.\-|\:|\,|\w|\s|\d|\)|\(]*?\")/);
      propsSplit.forEach(p => {
        let pair = p.split('=');
        if (pair.length == 2) {
          let key = pair[0].replaceAll("'", "");
          let val = pair[1].trim().replaceAll("'", "")
          propsObj[key] = val;
        }
      })
      let token = {
        type: "customComponents",
        raw: match.groups.obj,
        ...propsObj,
        text: propsObj.value ? propsObj.value : "",
        tokens: [],
      };
      this.lexer.inlineTokens(token.text, token.tokens);
      return token;
    }
  },
  renderer(token) {
    let classList = token.className.split(" ");
    let styleStr = token.style ? ` style=${token.style}` : "";

    let iconClsStr = token.iconCls ? `class="${token.iconCls}"` : "";
    let iconStyleStr = token.iconStyle ? ` style=${token.iconStyle}` : "";
    if (classList.includes("icon")) {
      return `<span class="${token.iconCls}">${this.parser.parseInline(token.tokens)}</span>`
    }
    else if (classList.includes("badge")) {
      return `
      <span class="${token.badgeCls}">
        <i ${iconClsStr}></i>
        ${this.parser.parseInline(token.tokens)}
      </span>`
    }
    else if (classList.includes("menu-item-drawing")) {
      return `
      <div class="${token.className}" ${styleStr}>
        <i ${iconClsStr}></i>
        <span>${this.parser.parseInline(token.tokens)}</span>
      </div>`;
    }
    else if (classList.includes("redirect-button")) {
      let styleStr = token.style ? ` style=${token.style}` : "";
      return `
      <button name="${token.dest}" class="redirect-button btn btn-link d-inline my-0 mx-2 p-0 pb-1" ${styleStr}>
        See <b>${this.parser.parseInline(token.tokens)}</b>
      </button>`;
    }
    else if (classList.includes("button-drawing")) {
      return `
      <button class="${token.btnCls} display-only" ${styleStr}>
        <i ${iconClsStr} ${iconStyleStr} ></i>
        ${this.parser.parseInline(token.tokens)}
      </button>`;
    }
    else return `<span class="${token.className}">${this.parser.parseInline(token.tokens)}</span>`
  }
};
export default customComponents;