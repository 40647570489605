import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";

class SidebarNavItem extends React.Component {
  render() {
    const {
      title,
      iconClass,
      href,
      pathname,
      activeForChildren,
      badgeCount,
      badgeStyle
    } = this.props;
    let active = false;
    if (activeForChildren) {
      active = pathname.indexOf(href) !== -1;
    } else {
      active = pathname === href;
    }
    const linkClass = classnames({
      "nav-link": true,
      active: active,
    });
    return (
      <li className="nav-item active" style={{ whiteSpace: "nowrap" }}>
        <a className={linkClass} href={href}>
          {badgeCount && badgeCount > 0 ? (
            <span className="badge badge-pill badge-danger" style={badgeStyle}>{badgeCount}</span>
          ) : null}
          <i className={`${iconClass} nav-icon`}></i>
          {` ${title}`}
        </a>
      </li>
    );
  }
}

export default connect("selectPathname", SidebarNavItem);
