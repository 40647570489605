import React from "react";
import { connect } from "redux-bundler-react";
import DomainsValueListItem from "./domains-value-list-item";
import DomainValueEditor from "./domain-value-editor";
import { sortBy } from "lodash";

class DomainsValuesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdding: false
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleAddNew = this.handleAddNew.bind(this);
    this.handleStopEditing = this.handleStopEditing.bind(this);
  }

  handleSave(domain) {
    const { doDomainsEditableSave } = this.props;
    this.setState(
      {
        isAdding: false
      },
      () => {
        doDomainsEditableSave(domain);
      }
    );
  }

  handleAddNew() {
    this.setState({
      isAdding: true
    });
  }

  handleStopEditing() {
    this.setState({
      isAdding: false
    });
  }

  renderInput() {
    const { isAdding } = this.state;
    const { group } = this.props;
    if (!isAdding) return null;
    return (
      <DomainValueEditor
        grp={group}
        onSave={this.handleSave}
        onCancel={this.handleStopEditing}
      />
    );
  }

  render() {
    const { isAdding } = this.props;
    const { domains } = this.props;
    if (domains.length === 0) {
      return <div>Pick a group from the list on the left</div>;
    }
    return (
      <div>
        <ul className="list-group">
          {sortBy(domains, ["display_order"]).map(domain => {
            return (
              <DomainsValueListItem
                key={domain.id}
                domain={domain}
                disable={isAdding}
              />
            );
          })}
          {this.renderInput()}
        </ul>
        <div className="float-right mt-3">
          <button
            disabled={isAdding}
            onClick={this.handleAddNew}
            className="btn btn-sm btn-primary"
          >
            Add New
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  "doDomainsEditableSave",
  DomainsValuesList
);
