import { find } from "lodash";
import { createSelector } from "redux-bundler";
import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "atpmTransfer",
  uid: "id",
  prefetch: true,
  staleAfter: 900000,
  persist: false,
  routeParam: null,
  getTemplate: "/atpmTransfers",
  putTemplate: "/atpmTransfers/:item.id",
  postTemplate: "/atpmTransfers",
  deleteTemplate: "/atpmTransfers/:item.id",
  fetchActions: ["URL_UPDATED", "ATPMTRANSFER_SAVE_FINISHED", "AUTH_LOGGED_IN"],
  addons: {
    selectAtpmTransferCurrentUserBackup: createSelector(
      "selectAtpmTransferItems",
      "selectProfileActive",
      "selectProfilesByOrg",
      (atpmTransferItems, profileActive, profilesByOrg) => {
        let transferItems =
          atpmTransferItems && atpmTransferItems.length > 0
            ? atpmTransferItems
            : [];

        transferItems = transferItems.map((item) => ({
          ...item,
          name:
            find(profilesByOrg, (p) => p.profile_id === item.atpm_id) &&
            find(profilesByOrg, (p) => p.profile_id === item.atpm_id).name,
        }));
        // if they dont have a name (which comes from profilesBYOrg) that means that person is not in this org and we dont need to show the banner
        return transferItems.filter(
          (item) => item.backup_atpm_id === profileActive.id && !!item.name
        );
      }
    ),
    selectAtpmTransferCurrentUserAtpm: createSelector(
      "selectAtpmTransferItems",
      "selectProfileActive",
      "selectProfilesByOrg",
      (atpmTransferItems, profileActive, profilesByOrg) => {
        let transferItems =
          atpmTransferItems && atpmTransferItems.length > 0
            ? atpmTransferItems
            : [];

        transferItems = transferItems.map((item) => ({
          ...item,
          name:
            find(profilesByOrg, (p) => p.profile_id === item.backup_atpm_id) &&
            find(profilesByOrg, (p) => p.profile_id === item.backup_atpm_id)
              .name,
        }));
        // if they dont have a name (which comes from profilesBYOrg) that means that person is not in this org and we dont need to show the banner
        return transferItems.filter(
          (item) => item.atpm_id === profileActive.id && !!item.name
        );
      }
    ),
  },
});
