import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "dashboardCounts",
  uid: "org",
  prefetch: true,
  staleAfter: 30000,
  persist: false,
  routeParam: "orgSlug",
  getTemplate: "/orgs/:orgSlug/dashboard/counts?rollup=true",
  putTemplate: "",
  postTemplate: "",
  deleteTemplate: "",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN"]
});
