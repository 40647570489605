import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import associatedItemModal from "./associated-item-modal";

const ModalButton = ({
  children,
  doDialogOpen,
  data,
  items,
  onItemClicked,
  updateDropdownItems,
  type,
  displayOnly,
}) => {
  return (
    <a
      className="link text-dark p-0 m-0"
      onClick={() =>
        doDialogOpen({
          content: associatedItemModal,
          props: {
            data,
            items,
            onItemClicked,
            updateDropdownItems,
            type,
            displayOnly,
          },
        })
      }>
      {children}
    </a>
  );
};
const AssociatedItemRenderer = ({
  doDialogOpen,
  data,
  aircraftConfigurationItems,
  onItemClicked,
  updateDropdownItems,
  displayOnly,
  type,
}) => {
  const [state, setState] = useState({
    items: [],
    numSelected: 0,
  });
  useEffect(() => {
    if (aircraftConfigurationItems) {
      let update = updateDropdownItems(data, displayOnly, type);
      setState({ ...state, ...update });
    }
  }, [aircraftConfigurationItems]);

  return (
    <ModalButton
      type={type}
      doDialogOpen={doDialogOpen}
      data={data}
      items={state.items}
      onItemClicked={onItemClicked}
      updateDropdownItems={updateDropdownItems}
      displayOnly={displayOnly}>
      <div className="d-flex flex-wrap align-items-center">
        <b>{state.numSelected} Items</b>
        <i className="mdi mdi-chevron-right text-dark mdi-14px d-flex" />
      </div>
    </ModalButton>
  );
};
export default connect(
  "selectAircraftConfigurationItems",
  "doDialogOpen",
  AssociatedItemRenderer
);
