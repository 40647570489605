import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
    name: "mmsSoftware",
    uid: "id",
    prefetch: true,
    routeParam: null,
    getTemplate: "/orgs/:orgSlug/mms/:mmsSlug/software",
    putTemplate: "/orgs/:orgSlug/mms/:mmsSlug/software/:item.id",
    postTemplate: "/orgs/:orgSlug/mms/:mmsSlug/software",
    deleteTemplate: "/orgs/:orgSlug/mms/:mmsSlug/software/:item.id",
    fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN"]
});