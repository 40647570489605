const alertConfig = {
    "Mission Details": 'mission_details',
    Inventory: 'inventory',
    Personnel: 'personnel',
    "Area of Interest":  'aoi',
    "Mission Area": 'mission_area',
    "Risk Assessment":  'raw',
    Figures:  'figures',
    Documents:  'documents',
    Notes:  'notes',
    Signatures:  'approvals',
    Flights:  'flights',
  };

export default alertConfig;