import React from "react";

class DeleteButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfirming: false,
    };
    this.delete = this.delete.bind(this);
    this.cancel = this.cancel.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  delete() {
    this.setState({ isConfirming: true });
  }

  cancel() {
    this.setState({ isConfirming: false });
  }

  confirm() {
    const { onDelete } = this.props;
    this.setState(
      {
        isConfirming: false,
      },
      onDelete()
    );
  }

  render() {
    const { isConfirming } = this.state;
    const { isLoading } = this.props;
    if (isLoading)
      return (
        <button className="btn btn-sm btn-danger">
          <i className="mdi mdi-loading mdi-spin mdi-14px" />
        </button>
      );
    else if (!isConfirming) {
      return (
        <button className="btn btn-sm btn-danger" onClick={this.delete}>
          {this.props.deleteText ? this.props.deleteText : "Delete"}
        </button>
      );
    } else {
      return (
        <div className="btn-group">
          <button className="btn btn-sm btn-secondary" onClick={this.cancel}>
            Cancel
          </button>
          <button className="btn btn-sm btn-danger" onClick={this.confirm}>
            Confirm
          </button>
        </div>
      );
    }
  }
}

export default DeleteButton;
