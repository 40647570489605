import React, { useState } from "react";
import { connect } from "redux-bundler-react";

export default connect(
  "selectProfileActive",
  "selectNotesParentId",
  "doNotesSave",
  ({ profileActive, item, notesParentId, doNotesSave, onComplete }) => {
    if (!item)
      item = {
        create_date: new Date(),
        last_updated_by: profileActive.edipi,
        name: profileActive.name,
        parent_id: notesParentId,
        note: "",
      };

    const [note, setNote] = useState(item.note);

    const save = () => {
      item.note = note;
      item.create_date = new Date().toISOString();
      doNotesSave(item, onComplete);
    };

    const cancel = () => {
      onComplete();
    };

    const lines = note.split("\n").length;

    return (
      <div className="clearfix mb-2">
        <div className="form-group">
          <textarea
            value={note}
            onKeyUp={(e) => {
              if (e.keyCode === 13 && e.ctrlKey) save();
            }}
            onChange={(e) => {
              setNote(e.target.value);
            }}
            className="form-control"
            rows={lines > 4 ? lines : 4}
          ></textarea>
        </div>
        <div className="float-right">
          <button className="btn btn-sm btn-secondary" onClick={cancel}>
            Cancel
          </button>
          <button className="btn btn-sm btn-primary ml-2" onClick={save}>
            Save
          </button>
        </div>
      </div>
    );
  }
);
