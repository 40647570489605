import createRestBundle from "./create-rest-bundle";
import { keyBy } from "lodash";
export default createRestBundle({
    name: "jobsViews",
    uid: "id",
    prefetch: true,
    persist: true,
    routeParam: null,
    getTemplate: "/job-board/:jobSlug/views",
    putTemplate: "/job-board/:jobSlug/views/:item.id",
    postTemplate: "/job-board/:jobSlug/views",
    deleteTemplate: "/job-board/:jobSlug/views/:item.id",
    fetchActions: [],
    forceFetchActions: ["JOBS_FETCH_STARTED", "JOBSVIEWS_SAVE_FINISHED", "JOBSVIEWS_DELETE_FINISHED"],
    initialData: {
        _jobViewsByUser: []
    },
    reduceFurther: (state, { type, payload }) => {
        switch (type) {
            case "JOBS_USER_VIEWS_FETCHED":
            case "JOBS_USER_VIEWS_FETCHED_ERROR":
                return Object.assign({}, state, payload)
            default:
                return state;
        }
    },

    addons: {
        selectJobsViewsByUser: (state) => {
            return state.jobsViews._jobViewsByUser
        },
        doFetchJobViewsByUser: () => ({ dispatch, store, apiGet }) => {
            apiGet('/job-board/views-by-user', (err, response, body) => {
                if (err || response.statusCode !== 200) {
                    dispatch({
                        type: "JOBS_USER_VIEWS_FETCHED_ERROR",
                        payload: {
                            _err: { response: response, err: err },
                            notification: {
                                statusCode: { response: response.statusCode }
                            }
                        }
                    })
                }
                else {
                    const jobViewsByUser = JSON.parse(body);
                    dispatch({
                        type: "JOBS_USER_VIEWS_FETCHED", payload: {
                            _jobViewsByUser: keyBy(jobViewsByUser, 'job_id')
                        }
                    })
                }
            })
        }
    }
});