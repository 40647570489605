import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";

export default createRestBundle({
  name: "missionsApprovalNotes",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/missions/:missionsSlug/approvalNotes",
  putTemplate: "/orgs/:orgSlug/missions/:missionsSlug/approvalNotes/:item.id",
  postTemplate: "/orgs/:orgSlug/missions/:missionsSlug/approvalNotes",
  deleteTemplate:
    "/orgs/:orgSlug/missions/:missionsSlug/approvalNotes/:item.id",
  fetchActions: ["AUTH_LOGGED_IN"],
  forceFetchActions: ["ACTIVE_TAB_UPDATE", "URL_UPDATED"],
  addons: {
    selectMissionsApprovalNotesItemsByMissionApprovalId: createSelector(
      "selectMissionsApprovalNotesItems",
      (items) => {
        const byMissionApprovalId = {};
        items.forEach((note) => {
          if (!byMissionApprovalId.hasOwnProperty(note.mission_approval_id))
            byMissionApprovalId[note.mission_approval_id] = [];
          byMissionApprovalId[note.mission_approval_id].push(note);
        });
        return byMissionApprovalId;
      }
    ),
  },
});
