import React from "react";
import classnames from "classnames"
import { findIndex, capitalize } from "lodash";
import PaginationBtnGrp from "../pagination-btn-grp"
import Dropdown from "./dropdown";

import "./item-board.css"
export const ItemBoardHeader = ({ children }) => {
    return (
        <div className="row mt-5 mx-lg-5 mx-0">
            {children}
        </div>
    )
}

export const ItemBoardBody = ({ minHeight = "75vh", children }) => {
    return (
        <div className="card mx-lg-5 mt-4" style={{ minHeight: minHeight }}>
            <div className="card-body d-flex flex-column" >
                {children}
            </div>
        </div>
    )
}
export const ItemBoardQueryStatusBar = ({ queryStatusParams, bundleState, doItemsUpdateQueryString, sortTextTranslations }) => {
    return (
        <div className="d-flex flex-row position-relative mt-0 pt-2">
            {
                queryStatusParams.map((param, idx) => {
                    let undoFilterBtnCls = classnames({
                        "d-none": bundleState[param.name] === param.undoVal || !bundleState[param.name],
                        "btn btn-sm btn-info mt-auto font-weight-bold ml-2": true,
                    })
                    return (
                        <button key={idx} className={undoFilterBtnCls} type="button" onClick={() => doItemsUpdateQueryString({ [param.name]: param.undoVal })} >
                            <i className="mdi mdi-close mr-2" />
                            {param.label}: {capitalize(bundleState[param.name])}
                        </button>
                    )
                })
            }
            <span className="position-relative ml-auto mb-auto px-1 py-2 mt-2 badge badge-secondary" style={{ marginBottom: "-1.2rem" }}>Sorting by: {sortTextTranslations[bundleState._sortBy]}</span>
        </div>
    )
}
export const ItemBoardQuerySearchBar = ({ children, searchBar, bundleState, doItemsUpdateQueryString }) => {
    const { placeholder, searchDropdownOptions, searchDropdownTitle } = searchBar
    const searchFieldChanged = (e) => {
        doItemsUpdateQueryString({ _searchTerms: e.currentTarget.value })
    }
    return (
        <div className="d-flex flex-column">
            <div className="item-board-searchbar input-group mr-1 mb-1" role="group">
                <Dropdown btnGroupCls="mr-auto" btnCls="btn-secondary" title={searchDropdownTitle} options={searchDropdownOptions} direction="dropdown-menu-left" />
                <input className="form-control" type="text" placeholder={placeholder} onChange={searchFieldChanged} value={bundleState._searchTerms ? bundleState._searchTerms : ''} />
            </div>
            {children}
        </div>
    )
}
export const ItemBoardQueryControls = ({ children, renderPrimaryControls, bundleState, doItemsUpdateQueryString, flags, sortOptions }) => {
    let sortDirIcon = classnames({
        mdi: true,
        "mdi-sort-ascending": flags._sortDir === 'ASC',
        "mdi-sort-descending": flags._sortDir === 'DESC',
    })
    const updateSortDirection = (e) => {
        let toggledVal = bundleState._sortDir === 'ASC' ? 'DESC' : 'ASC'
        doItemsUpdateQueryString({ _sortDir: toggledVal })
    }

    return (
        <div className="border-bottom">
            <div className="mt-4 mb-2 d-flex justify-content-between">
                {renderPrimaryControls()}
                {children}
                <div className="btn-group">
                    <button className="ml-2 btn btn-outline-primary" type="button" onClick={updateSortDirection}>
                        <span className={sortDirIcon} />
                    </button>
                    <Dropdown btnCls="btn-sm btn-outline-primary" direction="dropdown-menu-right" title="Sort" options={sortOptions} stateValueAsTitle={true} selectedIdx={findIndex(sortOptions, (o) => o.value === bundleState._sortBy)} />
                </div>
            </div>
        </div>
    )
}

const ItemBoard = ({ includePagination=true, className, children, style, doItemsUpdateQueryString, totalQueriedItems, pageIdx, itemsPerPage = 10 }) => {
    const pageChanged = (pageIdx) => {
        doItemsUpdateQueryString(pageIdx)
    }
    const renderPaginationBtnGrp = () => {
        if (totalQueriedItems > itemsPerPage) {
            return (
                <div className="mx-auto">
                    <PaginationBtnGrp numPages={Math.ceil(totalQueriedItems / itemsPerPage)} _onClick={pageChanged} pageIdx={pageIdx} />
                </div>
            )
        }
        else return null;
    }
    let itemBoardCls = classnames({
        "container-fluid d-flex flex-column": true,
        [className]: className
    })

    return (
        <div className={itemBoardCls} style={style}>
            {children}
        {includePagination && renderPaginationBtnGrp()}
        </div>
    )
}

export default ItemBoard;