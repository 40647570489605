import React, { useState, useEffect } from "react";
import { connect } from "redux-bundler-react";
import { formatDistance } from "date-fns";
import { marked } from "marked";
import purify from "dompurify";
import Dropdown from "./dropdown";
import DeleteDialog from "./delete-dialog";

import "./item-board.css";
import { capitalize } from "lodash";

const extractNameFromTokenPayload = (tokenPayload) => {
  let regExpFindCN = /(?<=CN=).*?(?=,)/;
  let userCN = regExpFindCN.exec(tokenPayload.subjectDN);
  if (userCN) {
    let firstAndLastName = userCN[0].split(".").slice(0, 2).reverse();
    return firstAndLastName.map((name) => capitalize(name)).join(" ");
  } else return null;
};

const ItemPageComment = ({
  permissions,
  routeInfo,
  renderName,
  renderExtraUserInfo,
  doDialogOpen,
  boardUrl,
  commentData,
  tokenPayload,
  parentItemData,
  sendFunc,
  deleteFunc,
  isForm = false,
  isUsers = false,
  userIsATPM = false,
  userIsHqAdmin = false,
  commentCols,
}) => {
  const [state, setState] = useState({
    parentItemData: { ...parentItemData },
    commentData: { ...commentData },
    commentText: commentData ? commentData.body : "",
    commentCols: commentCols,
  });

  const onTextChange = (e) => {
    setState({ ...state, commentText: e.currentTarget.value });
  };
  const onCommentSend = async () => {
    await sendFunc(
      Object.assign(
        {},
        {
          ...commentCols,
          created_by: tokenPayload.sub,
          created_by_name: extractNameFromTokenPayload(tokenPayload),
          body: state.commentText,
        }
      )
    );
    setState({ ...state, commentText: "" });
  };
  const deleteBtnClicked = (update) => {
    let permanent = update.type === "permDelete";
    doDialogOpen({
      content: DeleteDialog,
      props: {
        data: { ...commentData },
        deleteType: permanent ? "permanentDelete" : "normalDelete",
        commentCols,
        deleteText: `Are you sure? Deleting this comment will ${
          permanent
            ? "remove it from this post's comment section."
            : "only mark it as deleted."
        }`,
        sendFunc,
        deleteFunc,
        returnUrl: boardUrl,
        dataType: "comment",
        title: `Deleting Comment ${permanent ? "Permanently" : ""}`,
      },
    });
  };

  const renderDeleteBtnDropdown = () => {
    if (!isUsers && !userIsHqAdmin && !userIsATPM) return null;

    let options = [
      {
        update: { type: "normalDelete" },
        displayValue: "normalDelete",
        text: "Delete",
        handler: deleteBtnClicked,
        exitOnClick: true,
        itemCls: "text-danger",
      },
    ];
    if (userIsATPM) {
      options.push({
        update: { type: "permDelete" },
        displayValue: "permDelete",
        text: "Delete Permanently",
        handler: deleteBtnClicked,
        exitOnClick: true,
        itemCls: "text-danger",
      });
    }

    return (
      <Dropdown
        btnGroupCls="btn-link ml-auto mr-0 p-0"
        btnCls="btn-sm text-danger"
        direction="dropdown-menu-left"
        title="Options"
        options={options}
      />
    );
  };

  // Lets the user be able to hit 'Enter' to send a comment
  const checkForEnterKey = async (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      await onCommentSend(e);
    }
  };

  // Render the comment's create_date attribute as a string
  const renderTimeString = () => {
    let relativeDate = null;
    if (commentData && commentData.create_date) {
      relativeDate = formatDistance(
        new Date(commentData.create_date),
        new Date()
      );
      let timeStr = `${relativeDate} ago`;
      timeStr = timeStr.replace(/about/gi, "About");
      let title = commentData.create_date
        ? new Date(commentData.create_date).toLocaleDateString(undefined, {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        : null;
      return (
        <small className="p-1" title={title}>
          {timeStr}
        </small>
      );
    }
  };
  useEffect(() => {
    if (commentData) {
      setState({
        ...state,
        commentData: { ...commentData },
        commentText: commentData.body,
      });

      let hyperlinks = document.querySelectorAll(".comment .toast-body a");
      if (hyperlinks) {
        hyperlinks.forEach((link) => {
          // Setting target to '_blank' will tell browser to open the link in new tab
          link.setAttributeNS(null, "target", "_blank");
        });
      }
    }
  }, [commentData]);
  useEffect(() => {
    if (parentItemData) {
      setState({ ...state, parentItemData: { ...parentItemData } });
    }
  }, [parentItemData]);

  const renderCommenterName = () => {
    const { commentData, parentItemData } = state;

    // If the parentItemData already has the commenter associated with it, get that data; else create object with only the commenter's name available
    let user =
      parentItemData &&
      parentItemData.people &&
      parentItemData.people[commentData.created_by]
        ? parentItemData.people[commentData.created_by]
        : { phone: "", email: "", name: commentData.created_by_name, slug: {} };

    return (
      <div className="d-flex position-absolute my-auto flex-column w-75 mr-3">
        {renderName(commentData, routeInfo, user)}
        {renderExtraUserInfo
          ? renderExtraUserInfo(commentData, permissions, parentItemData)
          : null}
      </div>
    );
  };

  let isCurrentUsersComment =
    commentData && commentData.created_by === tokenPayload.sub;
  if (isForm) {
    return (
      <div className="col-4 item-comment is-form toast show h-auto">
        <div className="toast-body px-0 d-flex flex-row">
          <textarea
            className="d-inline mr-2 flex-fill"
            placeholder="Add a reply"
            rows={1}
            value={state.commentText}
            onChange={onTextChange}
            type="text"
            autoComplete="off"
            onKeyDown={checkForEnterKey}
          />
          <div className="d-flex flex-column">
            <button
              className="btn btn-primary float-right"
              type="submit"
              data-dismiss="toast"
              onClick={onCommentSend}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <span className="mdi mdi-send"></span>
            </button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div
        className={`col-4 item-comment toast show h-auto ${
          isCurrentUsersComment ? "from-user" : "from-other"
        }`}
        style={{ overflow: "visible" }}
      >
        <div className="toast-header d-flex flex-row w-100 justify-content-between">
          {renderCommenterName()}
          <div
            className="ml-auto mr-0 d-inline-flex flex-column mb-auto mt-0"
            style={{ overflow: "visible" }}
          >
            <span className="ml-auto mr-0 text-right">
              {renderTimeString()}
            </span>
            <span className="ml-auto mr-0">{renderDeleteBtnDropdown()}</span>
          </div>
        </div>
        <div className="toast-body">
          <div
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(
                marked(state.commentText ? state.commentText : "")
              ),
            }}
          />
        </div>
      </div>
    </>
  );
};

export default connect(
  "doDialogOpen",
  "selectRouteInfo",
  "selectTokenPayload",
  ItemPageComment
);
