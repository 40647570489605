import React from "react";
import { roleAbbreviations, roleColors } from "./helper";

const PersonnelListItem = ({ item }) => {
  const renderApprovalRole = () => {
    if (!item.approval_roles || !item.approval_roles.length) return null;
    return (
      <span className="float-right">
        {item.approval_roles.map((approval_role, i) => {
          return (
            <span
              key={i}
              title={`Since ${new Date(
                item.granted_date
              ).toLocaleDateString()}`}
              className={`badge badge-pill badge-${roleColors[approval_role]} ml-2`}>
              <i className="mdi mdi-account-check pr-1"></i>
              {roleAbbreviations[approval_role]}
            </span>
          );
        })}
      </span>
    );
  };

  return (
    <li className="list-group-item">
      <div className="row">
        <div className="col-sm">
          <a href={item.url}>{item.name}</a>
        </div>
        <div className="col-sm">{renderApprovalRole()}</div>
      </div>
    </li>
  );
};

const PersonnelList = ({ items: personnel }) => {
  if (!personnel) return null;
  personnel.sort((a, b) => {
    if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
    return 0;
  });
  return (
    <ul className="list-group">
      {personnel.map((item, i) => {
        return <PersonnelListItem key={i} item={item} />;
      })}
    </ul>
  );
};

export default PersonnelList;
