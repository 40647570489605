import React from "react";
import { connect } from "redux-bundler-react";
import ManageTransferCard from "./manage-transfer-card";

const ManageTransfers = ({ atpmTransferCurrentUserAtpm, doDialogClose }) => {
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Manage ATPM Transfers</h5>
        <button
          onClick={doDialogClose}
          className="close"
          type="button"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div className="modal-body">
        {atpmTransferCurrentUserAtpm &&
          atpmTransferCurrentUserAtpm.length > 0 &&
          atpmTransferCurrentUserAtpm.map((item, idx) => (
            <ManageTransferCard item={item} key={idx} />
          ))}
      </div>

      <div className="modal-footer">
        <button
          onClick={doDialogClose}
          className="btn btn-sm btn-secondary"
          type="button"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default connect(
  "selectAtpmTransferCurrentUserAtpm",
  "doDialogClose",
  "doAtpmTransferDelete",
  "doAtpmTransferSave",
  "selectAtpmTransferIsSaving",
  ManageTransfers
);
