import React from "react";
import { connect } from "redux-bundler-react";
import HazardTableRowForm from "./hazard-table-row-form";
import RoleFilter from "../../../../../app-containers/context-providers/role-filter";
import ReadOnly from "../../../../_shared/read-only-notification";
import MissionStatusFilter from "../../../_shared/mission-status-filter";

class HazardTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.renderForm = this.renderForm.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  renderForm() {
    const { editItem, onStopEditing } = this.props;
    return (
      <HazardTableRowForm editItem={editItem} onStopEditing={onStopEditing} />
    );
  }

  renderItem() {
    const { item, editItem, onStartEditing, domainsItemsObject } = this.props;
    const {
      mission_task_id,
      hazard_name,
      initial_risk_id,
      control,
      who,
      how,
      final_risk_id
    } = item;
    const mission_task = domainsItemsObject.hasOwnProperty(mission_task_id)
      ? domainsItemsObject[mission_task_id].val
      : null;
    const initial_risk = domainsItemsObject.hasOwnProperty(initial_risk_id)
      ? domainsItemsObject[initial_risk_id].val
      : null;
    const final_risk = domainsItemsObject.hasOwnProperty(final_risk_id)
      ? domainsItemsObject[final_risk_id].val
      : null;
    return (
      <tr>
        <td>{mission_task}</td>
        <td>{hazard_name}</td>
        <td>{initial_risk}</td>
        <td>{control}</td>
        <td>{how}</td>
        <td>{who}</td>
        <td>{final_risk}</td>
        <td>
          <RoleFilter allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]} alt={ReadOnly}>
            <MissionStatusFilter
              allowStatus={{
                approval_status: ["draft"]
              }}
              alt={ReadOnly}
            >
              <button
                disabled={!!editItem}
                onClick={() => {
                  onStartEditing(item);
                }}
                className="btn btn-sm btn-ghost-primary"
              >
                <i className="mdi mdi-pencil mr-1"></i> Edit
              </button>
            </MissionStatusFilter>
          </RoleFilter>
        </td>
      </tr>
    );
  }

  render() {
    const { item, editItem } = this.props;
    if (item === editItem) {
      return this.renderForm();
    } else {
      return this.renderItem();
    }
  }
}

export default connect(
  "selectDomainsItemsObject",
  HazardTableRow
);
