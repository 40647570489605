import React from "react";

const UsaceApprovedCell = (props) => {
  const { usace_approved } = props.data;
  return (
    <p className={`m-0 text-${usace_approved ? "success" : "danger"}`}>
      {usace_approved ? "YES" : "NO"}
    </p>
  );
};
export default UsaceApprovedCell;
