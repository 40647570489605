import React from "react";
import MaintenanceListItem from "./maintenance-list-item";
import { sortBy } from "lodash";

class MaintenanceList extends React.Component {
  render() {
    const { items, enableEditing } = this.props;
    const sorted = sortBy(items, ["create_date"]);
    if (!sorted.length) {
      return (
        <ul className="list-group">
          <li className="list-group-item">No Maintenance Records...</li>
        </ul>
      );
    } else {
      return (
        <ul className="list-group">
          {sorted.map((item, i) => {
            return (
              <MaintenanceListItem
                item={item}
                key={i}
                enableEditing={enableEditing}
              />
            );
          })}
        </ul>
      );
    }
  }
}

export default MaintenanceList;
