import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import { omit } from "lodash";
import ReactSelectCreatable from "react-select/creatable";
const FileEditDialog = ({ libraryItemsAllCategories, doDialogClose, file, handleTagsChange }) => {
  const [state, setState] = useState({
    ...file,
    selectedCategories: file.categories ? file.categories.split("~,~").map(cat => Object.assign({ label: cat, value: cat })) : [],
    allCategories: libraryItemsAllCategories.map(cat => Object.assign({ label: cat, value: cat }))
  });
  const handleConfirm = async (e) => {
    let saveState = { ...state, categories: state.selectedCategories.map(t=>t.value).join("~,~") };
    saveState = omit(saveState, [
      "tags",
      "onRemove",
      "removeUrl",
      "editing",
      "showDate",
      "_changesMade",
    ]);
    await handleTagsChange({ ...saveState, last_updated_date: new Date() });
    await doDialogClose();
  };
  const onChange = (input) => {
    setState({...state, selectedCategories:input})
  }
  const isValidNewOption = (value) => {
    return value && !value.includes("Read File Docs");
  }
  return (
    <div className="modal-content mx-auto">
      <div className="px-4 py-3">
        <div className="modal-header">
          <h5 className="modal-title">Categories: {file.filename}</h5>
          <button
            className="close"
            type="button"
            aria-label="close"
            onClick={() => {
              doDialogClose();
            }}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body" style={{ overflow: "visible" }}>
          <ReactSelectCreatable isMulti={true} value={state.selectedCategories} options={state.allCategories} onChange={onChange}
            isValidNewOption={isValidNewOption } />
        </div>
        <div className="modal-footer">
          <button
            className="form-control btn btn-sm btn-secondary"
            type="button"
            name="no"
            onClick={() => {
              doDialogClose();
            }}
          >
            Cancel
          </button>
          <button
            className="form-control btn btn-sm btn-primary"
            type="button"
            name="yes"
            onClick={handleConfirm}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
export default connect("doUpdateRelativeUrl", "doDialogClose", "selectLibraryItemsAllCategories", FileEditDialog);
