import React from "react";

function CreateNewGroupListItem({ state, handleNew, dropBoxOnDragOver, dropBoxOnDrop }) {
    const inputPreventDrag = (e) => {
        e.preventDefault();
        e.stopPropagation()
    }
    const dropBoxStyle = {
        height: "25px", opacity: state.dragOccuring ? 1 : 0,
        pointerEvents: "all", border: state.dragOccuring ? "1px dashed blue" : "1px solid blue"
    }

    const createNewItemClicked = () => {
        handleNew(Number(new Date()).toString())
    }


    const onDrop = (e) => {
        dropBoxOnDrop(e, { display_order: -1, category: state.orderedTabNames[state.activeTab] })
    }

    return (
        <div className="container-fluid w-100 h-auto">
            <div className="card row w-auto mx-auto my-0 p-0" style={dropBoxStyle}
                onDragStart={inputPreventDrag}
                onDragOver={dropBoxOnDragOver}
                onDrop={onDrop}
            >
                <div className="mx-auto" style={{ pointerEvents: "none" }}>
                    <strong style={{ pointerEvents: "none" }}>{state.dragOccuring ? "Drop" : "Insert"}</strong>
                    <i className="mdi mdi-plus-circle nav-icon"></i>
                </div>
            </div>

            <div className="card w-100 row mx-auto my-0" style={{ height: "152px", pointerEvents: "all", userSelect: "none" }}>
                <div className="row m-auto" style={{ pointerEvents: "all" }}>
                    <div className="col w-100">
                        <button className="btn btn-primary mr-2" onClick={createNewItemClicked}>
                            <h2 className="text-right m-0" style={{ pointerEvents: "none" }}>New <i className="mdi mdi-plus-circle nav-icon"></i></h2>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )


}


export default CreateNewGroupListItem;




