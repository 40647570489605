import React from "react";
import DocumentationNav from "./documentation-nav";
import DocumentationBody from "./documentation-body";
import "./documentation.css";
const Documentation = () => {
  return (
    <div className="app-body documentation-container">
      <DocumentationNav />
      <DocumentationBody />
    </div>  
  );
}
export default Documentation;
