import createRestBundle from "./create-rest-bundle";
export default createRestBundle({
    name: "messagesRatings",
    uid: "id",
    prefetch: true,
    persist: false,
    routeParam: null,
    getTemplate: "/orgs/:orgSlug/message-board/:messageSlug/ratings",
    putTemplate: "/orgs/:orgSlug/message-board/:messageSlug/ratings/:item.id",
    postTemplate: "/orgs/:orgSlug/message-board/:messageSlug/ratings",
    deleteTemplate: "/orgs/:orgSlug/message-board/:messageSlug/ratings/:item.id",

    fetchActions: ["MESSAGES_FETCH_STARTED", "URL_UPDATED", "MESSAGESRATINGS_SAVE_FINISHED", "MESSAGESRATINGS_DELETE_FINISHED"],

});