import React, { useState, useRef, useEffect } from "react";
import classnames from "classnames";
import { connect } from "redux-bundler-react";
import ReactSelect from "react-select";
import { capitalize, lowerCase, find, snakeCase } from "lodash";
import EquipmentRequestsCardSection from "./equipment-requests-card-section";

const EquipmentRequestsCard = ({
  equipmentRequestItems,
  equipmentRequestQueriedOrg,
  equipmentRequestQueriedType,
  equipmentRequestSelected,
  doEquipmentRequestSave: sendFunc,
  doEquipmentRequestDelete: deleteFunc,
  equipmentRequestFlags,
  equipmentRequestIsLoading,
  doEquipmentRequestUpdateQueryString: doItemsUpdateQueryString,
  equipmentRequestOrgOptions,
  equipmentRequestDeactivationTypes
}) => {
  const typeSelectRef = useRef(null);
  const orgSelectRef = useRef(null);
  const deactivatedActionTypes = equipmentRequestDeactivationTypes.map(
      item => snakeCase(item.val))
  const requestOptions = [
    { label: "All", value: "All" },
    { label: "Aircraft", value: "Aircraft" },
    { label: "GCS", value: "Gcs" },
    { label: "Payload", value: "Payload" },
  ];
  const [state, setState] = useState({
    showAddSection: false,
    showDeactivateSection: false,
    addItems: [],
    deactivateItems: [],
  });
  const itemCardRowLayout = {
    rowCls: "justify-content-start",
    cardInfo: { style: { height: "90px" } },
    titleInfo: { wrapperCls: "col-4 col-sm-3 mt-4" },
    bodyInfo: { wrapperCls: "col-8 col-sm-8 ml-0 py-2" },
    aboutItemInfo: {
      wrapperCls: "col-6 px-0 py-2 ml-auto",
      wrapperStyle: { fontWeight: "lighter", width: "12rem" },
    },
  };
  const itemCardPrependedCol = (item) => {
    const { type, org_name } = item;
    let iconCls = classnames({
      "mdi mdi-airplane": type === "aircraft",
      "mdi mdi-camera": type === "payload",
      "ms ms-drone-remote ml-2 pt-2": type === "gcs",
      "text-primary mx-2": true,
    });

    return (
      <div
        title={capitalize(type)}
        className="col-1 px-0 d-flex flex-column my-auto"
      >
        <h6 className="ml-0 mb-auto mr-auto">{org_name}</h6>
        <span className={iconCls} style={{ fontSize: "24px" }} />
      </div>
    );
  };
  const itemContentDetailsCol = (item) => {
    if (item.action !== "Add") {
      let deactivateText = capitalize(lowerCase(item.action));
      return (
        <h5 className="d-flex col-auto ml-0 mr-auto flex-column justify-content-center flex-shrink-1">
          <span className="badge badge-pill badge-danger">
            {deactivateText}
          </span>
        </h5>
      );
    }
    return null;
  };

  let listProps = {
    itemProps: {
      itemType: "Equipment Request",
      dateAttr: "create_date",
      sendFunc,
      deleteFunc,
      updateUrl: false,
      itemCardRowLayout,
      itemCardPrependedCol,
      itemContentDetailsCol,
      renderName: (item) => (
        <strong className="d-block text-wrap">{item.profile_name}</strong>
      ),
    },
    boardUrl: { params: [] },
    flags: equipmentRequestFlags,
    isLoading: equipmentRequestIsLoading,
  };

  const sectionBtnClicked = (e) => {
    setState({
      ...state,
      [e.currentTarget.name]: !state[e.currentTarget.name],
    });
  };

  const renderCountBadge = (count) => {
    return {
      ":after": {
        display: count > 0 ? "inline-block" : "none",
        fontSize: "100%",
        color: "#fff",
        float: "right",
        borderRadius: "0.25rem",
        lineHeight: 1,
        padding: "0.25em 0.4em",
        backgroundColor: "#007bff",
        content: `"${count}"`,
      },
    };
  };
  const renderControlPanel = () => {
    return (
      <div className="row px-3 my-0">
        <div className="col-2 px-0 mb-2 mr-3">
          <small>Organization</small>
          <ReactSelect
            ref={orgSelectRef}
            options={equipmentRequestOrgOptions}
            styles={{
              option: (styles, { data }) => {
                let orgData = find(equipmentRequestOrgOptions, [
                  "value",
                  data.value,
                ]);
                return {
                  ...styles,
                  ...renderCountBadge(orgData.combined_count),
                };
              },
            }}
            value={equipmentRequestQueriedOrg}
            onChange={(update) => {
              doItemsUpdateQueryString({ _orgId: update.value, _type: "All" });
              if (typeSelectRef.current)
                typeSelectRef.current.setValue({ label: "All", value: "All" });
            }}
          />
        </div>
        <div className="col-2 px-0 px-0 ml-3">
          <small>Type</small>
          <ReactSelect
            ref={typeSelectRef}
            onChange={(update) => {
              doItemsUpdateQueryString({ _type: update.value });
            }}
            options={requestOptions}
            defaultValue={{
              label: capitalize(equipmentRequestQueriedType),
              value: equipmentRequestQueriedType,
            }}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (equipmentRequestItems) {
      let addItems = [];
      let deactivateItems = [];
      equipmentRequestItems.forEach((i) => {
        if (i.action === "Add") addItems.push(i);
        else deactivateItems.push(i);
      });
      setState({
        ...state,
        addItems: addItems,
        deactivateItems: deactivateItems,
        showAddSection: addItems.length > 0,
        showDeactivateSection: deactivateItems.length > 0,
      });
    }
  }, [equipmentRequestItems]);
  const deactivate =
    equipmentRequestSelected &&
    deactivatedActionTypes.includes(equipmentRequestSelected.action);
  const headerIconCls = classnames({
    "mdi mr-2": true,
    "mdi-plus-circle text-success": equipmentRequestSelected && equipmentRequestSelected.action === "Add",
    "mdi-lock-outline text-info": equipmentRequestSelected && equipmentRequestSelected.action === 'Display Only',
    "mdi-delete-outline text-danger": deactivate,

  });
  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-3">
            <span className="mdi mdi-list-status mr-2" />
            Equipment Requests
          </div>

          {equipmentRequestSelected && (
            <div className="col-6 mr-3">
              <h5 className="m-auto text-center">
                  <span className={headerIconCls} />
                <span>{`Request to ${deactivate ? "Deactivate: " : ""
                  } ${capitalize(equipmentRequestSelected.action)} ${capitalize(
                    equipmentRequestSelected.type
                  )}`}</span>
              </h5>
            </div>
          )}
        </div>
      </div>
      <div className="card-body d-flex flex-column">
        {renderControlPanel()}
        <EquipmentRequestsCardSection
          items={state.addItems}
          show={state.showAddSection}
          updateQueryFn={doItemsUpdateQueryString}
          listProps={listProps}
          onSectionClick={sectionBtnClicked}
          name="Add"
          stateAttr="showAddSection"
        />
        <EquipmentRequestsCardSection
          items={state.deactivateItems}
          show={state.showDeactivateSection}
          updateQueryFn={doItemsUpdateQueryString}
          listProps={listProps}
          onSectionClick={sectionBtnClicked}
          name="Deactivate"
          stateAttr="showDeactivateSection"
        />
      </div>
    </div>
  );
};
export default connect(
  "selectEquipmentRequestOrgOptions",
  "selectEquipmentRequestQueriedOrg",
  "selectEquipmentRequestQueriedType",
  "selectEquipmentRequestSelected",
  "doEquipmentRequestSave",
  "doEquipmentRequestDelete",
  "doEquipmentRequestUpdateQueryString",
  "selectEquipmentRequestIsLoading",
  "selectEquipmentRequestFlags",
  "selectEquipmentRequestItems",
  "selectEquipmentRequestDeactivationTypes",
  EquipmentRequestsCard
);
