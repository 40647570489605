import React from "react";
import { connect } from "redux-bundler-react";
import "./inventory.css";
import drone from "./drone.jpg";
import DeleteButton from "../../../_shared/delete-button/delete-button";
import MissionStatusFilter from "../../_shared/mission-status-filter";
import configurationModal from "./modals/configuration-modal";

const ConfigurationTile = ({
  doDialogOpen,
  configuration,
  doMissionsConfigurationsDelete,
  doMissionsConfigurationsFetch,
  configurationId,
  missionsConfigurationIsDeleting,
  missionsConfigurationsTableData,
  index,
  aircraftItemsApproved,
  batteriesItems,
  gcsItemsNotDeactivated: gcsItems,
  payloadsItemsNotDeactivated: payloadsItems,
  mmsItems,
}) => {
  let activeConfigRow =
    missionsConfigurationsTableData.filter((c) => c.id === configurationId) &&
    missionsConfigurationsTableData.filter((c) => c.id === configurationId)[0];
  let adjudicatedItems =
    activeConfigRow && activeConfigRow.adjudicated_ids
      ? activeConfigRow.adjudicated_ids
      : "";

  const EquipmentItem = ({ item }) => {
    let isAdjudicated = adjudicatedItems.split(".").includes(item.equipment_id);
    return (
      <div
        className={`d-flex align-items-center w-100 item-container ${
          isAdjudicated ? "bg-danger" : ""
        }`}
        data-toggle="tooltip"
        data-placement="top"
        title={item.equipment_name}
        key={item.equipment_name}
      >
        <p
          className={isAdjudicated ? "m-0" : "text-muted m-0"}
          style={{ overflowWrap: "anywhere" }}
        >
          {item.equipment_name}
        </p>
      </div>
    );
  };
  return (
    <div className="tile">
      <div
        className="d-flex flex-column w-100 align-items-center h-100"
        style={{ position: "relative" }}
      >
        <div
          style={{
            backgroundImage: configuration["Aircraft"][0].image_url
              ? `url("${configuration["Aircraft"][0].image_url}")`
              : `url(${drone})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: 150,
            marginBottom: 12,
            border: `2px solid gray`,
            borderRadius: 8,
          }}
        ></div>
        <h6
          className="text-muted d-flex justify-content-center align-items-center mb-2"
          style={{ fontSize: 20 }}
        >
          {configuration["Aircraft"][0].equipment_name}
        </h6>
        <div
          className="align-items-center w-100 m-2"
          style={{
            backgroundColor: "white",
            borderRadius: 8,
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            gap: 8,
          }}
        >
          <div className="w-100 d-flex flex-column align-items-center h-100 mx-1">
            <div
              className="d-flex align-items-center justify-content-center mb-2"
              style={{
                height: 36,
                width: 36,
                borderRadius: 18,
                backgroundColor: "#63c2de38",
              }}
            >
              <i className="mdi mdi-18px mdi-car-battery text-info" />
            </div>
            {configuration["Batteries"] &&
              configuration["Batteries"].length > 0 &&
              configuration["Batteries"].map((item, idx) => (
                <EquipmentItem
                  item={item}
                  key={`${idx}_${index}_${item.equipment_id}`}
                />
              ))}
          </div>
          <div className="w-100 d-flex flex-column align-items-center h-100 mx-1">
            <div
              className="d-flex align-items-center justify-content-center mb-2"
              style={{
                height: 36,
                width: 36,
                borderRadius: 18,
                backgroundColor: "#63c2de38",
              }}
            >
              <i className="mdi mdi-18px mdi-camera-outline text-info" />
            </div>
            {configuration["Payloads"] &&
              configuration["Payloads"].length > 0 &&
              configuration["Payloads"].map((item, idx) => (
                <EquipmentItem
                  item={item}
                  key={`${idx}_${index}_${item.equipment_id}`}
                />
              ))}
          </div>
          <div className="w-100 d-flex flex-column align-items-center h-100  mx-1">
            <div
              className="d-flex align-items-center justify-content-center mb-2"
              style={{
                height: 36,
                width: 36,
                borderRadius: 18,
                backgroundColor: "#63c2de38",
              }}
            >
              <i className="ms mdi-18px ms-drone-remote text-info" />
            </div>
            {configuration["GCS"] &&
              configuration["GCS"].length > 0 &&
              configuration["GCS"].map((item, idx) => (
                <EquipmentItem
                  item={item}
                  key={`${idx}_${index}_${item.equipment_id}`}
                />
              ))}
          </div>
          <div className="w-100 d-flex flex-column align-items-center h-100 mx-1">
            <div
              className="d-flex align-items-center justify-content-center mb-2"
              style={{
                height: 36,
                width: 36,
                borderRadius: 18,
                backgroundColor: "#63c2de38",
              }}
            >
              <i className="mdi mdi-18px mdi-laptop text-info" />
            </div>
            {configuration["MMS"] &&
              configuration["MMS"].length > 0 &&
              configuration["MMS"].map((item, idx) => (
                <EquipmentItem
                  item={item}
                  key={`${idx}_${index}_${item.equipment_id}`}
                />
              ))}
          </div>
        </div>
        <MissionStatusFilter
          allowStatus={{
            approval_status: ["draft"],
          }}
          alt={null}
        >
          <div
            className="d-flex w-100 align-items-center justify-content-end"
            style={{ position: "relative", bottom: 0, right: 0 }}
          >
            <div className="d-flex btn-group">
              <button
                className="btn btn-sm btn-outline-info"
                onClick={() => {
                  doDialogOpen({
                    content: configurationModal,
                    props: {
                      aircraftItems: aircraftItemsApproved,
                      batteriesItems,
                      gcsItems,
                      payloadsItems,
                      mmsItems,
                      configuration,
                      configurationId,
                    },
                    size: "xl",
                  });
                }}
              >
                <i className="mdi mdi-pencil mdi-14px mr-1" />
                Edit
              </button>
              <DeleteButton
                isLoading={missionsConfigurationIsDeleting}
                onDelete={() =>
                  doMissionsConfigurationsDelete(
                    configurationId,
                    doMissionsConfigurationsFetch
                  )
                }
              />
            </div>
          </div>
        </MissionStatusFilter>
      </div>
    </div>
  );
};

export default connect(
  "doDialogOpen",
  "selectPublicFolder",
  "doMissionsConfigurationsDelete",
  "doMissionsConfigurationsFetch",
  "selectMissionsConfigurationIsDeleting",
  "selectMissionsConfigurationsTableData",
  "selectAircraftItemsApproved",
  "selectBatteriesItems",
  "selectGcsItemsNotDeactivated",
  "selectPayloadsItemsNotDeactivated",
  "selectMmsItems",
  ConfigurationTile
);
