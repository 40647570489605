import React from "react";
import HazardTable from "./hazard-table/hazard-table";

export default () => {
  return (
    <div>
      <h5>Risk Assessment Worksheet</h5>
      <div className="alert alert-info" style={{ whiteSpace: "pre-wrap" }}>
        {`Use the risk assessment matrix below to evaluate each hazard associated with this mission.
Add any additional hazards that are specific to this mission and remove any that are redundant or do not apply.`}
      </div>
      <HazardTable />
    </div>
  );
};
