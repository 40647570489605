import { createSelector } from "redux-bundler";

export default {
  name: "approvalRolesPersonnel",

  getReducer: () => {
    const initialData = {
      _orgSlug: null,
      _shouldFetch: false,
      _isLoading: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "APPROVAL_ROLES_PERSONNEL_FETCH_STARTED":
        case "APPROVAL_ROLES_PERSONNEL_ERROR":
        case "APPROVAL_ROLES_PERSONNEL_UPDATED_ORG":
          return Object.assign({}, state, payload);
        case "APPROVAL_ROLES_PERSONNEL_FETCH_FINISHED":
          return Object.assign({}, payload);
        case "URL_UPDATED":
          return Object.assign({}, initialData);
        default:
          return state;
      }
    };
  },

  doApprovalRolesPersonnelFetch:
    () =>
    ({ dispatch, store, apiGet }) => {
      dispatch({
        type: "APPROVAL_ROLES_PERSONNEL_FETCH_STARTED",
        payload: {
          _shouldFetch: false,
          _isLoading: true,
        },
      });

      const orgSlug = store.selectApprovalRolesPersonnelOrgSlug();
      if (!orgSlug) {
        const flags = store.selectApprovalRolesPersonnelFlags();
        dispatch({
          type: "APPROVAL_ROLES_PERSONNEL_FETCH_FINISHED",
          payload: Object.assign(flags, {
            _isLoading: false,
          }),
        });
      } else {
        const url = `/orgs/${orgSlug}/personnel`;
        apiGet(url, (err, response, body) => {
          if (err || response.statusCode !== 200) {
            dispatch({
              type: "APPROVAL_ROLES_PERSONNEL_ERROR",
              payload: {
                _isLoading: false,
                _err: response,
              },
            });
          } else {
            const flags = store.selectApprovalRolesPersonnelFlags();
            const data = JSON.parse(body);
            const itemsById = {};
            data.forEach((item) => {
              if (item.parent_id && !item.hidden) itemsById[item.id] = item;
            });
            dispatch({
              type: "APPROVAL_ROLES_PERSONNEL_FETCH_FINISHED",
              payload: Object.assign(itemsById, flags, {
                _isLoading: false,
              }),
            });
          }
        });
      }
    },

  doApprovalRolesPersonnelSetOrgSlug:
    (orgSlug) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "APPROVAL_ROLES_PERSONNEL_UPDATED_ORG",
        payload: {
          _orgSlug: orgSlug,
          _shouldFetch: true,
        },
      });
    },

  selectApprovalRolesPersonnelState: (state) => {
    return state.approvalRolesPersonnel;
  },

  selectApprovalRolesPersonnelFlags: createSelector(
    "selectApprovalRolesPersonnelState",
    (state) => {
      const flags = {};
      Object.keys(state).forEach((key) => {
        if (key[0] === "_") flags[key] = state[key];
      });
      return flags;
    }
  ),

  selectApprovalRolesPersonnelItemsObject: createSelector(
    "selectApprovalRolesPersonnelState",
    (state) => {
      const items = {};
      Object.keys(state).forEach((key) => {
        if (key[0] !== "_") items[key] = state[key];
      });
      return items;
    }
  ),

  selectApprovalRolesPersonnelItemsArray: createSelector(
    "selectApprovalRolesPersonnelState",
    (state) => {
      const items = [];
      Object.keys(state).forEach((key) => {
        if (key[0] !== "_") items.push(state[key]);
      });
      return items;
    }
  ),

  selectApprovalRolesPersonnelItems: createSelector(
    "selectApprovalRolesPersonnelItemsArray",
    (items) => {
      return items;
    }
  ),

  selectApprovalRolesPersonnelOrgSlug: (state) => {
    return state.approvalRolesPersonnel._orgSlug;
  },

  selectApprovalRolesPersonnelIsLoading: (state) => {
    return state.approvalRolesPersonnel._isLoading;
  },

  reactApprovalRolesPersonnelShouldFetch: (state) => {
    if (state.approvalRolesPersonnel._shouldFetch)
      return { actionCreator: "doApprovalRolesPersonnelFetch" };
  },
};
