import React from "react";
import { connect } from "redux-bundler-react";

const TransferLinkRenderer = (props) => {
  let item = props.data;
  return (
    <a
      className="link"
      href={`/${props.orgsByRoute.slug}/transfers/${item.slug}`}
    >
      {`${item.from_org} -> ${item.to_org}`}
      {item.type === "Temporary" ? (
        <i className="mdi mdi-alpha-t-circle-outline pl-1"></i>
      ) : null}
    </a>
  );
};

export default connect("selectOrgsByRoute", TransferLinkRenderer);
