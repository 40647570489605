import React from "react";
import { connect } from "redux-bundler-react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

class CopyMissionDialog extends React.Component {
  constructor(props) {
    super(props);
    const { missionsByRoute: mission } = this.props;
    this.state = Object.assign({}, mission);
    this.handleChange = this.handleChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.save = this.save.bind(this);
  }

  save() {
    const {
      doMissionsCopy,
      doUpdateRelativeUrl,
      doDialogClose,
      orgsByRoute: org,
    } = this.props;
    doMissionsCopy(
      this.state,
      () => {
        /**
         * There's a weird race condition where the
         * fetch triggered by "URL_UPDATED" is overriding the
         * _forceFetch set by "MISSIONS_COPY_FINISHED" in
         * the missions-bundle, using setTimeout let's the fetch
         * start without getting aborted so when we redirect to the planner
         * the new mission is there.
         **/
        window.setTimeout(() => {
          doDialogClose();
          doUpdateRelativeUrl(`/${org.slug}/planner`);
        }, 100);
      },
      true
    );
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleStartDateChange(e) {
    this.setState({
      date_start: e.toJSON(),
    });
  }

  handleEndDateChange(e) {
    this.setState({
      date_end: e.toJSON(),
    });
  }

  render() {
    const { doDialogClose, missionsIsSaving: saving } = this.props;
    const { name, date_start, date_end } = this.state;

    const selectedStart = date_start ? new Date(date_start) : null;
    const selectedEnd = date_end ? new Date(date_end) : null;
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Copy Mission</h5>
          <button
            onClick={doDialogClose}
            className="close"
            type="button"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="modal-body">
          <p>
            Verify the location name and mission dates that you want to use:
          </p>

          <div className="form-group">
            <label className="form-label">Location</label>
            <input
              className="form-control"
              type="text"
              name="name"
              value={name}
              onChange={this.handleChange}
              autoComplete="off"
            />
          </div>

          <div className="form-group">
            <label className="form-label">Start Date</label>
            <div>
              <DatePicker
                onChange={this.handleStartDateChange}
                selected={selectedStart}
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-label">End Date</label>
            <div>
              <DatePicker
                onChange={this.handleEndDateChange}
                selected={selectedEnd}
                className="form-control"
              />
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button
            onClick={doDialogClose}
            className="btn btn-sm btn-secondary"
            disabled={saving}
            type="button"
          >
            Cancel
          </button>
          <button
            onClick={this.save}
            className="btn btn-sm btn-success"
            disabled={saving}
            type="button"
          >
            Copy
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  "selectMissionsByRoute",
  "selectOrgsByRoute",
  "selectMissionsIsSaving",
  "doMissionsCopy",
  "doDialogClose",
  "doUpdateRelativeUrl",
  CopyMissionDialog
);
