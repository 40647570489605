import React from "react";
import { connect } from "redux-bundler-react";
import Grid from "../../_shared/grid";
import { keyBy, sortBy } from "lodash";
export default connect(
  "selectPersonnelReportNotHidden",
  "selectPersonnelReportItems",
  "selectPersonnelReportIsLoading",
  "selectDomainsItemsByGroup",
  ({
    personnelReportNotHidden: data,
    personnelReportIsLoading,
    domainsItemsByGroup,
  }) => {
    const duties = keyBy(domainsItemsByGroup["duties"], "id");
    const approvalRoleStyles = {
      "Aircrew Training Program Manager": {
        abbr: "ATPM",
        badgeColorCls: "badge-teal",
      },
      "Backup ATPM": {
        abbr: "BATPM",
        badgeColorCls: "badge-orange",
      },
      "Mission Briefing Officer": {
        abbr: "MBO",
        badgeColorCls: "badge-indigo",
      },
      Commander: {
        abbr: "CDR",
        badgeColorCls: "badge-purple",
      },
      Directory: {
        abbr: "DIR",
        badgeColorCls: "badge-info",
      },
    };
    const dutiesStyles = {
      RP: "badge-indigo",
      RPI: "badge-teal",
      SRP: "badge-cyan",
    };
    const config = {
      columnDefs: [
        {
          resizable: true,
          sortable: true,
          headerName: "Org",
          field: "org_name",
        },
        { resizable: true, sortable: true, headerName: "Name", field: "name" },
        {
          resizable: true,
          sortable: true,
          headerName: "E-Mail",
          field: "email",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Phone",
          field: "phone",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Part 107 Expiration",
          field: "part_107_expiration",
          valueFormatter: (cell) => {
            if (!cell.value) return null;
            const d = new Date(cell.value);
            return d.toLocaleDateString();
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Approval Roles",
          field: "approval_roles",
          cellRenderer: (params) => {
            if (params.data.approval_roles) {
              return (
                <>
                  {params.data.approval_roles.map((role, key) => {
                    let style = approvalRoleStyles[role];
                    if (!style) return <span />;
                    return (
                      <span
                        key={key}
                        className={`badge badge-pill ${style.badgeColorCls} ml-2`}
                      >
                        {style.abbr}
                      </span>
                    );
                  })}
                </>
              );
            }
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Flight Roles",
          field: "flight_roles",
          cellRenderer: (params) => {
            let flightRoleObjs = [];
            if (params.data.flight_roles) {
              let idsSplit = params.data.flight_roles.split(".");
              idsSplit.forEach((id) => {
                if (duties[id]) flightRoleObjs.push(duties[id]);
              });
              flightRoleObjs = sortBy(flightRoleObjs, (obj) =>
                parseInt(obj.display_order)
              );
            }
            return flightRoleObjs.map((obj, key) => {
              let badgeColorCls = dutiesStyles[obj.val];
              return (
                <span
                  key={key}
                  className={`badge badge-pill ${badgeColorCls} ml-2`}
                >
                  {obj.val}
                </span>
              );
            });
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Flights as Pilot",
          field: "flight_count_pilot",
          valueGetter: (params) =>
            params.data.flight_count_pilot &&
            parseInt(params.data.flight_count_pilot),
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Last Flight as Pilot",
          field: "last_time_pilot",
          valueFormatter: (cell) => {
            if (!cell.value) return null;
            const d = new Date(cell.value);
            return d.toLocaleDateString();
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Flights as VO",
          field: "flight_count_vo",
          valueGetter: (params) =>
            params.data.flight_count_vo &&
            parseInt(params.data.flight_count_vo),
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Last Flight as VO",
          field: "last_time_vo",
          valueFormatter: (cell) => {
            if (!cell.value) return null;
            const d = new Date(cell.value);
            return d.toLocaleDateString();
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Flight Min. as Pilot",
          field: "flight_time_pilot",
          valueGetter: (params) =>
            params.data.flight_time_pilot &&
            parseFloat(params.data.flight_time_pilot).toFixed(2),
          comparator: (valueA, valueB) => valueA - valueB,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Flight Min. as VO",
          field: "flight_time_vo",
          valueGetter: (params) =>
            params.data.flight_time_vo &&
            parseFloat(params.data.flight_time_vo).toFixed(2),
          comparator: (valueA, valueB) => valueA - valueB,
        },
      ],
    };
    return (
      <Grid
        isLoading={personnelReportIsLoading}
        config={config}
        data={data}
        style={{ height: `${window.innerHeight * 0.8}px`, width: "100%" }}
      />
    );
  }
);
