import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "../../../../../utils/classnames";
import DamageForm from "./damage-form";

class DamageTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleSelect(item) {
    // super hacky way to clear the form when changing items, seemed easier than editing the schema form at the time.
    this.setState(
      {
        selectedItem: null
      },
      () => {
        this.setState({
          selectedItem: item
        });
      }
    );
  }

  handleSave(item) {
    const {
      doMissionsFlightsAircraftSave,
      doMissionsFlightsBatteriesSave,
      doMissionsFlightsGcsSave,
      doMissionsFlightsMmsSave,
      doMissionsFlightsPayloadsSave
    } = this.props;
    let func = null;
    if (item.type === "aircraft") {
      func = doMissionsFlightsAircraftSave;
    } else if (item.type === "gcs") {
      func = doMissionsFlightsGcsSave;
    } else if (item.type === "mms") {
      func = doMissionsFlightsMmsSave;
    } else if (item.type === "battery") {
      func = doMissionsFlightsBatteriesSave;
    } else {
      func = doMissionsFlightsPayloadsSave;
    }
    if (func)
      func(
        item,
        () => {
          this.handleSelect(item);
        },
        true
      );
  }

  renderForm() {
    const {
      missionsFlightsAircraftIsSaving,
      missionsFlightsGcsIsSaving,
      missionsFlightsMmsIsSaving,
      missionsFlightsBatteriesIsSaving,
      missionsFlightsPayloadsIsSaving
    } = this.props;
    const { selectedItem } = this.state;

    if (!selectedItem) {
      return (
        <div>
          <small className="text-muted">Select an item to the left</small>
        </div>
      );
    } else {
      let isSaving = false;
      if (selectedItem.type === "aircraft") {
        isSaving = missionsFlightsAircraftIsSaving;
      } else if (selectedItem.type === "gcs") {
        isSaving = missionsFlightsGcsIsSaving;
      } else if (selectedItem.type === "mms") {
        isSaving = missionsFlightsMmsIsSaving;
      } else if (selectedItem.type === "battery") {
        isSaving = missionsFlightsBatteriesIsSaving;
      } else {
        isSaving = missionsFlightsPayloadsIsSaving;
      }
      return (
        <DamageForm
          item={selectedItem}
          onSave={this.handleSave}
          isSaving={isSaving}
        />
      );
    }
  }

  render() {
    const { selectedItem } = this.state;
    const {
      missionsFlightsAircraftItems: aircraft,
      missionsFlightsGcsItems: gcs,
      missionsFlightsMmsItems: mms,
      missionsFlightsBatteriesItems: batteries,
      missionsFlightsPayloadsItems: payloads
    } = this.props;

    // not really elegant, but hey, it works
    const items = [
      ...aircraft.map(i => {
        i.type = "aircraft";
        return i;
      }),
      ...gcs.map(i => {
        i.type = "gcs";
        return i;
      }),
      ...mms.map(i => {
        i.type = "mms";
        return i;
      }),
      ...batteries.map(i => {
        i.type = "battery";
        return i;
      }),
      ...payloads.map(i => {
        i.type = "payload";
        return i;
      })
    ];

    return (
      <div className="row">
        <div className="col-sm-3">
          <ul className="list-group">
            {items.map((item, i) => {
              return (
                <li
                  key={i}
                  className={classnames({
                    "list-group-item": true,
                    "list-group-item-action": true,
                    "float-right": true,
                    active: item === selectedItem
                  })}
                  onClick={() => {
                    this.handleSelect(item);
                  }}
                >
                  <i
                    className={classnames({
                      "pr-2": true,
                      ms: item.type === "gcs" || item.type === "mms",
                      "ms-drone-remote": item.type === "gcs",
                      "ms-laptop": item.type === "mms",
                      mdi: item.type !== "gcs" && item.type !== "mms",
                      "mdi-camera": item.type === "payload",
                      "mdi-car-battery": item.type === "battery",
                      "mdi-airplane": item.type === "aircraft"
                    })}
                  ></i>
                  {item.name}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="col-sm-9">{this.renderForm()}</div>
      </div>
    );
  }
}

export default connect(
  "selectMissionsFlightsAircraftItems",
  "selectMissionsFlightsGcsItems",
  "selectMissionsFlightsMmsItems",
  "selectMissionsFlightsBatteriesItems",
  "selectMissionsFlightsPayloadsItems",
  "selectMissionsFlightsAircraftIsSaving",
  "selectMissionsFlightsGcsIsSaving",
  "selectMissionsFlightsMmsIsSaving",
  "selectMissionsFlightsBatteriesIsSaving",
  "selectMissionsFlightsPayloadsIsSaving",
  "doMissionsFlightsAircraftSave",
  "doMissionsFlightsBatteriesSave",
  "doMissionsFlightsGcsSave",
  "doMissionsFlightsMmsSave",
  "doMissionsFlightsPayloadsSave",
  DamageTab
);
