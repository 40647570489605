import { get, transform, fromLonLat } from "ol/proj";
import { createSelector } from "redux-bundler";
import { createOlMap } from "../utils/map-utils";
import { debounce } from "lodash";

const defaultViewState = {
  center: [-98.579, 39.828],
  zoom: 8,
  rotation: 0,
  minZoom: 0,
  maxZoom: 22,
};

export default {
  name: "map",

  getReducer: () => {
    const initialState = {
      map: null,
      geoProjection: get("EPSG:4326"),
      webProjection: get("EPSG:3857"),
      center: defaultViewState.center,
      zoom: defaultViewState.zoom,
      rotation: defaultViewState.rotation,
      minZoom: defaultViewState.minZoom,
      maxZoom: defaultViewState.maxZoom,

      geocodeData: undefined,

      shouldInitialize: false,
      shouldResetViewState: false,
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case "MAP_":
        case "MAP_INITIALIZED":
        case "MAP_UPDATED_VIEW_STATE":
        case "REVERSE_GEOCODE_FETCH_START":
        case "REVERSE_GEOCODE_FETCH_SUCCESS":
        case "REVERSE_GEOCODE_FETCH_ERROR":
          return Object.assign({}, state, payload);
        case "URL_UPDATED":
          return Object.assign({}, state, { shouldResetViewState: true });
        default:
          return state;
      }
    };
  },

  doMapInitialize:
    (options) =>
    ({ dispatch, store }) => {
      const geoProjection = store.selectMapGeoProjection();
      const webProjection = store.selectMapWebProjection();
      const map = createOlMap(options, geoProjection, webProjection);
      map.on("moveend", debounce(store.doMapUpdateViewState, 200));
      dispatch({ type: "MAP_INITIALIZED", payload: { map: map } });
    },

  doMapUpdateViewState:
    () =>
    ({ dispatch, store }) => {
      const epsg4326 = store.selectMapGeoProjection();
      const epsg3857 = store.selectMapWebProjection();
      const map = store.selectMap();
      const view = map.getView();
      const center = transform(view.getCenter(), epsg3857, epsg4326);
      const rotation = view.getRotation();
      const zoom = view.getZoom();
      const minZoom = view.getMinZoom();
      const maxZoom = view.getMaxZoom();
      dispatch({
        type: "MAP_UPDATED_VIEW_STATE",
        payload: {
          center: center,
          rotation: rotation,
          zoom: zoom,
          minZoom: minZoom,
          maxZoom: maxZoom,
        },
      });
    },

  doMapResetViewState:
    () =>
    ({ dispatch, store }) => {
      const { center, zoom, rotation, minZoom, maxZoom } = defaultViewState;
      dispatch({
        type: "MAP_UPDATED_VIEW_STATE",
        payload: {
          shouldResetViewState: false,
          center: center,
          rotation: rotation,
          zoom: zoom,
          minZoom: minZoom,
          maxZoom: maxZoom,
        },
      });
    },

  doMapZoomTo:
    (lonLat) =>
    ({ store }) => {
      const map = store.selectMap();
      const view = map.getView();
      view.animate({
        zoom: 10,
        center: fromLonLat(lonLat),
      });
    },

  doFetchReverseGeocode:
    (lat, long) =>
    ({ dispatch, store, apiGet }) => {
      dispatch({
        type: "REVERSE_GEOCODE_FETCH_START",
        payload: {
          _isLoading: true,
        },
      });
      let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/{longitude},{latitude}.json?access_token=${
        import.meta.env.VITE_MAPBOX_TOKEN
      }`;
      let formattedUrl = url.replace(/{longitude}/gi, long);
      formattedUrl = formattedUrl.replace(/{latitude}/gi, lat);
      apiGet(formattedUrl, (err, response, body) => {
        // test for error and do something
        if (err || response.statusCode !== 200) {
          dispatch({
            type: "REVERSE_GEOCODE_FETCH_ERROR",
            payload: {
              _isLoading: false,
              _err: response,
            },
          });
        } else {
          const data = JSON.parse(body);
          dispatch({
            type: "REVERSE_GEOCODE_FETCH_SUCCESS",
            payload: {
              _isLoading: false,
              geocodeData: data,
            },
          });
        }
      });
    },

  selectMap: (state) => {
    return state.map.map;
  },

  selectMapGeoProjection: (state) => {
    return state.map.geoProjection;
  },

  selectMapWebProjection: (state) => {
    return state.map.webProjection;
  },

  selectMapCenter: (state) => {
    return state.map.center;
  },

  selectMapZoom: (state) => {
    return state.map.zoom;
  },

  selectMapRotation: (state) => {
    return state.map.rotation;
  },

  selectMapMinZoom: (state) => {
    return state.map.minZoom;
  },

  selectMapMaxZoom: (state) => {
    return state.map.maxZoom;
  },

  selectReverseGeocodeData: (state) => {
    return state.map.geocodeData;
  },

  selectMapInitializeProps: createSelector(
    "selectMapCenter",
    "selectMapZoom",
    "selectMapRotation",
    "selectMapMinZoom",
    "selectMapMaxZoom",
    (center, zoom, rotation, minZoom, maxZoom) => {
      return {
        center: center,
        zoom: zoom,
        rotation: rotation,
        minZoom: minZoom,
        maxZoom: maxZoom,
      };
    }
  ),

  reactMapShouldResetViewState: (state) => {
    if (state.map.shouldResetViewState)
      return { actionCreator: "doMapResetViewState" };
  },
};
