export default {
  name: "dialog",

  getReducer: () => {
    const initialData = {
      content: null,
      props: null,
      size: null,

      nestedContent: null,
      nestedProps: null,
      prevContent: null,
      prevProps: null,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "DIALOG_OPENED":
        case "DIALOG_CLOSED":
        case "NESTED_DIALOG_OPENED":
        case "NESTED_DIALOG_CLOSED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doDialogOpen:
    ({ content, props, size }) =>
    ({ dispatch }) => {
      document.body.style.overflow = "hidden";
      dispatch({
        type: "DIALOG_OPENED",
        payload: {
          content: content,
          props: props,
          size: size,
        },
      });
    },

  doNestedDialogOpen:
    ({ content, props, size }) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "NESTED_DIALOG_OPENED",
        payload: {
          nestedContent: content,
          nestedProps: props,
          prevContent: store.selectDialogContent(),
          prevProps: store.selectDialogProps(),
          size,
          content: null,
          props: null,
        },
      });
    },

  doNestedDialogClose:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "NESTED_DIALOG_CLOSED",
        payload: {
          nestedContent: null,
          nestedProps: null,
          prevContent: null,
          prevProps: null,
          content: store.selectDialogPrevContent(),
          props: store.selectDialogPrevProps(),
        },
      });
    },

  doDialogClose:
    (cb) =>
    ({ dispatch, store }) => {
      document.body.style.overflow = "auto";
      dispatch({
        type: "DIALOG_CLOSED",
        payload: {
          content: null,
          props: null,
          size: null,
          prevContent: null,
          prevProps: null,
        },
      });
      cb && typeof cb === "function" && cb();
    },

  selectDialogContent: (state) => {
    return state.dialog.content;
  },

  selectDialogProps: (state) => {
    return state.dialog.props;
  },

  selectDialogSize: (state) => {
    return state.dialog.size;
  },

  selectNestedDialogContent: (state) => {
    return state.dialog.nestedContent;
  },

  selectNestedDialogProps: (state) => {
    return state.dialog.nestedProps;
  },
  selectDialogPrevContent: (state) => state.dialog.prevContent,
  selectDialogPrevProps: (state) => state.dialog.prevProps,
};
