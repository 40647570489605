import React from "react";

export default ({ value, onChange, className }) => {
  return (
    <div className={`mb-3 ${className}`} style={{ width: "100%" }}>
      <input
        className="form-control"
        type="text"
        onChange={onChange}
        value={value}
        placeholder="Filter Items..."
      ></input>
    </div>
  );
};
