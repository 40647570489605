import classNames from "classnames";
import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import LoadingList from "./loading-list";

const AcceptDropdown = ({
  doAccessRequestAccept,
  appRolesItemsFiltered,
  user,
}) => {
  const [open, setOpen] = useState(false);

  const dropdownCls = classNames({
    btn: true,
    "btn-primary": true,
    "dropdown-toggle": true,
    show: open,
  });

  const dropdownMenuCls = classNames({
    "dropdown-menu": true,
    show: open,
  });

  const handleAssign = (e, user) => {
    doAccessRequestAccept(user, e.target.dataset.role);
  };

  return (
    <div className="btn-group pr-2">
      <button
        type="button"
        className={dropdownCls}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded={open}
        onClick={() => setOpen(!open)}
      >
        Accept
      </button>
      <div className={dropdownMenuCls}>
        {appRolesItemsFiltered.map((role, i) => {
          return (
            <button
              onClick={(e) => handleAssign(e, user)}
              className="dropdown-item"
              data-role={role.id}
            >
              {`Add User as ${role.roleName}`}
            </button>
          );
        })}
      </div>
    </div>
  );
};

const RequestsCard = ({
  accessRequestItems: requests,
  doUpdateRelativeUrl,
  orgsByRoute,
  doAccessRequestAccept,
  doAccessRequestDelete,
  appRolesItemsFiltered,
  isLoading,
}) => {
  const handleReject = (user) => {
    doAccessRequestDelete(user);
  };

  return (
    <div className="currency border-requests">
      <div className="custom-card-title requests">
        <h5 className="text-black m-0">Access Requests</h5>
        <button
          className="btn btn-sm btn-ghost-dark"
          onClick={() => {
            doUpdateRelativeUrl(`/${orgsByRoute.slug}/users`);
          }}
        >
          <i className="mdi mdi-account-multiple icon-inline mdi-18px"></i>
          Go to Members
        </button>
      </div>
      <div className="currency-body">
        {isLoading ? (
          <div className="mt-2">
            <LoadingList />
          </div>
        ) : requests.length > 0 ? (
          <ul className="list-group mt-2">
            {requests.map((user, i) => {
              return (
                <div
                  className="d-flex w-100 border p-2 m-2 flex-column rounded"
                  key={i}
                >
                  <p className="m-0 h6 pb-1 mb-2 border-bottom">
                    {user.username}
                  </p>
                  <p className="m-0 h6 pb-2 text-muted">
                    <small>
                      Request Date: {user.create_date.split("T")[0]}
                    </small>
                  </p>
                  <div className="d-flex">
                    <AcceptDropdown
                      doAccessRequestAccept={doAccessRequestAccept}
                      appRolesItemsFiltered={appRolesItemsFiltered}
                      user={user}
                    />
                    <button
                      className="btn btn-danger"
                      onClick={() => handleReject(user)}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              );
            })}
          </ul>
        ) : (
          <div className="d-flex flex-column h-100 align-items-center justify-content-center">
            <p className="text-muted h5 text-center">No new access requests!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(
  "selectOrgsByRoute",
  "doUpdateRelativeUrl",
  "selectAccessRequestItems",
  "doAccessRequestAccept",
  "doAccessRequestDelete",
  "selectAppRolesItemsFiltered",
  RequestsCard
);
