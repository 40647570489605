import React, { useReducer } from "react";
import { connect } from "redux-bundler-react";
import HelpConfirm from "./help-confirm";

import { allFields, formTemplates } from "./help-form-data";

import SelectInput from "../../app-components/schema-form/select-input";

const Help = ({ doDialogOpen, doHelpSave, doDialogClose }) => {
  const initState = {
    subject: "",
    body: "",
    readyToSubmit: true,
    requestType: "*",
    formFields: [],
    formAnswers: { ...formTemplates["*"].defaultAnswers },
    focusedDropdown: null,
  };

  const getFieldsForType = (type) => {
    let filteredKeys = Object.keys(allFields).filter((key) => {
      if (allFields[key].forTypes == "*") return 1;
      else {
        let intendedTypes = allFields[key].forTypes.split(",");
        return intendedTypes.includes(type);
      }
    });

    return Object.fromEntries(filteredKeys.map((key) => [key, allFields[key]]));
  };
  const formReducer = (state, action) => {
    switch (action.type) {
      case "HANDLE_TEXTBOX_CHANGE":
        return {
          ...state,
          readyToSubmit: state.subject.length == 0 || state.body.length == 0,
          [action.field]: action.payload,
        };
      case "HANDLE_REQUEST_TYPE_CHANGE":
        // Replace the old 'request-type' specific fields with the new 'request-type', but also retain the answers for the global fields
        let globalFieldKeys = Object.keys(formTemplates["*"].defaultAnswers);
        // Answers for the global fields are kept when changing the request type
        let retainedGlobalAnswers = Object.fromEntries(
          globalFieldKeys.map((key) => [
            key,
            state.formAnswers[key.toLowerCase()],
          ])
        );
        return {
          ...state,
          requestType: action.payload,
          formFields: getFieldsForType(action.payload.toLowerCase()),
          focusedDropdown: null,
          formAnswers: {
            ...retainedGlobalAnswers,
            ...formTemplates[action.payload.toLowerCase()].defaultAnswers,
          },
        };

      case "HANDLE_FIELD_CHANGE":
        return {
          ...state,
          readyToSubmit:
            document.querySelector(".form-control.is-invalid") != null,
          focusedDropdown: null,
          formAnswers: { ...state.formAnswers, [action.field]: action.payload },
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(formReducer, initState);

  const formatMessageBody = () => {
    const { body, requestType, formAnswers } = state;

    const requestTypeTags = {
      "*": `<div style="background:white; color:black; width:fit-content; border-radius:5px; padding:5px"><strong>Not Specified</strong></div>`,
      recommendation: `<div style="background:green; width:fit-content; border-radius:5px; padding:5px"><strong>Recommendation</strong></div>`,
      issue: `<div style="background:maroon; width:fit-content; border-radius:5px; padding:5px"><strong>Issue</strong></div>`,
      question: `<div style="background:navy; width:fit-content; border-radius:5px; padding:5px"><strong>Question</strong></div>`,
    };

    // Check if any further information was provided
    let furtherInfoProvided = false;
    Object.values(formAnswers).forEach((a) => {
      if (a != "notspecific") furtherInfoProvided = true;
    });

    // Function to build the table if further information is provided
    const buildFurtherInfoTable = () => {
      let answerKeys = Object.keys(formAnswers);
      return `<table><tbody>${answerKeys
        .map((key) => {
          let subPropLabel = allFields[key].label;
          if (formAnswers[key] != "notspecific") {
            return `<tr><td>${subPropLabel}</td><td>${formAnswers[key]}</td></tr>`;
          }
        })
        .join("")}</tbody></table><br><br>`;
    };

    // String segments to be inserted as Markdown
    let messageSegments = [
      `<table><tbody><tr><td>Request Type</td><td>${
        requestTypeTags[requestType.toLowerCase()]
      }</td></tr></tbody></table><br>`,
      furtherInfoProvided
        ? buildFurtherInfoTable()
        : `<strong>(No further information was provided)</strong><br><br>`,
      `<div style="outline:solid;min-height:30vh;display:block;position:relative">${body}</div>`,
    ];

    // Finally, join string segments of Markdown
    let formattedMessage = messageSegments.join("");

    return formattedMessage;
  };

  const submit = () => {
    const { subject, requestType, formAnswers, routeInfo } = state;
    const formattedBody = formatMessageBody();
    const savedStates = {
      subject,
      body: formattedBody,
      requestType,
      formAnswers,
    };

    doHelpSave({ ...routeInfo, ...savedStates });
    doDialogOpen({
      content: HelpConfirm,
      props: {},
    });
  };

  // Handles Subject and Body inputs fields
  const handleTextboxChange = (e) => {
    dispatch({
      type: "HANDLE_TEXTBOX_CHANGE",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  // Handles each dropdown field's change of selected item; when an option is selected from a field's dropdown
  const handleFieldChange = (e) => {
    dispatch({
      type: "HANDLE_FIELD_CHANGE",
      field: e.property,
      payload: e.value,
    });
  };

  // Handles the request-type dropdown field's change of selected item
  const handleRequestTypeChange = (e) => {
    if (!e.value) return;
    dispatch({
      type: "HANDLE_REQUEST_TYPE_CHANGE",
      field: e.property,
      payload: e.value,
    });
  };

  // Renders the fields specific to the request type
  const renderTypeSpecificFields = () => {
    let typeSpecificFieldKeys = Object.keys(state.formFields).filter((key) => {
      return state.formFields[key].forTypes != "*";
    });
    let typeSpecificFields = Object.fromEntries(
      typeSpecificFieldKeys.map((key) => [key, state.formFields[key]])
    );
    if (state.requestType) {
      return Object.keys(typeSpecificFields).map((item, idx) => {
        return (
          <div className="form-group col-sm-3 mb-3">
            <SelectInput
              onChange={handleFieldChange}
              property={typeSpecificFields[item].name}
              className="form-group d-inline col-sm-3 m-3"
              options={typeSpecificFields[item].options}
              schema={{
                title: typeSpecificFields[item].label,
                readOnly: false,
                displayOnly: false,
              }}
            />
          </div>
        );
      });
    } else return null;
  };

  // Renders the fields that are global to all request types
  const renderGlobalFields = () => {
    let globalFieldKeys = Object.keys(state.formFields).filter((key) => {
      return state.formFields[key].forTypes == "*";
    });
    let globalFields = Object.fromEntries(
      globalFieldKeys.map((key) => [key, state.formFields[key]])
    );
    if (state.requestType) {
      return Object.keys(globalFields).map((item, idx) => {
        return (
          <div className="form-group col-sm-3 mb-3">
            <SelectInput
              onChange={handleFieldChange}
              property={globalFields[item].name}
              className="form-group d-inline col-sm-3 m-3"
              options={globalFields[item].options}
              schema={{
                title: globalFields[item].label,
                readOnly: false,
                displayOnly: false,
              }}
            />
          </div>
        );
      });
    } else return null;
  };

  return (
    <div className="modal-content mx-auto">
      <div className="modal-header">
        <h5 className="modal-title">Request Assistance</h5>
        <button
          className="close"
          type="button"
          aria-label="Close"
          onClick={doDialogClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body" style={{ overflow: "visible" }}>
        <p>
          Hello, if you have any questions, issues, or recommendations
          pertaining to MARS, please let us know. We'll get back to you as soon
          as possible!
        </p>

        <div className="alert alert-warning" style={{ whiteSpace: "pre-wrap" }}>
          If you are needing assistance planning a specific mission, click the
          "Mission Planning Help" button on your mission's page instead.
        </div>

        <form>
          <fieldset>
            <div className="container d-block mx-auto">
              <div className="form-group row mb-3">
                <div className="form-group col-sm-3 mb-3">
                  <SelectInput
                    options={Object.values(formTemplates)}
                    property="requestType"
                    schema={{ title: "Specify Type of Request" }}
                    onChange={handleRequestTypeChange}
                  />
                </div>
                {renderTypeSpecificFields()}
              </div>

              <div className="form-group row mb-3">{renderGlobalFields()}</div>
              <hr />
            </div>

            <div className="form-group row  mb-3">
              <label className="col-sm-3 col-form-label text-right">
                Subject
              </label>
              <div className="col-sm-9">
                <input
                  value={state.subject}
                  name="subject"
                  onChange={handleTextboxChange}
                  type="text"
                  className={`form-control ${
                    state.subject == "" ? "is-invalid" : ""
                  }`}
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="form-group row  mb-3">
              <label className="col-sm-3 col-form-label text-right">
                Message
              </label>
              <div className="col-sm-9">
                <textarea
                  value={state.body}
                  name="body"
                  onChange={handleTextboxChange}
                  type="text"
                  className={`form-control ${
                    state.body == "" ? "is-invalid" : ""
                  }`}
                  rows="7"
                  autoComplete="off"
                />
              </div>
            </div>
          </fieldset>
        </form>
      </div>

      <div className="modal-footer">
        <button
          onClick={doDialogClose}
          className="btn btn-sm btn-secondary"
          type="button"
        >
          Close
        </button>
        <button
          disabled={state.readyToSubmit}
          onClick={submit}
          className="btn btn-sm btn-success"
          type="button"
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default connect(
  "selectRouteInfo",
  "doHelpSave",
  "doDialogOpen",
  "doDialogClose",
  Help
);
