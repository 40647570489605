import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import MissionCardRegion from "../_shared/mission-card-region";

const Debrief = (props) => {
  const { missionsItems: items, missionsIsLoading, doMissionsFetch } = props;
  useEffect(() => {
    doMissionsFetch();
  }, []);
  const filtered = items.filter((item) => {
    return (
      ["pending", "in progress"].indexOf(item.debrief_status) !== -1 &&
      item.approval_status === "approved" &&
      item.schedule_status !== "pending" &&
      item.archived === 0
    );
  });
  return (
    <div className="container-fluid mt-4">
      <MissionCardRegion
        isLoading={missionsIsLoading}
        items={filtered}
        allowAdd={false}
      />
    </div>
  );
};

export default connect(
  "selectMissionsItems",
  "selectMissionsIsLoading",
  "doMissionsFetch",
  Debrief
);
