import React from "react";
import { connect } from "redux-bundler-react";
import { isEmpty } from "lodash";
import { personnel } from "../../models";
import Loader from "../../app-components/loader";
import DeleteButton from "../_shared/delete-button/delete-button";
import SchemaForm from "../../app-components/schema-form/schema-form";
import MissionsCard from "../_shared/missions-card/missions-card";
import TrainingCard from "./training-card/training-card";
import ApprovalsCard from "./approval-roles-card/approval-roles-card";
import FileUploadCard from "../_shared/file-upload-card/file-upload-card";
import RoleFilter from "../../app-containers/context-providers/role-filter";
import ReadOnly from "../_shared/read-only-notification";
import NotesCard from "../_shared/notes-card/note-card";

class PersonnelDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.save = this.save.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.enableEditing = this.enableEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.renderEditButton = this.renderEditButton.bind(this);
  }

  save() {
    const { doPersonnelSave } = this.props;
    if (this.form.isValid()) {
      const data = this.form.serialize();
      this.setState(
        {
          editing: false,
        },
        () => {
          doPersonnelSave(data);
        }
      );
    }
  }

  enableEditing() {
    this.setState({
      editing: true,
    });
  }

  cancelEditing() {
    const { personnelByRoute } = this.props;
    this.setState(
      {
        editing: false,
      },
      () => {
        this.form.reset({ data: personnelByRoute, schema: personnel });
      }
    );
  }

  handleDelete() {
    const {
      doPersonnelDelete,
      doUpdateRelativeUrl,
      routeParams,
      personnelByRoute,
    } = this.props;
    this.setState(
      {
        editing: false,
      },
      () => {
        doPersonnelDelete(personnelByRoute, () => {
          doUpdateRelativeUrl(`/${routeParams.orgSlug}/personnel`);
        });
      }
    );
  }

  renderForm() {
    const { editing } = this.state;
    const { personnelByRoute } = this.props;
    if (isEmpty(personnelByRoute)) return <Loader />;
    return (
      <SchemaForm
        ref={(el) => {
          this.form = el;
        }}
        inline={true}
        displayOnly={!editing}
        schema={personnel}
        data={personnelByRoute}
      />
    );
  }

  renderActions() {
    const { editing } = this.state;
    if (!editing) return null;
    return (
      <div>
        <div className="float-right">
          <button
            onClick={this.cancelEditing}
            className="btn btn-sm btn-secondary mr-2"
          >
            Cancel
          </button>
          <button onClick={this.save} className="btn btn-sm btn-success">
            Save
          </button>
        </div>
        <DeleteButton onDelete={this.handleDelete} />
      </div>
    );
  }

  renderEditButton() {
    const { personnelByRoute } = this.props;
    const { editing } = this.state;
    if (personnelByRoute.parent_id) {
      return (
        <div title="Editing disabled when Connected to a User Profile">
          <i className="mdi mdi-account-switch text-success"></i>
        </div>
      );
    } else {
      return (
        <RoleFilter allowRoles={[":ORG.ADMIN"]} alt={ReadOnly}>
          <button
            disabled={editing}
            className="btn btn-sm btn-ghost-primary"
            onClick={this.enableEditing}
          >
            <i className="mdi mdi-pencil icon-inline"></i>
            Edit
          </button>
        </RoleFilter>
      );
    }
  }

  render() {
    const {
      personnelByRoute,
      personnelDocsItems,
      personnelDocsIsLoading,
      personnelMissionsItems,
      personnelMissionsIsLoading,
      personnelTrainingItems,
      personnelTrainingIsLoading,
      personnelRolesItems,
      personnelRolesIsLoading,
      doFileIoUpload,
      doFileIoDelete,
    } = this.props;
    return (
      <div className="container mt-3">
        <div className="row">
          <div className="col-sm-7">
            <div className="card">
              <div className="card-header">
                <div className="float-right">{this.renderEditButton()}</div>
                <i className="mdi mdi-account-multiple-outline"></i> Personnel
                Details
              </div>
              <div className="card-body">
                {this.renderForm()}
                {this.renderActions()}
              </div>
            </div>
          </div>
          <div className="col-sm-5">
            <TrainingCard
              isLoading={personnelTrainingIsLoading}
              items={personnelTrainingItems}
            />
            <ApprovalsCard
              isLoading={personnelRolesIsLoading}
              items={personnelRolesItems}
            />
            <FileUploadCard
              allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
              isLoading={personnelDocsIsLoading}
              items={personnelDocsItems}
              header={true}
              searchable={false}
              onUpload={doFileIoUpload}
              onRemove={doFileIoDelete}
              rel="personnel_docs"
              data={{
                personnel_id: personnelByRoute ? personnelByRoute.id : null,
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <MissionsCard
              isLoading={personnelMissionsIsLoading}
              items={personnelMissionsItems}
            />
            <NotesCard />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "doFileIoUpload",
  "doFileIoDelete",
  "doPersonnelSave",
  "doPersonnelDelete",
  "doPersonnelPlatformsSave",
  "doPersonnelPlatformsDelete",
  "selectRouteParams",
  "selectPersonnelByRoute",
  "selectPersonnelDocsItems",
  "selectPersonnelDocsIsLoading",
  "selectPersonnelMissionsItems",
  "selectPersonnelMissionsIsLoading",
  "selectPersonnelTrainingItems",
  "selectPersonnelTrainingIsLoading",
  "selectPersonnelRolesItems",
  "selectPersonnelRolesIsLoading",
  "selectPathname",
  PersonnelDetails
);
