import React from "react";
import SoftwareListItem from "./software-list-item";
import Loader from "../../../app-components/loader";

export default ({ items, editing, isLoading }) => {
  if (isLoading) return <Loader />;
  if (!items.length) {
    return (
      <ul className="list-group">
        <li className="list-group-item">No Associated Software...</li>
      </ul>
    );
  } else {
    return (
      <ul className="list-group">
        {items.map((item, i) => {
          return <SoftwareListItem item={item} editing={editing} key={i} />;
        })}
      </ul>
    );
  }
};
