import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";
import DailyRawPicker from "./daily-raw-picker";
import MissionStatusFilter from "../../_shared/mission-status-filter";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const config = {
  hazards: [
    {
      name: "rest_rpi",
      title: "Crew Rest - RPI",
      helpText: null,
      options: [
        {
          label: `Rested < 5 hours (NO-GO)`,
          value: 99,
        },
        {
          label: `Rested 5 - 6 hours (2)`,
          value: 2,
        },
        {
          label: `Rested 7 - 8 hours (1)`,
          value: 1,
        },
        {
          label: `Rested > 8 hours (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "rest_vo",
      title: "Crew Rest - VO",
      helpText: null,
      options: [
        {
          label: `Rested < 5 hours (NO-GO)`,
          value: 99,
        },
        {
          label: `Rested 5 - 6 hours (2)`,
          value: 2,
        },
        {
          label: `Rested 7 - 8 hours (1)`,
          value: 1,
        },
        {
          label: `Rested > 8 hours (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "experience_rpi",
      title: "Crew Proficiency - RPI",
      helpText: "Days since last flight",
      options: [
        {
          label: `> 60 days (4)`,
          value: 4,
        },
        {
          label: `45 - 60 days (3)`,
          value: 3,
        },
        {
          label: `30 - 45 days (2)`,
          value: 2,
        },
        {
          label: `< 30 days (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "experience_vo",
      title: "Crew Proficiency - VO",
      helpText: "Days since last flight",
      options: [
        {
          label: `> 60 days (3)`,
          value: 3,
        },
        {
          label: `45 - 60 days (2)`,
          value: 2,
        },
        {
          label: `30 - 45 days (1)`,
          value: 1,
        },
        {
          label: `< 30 days (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "duty_day_rpi",
      title: "Duty Day - RPI",
      helpText: "Includes anticipated mission time",
      options: [
        {
          label: `> 12 hours (NO-GO)`,
          value: 99,
        },
        {
          label: `8 - 12 hours (4)`,
          value: 4,
        },
        {
          label: `4 - 8 hours (1)`,
          value: 1,
        },
        {
          label: `< 4 hours (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "duty_day_vo",
      title: "Duty Day - VO",
      helpText: "Includes anticipated mission time",
      options: [
        {
          label: `> 12 hours (NO-GO)`,
          value: 99,
        },
        {
          label: `8 - 12 hours (4)`,
          value: 4,
        },
        {
          label: `4 - 8 hours (1)`,
          value: 1,
        },
        {
          label: `< 4 hours (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "planning",
      title: "Mission Planning",
      helpText: "Notification to Execution",
      options: [
        {
          label: `< 4 hours (6)`,
          value: 6,
        },
        {
          label: `4 - 8 hours (4)`,
          value: 4,
        },
        {
          label: `8 - 12 hours (3)`,
          value: 3,
        },
        {
          label: `> 12 hours (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "visibility",
      title: "Ceiling and Visibility",
      helpText: null,
      options: [
        {
          label: `< 3 miles vis or < 1,000' ceiling (NO-GO)`,
          value: 99,
        },
        {
          label: `3 - 5 miles vis or 1,000' - 1,500' ceiling (2)`,
          value: 2,
        },
        {
          label: `5 - 7 miles vis or 1,500' - 2,000' ceiling (1)`,
          value: 1,
        },
        {
          label: `> 7 miles vis or > 2,000' ceiling (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "winds",
      title: "Sustained Winds",
      helpText: null,
      options: [
        {
          label: `> UA Limit (NO-GO)`,
          value: 99,
        },
        {
          label: `> 20 knots (5)`,
          value: 5,
        },
        {
          label: `10 - 20 knots (2)`,
          value: 2,
        },
        {
          label: `< 10 knots (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "gusts",
      title: "Wind Gusts",
      helpText: "Above sustained wind speed",
      options: [
        {
          label: `> UA Limit (NO-GO)`,
          value: 99,
        },
        {
          label: `> 15 knots (5)`,
          value: 5,
        },
        {
          label: `5 - 15 knots (4)`,
          value: 4,
        },
        {
          label: `< 5 knots (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "command_rel",
      title: "Command Relationship",
      helpText: null,
      options: [
        {
          label: `Attached / Direct Support (3)`,
          value: 3,
        },
        {
          label: `Assigned / Organic (1)`,
          value: 1,
        },
      ],
    },
    {
      name: "crew_rel",
      title: "Crew Relationship",
      helpText: null,
      options: [
        {
          label: `From Different Organization (3)`,
          value: 3,
        },
        {
          label: `From Same Organization (1)`,
          value: 1,
        },
      ],
    },
    {
      name: "qual_training",
      title: "Qualification Training",
      helpText: null,
      options: [
        {
          label: `Yes (2)`,
          value: 2,
        },
        {
          label: `No (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "contin_training",
      title: "Continuation Training / Evaluation",
      helpText: null,
      options: [
        {
          label: `Yes (1)`,
          value: 1,
        },
        {
          label: `No (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "steady_state",
      title: "Steady-State / Continuous Mission",
      helpText: null,
      options: [
        {
          label: `Yes (1)`,
          value: 1,
        },
        {
          label: `No (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "direct_support",
      title: "Direct Support for 1st Responders",
      helpText: null,
      options: [
        {
          label: `Yes (2)`,
          value: 2,
        },
        {
          label: `No (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "new_software_payload",
      title: "New Operating Software / Payload",
      helpText: null,
      options: [
        {
          label: `Yes (1)`,
          value: 1,
        },
        {
          label: `No (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "close_to_airport",
      title: "Within 2 miles of Airfield or Helipad",
      helpText: null,
      options: [
        {
          label: `Yes (3)`,
          value: 3,
        },
        {
          label: `No (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "from_moving_vehicle",
      title: "Launch / Recovery from Moving Vehicle",
      helpText: null,
      options: [
        {
          label: `Yes (3)`,
          value: 3,
        },
        {
          label: `No (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "civil_twilight",
      title: "During Civil Twilight",
      helpText: null,
      options: [
        {
          label: `Yes (1)`,
          value: 1,
        },
        {
          label: `No (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "close_to_pop_area",
      title: "Within 1/4 mile of Populated Area",
      helpText: null,
      options: [
        {
          label: `Yes (2)`,
          value: 2,
        },
        {
          label: `No (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "close_to_mountains",
      title: "Within 1/4 mile of Mountains",
      helpText: null,
      options: [
        {
          label: `Yes (2)`,
          value: 2,
        },
        {
          label: `No (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "night",
      title: "Night",
      helpText: null,
      options: [
        {
          label: `Yes (3)`,
          value: 3,
        },
        {
          label: `No (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "bvlos",
      title: "Beyond Visual Line of Sight (BVLOS)",
      helpText: null,
      options: [
        {
          label: `Yes (3)`,
          value: 3,
        },
        {
          label: `No (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "rescue_recovery_disaster",
      title: "Airspace for Rescue / Recovery / Disaster",
      helpText: null,
      options: [
        {
          label: `Yes (4)`,
          value: 4,
        },
        {
          label: `No (0)`,
          value: 0,
        },
      ],
    },
    {
      name: "weather_unavailable",
      title: "Real-time Weather Brief Unavailable",
      helpText: null,
      options: [
        {
          label: `Yes (1)`,
          value: 1,
        },
        {
          label: `No (0)`,
          value: 0,
        },
      ],
    },
  ],
};

class DailyRaw extends React.Component {
  constructor(props) {
    super(props);
    const { missionsDailyRawItemsObject, rawId } = props;

    let item = {};
    if (rawId) item = missionsDailyRawItemsObject[rawId];

    this.state = {
      id: item && item.hasOwnProperty("id") ? item.id : null,
      date:
        item && item.hasOwnProperty("date") ? new Date(item.date) : new Date(),
      rest_rpi: item && item.hasOwnProperty("rest_rpi") ? item.rest_rpi : 100,
      rest_vo: item && item.hasOwnProperty("rest_vo") ? item.rest_vo : 100,
      experience_rpi:
        item && item.hasOwnProperty("experience_rpi")
          ? item.experience_rpi
          : 100,
      experience_vo:
        item && item.hasOwnProperty("experience_vo") ? item.experience_vo : 100,
      duty_day_rpi:
        item && item.hasOwnProperty("duty_day_rpi") ? item.duty_day_rpi : 100,
      duty_day_vo:
        item && item.hasOwnProperty("duty_day_vo") ? item.duty_day_vo : 100,
      planning: item && item.hasOwnProperty("planning") ? item.planning : 100,
      visibility:
        item && item.hasOwnProperty("visibility") ? item.visibility : 100,
      winds: item && item.hasOwnProperty("winds") ? item.winds : 100,
      gusts: item && item.hasOwnProperty("gusts") ? item.gusts : 100,
      command_rel:
        item && item.hasOwnProperty("command_rel") ? item.command_rel : 100,
      crew_rel: item && item.hasOwnProperty("crew_rel") ? item.crew_rel : 100,
      qual_training:
        item && item.hasOwnProperty("qual_training") ? item.qual_training : 100,
      contin_training:
        item && item.hasOwnProperty("contin_training")
          ? item.contin_training
          : 100,
      steady_state:
        item && item.hasOwnProperty("steady_state") ? item.steady_state : 100,
      direct_support:
        item && item.hasOwnProperty("direct_support")
          ? item.direct_support
          : 100,
      new_software_payload:
        item && item.hasOwnProperty("new_software_payload")
          ? item.new_software_payload
          : 100,
      close_to_airport:
        item && item.hasOwnProperty("close_to_airport")
          ? item.close_to_airport
          : 100,
      from_moving_vehicle:
        item && item.hasOwnProperty("from_moving_vehicle")
          ? item.from_moving_vehicle
          : 100,
      civil_twilight:
        item && item.hasOwnProperty("civil_twilight")
          ? item.civil_twilight
          : 100,
      close_to_pop_area:
        item && item.hasOwnProperty("close_to_pop_area")
          ? item.close_to_pop_area
          : 100,
      close_to_mountains:
        item && item.hasOwnProperty("close_to_mountains")
          ? item.close_to_mountains
          : 100,
      night: item && item.hasOwnProperty("night") ? item.night : 100,
      bvlos: item && item.hasOwnProperty("bvlos") ? item.bvlos : 100,
      rescue_recovery_disaster:
        item && item.hasOwnProperty("rescue_recovery_disaster")
          ? item.rescue_recovery_disaster
          : 100,
      weather_unavailable:
        item && item.hasOwnProperty("weather_unavailable")
          ? item.weather_unavailable
          : 100,
      go_no_go: item && item.hasOwnProperty("go_no_go") ? item.go_no_go : false,
      mitigations:
        item && item.hasOwnProperty("mitigations") ? item.mitigations : "",
      editable: !rawId,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleScoreChange = this.handleScoreChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCheckedChange = this.handleCheckedChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.save = this.save.bind(this);
    this.enableEditing = this.enableEditing.bind(this);
    this.delete = this.delete.bind(this);
    this.renderDelete = this.renderDelete.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  enableEditing() {
    this.setState({
      editable: true,
    });
  }

  save() {
    const {
      doMissionsDailyRawSave,
      doUpdateInnerRoute,
      doMissionsDailyRawFetch,
    } = this.props;
    doMissionsDailyRawSave(this.state, () => {
      doMissionsDailyRawFetch();
      doUpdateInnerRoute("/");
    });
  }

  cancel() {
    const { doUpdateInnerRoute } = this.props;
    doUpdateInnerRoute("/");
  }

  delete() {
    const { doMissionsDailyRawDelete, doUpdateInnerRoute } = this.props;
    const raw = {};
    Object.keys(this.state).forEach((key) => {
      if (key[0] !== "_") raw[key] = this.state[key];
    });
    doUpdateInnerRoute("/");
    doMissionsDailyRawDelete(raw);
  }

  handleChange(e) {
    const target = e.currentTarget;
    this.setState({
      [target.name]: target.value,
    });
  }

  handleCheckedChange(e) {
    const target = e.currentTarget;
    this.setState({
      [target.name]: target.checked,
    });
  }

  handleScoreChange(e) {
    const target = e.currentTarget;
    this.setState({
      [target.name]: Number(target.value),
    });
  }

  handleDateChange(e) {
    this.setState({
      date: e,
    });
  }

  renderDelete() {
    const { id } = this.state;
    if (!id) return null;
    return (
      <button onClick={this.delete} className="btn btn-sm btn-danger">
        Delete
      </button>
    );
  }

  renderActions() {
    const { editable } = this.state;
    const score = Object.values(this.state).reduce((p, c, i) => {
      if (typeof c === "number") return p + c;
      return p;
    }, 0);
    if (editable) {
      return (
        <div>
          <div className="float-right">
            <button
              onClick={this.cancel}
              className="btn btn-sm btn-secondary mr-2">
              Cancel
            </button>
            <button
              disabled={score > 100}
              onClick={this.save}
              className="btn btn-sm btn-success">
              Save
            </button>
          </div>
          {this.renderDelete()}
        </div>
      );
    } else {
      return (
        <div className="float-right">
          <button onClick={this.cancel} className="btn btn-sm btn-secondary">
            Back
          </button>
        </div>
      );
    }
  }

  render() {
    const { missionsByRoute } = this.props;
    const { date, mitigations, go_no_go, editable } = this.state;
    const score = Object.values(this.state).reduce((p, c, i) => {
      if (typeof c === "number") return p + c;
      return p;
    }, 0);
    const scoreMsgClass = classnames({
      "text-danger": score > 31,
      "text-warning": 31 >= score && score > 22,
      "text-success": 22 >= score,
    });
    const scoreMsg =
      score > 100
        ? "Missing at least one selection above"
        : score > 40
        ? "NO-GO"
        : score;
    return (
      <div className="row">
        <div className="col-xl-9 col-lg-12">
          <div className="float-right">
            <MissionStatusFilter
              denyStatus={{
                mission_status: ["complete", "archived"],
              }}>
              <button
                disabled={editable}
                className="btn btn-sm btn-ghost-primary"
                onClick={this.enableEditing}>
                <i className="mdi mdi-pencil icon-inline"></i>
                Edit
              </button>
            </MissionStatusFilter>
          </div>
          <div>
            <div className="form-horizontal">
              <div className="form-group row">
                <label className="col-md-3 col-form-label text-right">
                  Date
                </label>
                <div className="col-md-9">
                  <DatePicker
                    className="form-control"
                    data-key="date"
                    onChange={this.handleDateChange}
                    selected={date}
                    minDate={new Date(missionsByRoute.date_start)}
                    maxDate={new Date(missionsByRoute.date_end)}
                    todayButton={"Today"}
                    disabled={!editable}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">Hazard</div>
                <div className="col-md-9">Score</div>
              </div>
              <hr />

              {config.hazards.map((haz, i) => {
                return (
                  <DailyRawPicker
                    key={i}
                    name={haz.name}
                    title={haz.title}
                    value={this.state[haz.name]}
                    helpText={haz.helpText}
                    options={haz.options}
                    onChange={this.handleScoreChange}
                    disabled={!editable}
                  />
                );
              })}

              <div className="form-group row">
                <label className="col-md-3 col-form-label text-right">
                  Total Score
                </label>
                <div className="col-md-9">
                  <div>
                    <strong className={scoreMsgClass}>{scoreMsg}</strong>
                  </div>
                  <span className="help-block">
                    <small>
                      <div className="text-success">{`0-22 Low   `}</div>
                      <div className="text-warning">{`22-31 Medium   `}</div>
                      <div className="text-danger">{`>31 High, Change Conditions & Heighten Vigilance / >40 NO-GO`}</div>
                    </small>
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-md-3 col-form-label text-right">
                  Go / No-Go
                </label>
                <div className="col-md-9">
                  <label className="switch switch-label switch-pill switch-success">
                    <input
                      name="go_no_go"
                      checked={go_no_go}
                      onChange={this.handleCheckedChange}
                      readOnly={false}
                      disabled={score >= 40 || !editable}
                      type="checkbox"
                      className="switch-input"
                    />
                    <span
                      className="switch-slider"
                      data-checked="✓"
                      data-unchecked="✕"></span>
                  </label>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-md-3 col-form-label text-right">
                  Mitigation Description
                </label>
                <div className="col-md-9">
                  <textarea
                    onChange={this.handleChange}
                    value={this.state.mitigations}
                    className="form-control"
                    name="mitigations"
                    type="textarea"
                    rows="4"
                    disabled={!editable}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-9">{this.renderActions()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "selectMissionsDailyRawItemsObject",
  "selectMissionsByRoute",
  "doMissionsDailyRawSave",
  "doMissionsDailyRawDelete",
  "doMissionsDailyRawFetch",
  DailyRaw
);
