import React from "react";
import { connect } from "redux-bundler-react";
import { conforms, isEmpty, constant } from "lodash";
import JobNewForm from "./job-new-form";

export const defaultJobParams = {
  _changesMade: false,
  slug: null,
  last_comment_date: null,
  last_updated_by: null,
  last_updated_date: null,
  last_updated_by_name: null,
  published: null,
  published_date: null,
  create_date: null,
  created_by_name: null,
  created_by: null,
  date_end: null,
  date_start: null,
  id: undefined,
  sectionsReady: {
    general: null,
    pocs: null,
  },
  title: "",
  body: "",
  datesTentative: false,
  startDate: "",
  endDate: "",
  tags: [],
  addr: "",
  notes: "",
  requirements: "",
  poc: null,
  alt_poc: null,
  poc_name: "",
  poc_email: "",
  poc_phone: "",
  alt_poc_name: "",
  alt_poc_email: "",
  alt_poc_phone: "",
};

const JobNew = ({ doJobsNewFormStateUpdate, doUpdateRelativeUrl }) => {
  doJobsNewFormStateUpdate(defaultJobParams);
  const toBoardBtnClicked = async (e) => {
    await doUpdateRelativeUrl("/job-board");
  };

  const renderNavBtns = () => {
    return (
      <div className="py-2 d-flex border-bottom">
        <div className="mt-auto flex-fill mr-auto">
          <button
            className="btn btn-ghost-primary"
            type="button"
            onClick={toBoardBtnClicked}
          >
            <span className="mdi mdi-arrow-up-left mr-2" />
            Return to Job Board
          </button>
        </div>
      </div>
    );
  };

  let newFormActions = [
    {
      text: "Save as Draft",
      className: "btn btn-primary mr-2",
      name: "draft",
      disabled: [[conforms({ title: isEmpty }), constant(true)]],
    },
    {
      text: "Post",
      className: "btn btn-success ml-2",
      name: "publish",
      disabled: [
        [
          conforms({
            sectionsReady: (item) => Object.values(item).some((val) => !val),
          }),
          constant(true),
        ],
      ],
    },
  ];

  return (
    <div
      className="container-fluid"
      style={{ marginTop: "55px", height: "100%" }}
    >
      <div className="d-flex flex-column w-100">
        {renderNavBtns()}
        <JobNewForm title="Create Job posting" actions={newFormActions} />
      </div>
    </div>
  );
};

export default connect(
  "doJobsNewFormStateUpdate",
  "doUpdateRelativeUrl",
  JobNew
);
