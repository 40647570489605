import React from "react";

export default () => {
  const fills = {
    low: {
      backgroundColor: "#e4e7ea",
      textAlign: "center",
      fontSize: "1.2rem",
      fontWeight: "bold"
    },
    med: {
      backgroundColor: "#c8ced3",
      textAlign: "center",
      fontSize: "1.2rem",
      fontWeight: "bold"
    },
    high: {
      backgroundColor: "#acb4bc",
      color: "#f0f3f5",
      textAlign: "center",
      fontSize: "1.2rem",
      fontWeight: "bold"
    },
    exHigh: {
      backgroundColor: "#8f9ba6",
      color: "#f0f3f5",
      textAlign: "center",
      fontSize: "1.2rem",
      fontWeight: "bold"
    }
  };
  return (
    <div>
      <h5>Risk Assessment Matrix</h5>
      <table className="table table-bordered" style={{ fontSize: "0.8rem" }}>
        <tbody>
          <tr>
            <td colSpan="2"></td>
            <td colSpan="5" style={{ textAlign: "center" }}>
              <strong>Probability</strong> (expected frequency)
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <strong></strong>
            </td>
            <td>
              <strong>Frequent:</strong> Continuous, regular or inevitable
              occurences
            </td>
            <td>
              <strong>Likely:</strong> Several or numerous occurences
            </td>
            <td>
              <strong>Occasional:</strong> Sporadic or intermittent occurences
            </td>
            <td>
              <strong>Seldom:</strong> Infrequent occurences
            </td>
            <td>
              <strong>Unlikely:</strong> Possible occurences but improbable
            </td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <td colSpan="2">
              <strong>Severity</strong> (expected consequence)
            </td>
            <td>A</td>
            <td>B</td>
            <td>C</td>
            <td>D</td>
            <td>E</td>
          </tr>
          <tr>
            <td>
              <strong>Catastrophic:</strong> Death, unacceptable loss or damage,
              mission failure, or unit readiness eliminated
            </td>
            <td>I</td>
            <td style={fills.exHigh}>EH</td>
            <td style={fills.exHigh}>EH</td>
            <td style={fills.high}>H</td>
            <td style={fills.high}>H</td>
            <td style={fills.med}>M</td>
          </tr>
          <tr>
            <td>
              <strong>Critical:</strong> Severe injury, illness, loss, or
              damage; significantly degraded unit readiness or mission
              capability
            </td>
            <td>II</td>
            <td style={fills.exHigh}>EH</td>
            <td style={fills.high}>H</td>
            <td style={fills.high}>H</td>
            <td style={fills.med}>M</td>
            <td style={fills.low}>L</td>
          </tr>
          <tr>
            <td>
              <strong>Moderate:</strong> Minor injury, illness, loss, or damage;
              somewhat degraded unit readiness or mission capability
            </td>
            <td>III</td>
            <td style={fills.high}>H</td>
            <td style={fills.med}>M</td>
            <td style={fills.med}>M</td>
            <td style={fills.low}>L</td>
            <td style={fills.low}>L</td>
          </tr>
          <tr>
            <td>
              <strong>Negligible:</strong> Minimal injury, loss, or damage;
              little or no impact to unit readiness or mission capability
            </td>
            <td>IV</td>
            <td style={fills.med}>M</td>
            <td style={fills.low}>L</td>
            <td style={fills.low}>L</td>
            <td style={fills.low}>L</td>
            <td style={fills.low}>L</td>
          </tr>
          <tr>
            <td colSpan="7">
              <strong>Legend:</strong>
              <span className="ml-2">
                <strong>EH</strong> - extremely high risk
              </span>
              <span className="ml-4">
                <strong>H</strong> - high risk
              </span>
              <span className="ml-4">
                <strong>M</strong> - medium risk
              </span>
              <span className="ml-4">
                <strong>L</strong> - low risk
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
