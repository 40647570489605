import React from "react";
import { connect } from "redux-bundler-react";
import { find, isEmpty } from "lodash";
import { aircraft, aircraftHq } from "../../models";
import Loader from "../../app-components/loader";
import RoleFilter from "../../app-containers/context-providers/role-filter";
import OrgFilter from "../../app-containers/context-providers/org-filter";
import ReadOnly from "../_shared/read-only-notification";
import DeleteButton from "../_shared/delete-button/delete-button";
import SchemaForm from "../../app-components/schema-form/schema-form";
import MaintenanceCard from "./maintenance-card/maintenance-card";
import MissionsCard from "../_shared/missions-card/missions-card";
import FileUploadCard from "../_shared/file-upload-card/file-upload-card";
// import TagCard from '../_shared/tag-card/tag-card';
import NotesCard from "../_shared/notes-card/note-card";
import ApprovedComponentsCard from "../_shared/approved-components-card";
import aircraftDeactivationDialog from "./aircraft-deactivation-dialog";


class AircraftDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.save = this.save.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.enableEditing = this.enableEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.openDeactivationDialog = this.openDeactivationDialog.bind(this);
  }

  save() {
    const { doAircraftSave, aircraftByRoute } = this.props;
    if (this.form.isValid()) {
      const data = this.form.serialize();
      if (data.purchase_date === "") data.purchase_date = null;
      if (data.received_at_hq === "") data.received_at_hq = null;
      const item = Object.assign({}, aircraftByRoute, data);
      this.setState(
        {
          editing: false,
        },
        () => {
          doAircraftSave(item);
        }
      );
    }
  }

  enableEditing() {
    this.setState({
      editing: true,
    });
  }

  cancelEditing() {
    const { aircraftByRoute } = this.props;
    this.setState(
      {
        editing: false,
      },
      () => {
        this.form.reset({ data: aircraftByRoute, schema: aircraft });
      }
    );
  }

  openDeactivationDialog() {
    const { doDialogOpen } = this.props;
    doDialogOpen({
      content: aircraftDeactivationDialog,
      props: this.props,
      size: "xl",
    });
    this.cancelEditing();
  }

  handleDelete() {
    const {
      doAircraftDelete,
      doUpdateRelativeUrl,
      routeParams,
      aircraftByRoute,
    } = this.props;
    this.setState(
      {
        editing: false,
      },
      () => {
        doAircraftDelete(aircraftByRoute, () => {
          doUpdateRelativeUrl(`/${routeParams.orgSlug}/aircraft`);
        });
      }
    );
  }

  renderForm() {
    const { editing } = this.state;
    const { aircraftByRoute, tokenGroups } = this.props;
    let hqMember = tokenGroups.indexOf("HQ") !== -1;
    if (isEmpty(aircraftByRoute)) return <Loader />;
    return (
      <SchemaForm
        ref={(el) => {
          this.form = el;
        }}
        inline={true}
        displayOnly={!editing}
        schema={hqMember ? aircraftHq : aircraft}
        data={aircraftByRoute}
      />
    );
  }

  renderActions() {
    const { editing } = this.state;
    const { equipmentRequestItems, aircraftByRoute } = this.props;
    if (!editing) return null;

    return (
      <div>
        <div className="float-left">
          <button className="btn btn-sm btn-dark mr-2" disabled={!!find(equipmentRequestItems, { existing_id: aircraftByRoute.id })}
            onClick={this.openDeactivationDialog}
          >
            Request Deactivation
          </button>
        </div>
        <div className="float-right">
          <button
            onClick={this.cancelEditing}
            className="btn btn-sm btn-secondary mr-2"
          >
            Cancel
          </button>
          <button onClick={this.save} className="btn btn-sm btn-success">
            Save
          </button>
        </div>
        <OrgFilter allowOrgs={["hq"]}>
          <RoleFilter allowRoles={["HQ.MEMBER", "HQ.ADMIN"]}>
            <DeleteButton onDelete={this.handleDelete} />
          </RoleFilter>
        </OrgFilter>
      </div>
    );
  }


  render() {
    const { editing } = this.state;
    const {
      aircraftByRoute,
      maintenanceItems,
      maintenanceIsLoading,
      aircraftDocsItems,
      aircraftDocsIsLoading,
      aircraftMissionsItems,
      aircraftMissionsIsLoading,
      platformsDocsItems,
      platformsDocsIsLoading,
      doFileIoUpload,
      doFileIoDelete,
    } = this.props;

    return (
      <div className="container mt-3">
        {aircraftByRoute &&
          !aircraftByRoute.usace_approved &&
          !aircraftByRoute.benign_approved && (
            <div className="alert alert-danger" role="alert">
              <h5 className="alert-heading">Not USACE Approved</h5>
              <p>
                This aircraft is not approved for flight by USACE. If it is
                required, please provide reason in your mission plan.
              </p>
            </div>
          )}
        {aircraftByRoute && aircraftByRoute.benign_approved && (
          <div className="alert alert-warning" role="alert">
            <h5 className="alert-heading">Benign Only</h5>
            <p>
              This aircraft is only approved for Benign missions. If it is
              required for controlled missions, please provide reason in your
              mission plan.
            </p>
          </div>
        )}
        <div className="row">
          <div className="col-sm-7">
            <div className="card">
              <div className="card-header">
                <div className="float-right">
                  <RoleFilter
                    allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
                    alt={ReadOnly}
                  >
                    <button
                      disabled={editing}
                      className="btn btn-sm btn-ghost-primary"
                      onClick={this.enableEditing}
                    >
                      <i className="mdi mdi-pencil icon-inline"></i>
                      Edit
                    </button>
                  </RoleFilter>
                </div>
                <i className="mdi mdi-airplane"></i> Aircraft Details
              </div>
              <div className="card-body">
                {this.renderForm()}
                {this.renderActions()}
              </div>
            </div>
          </div>
          <div className="col-sm-5">
            <MaintenanceCard
              isLoading={maintenanceIsLoading}
              aircraft={aircraftByRoute}
              items={maintenanceItems}
            />
            <FileUploadCard
              allowRoles={[]}
              isLoading={platformsDocsIsLoading}
              title={"AWR Document"}
              header={true}
              searchable={false}
              items={
                aircraftByRoute &&
                  aircraftByRoute.awr_filename &&
                  aircraftByRoute.awr_s3_key
                  ? [
                    {
                      s3_key: aircraftByRoute.awr_s3_key,
                      filename: aircraftByRoute.awr_filename,
                    },
                  ]
                  : []
              }
              onUpload={null}
              onRemove={null}
              rel="platforms_awr_docs"
              data={{
                platform_id: null,
              }}
            />
            <FileUploadCard
              allowRoles={[]}
              isLoading={platformsDocsIsLoading}
              title={"SUAS Configuration Document"}
              header={true}
              searchable={false}
              items={
                aircraftByRoute &&
                  aircraftByRoute.configuration_filename &&
                  aircraftByRoute.configuration_s3_key
                  ? [
                    {
                      s3_key: aircraftByRoute.configuration_s3_key,
                      filename: aircraftByRoute.configuration_filename,
                    },
                  ]
                  : []
              }
              onUpload={null}
              onRemove={null}
              rel="platforms_configuration_docs"
              data={{
                platform_id: null,
              }}
            />
            <FileUploadCard
              allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
              isLoading={aircraftDocsIsLoading}
              items={aircraftDocsItems}
              header={true}
              searchable={false}
              onUpload={doFileIoUpload}
              onRemove={doFileIoDelete}
              rel="aircraft_docs"
              data={{
                aircraft_id: aircraftByRoute ? aircraftByRoute.id : null,
              }}
            />
            <FileUploadCard
              allowRoles={[]}
              isLoading={platformsDocsIsLoading}
              title={"Platform Documents"}
              header={true}
              searchable={false}
              items={platformsDocsItems}
              onUpload={null}
              onRemove={null}
              rel="platform_docs"
              data={{
                platform_id: null,
              }}
            />
            <FileUploadCard
              allowRoles={[]}
              isLoading={platformsDocsIsLoading}
              title={"AWR Document"}
              header={true}
              searchable={false}
              items={
                aircraftByRoute &&
                  aircraftByRoute.awr_filename &&
                  aircraftByRoute.awr_s3_key
                  ? [
                    {
                      s3_key: aircraftByRoute.awr_s3_key,
                      filename: aircraftByRoute.awr_filename,
                    },
                  ]
                  : []
              }
              onUpload={null}
              onRemove={null}
              rel="platforms_awr_docs"
              data={{
                platform_id: null,
              }}
            />
            <FileUploadCard
              allowRoles={[]}
              isLoading={platformsDocsIsLoading}
              title={"AWR Document"}
              header={true}
              searchable={false}
              items={
                aircraftByRoute &&
                  aircraftByRoute.awr_filename &&
                  aircraftByRoute.awr_s3_key
                  ? [
                    {
                      s3_key: aircraftByRoute.awr_s3_key,
                      filename: aircraftByRoute.awr_filename,
                    },
                  ]
                  : []
              }
              onUpload={null}
              onRemove={null}
              rel="platforms_awr_docs"
              data={{
                platform_id: null,
              }}
            />
            <ApprovedComponentsCard componentTypeToList="payload" />
            <ApprovedComponentsCard componentTypeToList="gcs" />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <MissionsCard
              isLoading={aircraftMissionsIsLoading}
              items={aircraftMissionsItems}
            />
            <NotesCard />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doFileIoUpload",
  "doFileIoDelete",
  "doAircraftSave",
  "doDialogOpen",
  "doAircraftDelete",
  "doUpdateRelativeUrl",
  "selectEquipmentRequestItems",
  "selectAircraftByRoute",
  "selectMaintenanceItems",
  "selectMaintenanceIsLoading",
  "selectAircraftDocsItems",
  "selectAircraftDocsIsLoading",
  "selectAircraftMissionsItems",
  "selectAircraftMissionsIsLoading",
  "selectRouteParams",
  "selectPlatformsDocsItems",
  "selectPlatformsDocsIsLoading",
  "selectTokenGroups",
  AircraftDetails
);
