import { find, sortBy } from "lodash";
import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";
export default createRestBundle({
  name: "missionsAlert",
  uid: "variable_id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/missions/:missionsSlug/alerts",
  putTemplate: "",
  postTemplate: "/orgs/:orgSlug/missions/:missionsSlug/alerts",
  deleteTemplate: "/orgs/:orgSlug/missions/:missionsSlug/alerts",
  fetchActions: [
    "AUTH_LOGGED_IN",
    "MISSIONSAREADATA_FETCH_FINISHED",
    "PLATFORMS_SAVE_FINISHED",
    "MISSIONS_CONFIGURATIONS_FETCH_FINISHED",
  ],
  forceFetchActions: ["ACTIVE_TAB_UPDATE", "URL_UPDATED"],
  addons: {
    selectMissionsAlertsAssocAdjudications: createSelector(
      "selectMissionsAlertItems",
      "selectMissionsAdjudicationsItems",
      (alerts, adjudications) => {
        return sortBy(
          alerts.map((item) => ({
            ...item,
            adjudications: adjudications.filter(
              (adj) =>
                adj.variable_id === item.variable_id &&
                adj.mission_id === item.id
            ),
          })),
          "create_date"
        );
      }
    ),
    selectMissionsAlertsByTab: createSelector(
      "selectMissionsAlertItems",
      (alerts) => {
        let alertsObj = {};
        alerts.forEach((alert) => {
          let { tab } = alert;
          if (alertsObj[tab]) {
            alertsObj[tab] = [...alertsObj[tab], alert];
          } else alertsObj[tab] = [alert];
        });
        return alertsObj;
      }
    ),
  },
});
