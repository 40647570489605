import React from "react";
import SchemaForm from "../../app-components/schema-form/schema-form";
import { connect } from "redux-bundler-react";
import { profile } from "../../models";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edipi: props.tokenEdipi
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const { doProfileSave } = this.props;
    if (this.form.isValid()) {
      const data = this.form.serialize();
      doProfileSave(data);
    }
  }

  render() {
    return (
      <div className="card mx-4">
        <div className="card-body p-4">
          <div className="d-flex" >
            <div className="col-sm-3 p-0">
              <h1>Register</h1>
              <p className="text-muted">Create your account</p>
            </div>
            <div className="col-sm-9 pl-2 pr-0">
              <div className="alert alert-info">
                <p>If you are signing up for the second time please email marshelp@usace.army.mil so your account can be properly configured.</p>
              </div>
            </div>
          </div>
          <SchemaForm
            ref={el => {
              this.form = el;
            }}
            inline={true}
            displayOnly={false}
            schema={profile}
            data={this.state}
          />
        </div>
        <div className="card-footer p-4">
          <button
            onClick={this.handleSubmit}
            className="btn btn-block btn-success"
            type="button"
          >
            Create Account
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  "doProfileSave",
  "selectTokenEdipi",
  Register
);
