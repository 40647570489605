import { createSelector } from "redux-bundler";
import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "efTwenty",
  uid: "id",
  initialData: {
    _isSigning: false,
  },
  prefetch: true,
  routeParam: null,
  getTemplate: "/profile/:id/efTwenty",
  postTemplate: "/profile/:id/efTwenty",
  putTemplate: "/profile/:id/efTwenty/:item.id",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN", "EFTWENTY_SAVE_FINISHED"],
  urlParamSelectors: ["selectProfileActiveData"],
  addons: {
    selectEfTwentyActive: createSelector(
      "selectEfTwentyItems",
      (efTwentyItems) => {
        return efTwentyItems && efTwentyItems.length > 0
          ? efTwentyItems[0]
          : {};
      }
    ),
  },
});
