import React from 'react';
const JobItemPocCard = ({ state }) => {
    const { jobData } = state
    const { poc_name, poc_phone, poc_email, alt_poc, alt_poc_name, alt_poc_phone, alt_poc_email } = jobData
    return (
        <div className="card mt-lg-4 mt-xl-4" style={{ maxHeight: "fit-content" }}>
            <h5 className="card-header">
                <span className="mdi mdi-phone mr-2" />
                Contact Info
            </h5>
            <div className="card-body d-flex flex-column">
                <div className="row ml-2">
                    <h6 className="col">
                        Primary Contact
                        <hr className="w-75 mx-0 mt-2 mb-1" />
                    </h6>
                </div>
                <div className="col ml-3">
                    <div className="row mx-0 my-1">
                        <div className="col-1 col-md-3 px-0">
                            <h6 className="d-md-inline d-none">Name</h6>
                            <span className="mdi mdi-account d-md-none d-inline" />
                        </div>
                        <div className="col-11 col-md-9 px-0"><span>{poc_name}</span></div>
                    </div>
                    {poc_phone &&
                        (<div className="row mx-0 my-2">
                            <div className="col-1 col-md-3 px-0">
                                <h6 className="d-md-inline d-none">Phone</h6>
                                <span className="mdi mdi-phone d-md-none d-inline" />
                            </div>
                            <div className="col-11 col-md-9 px-0">
                                <span>{poc_phone}</span>
                            </div>
                        </div>)}
                    {poc_email &&
                        (<div className="row mx-0 my-2">
                            <div className="col-1 col-md-3 px-0">
                                <h6 className="d-md-inline d-none">Email</h6>
                                <span className="mdi mdi-email-outline d-md-none d-inline" />
                            </div>
                            <div className="col-11 col-md-9 px-0">
                                <span>{poc_email}</span>
                            </div>
                        </div>)}
                </div>
                {alt_poc && alt_poc.length > 0 ? (
                    <div className="row ml-2 mt-4">
                        <h6 className="col">
                            Secondary Contact
                            <hr className="w-75 mx-0 mt-2 mb-1" />
                        </h6>
                    </div>

                ) : null}
                {alt_poc && alt_poc.length > 0 ? (
                    <div className="col ml-3">
                        <div className="row mx-0 my-1">

                            <div className="col-1 col-md-3 px-0">
                                <h6 className="d-md-inline d-none">Name</h6>
                                <span className="mdi mdi-account d-md-none d-inline" />
                            </div>
                            <div className="col-11 col-md-9 px-0"><span>{alt_poc_name}</span></div>

                        </div>
                        {poc_phone &&
                            (<div className="row mx-0 my-2">
                                <div className="col-1 col-md-3 px-0">
                                    <h6 className="d-md-inline d-none">Phone</h6>
                                    <span className="mdi mdi-phone d-md-none d-inline" />
                                </div>
                                <div className="col-11 col-md-9 px-0">
                                    <span>{alt_poc_phone}</span>
                                </div>
                            </div>)}
                        {poc_email &&
                            (<div className="row mx-0 my-2">
                                <div className="col-1 col-md-3 px-0">
                                    <h6 className="d-md-inline d-none">Email</h6>
                                    <span className="mdi mdi-email-outline d-md-none d-inline" />
                                </div>
                                <div className="col-11 col-md-9 px-0">
                                    <span>{alt_poc_email}</span>
                                </div>
                            </div>)}
                    </div>) : null}
            </div>
        </div>
    )
}
export default JobItemPocCard;