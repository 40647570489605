import React from "react";

class DomainsGroupListItem extends React.Component {
  render() {
    const { group, onSelect, active } = this.props;
    return (
      <li
        className={`list-group-item list-group-item-action ${
          active ? "active" : ""
        }`}
        onClick={() => {
          onSelect(group);
        }}
      >
        {group}
      </li>
    );
  }
}

export default DomainsGroupListItem;
