import { format } from "date-fns";
import { find, get, capitalize, isArray, isPlainObject } from "lodash";
import centroid from "@turf/centroid";

// a "helper-function" for getting precise string values for certain fields, specifically for 'risk-assessment.template.json'
const handleSignatureDetails = (props) => {
  const {
    mission_approval,
    missionsApprovalNotesItemsByMissionApprovalId,
    plan_sig,
    personnelItems,
  } = props;
  let update = {};
  if (plan_sig) {
    let planSigParsed = JSON.parse(atob(plan_sig.split(".")[0]));
    update = { ...update, ...processApproverSig("preparer", { sig: planSigParsed }, personnelItems) };
  }
  if (mission_approval[0] && mission_approval[0].routing_order === 1) {
    if (mission_approval[0].sig)
      update = {
        ...update,
        ...processApproverSig("mbo", mission_approval[0], personnelItems),
      };
  }
  if (mission_approval[1] && mission_approval[1].routing_order === 2) {
    if (mission_approval[1].sig)
      update = {
        ...update,
        ...processApproverSig("atpm", mission_approval[1], personnelItems),
      };
  }
  if (mission_approval[2] && mission_approval[2].routing_order === 3) {
    if (mission_approval[2].sig)
      update = {
        ...update,
        ...processApproverSig("maa", mission_approval[2], personnelItems),
      };
    let maaApprovalNotes =
      missionsApprovalNotesItemsByMissionApprovalId[mission_approval[2].id];
    if (maaApprovalNotes) {
      maaApprovalNotes.sort((a, b) => {
        if (a.last_updated_date > b.last_updated_date) return 1;
        if (a.last_updated_date < b.last_updated_date) return -1;
      });
      let mostRecentNote = maaApprovalNotes[maaApprovalNotes.length - 1];
      update["additional_guidance"] = `(${
        mostRecentNote.is_rejected ? "Rejected" : "Approved"
      } ${mostRecentNote.last_updated_date}) - ${mostRecentNote.note}`;
    }
  }
  return update;
};
export const extraHelpers = {
  handleSignatureDetails: (props) => handleSignatureDetails(props),
};

const processSig = (keyPrefix, sig, approvalRoleObj, personnelItems) => {
  let result = {};
  let personnelItem = find(personnelItems, { keycloak_id: sig.keycloak_id });
  if (personnelItem) {
    result[`${keyPrefix}_sig`] = [
      `(Digitally Signed) ${personnelItem.name}`,
      `${sig.date}`,
    ];
    result[`${keyPrefix}_phone`] = personnelItem.phone;
    result[`${keyPrefix}_email`] = personnelItem.email;
    if (approvalRoleObj)
      result[`${keyPrefix}_position`] = approvalRoleObj.approval_role;
    if (personnelItem.email.length > 0) {
      let firstMiddleLastTest = new RegExp(/(\w.*?)\.([a-z])\.(\w.*?)(?=@)/);
      if (firstMiddleLastTest.exec(personnelItem.email)) {
        let emailName = personnelItem.email.split("@")[0];
        let [firstName, middleInitial, lastName] = emailName.split(".");
        result[
          `${keyPrefix}_full_name`
        ] = `${lastName}, ${firstName}, ${capitalize(middleInitial)}`;
      }
    } else {
      let firstAndLastName = personnelItem.name.split(" ");
      if (firstAndLastName.length >= 2) {
        let lastName = firstAndLastName.slice(1).join(" ");
        result[
          `${keyPrefix}_full_name`
        ] = `${lastName}, ${firstAndLastName[0]}`;
      }
    }
  }

  return result;
};
const processApproverSig = (role, mission_approval, personnelItems) => {
  let update = {};
  let parsedSig = mission_approval.sig;
  let userIdKey = parsedSig.keycloak_id ? "keycloak_id" : "edipi";

  let userId = parsedSig[userIdKey];
  let personnelOfSig = find(personnelItems, { [userIdKey]: userId });

  if (personnelOfSig) {
    if (!personnelOfSig.name && personnelOfSig.username) {
      update[`${role}_sig_last_name`] = personnelOfSig.username.split(" ")[0];
      update[`${role}_full_name`] = parsedSig.username.split(".").join(" ");
    } else if (!personnelOfSig.username && personnelOfSig.name) {
      let firstLastName = personnelOfSig.name.split(" ");
      update[`${role}_sig_last_name`] = personnelOfSig.name.split(" ")[1];
      update[`${role}_full_name`] = firstLastName[1] + " " + firstLastName[0];
    }
  } else if (!parsedSig.name && parsedSig.username) {
    update[`${role}_sig_last_name`] = parsedSig.username.split(".")[0];
    update[`${role}_full_name`] = parsedSig.username.split(".").join(" ");
  }

  update[`${role}_sig_date`] = new Date(parsedSig.date).toLocaleDateString();
  update = {
    ...update,
    ...processSig(role, parsedSig, mission_approval, personnelItems),
  };

  return update;
};

export const drawNode = (
  doc,
  state,
  template,
  node,
  nodeDims,
  contentDims,
  linePos,
  personnel
) => {
  const processTextTemplate = (cell, text, templateField, foundItem) => {
    // a cell's "text-template" is the placeholder text that the actual value is rendered in
    let textTemplate = templateField["text-template"];
    let nullValueExists =
      typeof templateField["text-template-display-on-null"] === "string";

    // the "text-template" can have several variable names wrapped in brackets {}
    let variables = templateField["text-template"].match(
      new RegExp(/\{.*?\}/g)
    );
    variables = variables.map((v) => v.slice(1, v.length - 1));

    variables.forEach((v) => {
      if (v === "nth") {
        // The order of the cell if it has a 'row-index' attribute
        textTemplate = textTemplate.replace(`{${v}}`, cell["row-index"] + 1);
      } else if (v === "index") {
        textTemplate = textTemplate.replace(`{${v}}`, cell["row-index"]);
      } else if (v === "value") {
        // The value of the field contained in the state
        let valueKey = cell.field.replace("-{x}", "");
        valueKey = valueKey.replace(
          new RegExp(`^${templateField["list-attr-name"]}_`),
          ""
        );
        if (foundItem && foundItem[valueKey]) {
          text = foundItem[valueKey];
        }

        if (text.length > 0) {
          if (cell["string-slice"]) {
            let strSlice = cell["string-slice"];
            strSlice = strSlice.split(",").map((num) => {
              if (num.includes("len")) {
                num = num.replace("len", text.length);
                return Function("", `return ${num}`)();
              } else return parseInt(num);
            });

            if (typeof text === "string") {
              text = text.slice(...strSlice);
            }
          }
          textTemplate = textTemplate.replace(`{${v}}`, text);
        } else if (nullValueExists) {
          textTemplate = textTemplate.replace(
            `{${v}}`,
            templateField["text-template-display-on-null"]
          );
        }
      } else if (Object.keys(template.fields).includes(`${v}-{x}`)) {
        // "-{x}" is placed at the end of any fields that represent an enumerated list of similarly rendered fields
        let subField = template.fields[`${v}-{x}`];

        let foundSubItem = state[subField["list-attr-name"]]
          ? state[subField["list-attr-name"]][cell["row-index"]]
          : null;

        if (foundSubItem) {
          let subTextByType = renderByType(text, subField, foundSubItem);
          if (subTextByType) {
            textTemplate = textTemplate.replace(`{${v}}`, subTextByType);
          }
        }
      } else if (Object.keys(template.fields).includes(v)) {
        // If the variable in the cell's text-template references another field in document, repeat the same process above
        let subField = template.fields[v];
        let subVariables = subField["text-template"]
          ? subField["text-template"].match(new RegExp(/\{.*?\}/g))
          : [];
        let subFieldTextByType = renderByType(state[v], subField);
        let subFieldText = subFieldTextByType
          ? subFieldTextByType
          : subField["text-template"]
          ? subField["text-template"]
          : state[subField]
          ? state[subField]
          : "";
        let subNullValueExists =
          typeof subField["text-template-display-on-null"] === "string";

        subVariables.forEach((sv) => {
          if (sv === "{nth}") {
            subFieldText = subFieldText.replace(sv, cell["row-index"] + 1);
          } else if (sv === "{value}") {
            let valueKey = v.replace(
              new RegExp(`^${subField["list-attr-name"]}_`),
              ""
            );

            if (state[subField])
              subFieldText = subFieldText.replace(sv, state[subField]);
            else if (foundItem && foundItem[valueKey]) {
              subFieldText = subFieldText.replace(sv, foundItem[valueKey]);
            } else if (subNullValueExists) {
              subFieldText = subField["text-template-display-on-null"];
            }
          }
        });
        textTemplate = textTemplate.replace(`{${v}}`, subFieldText);
      }
    });
    textTemplate = textTemplate.split("|split|");

    return textTemplate;
  };
  const renderByType = (text, templateField, stateItem) => {
    // Get the text of the field and render it according to its type
    switch (templateField.type) {
      case "sig":
        let result = "";
        if (templateField["sig-template"]) {
          result = templateField["sig-template"];
          let sigVariables = templateField["sig-template"].match(
            new RegExp(/\{.*?\}/g)
          );
          if (isArray(text)) {
            result = text;
          } else if (isPlainObject(text)) {
            sigVariables.forEach((v) => {
              let valInBrackets = v.slice(1, v.length - 1);
              if (text[valInBrackets]) {
                result = result.replace(v, text[valInBrackets]);
              }
            });

            if (result.includes("{username}") && text["keycloak_id"]) {
              let foundPersonnel = find(personnel, {
                keycloak_id: text["keycloak_id"],
              });
              if (foundPersonnel)
                result = result.replace("{username}", foundPersonnel.name);
            }
            result = result.split("|split|");
          } else result = "";
        }

        return result;

      case "object-attr":
        if (templateField.attr && stateItem && stateItem[templateField.attr]) {
          return stateItem[templateField.attr];
        }
        return text;

      case "value":
        return stateItem;

      case "lat-long-using-centroid":
        if (text && text.features && text.features.length === 0) return null;
        let centerPt = centroid(text);
        let latLong = centerPt.geometry.coordinates.reduce((p, c) => {
          return (p += `    ${Math.round(c * 1000) / 1000}`);
        }, "");
        return latLong;

      case "domain":
        let foundDomainItem = find(state[templateField.domain], {
          val: stateItem[templateField.name],
        });
        if (foundDomainItem) {
          let label = foundDomainItem.label;
          if (stateItem[`${templateField.name}_variable`] === "") {
            label = label.replace(/(\{.*?\})/, "_________");
          } else if (stateItem[`${templateField.name}_variable`]) {
            let fieldVariable = stateItem[`${templateField.name}_variable`];
            label = label.replace(/(\{.*?\})/, fieldVariable);
          }
          text = label;
        }
        return text;

      case "page-display":
        let pageInfo = doc.getCurrentPageInfo();
        let totalPages = template.pageAttributes.filter(
          (p) => !p["omit-from-count"]
        ).length;
        let pageDisplayTemplate = templateField.template;
        text = pageDisplayTemplate.replace(
          "{current}",
          `${pageInfo.pageNumber}`
        );
        text = text.replace("{total}", `${totalPages}`);
        return text;

      case "date":
        if (stateItem && typeof stateItem === "object") {
          text = stateItem[templateField.name];
        }
        if (typeof stateItem === "string" && stateItem.length > 0) {
          text = new Date(stateItem);
        }

        if (
          [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC",
          ].includes(text)
        )
          text = `1-${text}`;
        if (templateField.format === "month-long") {
          text = text
            ? new Date(text).toLocaleString("default", { month: "long" })
            : "";
        } else
          text = text
            ? format(new Date(text.toString()), templateField.format)
            : "";
        return text;

      default:
        return null;
    }
  };
  const getTextContentOfNode = (cell) => {
    let text = cell.field
      ? state[cell.field]
        ? state[cell.field]
        : ""
      : cell.text;
    let templateField = null;
    if (template.fields[cell.field])
      templateField = template.fields[cell.field];
    // "-{x}" is placed at the end of any fields that are apart of a list of similarly rendered fields
    if (template.fields[`${cell.field}-{x}`]) {
      templateField = template.fields[`${cell.field}-{x}`];
    }
    if (templateField) {
      if (templateField["list-attr-name"] && templateField["index-attr-name"]) {
        let foundItem = null;
        if (
          templateField["index-attr-name"] ===
          templateField["list-attr-name"] + ".index"
        ) {
          foundItem = state[templateField["list-attr-name"]]
            ? state[templateField["list-attr-name"]][cell["row-index"]]
            : null;
        } else
          foundItem =
            state[templateField["list-attr-name"]][
              templateField["index-attr-name"]
            ];

        if (foundItem) {
          let textByType = renderByType(text, templateField, foundItem);
          if (textByType) text = textByType;
          if (templateField["text-template"]) {
            text = processTextTemplate(cell, text, templateField, foundItem);
          }
        } else text = "";
      } else {
        let stateItem = templateField["resource"]
          ? state[templateField["resource"]]
          : state[cell.field];

        let textByType = renderByType(
          state[cell.field],
          templateField,
          stateItem
        );
        text = textByType ? textByType : text;

        if (templateField.derive_from) {
          let deriveInfo = templateField.derive_from;
          if (
            template.fields[deriveInfo.src] &&
            template.fields[deriveInfo.src].type === "base64"
          ) {
            let sig = state[deriveInfo.src];
            if (sig) {
              let sigSplit = JSON.parse(atob(sig.split(".")[0]));
              if (sigSplit[deriveInfo.prop]) {
                text = sigSplit[deriveInfo.prop];
              }
              if (deriveInfo.prop === "keycloak_id") {
                let matchingPersonnel = find(personnel, { keycloak_id: text });
                text = matchingPersonnel ? matchingPersonnel.name : "";
              }
            }
          }
        }
        if (templateField["text-template"]) {
          text = processTextTemplate(cell, text, templateField, stateItem);
        } else if (cell.type === "checkbox") return templateField.name;
      }
    }
    // a cell's 'max-length' is the max number of characters allowed in its text content
    if (cell["max-length"]) {
      if (typeof text === "string") {
        if (text.length >= cell["max-length"]) {
          text = text.slice(0, cell["max-length"]) + "...";
        }
      } else {
        text = text.map((line) => {
          if (line.length >= cell["max-length"]) {
            return line.slice(0, cell["max-length"]) + "...";
          }
          return line;
        });
      }
    }
    // a cell's 'wrap-width' is the width of the container that it fits in. Text will stay inside the width specified
    if (cell["wrap-width"]) {
      text = doc.splitTextToSize(text, cell["wrap-width"]);
    }
    return text;
  };

  let {
    "font-size": nodeFontSize,
    "border-width": nodeBorderWidth,
    "font-family": nodeFontFamily,
    fillColor,
    textColor,
  } = node.style;

  // If border-width is specified in node.style, draw rectangle using x,y,w,h in nodeDims
  if (nodeBorderWidth) {
    doc.setLineWidth(parseInt(nodeBorderWidth));
    if (fillColor) {
      doc.setFillColor(...fillColor.split(",").map((i) => parseInt(i)));
      doc.rect(nodeDims.x, nodeDims.y + linePos, nodeDims.w, nodeDims.h, "FD");
      doc.setFillColor(255, 255, 255); // reset fill-color to white if it was changed
    } else
      doc.rect(nodeDims.x, nodeDims.y + linePos, nodeDims.w, nodeDims.h, "S");
  }

  // If this node has text content
  if (node.text || node.field) {
    let { x: cx, y: cy } = contentDims;
    cy = contentDims.y + linePos;
    if (nodeFontSize) doc.setFontSize(parseInt(nodeFontSize));
    if (nodeFontFamily) doc.setFont(...nodeFontFamily.split(" "));

    let text = getTextContentOfNode(node);
    if (typeof text === "number") text = text.toString();
    let fieldInTemplate = template.fields[node.field + "-{x}"];

    // If node type if 'figure' (image)
    if (fieldInTemplate && fieldInTemplate.type === "figure") {
      let nodeImage = state[node.field][node["row-index"]];
      if (nodeImage) {
        doc.addImage(
          nodeImage.img,
          node["media-type"],
          contentDims.x + 5,
          contentDims.y + 10,
          node["media-width"],
          node["media-height"],
          undefined,
          "MEDIUM",
          0
        );
        doc.text(text, 566 / 2, contentDims.y + 10 + 220 + 3, {
          align: "center",
          baseline: "top",
          maxWidth: 566 - 100,
        });
      }
    } else if (node.type === "checkbox") {
      doc.setFont("zapfdingbats", "normal"); //font family 'zapfdingbats' has checkmark (with value "4") for checkbox
      doc.rect(cx, cy - 10, 12, 12);
      let fieldInTemplate = template.fields[node.field];
      if (fieldInTemplate) {
        if (fieldInTemplate.type === "resource-domain") {
          let domainValInState = state[fieldInTemplate.resource];
          if (fieldInTemplate.attr) {
            domainValInState = domainValInState[fieldInTemplate.attr];
          }

          if (domainValInState) {
            let domainOfField = state[fieldInTemplate.domain];
            let matchingVal = fieldInTemplate.domainItemVal
              ? fieldInTemplate.domainItemVal
              : fieldInTemplate.name;
            let matchingDomainItem = find(domainOfField, { val: matchingVal });

            if (
              matchingDomainItem &&
              domainValInState.includes(matchingDomainItem.id)
            ) {
              doc.text("4", cx, cy);
            }
          }
        } else if (fieldInTemplate.type === "string-value") {
          if (fieldInTemplate["resource"].includes(".")) {
            let foundResource = get(state, fieldInTemplate["resource"]);
            if (fieldInTemplate.multipleIds) {
              if (foundResource.includes(fieldInTemplate["string-value"]))
                doc.text("4", cx, cy);
            } else if (foundResource === fieldInTemplate["string-value"])
              doc.text("4", cx, cy);
          } else if (
            state[fieldInTemplate["resource"]] ===
            fieldInTemplate["string-value"]
          )
            doc.text("4", cx, cy);
        } else if (fieldInTemplate.type === "boolean" && state[node.field]) {
          doc.text("4", cx, cy);
        } else if (fieldInTemplate.type === "boolean-compare-to-state") {
          if (state[fieldInTemplate.resource] === fieldInTemplate.value)
            doc.text("4", cx, cy);
        }
      }
      doc.setFont(...nodeFontFamily.split()); //reset font-family to default
      if (textColor) {
        doc.setTextColor(...textColor.split(",").map((i) => parseInt(i)));
      }
      doc.text(text, cx + 15, cy);
    } else {
      // Node is default type (text and/or border)
      if (textColor) {
        doc.setTextColor(...textColor.split(",").map((i) => parseInt(i)));
      }

      doc.text(
        text,
        cx,
        cy,
        node.style.align ? { align: node.style.align } : null
      );
    }
    doc.setTextColor(0, 0, 0); // reset text color to black if it was changed
  }
};
