import React from "react";
import { find } from "lodash";

class Picker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ""
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
  }

  handleSelect(e) {
    this.setState({
      value: e.target.value
    });
  }

  handleAddClick() {
    const { value } = this.state;
    const { onAdd, items } = this.props;
    const item = find(items, { id: value });
    if (onAdd) {
      this.setState(
        {
          value: ""
        },
        () => {
          onAdd(item);
        }
      );
    }
  }

  render() {
    const { value } = this.state;
    const { items, itemRenderFn } = this.props;
    return (
      <div className="input-group mt-2">
        <select
          onChange={this.handleSelect}
          value={value}
          className="form-control form-control-sm"
        >
          <option value="" disabled hidden>
            Please Choose...
          </option>
          {items.map((item, i) => {
            return (
              <option key={i} value={item.id}>
                {itemRenderFn(item)}
              </option>
            );
          })}
        </select>
        <div className="input-group-append">
          <button
            disabled={value === ""}
            onClick={this.handleAddClick}
            className="btn btn-sm btn-primary"
          >
            Add
          </button>
        </div>
      </div>
    );
  }
}

export default Picker;
