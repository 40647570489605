import React from "react";
import { connect } from "redux-bundler-react";
import FileUploadCard from "../../../modules/_shared/file-upload-card/file-upload-card";

const Documents = ({
  profileDocsItems,
  profileDocsTemp,
  profileActiveData,
  doFileIoUpload,
  doFileIoDelete,
}) => {
  let documents = profileDocsTemp ? profileDocsTemp : profileDocsItems;
  let profile_id = profileActiveData.id;
  return (
    <div>
      <FileUploadCard
        header
        allowRoles={["*.*"]}
        title="Associated Documents"
        items={documents}
        onUpload={doFileIoUpload}
        onRemove={doFileIoDelete}
        postUrl="/profile/docs"
        removeUrl="/profile/docs"
        rel="profile_docs"
        data={{
          profile_id,
        }}
        scrollable
      />
    </div>
  );
};

export default connect(
  "selectProfileDocsItems",
  "selectProfileDocsTemp",
  "selectProfileActiveData",
  "doFileIoUpload",
  "doFileIoDelete",
  Documents
);
