import React from "react";
import classnames from "classnames";

class SidebarNavDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  toggleOpen(e) {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { open } = this.state;
    const { iconClass, title, children } = this.props;
    const dropdownClass = classnames({
      "nav-item": true,
      "nav-dropdown": true,
      open: open
    });
    return (
      <li className={dropdownClass}>
        <div className="nav-link nav-dropdown-toggle" onClick={this.toggleOpen}>
          <i className={`${iconClass} nav-icon`}></i>
          {` ${title}`}
        </div>
        <ul className="nav-dropdown-items">{children}</ul>
      </li>
    );
  }
}

export default SidebarNavDropdown;
