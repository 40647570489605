import React from "react";
import { connect } from "redux-bundler-react";
import { payloads, payloadsRequest } from "../../models";
import SchemaForm from "../../app-components/schema-form/schema-form";

class PayloadsNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      org_id: props.orgsByRoute.id
    };
    this.save = this.save.bind(this);
  }

  save() {
    const { doPayloadsSave, orgsByRoute, doUpdateRelativeUrl, doEquipmentRequestSave, profileActiveData, equipmentRequestUserIsAdmin: userIsAdmin } = this.props;
    if (this.form.isValid()) {
      const data = this.form.serialize();
      if (userIsAdmin) {
        doPayloadsSave(
          data,
          (newItem) => {
            doUpdateRelativeUrl(`/${orgsByRoute.slug}/payloads/${newItem.slug}`);
          },
          true
        );
      }
      else {
        doEquipmentRequestSave(
          {
            ...data,
            created_by: profileActiveData.keycloak_id,
            status: "New",
            action: "Add",
            type: "payload",
            org_id: orgsByRoute.id,
            org_name: orgsByRoute.name,
            profile_id: profileActiveData.id,
            platform_id: data.parent_id,
            payload_type_id: data.payload_type_id
        }, () => doUpdateRelativeUrl(`/${orgsByRoute.slug}/payloads`), true)
      }
      
    }
  }

  render() {
    const { payloadsIsSaving: isSaving, equipmentRequestUserIsAdmin: userIsAdmin } = this.props;
    return (
      <div className="container mt-3">
        <div className="row">
          <div className="col-sm-12 col-lg-9">
            <div className="card">
              <div className="card-header">
                <i className="mdi mdi-camera"></i> New Payload
              </div>
              <div className="card-body">
                <SchemaForm
                  ref={(el) => {
                    this.form = el;
                  }}
                  inline={true}
                  displayOnly={false}
                  schema={payloads}
                  data={this.state}
                />
                <div className="float-right">
                  <button
                    disabled={isSaving}
                    onClick={() => {
                      window.history.back();
                    }}
                    className="btn btn-secondary mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={isSaving}
                    onClick={this.save}
                    className="btn btn-primary"
                  >
                    {userIsAdmin ? "Save" : "Request"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doEquipmentRequestSave",
  "doUpdateRelativeUrl",
  "doPayloadsSave",
  "selectProfileActiveData",
  "selectEquipmentRequestUserIsAdmin",
  "selectOrgsByRoute",
  "selectPayloadsIsSaving",
  PayloadsNew
);
