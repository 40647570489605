import { createSelector } from "redux-bundler";

export default {
  name: "profileAlert",

  getReducer() {
    const initialData = {
      data: [],
      isFetching: false,
      shouldFetch: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "PROFILEALERT_FETCH_STARTED":
        case "PROFILEALERT_FETCH_FINISHED":
        case "PROFILEALERT_FETCH_ERROR":
        case "PROFILEALERT_FETCH_ABORT":
          return Object.assign({}, state, payload);
        case "HEALTHASSESSMENT_SAVE_FINISHED":
        case "EFTWENTYTWO_FETCH_FINISHED":
          return { ...state, shouldFetch: true };
        default:
          return state;
      }
    };
  },

  doProfileAlertFetch:
    () =>
    ({ dispatch, store, apiGet }) => {
      dispatch({
        type: "PROFILEALERT_FETCH_STARTED",
        payload: { isFetching: true, shouldFetch: false },
      });
      let profileId = store.selectProfileActiveData().id;
      if (!profileId) {
        dispatch({
          type: "PROFILEALERT_FETCH_ABORT",
          payload: { isFetching: false },
        });
      } else {
        apiGet(`/profile/${profileId}/alerts`, (err, response, body) => {
          if (err || response.statusCode !== 200) {
            dispatch({
              type: "PROFILEALERT_FETCH_ERROR",
              payload: {
                err: { err: err, response: response },
                notification: {
                  statusCode: response.statusCode,
                },
                isFetching: false,
              },
            });
          } else {
            const data = JSON.parse(body);
            dispatch({
              type: "PROFILEALERT_FETCH_FINISHED",
              payload: {
                isFetching: false,
                data,
              },
            });
          }
        });
      }
    },

  selectProfileAlertItems: (state) => state.profileAlert.data,
  selectProfileAlertIsFetching: (state) =>
    state.profileAlert.isFetching || state.profile._profileCurrencyIsFetching,
  selectProfileAlertShouldFetch: (state) => state.profileAlert.shouldFetch,

  reactProfileAlertShouldFetch: createSelector(
    "selectProfileAlertShouldFetch",
    "selectProfileAlertIsFetching",
    (shouldFetch, isFetching) => {
      if (isFetching) return null;
      if (shouldFetch)
        return {
          actionCreator: "doProfileAlertFetch",
        };
    }
  ),
};
