import React from "react";
import { connect } from "redux-bundler-react";

class PlatformsPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ""
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
  }

  handleSelect(e) {
    this.setState({
      value: e.target.value
    });
  }

  handleAddClick() {
    const { value } = this.state;
    const { platformsItemsObject, onAdd } = this.props;
    if (onAdd) {
      this.setState(
        {
          value: ""
        },
        () => {
          onAdd(platformsItemsObject[value]);
        }
      );
    }
  }

  render() {
    const { value } = this.state;
    const { platformsItems } = this.props;
    return (
      <div className="input-group mt-2">
        <select
          onChange={this.handleSelect}
          value={value}
          className="form-control form-control-sm"
        >
          <option value="" disabled hidden>
            Please Choose...
          </option>
          {platformsItems.map((item, i) => {
            return (
              <option
                key={i}
                value={item.slug}
              >{`${item.make} ${item.model}`}</option>
            );
          })}
        </select>
        <div className="input-group-append">
          <button
            disabled={value === ""}
            onClick={this.handleAddClick}
            className="btn btn-sm btn-primary"
          >
            Add
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  "selectPlatformsItems",
  "selectPlatformsItemsObject",
  PlatformsPicker
);
