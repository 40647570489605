import React from "react";
import { connect } from "redux-bundler-react";
import Grid from "../../_shared/grid";
import { sortBy, find } from "lodash";
import Loader from "../../../app-components/loader";
import { customComparator } from "../../_shared/helper";
import { allowedIds } from "../../_shared/sysadmin-users";
import {
  AssociatedItemRenderer,
  BenignToggle,
  BenignApprovedCell,
  AwrDateCell,
  AwrUpload,
  Toggle,
  UsaceApprovedCell,
} from "../grid-renderers";
import ImageDialog from "./image-dialog";




class Platforms extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.onDropdownItemClicked = this.onDropdownItemClicked.bind(this);
    this.updateDropdownItems = this.updateDropdownItems.bind(this);
    this.openImageDialog = this.openImageDialog.bind(this);
  }

  handleSelect(slug) {
    const { doUpdateRelativeUrl, orgsByRoute } = this.props;
    doUpdateRelativeUrl(`/${orgsByRoute.slug}/platforms/${slug}`);
  }

  openImageDialog(params) {
    const { doDialogOpen } = this.props;
    doDialogOpen({
      content: ImageDialog,
      props: { params },
      size: "s",
    });
  }

  onDropdownItemClicked = async (item, data) => {
    const { doAircraftConfigurationSave } = this.props;
    let args = {
      ...item,
      aircraft_id: data.id,
      slug: data.slug,
      make: data.make,
      model: data.model,
    };
    doAircraftConfigurationSave(
      Object.assign({}, { ...args, approved: item.id && item.approved ? 0 : 1 })
    );
  };
  updateDropdownItems(data, displayOnly, type) {
    const {
      platformsPayloadsApproved,
      aircraftConfigurationItems,
      platformsGcsApproved,
    } = this.props;
    let items = [];
    let num = 0;
    let platforms =
      type === "GCS" ? platformsGcsApproved : platformsPayloadsApproved;
    let id = type === "GCS" ? "gcs_id" : "payload_id";
    platforms.forEach((platform) => {
      let combinedItem = {
        [id]: platform.id,
        aircraft_id: data.id,
        make: platform.make,
        model: platform.model,
      };
      let itemExists = find(aircraftConfigurationItems, {
        [id]: platform.id,
        aircraft_id: data.id,
      });
      if (itemExists) {
        combinedItem = {
          ...combinedItem,
          ...itemExists,
          selected: itemExists.approved === 1,
        };
        if (itemExists.approved === 1) ++num;
      }
      combinedItem = {
        ...combinedItem,
        make: platform.make,
        model: platform.model,
      };
      items.push(combinedItem);
    });
    items.sort((a, b) => {
      if (a.model > b.model) return 1;
      else return -1;
    });
    if (displayOnly) items = items.filter((i) => i.selected);
    return { items: items, numSelected: num };
  }

  render() {
    const {
      platformsItems: items,
      orgsByRoute,
      platformsIsSaving,
      doPlatformsPut,
      tokenKeyCloakId,
    } = this.props;

    const config = {
      suppressScrollOnNewData: true,
      rowHeight: 40,
      columnDefs: [
        {
          resizable: true,
          sortable: true,
          headerName: "Make",
          field: "make",
          filter: "agTextColumnFilter",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Model",
          field: "model",
          filter: "agTextColumnFilter",
          cellRenderer: (params) => {
            return (
              <a href={`/${orgsByRoute.slug}/platforms/${params.data.slug}`}>
                {params.data.model}
              </a>
            );
          },
          comparator: customComparator,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Active Count",
          field: "active_count",
          filter: "agTextColumnFilter",
          comparator: (valueA, valueB) => valueA - valueB,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Image",
          field: "url",
          cellRenderer: (params) => {
            if (params.value)
              return (
                <i className="mdi mdi-image" onMouseOver={(e) => this.openImageDialog(params)} />
              )
          }
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Group Type",
          field: "group_type",
          filter: "agTextColumnFilter",
        },
        {
          resizable: true,
          sortable: false,
          headerName: "USACE Approved",
          field: "usace_approved",
          filter: "agTextColumnFilter",
          cellRenderer: allowedIds.includes(tokenKeyCloakId)
            ? Toggle
            : UsaceApprovedCell,
          valueGetter: (p) => p.data,
          cellRendererParams: {
            doSave: doPlatformsPut,
            items,
          },
        },
        {
          resizable: true,
          sortable: false,
          headerName: "Benign Only",
          field: "benign_approved",
          filter: "agTextColumnFilter",
          cellRenderer: allowedIds.includes(tokenKeyCloakId)
            ? BenignToggle
            : BenignApprovedCell,
          valueGetter: (p) => p.data,
          cellRendererParams: {
            doSave: doPlatformsPut,
            items,
          },
        },
        {
          resizable: true,
          sortable: false,
          field: "Approved Payloads",
          headerName: "Approved Payloads",
          cellRendererSelector: (params) => {
            return {
              component: AssociatedItemRenderer,
              params: {
                items: items,
                updateDropdownItems: this.updateDropdownItems,
                onItemClicked: this.onDropdownItemClicked,
                type: "Payload",
                totalRows: items.length,
                displayOnly: !allowedIds.includes(tokenKeyCloakId),
              },
            };
          },
          valueGetter: (p) => p.data,
          cellClass: ["d-flex", "m-auto", "p-auto"],
        },
        {
          resizable: true,
          sortable: false,
          field: "Approved GCS",
          headerName: "Approved GCS",
          cellRendererSelector: (params) => {
            return {
              component: AssociatedItemRenderer,
              params: {
                items: items,
                updateDropdownItems: this.updateDropdownItems,
                onItemClicked: this.onDropdownItemClicked,
                type: "GCS",
                totalRows: items.length,
                displayOnly: !allowedIds.includes(tokenKeyCloakId),
              },
            };
          },
          valueGetter: (p) => p.data,
          cellClass: ["d-flex", "m-auto", "p-auto"],
        },
        {
          resizable: true,
          sortable: true,
          headerName: "AWR File",
          field: "filename",
          cellRenderer: (props) => {
            return <AwrUpload {...props} doSave={doPlatformsPut} />;
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "AWR Exp Date",
          field: "awr_expire_date",
          cellRenderer: (props) => {
            return <AwrDateCell {...props} doSave={doPlatformsPut} />;
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "AWR #",
          field: "awr_number",
        },
      ],
    };
    let sorted = items.map((item, idx) => {
      return {
        ...item,
        rowIndex: idx,
        usace_approved: !!item.usace_approved,
        benign_approved: !!item.benign_approved,
      };
    });
    sorted = sortBy(sorted, ["make", "model"]);
    if (platformsIsSaving) {
      return (
        <div className="container-fluid mt-4">
          <Loader opt="dissolve-cube" />
        </div>
      );
    }
    if (platformsIsSaving) {
      return (
        <div className="container-fluid mt-4">
          <Loader opt="dissolve-cube" />
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-12">
          <div className="card platforms-grid-card">
            <div className="card-header">Approved Platforms</div>
            <div className="card-body p-0">
              <Grid
                config={config}
                data={sorted.filter((s) => s.usace_approved)}
                style={{
                  height: `${window.innerHeight * 0.8}px`,
                  width: "100%",
                }}
              />
            </div>
          </div>
          <div className="card platforms-grid-card">
            <div className="card-header">Unapproved Platforms</div>
            <div className="card-body p-0">
              <Grid
                config={config}
                data={sorted.filter((s) => !s.usace_approved)}
                style={{
                  height: `${window.innerHeight * 0.8}px`,
                  width: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "selectPlatformsPayloadsApproved",
  "selectTokenKeyCloakId",
  "selectPlatformsItems",
  "selectPlatformsByRoute",
  "selectOrgsByRoute",
  "doPlatformsPut",
  "doDialogOpen",
  "doDialogClose",
  "selectAircraftConfigurationItems",
  "doAircraftConfigurationSave",
  "selectPlatformsGcsApproved",
  Platforms
);
