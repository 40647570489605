import { createSelector } from "redux-bundler";
import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "platformsPayloads",
  uid: "slug",
  initialData: {
    _isSaving: false,
  },
  prefetch: true,
  staleAfter: 30000,
  persist: false,
  routeParam: "platformsSlug",
  getTemplate: "/platformsPayloads",
  putTemplate: "/platformsPayloads/:item.id",
  postTemplate: "/platformsPayloads",
  deleteTemplate: "/platformsPayloads/:item.id",
  fetchActions: [
    "AUTH_LOGGED_IN",
    "PLATFORMSPAYLOADS_SAVE_FINISHED",
    "FILE_IO_UPLOAD_FINISHED",
    "FILE_IO_DELETE_FINISHED",
  ],
  addons: {
    selectPlatformsPayloadsApproved: createSelector(
      "selectPlatformsPayloadsItems",
      (platformsPayloadsItems) => platformsPayloadsItems.filter(item => item.usace_approved)
    ),
    // Need this function because we are saving a platform from the /platforms page, where we can't get the platform ID from the URL slug
    doPlatformsPayloadsPut:
      (item, callback, deferCallback) =>
        ({ dispatch, store, apiPut }) => {
          let platform = item;
          delete platform.s3_key;
          delete platform.filename;
          dispatch({
            type: "PLATFORMSPAYLOADS_SAVE_STARTED",
            payload: { _isSaving: true },
          });
          apiPut(`/platformsPayloads/${item.id}`, item, (err, response, body) => {
            if (err || response.statusCode !== 200) {
              dispatch({
                type: "PLATFORMS_SAVE_ERROR",
                payload: {
                  _err: { err: err, response: response },
                  notification: {
                    statusCode: response.statusCode,
                  },
                  _isSaving: false,
                },
              });
            } else {
              // Make sure we're sending save_finished when we're done
              dispatch({
                type: "PLATFORMSPAYLOADS_SAVE_FINISHED",
                payload: {
                  _isSaving: false,
                },
              });

              if (deferCallback && callback) callback();
            }
            // if we get a callback, go ahead and fire it
            if (!deferCallback && callback) callback();
          });
        },
  },
});
