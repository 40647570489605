import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";
import Loader from "../../app-components/loader";

const MemberItem = ({ text, value, onClick }) => {
  return (
    <div
      onClick={() => {
        if (onClick) onClick(value);
      }}
      className="dropdown-item"
    >
      {text}
    </div>
  );
};

class MemberDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    const { doOrgUsersFetch } = this.props;
    doOrgUsersFetch();
  }

  toggleShow() {
    this.setState({
      show: !this.state.show
    });
  }

  handleSelect(item) {
    const { onSelect } = this.props;
    this.setState(
      {
        show: false
      },
      () => {
        onSelect(item);
      }
    );
  }

  renderOptions() {
    const { show } = this.state;
    if (!show) return null;
    const {
      orgUsersIsLoading,
      orgUsersItems: items,
      personnelItems
    } = this.props;
    if (orgUsersIsLoading) {
      return (
        <div className="dropdown-item">
          <Loader />
        </div>
      );
    } else {
      const currentPersonnel = personnelItems.map(p => {
        return p.edipi;
      });
      const filtered = items.filter(item => {
        return currentPersonnel.indexOf(item.userNumber) === -1;
      });
      return (
        <>
          {filtered.map((item, i) => {
            return (
              <MemberItem
                key={i}
                text={item.userName}
                value={item}
                onClick={this.handleSelect}
              />
            );
          })}
        </>
      );
    }
  }

  render() {
    const { show } = this.state;
    const grpCls = classnames({
      "btn-group": true,
      "mr-2": true,
      show: show
    });
    const dropdownCls = classnames({
      "dropdown-menu": true,
      "dropdown-menu-right": true,
      show: show
    });
    return (
      <div className={grpCls}>
        <button
          className="btn btn-outline-primary dropdown-toggle"
          onClick={this.toggleShow}
        >
          Import Member
        </button>
        <div
          onClick={this.toggleShow}
          style={{
            display: show ? "block" : "none",
            position: "fixed",
            zIndex: 999,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          }}
        ></div>
        <div
          className={dropdownCls}
          x-placement="bottom-start"
          style={{
            position: "absolute",
            willChange: "transform",
            top: "0px",
            right: "0px",
            transform: "translate3d(0px, 36px, 0px)"
          }}
        >
          {this.renderOptions()}
        </div>
      </div>
    );
  }
}

export default connect(
  "doOrgUsersFetch",
  "selectOrgUsersIsLoading",
  "selectOrgUsersItems",
  "selectPersonnelItems",
  MemberDropdown
);
