import React from "react";
import { connect } from "redux-bundler-react";
import AddNewSelector from "../add-new-selector";
import { find } from "lodash";

class FormGroup extends React.Component {
  constructor(props) {
    super(props);
    const { aircraft } = this.props;
    const initialState = {};
    aircraft.forEach((a) => {
      initialState[a.id] = true;
    });
    this.state = initialState;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { aircraft } = this.props;
    const { aircraft: prevAircraft } = prevProps;
    let currentState = this.state;
    let updateState = false; //don't want to setState in update, will cause inf loop. only update if prevaircraft is different than current.
    prevAircraft.forEach((prev) => {
      if (!find(aircraft, { id: prev.id })) {
        updateState = true;
        delete currentState[prev.id];
      }
    });
    if (updateState) this.setState({ ...currentState });
  }

  handleChange(e) {
    const { onChange, domainsFMC: domain } = this.props;
    const id = e.target.value;
    const checked = e.target.checked;
    this.setState(
      {
        [id]: checked,
      },
      () => {
        const items = [];
        Object.keys(this.state).forEach((key) => {
          if (this.state[key]) {
            items.push({
              id: key,
              availability_type_id: domain.id,
              faults_damage: null,
              corrective_action: null,
            });
          }
        });
        onChange(items);
      }
    );
  }

  render() {
    const {
      missionsAircraftItems: items,
      disabled,
      doMissionsAircraftSave,
      doMissionsAircraftFetch,
      aircraftItems,
      missionsAircraftApproved,
      missionsAircraftAdjudicated,
      missionsConfigurationsActive,
      aircraftItemsNotInConfiguration,
    } = this.props;
    // if there is a selected configuration, then use missionConfigurations otherwise use missionsAircraft
    let approvedAircraft = missionsConfigurationsActive
      ? missionsConfigurationsActive["Aircraft"]
        ? missionsConfigurationsActive["Aircraft"].filter(
            (item) =>
              !find(missionsAircraftAdjudicated, { id: item.equipment_id })
          )
        : []
      : missionsAircraftApproved;
    return (
      <div className="form-group row">
        <label className="col-md-3 col-form-label text-right">Aircraft</label>
        <div className="col-md-9 col-form-label">
          {/*Approved Items here */}
          {approvedAircraft.map((item, i) => {
            return (
              <div key={i} className="form-check checkbox col-sm-4">
                <label className="form-check-label">
                  <input
                    onChange={this.handleChange}
                    checked={
                      !!this.state[
                        missionsConfigurationsActive
                          ? item.equipment_id
                          : item.id
                      ]
                    }
                    className="form-check-input"
                    type="checkbox"
                    value={
                      missionsConfigurationsActive ? item.equipment_id : item.id
                    }
                    disabled={disabled}
                  />
                  {missionsConfigurationsActive
                    ? item.equipment_name
                    : item.name}
                </label>
              </div>
            );
          })}
          {/*Adjudicated Items here */}
          <div
            className={`w-100 ${
              missionsAircraftAdjudicated.length > 0 ? `border-top` : ""
            } my-2`}
          >
            {missionsAircraftAdjudicated.length > 0 && (
              <p className="text-muted mb-1">
                <small>Added post approval:</small>
              </p>
            )}
            {missionsAircraftAdjudicated.map((item, i) => {
              return (
                <div key={i} className="form-check checkbox col-sm-4">
                  <label className="form-check-label">
                    <input
                      onChange={this.handleChange}
                      checked={!!this.state[item.id]}
                      className="form-check-input"
                      type="checkbox"
                      value={item.id}
                      disabled={disabled}
                    />
                    {item.name}
                  </label>
                </div>
              );
            })}
          </div>
          <AddNewSelector
            doSave={doMissionsAircraftSave}
            doFetch={doMissionsAircraftFetch}
            disabled={disabled}
            items={
              missionsConfigurationsActive
                ? aircraftItemsNotInConfiguration
                : aircraftItems
            }
            ignoreItems={items}
            equipmentType="aircraft_ids"
          />
        </div>
      </div>
    );
  }
}

export default connect(
  "selectMissionsAircraftItems",
  "selectDomainsFMC",
  "doMissionsAircraftSave",
  "doMissionsAircraftFetch",
  "selectAircraftItems",
  "selectMissionsAircraftApproved",
  "selectMissionsAircraftAdjudicated",
  "selectMissionsConfigurationsActive",
  "doMissionsConfigurationsSave",
  "selectAircraftItemsNotInConfiguration",
  "doMissionsConfigurationsFetch",
  "doMissionsConfigurationsFetchTable",
  FormGroup
);
