import React from "react";
import { connect } from "redux-bundler-react";
import { isEmpty } from "lodash";
import { batteries } from "../../models";
import Loader from "../../app-components/loader";
import DeleteButton from "../_shared/delete-button/delete-button";
import SchemaForm from "../../app-components/schema-form/schema-form";
import PlatformCard from "../_shared/platforms-card/platforms-card";
import MissionsCard from "../_shared/missions-card/missions-card";
import FileUploadCard from "../_shared/file-upload-card/file-upload-card";
import RoleFilter from "../../app-containers/context-providers/role-filter";
import ReadOnly from "../_shared/read-only-notification";
import NotesCard from "../_shared/notes-card/note-card";

class BatteryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.save = this.save.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.enableEditing = this.enableEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  save() {
    const { doBatteriesSave } = this.props;
    if (this.form.isValid()) {
      const data = this.form.serialize();
      this.setState(
        {
          editing: false,
        },
        () => {
          doBatteriesSave(data);
        }
      );
    }
  }

  enableEditing() {
    this.setState({
      editing: true,
    });
  }

  cancelEditing() {
    const { batteriesByRoute } = this.props;
    this.setState(
      {
        editing: false,
      },
      () => {
        this.form.reset({ data: batteriesByRoute, schema: batteries });
      }
    );
  }

  handleDelete() {
    const {
      doBatteriesDelete,
      doUpdateRelativeUrl,
      routeParams,
      batteriesByRoute,
    } = this.props;
    this.setState(
      {
        editing: false,
      },
      () => {
        doBatteriesDelete(batteriesByRoute, () => {
          doUpdateRelativeUrl(`/${routeParams.orgSlug}/batteries`);
        });
      }
    );
  }

  renderForm() {
    const { editing } = this.state;
    const { batteriesByRoute } = this.props;
    if (isEmpty(batteriesByRoute)) return <Loader />;
    return (
      <SchemaForm
        ref={(el) => {
          this.form = el;
        }}
        inline={true}
        displayOnly={!editing}
        schema={batteries}
        data={batteriesByRoute}
      />
    );
  }

  renderActions() {
    const { editing } = this.state;
    if (!editing) return null;
    return (
      <div>
        <div className="float-right">
          <button
            onClick={this.cancelEditing}
            className="btn btn-sm btn-secondary mr-2"
          >
            Cancel
          </button>
          <button onClick={this.save} className="btn btn-sm btn-success">
            Save
          </button>
        </div>
        <DeleteButton onDelete={this.handleDelete} />
      </div>
    );
  }

  render() {
    const { editing } = this.state;
    const {
      batteriesByRoute,
      batteriesPlatformsItems,
      batteriesPlatformsIsLoading,
      doBatteriesPlatformsSave,
      doBatteriesPlatformsDelete,
      batteriesDocsItems,
      batteriesDocsIsLoading,
      batteriesMissionsItems,
      batteriesMissionsIsLoading,
      doFileIoUpload,
      doFileIoDelete,
    } = this.props;
    return (
      <div className="container mt-3">
        <div className="row">
          <div className="col-sm-7">
            <div className="card">
              <div className="card-header">
                <div className="float-right">
                  <RoleFilter
                    allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
                    alt={ReadOnly}
                  >
                    <button
                      disabled={editing}
                      className="btn btn-sm btn-ghost-primary"
                      onClick={this.enableEditing}
                    >
                      <i className="mdi mdi-pencil icon-inline"></i>
                      Edit
                    </button>
                  </RoleFilter>
                </div>
                <i className="mdi mdi-car-battery"></i> Battery Details
              </div>
              <div className="card-body">
                {this.renderForm()}
                {this.renderActions()}
              </div>
            </div>
          </div>
          <div className="col-sm-5">
            {/* <PlatformCard
              allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
              isLoading={batteriesPlatformsIsLoading}
              items={batteriesPlatformsItems}
              onAdd={doBatteriesPlatformsSave}
              onRemove={doBatteriesPlatformsDelete}
            /> */}
            <FileUploadCard
              allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
              isLoading={batteriesDocsIsLoading}
              items={batteriesDocsItems}
              header={true}
              searchable={false}
              onUpload={doFileIoUpload}
              onRemove={doFileIoDelete}
              rel="battery_docs"
              data={{
                battery_id: batteriesByRoute ? batteriesByRoute.id : null,
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <MissionsCard
              isLoading={batteriesMissionsIsLoading}
              items={batteriesMissionsItems}
            />
            <NotesCard />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "doFileIoUpload",
  "doFileIoDelete",
  "doBatteriesSave",
  "doBatteriesDelete",
  "doBatteriesPlatformsSave",
  "doBatteriesPlatformsDelete",
  "selectRouteParams",
  "selectBatteriesByRoute",
  "selectBatteriesPlatformsItems",
  "selectBatteriesPlatformsIsLoading",
  "selectBatteriesDocsItems",
  "selectBatteriesDocsIsLoading",
  "selectBatteriesMissionsItems",
  "selectBatteriesMissionsIsLoading",
  BatteryDetails
);
