import React from "react";
import { connect } from "redux-bundler-react";
import Grid from "../../_shared/grid";
import { customComparator } from "../../_shared/helper";

export default connect(
  "selectBatteriesReportItems",
  "selectBatteriesReportIsLoading",
  ({ batteriesReportItems: data, batteriesReportIsLoading }) => {
    const config = {
      columnDefs: [
        {
          resizable: true,
          sortable: true,
          headerName: "Org",
          field: "org_name",
          comparator: customComparator
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Name",
          field: "name",
          comparator: customComparator
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Make",
          field: "make",
          comparator: customComparator
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Model",
          field: "model",
          comparator: customComparator
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Battery Type",
          field: "battery_type",
          comparator: customComparator
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Serial No.",
          field: "serial_no",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Operational",
          field: "operational_id",
          comparator: customComparator
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Service Entry Date",
          field: "service_entry_date",
          valueFormatter: (cell) => {
            if (!cell.value) return null;
            const d = new Date(cell.value);
            return d.toLocaleDateString();
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Last Flight",
          field: "last_flight_date",
          valueFormatter: (cell) => {
            if (!cell.value) return null;
            const d = new Date(cell.value);
            return d.toLocaleDateString();
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Total Flight Minutes",
          field: "total_flight_minutes",
        },
      ],
    };
    return (
      <Grid
        isLoading={batteriesReportIsLoading}
        config={config}
        data={data}
        style={{ height: `${window.innerHeight * 0.8}px`, width: "100%" }}
      />
    );
  }
);
