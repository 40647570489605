import { createSelector } from "redux-bundler";
import async from "async";
import React from "react";
import ReactDOM from "react-dom";
import centroid from "@turf/centroid";
import Popup from "../modules/_shared/map/map-popup";
import { transform } from "ol/proj";
import Overlay from "ol/Overlay";

const identify = {
  overlays: []
};

export default {
  name: "identify",

  getReducer: () => {
    const initialState = {
      e: null,
      results: null,
      layer: null,
      listeners: [],
      shouldInitialize: false,
      shouldAddFeatures: false,
      shouldRenderResults: false
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case "IDENTIFY_INITIALIZE_STARTED":
        case "IDENTIFY_INITIALIZE_FINISHED":
        case "IDENTIFY_FIRED_STARTED":
        case "IDENTIFY_FIRED_FINISHED":
        case "IDENTIFY_ADD_FEATURES_STARTED":
        case "IDENTIFY_ADD_FEATURES_FINISHED":
        case "IDENTIFY_REGISTERED":
        case "IDENTIFY_UI_RESULTS_DISPLAYED":
        case "IDENTIFY_PAUSE_STARTED":
        case "IDENTIFY_PAUSE_FINISHED":
          return Object.assign({}, state, payload);
        case "IDENTIFY_RESULTS_FOUND":
          return Object.assign({}, state, payload, { shouldAddFeatures: true });
        case "MAP_INITIALIZED":
        case "MAP_INTERACTIONS_CLEARED":
          return Object.assign({}, state, { shouldInitialize: true });
        case "MAP_INTERACTIONS_ADDED":
          return Object.assign({}, state, { shouldPause: true });
        default:
          return state;
      }
    };
  },

  doIdentifyRegister: listener => ({ dispatch, store }) => {
    const listeners = store.selectIdentifyListeners();
    dispatch({
      type: "IDENTIFY_REGISTERED",
      payload: { listeners: [listener, ...listeners] }
    });
  },

  doIdentifyHandleClick: e => ({ dispatch, store }) => {
    dispatch({ type: "IDENTIFY_FIRED_STARTED", payload: { e: e } });
    const listeners = store.selectIdentifyListeners();
    // make a copy of the existing overlays array so that we can remove
    // them async-like and forfeit the race condition
    const oldOverlays = [...identify.overlays];
    store.doClearBulkOverlays(oldOverlays);

    async.parallel(listeners, (err, results) => {
      if (err) return console.log("Identify error: ", err);
      dispatch({
        type: "IDENTIFY_FIRED_FINISHED",
        payload: {
          results: [].concat(...results),
          shouldRenderResults: true
        }
      });
    });
  },

  doIdentifyInitialize: () => ({ dispatch, store }) => {
    dispatch({
      type: "IDENTIFY_INITIALIZE_STARTED",
      payload: { shouldInitialize: false }
    });
    const map = store.selectMap();
    map.on("click", store.doIdentifyHandleClick);
    dispatch({ type: "IDENTIFY_INITIALIZE_FINISHED", payload: {} });
  },

  doIdentifyPause: () => ({ dispatch, store }) => {
    dispatch({
      type: "IDENTIFY_PAUSE_STARTED",
      payload: { shouldPause: false }
    });
    const map = store.selectMap();
    map.un("click", store.doIdentifyHandleClick);
    dispatch({ type: "IDENTIFY_PAUSE_FINISHED", payload: {} });
  },

  doIdentifyRenderResults: () => ({ dispatch, store }) => {
    dispatch({
      type: "IDENTIFY_UI_RESULTS_DISPLAYED",
      payload: { shouldRenderResults: false }
    });
    const map = store.selectMap();
    const results = store.selectIdentifyResults();

    results.forEach(fc => {
      if (fc && fc.features && fc.features.length) {
        fc.features.forEach(feature => {
          const el = document.createElement("div");
          const overlay = new Overlay({
            element: el,
            positioning: "bottom-center",
            offset: [0, -10]
          });
          map.addOverlay(overlay);
          ReactDOM.render(
            <Popup
              overlay={overlay}
              feature={feature}
              map={map}
              onClear={store.doClearOverlay}
            />,
            el
          );

          const center = centroid(feature);
          overlay.setPosition(
            transform(center.geometry.coordinates, "EPSG:4326", "EPSG:3857")
          );
          identify.overlays.push(overlay);
        });
      }
    });
  },

  doClearOverlay: overlay => ({ store }) => {
    const map = store.selectMap();
    if (identify.overlays && identify.overlays.length) {
      const idx = identify.overlays.indexOf(overlay);
      overlay.setPosition(undefined);
      const el = overlay.getElement();
      el.parentNode.removeChild(el);
      map.removeOverlay(overlay);
      identify.overlays.splice(idx, 1);
    }
  },

  doClearBulkOverlays: overlays => ({ store }) => {
    overlays.forEach(store.doClearOverlay);
  },

  selectIdentifyListeners: state => {
    return state.identify.listeners;
  },

  selectIdentifyEvent: state => {
    return state.identify.e;
  },

  selectIdentifyResults: state => {
    return state.identify.results;
  },

  selectIdentifyLayer: state => {
    return state.identify.layer;
  },

  selectIdentifyLayerFeatures: createSelector(
    "selectAppTime",
    "selectIdentifyLayer",
    (time, layer) => {
      if (!layer) return null;
      return layer.getSource().getFeatures();
    }
  ),

  reactIdentifyShouldInitialize: state => {
    if (state.identify.shouldInitialize)
      return { actionCreator: "doIdentifyInitialize" };
  },

  reactIdentifyShouldRenderResults: state => {
    if (state.identify.shouldRenderResults)
      return { actionCreator: "doIdentifyRenderResults" };
  },

  reactIdentifyShouldPause: state => {
    if (state.identify.shouldPause) return { actionCreator: "doIdentifyPause" };
  }
};
