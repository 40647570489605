import React from "react";
import { connect } from "redux-bundler-react";
import { find, sortBy } from "lodash";

class HazardTableRow extends React.Component {
  constructor(props) {
    super(props);
    const { editItem, missionsRawItems } = props;
    const raw = missionsRawItems[0];
    this.state = Object.assign(
      {},
      {
        id: null,
        raw_id: raw.id,
        mission_task_id: null,
        hazard_name: "",
        initial_risk_id: null,
        control: "",
        who: "",
        how: "",
        final_risk_id: null,
        shouldVerifyDelete: false,
        isDeleting: false
      },
      editItem
    );
    this.updateItem = this.updateItem.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
    this.save = this.save.bind(this);
    this.renderActionButtons = this.renderActionButtons.bind(this);
    this.shouldDelete = this.shouldDelete.bind(this);
    this.stopDelete = this.stopDelete.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentWillUnmount() {
    const { onStopEditing } = this.props;
    onStopEditing();
  }

  save() {
    const {
      editItem,
      doMissionsRawHazardsSave,
      onStopEditing,
      missionsRawItems
    } = this.props;
    // hack to get around the constructor not having the current raw item available yet
    const raw = missionsRawItems[0];
    Object.keys(editItem).forEach(key => {
      if (this.state.hasOwnProperty(key)) editItem[key] = this.state[key];
    });
    editItem.raw_id = raw.id;
    doMissionsRawHazardsSave(editItem);
    onStopEditing();
  }

  delete() {
    const { doMissionsRawHazardsDelete, editItem } = this.props;
    this.setState(
      {
        isDeleting: true
      },
      () => {
        doMissionsRawHazardsDelete(editItem);
      }
    );
  }

  updateItem(e) {
    const { domainsItems: domains } = this.props;
    const key = e.currentTarget.dataset.key;
    const val = e.currentTarget.value;
    const newState = {
      [key]: val
    };
    if (key.indexOf("_id") !== -1) {
      const d = find(domains, { id: val });
      const valKey = key.substr(0, key.length - 3);
      newState[valKey] = d.val;
    }
    this.setState(newState);
  }

  shouldDelete() {
    this.setState({
      shouldVerifyDelete: true
    });
  }

  stopDelete() {
    this.setState({
      shouldVerifyDelete: false
    });
  }

  renderOptions(grp) {
    const { domainsItems: domains } = this.props;
    const grpDomains = domains.filter(d => {
      return d.grp === grp;
    });
    const sorted = sortBy(grpDomains, ["display_order"]);
    return sorted.map(d => {
      return (
        <option key={d.id} value={d.id}>
          {d.val}
        </option>
      );
    });
  }

  renderActionButtons() {
    const { shouldVerifyDelete, isDeleting } = this.state;
    const { onStopEditing, editItem } = this.props;
    if (shouldVerifyDelete) {
      return (
        <div className="btn-group w-100">
          <button
            onClick={this.delete}
            disabled={isDeleting}
            className="btn btn-sm btn-ghost-danger"
            title="Confirm Delete"
          >
            <i className="mdi mdi-delete-forever"></i>
          </button>
          <button
            onClick={this.stopDelete}
            disabled={isDeleting}
            className="btn btn-sm btn-ghost-secondary"
            title="Cancel"
          >
            <i className="mdi mdi-cancel"></i>
          </button>
        </div>
      );
    } else {
      return (
        <div className="btn-group w-100">
          <button
            onClick={this.save}
            className="btn btn-sm btn-ghost-primary"
            title="Save"
          >
            <i className="mdi mdi-content-save"></i>
          </button>
          <button
            onClick={onStopEditing}
            className="btn btn-sm btn-ghost-secondary"
            title="Cancel"
          >
            <i className="mdi mdi-cancel"></i>
          </button>
          <button
            onClick={this.shouldDelete}
            disabled={!editItem.id}
            className="btn btn-sm btn-ghost-danger"
            title="Delete"
          >
            <i className="mdi mdi-delete-forever"></i>
          </button>
        </div>
      );
    }
  }

  render() {
    const {
      mission_task_id,
      hazard_name,
      initial_risk_id,
      control,
      who,
      how,
      final_risk_id
    } = this.state;
    return (
      <tr>
        <td>
          <select
            className="form-control"
            data-key="mission_task_id"
            value={mission_task_id || ""}
            onChange={this.updateItem}
          >
            <option value="" disabled hidden>
              Please Choose...
            </option>
            {this.renderOptions("mission_task")}
          </select>
        </td>
        <td>
          <input
            type="text"
            className="form-control"
            data-key="hazard_name"
            value={hazard_name}
            onChange={this.updateItem}
          ></input>
        </td>
        <td>
          <select
            className="form-control"
            data-key="initial_risk_id"
            value={initial_risk_id || ""}
            onChange={this.updateItem}
          >
            <option value="" disabled hidden>
              Please Choose...
            </option>
            {this.renderOptions("risk")}
          </select>
        </td>
        <td>
          <input
            type="text"
            className="form-control"
            data-key="control"
            value={control}
            onChange={this.updateItem}
          ></input>
        </td>
        <td>
          <input
            type="text"
            className="form-control"
            data-key="how"
            value={how}
            onChange={this.updateItem}
          ></input>
        </td>
        <td>
          <input
            type="text"
            className="form-control"
            data-key="who"
            value={who}
            onChange={this.updateItem}
          ></input>
        </td>
        <td>
          <select
            className="form-control"
            data-key="final_risk_id"
            value={final_risk_id || ""}
            onChange={this.updateItem}
          >
            <option value="" disabled hidden>
              Please Choose...
            </option>
            {this.renderOptions("risk")}
          </select>
        </td>
        <td>{this.renderActionButtons()}</td>
      </tr>
    );
  }
}

export default connect(
  "selectDomainsItems",
  "selectPathname",
  "selectMissionsRawItems",
  "doMissionsRawHazardsSave",
  "doMissionsRawHazardsDelete",
  HazardTableRow
);
