import React from "react";

const displayProperties = ["layer", "name", "phone", "url", "icao"];

class IdentifyPopup extends React.Component {
  constructor(props) {
    super(props);
    this.overlay = props.overlay;
    this.handleClose = this.handleClose.bind(this);
    this.renderContents = this.renderContents.bind(this);
  }

  componentDidMount() {
    // this.dragHandle.addEventListener('mousedown', this.startDrag);
    this.closeButton.addEventListener("click", this.handleClose);
  }

  componentWillUnmount() {
    // this.dragHandle.removeEventListener('mousedown', this.startDrag);
    this.closeButton.removeEventListener("click", this.handleClose);
  }

  handleClose() {
    const { onClear, overlay } = this.props;
    onClear(overlay);
  }

  renderContents() {
    const { feature } = this.props;
    const { properties } = feature;
    const rows = [];

    displayProperties.forEach((prop, i) => {
      if (properties.hasOwnProperty(prop)) {
        if (prop === "layer") {
          return rows.push(
            <div key={i}>
              <strong>
                {`${properties[prop].toUpperCase().replace("_", " ")} ${
                  properties.hasOwnProperty("icao")
                    ? ` - ${properties.icao}`
                    : ""
                }`}
              </strong>
            </div>
          );
        }

        if (prop === "url") {
          return rows.push(
            <div className="pt-1" key={i}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={properties[prop]}
              >
                {properties[prop]}
              </a>
            </div>
          );
        }

        if (prop === "icao") return;

        return rows.push(
          <div className="pt-1" key={i}>
            {properties[prop]}
          </div>
        );
      }
    });

    return rows;
  }

  render() {
    return (
      <div
        className="card top"
        style={{
          marginBottom: 0,
          marginTop: "-10px",
          fontSize: "0.6rem",
          maxWidth: "350px"
        }}
      >
        <div className="d-flex flex-row justify-content-between">
          <span
            className="pointer"
            ref={el => {
              this.closeButton = el;
            }}
          >
            <i className="mdi mdi-close"></i>
          </span>
        </div>
        <div className="arrow" style={{ left: "50%" }}></div>
        <div className="card-body" style={{ padding: "0.5rem", paddingTop: 0 }}>
          {this.renderContents()}
        </div>
      </div>
    );
  }
}

export default IdentifyPopup;

// {Object.keys(feature.properties).map((key, i) => {
//   return (
//     <div
//       className="row"
//       key={i}
//       style={{
//         margin: "3px",
//         padding: "2px",
//         maxWidth: "500px"
//       }}
//     >
//       <span
//         style={{
//           minWidth: "100px"
//         }}
//         className="col-3 overflow-ellipsis"
//         title={key}
//       >
//         {key}
//       </span>
//       {this.renderValue(feature.properties[key])}
//     </div>
//   );
// })}
