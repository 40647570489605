import React from "react";
import classnames from "classnames";
import ComponentTree from "../component-tree";
import "../documentation.css";
const ListGroup = ({ className, childComponents=[] }) => {
  let clsName = classnames({
    "list-group": true,
    [className]: className
  });
  return (
    <ul className={clsName}>
      {
        childComponents.map((item, idx) => {
          return (
            <li className="list-group-item" key={idx}>
              <ComponentTree obj={item} />
            </li>
          );
        })
      }
    </ul>
  );
}
export default ListGroup;