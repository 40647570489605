import React, { useState } from "react";
import { connect } from "redux-bundler-react";

export default connect(
  "doTransfersDelete",
  "selectTransfersByRoute",
  ({ doTransfersDelete, transfersByRoute: transfer }) => {
    // only let them delete transfers if it hasn't been sent yet
    if (transfer && transfer.from_status !== "In-Progress") return null;

    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
      setExpanded(!expanded);
    };

    return (
      <div className="card border-danger">
        <div className="card-header">
          <span className="float-right">
            <button
              onClick={toggleExpanded}
              className="btn btn-sm btn-outline-danger"
            >
              <i
                className={`mdi mdi-${
                  expanded ? "chevron-up" : "chevron-down"
                }`}
              ></i>
            </button>
          </span>
          Danger Zone
        </div>
        {expanded ? (
          <div
            onClick={() => {
              doTransfersDelete(transfer, () => {
                window.history.back();
              });
            }}
            className="card-body"
          >
            <button className="btn btn-danger">Delete Transfer</button>
          </div>
        ) : null}
      </div>
    );
  }
);
