import React from "react";
import { connect } from "redux-bundler-react";
import Breadcrumbs from "./flight-breadcrumbs/breadcrumb-section";

class Flights extends React.Component {
  constructor(props) {
    super(props);
    this.renderInnerComponent = this.renderInnerComponent.bind(this);
    this.updatePath = this.updatePath.bind(this);
  }

  componentDidMount() {
    this.props.doMissionsConfigurationsFetch();
    this.props.doMissionsConfigurationsFetchTable();
  }

  componentWillUnmount() {
    this.updatePath("/");
  }

  updatePath(p) {
    const { doFlightsTabRouterUpdatePath } = this.props;
    doFlightsTabRouterUpdatePath(p);
  }

  renderInnerComponent() {
    const { flightsTabRouterComponent, flightsTabRouterParams } = this.props;
    const Comp = flightsTabRouterComponent;
    const params = flightsTabRouterParams;
    return <Comp {...params} doUpdateInnerRoute={this.updatePath} />;
  }

  render() {
    return (
      <div>
        <Breadcrumbs doUpdateInnerRoute={this.updatePath} />
        {this.renderInnerComponent()}
      </div>
    );
  }
}

export default connect(
  "selectFlightsTabRouterComponent",
  "selectFlightsTabRouterParams",
  "doFlightsTabRouterUpdatePath",
  "doMissionsConfigurationsFetch",
  "doMissionsConfigurationsFetchTable",
  Flights
);
