import React from "react";
import JobNewForm from "./job-new/job-new-form";
import { connect } from "redux-bundler-react";
import DeleteDialog from "../_shared/item-board/delete-dialog";
import { conforms, isEmpty, constant } from "lodash";
export const loadDataToNewForm = async (dispatchFn, data) => {
  await dispatchFn({
    _changesMade: false,
    ...data,
    startDate:
      data.date_start && new Date(data.date_start) > new Date()
        ? new Date(data.date_start)
        : null,
    endDate:
      data.date_end && new Date(data.date_start) > new Date()
        ? new Date(data.date_end)
        : null,
    datesTentative: data.date_start_tentative,
    tags: data.tags ? data.tags.split("~") : [],
  });
};

const JobItemEditor = ({
  state,
  setState,
  doJobsSave,
  doJobsDelete,
  doDialogOpen,
  doUpdateRelativeUrl,
}) => {
  const { jobData } = state;
  const deleteBtnClicked = async (e) => {
    let deleteText =
      "Are you sure? Deleting this job will remove it from the job board. Comments will not be accessible.";
    doDialogOpen({
      content: DeleteDialog,
      props: {
        data: { ...jobData },
        dataType: "job",
        deleteType: "permanentDelete",
        deleteText: deleteText,
        title: "Deleting Job Permanently",
        sendFunc: doJobsSave,
        deleteFunc: doJobsDelete,
        returnUrl: "/job-board",
      },
    });
  };
  let newFormActions = [];
  if (jobData.published) {
    newFormActions = [
      {
        text: "Delete",
        className: "btn btn-danger mr-2",
        name: "delete",
        onAction: () => deleteBtnClicked(),
      },
      {
        text: "Cancel",
        className: "btn btn-secondary mr-2",
        name: "cancel",
        disabled: [
          [conforms({ _changesMade: (item) => item === true }), constant(true)],
        ],
        onAction: () => setState({ ...state, editingMode: false }),
      },
      {
        text: "Save",
        className: "btn btn-primary mr-2",
        name: "save",
        disabled: [
          [
            conforms({
              sectionsReady: (item) => Object.values(item).some((val) => !val),
            }),
            constant(true),
          ],
        ],
        onAction: () => setState({ ...state, editingMode: false }),
      },
    ];
  } else {
    newFormActions = [
      {
        text: "Delete",
        className: "btn btn-danger mr-2",
        name: "cancel",
        onAction: () => deleteBtnClicked(),
      },
      {
        text: "Save as Draft",
        className: "btn btn-primary mr-2",
        name: "draft",
        disabled: [[conforms({ title: isEmpty }), constant(true)]],
      },
      {
        text: "Post",
        className: "btn btn-success ml-2",
        name: "publish",
        disabled: [
          [
            conforms({
              sectionsReady: (item) => Object.values(item).some((val) => !val),
            }),
            constant(true),
          ],
        ],
        onAction: () => doUpdateRelativeUrl("/job-board"),
      },
    ];
  }
  return <JobNewForm title="Editing Job" actions={newFormActions} />;
};

export default connect(
  "doJobsSave",
  "doJobsDelete",
  "doDialogOpen",
  "doUpdateRelativeUrl",
  JobItemEditor
);
