import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import VersionList from "./version-list";
import Editor from "./editor";

export default connect(
  "selectReleaseNotesItems",
  ({ releaseNotesItems: notes }) => {
    const [active, setActive] = useState(null);
    return (
      <div className="container-fluid" style={{marginTop: "80px"}}>
        <div className="row">
          <div className="col-3">
            <VersionList notes={notes} active={active} setActive={setActive} />
          </div>
          <div className="col-9">
            {active ? (
              <Editor active={active} setActive={setActive} />
            ) : (
              <div>Choose a version to edit</div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
