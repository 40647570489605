import React from "react";
import jsPDF, { AcroFormTextField } from "jspdf";
import "jspdf-autotable";
import { connect } from "redux-bundler-react";

const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  return `${month}/${day}/${year}`;
};

const PdfGenerator = ({ doFileIoUpload, jsonData }) => {
  const doc = new jsPDF();
  const FORM_Y_START = 20;

  const adjustY = (currentPosition, addedYPosition) => {
    let newPosition = (currentPosition += addedYPosition);
    if (newPosition > doc.internal.pageSize.height - 20) {
      doc.addPage();
      return FORM_Y_START;
    } else return newPosition;
  };
  const generatePdf = () => {
    doc.setFontSize(20);
    let adjustedYPosition = FORM_Y_START;
    doc.text("USACE COA Request Form", 60, adjustedYPosition);
    doc.setFontSize(12);

    // Project Name
    adjustedYPosition = adjustY(adjustedYPosition, 20);
    doc.text("1. Project Name:", 10, adjustedYPosition);
    const projectNameField = new doc.AcroFormTextField();
    projectNameField.fontSize = 12;
    projectNameField.Rect = [45, adjustedYPosition - 5, 120, 10];
    projectNameField.value = jsonData.name;
    projectNameField.name = "projectName";
    doc.addField(projectNameField);

    // Description / Justification
    adjustedYPosition = adjustY(adjustedYPosition, 15);
    doc.text("2. Description / Justification:", 10, adjustedYPosition);
    var textWidth = 180;
    var text =
      "(e.g. “This is a flood mitigation project. Flights will only occur during flooding events to determine limits of flooding and those conditions for hydrographic analysis and design.”)";
    var lines = doc.splitTextToSize(text, textWidth);

    // Draw each line with adjusted font size
    for (var i = 0; i < lines.length; i++) {
      adjustedYPosition = adjustY(adjustedYPosition, 5);
      doc.text(lines[i], 10, adjustedYPosition); // Adjust Y position according to font size
    }
    adjustedYPosition = adjustY(adjustedYPosition, 5);
    const descriptionField = new AcroFormTextField();
    descriptionField.fontSize = 12;
    descriptionField.Rect = [10, adjustedYPosition, 180, 12];
    descriptionField.value = jsonData.description;
    descriptionField.name = "description";
    doc.addField(descriptionField);

    // Organization Requesting Support
    adjustedYPosition = adjustY(adjustedYPosition, 20);
    doc.text("3. Organization Requesting Support:", 10, adjustedYPosition);
    const orgField = new AcroFormTextField();
    orgField.fontSize = 12;
    adjustedYPosition = adjustY(adjustedYPosition, 5);
    orgField.Rect = [10, adjustedYPosition, 180, 10];
    orgField.value = jsonData.org;
    orgField.name = "organization";
    doc.addField(orgField);

    // Is this DoD property or Public Land?
    adjustedYPosition = adjustY(adjustedYPosition, 20);
    doc.text("4. Is this DoD property or Public Land?", 10, adjustedYPosition);
    const propertyYesField = new AcroFormTextField();
    propertyYesField.fontSize = 12;
    propertyYesField.Rect = [100, adjustedYPosition - 5, 15, 10];
    propertyYesField.name = "propertyType";
    if (jsonData.dod_land) propertyYesField.value = "X";
    doc.addField(propertyYesField);
    doc.text("Yes", 90, adjustedYPosition);

    const propertyNoField = new AcroFormTextField();
    propertyNoField.fontSize = 12;
    propertyNoField.Rect = [140, adjustedYPosition - 5, 15, 10];
    propertyNoField.name = "propertyType";
    if (!jsonData.dod_land) propertyNoField.value = "X";
    doc.addField(propertyNoField);
    doc.text("No", 130, adjustedYPosition);
    // DOD If No Text
    adjustedYPosition = adjustY(adjustedYPosition, 5);
    let dodText =
      "(If no, submit a “Land Use Agreement with this form. The DARR / FAA will not process the request without it.)";
    let dodLines = doc.splitTextToSize(dodText, 180);
    for (var i = 0; i < dodLines.length; i++) {
      adjustedYPosition = adjustY(adjustedYPosition, 5);
      doc.text(dodLines[i], 10, adjustedYPosition); // Adjust Y position according to font size
    }
    // Mission Start and End
    adjustedYPosition = adjustY(adjustedYPosition, 10);
    doc.text("5. Start Date:", 10, adjustedYPosition);
    const propertyStartField = new AcroFormTextField();
    propertyStartField.fontSize = 12;
    propertyStartField.Rect = [35, adjustedYPosition - 5, 20, 10];
    propertyStartField.name = "missionStart";
    propertyStartField.value = formatDate(jsonData.date_start);
    doc.addField(propertyStartField);

    const propertyEndField = new AcroFormTextField();
    doc.text("End Date:", 70, adjustedYPosition);
    propertyEndField.fontSize = 12;
    propertyEndField.Rect = [90, adjustedYPosition - 5, 20, 10];
    propertyEndField.value = formatDate(jsonData.date_end);
    propertyEndField.name = "missionEnd";
    doc.addField(propertyEndField);

    // Coordinates
    adjustedYPosition = adjustY(adjustedYPosition, 15);
    doc.text("6. Location:", 10, adjustedYPosition);
    doc.text("Multiple Waypoints (Defining Area):", 10, adjustedYPosition + 5);
    jsonData.coordinates &&
      jsonData.coordinates[0].forEach((coord, index) => {
        adjustedYPosition = adjustY(adjustedYPosition, 15);
        doc.text(`Lat -`, 10, adjustedYPosition);
        const latField = new AcroFormTextField();
        latField.fontSize = 12;
        latField.Rect = [25, adjustedYPosition, 70, 10];
        latField.value = coord[1].toString();
        latField.name = `multiLat${index}`;
        doc.addField(latField);

        doc.text(`Long -`, 100, adjustedYPosition);
        const longField = new AcroFormTextField();
        longField.fontSize = 12;
        longField.Rect = [115, adjustedYPosition, 70, 10];
        longField.value = coord[0].toString();
        longField.name = `multiLong${index}`;
        doc.addField(longField);
      });

    // Altitude (max to be flown)
    adjustedYPosition = adjustY(adjustedYPosition, 20);
    doc.text("7. Altitude (max to be flown):", 10, adjustedYPosition);
    const altitudeField = new AcroFormTextField();
    altitudeField.fontSize = 12;
    adjustedYPosition = adjustY(adjustedYPosition, 5);
    altitudeField.Rect = [10, adjustedYPosition, 180, 10];
    altitudeField.value = jsonData.max_alt.toString();
    altitudeField.name = "altitude";
    doc.addField(altitudeField);

    // sUAS used
    adjustedYPosition = adjustY(adjustedYPosition, 20);
    doc.text("8. sUAS used:", 10, adjustedYPosition);
    jsonData.aircraft.forEach((aircraft, index) => {
      adjustedYPosition = adjustY(adjustedYPosition, 10);
      doc.text(`Make -`, 10, adjustedYPosition);
      const makeField = new AcroFormTextField();
      makeField.fontSize = 12;
      makeField.Rect = [25, adjustedYPosition - 5, 70, 10];
      makeField.value = aircraft.make;
      makeField.name = `make${index}`;
      doc.addField(makeField);

      doc.text(`Model -`, 100, adjustedYPosition);
      const modelField = new AcroFormTextField();
      modelField.fontSize = 12;
      modelField.Rect = [115, adjustedYPosition - 5, 70, 10];
      modelField.value = aircraft.model;
      modelField.name = `model${index}`;
      doc.addField(modelField);

      adjustedYPosition = adjustY(adjustedYPosition, 15);
      doc.text(`S/N -`, 10, adjustedYPosition);
      const snField = new AcroFormTextField();
      snField.fontSize = 12;
      snField.Rect = [25, adjustedYPosition - 5, 70, 10];
      snField.value = aircraft.sn;
      snField.name = `sn${index}`;
      doc.addField(snField);
    });

    // sUAS Crew
    adjustedYPosition = adjustY(adjustedYPosition, 20);
    doc.text("9. sUAS Crew:", 10, adjustedYPosition);
    jsonData.crew.forEach((member, index) => {
      adjustedYPosition = adjustY(adjustedYPosition, 15);
      doc.text(`Name -`, 10, adjustedYPosition);
      const pilotNameField = new AcroFormTextField();
      pilotNameField.fontSize = 12;
      pilotNameField.Rect = [25, adjustedYPosition - 5, 70, 10];
      pilotNameField.value = member.name;
      pilotNameField.name = `pilotName${index}`;
      doc.addField(pilotNameField);

      doc.text(`Email -`, 100, adjustedYPosition);
      const pilotEmailField = new AcroFormTextField();
      pilotEmailField.fontSize = 12;
      pilotEmailField.Rect = [115, adjustedYPosition - 5, 70, 10];
      pilotEmailField.value = member.email;
      pilotEmailField.name = `pilotEmail${index}`;
      doc.addField(pilotEmailField);

      adjustedYPosition = adjustY(adjustedYPosition, 15);
      doc.text(`Ph# -`, 10, adjustedYPosition);
      const pilotPhField = new AcroFormTextField();
      pilotPhField.fontSize = 12;
      pilotPhField.Rect = [25, adjustedYPosition - 5, 70, 10];
      pilotPhField.value = member.phone;
      pilotPhField.name = `pilotPh${index}`;
      doc.addField(pilotPhField);

      // add pilot role
      adjustedYPosition = adjustY(adjustedYPosition, 10);
      doc.text("Flight Role:", 10, adjustedYPosition);
      adjustedYPosition = adjustY(adjustedYPosition, 10);
      const pilotField = new AcroFormTextField();
      pilotField.fontSize = 12;
      pilotField.Rect = [20, adjustedYPosition - 5, 15, 10];
      pilotField.name = "pilotField";
      doc.addField(pilotField);
      doc.text("RP", 10, adjustedYPosition);

      const voField = new AcroFormTextField();
      voField.fontSize = 12;
      voField.Rect = [50, adjustedYPosition - 5, 15, 10];
      voField.name = "voField";
      doc.addField(voField);
      doc.text("VO", 40, adjustedYPosition);
      adjustedYPosition = adjustY(adjustedYPosition, 10);
    });

    doc.save(`${jsonData.name}_USACE.COA.Request.Form.Ver.2.1.pdf`);
  };

  return (
    <button onClick={generatePdf} className="btn btn-lg btn-outline-success">
      <i className="mdi mdi-clipboard mr-2" />
      Generate
    </button>
  );
};

export default connect("doFileIoUpload", PdfGenerator);
