import React from "react";
const iconObj = {
  Aircraft: "mdi mdi-airplane mdi-24px",
  Batteries: "mdi mdi-battery mdi-24px",
  Payloads: "mdi mdi-camera mdi-24px",
  GCS: "ms ms-drone-remote mb-2",
  MMS: "mdi mdi-laptop mdi-24px",
};

const EquipmentCard = ({ title, item }) => {
  return (
    <div className="p-2 d-flex flex-column w-100 border rounded justify-content-center align-items-center">
      <i className={`${iconObj[title]}`} style={{ fontSize: 24 }} />
      <p className={item.name ? "mb-1" : "m-0"} style={{ textAlign: "center" }}>
        {item.label}
      </p>
      {item.name && (
        <p className="text-muted m-0" style={{ textAlign: "center" }}>
          <small>{item.name}</small>
        </p>
      )}
    </div>
  );
};

export default EquipmentCard;
