import React, { useState, useEffect } from "react";
import { connect } from "redux-bundler-react";
import SelectInput from "../../../../../app-components/schema-form/select-input";
import DateInput from "../../../../../app-components/schema-form/date-input";
import Loader from "../../../../../app-components/loader";
import { keyBy, omit, omitBy, uniqueId } from "lodash";

const flightModeOptions = {
    na: { label: "NOT APPLICABLE", name: "na", value: "na" },
    day: { label: "DAY", name: "day", value: "day" },
    night: { label: "NIGHT", name: "night", value: "night" },
    dayBvlos: { label: "DAY - BVLOS", name: "dayBvlos", value: "dayBvlos" },
    movingVehicleDay: { label: "From a moving vehicle - DAY", name: "movingVehicleDay", value: "movingVehicleDay" },
    movingVehicleNight: { label: "From a moving vehicle - NIGHT", name: "movingVehicleNight", value: "movingVehicleNight" },
    boatDay: { label: "From a Boat - DAY", name: "boatDay", value: "boatDay" },
    boatNight: { label: "From a Boat - NIGHT", name: "boatNight", value: "boatNight" }
};

const taskOptions = [
    { label: "APL 95-1-1 Open-Book Exam", name: "apl-open-book", value: "apl-open-book" },
    { label: "LOCAL SOP Open-Book Exam", name: "local-open-book", value: "local-open-book" },
    { label: "Oral Evaluation IAW 95-1-1", name: "oral-eval", value: "oral-eval" },
    { label: "0901 - Perform Mission Analysis", name: "mission-analysis", value: "mission-analysis" },
    { label: "0902 - Plan and Submit an SUAS Mission Request", name: "mission-request", value: "mission-request" },
    { label: "1000 - Participate in a Crew Brief", name: "crew-brief", value: "crew-brief" },
    { label: "1001 - Prepare SUAS for Flight", name: "prepare-flight", value: "prepare-flight" },
    { label: "1002 - Conduct Radio Communications", name: "radio-comm", value: "radio-comm" },
    { label: "1003 - Operate UA in Autonomous Mode", name: "auto-mode", value: "auto-mode" },
    { label: "1004 - Operate UA in Manual Mode", name: "manual-mode", value: "manual-mode" },
    { label: "1005 - Respond to an Emergency", name: "emergency-response", value: "emergency-response" },
    { label: "1006 - Complete Post Flight Procedures", name: "post-flight", value: "post-flight" },
    { label: "1007 - Conduct Operator Level Maintenance", name: "maintenance", value: "maintenance" },
    { label: "1008 - Process Mission Data", name: "process-mission-data", value: "process-mission-data" },
    { label: "4000 - Select a Vantage Point", name: "vantage-point", value: "vantage-point" },
    { label: "4001 - Maintain Airspace Surveillance", name: "surveillance", value: "surveillance" },
    { label: "5000 - Provide Academic Instruction", name: "academic-instruction", value: "academic-instruction" },
    { label: "5001 - Provide New Equipment Training", name: "equipment-training", value: "equipment-training" },
    { label: "5002 - Provide Flight Training", name: "flight-training", value: "flight-training" },
    { label: "5003 - Conduct Crewmember Evaluations", name: "crew-eval", value: "crew-eval" },
    { label: "FREE TEXT ENTRY", name: "FREE TEXT ENTRY", value: "FREE TEXT ENTRY" }
];

const gradeOptions = [
    { label: "SAT", value: "sat", name: "sat" },
    { label: "UNSAT", value: "unsat", name: "unsat" },
    { label: "N/A", value: "na", name: "na" }
];

const GradeSlipEntryTable = ({
    gradeSlipSelectedForm: selectedForm,
    doGradeSlipUpdateState,
    doGradeSlipEntrySave,
    doGradeSlipEntryDelete,
    gradeSlipEntryItemsObjectByForm,
    gradeSlipEditedEntries,
    gradeSlipEntryIsLoading: _isLoading,
    gradeSlipEntryIsSaving: _isSaving,
    gradeSlipEntryShouldFetch: _shouldFetch,
    doGradeSlipUpdateEditedEntries,
    isProfileActiveAtpm,
    disabled
}) => {

    const [state, setState] = useState({
        entryEdits: {},
        entries: {},
        taskOptionsObj: keyBy(taskOptions, 'value'),
        flightModeOptionsObj: keyBy(flightModeOptions, 'value')
    });

    const addEntry = () => {
        let entriesCopy = { ...state.entries };
        let tempId = uniqueId('temp_');
        doGradeSlipUpdateEditedEntries({ ...entriesCopy, [tempId]: { date: '', task: '', grade: '', flight_mode: '', id: tempId, task_variable: null } });
      setState({
        ...state,
        entries: { ...entriesCopy, [tempId]: { date: '', task: '', grade: '', flight_mode: '', id: tempId, task_variable: null } },
        entryEdits: { ...state.entryEdits, [tempId]: { date: '', task: '', grade: '', flight_mode: '', id: tempId, task_variable: null } },
      });
    }
    const onEntryChange = (input) => {
        const { key, attr } = input.property;
        let entriesCopy = { ...state.entries }
        let entry = entriesCopy[key];
        if (entry) {
            doGradeSlipUpdateEditedEntries({ ...entriesCopy, [key]: { ...entry, [attr]: input.value } });
        }
      setState({
        ...state,
        entries: { ...entriesCopy, [key]: { ...entry, [attr]: input.value } },
        entryEdits: { ...state.entryEdits, [key]: { ...entry, [attr]: input.value } }
      });

    }

    const onEntrySave = (e) => {
      let entryId = e.currentTarget.name;
      const { id: gradeSlipId, profile_id } = selectedForm;
      let itemIsTemp = entryId.includes('temp_');
      doGradeSlipEntrySave(Object.assign({}, { ...state.entries[entryId], id: itemIsTemp ? null : entryId, grade_slip_id: gradeSlipId, profile_id: profile_id }), (result) => {
        let newEditedEntries = omit(state.entryEdits, [entryId, result?.id])
        if (result) {
          doGradeSlipUpdateState({
            _entriesLastSave: { ...gradeSlipEntryItemsObjectByForm, [result.id]: { ...result, task_variable: result.task_variable ? result.task_variable : null } },
            _editedEntries: newEditedEntries
          });
        }
        else {
          doGradeSlipUpdateState({
            _entriesLastSave: { ...gradeSlipEntryItemsObjectByForm, [entryId]: { ...state.entries[entryId] } }
          });
        }
        setState({ ...state, entryEdits: newEditedEntries })
      }, true);
    }
    const onEntryDelete = (e) => {
        let entryId = e.currentTarget.name;
        const { id: gradeSlipId, profile_id } = selectedForm;
        if (entryId.includes('temp_')) {
            let entriesCopy = { ...state.entries }
            let newEntriesCopy = omit(entriesCopy, [entryId])
            doGradeSlipUpdateEditedEntries(newEntriesCopy);
            setState({ ...state, entries: newEntriesCopy });
        }
        else {
            doGradeSlipEntryDelete(Object.assign({}, { ...state.entries[entryId], grade_slip_id: gradeSlipId, profile_id: profile_id, deleted: 1 }), () => {
                let newEntriesCopy = omit(gradeSlipEditedEntries, [entryId])
                doGradeSlipUpdateState({ _editedEntries: newEntriesCopy });
            });
        }
    }

    useEffect(() => {
        if (gradeSlipEntryItemsObjectByForm) {
            let entries = omitBy(gradeSlipEntryItemsObjectByForm, (value, key) => !value.id);
            setState({ ...state, entries: { ...entries, ...gradeSlipEditedEntries  } });
        }
    }, [gradeSlipEntryItemsObjectByForm]);

    const renderEntries = () => {
        if (_isLoading || _isSaving || _shouldFetch) {
            return (
                <tr>
                    <td colSpan={isProfileActiveAtpm ? "5" : "4"}>
                        <Loader />
                    </td>
                </tr>
            )
        }
        let entriesCopy = { ...state.entries }

        let sorted = Object.values(entriesCopy).sort((a, b) => {
            if (new Date(a.date) > new Date(b.date)) return 1;
            else if (new Date(a.date) < new Date(b.date)) return -1;
            else return 0
        })
        if (sorted.length === 0) {
            return (
                <tr>
                    <td colSpan={isProfileActiveAtpm ? "5" : "4"}>
                        <div className="container-fluid my-auto h-100 w-100 d-flex justify-content-around py-2">
                            <div className="d-flex justify-content-center" style={{ fontSize: "larger" }}>
                                <i className="mdi mdi-alert-circle-outline mr-2"
                                    style={{ fontSize: "larger", color: "#3b5998", textShadow: "1px 1px 2px rgba(0,0,0,.15)" }}
                                />
                                <div className="text-value-sm my-auto text-center">
                                    Nothing found.
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        }
        return (
            <>
                {
                    sorted.map(row => {
                        let edited = row.id.includes('temp_') || state.entryEdits[row.id];
                        return (
                            <tr key={row.id} style={{ border: edited ? "2px solid #17a2b8" : '' }}>
                                <td>
                                    {edited && <h6 className="position-absolute text-info" style={{ marginTop: "-.75rem", marginLeft: "-.5rem" }}><span className="mdi mdi-alert-circle-outline mr-2" />Unsaved changes</h6>}
                                    <DateInput
                                        value={row.date}
                                        property={{ key: row.id, attr: 'date' }}
                                        schema={{ title: '' }}
                                        onChange={(input) => onEntryChange(input)}
                                        displayOnly={disabled}
                                    />
                                </td>
                                <td>
                                    <SelectInput
                                        value={row.taskIsFreeText ? "FREE TEXT ENTRY" : row.task}
                                        property={{ key: row.id, attr: 'task' }}
                                        options={taskOptions} schema={{ title: "" }}
                                        onChange={(input) => onEntryChange(input)}
                                        displayOnly={disabled}
                                    />
                                    <div className="form-group">
                                        <input className={`form-control ${row.task === "FREE TEXT ENTRY" ? '' : 'd-none'}`} value={row.task_variable || ''} name={row.id} placeholder='Enter FREE TEXT here...'
                                            disabled={disabled}
                                            onChange={(e) => {
                                                onEntryChange({ value: e.currentTarget.value, property: { key: row.id, attr: 'task_variable' } })
                                            }} />
                                    </div>
                                </td>
                                <td>
                                    <SelectInput
                                        value={row.grade} property={{ key: row.id, attr: 'grade' }} options={gradeOptions} schema={{ title: "" }}
                                        onChange={(input) => onEntryChange(input)}
                                        displayOnly={disabled}
                                    />
                                </td>
                                <td>
                                    <SelectInput
                                        value={row.flight_mode} property={{ key: row.id, attr: 'flight_mode' }} options={Object.values(flightModeOptions)} schema={{ title: "" }}
                                        onChange={(input) => onEntryChange(input)}
                                        displayOnly={disabled}
                                    />
                                </td>
                                {isProfileActiveAtpm &&
                                    <td>
                                        <div className="btn-group-vertical">
                                            <button name={`${row.id}`} className="btn btn-outline-primary" onClick={onEntrySave} disabled={disabled || !edited}>
                                                <span className="mdi mdi-content-save" />
                                            </button>
                                            <button name={`${row.id}`} className="btn btn-outline-danger" onClick={onEntryDelete} disabled={disabled}>
                                                <span className="mdi mdi-delete-forever" />
                                            </button>
                                        </div>
                                    </td>

                                }

                            </tr>
                        )
                    })
                }
            </>
        )
    }

    return (
        <table className="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">
                        <div className="d-flex flex-column">
                            <span className="mx-auto">Date</span>
                            <i className="mx-auto" style={{ fontSize: "smaller" }}>(DD-MMM-YY)</i>
                        </div>
                    </th>
                    <th className="text-center align-middle">
                        Evaluated Task(s)
                    </th>
                    <th className="text-center align-middle">
                        Grade
                    </th>
                    <th className="text-center align-middle">
                        Flight Mode
                    </th>
                    {isProfileActiveAtpm && <th style={{ width: "4rem" }}></th>}
                </tr>
            </thead>
            <tbody>
                {renderEntries()}
                {isProfileActiveAtpm &&
                    <tr>
                        <td style={{ textAlign: "center" }} colSpan="5">
                            <button className="btn btn-ghost-success ml-auto mr-0" onClick={addEntry} disabled={disabled}>
                                Add Row
                                <span className="mdi mdi-plus-circle ml-2" />
                            </button>
                        </td>
                    </tr>
                }

            </tbody>
        </table>
    )
}
export default connect(
    "selectIsProfileActiveAtpm",
    "doGradeSlipEntrySave",
    "doGradeSlipEntryDelete",
    "selectGradeSlipEntryItemsObjectByForm",
    "selectGradeSlipEntryIsLoading",
    "selectGradeSlipEntryIsSaving",
    "doGradeSlipUpdateState",
    "selectGradeSlipEditedEntries",
    "selectGradeSlipSelectedForm",
    "selectGradeSlipEntryShouldFetch",
    "doGradeSlipUpdateEditedEntries",
    GradeSlipEntryTable);