import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import classnames from "../../../../utils/classnames";

const AdvisoryListItem = ({ item }) => {
  const liClass = classnames({
    "list-group-item": true,
    "list-group-item-danger": item.color === "red",
    "list-group-item-warning": item.color === "orange"
  });
  const iconClass = classnames({
    mdi: true,
    "mdi-alert": item.color === "orange",
    "mdi-alert-octagram": item.color === "red",
    "pr-2": true
  });
  return (
    <li className={liClass}>
      <div>
        <strong>
          <i className={iconClass}></i>
          {item.type.toUpperCase().replace("_", " ")}
        </strong>
      </div>
      <div>{item.name}</div>
    </li>
  );
};

const AdvisoryList = ({ advisoriesDistinct: items }) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const renderItems = () => {
    if (collapsed) return null;
    if (!items.length) {
      return (
        <li className="list-group-item list-group-item-success">
          <strong>No Current Advisories</strong>
        </li>
      );
    }
    return (
      <>
        {items.map((item, i) => {
          return <AdvisoryListItem key={i} item={item} />;
        })}
      </>
    );
  };

  return (
    <ul
      className="list-group"
      style={{
        width: "300px",
        position: "absolute",
        zIndex: 999999,
        right: "8px",
        bottom: "8px",
        opacity: 0.85,
        fontSize: "0.8em"
      }}
    >
      <li className="list-group-item">
        <span className="float-right" onClick={toggleCollapsed}>
          <i className={`mdi mdi-${collapsed ? "plus" : "minus"}-box pr-2`}></i>
        </span>
        <strong>AOI Advisories</strong>
      </li>
      {renderItems()}
    </ul>
  );
};

export default connect("selectAdvisoriesDistinct", AdvisoryList);
