import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "personnelRoles",
  uid: "approval_role_id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/personnel/:personnelSlug/approvalroles",
  putTemplate:
    "/orgs/:orgSlug/personnel/:personnelSlug/approvalroles/:item.approval_role_id",
  postTemplate: "",
  deleteTemplate:
    "/orgs/:orgSlug/personnel/:personnelSlug/approvalroles/:item.approval_role_id",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN"]
});
