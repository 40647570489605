import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler"; 
export default createRestBundle({
    name: "healthAssessmentApproval",
    uid: "id",
    prefetch: true,
    routeParam: "approvalId",
    getTemplate: "/healthAssessmentApprovals?:queryString",
    postTemplate: "/healthAssessmentApprovals",
    deleteTemplate: "/healthAssessmentApprovals/:item.id",
    putTemplate: "",
    urlParamSelectors: ["selectHealthAssessmentApprovalsFormattedQueryString"],
    fetchActions: ["NESTED_DIALOG_CLOSED", "HEALTHASSESSMENT_FORM_SELECTED", "EFTWENTY_FETCH_FINISHED", "HEALTHASSESSMENT_SAVE_FINISHED", "HEALTHASSESSMENTAPPROVAL_SAVE_FINISHED", "HEALTHASSESSMENTAPPROVAL_DELETE_FINISHED"],
    forceFetchAction: [],
    initialData: {
      _queryString: { formId :null }
    },
  addons: {
    selectHealthAssessmentApprovalsFormattedQueryString: createSelector(
      "selectHealthAssessmentSelectedForm",
      (selectedForm) => {
        let queryString = selectedForm.id? `formId=${selectedForm.id}` : ""
        return { queryString: queryString }
      }
    )
    }
});