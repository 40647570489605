import React from "react";
import { connect } from "redux-bundler-react";
import AircraftCardRegion from "./aircraft-card-region";
import Loader from "../../app-components/loader";

class Aircraft extends React.Component {

  componentDidMount() {
    const { doEquipmentRequestInitUrlParams } = this.props;
    doEquipmentRequestInitUrlParams();
  }
  render() {
    const { aircraftItemsNotDeactivated: aircraftItems, aircraftIsSaving, aircraftIsLoading,
      equipmentRequestIsSaving, equipmentRequestIsLoading,
      equipmentRequestItemsOrderedByStatus } = this.props;
    if (aircraftIsSaving || aircraftIsLoading || equipmentRequestIsSaving || equipmentRequestIsLoading) {
      return (
        <div className="container-fluid mt-4">
          <Loader opt="dissolve-cube" />
        </div>
      );
    }
    return (
      <div className="container-fluid mt-4">
        <AircraftCardRegion items={aircraftItems} equipmentRequestItems={equipmentRequestItemsOrderedByStatus} />
      </div>
    );
  }
}

export default connect(
  "selectEquipmentRequestIsSaving",
  "selectEquipmentRequestIsLoading",
  "selectAircraftIsSaving",
  "selectAircraftItemsNotDeactivated",
  "selectAircraftIsLoading",
  "doAircraftFetch",
  "doEquipmentRequestInitUrlParams",
  "selectEquipmentRequestItemsOrderedByStatus",
  Aircraft
);
