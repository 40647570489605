import React from "react";
import { connect } from "redux-bundler-react";
import classnames from 'classnames'

import TagInputContainer from "../../_shared/tag-input-container"
import CheckboxInput from "../../../app-components/schema-form/checkbox-input";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "../../_shared/item-board/item-board.css"

const validStartAndEndDates = (start, end) => {
    if (start && start <= end) return true
    return false
}

const JobNewGeneralSection = ({ jobsNewFormState, jobsNewFormSectionsReady, routeInfo, doJobsNewFormStateUpdate }) => {
    const handleStartDateChange = (e) => {
        doJobsNewFormStateUpdate({ startDate: e, _changesMade: true })
    }
    const handleEndDateChange = (e) => {
        doJobsNewFormStateUpdate({ endDate: e, _changesMade: true })
    }
    const handleTextFieldChange = (e) => {
        let newText = e.target.value;
        newText = newText.replace(/<.*?>/ig, '')
        doJobsNewFormStateUpdate({ [e.target.name]: newText, _changesMade: true })
    }
    const handleTagsChange = (update) => {
        doJobsNewFormStateUpdate(update)
    }
    const handleJobsDateTentativeChanged = (e) => {
        doJobsNewFormStateUpdate({ datesTentative: e.value })
        return
    }

    const {
        title, body, datesTentative, requirements, addr, notes, tags = Object.assign({}, tags),
        startDate = startDate ? new Date(startDate) : null,
        endDate = endDate ? new Date(endDate) : null,
    } = jobsNewFormState;

    const { general: isReady } = jobsNewFormSectionsReady
    let readyCls = classnames({
        "mdi mdi-alert-circle text-danger ml-2": isReady === false,
        "mdi mdi-checkbox-blank-circle-outline text-secondary ml-2": isReady === null,
        "mdi mdi-checkbox-marked-circle text-success ml-2": isReady === true
    })
    let subjectCls = classnames({
        "form-control": true,
        "is-invalid": title === ""
    })
    let bodyCls = classnames({
        "form-control": true,
        "is-invalid": body === ""
    })
    let startDateCls = classnames({
        "form-control": true,
        "is-invalid": !startDate && !datesTentative,
    })
    let endDateCls = classnames({
        "form-control": true,
        "is-invalid": !validStartAndEndDates(startDate, endDate) && !datesTentative
    })
    return (
        <li className="list-group-item" style={{ overflow: "visible" }}>

            <div className="card-body">
                <h5 className="card-title">
                    General Information
                    <span className={readyCls} style={{ fontSize: '16px' }} />
                </h5>
                <div className="row mx-0 my-2">
                    <h6 className="col-sm-3">Job Title</h6>
                    <div className="col-sm-9">
                        <input className={subjectCls} type="text" autoComplete="off" maxLength={50} disabled={!routeInfo.url.includes("job-board/new")}
                            onChange={handleTextFieldChange} value={title} name="title"
                        />
                    </div>
                </div>

                <div className="row mx-0 my-3">
                    <h6 className="col-sm-3" >Job Schedule</h6>
                    <div className="col-sm-9">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column">
                                <small className="text-left h-auto font-weight-light">Start</small>
                                <DatePicker className={startDateCls} selected={startDate} title="Start Date" onChange={handleStartDateChange} />
                            </div>

                            <span className="mdi mdi-minus mx-2 mt-auto mb-1" />

                            <div className="d-flex flex-column">
                                <small className="text-left h-auto font-weight-light">End</small>
                                <DatePicker className={endDateCls} selected={endDate} title="End Date" onChange={handleEndDateChange} />
                            </div>
                            <div className="ml-auto d-none my-auto d-lg-flex justify-content-md-between">
                                <span className="ml-auto mr-2">Tentative</span>

                                <div className="dates-tentative-checkbox">
                                    <CheckboxInput inline={true} property={'datesTentative'} value={jobsNewFormState['datesTentative']} prop={jobsNewFormState} schema={{ title: "" }}
                                        onChange={handleJobsDateTentativeChanged} />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-sm-9 offset-sm-3 ml-auto">
                        <small className="text-break my-1 text-right">*If start/end dates are undetermined/subject to change, check 'Tentative' </small>
                    </div>
                </div>

                <div className="row d-flex d-lg-none mx-0 my-2">

                    <div className="col-sm-auto offset-sm-3 ml-auto d-flex justify-content-between">
                        <span className="ml-auto mr-2">Tentative</span>
                        <div className="dates-tentative-checkbox">
                            <CheckboxInput inline={true} property={'datesTentative'} value={jobsNewFormState['datesTentative']} prop={jobsNewFormState} schema={{ title: "" }}
                                onChange={handleJobsDateTentativeChanged} />
                        </div>
                    </div>
                </div>
                <div className="row mx-0 my-2">
                    <div className="col-sm-3 d-flex flex-lg-column flex-row">
                        <h6>Job Location</h6>
                        <small className="ml-2 ml-lg-0 ml-xl-0"><em>{`(optional)`}</em></small>
                    </div>

                    <div className="col-sm-9">
                        <input className="form-control" type="text" autoComplete="off" maxLength={50}
                            onChange={handleTextFieldChange} value={addr} name="addr"
                        />
                    </div>
                </div>
                <div className="row my-1">
                    <hr className="mx-4 w-100 p-1 pt-0" />
                </div>

                <div className="row mx-0 my-2">
                    <h6 className="col-sm-3">Description</h6>
                    <div className="col-sm-9">
                        <textarea className={bodyCls} rows="7" autoComplete="off" name="body" type="text" value={body}
                            onChange={handleTextFieldChange}
                        />
                    </div>
                </div>
                <div className="row mx-0 my-2">
                    <div className="col-sm-3 d-flex flex-lg-column flex-row">
                        <h6>Requirements</h6>
                        <small className="ml-2 ml-lg-0 ml-xl-0"><em>{`(optional)`}</em></small>
                    </div>
                    <div className="col-sm-9">
                        <textarea className="form-control" rows="2" autoComplete="off" name="requirements" type="text" value={requirements}
                            onChange={handleTextFieldChange}
                        />
                    </div>
                </div>
                <div className="row mx-0 my-2">
                    <div className="col-sm-3 d-flex flex-lg-column flex-row">
                        <h6>Notes</h6>
                        <small className="ml-2 ml-lg-0 ml-xl-0"><em>{`(optional)`}</em></small>
                    </div>
                    <div className="col-sm-9">
                        <textarea className="form-control" rows="2" autoComplete="off" name="notes" type="text" value={notes}
                            onChange={handleTextFieldChange}
                        />
                    </div>
                </div>
                <div className="row my-1">
                    <hr className="mx-4 w-100 p-1 pt-0" />
                </div>
                <div className="row mx-0 my-2">
                    <div className="col-sm-3 d-flex flex-lg-column flex-row">
                        <h6>Tags</h6>
                        <small className="ml-2 ml-lg-0 ml-xl-0"><em>{`(optional)`}</em></small>
                    </div>

                    <div className="col-lg-auto col-sm-11">
                        <TagInputContainer onChange={handleTagsChange} tags={tags} />
                    </div>
                </div>
            </div>
        </li>
    )
}

export default connect("selectJobsNewFormSectionsReady", "doJobsNewFormStateUpdate", "selectJobsNewFormState", "selectRouteInfo", JobNewGeneralSection);