import React from "react";
import "../documentation.css";
import RoleFilter from "../../../app-containers/context-providers/role-filter";
import ComponentTree from "../component-tree";
const Alert = ({ allowRoles=["*.*"], className, style = {}, childComponents=[], title }) => {
  const adminOnly = allowRoles.length === 1 && allowRoles[0].includes("ADMIN");
  return (
    <RoleFilter allowRoles={allowRoles}>
      <div className={className} style={style}>
        <strong className="alert-heading d-flex">
          <i className="mdi mdi-alert-outline mr-2" ></i>
          {title}
          {adminOnly && <span className="ml-2">ADMIN ONLY</span>}
        </strong>
        {childComponents.map((c) => <ComponentTree obj={c} />)}
      </div>
    </RoleFilter>
  );
}
export default Alert;