import React from "react";
import { connect } from "redux-bundler-react";
import DailyRawList from "./daily-raw-list";
import MissionStatusFilter from "../../_shared/mission-status-filter";

class DailyRawHome extends React.Component {
  render() {
    const { doUpdateInnerRoute, missionsDailyRawItems } = this.props;
    return (
      <div>
        <DailyRawList
          rawItems={missionsDailyRawItems}
          doUpdateInnerRoute={doUpdateInnerRoute}
        />
        <MissionStatusFilter
          denyStatus={{
            mission_status: ["complete", "archived"],
          }}
          alt={() => {
            return (
              <button
                className="btn btn-sm btn-primary disabled"
                disabled={true}
              >
                Add Daily Risk Assessment Worksheet
              </button>
            );
          }}
        >
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              doUpdateInnerRoute("/raw/new");
            }}
          >
            Add Daily Risk Assessment Worksheet
          </button>
        </MissionStatusFilter>
      </div>
    );
  }
}

export default connect("selectMissionsDailyRawItems", DailyRawHome);
