import { createSelector } from "redux-bundler";
import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "missionsPersonnel",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/missions/:missionsSlug/personnel",
  putTemplate: "/orgs/:orgSlug/missions/:missionsSlug/personnel/:item.id",
  postTemplate: "",
  deleteTemplate: "/orgs/:orgSlug/missions/:missionsSlug/personnel/:item.id",
  fetchActions: ["AUTH_LOGGED_IN"],
  forceFetchActions: ["ACTIVE_TAB_UPDATE", "URL_UPDATED"],
  addons: {
    selectMissionsPersonnelApproved: createSelector(
      "selectMissionsPersonnelItems",
      (missionsPersonnelItems) => {
        return missionsPersonnelItems.filter((item) => !item.adjudicated);
      }
    ),
    selectMissionsPersonnelAdjudicated: createSelector(
      "selectMissionsPersonnelItems",
      (missionsPersonnelItems) => {
        return missionsPersonnelItems.filter((item) => !!item.adjudicated);
      }
    ),
  },
});
