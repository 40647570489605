import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";
import { find } from 'lodash';

export default createRestBundle({
  name: "approvalRoles",
  uid: "personnel_approval_role_id",
  prefetch: true,
  staleAfter: 30000,
  persist: false,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/approvalroles",
  putTemplate:
    "/orgs/:item.org_slug/personnel/:item.slug/approvalroles/:item.approval_role_id",
  postTemplate:
    "/orgs/:item.org_slug/personnel/:item.slug/approvalroles/:item.approval_role_id",
  deleteTemplate:
    "/orgs/:item.org_slug/personnel/:item.slug/approvalroles/:item.approval_role_id",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN"],
  forceFetchActions: [
    "APPROVAL_ROLES_PERSONNEL_UPDATED_ORG",
    "PERSONNELROLES_SAVE_FINISHED",
    "PERSONNELROLES_DELETE_FINISHED",
    "APPROVALROLES_SAVE_FINISHED",
    "APPROVALROLES_DELETE_FINISHED",
  ],
  addons: {
    selectApprovalRolesByLoggedIn: createSelector(
      "selectApprovalRolesItems",
      "selectTokenPayload",
      (roles, user) => {
        const userRoleIds = [];
        roles.forEach((role) => {
          // eslint-disable-next-line
          if (role.keycloak_id == user.sub)
            userRoleIds.push(role.approval_role_id);
        });
        return userRoleIds;
      }
    ),
    selectApprovalRolesGetUrl: createSelector(
      "selectApprovalRolesGetTemplate",
      "selectRouteParams",
      "selectFlightsTabRouterParams",
      "selectOrgsAdditionalParams",
      "selectPathname",
      "selectApprovalRolesPersonnelOrgSlug",
      (
        template,
        params,
        fTabParams,
        orgsAdditionalParams,
        path,
        approvalOrgSlug
      ) => {
        const availableParams = {
          ...params,
          ...fTabParams,
          ...orgsAdditionalParams,
        };
        let url = template;
        if (path.indexOf("/roles") !== -1) {
          if (approvalOrgSlug) availableParams.orgSlug = approvalOrgSlug;
        }
        Object.keys(availableParams).forEach((key) => {
          url = url.replace(`:${key}`, availableParams[key]);
        });
        return url;
      }
    ),
    selectApprovalRolesAtpms: createSelector('selectApprovalRolesItems', personnel => {
      let atpms = [];
      personnel.forEach(p => {
        // if user isnt already in array and is ATPM
        if (!find(atpms, { parent_id: p.parent_id }) && p.approval_role === 'Aircrew Training Program Manager') {
          atpms.push(p)
        }
      });
      return atpms;
    }),

    selectUserIsAtpm: createSelector(
      "selectApprovalRolesByLoggedIn",
      "selectDomainsATPM",
      "selectDomainsBATPM",
      (approvalRolesByLoggedIn, domainsATPM, domainsBATPM) => {
        return approvalRolesByLoggedIn.includes(domainsATPM.id) || approvalRolesByLoggedIn.includes(domainsBATPM.id);
      })
  },
});
