import { createSelector } from "redux-bundler";
import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "batteries",
  uid: "slug",
  prefetch: true,
  staleAfter: 30000,
  persist: false,
  routeParam: "batteriesSlug",
  getTemplate: "/orgs/:orgSlug/batteries",
  putTemplate: "/orgs/:orgSlug/batteries/:item.slug",
  postTemplate: "/orgs/:orgSlug/batteries",
  deleteTemplate: "/orgs/:orgSlug/batteries/:item.slug",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN", "TRANSFERS_SAVE_FINISHED"],
  forceFetchActions: ["BATTERIES_SAVE_FINISHED"],
  addons: {
    selectBatteriesItemsNotInConfiguration: createSelector(
      "selectBatteriesItems",
      "selectMissionsConfigurationsActiveRow",
      (batteriesItems, missionsConfigurationsActiveRow) => {
        if (
          missionsConfigurationsActiveRow &&
          missionsConfigurationsActiveRow.batteries_ids
        ) {
          return batteriesItems.filter(
            (a) =>
              !missionsConfigurationsActiveRow.batteries_ids
                .split(".")
                .includes(a.id)
          );
        } else return batteriesItems;
      }
    ),
  },
});
