import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";

const riskLevels = {
  Low: 0,
  Medium: 1,
  High: 2,
  "Extremely High": 3
};

export default createRestBundle({
  name: "missionsRawHazards",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/missions/:missionsSlug/rawhazards",
  putTemplate: "/orgs/:orgSlug/missions/:missionsSlug/rawhazards/:item.id",
  postTemplate: "/orgs/:orgSlug/missions/:missionsSlug/rawhazards",
  deleteTemplate: "/orgs/:orgSlug/missions/:missionsSlug/rawhazards/:item.id",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN"],
  addons: {
    selectMissionsRawHazardsInitialRisk: createSelector(
      "selectMissionsRawHazardsItems",
      items => {
        if (!items || !items.length) return "Extremely High";
        let initialRisk = "Low";
        items.forEach(item => {
          if (riskLevels[item.initial_risk] > riskLevels[initialRisk]) {
            initialRisk = item.initial_risk;
          }
        });
        return initialRisk;
      }
    ),
    selectMissionsRawHazardsResidualRisk: createSelector(
      "selectMissionsRawHazardsItems",
      items => {
        if (!items || !items.length) return "Extremely High";
        let residualRisk = "Low";
        items.forEach(item => {
          if (!item.final_risk_id) {
            residualRisk = item.initial_risk;
          }
          if (riskLevels[item.final_risk] > riskLevels[residualRisk]) {
            residualRisk = item.final_risk;
          }
        });
        return residualRisk;
      }
    )
  }
});
