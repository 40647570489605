import React from "react";
import HazardTableRowForm from "./hazard-table-row-form";

class HazardTableNew extends React.Component {
  render() {
    const { editItem, onStopEditing } = this.props;
    if (!editItem) return null;
    if (editItem.id) return null;
    return (
      <>
        <HazardTableRowForm editItem={editItem} onStopEditing={onStopEditing} />
      </>
    );
  }
}

export default HazardTableNew;
