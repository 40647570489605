import React from "react";
import { connect } from "redux-bundler-react";
import DomainValueEditor from "./domain-value-editor";

class DomainsValuesListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      isConfirming: false
    };
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleStopEditing = this.handleStopEditing.bind(this);
  }

  handleSave(domain) {
    const { doDomainsEditableSave } = this.props;
    this.setState(
      {
        isEditing: false
      },
      () => {
        doDomainsEditableSave(domain);
      }
    );
  }

  handleEdit() {
    this.setState({
      isEditing: true
    });
  }

  handleStopEditing() {
    this.setState({
      isEditing: false,
      isConfirming: false
    });
  }

  handleDelete() {
    this.setState({
      isConfirming: true
    });
  }

  handleConfirm() {
    const { doDomainsEditableDelete, domain } = this.props;
    this.setState(
      {
        isEditing: false,
        isConfirming: false
      },
      () => {
        doDomainsEditableDelete(domain);
      }
    );
  }

  renderActions() {
    const { isConfirming } = this.state;
    if (isConfirming) {
      return (
        <span className="float-right">
          <button
            onClick={this.handleConfirm}
            className="btn btn-sm btn-danger mr-2"
          >
            Confirm
          </button>
          <button
            onClick={this.handleStopEditing}
            className="btn btn-sm btn-secondary"
          >
            Cancel
          </button>
        </span>
      );
    } else {
      return (
        <span className="float-right">
          <button
            onClick={this.handleEdit}
            className="btn btn-sm btn-secondary mr-2"
          >
            Edit
          </button>
          <button onClick={this.handleDelete} className="btn btn-sm btn-danger">
            Delete
          </button>
        </span>
      );
    }
  }

  render() {
    const { isEditing } = this.state;
    const { domain } = this.props;
    if (isEditing) {
      return (
        <DomainValueEditor
          domain={domain}
          onCancel={this.handleStopEditing}
          onSave={this.handleSave}
        />
      );
    }
    return (
      <li className="list-group-item">
        {this.renderActions()}
        {domain.val}
        <small className="text-muted ml-3">{domain.tooltip}</small>
      </li>
    );
  }
}

export default connect(
  "doDomainsEditableSave",
  "doDomainsEditableDelete",
  DomainsValuesListItem
);
