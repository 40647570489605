import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";

class CaptureThumbnailButton extends React.Component {
  constructor(props) {
    super(props);
    this.capture = this.capture.bind(this);
  }

  capture() {
    const {
      doMapAoiZoomToBuffer,
      map,
      missionsByRoute: mission,
      doMissionsSave,
    } = this.props;
    map.once("rendercomplete", function (event) {
      var canvas = event.context.canvas;
      const mapImg = canvas.toDataURL("image/jpeg", 0.3);
      //mission.thumbnail = mapImg;     //Dont save image until compression is addressed
      doMissionsSave(mission);
    });
    doMapAoiZoomToBuffer();
  }

  render() {
    // return null;
    const { mapAoiInteractionsActive, disabled } = this.props;
    const btnClass = classnames({
      btn: true,
      "btn-primary": true,
      active: mapAoiInteractionsActive,
      "mr-2": true,
    });
    return (
      <button
        className={btnClass}
        onClick={this.capture}
        disabled={disabled}
        title="Capture AOI Thumbnail"
      >
        <i className="mdi mdi-image-filter-center-focus-strong"></i>
      </button>
    );
  }
}

//<i className="mdi mdi-image-filter-center-focus-strong"></i>

export default connect(
  "doMapAoiZoomToBuffer",
  "selectMap",
  "selectMissionsByRoute",
  "doMissionsSave",
  CaptureThumbnailButton
);
