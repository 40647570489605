import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { connect } from "redux-bundler-react";
import alertConfig from "./alertConfig";
import AlertItem from "./alert-item";
import Adjudication from "./adjudication";

const alertHeader = {
  Flights: "Alerts must be adjudicated in order to save new flights.",
  Signatures: `Preparer must adjudicate all the alerts below in order to sign
  the mission.`,
};
const AlertContainer = ({
  tab,
  doMissionsAdjudicationsSave,
  doMissionsAdjudicationsFetch,
  missionsAdjudicationsIsLoading,
  missionsAdjudicationsIsSaving,
  missionsAlertsAssocAdjudications: alertItems,
  missionsByRoute,
}) => {
  const [alerts, setAlerts] = useState([]);
  const [open, setOpen] = useState(true);
  const accordionClass = classNames({
    "accordion-collapse collapse": true,
    show: open,
  });

  useEffect(() => {
    let tabAlerts =
      tab === "Adjudications"
        ? alertItems
        : tab === "Signatures"
        ? alertItems.filter((item) => item.tab !== "flights")
        : alertItems.filter((item) => alertConfig[tab] === item.tab);
    setAlerts(tabAlerts);
  }, [alertItems]);

  if (
    ["Signatures", "Flights"].indexOf(tab) !== -1 &&
    alerts &&
    alerts.length > 0
  ) {
    return (
      <div className="accordion mb-2" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button d-flex justify-content-between align-items-center w-100 p-0 btn btn-ghost btn-lg"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded={open}
              aria-controls="collapseOne"
              onClick={() => setOpen(!open)}>
              <div className="d-flex flex-column justify-content-start">
                <h5 className="d-flex flex-column align-items-start mb-2">
                  Alerts / Adjudications
                </h5>
                <h6 className="mb-2 text-muted">{alertHeader[tab]}</h6>
              </div>
              <i className={`mdi mdi-${open ? "minus" : "plus"} mdi-18px`} />
            </button>
          </h2>
          <div
            id="collapseOne"
            className={accordionClass}
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
            <div
              className="accordion-body rounded"
              style={{ border: "2px solid #fdd6d6" }}>
              {alerts.map((item, i) => {
                return (
                  <AlertItem
                    key={item.variable_id}
                    item={item}
                    doSave={doMissionsAdjudicationsSave}
                    doFetch={doMissionsAdjudicationsFetch}
                    isFetching={
                      missionsAdjudicationsIsLoading ||
                      missionsAdjudicationsIsSaving
                    }
                    editable
                    approvalStatus={missionsByRoute.approval_status}
                    tab={tab}
                  />
                );
              })}
              <div className="p-2">
                <Adjudication
                  alerts={alerts}
                  doFetch={doMissionsAdjudicationsFetch}
                  isFetching={
                    missionsAdjudicationsIsLoading ||
                    missionsAdjudicationsIsSaving
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      alerts &&
      alerts.length > 0 &&
      alerts.map((item) => {
        return (
          <AlertItem
            key={item.variable_id}
            item={item}
            editable={tab === "Adjudications"}
            approvalStatus={missionsByRoute.approval_status}
            tab={tab}
            alwaysCollapsed={tab === "Adjudications"}
          />
        );
      })
    );
  }
};

export default connect(
  "selectMissionsAlertItems",
  "selectMissionsAdjudicationsItems",
  "doMissionsAdjudicationsSave",
  "doMissionsAdjudicationsFetch",
  "selectMissionsAdjudicationsIsLoading",
  "selectMissionsAdjudicationsIsSaving",
  "selectMissionsAlertsAssocAdjudications",
  "selectMissionsByRoute",
  AlertContainer
);
