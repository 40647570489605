import React from "react";
import { connect } from "redux-bundler-react";

const PilotRoleFilter = ({
  domainsItems: domains,
  efTwentyItems,
  allowRoles,
  children,
  Alt,
  ...props
}) => {
  let flightDuties =
    efTwentyItems && efTwentyItems.length > 0 ? efTwentyItems[0].duties_id : "";
  flightDuties = flightDuties ? flightDuties.split(".") : [];
  let dutiesNames = domains
    .filter((d) => flightDuties.includes(d.id))
    .map((item) => item.val);

  let found =
    dutiesNames.some((r) => allowRoles.indexOf(r) >= 0) ||
    allowRoles.includes("*");
  if (found) return <>{children}</>;
  else if (Alt) return <Alt {...props} />;
  else return null;
};

export default connect(
  "selectDomainsItems",
  "selectEfTwentyItems",
  PilotRoleFilter
);
