import React from "react";
import { connect } from "redux-bundler-react";
import SelectorCard from "../_shared/selector-card/card";

export default connect(
  "selectOrgsByRoute",
  "selectTransfersByRoute",
  "selectTransfersEquipmentItems",
  "selectTransfersEquipmentIsLoading",
  "selectTransfersAllEquipment",
  "doUpdateRelativeUrl",
  "doTransfersEquipmentSave",
  "doTransfersEquipmentDelete",
  ({
    orgsByRoute,
    transfersByRoute,
    transfersEquipmentItems,
    transfersEquipmentIsLoading,
    transfersAllEquipment,
    doUpdateRelativeUrl,
    doTransfersEquipmentSave,
    doTransfersEquipmentDelete,
  }) => {
    const handleNext = () => {
      doUpdateRelativeUrl(
        `/${orgsByRoute.slug}/transfers/${transfersByRoute.slug}`
      );
    };
    return (
      <div className="container mt-3">
        <div className="row">
          <div className="col-sm-12 col-lg-9">
            <p>
              Choose the equipment to transfer. Once the list is complete click
              next. You will be able to edit the list on the next screen as
              well.
            </p>
            <SelectorCard
              editing={true}
              hideDoneButton={true}
              allowRoles={[":ORG.ADMIN"]}
              allowEditing={true}
              items={transfersEquipmentItems}
              isLoading={transfersEquipmentIsLoading}
              pickFrom={transfersAllEquipment}
              onAdd={doTransfersEquipmentSave}
              onRemove={doTransfersEquipmentDelete}
              title="Transfer Equipment"
              iconClass="mdi mdi-swap-horizontal"
              itemRenderFn={(item) => {
                return (
                  <div className="d-flex-flex-column">
                    {`${item.type}: ${item.name}`}
                    <p className="m-0 text-muted">
                      {item.serial_no ? `SN: ${item.serial_no}` : ""}
                    </p>
                  </div>
                );
              }}
              pickerRenderFn={(item) => {
                return `${item.type}: ${item.name}`;
              }}
            />
            <div className="float-right">
              <button onClick={handleNext} className="btn btn-primary">
                Next<i className="mdi mdi-chevron-right pl-1"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
