import React from "react";

const Toggle = (props) => {
  const _onChange = () => {
    props.doSave({
      ...props.data,
      usace_approved: !props.data.usace_approved,
    });
  };

  return (
    <div className="custom-control custom-switch d-flex align-items-center justify-content-center">
      <input
        className="custom-control-input"
        type="checkbox"
        role="switch"
        id={props.data.id}
        checked={props.data.usace_approved}
        value={props.data.usace_approved ?? false}
        onChange={_onChange}
      />
      <label className="custom-control-label" htmlFor={props.data.id}></label>
    </div>
  );
};
export default Toggle;
