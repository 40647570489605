import React from 'react';
import { connect } from "redux-bundler-react"
import Dropdown from "../../_shared/item-board/dropdown"
import { findIndex } from "lodash"
const JobCommonTags = ({ jobsState, updateQueryField, jobsCommonTags }) => {
    const { _onlyFetchTag } = jobsState
    if (jobsCommonTags.length === 0) return null
    let jobsCommonTagsOptions = jobsCommonTags.map((tag) => {
        return { update: { _onlyFetchTag: tag }, text: tag, handler: updateQueryField, displayValue: tag, exitOnClick: true }
    })
    return (
        <div className="d-flex flex-column">
            <div className="d-lg-flex d-xl-flex d-none flex-row flex-wrap p-0 m-0 w-100" style={{ fontSize: "14px" }}>
                <span className="font-weight-bold mr-2 align-self-center">Common Tags: </span>
                {jobsCommonTags.map((tag, idx) => {
                    return <button key={idx} className="btn mr-2 btn-ghost-info font-weight-lighter" name={tag} onClick={(e) => {
                        updateQueryField({ _onlyFetchTag: e.currentTarget.name })
                    }}>{tag}</button>
                })}
            </div>
            <div className="d-lg-none d-xl-none">
                <Dropdown
                    btnGroupCls="mr-2"
                    btnCls="btn-md btn-outline-primary"
                    title="Common Tags"
                    options={jobsCommonTagsOptions}
                    state={jobsState}
                    direction="dropdown-menu-left"
                    selectedIdx={findIndex(jobsCommonTagsOptions, (o) => o.value === _onlyFetchTag)}
                />
            </div>
        </div>
    )
}
export default connect(
    "selectJobsState",
    "selectJobsAllTags",
    "selectJobsCommonTags",
    JobCommonTags
)

