import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";

class LzButton extends React.Component {
  constructor(props) {
    super(props);
    this.updateInteraction = this.updateInteraction.bind(this);
  }

  updateInteraction() {
    const {
      mapLzInteractionsActive,
      doMapLzAddInteraction,
      doMapInteractionsClear,
    } = this.props;
    if (mapLzInteractionsActive) {
      doMapInteractionsClear();
    } else {
      doMapLzAddInteraction();
    }
  }

  render() {
    const { mapLzInteractionsActive, doMapLzDeleteSelected, mapLzActions, doMapLzUndoAdd } = this.props;

    const btnClass = classnames({
      btn: true,
      "btn-primary": true,
      active: mapLzInteractionsActive,
    });
    let newActions = mapLzActions.filter(act => {
      let src = act.type === 'delete' ? 'new' : act.feature.feature.values_.src;
      let id = act.feature.feature ? act.feature.feature.values_.id : act.feature.values_.id;
      return (src === 'new') && (id !== null);
    });
    return (
      <div className="btn-group mr-2">
        <button
          className={`btn btn-${!newActions.length ? 'outline-secondary' : 'outline-primary'}`}
          onClick={doMapLzUndoAdd}
          disabled={!newActions.length}
          title="Undo last Added/Deleted LZ points"
        >
          <i className="mdi mdi-undo"></i>
        </button>
        <button className={btnClass} onClick={this.updateInteraction}>
          Add/Modify LZ
        </button>
        <button
          className="btn btn-danger"
          onClick={doMapLzDeleteSelected}
          disabled={!mapLzInteractionsActive}
          title="Delete selected LZ points"
        >
          <i className="mdi mdi-trash-can-outline"></i>
        </button>
      </div>
    );
  }
}

export default connect(
  "selectMapLzInteractionsActive",
  "doMapLzAddInteraction",
  "doMapInteractionsClear",
  "doMapLzDeleteSelected",
  "selectMapLzActions",
  "doMapLzUndoAdd",
  LzButton
);
