import React from "react";
import { connect } from "redux-bundler-react";
import FigureCard from "./figure-card";

class FiguresTab extends React.Component {
  render() {
    const { missionsFiguresItems: figures } = this.props;
    return (
      <div>
        <div className="clearfix">
          <div className="float-right text-muted">
            Click Image to open preview
          </div>
        </div>
        <div className="row">
          {figures.map((figure, i) => {
            return <FigureCard figure={figure} key={i} />;
          })}
        </div>
      </div>
    );
  }
}

export default connect("selectMissionsFiguresItems", FiguresTab);
