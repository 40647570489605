import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";

export default createRestBundle({
  name: "aircraft",
  uid: "slug",
  prefetch: true,
  staleAfter: 30000,
  persist: false,
  routeParam: "aircraftSlug",
  getTemplate: "/orgs/:orgSlug/aircraft",
  putTemplate: "/orgs/:orgSlug/aircraft/:item.slug",
  postTemplate: "/orgs/:orgSlug/aircraft",
  deleteTemplate: "/orgs/:orgSlug/aircraft/:item.slug",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN", "TRANSFERS_SAVE_FINISHED"],
  forceFetchActions: ["EQUIPMENTREQUEST_STATUS_CHANGING", "AIRCRAFT_SAVE_FINISHED", "PLATFORMS_SAVE_FINISHED"],
  addons: {
    selectAircraftItemsNotDeactivated: createSelector(
      "selectAircraftItems",
      "selectEquipmentRequestDeactivationTypes",
      (aircraftItems, deactivationTypes) => {
        let deactivationTypeNames = deactivationTypes.map(t => t.val);
        return aircraftItems.filter(g => !deactivationTypeNames.includes(g.deactivated))
      }
    ),
    selectAircraftItemsOperational: createSelector(
      "selectAircraftItems",
      (items) => {
        let obj = { FMC: 0, NMC: 0 };
        items.forEach((a) => {
          if (!a.operational) return;
          if (obj[a.operational]) {
            obj[a.operational] = obj[a.operational] += 1;
          } else obj[a.operational] = 1;
        });
        return obj;
      }
    ),
    selectAircraftByUrlPlatformSlug: createSelector(
      "selectAircraftByRoute",
      "selectPlatformsItems",
      (aircraft, platforms) => {
        if (!aircraft) return {};
        let activePlatform = null;
        for (var i = 0; i < platforms.length; i++) {
          if (platforms[i].id === aircraft.platform_id) {
            activePlatform = platforms[i];
            break;
          }
        }
        if (!activePlatform) return {};
        return {
          platformsSlug: activePlatform.slug,
        };
      }
    ),
    selectAircraftItemsApproved: createSelector(
      "selectAircraftItems",
      (aircraft) => aircraft.filter((item) => !item.deactivated && item.usace_approved &&
        new Date(item.awr_expire_date) >= new Date())
    ),

    selectAircraftItemsNotInConfiguration: createSelector(
      "selectAircraftItemsApproved",
      "selectMissionsConfigurationsActiveRow",
      (aircraftItems, missionsConfigurationsActiveRow) => {
        if (
          missionsConfigurationsActiveRow &&
          missionsConfigurationsActiveRow.aircraft_ids
        ) {
          return aircraftItems.filter(
            (a) =>
              !missionsConfigurationsActiveRow.aircraft_ids
                .split(".")
                .includes(a.id)
          );
        } else return aircraftItems;
      }
    ),
  },
});
