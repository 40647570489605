import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "missionsApproval",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/missions/:missionsSlug/approval",
  putTemplate: "/orgs/:orgSlug/missions/:missionsSlug/approval/:item.id",
  postTemplate: "/orgs/:orgSlug/missions/:missionsSlug/approval/preparedby",
  deleteTemplate: "/orgs/:orgSlug/missions/:missionsSlug/approval",
  fetchActions: ["AUTH_LOGGED_IN"],
  forceFetchActions: ["ACTIVE_TAB_UPDATE", "URL_UPDATED"],
  addons: {
    doMissionsApprovalReview:
      (item, callback, deferCallback) =>
      ({ dispatch, store, apiPost }) => {
        let orgSlug = store.selectOrgsByRoute().slug;
        let missionSlug = store.selectMissionsByRoute().slug;
        dispatch({
          type: "MISSIONSAPPROVAL_REVIEW_START",
          payload: { _isSigning: true },
        });

        apiPost(
          `/orgs/${orgSlug}/missions/${missionSlug}/approval/review`,
          item,
          (err, response, body) => {
            if (err || response.statusCode !== 200) {
              dispatch({
                type: "MISSIONSAPPROVAL_REVIEW_ERROR",
                payload: {
                  _err: { err: err, response: response },
                  notification: {
                    statusCode: response.statusCode,
                  },
                  _isSigning: false,
                },
              });
            } else {
              // Make sure we're sending save_finished when we're done
              dispatch({
                type: "MISSIONSAPPROVAL_REVIEW_FINISHED",
                payload: {
                  _isSigning: false,
                },
              });

              if (deferCallback && callback) callback();
            }
            // if we get a callback, go ahead and fire it
            if (!deferCallback && callback) callback();
          }
        );
      },
    selectMissionsApprovalReviewIsSigning: (state) =>
      state.missionsApproval._isSigning,
  },
});
