import React from "react";

const ExpDateRenderer = (props) => {
  let item = props.data;
  let date = item.exp_date;
  if (!date) return null;
  if (item.return_status === "Complete") {
    return (
      <span className="text-success">
        <i className="mdi mdi-check pr-1"></i>
        {new Date(item.return_date).toLocaleDateString()}
      </span>
    );
  }
  return (
    <span className={new Date(date) < new Date() ? "text-danger" : ""}>
      {new Date(date).toLocaleDateString()}
    </span>
  );
};

export default ExpDateRenderer;
