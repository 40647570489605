import React from "react";
import { connect } from "redux-bundler-react";
import DamageTab from "./damage-tab";

class FlightDebrief extends React.Component {
  constructor(props) {
    super(props);

    this.goBack = this.goBack.bind(this);
    this.addIncident = this.addIncident.bind(this);
  }

  goBack() {
    const { doUpdateInnerRoute } = this.props;
    doUpdateInnerRoute("/");
  }

  addIncident() {
    const { doUpdateInnerRoute, flightsTabRouterParams: params } = this.props;
    doUpdateInnerRoute(
      `/raw/${params.rawId}/flights/${params.flightId}/debrief/incident`
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-xl-9 col-lg-12">
          <section className="mb-3">
            <div className="float-right">
              <button
                className="btn btn-sm btn-warning mr-2"
                onClick={this.addIncident}
              >
                Incident Report
              </button>
              <button
                className="btn btn-sm btn-secondary"
                onClick={this.goBack}
              >
                Back
              </button>
            </div>
            <p>
              Choose a piece of equipment from the list on the left to enter a
              status other than "Fully Mission Capable"
            </p>
          </section>

          <DamageTab />
        </div>
      </div>
    );
  }
}

export default connect("selectFlightsTabRouterParams", FlightDebrief);
