import React from "react";
import { connect } from "redux-bundler-react";
import classnames from 'classnames'

const JobNewPocSection = ({ jobsNewFormSectionsReady, doJobsNewFormStateUpdate, jobsNewFormState }) => {
    const { poc_name, poc_phone, poc_email, alt_poc_name, alt_poc_phone, alt_poc_email } = jobsNewFormState;
    const { pocs: isReady } = jobsNewFormSectionsReady;

    let readyCls = classnames({
        "mdi mdi-alert-circle text-danger ml-2": isReady === false,
        "mdi mdi-checkbox-blank-circle-outline text-secondary ml-2": isReady === null,
        "mdi mdi-checkbox-marked-circle text-success ml-2": isReady === true
    })
    const handleTextFieldChange = (e) => {
        let newText = e.target.value;
        newText = newText.replace(/<.*?>/ig, '')
        doJobsNewFormStateUpdate({ [e.currentTarget.name]: newText })
    }
    const handleKeyDown = (e) => {
        if (e.key === '~') e.preventDefault()
    }
    let primaryNameCls = classnames({
        "form-control": true,
        "is-invalid": poc_name.length === 0
    })
    let primaryEmailPhoneCls = classnames({
        "form-control": true,
        "is-invalid": poc_phone.length === 0 && poc_email.length === 0
    })

    return (
        <li className="list-group-item">
            <div className="card-body">
                <h5 className="card-title">
                    <span>Point(s) of Contact</span>
                    <span className={readyCls} style={{ fontSize: '16px' }} />
                </h5>
                <div className="row mx-0 my-1">
                    <h6 className="col-sm-2">
                        Primary Contact
                    </h6>
                    <div className="col-10">
                        <div className="row mx-0 mb-2">
                            <div className="col-sm-auto offset-sm-1">
                                <small className="text-break">*At least one method of contact (phone, email) is required</small>
                            </div>
                        </div>
                        <div className="row mx-0 my-2">
                            <div className="col-sm-1 px-0">Name</div>
                            <div className="col-sm-11">
                                <input className={primaryNameCls} type="text" autoComplete="off" maxLength={200} value={poc_name} name="poc_name"
                                    onChange={handleTextFieldChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 my-2">
                            <div className="col-sm-1 px-0">Phone</div>
                            <div className="col-sm-11">
                                <input className={primaryEmailPhoneCls} type="text" autoComplete="off" maxLength={200} value={poc_phone} name="poc_phone"
                                    onChange={handleTextFieldChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 my-2">
                            <div className="col-sm-1 px-0">Email</div>
                            <div className="col-sm-11">
                                <input className={primaryEmailPhoneCls} type="text" autoComplete="off" maxLength={200} value={poc_email} name="poc_email"
                                    onChange={handleTextFieldChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mx-0 my-2">
                    <div className="col-sm-2 d-flex flex-lg-column flex-row">
                        <h6>Secondary Contact</h6>
                        <small className="ml-2 ml-lg-auto ml-xl-0"><em>{`(optional)`}</em></small>
                    </div>
                    <div className="col-10">
                        <div className="row mx-0 my-2">
                            <div className="col-sm-1 px-0">Name</div>
                            <div className="col-sm-11">
                                <input className="form-control" type="text" autoComplete="off" maxLength={200} value={alt_poc_name} name="alt_poc_name"
                                    onChange={handleTextFieldChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 my-2">
                            <div className="col-sm-1 px-0">Phone</div>
                            <div className="col-sm-11">
                                <input className="form-control" type="text" autoComplete="off" maxLength={200} value={alt_poc_phone} name="alt_poc_phone"
                                    onChange={handleTextFieldChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 my-2">
                            <div className="col-sm-1 px-0">Email</div>
                            <div className="col-sm-11">
                                <input className="form-control" type="text" autoComplete="off" maxLength={200} value={alt_poc_email} name="alt_poc_email"
                                    onChange={handleTextFieldChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}

export default connect("selectJobsNewFormSectionsReady", "doJobsNewFormStateUpdate", "selectJobsNewFormState", JobNewPocSection)