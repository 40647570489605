import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";
import { groupBy, every } from "lodash";
export default createRestBundle({
  name: "forumUserPermissions",
  uid: "id",
  prefetch: true,
  persist: false,
  routeParam: "forumUserPermissionId",
  getTemplate: "/forum-user-permissions",
  putTemplate: "/forum-user-permissions/:profileId/:item.id",
  postTemplate: "/forum-user-permissions/:profileId",
  deleteTemplate: "/forum-user-permissions/:profileId/:item.id",
  fetchActions: ["AUTH_LOGGED_IN"],
  forceFetchActions: ["FORUMUSERPERMISSIONS_SAVE_ALL_FINISHED"],

  reduceFurther: (state, { type, payload }) => {
    switch (type) {
      case "FORUMUSERPERMISSIONS_SAVE_ALL_ERROR":
      case "FORUMUSERPERMISSIONS_SAVE_ALL_STARTED":
      case "FORUMUSERPERMISSIONS_SAVE_ALL_FINISHED":
        return Object.assign({}, state, payload);
      default:
        return state;
    }
  },
  addons: {
    doForumUserPermissionsSaveByLoggedIn:
      (items, callback) =>
      ({ dispatch, store, apiPut, apiPost }) => {
        let profileId = store.selectProfileActive().keycloak_id;
        dispatch({
          type: "FORUMUSERPERMISSIONS_SAVE_ALL_STARTED",
          payload: { _isSaving: true },
        });
        let itemsByMethod = groupBy(items, (obj) => {
          if (obj.id !== undefined) return "put";
          else return "post";
        });
        if (itemsByMethod["post"]) {
          apiPost(
            `/forum-user-permissions/${profileId}`,
            itemsByMethod["post"],
            (err, res, body) => {
              if (err || res.statusCode !== 200) {
                dispatch({
                  type: "FORUMUSERPERMISSIONS_SAVE_ALL_ERROR",
                  payload: {
                    _err: { response: res, err: err },
                    notification: { statusCode: res.statusCode },
                    _isSaving: false,
                  },
                });
              } else {
                dispatch({
                  type: "FORUMUSERPERMISSIONS_SAVE_ALL_FINISHED",
                  payload: { _isSaving: false },
                });
              }
            }
          );
        }

        if (itemsByMethod["put"]) {
          apiPut(
            `/forum-user-permissions/${profileId}`,
            itemsByMethod["put"],
            (err, res, body) => {
              if (err || res.statusCode !== 200) {
                dispatch({
                  type: "FORUMUSERPERMISSIONS_SAVE_ALL_ERROR",
                  payload: {
                    _err: { response: res, err: err },
                    notification: { statusCode: res.statusCode },
                    _isSaving: false,
                  },
                });
              } else {
                dispatch({
                  type: "FORUMUSERPERMISSIONS_SAVE_ALL_FINISHED",
                  payload: { _isSaving: false },
                });
              }
            }
          );
        }

        if (callback) callback();
      },
    selectForumUserPermissionsIsFetching: (state) => {
      return state.forumUserPermissions._isFetching;
    },

    selectForumUserPermissionsByLoggedIn: createSelector(
      "selectForumUserPermissionsItems",
      "selectTokenPayload",
      (forumUserPermissionsItems, tokenPayload) => {
        let forumUserPermissionsByLoggedIn = forumUserPermissionsItems.filter(
          (item) => item.created_by === tokenPayload.sub
        );
        if (
          !forumUserPermissionsByLoggedIn ||
          forumUserPermissionsByLoggedIn.length === 0
        ) {
          forumUserPermissionsByLoggedIn = [
            {
              forum_name: "job-board",
              created_by: tokenPayload.sub,
              last_updated_by: tokenPayload.sub,
              create_date: new Date(),
              last_updated_date: new Date(),
              deleted: 0,
              personnel_id: null,
              allow_notifications: true,
              show_approval_roles: false,
              show_contact_info: false,
            },
            {
              forum_name: "message-board",
              created_by: tokenPayload.sub,
              last_updated_by: tokenPayload.sub,
              deleted: 0,
              personnel_id: null,
              create_date: new Date(),
              last_updated_date: new Date(),
              deleted: 0,
              personnel_id: null,
              allow_notifications: true,
              show_approval_roles: false,
              show_contact_info: false,
            },
          ];
        }
        return forumUserPermissionsByLoggedIn;
      }
    ),
    selectForumUserPermissionsAllExists: createSelector(
      "selectForumUserPermissionsItems",
      "selectTokenPayload",
      (forumUserPermissionsItems, tokenPayload) => {
        let forumUserPermissionsByLoggedIn = forumUserPermissionsItems.filter(
          (item) => item.created_by === tokenPayload.sub
        );
        if (forumUserPermissionsByLoggedIn.length === 0) return false;
        return every(
          forumUserPermissionsByLoggedIn,
          (obj) => typeof obj.id === "string"
        );
      }
    ),
  },
});
