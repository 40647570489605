import { sortBy } from "lodash";
import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";
export default createRestBundle({
  name: "gcsReport",
  uid: "id",
  routeParam: "",
  getTemplate: "/orgs/:orgSlug/reports/gcs?:queryString",
  prefetch: false,
  persist: false,
  fetchActions: [],
  forceFetchActions: [],
  initialData: {
    _queryString: {},
    _isLoading: false,
    _isStatsLoading: false,
    _lastStatsFetch: null,
    _stats: null,
    _lastStatsResource: null,
    _statsStaleAfter: 30000,
    _shouldFetchStats: true
  },
  urlParamSelectors: ["selectGcsReportFormattedQueryString"],
  reduceFurther: (state, { type, payload }) => {
    switch (type) {
      case "GCSREPORT_FETCH_STATS_STARTED":
      case "GCSREPORT_FETCH_STATS_ABORTED":
      case "GCSREPORT_FETCH_STATS_ERROR":
      case "GCSREPORT_UPDATE_QUERY_STRING":
        return Object.assign({}, state, payload);
      case "GCSREPORT_FETCH_STATS_FINISHED":
        return Object.assign({}, payload);
      case "URL_UPDATED":
        return Object.assign({}, state, { _shouldFetchStats: true });
      default:
        return state;
    }
  },
  addons: {
    doGcsReportDownloadAsCsv: () => ({ store }) => {
      const ignoreFields = ["id", "org_id", "parent_id", "deleted", "create_date", "last_updated_date", "slug"];
      const data = store.selectGcsReportItems();
      if (!data || !data.length) return null;
      const csvRows = [];
      const fixCommas = (value) => {
        if (value && typeof value === "string" && value.indexOf(",") !== -1) {
          return `"${value}"`;
        }
        return value;
      }
      const setHeaders = (item) => {
        let head = [];
        Object.keys(item).forEach(key => {
          if (ignoreFields.indexOf(key) === -1) {
            if (key === "deactivated") key = "status";
            head.push(fixCommas(key.toUpperCase()));
          }
        })
        csvRows.push(head.join(","));
      }
      data.forEach((item, i) => {
        if (i === 0) setHeaders(item);
        let row = [];
        Object.keys(item).forEach(key => {
          if (ignoreFields.indexOf(key) === -1) {
            row.push(fixCommas(item[key]));
          }
        });
        csvRows.push(row.join(","));
      });

      let csv = csvRows.join("\n");
      let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) navigator.msSaveBlob(blob, "export.csv");
      else {
        let link = document.createElement("a");
        if (link.download !== undefined) {
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "export.csv");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    selectGcsReportFormattedQueryString: createSelector(
      "selectGcsReportQueryString",
      (queryString) => {
        return {
          queryString: Object.keys(queryString).map(k => {
            if (queryString[k] && queryString[k].length) return `${k}=${encodeURIComponent(queryString[k])}`
          }).join("&")
        }
      }
    ),
    selectGcsReportParameters: createSelector(
      "selectGcsReportStatsFormatted",
      "selectDomainsItemsByGroup",
      "selectOrgsByRoute",
      (gcsReportStatsFormatted, domainsItemsByGroup, orgsByRoute) => {
        if (!orgsByRoute || !gcsReportStatsFormatted) return null;
        const { orgs } = gcsReportStatsFormatted;

        return [
          {
            type: "checkbox-search",
            column: "org_name",
            title: "Organization",
            options: orgs
          },
          {
            type: "text-search",
            column: "name",
            title: "Name"
          },
          {
            type: "text-search",
            column: "make",
            title: "Make"
          },
          {
            type: "text-search",
            column: "model",
            title: "Model"
          },
          {
            type: "text-search",
            column: "serial_no",
            title: "Serial No."
          },
          {
            type: "checkbox-search",
            column: "deactivated",
            title: "Status",
            options: sortBy(domainsItemsByGroup["equipment_status"], ["display_order"]).map(t => {
              return { id: t.val, val: t.val }
            })
          },
          {
            type: "checkbox-search",
            column: "form_factor",
            title: "Form Factor",
            options: sortBy(domainsItemsByGroup["form_factor"], ["display_order"]).map(t => {
              return { id: t.val, val: t.val }
            })
          }
        ]
      }
    ),
    selectGcsReportStatsFormatted: createSelector(
      "selectGcsReportStats",
      "selectOrgsByRoute",
      (gcsReportStats, orgsByRoute) => {
        if (!orgsByRoute) return null;
        if (gcsReportStats && gcsReportStats.orgs) {
          return { ...gcsReportStats, orgs: gcsReportStats.orgs.map(o => Object.assign({}, { id: o, val: o, checkedByDefault: o === orgsByRoute.name })) };
        }
        return [];
      }
    ),
    selectGcsReportIsStatsStale: createSelector(
      "selectAppTime",
      "selectGcsReportLastStatsFetch",
      "selectGcsReportStaleAfter",
      (appTime, gcsReportLastStatsFetch, gcsReportStaleAfter) => {
        return appTime - new Date(gcsReportLastStatsFetch) > gcsReportStaleAfter;
      }
    ),
    doGcsReportUpdateQueryString: (update) => ({ dispatch, store }) => {
      const queryString = store.selectGcsReportQueryString();
      dispatch({
        type: "GCSREPORT_UPDATE_QUERY_STRING",
        payload: {
          _queryString: {
            ...queryString,
            ...update
          },
          _shouldFetch: true
        }
      });
    },
    selectGcsReportStats: state => {
      return state.gcsReport._stats;
    },
    selectGcsReportQueryString: state => {
      return state.gcsReport._queryString;
    },
    selectGcsReportIsStatsLoading: state => {
      return state.gcsReport._isStatsLoading;
    },
    selectGcsReportLastStatsResource: state => {
      return state.gcsReport._lastStatsResource;
    },
    selectGcsReportStaleAfter: state => {
      return state.gcsReport._statsStaleAfter;
    },
    selectGcsReportLastStatsFetch: state => {
      return state.gcsReport._lastStatsFetch;
    },
    selectGcsReportFetchStatsUrl: createSelector(
      "selectOrgsByRoute",
      (orgsByRoute) => {
        let pattern = "/orgs/:orgSlug/reports/gcs/stats";
        if (orgsByRoute) return pattern.replace(":orgSlug", orgsByRoute.slug);
        return pattern;
      }
    ),
    doGcsReportFetchStats: () => ({ dispatch, store, apiGet }) => {
      dispatch({
        type: "GCSREPORT_FETCH_STATS_STARTED",
        payload: {
          _shouldFetchStats: false,
          _isLoading: true,
          _isStatsLoading: true
        }
      });
      const gcsReportFlags = store.selectGcsReportFlags();
      const statsIsStale = store.selectGcsReportIsStatsStale();
      const url = store.selectGcsReportFetchStatsUrl();
      const lastResource = store.selectGcsReportLastStatsResource();
      if (url.includes(":orgSlug") || (!statsIsStale && url === lastResource)) {
        dispatch({ type: "GCSREPORT_FETCH_STATS_ABORTED", payload: { _isStatsLoading: false } });
      }
      else {
        apiGet(url, (err, res, body) => {
          if (err || res.statusCode !== 200) {
            dispatch({
              type: "GCSREPORT_FETCH_STATS_ERROR",
              payload: {
                _err: { err, res },
                _isStatsLoading: false,
                notification: {
                  statusCode: res.statusCode
                }
              }
            });
          }
          else {
            const result = JSON.parse(body);
            dispatch({
              type: "GCSREPORT_FETCH_STATS_FINISHED",
              payload: {
                ...gcsReportFlags,
                _stats: result,
                _queryString: {},
                _isStatsLoading: false,
                _isLoading: false,
                _lastStatsFetch: new Date(),
                _lastStatsResource: url
              }
            });
          }
        })
      }
    },
    reactGcsReportShouldFetchStats: state => {
      if (state.gcsReport._shouldFetchStats) {
        return { actionCreator: "doGcsReportFetchStats" };
      }
    }

  }
})