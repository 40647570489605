import { useRef, useState } from "react";
import { connect } from "redux-bundler-react";
import PdfViewer from "./sample-pdf-viewer";
import ScrollableContainer from "./scrollable-container";

const SectionalLegendModal = ({ doDialogClose }) => {
  const modalBodyRef = useRef();
  const [zoom, setZoom] = useState(1);
  return (
    <div className="modal-content" ref={modalBodyRef}>
      <div className="modal-header">
        <h5 className="modal-title">Sectional Legend Modal</h5>
        <button
          onClick={doDialogClose}
          className="close"
          type="button"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body p-0" style={{ position: "relative" }}>
        <div
          className="d-flex justify-content-start px-4 pt-2"
          style={{
            position: "absolute",
            top: 24,
            zIndex: 99,
          }}
        >
          <div className="d-flex btn-group-vertical">
            <button
              className="btn btn-primary mb-1"
              onClick={() => setZoom(zoom + 0.5)}
            >
              <p className="m-0 text-white">
                <b>+</b>
              </p>
            </button>
            <button
              className="btn btn-primary"
              onClick={() => zoom > 1 && setZoom(zoom - 0.5)}
            >
              <p className="m-0 text-white">
                <b>-</b>
              </p>
            </button>
          </div>
        </div>
        <div className="d-flex w-100 align-items-center justify-content-center">
          <ScrollableContainer>
            {
              <PdfViewer
                pdfFile={`${
                  import.meta.env.VITE_URL_BASE_PATH
                }/sectional-legend.pdf`}
                width={
                  modalBodyRef &&
                  modalBodyRef.current &&
                  modalBodyRef.current.clientWidth
                }
                zoom={zoom}
              />
            }
          </ScrollableContainer>
        </div>
      </div>
      <div className="modal-footer">
        <button
          onClick={doDialogClose}
          className="btn btn-sm btn-secondary"
          type="button"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default connect("doDialogClose", SectionalLegendModal);
