import React from "react";
import { connect } from "redux-bundler-react";
import Grid from "../_shared/grid";
import classnames from "../../utils/classnames";

export default connect(
  "selectAllEquipmentItems",
  "selectAllEquipmentIsLoading",
  ({ allEquipmentItems: data, allEquipmentIsLoading }) => {
    const config = {
      columnDefs: [
        {
          resizable: false,
          sortable: true,
          headerName: "Type",
          field: "type",
          width: 80,
          cellRenderer: (params) => {
            const { type, operational } = params.data;
            const cls = classnames({
              mdi: ["aircraft", "batteries", "payload"].indexOf(type) !== -1,
              ms: type === "gcs" || type === "mms",
              "mdi-airplane": type === "aircraft",
              "mdi-car-battery": type === "batteries",
              "mdi-camera": type === "payload",
              "ms-drone-remote": type === "gcs",
              "ms-laptop": type === "mms",
              "text-success": operational === "FMC",
              "text-warning": operational === "PMC",
              "text-danger": operational === "NMC",
              "text-muted": !operational,
            });
            return (
              <div className="d-flex h-100 w-100 align-items-center justify-content-center">
                <h5
                  className="m-0"
                  title={`${operational ? operational : "Status Unknown"}`}
                >
                  <i className={cls} />
                </h5>
              </div>
            );
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Owning Org",
          field: "owning_org_name",
          filter: "agTextColumnFilter",
          cellRenderer: (params) => {
            return (
              <a href={`/${params.data.owning_org_slug}`}>
                {params.data.owning_org_name}
              </a>
            );
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Name",
          field: "name",
          filter: "agTextColumnFilter",
          cellRenderer: (params) => {
            return (
              <a
                href={`/${params.data.owning_org_slug}/${params.data.type}/${params.data.slug}`}
              >
                {params.data.name}
              </a>
            );
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Make",
          field: "make",
          filter: "agTextColumnFilter",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Model",
          field: "model",
          filter: "agTextColumnFilter",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Active Transfer",
          field: "transfer_desc",
          filter: "agTextColumnFilter",
          cellRenderer: (params) => {
            if (!params.data.transfer_desc) return null;
            return (
              <a
                href={`/${params.data.owning_org_slug}/transfers/${params.data.transfer_slug}`}
              >
                {params.data.transfer_desc}
                {params.data.transfer_type === "Temporary" ? (
                  <i className="mdi mdi-alpha-t-circle-outline pl-1"></i>
                ) : (
                  ""
                )}
              </a>
            );
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Transfer Type",
          field: "transfer_type",
          filter: "agTextColumnFilter",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Losing Status",
          field: "from_status",
          filter: "agTextColumnFilter",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Gaining Status",
          field: "to_status",
          filter: "agTextColumnFilter",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "HQ Status",
          field: "hq_status",
          filter: "agTextColumnFilter",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Transfer Status",
          field: "transfer_status",
          filter: "agTextColumnFilter",
        },
      ],
    };
    return (
      <Grid
        isLoading={allEquipmentIsLoading}
        config={config}
        data={data}
        style={{ height: `${window.innerHeight * 0.8}px`, width: "100%" }}
      />
    );
  }
);
