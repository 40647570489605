import React from "react";
import { connect } from "redux-bundler-react";
import Widget from "../_shared/widget";
import APIDataLayer from "../_shared/api-data-layer";
import MissionList from "../../_shared/mission-list";

export default connect(
  "selectOrgsByRoute",
  ({ orgsByRoute: org, daysOut: days, rollup }) => {
    if (!org) return null;
    return (
      <Widget>
        <strong>{`Missions Starting within ${days} days`}</strong>
        <APIDataLayer
          path={`/orgs/${org.slug}/missions/dashboard?withinDays=${days}&rollup=${rollup}`}
          component={MissionList}
        ></APIDataLayer>
      </Widget>
    );
  }
);
