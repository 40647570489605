import React from "react";

export default ({ onRemove, item, itemRenderFn, editing }) => {
  const renderDeleteButton = () => {
    if (!editing) return null;
    return (
      <div className="float-right">
        <button
          className="btn btn-sm btn-ghost-danger"
          onClick={() => {
            onRemove(item);
          }}
        >
          <i className="mdi mdi-delete"></i>
        </button>
      </div>
    );
  };
  return (
    <li className="list-group-item">
      {renderDeleteButton()}
      <div className="overflow-ellipsis">{itemRenderFn(item)}</div>
    </li>
  );
};
