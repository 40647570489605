import React from "react";
import classnames from "classnames";
const StatusRenderer = ({ status }) => {
  const isComplete = status === "Complete";
  const iconClass = classnames({
    "ml-2": true,
    "pr-2": true,
    mdi: true,
    "mdi-checkbox-marked-circle": isComplete,
    "mdi-checkbox-blank-circle": !isComplete,
    "text-success": isComplete,
    "text-secondary": !isComplete,
  });
  return (
    <span title={status}>
      <i className={iconClass}></i>
      {status}
    </span>
  );
};
export default StatusRenderer;
