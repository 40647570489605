import React from "react";
import classnames from "classnames";
import ComponentTree from "../component-tree";
import "../documentation.css";
const NumberedListGroup = ({ style = {}, className, childComponents=[]}) => {
  let _className = classnames({
    "list-group numbered": true,
    [className]: className
  });
  return (
    <ol className={_className} style={style}>
      {
        childComponents.map((item, idx) => {
          return (
            <li className="list-group-item" key={idx}>
              <ComponentTree obj={item} />
            </li>
          );
        })
      }
    </ol>
  );
}
export default NumberedListGroup;