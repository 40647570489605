import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import DeleteButton from "../_shared/delete-button/delete-button";

const ManageTransferCard = ({
  item,
  doAtpmTransferSave,
  doAtpmTransferDelete,
  atpmTransferIsSaving,
}) => {
  const [date, setDate] = useState(item.expiration_date);
  const [edit, setEdit] = useState(false);
  return (
    <div
      className="align-items-center mb-2 border rounded p-2"
      style={{
        display: "grid",
        gap: 8,
        gridTemplateColumns: "1fr 1fr 1fr",
      }}
    >
      <div className="d-flex flex-column w-100">
        <p className="m-0 mb-1 text-muted">
          <small>Name</small>
        </p>
        <p className="m-0">{item.name}</p>
      </div>
      <div className="d-flex flex-column w-100">
        <p className="m-0 mb-1 text-muted">
          <small>Expiration Date</small>
        </p>
        {edit ? (
          <input
            type="date"
            className="form-control"
            name="expiration_date"
            onChange={(e) => setDate(e.target.value)}
            value={date && date.split("T")[0]}
          />
        ) : (
          <p className="m-0">
            {item.expiration_date && item.expiration_date.split("T")[0]}
          </p>
        )}
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-sm btn-outline-info mr-1"
          onClick={() =>
            !edit
              ? setEdit(!edit)
              : doAtpmTransferSave(
                  { ...item, expiration_date: date },
                  () => setEdit(false),
                  true
                )
          }
        >
          {edit ? (
            <i
              className={
                atpmTransferIsSaving
                  ? "mdi mdi-loading mdi-spin mdi-12px"
                  : "mdi mdi-content-save mdi-12px"
              }
            />
          ) : (
            <i
              className={
                atpmTransferIsSaving
                  ? "mdi mdi-loading mdi-spin mdi-12px"
                  : "mdi mdi-pencil mdi-12px"
              }
            />
          )}
        </button>
        <DeleteButton
          deleteText={<i className={`mdi mdi-trash-can mdi-12px`} />}
          onDelete={() => doAtpmTransferDelete(item)}
        />
      </div>
    </div>
  );
};

export default connect(
  "doAtpmTransferDelete",
  "doAtpmTransferSave",
  "selectAtpmTransferIsSaving",
  ManageTransferCard
);
