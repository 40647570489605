import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";

export default createRestBundle({
  name: "missionsAreaData",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/missions/:missionsSlug/missionAreaData",
  putTemplate: "/orgs/:orgSlug/missions/:missionsSlug/missionAreaData/:item.id",
  postTemplate:
    "/orgs/:orgSlug/missions/:missionsSlug/missionAreaData/preparedby",
  deleteTemplate:
    "/orgs/:orgSlug/missions/:missionsSlug/missionAreaData/:item.id",
  fetchActions: ["AUTH_LOGGED_IN", "MISSIONSAREADATA_SAVE_FINISHED"],
  forceFetchActions: ["ACTIVE_TAB_UPDATE", "URL_UPDATED"],
  addons: {
    selectMissionsAreaDataItem: createSelector(
      "selectMissionsAreaDataItems",
      (items) => {
        if (!items || !items.length) return {};
        return items[0];
      }
    ),
  },
});
