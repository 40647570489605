import React, { useEffect } from "react";
import classnames from "classnames"
import { connect } from "redux-bundler-react";
import List from "../_shared/item-list/item-list";
import ItemBoard, { ItemBoardBody, ItemBoardHeader } from "../_shared/item-board/item-board";
import MessageQueryPanel from "./message-board-query-panel";
import ForumSelectDropdown from "./_shared/forum-select-dropdown";
import PermissionsDropdown from "./_shared/permissions-dropdown";
import { renderName } from "./_shared/utils";
import "../_shared/item-board/item-board.css"
import "./message-board.css"

const MessageBoardHome = ({
    messagesItems: items,
    doMessagesSave: sendFunc,
    doMessagesDelete: deleteFunc,
    doMessagesInitUrlParams,
    messagesPageIdx: pageIdx,
    messagesIsLoading: isLoading,
    messagesFlags: flags,
    messagesTotalQueried: totalQueriedItems,
    doMessagesUpdateQueryString: doItemsUpdateQueryString,
    messagesForum
}) => {
    useEffect(() => {
        doMessagesInitUrlParams();
    }, []);

    const renderRatingsBox = (item) => {
        let helpRating = item.rating ? item.rating : 0;
        let badgeColor = helpRating > 0 ? "success" : helpRating < 0 ? "danger" : "primary";
        if (messagesForum === "SHARED") {
            return (<div className="d-flex align-items-between p-1">
                {renderCategoryIcon(item, "px-1 py-0 mt-1")}
            </div >)
        }
        return (
            <div className="d-flex align-items-between p-1">
                <span className={`rounded-pill text-${badgeColor} px-1  mt-1 mb-0`}>
                    <h6 className="mb-0">
                        {helpRating > 0 ? '+' : ''}{helpRating}<span className="mdi mdi-thumbs-up-down ml-1" />
                    </h6>
                </span>
            </div >
        )
    }

    const itemCardPrependedCol = (item) => {
        let orgNameStyle = { overflowInline: "visible", fontSize: item.org_name && item.org_name.length > 3 ? "20px" : "24px" }
        if (messagesForum === "SHARED") {
            return (
                <div className="col-1 px-0 d-flex flex-column ml-0 mt-0 mb-auto my-sm-auto" style={{ overflowInline: "visible" }}>
                    <div className="d-inline p-1 nasa w-auto text-nowrap" style={orgNameStyle}>{item.org_name}</div>
                    <div className="d-none item-card-badge-group d-sm-inline">
                        {renderRatingsBox(item)}
                    </div>
                </div>
            )
        }
        return (
            <div className="col-1 px-0 d-flex flex-column my-auto">
                {renderCategoryIcon(item, "p-1 mt-0")}
                <div className="item-card-badge-group d-none d-xl-inline">
                    {renderRatingsBox(item)}
                </div>
            </div>
        )
    }

    const renderCategoryIcon = (item, spacingAttrs) => {
        const iconColors = {
            [null]: "grey",
            "general": "grey",
            "inquiry": "#3b5998",
            "alert": "rgb(122,31,31)",
        }
        let categoryIconCls = classnames({
            "mdi mdi-help-circle-outline": item.category === "inquiry",
            "mdi mdi-alert-outline": item.category === "alert",
            "mdi mdi-comment-text-outline": item.category === "general" || item.category === null,
            [spacingAttrs]: true,
            "rounded-pill": messagesForum === "SHARED"
        })
        let categoryIconStyle = { fontSize: messagesForum === "SHARED" ? "24px" : "36px", opacity: 0.8, color: iconColors[item.category] }
        if (messagesForum === "SHARED") return <span className={categoryIconCls} style={categoryIconStyle}></span>
        return (<i className={categoryIconCls} style={categoryIconStyle} />)
    }

    const renderCommentCount = (item) => {
        return (
            <div className="text-right" style={{ fontSize: "18px" }}>
                <span className="badge badge-pill badge-danger">
                    <span className="mdi mdi-comment-text-outline mr-2" />
                    {item.comment_count}
                </span>
            </div>
        )
    }
    const itemCardRowLayout = {
        rowCls: "justify-content-between",
        cardInfo: { style: { height: "90px" } },
        titleInfo: { wrapperCls: "col-7 col-sm-7 mt-4" },
        bodyInfo: { wrapperCls: "col-3 col-sm-3 py-2 ml-auto mr-sm-4 mr-0" },
        aboutItemInfo: {
            wrapperCls: "px-0 d-flex flex-column pb-0 align-middle", wrapperStyle: { fontWeight: "lighter", width: "12rem" }
        }
    }
    let boardProps = { totalQueriedItems, pageIdx, doItemsUpdateQueryString }
    let listProps = {
        itemProps: {
            itemType: "Message",
            itemCardRowLayout, itemCardPrependedCol, dateAttr: "create_date",
            renderName, sendFunc, deleteFunc, commentCountComponent: renderCommentCount, includeDeleteBtnDropdown: true
        },
        boardUrl: { params: ["orgSlug"], pattern: "/:orgSlug/message-board" },
        items, flags, isLoading,

    }

    let additionalPermissionFields = [
        { type: "title", text: "Shared Message Board" },
        { type: "field", field: 'show_contact_info', text: "Display my contact info", className: "pb-1" },
        { type: "field", field: 'show_approval_roles', text: "Display my approval roles" },
    ];
    return (
        <ItemBoard {...boardProps}>
            <ItemBoardHeader>
                <div className="col-2 px-0">
                    <ForumSelectDropdown />
                </div>
                <div className="col-8 px-0">
                    <h3 className="mx-auto text-center"><span className="nasa mr-2">MARS</span>Message Board</h3>
                </div>
                <div className="col-2 px-0 d-flex justify-content-end">
                    <PermissionsDropdown board="message-board" fields={additionalPermissionFields} />
                </div>
            </ItemBoardHeader>
            <ItemBoardBody >
                <MessageQueryPanel />
                <List {...listProps} />
            </ItemBoardBody>
        </ItemBoard>
    )
}
export default connect(
    "doMessagesInitUrlParams",
    "doMessagesSave",
    "doMessagesDelete",
    "doMessagesUpdateQueryString",
    "selectMessagesFlags",
    "selectMessagesForum",
    "selectMessagesState",
    "selectMessagesTotalQueried",
    "selectMessagesPageIdx",
    "selectMessagesItems",
    "selectMessagesIsLoading",
    MessageBoardHome
);