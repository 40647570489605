import React from "react";

const BenignApprovedCell = (props) => {
  const { benign_approved } = props.data;
  return (
    <p className={`m-0 text-${benign_approved ? "success" : "danger"}`}>
      {benign_approved ? "YES" : "NO"}
    </p>
  );
};
export default BenignApprovedCell;
