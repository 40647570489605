import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";

export default createRestBundle({
  name: "missionsAdjudications",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/missions/:missionsSlug/adjudications",
  putTemplate: "",
  postTemplate: "/orgs/:orgSlug/missions/:missionsSlug/adjudications",
  deleteTemplate: "/orgs/:orgSlug/missions/:missionsSlug/adjudications",
  fetchActions: [
    "URL_UPDATED",
    "AUTH_LOGGED_IN",
    "MISSIONSALERT_FETCH_FINISHED",
    "PLATFORMS_SAVE_FINISHED",
  ],
  forceFetchActions: ["ACTIVE_TAB_UPDATE"],
  addons: {
    doMissionsAdjudicationsPost:
      (object, callback) =>
      ({ dispatch, store, apiPost }) => {
        let missionSlug = store.selectMissionsByRoute().slug;
        let orgSlug = store.selectOrgsByRoute().slug;
        dispatch({
          type: "MISSIONSADJUDICATIONS_SAVE_ALL_STARTED",
          payload: { isPosting: true },
        });
        apiPost(
          `${store.selectApiRoot()}/orgs/${orgSlug}/missions/${missionSlug}/adjudications`,
          object,
          (err, res, body) => {
            if (err) {
              dispatch({
                type: "MISSIONSADJUDICATIONS_SAVE_ALL_ERROR",
                payload: { isPosting: false, err },
              });
            } else {
              dispatch({
                type: "MISSIONSADJUDICATIONS_SAVE_ALL_FINISHED",
                payload: { isPosting: false },
              });
              callback && callback(body);
            }
          }
        );
      },

    selectMissionsAdjudicationsByTab: createSelector(
      "selectMissionsAlertsAssocAdjudications",
      (missionsAlertsAssocAdjudications) => {
        let itemsObj = {};
        missionsAlertsAssocAdjudications.forEach((alert) => {
          if (itemsObj[alert.tab]) {
            itemsObj[alert.tab] = [
              ...itemsObj[alert.tab],
              ...alert.adjudications,
            ];
          } else itemsObj[alert.tab] = [...alert.adjudications];
        });
        return itemsObj;
      }
    ),
  },
});
