import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";
import Dropdown from "../../_shared/item-board/dropdown";
import ForumSelectDropdown from "../_shared/forum-select-dropdown";
import { capitalize } from "lodash";

const extractNameFromTokenPayload = (tokenPayload) => {
  let regExpFindCN = /(?<=CN=).*?(?=,)/;
  let userCN = regExpFindCN.exec(tokenPayload.subjectDN);
  if (userCN) {
    let firstAndLastName = userCN[0].split(".").slice(0, 2).reverse();
    return firstAndLastName.map((name) => capitalize(name)).join(" ");
  } else return null;
};

const MessageNew = ({
  state,
  orgsByRoute,
  messagesOrgPersonnelData,
  doMessagesSave,
  tokenPayload,
  doDialogClose,
  messagesForum,
}) => {
  const [messageData, setMessageData] = useState({
    ...state.newMessage,
    category: "general",
    org_name: orgsByRoute.name,
  });

  const handleMessageAction = async (e) => {
    const { title, body, category } = messageData;

    const tokenKeyCloakId = tokenPayload.sub;
    let poster = messagesOrgPersonnelData[tokenKeyCloakId];
    let posterApprovalRoles = poster
      ? poster.approvalRoles.map((role) => role.approval_role).join(".")
      : "";

    let posterName = extractNameFromTokenPayload(tokenPayload);
    if (!posterName) {
      if (poster.data.name) {
        posterName = poster.data.name;
      }
    }
    if (messagesForum === "SHARED") {
      const confirmation = window.confirm(
        "You are about to post this message for all MARS organizations to see. Do you wish to proceed?"
      );
      if (confirmation) {
        await doMessagesSave(
          Object.assign(
            {},
            {
              id: messageData.id,
              created_by: tokenKeyCloakId,
              created_by_name: posterName,
              created_by_approval_roles: posterApprovalRoles,
              title: title,
              is_shared: true,
              org_name: messageData.org_name,
              body: body,
              category: category,
              rating: 0,
              comment_count: 0,
              posted_date: new Date(),
              last_comment_date: new Date(2000, 1, 1, 12, 0, 0),
              deleted: 0,
            }
          )
        );
        await doDialogClose();
      }
    } else {
      await doMessagesSave(
        Object.assign(
          {},
          {
            id: messageData.id,
            created_by: tokenKeyCloakId,
            created_by_name: posterName,
            created_by_approval_roles: posterApprovalRoles,
            title: title,
            is_shared: false,
            org_name: orgsByRoute.name,
            body: body,
            category: category,
            rating: 0,
            comment_count: 0,
            posted_date: new Date(),
            last_comment_date: new Date(2000, 1, 1, 12, 0, 0),
            deleted: 0,
          }
        )
      );
      await doDialogClose();
    }
  };
  const handleTextFieldChange = (e) => {
    setMessageData({ ...messageData, [e.currentTarget.name]: e.target.value });
  };
  const handleFieldChange = (update) => {
    setMessageData({ ...messageData, ...update });
  };
  const { category } = messageData;
  let categoryOptions = [
    {
      update: { category: "general" },
      icon: "mdi mdi-comment-processing-outline",
      text: "General",
      displayValue: "general",
      handler: handleFieldChange,
      exitOnClick: true,
    },
    {
      update: { category: "inquiry" },
      icon: "mdi mdi-help-circle-outline",
      text: "Inquiry",
      displayValue: "inquiry",
      handler: handleFieldChange,
      exitOnClick: true,
    },
    {
      update: { category: "alert" },
      icon: "mdi mdi-alert-outline",
      text: "Alert",
      displayValue: "alert",
      handler: handleFieldChange,
      exitOnClick: true,
    },
  ];
  let subjectCls = classnames({
    "form-control": true,
    "is-invalid": state.subject === "",
  });
  let bodyCls = classnames({
    "form-control": true,
    "is-invalid": state.body === "",
  });

  return (
    <div className="modal-content mx-auto">
      <div className="modal-header">
        <h5 className="modal-title">
          {" "}
          <i className="mdi mdi-messagesForum" /> Create post
        </h5>
        <button
          className="close"
          type="button"
          aria-label="Close"
          onClick={() => {
            doDialogClose();
          }}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body" style={{ overflow: "visible" }}>
        <div className="container">
          <div className="row mb-2">
            <div className="offset-2 col-2">
              <h6 className="d-inline">Forum:</h6>
            </div>
            <div className="col-4">
              <ForumSelectDropdown
                btnGroupCls="nasa"
                btnCls="btn-sm btn-outline-dark"
              />
            </div>
          </div>
          {messagesForum === "SHARED" && (
            <div className="row mb-2">
              <div className="offset-2 col-2">
                <h6 className="d-inline">Affiliation:</h6>
              </div>
              <div className="col-2">
                <h5 className="font-weight-bold">{orgsByRoute.name}</h5>
              </div>
            </div>
          )}
          <div className="row mb-2">
            <div className="offset-2 col-2">
              <h6 className="d-inline">Category:</h6>
            </div>
            <div className="col-4">
              <Dropdown
                btnCls="btn-sm btn-secondary"
                title="Category"
                options={categoryOptions}
                stateValueAsTitle={true}
                selectedVal={category}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="offset-2 col-8">
              <input
                name="title"
                className={subjectCls}
                type="text"
                placeholder="Title"
                autoComplete="off"
                maxLength={200}
                onChange={handleTextFieldChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="offset-2 col-8">
              <textarea
                name="body"
                className={bodyCls}
                rows="7"
                autoComplete="off"
                type="text"
                placeholder="Body (optional)"
                value={messageData.body}
                onChange={handleTextFieldChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          disabled={state.readyToSubmit}
          className="btn btn-sm btn-success"
          type="button"
          onClick={handleMessageAction}
        >
          Post
        </button>
      </div>
    </div>
  );
};
export default connect(
  "selectMessagesOrgPersonnelData",
  "selectMessagesForum",
  "doDialogClose",
  "doMessagesSave",
  "selectTokenPayload",
  "selectOrgsByRoute",
  MessageNew
);
