import React from "react";
import RiskAssessmentMatrix from "./risk-assessment-matrix";
import MapHazards from "./map-hazards";
import RawWorksheet from "./raw-worksheet";

class Raw extends React.Component {
  render() {
    return (
      <div>
        <h5>Five Steps of Risk Management</h5>
        <div>
          1. Identify the Hazards 2. Assess the Hazards 3. Develop Controls &
          Make Decisions 4. Implement Controls 5. Supervise and Evaluate
        </div>
        <hr />
        <MapHazards />
        <hr />
        <RawWorksheet />
        <hr />
        <RiskAssessmentMatrix />
      </div>
    );
  }
}

export default Raw;
