import React from "react";

const decryptSignature = (sig) => JSON.parse(atob(sig.split(".")[0]));
const IconCellRenderer = ({ data }) => {
  const { atpm_sig: atpm, suac_sig: suac } = data;
  let atpm_sig = atpm ? decryptSignature(atpm).username : atpm;
  let suac_sig = suac ? decryptSignature(suac).username : suac;
  if (atpm_sig && suac_sig) {
    return (
      <div className="d-flex">
        <i
          className="mdi mdi-checkbox-marked-circle-outline text-success mdi-18px"
          title={atpm_sig}
        />
        <i
          className="mdi mdi-checkbox-marked-circle-outline text-success mdi-18px ml-2"
          title={suac_sig}
        />
      </div>
    );
  } else if (atpm_sig && !suac_sig) {
    return (
      <div className="d-flex">
        <i
          className="mdi mdi-checkbox-marked-circle-outline text-success mdi-18px"
          title={atpm_sig}
        />
        <i className="mdi mdi-checkbox-blank-circle-outline text-danger mdi-18px ml-2" />
      </div>
    );
  } else if (!atpm_sig && !suac_sig) {
    return (
      <div className="d-flex">
        <i className="mdi mdi-checkbox-blank-circle-outline text-danger mdi-18px" />
        <i className="mdi mdi-checkbox-blank-circle-outline text-danger mdi-18px ml-2" />
      </div>
    );
  } else {
    return (
      <div className="d-flex">
        <i className="mdi mdi-checkbox-blank-circle-outline text-danger mdi-18px" />
        <i
          className="mdi mdi-checkbox-marked-circle-outline text-success mdi-18px ml-2"
          title={suac_sig}
        />
      </div>
    );
  }
};

export default IconCellRenderer;
