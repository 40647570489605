import { createSelector } from "redux-bundler";
import createRestBundle from "./create-rest-bundle";
import { uniq, omit, findKey, mapKeys, find, reduce } from "lodash"

export default createRestBundle({
    name: "messages",
    uid: "slug",
    prefetch: true,
    persist: false,
    routeParam: "messageSlug",
    getTemplate: "/orgs/:orgSlug/message-board?:queryString",
    putTemplate: "/orgs/:orgSlug/message-board/:item.slug",
    postTemplate: "/orgs/:orgSlug/message-board",
    deleteTemplate: "/orgs/:orgSlug/message-board/:item.slug",
    fetchActions: [],
    forceFetchActions: ["MESSAGES_SAVE_FINISHED", "MESSAGES_DELETE_FINISHED"],

    initialData: {
        _queryString: {
            _pageIdx: '1',
            _sortBy: 'relevant',
            _searchTerms: '',
            _sortDir: 'ASC',
            _filterByType: 'none',
            _targetId: '',
            _forum: 'SHARED',
            _includeComments: 'false'
        },
        _pageIdx: '1',
        _sortBy: 'relevant',
        _searchTerms: '',
        _sortDir: 'ASC',
        _filterByType: 'none',
        _targetId: '',
        _forum: 'SHARED',
        _includeComments: 'false',
        _messagesFilterSettings: {},
        _total_queried_messages: 0,
        _userSettings: {},
        _adjacentPageLoading: false
    },

    urlParamSelectors: ["selectMessagesFormattedQueryString"],
    reduceFurther: (state, { type, payload }) => {
        switch (type) {
            case "MESSAGES_SAVE_FINISHED":
            case "MESSAGES_DELETE_FINISHED":
                return Object.assign({}, state, { ...payload, _shouldFetch: true });
            case "MESSAGES_ERROR":
            case "MESSAGES_ADJACENT_PAGE_LOADING":
            case "MESSAGES_SETTINGS_UPDATED":
            case "MESSAGES_SETTINGS_SAVE_ERROR":
            case "MESSAGES_QUERY_STRING_UPDATED":
            case "MESSAGES_QUERY_COMPLETE":
            case "MESSAGES_FETCH_ERROR":
            case "MESSAGES_SETTINGS_FETCHED":
            case "MESSAGES_INIT_URL_PARAMS":
                return Object.assign({}, state, payload);

            case "URL_UPDATED":
                // If the updated url is outside of the message-board module and the localStorage item exists, reset the localStorage item to the original 
                if (!/\/message-board/.test(payload.url)) {
                    if (window.localStorage.getItem(`uas-mgr-ui-messages-query-data`)) {
                        window.localStorage.removeItem('uas-mgr-ui-messages-query-data')
                    }
                }
            default:
                return state;
        }
    },

    addons: {
        doMessagesMovingToAdjacentPage: () => ({ dispatch }) => {
            dispatch({ type: "MESSAGES_ADJACENT_PAGE_LOADING", payload: { _adjacentPageLoading: true } })
        },

        // Initialize messages query params. Usually only needed the first time
        doMessagesInitUrlParams: () => ({ dispatch }) => {
            let queryStorageItem = window.localStorage.getItem(`uas-mgr-ui-messages-query-data`);
            let defaultQueryData = { pageIdx: '1', sortBy: 'relevant', searchTerms: '', sortDir: 'ASC', filterByType: 'none', targetId: 'none', includeComments: 'false' }

            let updatedQueryData = { ...defaultQueryData }
            if (queryStorageItem) {
                let queryData = JSON.parse(queryStorageItem);
                updatedQueryData = { ...defaultQueryData, ...queryData }
            }
            else {
                updatedQueryData = { ...defaultQueryData, forum: 'SHARED' }
                window.localStorage.setItem(`uas-mgr-ui-messages-query-data`, JSON.stringify(updatedQueryData))
            }
            updatedQueryData = mapKeys(updatedQueryData, (val, key) => '_' + key)
            dispatch({
                type: 'MESSAGES_INIT_URL_PARAMS', payload: {
                    _queryString: updatedQueryData,
                    ...updatedQueryData,
                    _shouldFetch: true,
                }
            })
        },

        // Update the total number of messages queried ( not how many is on the current page, but instead the total queried). The value for totalQueried is determined by the API
        doMessagesUpdateTotalQueried: (totalQueried) => ({ dispatch }) => {
            dispatch({ type: 'MESSAGES_QUERY_COMPLETE', payload: { _total_queried_messages: totalQueried } })
        },

        // Update the query string alongside the localStorage item 
        doMessagesUpdateQueryString: (update) => ({ dispatch, store }) => {
            let q = store.selectMessagesQueryString();
            let updatedQueryStr = { ...q, ...update };
            let storedQuery = window.localStorage.getItem('uas-mgr-ui-messages-query-data');
            let queryParams = {};

            if (storedQuery) {
                queryParams = JSON.parse(storedQuery)
                Object.keys(update).forEach(key => {
                    queryParams[[key.substring(1)]] = `${update[key]}`
                })
            }
            else {
                let routeInfo = store.selectRouteInfo();
                let messagesPageIdx = store.selectMessagesPageIdx();
                let messagesSearchTerms = store.selectMessagesSearchTerms();
                let messagesFilterSettings = store.selectMessagesFilterSettings();
                let messagesSortSettings = store.selectMessagesSortSettings();
                let messagesIncludeComments = store.selectMessagesIncludeComments();
                let messagesSortDirection = store.selectMessagesSortDirection();
                let messagesForum = store.selectMessagesForum();
                queryParams = {
                    targetId: routeInfo.params.messageSlug,
                    pageIdx: messagesPageIdx,
                    searchTerms: messagesSearchTerms,
                    filterByType: messagesFilterSettings['filterByType'],
                    sortBy: messagesSortSettings['sortBy'],
                    includeComments: messagesIncludeComments,
                    sortDir: messagesSortDirection,
                    forum: messagesForum,
                }
            }


            if (updatedQueryStr._filterByType) {
                let filterSettings = q._messagesFilterSettings;
                let omitList = []
                let newFilterSettings = { ...filterSettings, filterByType: updatedQueryStr._filterByType };

                Object.keys(newFilterSettings).forEach(setting => {
                    if (newFilterSettings[setting] === 'none') omitList.push(setting)
                })
                updatedQueryStr = { ...updatedQueryStr, _messagesFilterSettings: omit(newFilterSettings, omitList) }
            }
            // If category filter changes or queryString changes, reset the pageIdx to 1
            let filterByTypeChanged = queryParams.filterByType && queryParams.filterByType !== q._filterByType;
            let searchTermsChanged = queryParams.searchTerms && queryParams.searchTerms !== q._searchTerms;
            let forumChanged = queryParams.forum && queryParams.forum !== q._forum;

            if (filterByTypeChanged || searchTermsChanged || forumChanged) {
                updatedQueryStr._pageIdx = '1'
                queryParams.pageIdx = '1'
            }

            window.localStorage.setItem('uas-mgr-ui-messages-query-data', JSON.stringify(queryParams))
            dispatch({
                type: "MESSAGES_QUERY_STRING_UPDATED",
                payload: {
                    _queryString: updatedQueryStr,
                    ...updatedQueryStr,
                    _shouldFetch: true,
                },
            });
        },

        selectMessagesSearchTerms: (state) => {
            return state.messages._searchTerms;
        },
        selectMessagesSortSettings: (state) => {
            return { sortBy: state.messages._sortBy }
        },
        selectMessagesFilterSettings: (state) => {
            return { filterByType: state.messages._filterByType }
        },
        selectMessagesPageIdx: (state) => {
            return state.messages._pageIdx;
        },
        selectMessagesQueryString: (state) => {
            return state.messages._queryString;
        },
        selectMessagesIncludeComments: (state) => {
            return state.messages._includeComments;
        },
        selectMessagesSortDirection: (state) => {
            return state.messages._sortDir
        },
        selectMessagesForum: (state) => {
            return state.messages._forum;
        },
        selectMessagesUserMemberships: createSelector(
            "selectTokenRolesJoined",
            (tokenRolesJoined) => {
                return uniq(tokenRolesJoined.map(role => role.split('.')[0])).filter(group => group !== 'PUBLIC')
            }

        ),

        selectMessagesLoadedInQueue: (state) => {
            return reduce(state.messages, (result, value, key) => {
                if (key[0] !== '_') (result || (result = [])).push({ ...value, nthrow: parseInt(value.nthrow) })
                return result
            }, [])

        },
        selectMessagesAdjacentInQueue: createSelector(
            "selectMessagesLoadedInQueue",
            "selectMessagesByRoute",
            (messagesLoadedInQueue, messagesByRoute) => {
                if (!messagesByRoute) return { previous: null, next: null };
                let prev = find(messagesLoadedInQueue, { nthrow: parseInt(messagesByRoute.nthrow) - 1 });
                let next = find(messagesLoadedInQueue, { nthrow: parseInt(messagesByRoute.nthrow) + 1 });
                return { previous: prev, next: next };
            }
        ),

        selectMessagesFormattedQueryString: createSelector(
            "selectRouteInfo",
            "selectMessagesPageIdx",
            "selectMessagesSearchTerms",
            "selectMessagesFilterSettings",
            "selectMessagesSortSettings",
            "selectMessagesIncludeComments",
            "selectMessagesSortDirection",
            "selectMessagesForum",
            (routeInfo, messagesPageIdx, messagesSearchTerms, messagesFilterSettings, messagesSortSettings, messagesIncludeComments, messagesSortDirection, messagesForum) => {
                let queryItems = []
                if (routeInfo.params.messageSlug) {
                    // Assigning targetId will tell the backend to choose the pageIdx relative to the position of the targetId, instead of pageIdx
                    queryItems.push(`targetId=${encodeURIComponent(routeInfo.params.messageSlug)}`)
                }

                if (messagesPageIdx) {
                    queryItems.push(`pageIdx=${encodeURIComponent(messagesPageIdx)}`)
                }

                if (messagesIncludeComments) {
                    queryItems.push(`includeComments=${encodeURIComponent(messagesIncludeComments)}`)
                }

                if (messagesSearchTerms.length > 0) {
                    queryItems.push(`searchTerms=${encodeURIComponent(messagesSearchTerms)}`)
                }

                if (Object.keys(messagesFilterSettings).length > 0) {
                    Object.keys(messagesFilterSettings).forEach(s => {
                        if (messagesFilterSettings[s] && messagesFilterSettings[s] != 'none') queryItems.push(`${s}=${messagesFilterSettings[s].toLowerCase()}`)
                    })
                }

                if (Object.keys(messagesSortSettings).length > 0) {
                    Object.keys(messagesSortSettings).forEach(s => {
                        if (messagesSortSettings[s]) queryItems.push(`${s}=${messagesSortSettings[s].toLowerCase()}`);
                    })
                }

                if (messagesSortDirection) {
                    queryItems.push(`sortDir=${encodeURIComponent(messagesSortDirection)}`);
                }
                if (messagesForum) {
                    queryItems.push(`forum=${messagesForum}`)
                }
                if (!queryItems.length) return {}
                return { queryString: queryItems.join("&") }
            }
        ),
        selectMessagesOrgPersonnelData: createSelector(
            "selectPersonnelItems",
            "selectApprovalRolesItems",
            (personnelItems, approvalRolesItems) => {
                return Object.fromEntries(personnelItems.map(person => {
                    return [person.keycloak_id, { approvalRoles: approvalRolesItems.filter(item => item.keycloak_id === person.keycloak_id), data: { ...person } }]
                }))
            }
        ),
        selectMessagesTotalQueried: createSelector(
            "selectMessagesState",
            (messagesState) => {
                let firstMessageFound = findKey(messagesState, (obj) => { return obj && obj.full_count })
                if (firstMessageFound && messagesState[firstMessageFound].full_count) return messagesState[firstMessageFound].full_count
                return '0'
            }
        ),

        selectMessagesCurrentPageItem: createSelector(
            "selectRouteInfo",
            "selectMessagesState",
            (routeInfo, messagesState) => {
                return messagesState[routeInfo.params.messageSlug]
            }
        ),
        selectMessagesActiveUserDetails: createSelector(
            "selectMessagesCurrentPageItem",
            "selectTokenPayload",
            (messagesCurrentPageItem, tokenPayload) => {
                if (messagesCurrentPageItem) {
                    let activeUserKeycloak = tokenPayload.sub;
                    let activeUserPreferredName = tokenPayload.preferred_username
                    let activeUser = { keycloak_id: tokenPayload.sub, name: activeUserPreferredName, approval_roles: {} }
                    if (messagesCurrentPageItem && messagesCurrentPageItem.people && messagesCurrentPageItem.people[activeUserKeycloak]) activeUser = messagesCurrentPageItem.people[activeUserKeycloak]
                    return activeUser
                }
                else return null
            }
        ),
        selectMessagesPosterDetails: createSelector(
            "selectMessagesCurrentPageItem",
            (messagesCurrentPageItem) => {
                if (messagesCurrentPageItem) {
                    let posterKeycloak = messagesCurrentPageItem.created_by;
                    let activeUser = { keycloak_id: posterKeycloak, name: messagesCurrentPageItem.created_by_name, approval_roles: messagesCurrentPageItem.created_by_approval_roles }
                    if (messagesCurrentPageItem && messagesCurrentPageItem.people && messagesCurrentPageItem.people[posterKeycloak]) activeUser = messagesCurrentPageItem.people[posterKeycloak]
                    return activeUser
                }
                else return null
            }
        )
    }
});