import React from "react";
import icons from "./hazard-icons";

class HazardCard extends React.Component {
  constructor(props) {
    super(props);
    this.renderName = this.renderName.bind(this);
  }

  renderName() {
    const { item } = this.props;
    const { type, count } = item;
    const text = `${type.toUpperCase()} (${count})`;
    return (
      <div
        className="font-weight-bold overflow-ellipsis"
        title={text}
        style={{ maxHeight: "3rem", overflow: "hidden" }}
      >
        {text}
      </div>
    );
  }

  render() {
    const { item } = this.props;
    return (
      <div className="col-md-4">
        <div className="card">
          <div className="card-body p-0 d-flex align-items-center">
            <i
              className={`${icons[item.type]} bg-secondary p-3 mr-2`}
              title={item.type}
            ></i>
            <div style={{ overflow: "hidden" }}>{this.renderName()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default HazardCard;
