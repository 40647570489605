import React from "react";
import { connect } from "redux-bundler-react";
import LookAhead from "./cards/mission-look-ahead";
import MissionsByStatus from "./cards/missions-by-status";
import ApprovalRoles from "./cards/approval-roles-card";
import CountCard from "./cards/count-card";
import NewDashboard from "./new-dashboard";

class Dashboard extends React.Component {
  componentDidMount() {
    const { doDashboardCountsFetch, dashboardCountsIsLoading } = this.props;
    if (!dashboardCountsIsLoading) {
      doDashboardCountsFetch();
    }
  }
  render() {
    const { routeParams, readFileCountObject } = this.props;
    let readFileColor =
      readFileCountObject.red > 0 &&
      readFileCountObject.red >= readFileCountObject.yellow
        ? "danger"
        : readFileCountObject.yellow > 0
        ? "warning"
        : "";
    return (
      <div className="container-fluid mt-4">
        {readFileColor && (
          <div className={`alert alert-${readFileColor}`} role="alert">
            <a
              style={{ textDecoration: "none", color: "inherit" }}
              href="/read-file"
            >
              <h5 className="alert-heading">Incomplete Read File Items</h5>
              <p>
                There is new required reading in your Read File that you need to
                complete before proceeding. Click here to view your Read File.
              </p>
            </a>
          </div>
        )}
        <NewDashboard />
        <div className="row">
          <div className="col">
            <CountCard
              title="Operational Aircraft"
              icon="mdi mdi-airplane"
              bgcolor="pink"
              txtcolor="white"
              countClass="operational"
              countGroup={
                routeParams.orgSlug === "hq" ? "total" : routeParams.orgSlug
              }
            />
            <CountCard
              title="Approval Roles"
              icon="mdi mdi-account-check"
              bgcolor="teal"
              txtcolor="white"
              countClass="approval_role"
              countGroup={
                routeParams.orgSlug === "hq" ? "total" : routeParams.orgSlug
              }
            />
            <CountCard
              title={
                "Approval Roles" +
                (routeParams.orgSlug === "hq" ? " (incl. sub orgs)" : "")
              }
              icon="mdi mdi-account-check"
              bgcolor="warning"
              txtcolor="white"
              countClass="approval_role_distinct"
              countGroup="total"
            />
            <LookAhead daysOut={14} />
            <ApprovalRoles />
          </div>
          <div className="col">
            <CountCard
              title="Mission Types"
              icon="ms ms-drone-wing"
              bgcolor="purple"
              txtcolor="white"
              countClass="mission_type"
              countGroup={
                routeParams.orgSlug === "hq" ? "total" : routeParams.orgSlug
              }
            />
            <CountCard
              title="Flight Category"
              icon="ms ms-drone-quad"
              bgcolor="info"
              txtcolor="white"
              countClass="flight_category"
              countGroup={
                routeParams.orgSlug === "hq" ? "total" : routeParams.orgSlug
              }
            />
            <MissionsByStatus
              title="Pending Approval"
              status="pending-approval"
            />
            <MissionsByStatus title="Currently Active" status="active" />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "selectRouteParams",
  "doDashboardCountsFetch",
  "selectDashboardCountsIsLoading",
  "selectReadFileCountObject",
  Dashboard
);
