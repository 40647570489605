import React from "react";
import Grid from "../_shared/grid";
import {
  EquipmentCostRenderer,
  EquipmentRenderer,
  SenderStatusRenderer,
  ReceiverStatusRenderer,
  ReviewStatusRenderer,
  CreateDateRenderer,
  ShipDateRenderer,
  ExpDateRenderer,
  TrackingRenderer,
  TransferLinkRenderer,
} from "./grid-components";
import { costComparator } from "../_shared/helper";

const TransfersGrid = (props) => {
  const config = {
    columnDefs: [
      {
        resizable: true,
        sortable: true,
        headerName: "Link",
        field: "from_org",
        filter: "agTextColumnFilter",
        width: 150,
        cellRenderer: TransferLinkRenderer,
      },
      {
        resizable: true,
        sortable: true,
        headerName: "From",
        field: "from_org",
        filter: "agTextColumnFilter",
        width: 100,
      },
      {
        resizable: true,
        sortable: true,
        headerName: "To",
        field: "to_org",
        filter: "agTextColumnFilter",
        width: 100,
      },
      {
        resizable: true,
        sortable: true,
        headerName: "Description",
        field: "name",
        filter: "agTextColumnFilter",
      },
      {
        resizable: true,
        sortable: true,
        headerName: "Equipment",
        field: "equipment_status",
        filter: "agTextColumnFilter",
        cellRenderer: EquipmentRenderer,
        width: 300,
      },
      {
        resizable: true,
        sortable: true,
        headerName: "Date Created",
        field: "create_date",
        filter: "agTextColumnFilter",
        cellRenderer: CreateDateRenderer,
      },
      {
        resizable: true,
        sortable: true,
        headerName: "Date Shipped",
        field: "ship_date",
        filter: "agTextColumnFilter",
        cellRenderer: ShipDateRenderer,
      },
      {
        resizable: true,
        sortable: true,
        headerName: "Type",
        field: "type",
        filter: "agTextColumnFilter",
      },
      {
        resizable: true,
        sortable: true,
        headerName: "Sender Status",
        field: "from_status",
        filter: "agTextColumnFilter",
        cellRenderer: SenderStatusRenderer,
      },
      {
        resizable: true,
        sortable: true,
        headerName: "Receiver Status",
        field: "to_status",
        filter: "agTextColumnFilter",
        cellRenderer: ReceiverStatusRenderer,
      },
      {
        resizable: true,
        sortable: true,
        headerName: "Review Status",
        field: "hq_status",
        filter: "agTextColumnFilter",
        cellRenderer: ReviewStatusRenderer,
      },
      {
        resizable: true,
        sortable: true,
        headerName: "Expiration Date",
        field: "exp_date",
        filter: "agTextColumnFilter",
        cellRenderer: ExpDateRenderer,
      },
      {
        resizable: true,
        sortable: true,
        headerName: "Total Equip. Cost",
        field: "equipment_status",
        filter: "agTextColumnFilter",
        cellRenderer: EquipmentCostRenderer,
        comparator: costComparator,
      },
      {
        resizable: true,
        sortable: true,
        headerName: "Tracking No.",
        field: "tracking_no",
        filter: "agTextColumnFilter",
        cellRenderer: TrackingRenderer,
      },
    ],
    frameworkComponents: {},
  };
  return (
    <Grid
      config={config}
      data={props.data}
      style={{ height: `${window.innerHeight * 0.8}px`, width: "100%" }}
    />
  );
};

export default TransfersGrid;
