import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "missionsFlights",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/missions/:missionsSlug/flights",
  putTemplate: "/orgs/:orgSlug/missions/:missionsSlug/flights/:item.id",
  postTemplate: "/orgs/:orgSlug/missions/:missionsSlug/flights",
  deleteTemplate: "/orgs/:orgSlug/missions/:missionsSlug/flights/:item.id",
  fetchActions: ["AUTH_LOGGED_IN"],
  forceFetchActions: ["ACTIVE_TAB_UPDATE", "URL_UPDATED"],
});
