import React from "react";
import SearchInput from "../_shared/search-input/search-input";
import AircraftCard from "./aircraft-card";
import { orderBy, find } from "lodash";
import RoleFilter from "../../app-containers/context-providers/role-filter";
import OrgFilter from "../../app-containers/context-providers/org-filter";
import { connect } from "redux-bundler-react";
import { differenceInDays } from "date-fns";
class AircraftCardRegion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      requestedSectionShowing: props.equipmentRequestItems.length > 0,
      approvedSectionShowing: true,
      unapprovedSectionShowing: false,
      deactivationSectionShowing: false,
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.renderRegion = this.renderRegion.bind(this);
    this.handleSectionToggled = this.handleSectionToggled.bind(this);
    this.renderRequestsSection = this.renderRequestsSection.bind(this);
  }


  handleSearch(e) {
    this.setState({
      searchTerm: e.target.value,
    });
  }
  handleSectionToggled(e) {
    let attr = e.currentTarget.name;
    e.currentTarget.parentElement.nextElementSibling.classList.toggle("show");
    this.setState({ [attr]: !this.state[attr] });
  }

  renderRegion(items, directory = 'aircraft') {
    const { equipmentRequestItems } = this.props;
    if (items.length === 0) {
      return (
        <div className="container-fluid my-auto h-100 w-100 d-flex justify-content-around py-2">
          <div
            className="d-flex flex-row mx-lg-4 mb-3"
            style={{ fontSize: "2.5rem", color: "#8B0000" }}
          >
            <div className="">
              <i
                className="mdi mdi-alert-outline"
                style={{
                  textShadow: "1px 1px 2px rgba(0,0,0,.15)",
                }}
              />
            </div>
            <div className="text-value-sm my-auto text-center">
              Nothing found.
            </div>
          </div>
        </div>
      );
    }
    
    return (
      <div className="row">
        {items.map((item, i) => {
          let equipmentRequestExists = find(equipmentRequestItems, ['existing_id', item.id ])
          return <AircraftCard item={item} key={i} directory={directory} renderPendingBanner={equipmentRequestExists} />;
        })}
      </div>
    );
  }


  renderRequestsSection() {
    const { equipmentRequestItems } = this.props;
    let requested = equipmentRequestItems.filter(item => item.type === 'aircraft');
    requested = requested.map(r => {
      return {
        ...r, isNew: differenceInDays(Date.now(), new Date(r.create_date)) <= 30,
        isRequest:true
      }
    });
    requested = orderBy(requested, ['isNew', 'create_date'], ['desc', 'desc']);
    return (
      <div className="card w-100 mb-0"
        style={{ backgroundColor: "#e4e5e6" }}
      >
        <div className="card-header d-flex justify-content-between">
          <button
            name="requestedSectionShowing"
            className="btn d-flex justify-content-between w-100"
            onClick={this.handleSectionToggled}
          >
            <h5 className="text-primary">
              <span className="ms ms-drone-wing mr-2" />
              Requests Awaiting Review
            </h5>
            <span
              className={`mdi mdi-${this.state.requestedSectionShowing ? "minus" : "plus"
                }`}
              style={{ fontSize: "larger" }}
            />
          </button>
        </div>
        <div
          className={`collapse card-body ${this.state.requestedSectionShowing ? "show" : ""
            }`}
        >
          {this.renderRegion(requested, 'aircraft/equipment-requests')}
        </div>
      </div>
    )
  }

  render() {
    const { items, orgsByRoute, doUpdateRelativeUrl, equipmentRequestUserIsAdmin: userIsAdmin } = this.props;
    const { searchTerm } = this.state;
    const matcher = new RegExp(searchTerm, "ig");
    const filtered = items.filter((item) => {
      const testString = Object.values(item).join(" ");
      return testString.match(matcher);
    });
    const isNewIteratree = (o) => {
      return differenceInDays(Date.now(), new Date(o.create_date)) <= 30;
    }
    const isApprovedIteratree = (o) => {
      if (o.usace_approved === true) return 1;
      else if (o.usace_approved === false) return 0;
      return -1;
    };
    const operationalIteratree = (o) => {
      if (o.operational === "FMC") return 1;
      else if (o.operational === "PMC") return 0;
      return -1;
    };
    const isPendingDeactivation = (o) => {
      if (o.pending_deactivation === true) return 1;
      else if (o.pending_deactivation === false) return 0;
    }
    const isDisplayOnly = (o) => {
      if (o.deactivated === "Display Only") return 1;
      else return 0;
    }
    const sorted = orderBy(
      filtered,
      [isDisplayOnly, isPendingDeactivation, isNewIteratree, isApprovedIteratree, operationalIteratree, "name"],
      ["asc","asc","desc", "desc", "desc", "asc"]
    );


    let approved = [];
    let unapproved = [];
    sorted.forEach((s) => {
      if (!s.deactivated || s.deactivated==='Display Only') {
        if (s.usace_approved) approved.push(s);
        else unapproved.push(s);
      }
    });
    return (
      <div>
        <div className="row mb-3">
          <div className="col-sm-auto pl-0">
            <button
              className="btn btn-primary mr-auto ml-0"
              onClick={() => {
                doUpdateRelativeUrl(`/${orgsByRoute.slug}/aircraft/new`);
              }}
            >
              <h5 className="m-auto">
                <span>{userIsAdmin ? "Create" : "Request"} New</span>
                <span className="mdi mdi-plus ml-2" />
              </h5>
            </button>
          </div>
          <div className="col-sm pr-0 my-auto">
            <SearchInput
              className=""
              onChange={this.handleSearch}
              value={this.state.searchTerm}
            />
          </div>
        </div>

        <div className="row mt-3 accordion">
          {this.renderRequestsSection()}
          <div
            className="card w-100 mb-0"
            style={{ backgroundColor: "#e4e5e6" }}
          >
            <div className="card-header d-flex justify-content-between">
              <button
                name="approvedSectionShowing"
                className="btn d-flex justify-content-between w-100"
                onClick={this.handleSectionToggled}
              >
                <h5 className="text-success">
                  <span className="ms ms-drone-wing mr-2" />
                  USACE Approved
                </h5>
                <span
                  className={`mdi mdi-${this.state.approvedSectionShowing ? "minus" : "plus"
                    }`}
                  style={{ fontSize: "larger" }}
                />
              </button>
            </div>
            <div
              className={`collapse card-body ${this.state.approvedSectionShowing ? "show" : ""
                }`}
            >
              {this.renderRegion(approved)}
            </div>
          </div>
          <div className="card w-100 mb-0" style={{ backgroundColor: "#e4e5e6" }}>
            <div className="card-header d-flex justify-content-between">
              <button
                name="unapprovedSectionShowing"
                className="btn d-flex justify-content-between w-100"
                onClick={this.handleSectionToggled}
              >
                <h5 style={{ color: "#8B0000" }}>
                  <span className="ms ms-drone-wing mr-2" />
                  USACE Unapproved
                </h5>
                <span
                  className={`mdi mdi-${this.state.unapprovedSectionShowing ? "minus" : "plus"
                    }`}
                  style={{ fontSize: "larger" }}
                />
              </button>
            </div>
            <div
              className={`collapse card-body ${this.state.unapprovedSectionShowing ? "show" : ""
                }`}
            >
              {this.renderRegion(unapproved)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "selectEquipmentRequestItemsOrderedByStatus",
  "selectEquipmentRequestUserIsAdmin",
  "doEquipmentRequestInitUrlParams",
  "doUpdateRelativeUrl",
  "selectOrgsByRoute",
  AircraftCardRegion
);
