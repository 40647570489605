import React from "react";
import classnames from "classnames";
import { connect } from "redux-bundler-react";
import { uniqBy } from "lodash";
import FaqsGroupList from "./faqs-group-list";
import DeleteSelectionConfirm from "./modals/delete-selection-confirm";
import Loader from "../../app-components/loader"

import ActionsDropdown from "./actions-dropdown"
import RoleFilter from "../../app-containers/context-providers/role-filter";
class TabsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.handleTabClick = this.handleTabClick.bind(this);
        this.renderTabs = this.renderTabs.bind(this);

        this.renderTabContent = this.renderTabContent.bind(this);
        this.renderAddTab = this.renderAddTab.bind(this)

        this.tabOnBlur = this.tabOnBlur.bind(this)
        this.tabNameChanged = this.tabNameChanged.bind(this)
        this.onItemDragEnter = this.onItemDragEnter.bind(this);
        this.deleteTabBtnClicked = this.deleteTabBtnClicked.bind(this);

        this.addTabBtnClicked = this.addTabBtnClicked.bind(this)
        this.deleteTab = this.deleteTab.bind(this)
        this.tabMoveHandler = this.tabMoveHandler.bind(this)
        this.preventDropItem = this.preventDropItem.bind(this)
        this.tabEditBtnClicked = this.tabEditBtnClicked.bind(this)
        this.tabCancelEditBtnClicked = this.tabCancelEditBtnClicked.bind(this)
        this.renderSelectControls = this.renderSelectControls.bind(this)
        this.sortDirectionChanged = this.sortDirectionChanged.bind(this)
        this.selectBtnClicked = this.selectBtnClicked.bind(this)
        this.deleteSelection = this.deleteSelection.bind(this)

    }

    preventDropItem(e) {
        e.preventDefault();
        return false;
    }
    componentDidMount() {
        const { dispatch } = this.props;
        const { items, orderedTabNames } = this.props.state
        let itemValues = Object.values(items);
        let uniqueCats = uniqBy(itemValues, 'category');
        // New Categories found
        if (uniqueCats.length > orderedTabNames.length) {
            dispatch({ type: "HANDLE_NEW_TAB" })
        }
    }

    async deleteTab(idx) {
        const { dispatch, handleCRUD } = this.props
        const { orderedTabNames } = this.props.state
        dispatch({ type: "HANDLE_DELETE_TAB", idx: idx })
        await handleCRUD({ operation: 'deleted', id: 'all' }, orderedTabNames[idx])
    }

    handleTabClick(e) {
        if (e.target.name == "moveTabLeft" || e.target.name == "moveTabRight") return;
        const { dispatch, doUpdateActiveTab } = this.props
        let child = e.currentTarget;
        let parent = child.parentNode;
        let idx = Array.prototype.indexOf.call(parent.children, child)
        dispatch({ type: "HANDLE_ACTIVE_TAB_CHANGED", value: idx })
        dispatch({ type: "HANDLE_UNSAVED_ITEMS" })
        doUpdateActiveTab(idx);
    }

    // When focus moves away from current tab
    tabOnBlur(e) {
        const { dispatch } = this.props
        const { orderedTabNames } = this.props.state;
        let idx = e.currentTarget.value
        if (orderedTabNames[idx].length == 0) {     //If the tab name is blank, generate a temporary tab name
            dispatch({ type: "HANDLE_BLANK_TAB_NAME", idx: idx })
        }
    }

    // Handles when a FAQ is dragged over a tab by changing active tab
    onItemDragEnter = (e) => {
        const { dispatch, doUpdateActiveTab } = this.props;
        e.preventDefault();
        if (e.target.name == "editTabNameInput") { return; }
        if (e.currentTarget.classList.contains("nav-item")) {
            e.dataTransfer.dropEffect = "move"
            dispatch({ type: "HANDLE_ACTIVE_TAB_CHANGED", value: e.currentTarget.value })
            dispatch({ type: "HANDLE_UNSAVED_ITEMS" })
            doUpdateActiveTab(e.currentTarget.value);
            e.persist();
        }
    }

    // Handles when a tab's delete button is clicked
    deleteTabBtnClicked = async (e) => {
        const { doDialogOpen } = this.props;
        const { items, orderedTabNames } = this.props.state
        let tabIdx = e.currentTarget.parentElement.parentElement.parentElement.value
        let itemKeys = Object.keys(items).filter((key) => {
            return items[key].deleted == 0 && items[key].category == orderedTabNames[tabIdx]
        })
        // If tab being deleted is empty, do an immediate delete
        if (itemKeys.length == 0) await this.deleteTab(tabIdx)

        // Else if items in tab, open dialog to confirm deletion.
        else {
            doDialogOpen({
                content: DeleteSelectionConfirm,
                props: { scrollable: true, mode: "tab", deleteHandler: this.deleteTab, tabIdx: tabIdx }
            });
        }
    }

    // Triggered when a tab's LEFT and RIGHT buttons are pressed
    tabMoveHandler(e) {
        e.preventDefault();
        const { dispatch, state, doUpdateActiveTab } = this.props
        const { orderedTabNames, activeTab } = state
        let btnName = e.currentTarget.name
        let diff = 0;
        if (btnName == "moveTabLeft") diff = -1;
        else if (btnName == "moveTabRight") diff = 1
        let numerator = activeTab + diff
        let dest = numerator < 0 ? (orderedTabNames.length - 1) : numerator % orderedTabNames.length;
        dispatch({ type: "HANDLE_TAB_MOVE", dest: dest, src: activeTab })
        doUpdateActiveTab(dest);
    }

    tabEditBtnClicked() {
        const { doFaqsSave } = this.props
        const { editingTabNameIdx, activeTab, orderedTabNames, items } = this.props.state

        let currentCategoryName = orderedTabNames[activeTab];

        const { dispatch } = this.props

        if (editingTabNameIdx != null) {
            dispatch({ type: "HANDLE_SAVING_TAB_DATA", value: true })
            // Save remaining items in this tab
            Object.keys(items)
                .filter(key => items[key].category == currentCategoryName && items[key].deleted == 0)
                .sort((a, b) => {
                    if (items[a].display_order > items[b].display_order) return 1;
                    if (items[a].display_order < items[b].display_order) return -1;
                    return 0;
                })
                .forEach((key) => {
                    if (items[key].fromStorage && !items[key].deleted) {       // Item is already in database so update it with any possible new data
                        let { id, category, category_order } = items[key]
                        doFaqsSave(Object.assign({}, { id, category, category_order }))
                    }
                })
        }
        dispatch({ type: "HANDLE_EDITING_TAB_NAME", value: editingTabNameIdx != null ? null : activeTab })
    }


    tabNameChanged(e) {
        const { dispatch } = this.props
        const { activeTab, orderedTabNames, items } = this.props.state
        let itemsCopy = { ...items }
        let currentCategoryName = orderedTabNames[activeTab];

        Object.keys(itemsCopy).forEach((key) => {
            if (itemsCopy[key].category == currentCategoryName) {
                itemsCopy[key] = { ...itemsCopy[key], category: e.target.value }
            }
        })
        let tabNames = activeTab == 0 ?
            [e.target.value, ...orderedTabNames.slice(1)]
            : [...orderedTabNames.slice(0, activeTab), e.target.value, ...orderedTabNames.slice(activeTab + 1)]

        dispatch({ type: "HANDLE_UPDATE_TAB_NAMES", items: tabNames })
        dispatch({ type: "HANDLE_UPDATE_ITEMS", items: itemsCopy })
    }


    tabCancelEditBtnClicked(e) {

        const { dispatch } = this.props
        const { activeTab, orderedTabNames, items, mostRecentTabNameChange, editingTabNameIdx } = this.props.state

        if (editingTabNameIdx == null) return;
        let itemsCopy = { ...items }
        let currentCategoryName = orderedTabNames[activeTab];

        Object.keys(itemsCopy).forEach((key) => {
            if (itemsCopy[key].category == currentCategoryName) {
                itemsCopy[key] = { ...itemsCopy[key], category: mostRecentTabNameChange }
            }
        })
        let tabNames = activeTab == 0 ?
            [mostRecentTabNameChange, ...orderedTabNames.slice(1)]
            : [...orderedTabNames.slice(0, activeTab), mostRecentTabNameChange, ...orderedTabNames.slice(activeTab + 1)]

        dispatch({ type: "HANDLE_EDITING_TAB_NAME", value: null })
        dispatch({ type: "HANDLE_UPDATE_TAB_NAMES", items: tabNames })
        dispatch({ type: "HANDLE_UPDATE_ITEMS", items: itemsCopy })
    }

    renderTabs() {
        const { orderedTabNames, activeTab, mode, editingTabNameIdx } = this.props.state;
        const cancelBtnCls = classnames({
            "btn btn-sm btn-outline-secondary": true,
            "disabled": editingTabNameIdx == null
        })

        return orderedTabNames.map((key, i) => {
            let itemCls = classnames({ "nav-link": true, active: i === activeTab });
            return (
                <li className="nav-item align-bottom d-inline-block" key={i} idx={i} value={i} onClick={this.handleTabClick} onBlur={this.tabOnBlur}
                    onDragLeave={this.onItemDragLeave}
                    onDragEnter={this.onItemDragEnter}
                    onDragOver={this.onItemDragOver}
                >
                    {mode == "editor" ?
                        (
                            <>
                                <div className={itemCls} style={{ pointerEvents: "all" }}>
                                    {i == activeTab ?
                                        <div className="d-flex justify-content-between mb-1">
                                            <div className="btn-group mx-2 mb-1" role="group" style={{ width: "fit-content", pointerEvents: "none" }}>
                                                <button name="moveTabLeft" style={{ pointerEvents: "all" }} type="button" className="btn btn-sm btn-secondary border" onClick={this.tabMoveHandler}>
                                                    <span style={{ pointerEvents: "none" }} className="mdi mdi-arrow-left"></span>
                                                </button>
                                                <button name="moveTabRight" style={{ pointerEvents: "all" }} type="button" className="btn btn-sm btn-secondary border" onClick={this.tabMoveHandler} >
                                                    <span style={{ pointerEvents: "none" }} className="mdi mdi-arrow-right"></span>
                                                </button>
                                            </div>
                                            <button type="button" className="btn btn-sm btn-outline-danger mr-0 ml-2" onClick={this.deleteTabBtnClicked} style={{ pointerEvents: "initial" }}>
                                                <span className="mdi mdi-delete-forever nav-icon"></span>
                                            </button>
                                        </div>
                                        : <div className="py-4" />}
                                    {i == activeTab ?
                                        <div className="d-flex">
                                            <div className="input-group">
                                                {editingTabNameIdx != null ?
                                                    <input autoComplete="off" name="editTabNameInput" type="text" value={key} style={{ pointerEvents: "all" }} onChange={this.tabNameChanged} onDrop={this.preventDropItem} />
                                                    : <span className="mx-2 border border-secondary" style={{ cursor: 'auto', userSelect: 'none', width: "167px" }}>{key}</span>
                                                }

                                                <div className="btn-group">
                                                    <button type="button" className="btn btn-sm btn-outline-primary" style={{ pointerEvents: "initial" }} onClick={this.tabEditBtnClicked}>
                                                        {editingTabNameIdx != null ? <i className="mdi mdi-content-save"></i> : <i className="mdi mdi-pencil icon-inline p-0"></i>}
                                                    </button>
                                                    <button type="button" className={cancelBtnCls} onClick={this.tabCancelEditBtnClicked} >
                                                        <i className="mdi mdi-cancel"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        : (<div className="mx-2">{key}</div>)
                                    }
                                </div>
                            </>
                        )
                        : (<div className={itemCls}>{key}</div>)
                    }
                </li>
            );
        });
    }

    // Handles when new tab button clicked; adds a new tab with a temporary tab name
    addTabBtnClicked() {
        const { dispatch } = this.props
        dispatch({ type: "HANDLE_NEW_TAB" })
    }
    renderAddTab() {
        const { orderedTabNames, activeTab } = this.props.state;
        const addTabCls = classnames({ "nav-link": true, active: orderedTabNames.length === activeTab });
        return (
            <li className="nav-item mt-5" key={orderedTabNames.length} onClick={this.addTabBtnClicked}>
                <div className={addTabCls}>
                    {orderedTabNames.length == 0 && "Create Category    "}
                    <i className="mdi mdi-plus-circle nav-icon" />
                </div>
            </li>
        )
    }
    itemDragHandler = (e, currentItem) => {
        const { dispatch } = this.props
        //Here, currentItem is the item being dragged/moved
        e.dataTransfer.setData("text/plain", JSON.stringify(currentItem))
        e.dataTransfer.effectAllowed = "move"
        dispatch({ type: "HANDLE_DRAG_OCCURING", value: true })
    }

    renderTabContent() {
        const { state, dispatch, handleCRUD, faqsIsLoading, faqsIsSaving } = this.props;
        const { orderedTabNames, activeTab, tabsAreSaving } = this.props.state

        if ((faqsIsSaving || faqsIsLoading) && tabsAreSaving) {
            return (
                <div className="col-sm-6 col-lg-4 mx-auto" style={{ marginTop: "80px" }}>
                    <div className="brand-card" style={{ height: "190px" }}>
                        <div className="brand-card-header bg-facebook">
                            <Loader opt="dissolve-cube" color="#ffffff" />
                        </div>
                        <div className="brand-card-body">
                            <div>
                                <div className="text-value">Refreshing List</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <>
                {this.renderSelectControls()}
                {orderedTabNames.map((tab, i) => {
                    if (i !== activeTab) return null;
                    return (
                        <div key={i} className="tab-pane active" role="tabpanel" >
                            <FaqsGroupList state={state} dispatch={dispatch} handleCRUD={handleCRUD} />
                        </div>
                    )
                })}
            </>
        )
    }

    renderSelectControls = () => {
        const { state, dispatch } = this.props
        const { items, mode, anySelected, sortDirection, sortOperation } = this.props.state

        let sortByOptions = [
            { icon: "mdi mdi-close", text: "None", onClick: () => { }, value: 'sortOperation', dispatchType: "HANDLE_SORT_OPERATION_CHANGE", exitOnClick: true },
            { icon: "mdi mdi-thumbs-up-down", text: "Helpfulness", onClick: () => { }, value: 'sortOperation', dispatchType: "HANDLE_SORT_OPERATION_CHANGE", exitOnClick: true },
            { icon: "mdi mdi-eye", text: "Views", onClick: () => { }, value: 'sortOperation', dispatchType: "HANDLE_SORT_OPERATION_CHANGE", exitOnClick: true },
            { icon: "mdi mdi-update", text: "Most Recent", onClick: () => { }, value: 'sortOperation', dispatchType: "HANDLE_SORT_OPERATION_CHANGE", exitOnClick: true },
        ]

        if (mode == "editor") {
            let numSelected = Object.keys(items).filter(key => items[key].isSelected).length
            return (<RoleFilter allowRoles={[`HQ.ADMIN`]}>
                <div className="clearfix mt-3 d-block mr-5">
                    <div className="float-right">
                        <div className="btn-group mr-1 w-100 p-2" role="group">
                            <span className="badge badge-light align-middle">
                                <h5>{`Selected ${numSelected} item(s)`}</h5>
                            </span>
                            <button type="button" className={`btn btn-danger ${!anySelected ? 'disabled' : ''}`} value="deleteSelection" onClick={this.selectBtnClicked}>
                                Delete
                            </button>
                            <button type="button" className={`btn btn-secondary ${!anySelected ? 'disabled' : ''}`} value="deselectSelection" onClick={this.selectBtnClicked}>
                                Deselect
                            </button>
                            <button type="button" className={`btn btn-primary ${!anySelected ? 'disabled' : ''}`} value="saveSelection" onClick={this.selectBtnClicked}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </RoleFilter>)
        }
        else if (mode == "preview") {
            return (
                <div className={`clearfix mt-3 d-block ${state.categoryViewMode ? 'mr-4' : 'mr-2'}`}>
                    <div className="float-right">
                        <div className="btn-group mr-0 w-100 p-2" role="group">
                            <ActionsDropdown title="Sort By" options={sortByOptions} state={state} dispatch={dispatch} stateValueAsTitle="sortOperation" bg="bg-primary" />
                            <button type="button" title="Ascending" className={`btn btn-${sortDirection === "Ascending" ? 'primary' : 'outline-primary'} ${sortOperation ? '' : 'disabled'}`} value="Ascending" onClick={this.sortDirectionChanged} style={{ fontSize: "18px" }}>
                                <span className="mdi mdi-sort-descending no-pointer-events"></span>
                            </button>
                            <button type="button" title="Descending" className={`btn btn-${sortDirection === "Descending" ? 'primary' : 'outline-primary'} ${sortOperation ? '' : 'disabled'}`} value="Descending" onClick={this.sortDirectionChanged} style={{ fontSize: "18px" }}>
                                <span className="mdi mdi-sort-ascending no-pointer-events"></span>
                            </button>

                        </div>
                    </div>
                </div>
            )
        }
    }

    selectBtnClicked = async (e) => {
        const { state, dispatch, doDialogOpen, handleCRUD } = this.props
        const { items } = state
        let itemsCopy = { ...items }

        // Filtering out items that are not selected
        let selectedItemsKeys = Object.keys(itemsCopy).filter((i => itemsCopy[i].isSelected))
        let selectedItems = Object.fromEntries(selectedItemsKeys.map(key => [key, itemsCopy[key]]))
        if (e.currentTarget.value == "deleteSelection") {
            doDialogOpen({
                content: DeleteSelectionConfirm,
                props: { scrollable: true, mode: "selection", selection: selectedItems, deleteHandler: this.deleteSelection }
            });
        }
        if (e.currentTarget.value == "deselectSelection") {
            dispatch({ type: "HANDLE_DESELECT_ITEMS", value: false })
        }
        if (e.currentTarget.value == "saveSelection") {
            for (const key of Object.keys(selectedItems)) {
                await handleCRUD({ operation: 'saved', id: key }, null)
            }

        }
    }

    deleteSelection = async (selection) => {
        const { state, dispatch, handleCRUD } = this.props
        const { items } = state
        Object.keys(selection).forEach(item => {
            if (selection[item].isSelected) selection[item].deleted = 1
        })
        await handleCRUD({ operation: 'deleted', id: 'selected' }, null)
        dispatch({ type: "HANDLE_UPDATE_ITEMS", items: { ...items, ...selection } })
        dispatch({ type: "HANDLE_DESELECT_ITEMS", value: false })

    }

    sortDirectionChanged = (e) => {
        const { dispatch, state } = this.props
        if (!state.sortOperation) return
        dispatch({ type: "HANDLE_SORT_DIRECTION_CHANGE", value: e.target.value })
    }

    render() {
        const { mode, items, categoryViewMode, orderedTabNames } = this.props.state

        //No FAQs found
        if (Object.keys(items).length == 0 && orderedTabNames.length == 0) {
            return (
                <div className="col-sm-6 col-lg-4 mx-auto">
                    <div className="brand-card" style={{ height: "190px" }}>
                        <div className="brand-card-header bg-facebook">
                            <i className="mdi mdi-alert-circle-outline" />
                        </div>
                        <div className="brand-card-body">
                            <div>
                                <div className="text-value mb-1">Nothing to show</div>
                                {mode == "editor" &&
                                    <button className="btn btn-primary btn-lg mt-3 mx-auto my-auto " onClick={this.addTabBtnClicked}>
                                        Start
                                    </button>}
                            </div>
                        </div>
                    </div>
                </div>)
        }
        // FAQs found
        else {
            if (categoryViewMode) {
                return (
                    <>
                        <ul className="nav nav-tabs" >
                            {this.renderTabs()}
                            {(mode == "editor") && this.renderAddTab()}
                        </ul>
                        <div className="tab-content mx-auto">
                            {this.renderTabContent()}
                        </div>
                    </>
                )
            }
            return (
                <>
                    {this.renderTabContent()}
                </>
            )
        }
    }
}
export default connect(
    "doDialogOpen",
    "doFaqsSave",
    "selectFaqsIsSaving",
    "selectFaqsIsLoading",
    "doUpdateActiveTab",
    TabsContainer);