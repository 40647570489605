import React from "react";
import classnames from "classnames";
import ComponentTree from "../component-tree";
import "../documentation.css";
import RoleFilter from "../../../app-containers/context-providers/role-filter";

const Section = ({ childComponents =[], title, _key, allowRoles =["*.*"], className, chapterHeading=false }) => {
  let rowClsName = classnames({
    "row": true,
    [className]: className
  });
  return (
    <RoleFilter allowRoles={allowRoles}>
      <div className={rowClsName}>
        <div className="col">
          {
            chapterHeading ?
            <h1 id={_key}>{title}</h1> :
            <h5 id={_key}>{title}</h5>
          }
          {childComponents.map((child) => {
            return <ComponentTree obj={child}/>
          })}
        </div>
      </div>
    </RoleFilter>
  );
}
export default Section;