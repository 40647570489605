import React from "react";
import ComponentTree from "../component-tree";
import classnames from "classnames";
import "../documentation.css";
import RoleFilter from "../../../app-containers/context-providers/role-filter";
const SectionRow = ({ className, childComponents = [], allowRoles = ["*.*"], style = {} }) => {
  const rowCls = classnames({
    "row section-row": true,
    [className]: className
  });
  return (
    <RoleFilter allowRoles={allowRoles}>
      <div className={rowCls} style={style}>
        {childComponents.map(c => {
          let colCls = c.className ? c.className : "";
          if (!colCls.includes("col")) {
            colCls = "col " + colCls
          }
          return <ComponentTree obj={{ ...c, className: colCls }} />
        })}
      </div>
    </RoleFilter>
  );
}
export default SectionRow;