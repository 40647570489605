import React, { useState, useEffect } from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";
import Loader from "../../../app-components/loader";
import "./item-board.css";
import ItemPageComment from "./item-page-comment";
import { find } from "lodash";

const CommentsSection = ({
  commentsFlags,
  forumUserPermissionsItems,
  renderName,
  renderExtraUserInfo,
  sendFunc,
  deleteFunc,
  boardUrl,
  domainsATPM,
  approvalRolesByLoggedIn,
  additionalState,
  commentsItems,
  parentItemData,
  children,
  commentsIsLoading,
  tokenRolesJoined,
  tokenPayload,
}) => {
  const [state, setState] = useState({
    ...additionalState, // for message board, 'additionalState' is for things like forum, is_shared, etc
    comments: commentsItems,
    reducedComments: [],
    activeUser: null,
    numComments: commentsItems.length,
    forum_name: "",
  });

  const renderComments = () => {
    const { commentsBoxReduced } = state;
    const { _shouldFetch } = commentsFlags;
    let reducedComments =
      commentsItems.length > 5
        ? commentsItems.slice(commentsItems.length - 5)
        : commentsItems;
    const commentsBoxCls = classnames({
      "comments-box p-3": true,
      reduced: commentsBoxReduced,
    });
    if (commentsIsLoading || _shouldFetch) {
      return (
        <div className="container-fluid d-flex justify-content-around w-100 h-100">
          <div className="d-flex flex-column mx-auto my-auto align-middle">
            <Loader />
          </div>
        </div>
      );
    }
    if (commentsItems.length === 0) {
      return (
        <div
          className="d-flex flex-column mx-lg-4 mb-3"
          style={{ fontSize: "2.5rem" }}
        >
          <div className="mx-auto">
            <i
              className="mdi mdi-alert-circle-outline "
              style={{
                color: "#3b5998",
                textShadow: "1px 1px 2px rgba(0,0,0,.15)",
              }}
            />
          </div>
          <div className="mx-auto">
            <div className="text-value-sm my-auto text-center">
              There are no comments on this post.
            </div>
          </div>
        </div>
      );
    } else if (commentsBoxReduced && commentsItems.length > 4) {
      return (
        <div className={commentsBoxCls} style={{ overflow: "visible" }}>
          {reducedComments.map((c, idx) => {
            let isUsers = c.created_by === tokenPayload.sub;
            let userIsATPM = approvalRolesByLoggedIn.includes(
              domainsATPM ? domainsATPM.id : null
            );
            let userIsHqAdmin = tokenRolesJoined.includes("HQ.ADMIN");
            let commentProps = {
              renderName,
              renderExtraUserInfo,
              userIsATPM,
              isUsers,
              userIsHqAdmin,
              parentItemData,
              boardUrl,
              sendFunc,
              deleteFunc,
            };
            let commenterPermissions = find(forumUserPermissionsItems, {
              created_by: c.created_by,
              forum_name: boardUrl.name,
            });
            return (
              <ItemPageComment
                {...commentProps}
                key={idx}
                isForm={false}
                commentData={c}
                permissions={commenterPermissions}
              />
            );
          })}
        </div>
      );
    } else {
      return (
        <div className={commentsBoxCls} style={{ overflow: "visible" }}>
          {commentsItems.map((c, idx) => {
            let isUsers = c.created_by === tokenPayload.sub;
            let userIsATPM = approvalRolesByLoggedIn.includes(
              domainsATPM ? domainsATPM.id : null
            );
            let userIsHqAdmin = tokenRolesJoined.includes("HQ.ADMIN");
            let commentProps = {
              renderName,
              renderExtraUserInfo,
              userIsATPM,
              isUsers,
              userIsHqAdmin,
              parentItemData,
              boardUrl,
              sendFunc,
              deleteFunc,
            };
            let commenterPermissions = find(forumUserPermissionsItems, {
              created_by: c.created_by,
              forum_name: boardUrl.name,
            });
            return (
              <ItemPageComment
                {...commentProps}
                key={idx}
                isForm={false}
                commentData={c}
                permissions={commenterPermissions}
              />
            );
          })}
        </div>
      );
    }
  };
  const reduceCommentsBtnClicked = (e) => {
    setState({ ...state, commentsBoxReduced: !state.commentsBoxReduced });
  };
  const renderReduceCommentsBtn = () => {
    const reduceCommentsCls = classnames({
      "btn btn-sm btn-outline-primary align-self-center": true,
      disabled: commentsItems.length <= 4,
    });
    const reduceCommentsStyle = {
      pointerEvents: commentsItems.length > 4 ? "all" : "none",
    };
    return (
      <button
        className={reduceCommentsCls}
        type="button"
        onClick={reduceCommentsBtnClicked}
        style={reduceCommentsStyle}
      >
        {state.commentsBoxReduced ? "Show all" : "Show less"}
        <span className="d-none d-sm-inline"> comments</span>
      </button>
    );
  };

  useEffect(() => {
    if (commentsItems.length > 0) {
      setState({
        ...state,
        disableItemPageNavBtns: ["comment_count", "relevant"].includes(
          state._sortBy
        ),
        comments: commentsItems,
        reducedComments:
          commentsItems.length > 5
            ? commentsItems.slice(commentsItems.length - 5)
            : commentsItems,
        people: state.people,
        numComments: commentsItems.length,
      });
    }
  }, [commentsItems]);

  return (
    <div className="card comments-section-card">
      <div className="d-flex flex-column justify-content-between">
        <div className="d-flex flex-column justify-content-between h-100">
          <h5 className="card-header d-flex justify-content-between">
            <div className="my-auto">
              <span className="mdi mdi-comment-text-outline mr-2" />
              <span className="d-none d-sm-inline">Comments</span>
              <span className="ml-2 badge badge-pill badge-danger">
                {state.numComments}
              </span>
            </div>
            {renderReduceCommentsBtn()}
          </h5>
          <div className="card-body">
            {renderComments()}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(
  "selectForumUserPermissionsItems",
  "selectDomainsATPM",
  "selectApprovalRolesByLoggedIn",
  "selectTokenRolesJoined",
  "selectTokenPayload",
  CommentsSection
);
