import React from "react";
import ItemCard from "./item";
import Loader from "../../../app-components/loader";
import { connect } from "redux-bundler-react";
import "./item-list.css";

const List = ({
  boardUrl,
  items,
  flags,
  isLoading,
  approvalRolesByLoggedIn,
  domainsATPM,
  tokenPayload,
  tokenRolesJoined,
  itemProps,
}) => {
  const { itemContentDetailsCol } = itemProps;

  const renderItems = () => {
    let _items = items.filter((item) => item && item.title);
    let totalQueried = items.length > 0 ? parseInt(items[0].full_count) : 0;
    if (totalQueried === 0) {
      return (
        <div className="container-fluid my-auto h-100 w-100 d-flex justify-content-around py-2">
          <div
            className="d-flex flex-column mx-lg-4 mb-3"
            style={{ fontSize: "2.5rem" }}
          >
            <div className="mx-auto">
              <i
                className="mdi mdi-alert-circle-outline "
                style={{
                  color: "#3b5998",
                  textShadow: "1px 1px 2px rgba(0,0,0,.15)",
                }}
              />
            </div>
            <div className="mx-auto">
              <div className="text-value-sm my-auto text-center">
                Nothing found.
              </div>
            </div>
          </div>
        </div>
      );
    }
    return _items.map((item, idx) => {
      if (typeof item === "object") {
        if (item.statusCode && item.statusCode == 500) return;
        let isUsers = item.created_by === tokenPayload.sub;
        let userIsATPM = approvalRolesByLoggedIn.includes(
          domainsATPM ? domainsATPM.id : null
        );
        let userIsHqAdmin = tokenRolesJoined.includes("HQ.ADMIN");
        return (
          <ItemCard
            {...itemProps}
            boardUrl={boardUrl}
            isUsers={isUsers}
            userIsATPM={userIsATPM}
            userIsHqAdmin={userIsHqAdmin}
            key={idx}
            itemData={item}
          >
            {itemContentDetailsCol ? itemContentDetailsCol(item) : null}
          </ItemCard>
        );
      }
    });
  };

  if (flags._shouldFetch || isLoading) {
    return (
      <div className="container-fluid my-auto h-100 w-100 d-flex justify-content-around py-2">
        <div
          className="d-flex flex-column mx-auto my-auto align-middle"
          style={{ height: "fit-content" }}
        >
          <Loader />
          <h4>Loading...</h4>
        </div>
      </div>
    );
  }
  return <div className="item-list list-group py-2 my-2">{renderItems()}</div>;
};

export default connect(
  "selectTokenPayload",
  "selectApprovalRolesByLoggedIn",
  "selectDomainsATPM",
  "selectTokenRolesJoined",
  List
);
