import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import FileUploadCard from "../_shared/file-upload-card/file-upload-card";
import { snakeCase } from "lodash";

const aircraftDeactivationDialog = ({
    doDialogClose,
    tokenPayload,
    orgsByRoute,
    doEquipmentRequestSave,
    profileActiveData,
    aircraftByRoute,
    doUpdateRelativeUrl,
    doFileIoUpload,
    equipmentRequestDocsIsLoading,
    equipmentRequestDeactivationTypes
}) => {
    const [state, setState] = useState({
        id: null,
        type: "aircraft",
        name: aircraftByRoute.name,
        org_id: orgsByRoute.id,
        created_by: tokenPayload.sub,
        last_update_by: tokenPayload.sub,
        create_date: new Date(),
        org_name: orgsByRoute.name,
        action: "",
        status: "New",
        preferredName: tokenPayload.preferred_username,
        profile_id: profileActiveData.id,
        platform_id: aircraftByRoute.platform_id,
        serial_no: aircraftByRoute.serial_no,
        barcode: aircraftByRoute.barcode,
        service_entry_date: aircraftByRoute.service_entry_date,
        operational_id: aircraftByRoute.operational_id,
        existing_id: aircraftByRoute.id,
        comment: "",
        docs: [],
    });

    const handleCommentsChange = (e) => {
        let notes = e.target.value;
        setState({ ...state, comment: notes });
    };

    return (
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Deactivation</h5>
                <button
                    className="close"
                    type="button"
                    aria-label="close"
                    onClick={() => {
                        doDialogClose();
                    }}
                >
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="modal-body">
                <h6>Please select why you want to de-activate this aircraft.</h6>
                <form>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" value="Display Only" name="Deactivation" onChange={(e) => setState({ ...state, action: e.target.value })} />
                      <label className="form-check-label">Display Only</label>
                    </div>
                    {
                      equipmentRequestDeactivationTypes.map((status, idx) => {
                        return (
                          <div className="form-check" key={idx}>
                            <input className="form-check-input" type="radio" value={status.val} name="Deactivation" onChange={(e) => setState({ ...state, action: e.target.value })} />
                            <label className="form-check-label">{status.val}</label>
                          </div>
                        )
                      })
                    }
                </form>
                <div>
                    <br></br>
                    <h6>Attach your supporting documents for deactivating this aircraft: </h6>
                    <br></br>
                    <FileUploadCard
                        required
                        allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
                        isLoading={equipmentRequestDocsIsLoading}
                        title={"Deactivation Documents"}
                        items={state.docs}
                        header={true}
                        searchable={false}
                        onRemove={e => setState({ ...state, docs: [] })}
                        rel="deactivation_docs"
                        onUpload={(url, file, rel) => setState({ ...state, docs: [{ url, file, rel, filename: file.name }] })}
                        disabledEditing={state.docs.length > 0}
                    />
                    <form>
                        <div className="form-group">
                            <h6>Additional Comments: </h6>
                            <textarea className="form-control" id="comment" name="comment" rows="3" onChange={handleCommentsChange}></textarea>
                        </div>
                    </form>
                </div>
            </div>
            <div className="modal-footer">
                <h6 className="float-right">A message will be sent to HQ to approve your request.</h6>
                <button type="button" className="btn btn-secondary" onClick={doDialogClose}>Cancel</button>
                <button type="button" id="submit" className="btn btn-primary" disabled={!state.docs.length}
                    onClick={(e) => {
                        let currentDoc = state.docs && state.docs.length > 0 ? state.docs[0] : {};
                        doEquipmentRequestSave(
                            state,
                            (equipmentRequestByRoute) =>
                                doFileIoUpload(
                                    currentDoc.url,
                                    currentDoc.file,
                                    currentDoc.rel,
                                    { deactivation_id: equipmentRequestByRoute.id },
                                    doDialogClose(),
                                    doUpdateRelativeUrl(`/${orgsByRoute.slug}/aircraft`)
                                ),
                            true
                        );
                    }}>Send Request</button>
            </div>
        </div>
    );
};

export default connect(
    "doDialogClose",
    "doFileIoUpload",
    "doFileIoDelete",
    "doUpdateRelativeUrl",
    "selectTokenPayload",
    "selectTokenRolesJoined",
    "selectOrgsByRoute",
    "selectEquipmentRequestByRoute",
    "doEquipmentRequestSave",
    "selectEquipmentRequestDocsItems",
    "selectProfileActiveData",
    "selectEquipmentRequestDocsIsLoading",
    "selectEquipmentRequestDeactivationTypes",
    aircraftDeactivationDialog,
)