import React, { useState, useEffect } from "react";

export default ({ title, onChange, column }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const [value, setValue] = useState("");
  const handleValueChange = (e) => {
    setValue(e.target.value);
    onChange({[column]: e.target.value});
  };

  const handleClear = () => {
    setValue("");
    onChange({ [column]: ""})
  };


  const hasCondition = !!value.length;

  return (
    <div className="card mb-0">
      <div className="card-header" onDoubleClick={toggleExpanded}>
        {title}
        <i
          className={`mdi mdi-filter-variant text-${
            hasCondition ? "primary" : "muted"
          } pl-2`}
        ></i>
        <div className="card-header-actions">
          <span onClick={toggleExpanded}>
            <i
              className={`mdi mdi-${
                expanded ? "chevron-down" : "chevron-right"
              }`}
            ></i>
          </span>
        </div>
      </div>
      {expanded ? (
        <div className="card-body">
          <div className="">
            <div className="input-group">
              <input
                value={value}
                onChange={handleValueChange}
                className="form-control"
                type="text"
                placeholder="Find values like..."
              />
              <span className="input-group-append">
                <button
                  disabled={!value.length}
                  onClick={handleClear}
                  className="btn btn-secondary"
                  type="button"
                >
                  <i className="mdi mdi-close"></i>
                </button>
              </span>
            </div>
            <small className="form-text text-muted">
              Search is case-insensitive
            </small>
          </div>
        </div>
      ) : null}
    </div>
  );
};
