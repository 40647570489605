import React from "react";
import { connect } from "redux-bundler-react";
import AutoComplete from "../../../../app-components/autocomplete";

class Geocoder extends React.Component {
  constructor(props) {
    super(props);
    this.clear = this.clear.bind(this);
  }
  clear() {
    this.autocomplete.clear();
  }
  render() {
    const { doMapZoomTo } = this.props;
    return (
      <div className="input-group">
        <AutoComplete
          inputStyle={{ width: "230px" }}
          ref={el => {
            this.autocomplete = el;
          }}
          itemClass="list-group-item overflow-ellipsis"
          displayKey="place_name"
          apiParser={json => {
            if (!json || !json.features) return [];
            const results = [];
            json.features.forEach(feature => {
              results.push(feature);
            });
            return results;
          }}
          onSelect={e => {
            doMapZoomTo(e.center);
            this.clear();
          }}
          url={
            "https://api.mapbox.com/geocoding/v5/mapbox.places/{query}.json?access_token=pk.eyJ1IjoidXNhY2UiLCJhIjoiY2wwbXV1bGIzMTlycDNpcmtnNHJhMDNhYiJ9.tKNUaXSTYmou55_dwqtQtA&cachebuster=1553281681395&autocomplete=true"
          }
        />
        <div className="input-group-append">
          <div className="input-group-text" onClick={this.clear}>
            <i className="mdi mdi-close"></i>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doMapZoomTo",
  Geocoder
);
