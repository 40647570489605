import React, { useEffect, useState, useCallback } from "react";
import classnames from "classnames"
import { connect } from "redux-bundler-react"
import Dropdown from "../../_shared/item-board/dropdown";
import CheckboxInput from "../../../app-components/schema-form/checkbox-input";
import { find } from "lodash"
import "../../_shared/item-board/item-board.css"

const PermissionsDropdown = ({ fields = [], forumUserPermissionsByLoggedIn, forumUserPermissionsIsSaving, forumUserPermissionsIsLoading, forumUserPermissionsFlags, board, doForumUserPermissionsSaveByLoggedIn }) => {
    const [state, setState] = useState({
        settingsChanged: false,
        submitted: false,
        settingsShowing: false,
        permissionsState: find(forumUserPermissionsByLoggedIn, { forum_name: board }),
        permissionsBtnCls: "permissions-dropdown-btn btn-link",
    })
    const { _shouldFetch } = forumUserPermissionsFlags
    const handleSubmit = useCallback((e) => {
        setState({ ...state, submitted: true, settingsChanged: false });
        doForumUserPermissionsSaveByLoggedIn(Object.assign({}, { [board]: { ...state.permissionsState } }));
    }, [state.permissionsState]);

    const renderFeedbackForm = (callback) => {
        if (forumUserPermissionsIsSaving || forumUserPermissionsIsLoading || _shouldFetch) return null;
        if (state.submitted) {
            return (
                <div className="p-3">
                    <div className="row justify-content-between mx-0 my-1">
                        <h5 className="mdi mdi-check ml-2 my-auto text-success" />
                        <h6 className="mr-auto ml-2 my-auto">Settings Saved.</h6>
                        <button className="close" type="button" aria-label="close" onBlur={onFeedbackBlur} autoFocus={true} onClick={(e) => {
                            if (callback) callback(e);
                            setState({ ...state, submitted: false, settingsChanged: false, settingsShowing: false, permissionsBtnCls: 'permissions-dropdown-btn btn-link' })
                        }}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                </div>
            )
        }
        return null
    }
    const renderForm = () => {
        if (forumUserPermissionsIsSaving || forumUserPermissionsIsLoading || _shouldFetch) {
            return (<div className="d-flex px-4 py-3 justify-content-around" >
                <span className=" mx-auto my-auto text-primary spinner-border" />
            </div>)
        }
        if (!state.submitted) {
            return (
                <div className="px-4 py-3">
                    {fields.map((permObj, idx) => {
                        if (permObj.type === 'title') {
                            return (
                                <div key={idx} className="row mx-0 mb-1 mt-3">
                                    <h6 className="my-auto">{permObj.text}</h6>
                                </div>
                            )
                        }
                        let fieldClsName = classnames({
                            "row d-flex mx-0 my-1 justify-content-between": true,
                            [permObj.className]: permObj.className
                        })
                        return (
                            <div key={idx} className={fieldClsName}>
                                <div className="col-8 mx-0 d-flex">{permObj.text}</div>
                                <div className="col-2 ml-auto mr-0 px-0 item-board-permissions-checkbox">
                                    <CheckboxInput inline={true} value={state.permissionsState[permObj.field]} prop={state.permissionsState} schema={{ title: "" }}
                                        onChange={(e) => {
                                            if (e.value !== state.permissionsState[permObj.field]) {
                                                setState({
                                                    ...state,
                                                    settingsChanged: true,
                                                    permissionsState: { ...state.permissionsState, [permObj.field]: e.value }
                                                });
                                            }
                                        }} />
                                </div>
                            </div>
                        )
                    })}
                    <div className="row d-flex mx-0 my-1">
                        <button className="col-2 offset-8 btn btn-sm btn-primary ml-auto mr-1" disabled={!state.settingsChanged} type="button" onClick={handleSubmit}>Save</button>
                    </div>
                </div>
            )
        }
        return null
    }
    const onToggleShow = (val) => {
        setState({
            ...state,
            settingsShowing: val ? val : !state.settingsShowing,
            permissionsBtnCls: `permissions-dropdown-btn btn-link ${val ? 'selected' : ''}`
        })
    }
    const onFeedbackBlur = (e) => {
        setState({
            ...state, submitted: false, settingsShowing: false, settingsChanged: false, permissionsBtnCls: 'permissions-dropdown-btn btn-link'
        });
    }
    useEffect(() => {
        if (forumUserPermissionsByLoggedIn) {
            setState({ ...state, permissionsState: find(forumUserPermissionsByLoggedIn, { forum_name: board }) })
        }

    }, [forumUserPermissionsByLoggedIn])

    return (
        <Dropdown btnCls={state.permissionsBtnCls} onToggleShow={onToggleShow} show={state.settingsShowing}
            form={renderForm} feedbackForm={renderFeedbackForm}
            title={{ type: "icon", value: "mdi mdi-bell-outline", style: { fontSize: "16px" } }} maxHeight="350px" width="25rem" />
    )
}
export default connect(
    "selectForumUserPermissionsByLoggedIn",
    "selectForumUserPermissionsIsSaving",
    "selectForumUserPermissionsIsLoading",
    "selectForumUserPermissionsFlags",
    "doForumUserPermissionsSaveByLoggedIn",
    PermissionsDropdown);