import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";

export default createRestBundle({
  name: "notes",
  uid: "id",
  prefetch: true,
  persist: false,
  routeParam: "",
  getTemplate: "/orgs/:orgSlug/notes/:parentId",
  putTemplate: "/orgs/:orgSlug/notes/:item.id",
  postTemplate: "/orgs/:orgSlug/notes/",
  deleteTemplate: "/orgs/:orgSlug/notes/:item.id",
  fetchActions: [
    "URL_UPDATED",
    "AUTH_LOGGED_IN",
    "AIRCRAFT_FETCH_FINISHED",
    "BATTERIES_FETCH_FINISHED",
    "GCS_FETCH_FINISHED",
    "PAYLOADS_FETCH_FINISHED",
    "PERSONNEL_FETCH_FINISHED",
    "MISSIONS_FETCH_FINISHED",
    "PLATFORMS_FETCH_FINISHED",
    "TRANSFERS_FETCH_FINISHED",
    "NOTES_SAVE_FINISHED",
  ],
  addons: {
    selectNotesParentId: createSelector(
      "selectAircraftByRoute",
      "selectBatteriesByRoute",
      "selectGcsByRoute",
      "selectPayloadsByRoute",
      "selectPersonnelByRoute",
      "selectMissionsByRoute",
      "selectPlatformsByRoute",
      "selectPlatformsGcsByRoute",
      "selectPlatformsPayloadsByRoute",
      "selectTransfersByRoute",
      (
        aircraft,
        battery,
        gcs,
        payload,
        personnel,
        mission,
        platform,
        platformsGcs,
        platformsPayloads,
        transfer
      ) => {
        const activeDetails = {
          ...aircraft,
          ...battery,
          ...gcs,
          ...payload,
          ...personnel,
          ...mission,
          ...platform,
          ...platformsGcs,
          ...platformsPayloads,
          ...transfer,
        };

        if (activeDetails.id) return activeDetails.id;
        return null;
      }
    ),
    selectNotesGetUrl: createSelector(
      "selectNotesGetTemplate",
      "selectRouteParams",
      "selectNotesParentId",
      (template, params, parentId) => {
        const activeDetails = {};

        if (parentId) activeDetails.parentId = parentId;

        const availableParams = {
          ...params,
          ...activeDetails,
        };
        let url = template;
        Object.keys(availableParams).forEach((key) => {
          url = url.replace(`:${key}`, availableParams[key]);
        });
        return url;
      }
    ),
  },
});
