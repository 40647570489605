import { find, sortBy, capitalize } from "lodash";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { connect } from "redux-bundler-react";
import DateInput from "../../../../app-components/schema-form/date-input";
import SelectInput from "../../../../app-components/schema-form/select-input";
import TextArea from "../../../../app-components/schema-form/text-area";
import TextInput from "../../../../app-components/schema-form/text-input";
import PreparedBySigItem from "../../../../modules/missions/details/approvals-tab/prepared-by-sig-item";
import Loader from "../../../../app-components/loader";
import PrintButton from "../../../../app-components/print-button/print-button";
import { format } from "date-fns";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "UPDATE_ATP_MONTH":
    case "UPDATE_INPUT":
    case "APPLY_SIGNATURES":
      return Object.assign({}, state, payload);
    case "INITIALIZE_MODAL":
      return { ...payload };
    default:
      return state;
  }
};

const MONTHS = [
  { value: "01", name: "jan", label: "January" },
  { value: "02", name: "feb", label: "February" },
  { value: "03", name: "mar", label: "March" },
  { value: "04", name: "apr", label: "April" },
  { value: "05", name: "may", label: "May" },
  { value: "06", name: "jun", label: "June" },
  { value: "07", name: "jul", label: "July" },
  { value: "08", name: "aug", label: "August" },
  { value: "09", name: "sept", label: "September" },
  { value: "10", name: "oct", label: "October" },
  { value: "11", name: "nov", label: "November" },
  { value: "12", name: "dec", label: "December" },
];

const EFTwenty = ({
  doNestedDialogClose,
  domainsItemsByGroup: domains,
  doEfTwentySave,
  doEfTwentyApprovalSave,
  doEfTwentyApprovalDeleteByForm,
  data,
  profileActiveData,
  currency,
  efTwentyApprovalIsLoading,
  efTwentyApprovalItems,
  isProfileActiveAtpm: isPilotsAtpm,
  profileId,
  profilesAll,
  doFetchAtpmDashboardById,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    name: profileActiveData.name,
    duties_id: [],
    flight_modes_id: [],
    atp_from: new Date().getFullYear(),
    atp_to: new Date().getFullYear() + 1,
    req_flights_second_rp: 2,
    req_flights_second_vo: 1,
    req_flights_first_vo: 1,
    req_flights_first_rp: 2,
  });

  const [disabled, setDisabled] = useState(
    efTwentyApprovalItems.length > 0 || !isPilotsAtpm
  );

  useEffect(() => {
    if (data) {
      let tempState = data;
      tempState.duties_id =
        data.duties_id && typeof data.duties_id === "string"
          ? data.duties_id.split(".")
          : [];
      tempState.flight_modes_id =
        data.flight_modes_id && typeof data.flight_modes_id === "string"
          ? data.flight_modes_id.split(".")
          : [];
      tempState.first_semi_start = data.first_semi_start
        ? new Date(data.first_semi_start)
        : null;
      tempState.second_semi_start = data.second_semi_start
        ? new Date(data.second_semi_start)
        : null;
      tempState.first_semi_end = data.first_semi_end
        ? new Date(data.first_semi_end)
        : null;
      tempState.second_semi_end = data.second_semi_end
        ? new Date(data.second_semi_end)
        : null;

      // have to convert to int to add, and then back to string because TextInput converts int 0 to falsy and renders ""
      tempState.act_flights_first_rp = currency.rp_first_semi_yr;
      tempState.act_flights_first_vo = currency.vo_first_semi_yr;
      tempState.act_flights_second_rp = currency.rp_second_semi_yr;
      tempState.act_flights_second_vo = currency.vo_second_semi_yr;

      dispatch({
        type: "INITIALIZE_MODAL",
        payload: tempState,
      });
    }
  }, [data, currency]);

  useEffect(() => {
    let tempState = { atpm_sig: null, suac_sig: null };
    if (efTwentyApprovalItems && efTwentyApprovalItems.length > 0) {
      efTwentyApprovalItems.forEach((sigObj) => {
        if (sigObj.routing_order === 0) tempState.atpm_sig = sigObj.signature;
        else tempState.suac_sig = sigObj.signature;
      });
    }
    dispatch({
      type: "APPLY_SIGNATURES",
      payload: tempState,
    });
  }, [efTwentyApprovalItems, efTwentyApprovalItems.length]);

  const _onSave = () => {
    state.duties_id = state.duties_id ? state.duties_id.join(".") : null;
    state.flight_modes_id = state.flight_modes_id
      ? state.flight_modes_id.join(".")
      : null;
    state.in_flight_eval = state.in_flight_eval ? state.in_flight_eval : null;
    state.local_test = state.local_test ? state.local_test : null;
    state.apl_test = state.apl_test ? state.apl_test : null;
    if (state.atp_month) {
      state.expiration_date = new Date(
        state.atp_from,
        parseInt(state.atp_month) + 11,
        0
      );
      // for testing only
      // state.expiration_date = new Date("1/1/2023");
    }
    delete state.first_semi;
    delete state.second_semi;
    // these do not need to be saved to ef_7120 table. they are inserted into ef_7120_approval when signed.
    delete state.atpm_sig;
    delete state.suac_sig;
    doEfTwentySave(state, () => {
      doFetchAtpmDashboardById(profileId);
      doNestedDialogClose();
    });
  };

  const _getStartSemi = (val, first) => {
    let nextMo = first ? -1 : 5;
    let currentYear = state.atp_from;
    return new Date(currentYear, parseInt(val) + nextMo, 1);
  };

  const _getEndSemi = (val, first) => {
    let nextMo = first ? 5 : 11;
    let currentYear = state.atp_from;
    return new Date(currentYear, parseInt(val) + nextMo, 0);
  };
  const _onChange = (input) => {
    if (input.property === "atp_month") {
      dispatch({
        type: "UPDATE_ATP_MONTH",
        payload: {
          [input.property]: input.value,
          first_semi_start: _getStartSemi(input.value, true),
          first_semi_end: _getEndSemi(input.value, true),
          second_semi_start: _getStartSemi(input.value, false),
          second_semi_end: _getEndSemi(input.value, false),
        },
      });
    } else if (input.property === "atp_from") {
      dispatch({
        type: "UPDATE_INPUT",
        payload: {
          [input.property]: input.value,
          atp_to: parseInt(input.value) + 1,
          first_semi_start:
            state.first_semi_start &&
            new Date(state.first_semi_start.setYear(input.value)),
          first_semi_end:
            state.first_semi_start && state.first_semi_start.getMonth() > 6
              ? new Date(
                  state.first_semi_end.setYear(parseInt(input.value) + 1)
                )
              : state.first_semi_end &&
                new Date(state.first_semi_end.setYear(input.value)),
          second_semi_start:
            state.first_semi_start && state.first_semi_start.getMonth() > 5
              ? new Date(
                  state.second_semi_start.setYear(parseInt(input.value) + 1)
                )
              : state.second_semi_start &&
                new Date(state.second_semi_start.setYear(input.value)),
          second_semi_end:
            state.second_semi_end &&
            new Date(state.second_semi_end.setYear(parseInt(input.value) + 1)),
        },
      });
    } else {
      dispatch({
        type: "UPDATE_INPUT",
        payload: { [input.property]: input.value },
      });
    }
  };

  const _onCheckboxChange = (e) => {
    let checkedBoxes = state[e.target.name];
    if (e.target.checked) {
      checkedBoxes.push(e.target.id);
    } else {
      checkedBoxes = checkedBoxes.filter((item) => item !== e.target.id);
    }
    dispatch({
      type: "UPDATE_INPUT",
      payload: { [e.target.name]: checkedBoxes },
    });
  };

  let dutiesDomains = sortBy(domains["duties"], (val) =>
    parseInt(val.display_order)
  );

  let flightModes = sortBy(domains["flight_mode"], (val) =>
    parseInt(val.display_order)
  ).filter((obj) =>
    ["Day", "Night*", "BVLOS*", "Single RPI Operates Multiple UAS*"].includes(
      obj.val
    )
  );

  const _getSemiRange = (first) => {
    let start = first ? "first_semi_start" : "second_semi_start";
    let end = first ? "first_semi_end" : "second_semi_end";

    if (state[end] && state[start]) {
      let semiStartMonth = find(
        MONTHS,
        (obj) => parseInt(obj.value) === state[start].getMonth() + 1
      );
      let semiEndMonth = find(
        MONTHS,
        (obj) => parseInt(obj.value) === state[end].getMonth() + 1
      );
      if (semiStartMonth && semiEndMonth)
        return `1 ${semiStartMonth.name.toUpperCase()} - ${state[
          end
        ].getDate()} ${semiEndMonth.name.toUpperCase()}`;
    }
  };
  const parseSig = (sig) => {
    if (!sig) return "";
    sig = JSON.parse(atob(sig.split(".")[0]));
    let signer = find(profilesAll, { keycloak_id: sig.keycloak_id });
    return {
      atpm_sig_parsed_20: [signer ? signer.name : "", "(Digitally Signed)"],
      atpm_eff_date_20: format(new Date(sig.date), "dd-MMM-yy"),
    };
  };

  return (
    <div className="modal-content">
      <div className="modal-header">
        <div className="d-flex flex-column">
          <h5 className="modal-title">ATPM's SUAC Task List</h5>
          <h6 className="text-muted mt-2 mb-0">
            For use of this form see USACE Aviation Policy Letter 95-1-1
          </h6>
          <h6 className="text-muted mt-2">
            The proponent agency is HQ Aviation
          </h6>
        </div>
        <div className="d-flex flex-column h-100">
          <button
            onClick={doNestedDialogClose}
            className="close"
            type="button"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
          <PrintButton
            className="btn-sm mt-auto mb-0"
            options={[
              {
                resultFileName: "EF 7120 ATPM's SUAC Task List",
                file: "ef-twenty.template.json",
              },
            ]}
            state={{
              ...state,
              duties_id: state.duties_id.join("."),
              ...parseSig(state.atpm_sig),
              flight_modes_id: state.flight_modes_id.join("."),
              atp_to: state.atp_to ? state.atp_to.toString() : "",
            }}
          >
            <i className="mdi mdi-printer mr-2" />
            Fill & Print EF 7120
          </PrintButton>
        </div>
      </div>

      <div className="modal-body">
        <div className="container-fluid">
          <h6 className="border-bottom pb-2">Part I. Biographical</h6>
          <div className="row">
            <div className="col-sm">
              <TextInput
                value={state.name}
                property="name"
                onChange={_onChange}
                schema={{ title: "Name" }}
                displayOnly={disabled}
              />
            </div>
            <div className="col-sm">
              <TextInput
                value={state.foa}
                property="foa"
                onChange={_onChange}
                schema={{ title: "FOA" }}
                displayOnly={disabled}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg">
              <SelectInput
                onChange={_onChange}
                value={state.atp_month}
                property="atp_month"
                options={MONTHS}
                schema={{
                  title: "Month ATP Year Begins",
                  description:
                    "Crewmember's birth month or one designated by ATPM",
                }}
                displayOnly={disabled}
                required
              />
            </div>
          </div>
          <h6 className="border-bottom pb-2">Part II. Authorized Duties</h6>
          <p className="m-0 text-muted">Check all applicable boxes</p>
          <div className="row mt-2">
            {dutiesDomains &&
              dutiesDomains.map((obj) => {
                return (
                  <div className="col-sm" key={obj.id}>
                    <input
                      type="checkbox"
                      name="duties_id"
                      id={obj.id}
                      checked={state.duties_id.includes(obj.id)}
                      value={state.duties_id.includes(obj.id)}
                      onChange={_onCheckboxChange}
                      disabled={disabled}
                    />
                    <label className="ml-2" htmlFor={obj.val}>
                      {obj.val}
                    </label>
                  </div>
                );
              })}
          </div>
          <h6 className="border-bottom pb-2">
            Part III. Authorized Flight Modes
          </h6>
          <p className="m-0 text-muted">Check all applicable boxes</p>
          <div className="row mt-2">
            {flightModes &&
              flightModes.map((obj) => {
                return (
                  <div className="col-sm" key={obj.id}>
                    <input
                      type="checkbox"
                      name="flight_modes_id"
                      id={obj.id}
                      checked={state.flight_modes_id.includes(obj.id)}
                      value={state.flight_modes_id.includes(obj.id)}
                      onChange={_onCheckboxChange}
                      disabled={disabled}
                    />
                    <label className="ml-2" htmlFor={obj.val}>
                      {obj.val}
                    </label>

                    {obj.tooltip && (
                      <small className="ml-2 text-muted">{`(${obj.tooltip})`}</small>
                    )}
                  </div>
                );
              })}
          </div>
          <h6 className="border-bottom pb-2">Part IV. Currency Requirements</h6>
          <table>
            <thead>
              <tr>
                <th className="border border-bottom-0 p-1 w-25" scope="col">
                  ATP Year:
                </th>
                <th className="border p-1" scope="col">
                  1st Semi-Annual Period
                </th>
                <th className="border p-1" scope="col">
                  2nd Semi-Annual Period
                </th>
                <th className="border p-1" scope="col">
                  Adjustments
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-top-0 p-1 w-25">
                  <div className="d-flex flex-column text-align-center align-items-center">
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: 12,
                        alignItems: "center",
                      }}
                    >
                      <TextInput
                        value={state.atp_from}
                        property="atp_from"
                        placeholder="From"
                        schema={{}}
                        displayOnly={disabled || !state.atp_month}
                        onChange={_onChange}
                      />
                      <TextInput
                        value={state.atp_to}
                        property="atp_to"
                        placeholder="To"
                        schema={{}}
                        displayOnly
                      />
                    </div>
                  </div>
                </td>
                <td className="border p-1">
                  <TextInput
                    onChange={_onChange}
                    value={_getSemiRange(true)}
                    property="first_semi"
                    schema={{
                      title: "",
                    }}
                    displayOnly
                  />
                </td>
                <td className="border p-1">
                  <TextInput
                    onChange={_onChange}
                    value={_getSemiRange(false)}
                    property="second_semi"
                    schema={{
                      title: "",
                    }}
                    displayOnly
                  />
                </td>
                <th className="border p-1"></th>
              </tr>
              <tr>
                <td className="border p-1 w-25">
                  Flights - Required<sup>*</sup>
                </td>
                <td className="border p-1">
                  <div>
                    <TextInput
                      value={state.req_flights_first_rp}
                      property="req_flights_first_rp"
                      onChange={_onChange}
                      placeholder="RP Required Flights"
                      schema={{
                        title: "RP Required Flights",
                        description: "*minimum unless adjusted IAW APL 95-1-1",
                      }}
                      displayOnly={disabled}
                    />
                    <TextInput
                      value={state.req_flights_first_vo}
                      property="req_flights_first_vo"
                      onChange={_onChange}
                      placeholder="VO Required Flights"
                      schema={{
                        title: "VO Required Flights",
                        description: "*minimum unless adjusted IAW APL 95-1-1",
                      }}
                      displayOnly={disabled}
                    />
                  </div>
                </td>
                <td className="border p-1">
                  <div>
                    <TextInput
                      value={state.req_flights_second_rp}
                      property="req_flights_second_rp"
                      onChange={_onChange}
                      placeholder="RP Required Flights"
                      schema={{
                        title: "RP Required Flights",
                        description: "*minimum unless adjusted IAW APL 95-1-1",
                      }}
                      displayOnly={disabled}
                    />
                    <TextInput
                      value={state.req_flights_second_vo}
                      property="req_flights_second_vo"
                      onChange={_onChange}
                      placeholder="VO Required Flights"
                      schema={{
                        title: "VO Required Flights",
                        description: "*minimum unless adjusted IAW APL 95-1-1",
                      }}
                      displayOnly={disabled}
                    />
                  </div>
                </td>
                <td className="border p-1">
                  <TextInput
                    value={state.req_adj}
                    property="req_adj"
                    onChange={_onChange}
                    schema={{ title: "" }}
                    placeholder="Adjustments"
                    displayOnly={disabled}
                  />
                </td>
              </tr>
              <tr>
                <td className="border p-1 w-25">Flights - Actual*</td>
                <td className="border p-1">
                  <div>
                    <TextInput
                      value={state.act_flights_first_rp}
                      property="act_flights_first_rp"
                      onChange={_onChange}
                      placeholder="RP Actual Flights"
                      schema={{
                        title: "RP Actual Flights",
                        description: "*minimum unless adjusted IAW APL 95-1-1",
                      }}
                      displayOnly
                    />
                    <TextInput
                      value={state.act_flights_first_vo}
                      property="act_flights_first_vo"
                      onChange={_onChange}
                      placeholder="VO Actual Flights"
                      schema={{
                        title: "VO Actual Flights",
                        description: "*minimum unless adjusted IAW APL 95-1-1",
                      }}
                      displayOnly
                    />
                  </div>
                </td>
                <td className="border p-1">
                  <div>
                    <TextInput
                      value={state.act_flights_second_rp}
                      property="act_flights_second_rp"
                      onChange={_onChange}
                      placeholder="RP Actual Flights"
                      schema={{
                        title: "RP Actual Flights",
                        description: "*minimum unless adjusted IAW APL 95-1-1",
                      }}
                      displayOnly
                    />
                    <TextInput
                      value={state.act_flights_second_vo}
                      property="act_flights_second_vo"
                      onChange={_onChange}
                      placeholder="VO Actual Flights"
                      schema={{
                        title: "VO Actual Flights",
                        description: "*minimum unless adjusted IAW APL 95-1-1",
                      }}
                      displayOnly
                    />
                  </div>
                </td>
                <td className="border p-1">
                  <TextInput
                    value={state.act_adj}
                    property="act_adj"
                    onChange={_onChange}
                    schema={{ title: "" }}
                    placeholder="Adjustments"
                    displayOnly={disabled}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <h6 className="border-bottom pb-2 pt-3">
            Part V. Evaluation Requirements
          </h6>
          <table className="w-100">
            <thead>
              <tr>
                <th className="border p-1" scope="col">
                  Evaluation
                </th>
                <th className="border p-1" scope="col">
                  Date Completed
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border p-1">
                  Aviation Policy Letter 95-1-1 written knowledge test (open
                  book)
                </td>
                <td className="border p-1">
                  <DateInput
                    value={state.apl_test}
                    property="apl_test"
                    onChange={_onChange}
                    schema={{ title: "" }}
                    displayOnly={disabled}
                    placeholder="Enter date..."
                  />
                </td>
              </tr>
              <tr>
                <td className="border p-1">
                  Locally produced written knowledge test (use of reference
                  material authorized)
                </td>
                <td className="border p-1">
                  <DateInput
                    value={state.local_test}
                    property="local_test"
                    onChange={_onChange}
                    schema={{ title: "" }}
                    displayOnly={disabled}
                    placeholder="Enter date..."
                  />
                </td>
              </tr>
              <tr>
                <td className="border p-1">
                  In-flight evaluation (Includes oral topics)
                </td>
                <td className="border p-1">
                  <DateInput
                    value={state.in_flight_eval}
                    property="in_flight_eval"
                    onChange={_onChange}
                    schema={{ title: "" }}
                    displayOnly={disabled}
                    placeholder="Enter date..."
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {!state.id && (
            <p className="text-muted mt-3">
              Please save your entries to be able to sign the form.
            </p>
          )}
          {state.id && (
            <>
              <h6 className="border-bottom pb-2 mt-3">
                Part VI. Certification
              </h6>
              <h5 className="text-muted pb-2">
                <small>
                  This form and its enclosures establish your Aircrew Training
                  Program Requirements.
                </small>
              </h5>
              <div className="row">
                <div className="col-sm">
                  {efTwentyApprovalIsLoading ? (
                    <Loader />
                  ) : (
                    <PreparedBySigItem
                      title="ATPM Signature"
                      sig={state.atpm_sig}
                      onSign={() =>
                        doEfTwentyApprovalSave({ routing_order: 0 }, () =>
                          setDisabled(true)
                        )
                      }
                      item={{}}
                      disabled={!state.atpm_sig && !isPilotsAtpm}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg">
                  <TextArea
                    className="form-control"
                    value={state.remarks}
                    property="remarks"
                    onChange={_onChange}
                    schema={{
                      title: "Remarks",
                      description:
                        "Enter remarks in space below and make corresponding event entries, as necessary, in crewmember's EF 7122.",
                    }}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <PreparedBySigItem
                    title="SUAC Signature"
                    sig={state.suac_sig}
                    onSign={() =>
                      doEfTwentyApprovalSave({ routing_order: 1 }, () => {
                        doFetchAtpmDashboardById(profileId);
                        setDisabled(true);
                      })
                    }
                    item={{}}
                    disabled={
                      !state.suac_sig && !(profileActiveData.id === profileId)
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="modal-footer">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div>
            {data && data.expiration_date && (
              <h6 className="m-0">
                Expires on:{" "}
                <small className="text-muted">
                  {data.expiration_date.split("T")[0]}
                </small>
              </h6>
            )}
          </div>
          <div className="d-flex">
            {(state.atpm_sig || state.suac_sig) && (
              <button
                onClick={() => {
                  setDisabled(false);
                  doEfTwentyApprovalDeleteByForm(state.id);
                }}
                className="btn btn-sm btn-primary mr-2"
                type="button"
              >
                Unlock Form
              </button>
            )}
            <button
              onClick={doNestedDialogClose}
              className="btn btn-sm btn-secondary mr-2"
              type="button"
            >
              Cancel
            </button>
            <button
              onClick={_onSave}
              className={`btn btn-sm btn-${
                state.atp_month ? "success" : "secondary"
              }`}
              type="button"
              disabled={!state.atp_month}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  "doNestedDialogClose",
  "selectDomainsItemsByGroup",
  "doEfTwentySave",
  "doEfTwentyApprovalSave",
  "doEfTwentyApprovalDeleteByForm",
  "selectProfileActiveData",
  "selectEfTwentyApprovalIsLoading",
  "selectEfTwentyApprovalItems",
  "selectIsProfileActiveAtpm",
  "selectProfileId",
  "selectProfilesAll",
  "doFetchAtpmDashboardById",
  EFTwenty
);
