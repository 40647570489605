import React from "react";
import DatePicker from "react-datepicker";
import TrainingPicker from "./training-picker";
import "react-datepicker/dist/react-datepicker.css";

class TrainingForm extends React.Component {
  constructor(props) {
    super(props);
    const input = Object.assign({}, props.item);
    this.state = {
      training_id: input.training_id || "",
      cert_no: input.cert_no || "",
      completed_date: input.completed_date || new Date()
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.serialize = this.serialize.bind(this);
  }

  serialize() {
    const { item, trainingItemsObject } = this.props;
    const { training_id, cert_no, completed_date } = this.state;
    const t = trainingItemsObject[training_id];
    const updatedItem = {
      id: item.id || null,
      cert_no: cert_no,
      completed_date: completed_date,
      expires_in: t.expires_in,
      expires_in_uom: t.expires_in_uom,
      training_id: training_id,
      title: t.title
    };
    return updatedItem;
  }

  handleChange(e) {
    const key = e.currentTarget.dataset.key;
    const val = e.currentTarget.value;
    this.setState({
      [key]: val
    });
  }

  handleDateChange(e) {
    const val = e.toISOString();
    this.setState({
      completed_date: val
    });
  }

  render() {
    let { training_id, completed_date, cert_no } = this.state;
    completed_date = new Date(completed_date);
    return (
      <div>
        <div className="row">
          <div className="col">
            <TrainingPicker
              value={training_id}
              onChange={this.handleChange}
              dataKey="training_id"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-7">
            <label>Certificate No.</label>
            <input
              className="form-control"
              type="text"
              value={cert_no}
              data-key="cert_no"
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-5">
            <label>Complete Date</label>
            <DatePicker
              className="form-control"
              onChange={this.handleDateChange}
              selected={completed_date}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TrainingForm;
