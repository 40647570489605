import React from "react";
import { connect } from "redux-bundler-react";
const RedirectButton = ({ title, dest, doDocumentationChangePage }) => {
  const changePage = () => {
    doDocumentationChangePage(dest, true, (selectedSection) => {
      document.querySelectorAll(".documentation-nav .sub-nav-item button").forEach(item => {
        if (![selectedSection._key, selectedSection.parentPage].includes(item.name)) item.classList.remove("selected");
      });
    });
  }
  return (
    <button className="btn btn-link d-inline my-0 mx-2 p-0 pb-1" onClick={changePage}>
      See <b>{title}</b>
    </button>
  );
}
export default connect(
  "doDocumentationChangePage",
  RedirectButton);