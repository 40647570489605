import xhr from "xhr";

export default {
  name: "weather",

  getReducer: () => {
    const initialState = {
      error: null,
      hasLoaded: false,
      data: null,
      condition: null,
      dew_point: null,
      humidity: null,
      icon: null,
      mslp: null,
      precipitation: null,
      temperature: null,
      time: null,
      timezone: null,
      visibility: null,
      wind_gusting: null,
      wind_heading: null,
      wind_speed: null,
      url: `https://api.airmap.com/advisory/v1/weather/`,
      shouldFetch: false,
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case "WEATHER_FETCH_STARTED":
        case "WEATHER_FETCH_FINISHED":
          return Object.assign({}, state, payload);
        case "MAP_UPDATED_VIEW_STATE":
          return Object.assign({}, state, { shouldFetch: true });
        default:
          return state;
      }
    };
  },

  doWeatherFetch:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "WEATHER_FETCH_STARTED",
        payload: { shouldFetch: false },
      });
      const url = store.selectWeatherUrl();
      const api_key = store.selectAirmapApiKey();
      const center = store.selectMapCenter();
      const lon = center[0];
      const lat = center[1];
      xhr.get(
        {
          url: `${url}?longitude=${lon}&latitude=${lat}`,
          headers: {
            accept: "application/json",
            "x-api-key": api_key,
          },
        },
        (err, response, body) => {
          if (err) return console.log(err);
          const data = JSON.parse(body);
          if (data.status !== "success" || !data.data.weather.length) {
            dispatch({
              type: "WEATHER_FETCH_ERROR",
              payload: { error: data.message },
            });
          } else if (data.data.weather.length) {
            const weather = data.data.weather[0];
            const {
              condition,
              dew_point,
              humidity,
              icon,
              mslp,
              precipitation,
              temperature,
              time,
              timezone,
              visibility,
              wind,
            } = weather;
            dispatch({
              type: "WEATHER_FETCH_FINISHED",
              payload: {
                error: null,
                hasLoaded: true,
                data: data,
                condition,
                dew_point,
                humidity,
                icon,
                mslp,
                precipitation,
                temperature,
                time,
                timezone,
                visibility,
                wind_gusting: wind.gusting,
                wind_heading: wind.heading,
                wind_speed: wind.speed,
              },
            });
          }
        }
      );
    },

  selectWeatherUrl: (state) => {
    return state.weather.url;
  },

  selectWeather: (state) => {
    if (!state.weather.hasLoaded) return null;
    if (state.weather.error) return { error: state.weather.error };
    const {
      condition,
      dew_point,
      humidity,
      icon,
      mslp,
      precipitation,
      temperature,
      time,
      timezone,
      visibility,
      wind_gusting,
      wind_heading,
      wind_speed,
    } = state.weather;
    return {
      condition,
      dew_point,
      humidity,
      icon,
      mslp,
      precipitation,
      temperature,
      time,
      timezone,
      visibility,
      wind_gusting,
      wind_heading,
      wind_speed,
    };
  },

  // reactWeatherShouldFetch: state => {
  //   if (state.weather.shouldFetch) return { actionCreator: "doWeatherFetch" };
  // }
};
