import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";
import { keyBy, pickBy, pick, countBy, orderBy, find } from "lodash";

export default createRestBundle({
    name: "gradeSlip",
    uid: "id",
    prefetch: true,
    persist: false,
    routeParam: "formId",
    getTemplate: "/profile/:id/gradeSlip?:queryString",
    putTemplate: "/profile/:id/gradeSlip/:item.id",
    postTemplate: "/profile/:id/gradeSlip",
    urlParamSelectors: ["selectGradeSlipFormattedQueryString"],
    fetchActions: ["GRADESLIP_SAVE_FINISHED", "GRADESLIP_DELETE_FINISHED", "PROFILECURRENCY_FETCH_FINISHED"],
    initialData: {
        _evalType: "annual",
        _isSigning: false,
        _selected: null,
        _selectedDisabled: false,
        _selectedLastSave: null,
        _editedEntries: {},
        _entriesLastSave: {},
        _customEvalTypes: {},
        _defaultEvalTypes: {
            annual: { property: 'event', value: 'annual', label: 'Annual Comprehensive' },
            post_mishap_flight_eval: { property: 'event', value: 'post_mishap_flight_eval', label: 'Post Flight Mishap' },
            proficiency_flight_eval: { property: 'event', value: 'proficiency_flight_eval', label: 'Flight Proficiency' },
            no_notice_eval: { property: 'event', value: 'no_notice_eval', label: 'No-Notice' }
        },
        _newFormSelected: false,
        _flightModeOptionsObj: {
            na: { label: "NOT APPLICABLE", name: "na", value: "na" },
            day: { label: "DAY", name: "day", value: "day" },
            night: { label: "NIGHT", name: "night", value: "night" },
            dayBvlos: { label: "DAY - BVLOS", name: "dayBvlos", value: "dayBvlos" },
            movingVehicleDay: { label: "From a moving vehicle - DAY", name: "movingVehicleDay", value: "movingVehicleDay" },
            movingVehicleNight: { label: "From a moving vehicle - NIGHT", name: "movingVehicleNight", value: "movingVehicleNight" },
            boatDay: { label: "From a Boat - DAY", name: "boatDay", value: "boatDay" },
            boatNight: { label: "From a Boat - NIGHT", name: "boatNight", value: "boatNight" }
        },
        _taskOptionsObj: {
            "apl-open-book": { label: "APL 95-1-1 Open-Book Exam", name: "apl-open-book", value: "apl-open-book" },
            "local-open-book": { label: "LOCAL SOP Open-Book Exam", name: "local-open-book", value: "local-open-book" },
            "oral-eval": { label: "Oral Evaluation IAW 95-1-1", name: "oral-eval", value: "oral-eval" },
            "mission-analysis": { label: "0901 - Perform Mission Analysis", name: "mission-analysis", value: "mission-analysis" },
            "mission-request": { label: "0902 - Plan and Submit an SUAS Mission Request", name: "mission-request", value: "mission-request" },
            "crew-brief": { label: "1000 - Participate in a Crew Brief", name: "crew-brief", value: "crew-brief" },
            "prepare-flight": { label: "1001 - Prepare SUAS for Flight", name: "prepare-flight", value: "prepare-flight" },
            "radio-comm": { label: "1002 - Conduct Radio Communications", name: "radio-comm", value: "radio-comm" },
            "auto-mode": { label: "1003 - Operate UA in Autonomous Mode", name: "auto-mode", value: "auto-mode" },
            "manual-mode": { label: "1004 - Operate UA in Manual Mode", name: "manual-mode", value: "manual-mode" },
            "emergency-response": { label: "1005 - Respond to an Emergency", name: "emergency-response", value: "emergency-response" },
            "post-flight": { label: "1006 - Complete Post Flight Procedures", name: "post-flight", value: "post-flight" },
            "maintenance": { label: "1007 - Conduct Operator Level Maintenance", name: "maintenance", value: "maintenance" },
            "process-mission-data": { label: "1008 - Process Mission Data", name: "process-mission-data", value: "process-mission-data" },
            "vantage-point": { label: "4000 - Select a Vantage Point", name: "vantage-point", value: "vantage-point" },
            "surveillance": { label: "4001 - Maintain Airspace Surveillance", name: "surveillance", value: "surveillance" },
            "academic-instruction": { label: "5000 - Provide Academic Instruction", name: "academic-instruction", value: "academic-instruction" },
            "equipment-training": { label: "5001 - Provide New Equipment Training", name: "equipment-training", value: "equipment-training" },
            "flight-training": { label: "5002 - Provide Flight Training", name: "flight-training", value: "flight-training" },
            "crew-eval": { label: "5003 - Conduct Crewmember Evaluations", name: "crew-eval", value: "crew-eval" },
            "FREE TEXT ENTRY": { label: "FREE TEXT ENTRY", name: "FREE TEXT ENTRY", value: "FREE TEXT ENTRY" }
        }
    },
    reduceFurther: (state, { type, payload }) => {
        switch (type) {
            case "GRADESLIP_UPDATE_SELECTED_FORM":
            case "GRADESLIP_SIG_UPDATE_ERROR":
            case "GRADESLIP_DELETE_ERROR":
            case "GRADESLIP_SIGNED":
            case "GRADESLIP_UPDATE":
            case "GRADESLIP_UPDATE_ERROR":
            case "GRADESLIP_UPDATE_CUSTOM_EVAL_TYPES":
            case "GRADESLIP_UPDATE_CUSTOM_EVAL_TYPES_ERROR":
                return Object.assign({}, state, payload);
            default:
                return state;
        }
    },
  addons: {
        selectGradeSlipLinkedMission: createSelector(
          "selectMissionsItems",
          "selectGradeSlipSelectedForm",
          (missionsItems, gradeSlipSelectedForm) => {
            if (!gradeSlipSelectedForm) return null;
            return find(missionsItems, ["id", gradeSlipSelectedForm.mission_id]);
          }
        ),
        selectGradeSlipSelectableMissions: createSelector(
          "selectMissionsItems",
          (missionsItems) => {
            return orderBy(missionsItems, ["date_start"], ["desc"]).map((item) => {
              return { label: item.name, value: item.id, property: "mission_id", slug:item.slug };
            })
          }
        ),
        selectGradeSlipSelectedEvalType: createSelector(
          "selectGradeSlipAllTypes",
          "selectGradeSlipEvalType",
          (gradeSlipAllTypes, gradeSlipEvalType) => {
            return gradeSlipAllTypes[gradeSlipEvalType]
          }
        ),
        selectGradeSlipUnviewedItemsByEvalType: createSelector(
            "selectGradeSlipAllTypes",
            "selectGradeSlipItemsSigned",
            (gradeSlipAllTypes, gradeSlipItems) => {
                let allTypes = Object.keys(gradeSlipAllTypes);
                return Object.fromEntries(allTypes.map(type => [type, countBy(gradeSlipItems, { event: type, viewed: false })]));
            }
        ),
        selectGradeSlipItemsSigned: createSelector(
          "selectGradeSlipItems",
          (gradeSlipItems) => {
            return gradeSlipItems.filter(item => item.atpm_sig && item.atpm_sig.length > 0)
          }
        ),
        selectGradeSlipItemsByEvalType: createSelector(
          "selectGradeSlipItems",
          "selectGradeSlipEvalType",
          "selectIsProfileActiveAtpm",
          "selectRouteInfo",
          (gradeSlipItems, gradeSlipEvalType, isProfileActiveAtpm, routeInfo) => {
            if (!isProfileActiveAtpm || routeInfo.url.includes('/profile')) return gradeSlipItems.filter(item => item.event === gradeSlipEvalType)
              return gradeSlipItems.filter(item => item.event === gradeSlipEvalType);
          }
        ),
        selectGradeSlipFormattedQueryString: createSelector(
            "selectProfileActiveData",
            "selectOrgsByRoute",
            (profileActiveData, orgsByRoute) => {
                let orgId = orgsByRoute ? orgsByRoute.id : ''
                return { ...profileActiveData, queryString: `orgId=${orgId}` }
            }
        ),
        selectGradeSlipEntriesLastSave: (state) => {
          return state.gradeSlip._entriesLastSave;
        },
        selectGradeSlipCustomEvalTypes: state => {
          return state.gradeSlip._customEvalTypes;
        },
        selectGradeSlipDefaultEvalTypes: state => {
          return state.gradeSlip._defaultEvalTypes;
        },

        selectGradeSlipAllTypes: createSelector(
            "selectGradeSlipDefaultEvalTypes",
            "selectGradeSlipCustomEvalTypes",
            (gradeSlipDefaultEvalTypes, gradeSlipCustomEvalTypes) => {
                return (
                    {
                        ...gradeSlipDefaultEvalTypes,
                        ...keyBy(gradeSlipCustomEvalTypes, 'value')
                    }
                )
            }
        ),

        doGradeSlipUpdateFormViewed: (selected) => ({ dispatch, store, apiPut }) => {
            let profileActiveData = store.selectProfileActiveData();
            if (!selected.viewed && selected.id) {
                apiPut(`/profile/${profileActiveData.id}/gradeSlip/${selected.id}/view`, selected, (err, response, body) => {
                    if (err || response.statusCode !== 200) {
                        dispatch({
                            type: "GRADESLIP_UPDATE_ERROR", payload: {
                                _isSigning: false,
                                _err: { response: response, err: err },
                                notification: {
                                    statusCode: { response: response.statusCode }
                                }
                            }
                        });
                    }
                    else dispatch({ type: "GRADESLIP_UPDATE" });
                })
            }
        },

        doGradeSlipFetchCategoriesByOrg: () => ({ dispatch, store, apiGet }) => {
            let profileActiveData = store.selectProfileActiveData();
            let orgsByRoute = store.selectOrgsByRoute();
            let url = `/profile/${profileActiveData.id}/gradeSlip/querySpecificCategories`
            if (orgsByRoute) url += `?orgId=${orgsByRoute.id}`;
            let defaultEvalTypes = store.selectGradeSlipDefaultEvalTypes();
            apiGet(url, (err, response, body) => {
                if (err || response.statusCode !== 200) {
                    dispatch({
                        type: "GRADESLIP_UPDATE_CUSTOM_EVAL_TYPES_ERROR",
                        payload: {
                            _err: { response: response, err: err },
                            notification: {
                                statusCode: { response: response.statusCode },
                            },
                        },
                    });
                }
                const result = JSON.parse(body);
                if (result) {
                    let defaultTypesWithCounts = keyBy(defaultEvalTypes, 'value');
                    let customTypesWithCounts = [];
                    result.forEach(type => {
                      if (type.custom && !find(customTypesWithCounts, ['event', type.event])) customTypesWithCounts.push(type)
                      else if (!type.custom) defaultTypesWithCounts = { ...defaultTypesWithCounts, [type.event]: { ...defaultTypesWithCounts[type.event], count: type.count } }
                    })
                    dispatch({
                        type: "GRADESLIP_UPDATE_CUSTOM_EVAL_TYPES", payload: {
                            _defaultEvalTypes: defaultTypesWithCounts,
                            _customEvalTypes: customTypesWithCounts.map(type => Object.assign({ value: type.event, label: type.event, property: 'event', count: type.count })),
                        }
                    })
                }
            })
        },
        doGradeSlipUpdateEditedEntries: (updatedEntries, callback) => ({ dispatch, store }) => {
            let results = {};
            let newEntries = pickBy(updatedEntries, (val, key) => key.includes('temp_'))
            let lastSavedEntries = store.selectGradeSlipEntriesLastSave();
            Object.values(lastSavedEntries).forEach(lastSavedEntry => {
                if (!lastSavedEntry.id.includes('temp_')) {
                    if (updatedEntries[lastSavedEntry.id]) {
                        let current = pick(updatedEntries[lastSavedEntry.id], ['date', 'grade', 'flight_mode', 'task', 'task_variable']);
                        let lastSave = pick(lastSavedEntry, ['date', 'grade', 'flight_mode', 'task', 'task_variable']);
                        if (JSON.stringify(current) !== JSON.stringify(lastSave)) {
                            results[lastSavedEntry.id] = updatedEntries[lastSavedEntry.id]
                        }
                    }
                }
            })
            dispatch({
                type: "GRADESLIP_UPDATE", payload: {
                    _editedEntries: { ...newEntries, ...results }
                }
            });
            if (callback) callback();
        },
        selectGradeSlipEditedEntries: (state) => {
            return state.gradeSlip._editedEntries;
        },
        selectGradeSlipIsSigning: (state) => {
            return state.gradeSlip._isSigning;
        },
        selectGradeSlipEvalType: (state) => {
            return state.gradeSlip._evalType;
        },
        selectGradeSlipSelected: (state) => {
          return state.gradeSlip._selected;
        },
        selectGradeSlipSelectedForm: createSelector(
          "selectGradeSlipSelected",
          "selectGradeSlipAllTypes",
          "selectMissionsItems",
          (gradeSlipSelected, gradeSlipAllTypes, missionsItems) => {
            if (!gradeSlipSelected) return null;
            const { overall_grade, suac_debrief_complete, additional_training, event, mission_id, atpm_sig } = gradeSlipSelected;
            const printableFormValues = {
              overall_grade_sat: overall_grade === 'sat',
              overall_grade_unsat: overall_grade === 'unsat',
              overall_grade_na: overall_grade === 'na',
              suac_debrief_complete_yes: suac_debrief_complete === 'yes',
              suac_debrief_complete_no: suac_debrief_complete === 'no',
              additional_training_yes: additional_training === 'yes',
              additional_training_no: additional_training === 'no',
              event_str: gradeSlipAllTypes[event] ? gradeSlipAllTypes[event].label : '',
              mission_name: find(missionsItems, ["id", mission_id]),
              atpm_sig_parsed: atpm_sig? JSON.parse(atob(atpm_sig.split(".")[0])) : ''
            }
            return { ...gradeSlipSelected, ...printableFormValues };
          }
        ),
        selectGradeSlipSelectedFormLastSave: (state) => {
            return state.gradeSlip._selectedLastSave;
        },
        doGradeSlipRemoveForm: (data) => ({ dispatch, apiDelete }) => {
          const { id, profile_id } = data;
            apiDelete(`/profile/${profile_id}/gradeSlip/${id}`, data, (err, response, body) => {
                if (err || response.statusCode !== 200) {
                    dispatch({
                        type: "GRADESLIP_DELETE_ERROR", payload: {
                            _err: { response: response, err: err },
                            notification: {
                                statusCode: { response: response.statusCode }
                            }
                        }
                    });
                }
                else dispatch({ type: "GRADESLIP_DELETE_FINISHED" });
            });
        },
        doGradeSlipAtpmModifySign: (data, callback) => async ({ dispatch, apiPut }) => {
            const { id: formId, profile_id } = data;
            dispatch({ type: "GRADESLIP_UPDATE", payload: { _isSigning: true } });
            await apiPut(`/profile/${profile_id}/gradeSlip/${formId}/approval`, data, (err, response, body) => {
                if (err || response.statusCode !== 200) {
                    dispatch({
                        type: "GRADESLIP_SIG_UPDATE_ERROR", payload: {
                            _isSigning: false,
                            _err: { response: response, err: err },
                            notification: {
                                statusCode: { response: response.statusCode }
                            }
                        }
                    });
                }
                else {
                  dispatch({
                    type: "GRADESLIP_SIGNED",
                    payload: {
                      _isSigning: false,
                      _selected: { ...data, atpm_sig: body.sig },
                      _selectedLastSave: { ...data, atpm_sig: body.sig }
                    }
                  });
                    if (callback) callback(body);
                }
            });
        },
        doGradeSlipUpdateState: (update, callback) => ({ dispatch, store }) => {
            dispatch({ type: "GRADESLIP_UPDATE", payload: update });
            if (callback) callback()
        },

        doGradeSlipUpdateSelectedForm: (update) => ({ dispatch }) => {
            dispatch({
                type: "GRADESLIP_UPDATE_SELECTED_FORM", payload: {
                    _selected: { ...update },
                    _selectedLastSave: { ...update },
                    _editedEntries: {},
                    _entriesLastSave: {},
                    _newFormSelected: true,
                    _selectedDisabled: update.atpm_sig && update.atpm_sig.length > 0
                }
            });
        }
    }
});