import { connect } from "redux-bundler-react";
import FileUploadCard from "../_shared/file-upload-card/file-upload-card";

const CoaUploadModal = ({
  doDialogClose,
  doFileIoDelete,
  doFileIoUpload,
  fileIoIsUploading,
  missionId,
  docId,
  coaApprovalDocsItems,
}) => {
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Upload Approved COA</h5>
        <button
          onClick={doDialogClose}
          className="close"
          type="button"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div className="modal-body">
        <FileUploadCard
          allowRoles={["HQ.ADMIN"]}
          items={coaApprovalDocsItems.filter((obj) => {
            return obj.coa_doc_id === docId;
          })}
          header={true}
          onUpload={doFileIoUpload}
          onRemove={doFileIoDelete}
          data={{
            coa_doc_id: docId,
          }}
          rel="coa_approval_docs"
          postUrl="/docs/upload/coaApproval"
          removeUrl="/coaApproval/docs"
        />
      </div>

      <div className="modal-footer">
        <button
          onClick={doDialogClose}
          className="btn btn-sm btn-secondary"
          type="button"
        >
          Close
        </button>
        <button
          onClick={doDialogClose}
          className="btn btn-sm btn-success"
          type="button"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default connect(
  "doDialogClose",
  "doFileIoDelete",
  "doFileIoUpload",
  "selectFileIoIsUploading",
  "selectCoaDocsItems",
  "selectCoaApprovalDocsItems",
  CoaUploadModal
);
