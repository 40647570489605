import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "efTwentyApproval",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/efTwenty/:id/approval",
  postTemplate: "/efTwenty/:id/approval",
  putTemplate: "",
  deleteTemplate: "/efTwenty/:id/approval",
  fetchActions: [
    "URL_UPDATED",
    "AUTH_LOGGED_IN",
    "EFTWENTYAPPROVAL_SAVE_FINISHED",
    "EFTWENTYAPPROVAL_DELETE_FINISHED",
    "EFTWENTY_FETCH_FINISHED",
  ],
  urlParamSelectors: ["selectEfTwentyActive"],
  addons: {
    doEfTwentyApprovalDeleteByForm:
      (formId) =>
      ({ dispatch, store, apiDelete }) => {
        dispatch({
          type: "EFTWENTYAPPROVAL_DELETE_STARTED",
          payload: {
            _isDeleting: true,
          },
        });
        apiDelete(`/efTwenty/${formId}/approval`, {}, (err, response, body) => {
          if (err || response.statusCode !== 200) {
            dispatch({
              type: "EFTWENTYAPPROVAL_DELETE_ERROR",
              payload: {
                _isDeleting: false,
              },
            });
          } else {
            dispatch({
              type: "EFTWENTYAPPROVAL_DELETE_FINISHED",
              payload: {
                _isDeleting: false,
              },
            });
          }
        });
      },
  },
});
