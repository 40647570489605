import React from "react";
import { connect } from "redux-bundler-react";
import PreparedBySigItem from "./prepared-by-sig-item";
import ApprovalRouting from "./approval-routing";
import GenerateCoa from "./generate-coa";
import { find } from "lodash";

const statements = {
  plan: "I have verified all referenced documents and reviewed each hazard on the attached DD Form 2977 with the RPI. This mission can be conducted within the assessed risk.",
  "Mission Briefing Officer":
    "I have reviewed for completeness and risk mitigation.",
  "Mission Approval":
    "I have reviewed this mission plan.  This mission can be completed within the assessed risk.  This mission is approved to be completed within the planned parameters.",
  review:
    "This signature is not required for mission approval. Approximately 25% of missions will be reviewed.",
};

class Approvals extends React.Component {
  constructor(props) {
    super(props);
    this.handleSign = this.handleSign.bind(this);
  }

  componentDidMount() {
    const { missionsApprovalIsLoading, doMissionsApprovalFetch } = this.props;
    if (!missionsApprovalIsLoading) doMissionsApprovalFetch();
  }

  handleSign(item, type) {
    const {
      doMissionsApprovalSave,
      doMissionsApprovalReview,
      doMissionsApprovalFetch,
      doMissionsFetch,
      tokenRolesJoined,
    } = this.props;
    let userIsHqAdmin = tokenRolesJoined.includes("HQ.ADMIN");
    if (type === "review") {
      if (userIsHqAdmin) {
        doMissionsApprovalReview(
          item,
          () => {
            doMissionsFetch();
            doMissionsApprovalFetch();
          },
          true
        );
      }
    } else {
      doMissionsApprovalSave(
        item,
        () => {
          doMissionsFetch();
          doMissionsApprovalFetch();
        },
        true
      );
    }
  }

  render() {
    const {
      missionsByRoute,
      missionsRawHazardsResidualRisk,
      missionsApprovalIsSaving,
      missionsApprovalIsLoading,
      missionsAlertsAssocAdjudications,
      missionsIsLoading,
      tokenRolesJoined,

      coaApprovalDocsItems,
      coaDocsByMission,
    } = this.props;
    const { plan_sig, review_sig } = missionsByRoute;

    let userIsHqAdmin = tokenRolesJoined.includes("HQ.ADMIN");

    let isLoading =
      missionsIsLoading ||
      missionsApprovalIsLoading ||
      missionsApprovalIsSaving;
    //adjudicated array is an array of all alerts that are not adjudicated. if adjudicated.length > 0 then disable signing.
    let alertsPreApproval =
      missionsAlertsAssocAdjudications &&
      missionsAlertsAssocAdjudications.length > 0
        ? missionsAlertsAssocAdjudications.filter(
            (item) => item.tab !== "flights"
          )
        : [];
    let adjudicated =
      alertsPreApproval.length > 0
        ? alertsPreApproval.filter(
            (item) => !(item.adjudications && item.adjudications.length > 0)
          )
        : [];

    let hasCoaApproval =
      coaApprovalDocsItems.filter((d) =>
        find(coaDocsByMission, { id: d.coa_doc_id })
      ).length > 0;
    return (
      <div>
        <div>
          This Mission has an Overall Residual Risk of{" "}
          <b>{missionsRawHazardsResidualRisk}</b>
        </div>
        <hr />
        {isLoading ? (
          <div className="d-flex w-100 justify-content-center">
            <i className="mdi mdi-loading mdi-spin mdi-48px" />
          </div>
        ) : (
          <>
            <GenerateCoa />
            <hr />
            <PreparedBySigItem
              title="Prepared By"
              statement={statements.plan}
              sig={plan_sig}
              onSign={this.handleSign}
              item={{}}
              disabled={!plan_sig && adjudicated.length > 0}
            />
            <ApprovalRouting
              planSig={plan_sig}
              onSign={this.handleSign}
              showCheckbox={alertsPreApproval.length > 0}
              hasCoaApproval={hasCoaApproval}
            />
            <PreparedBySigItem
              title="Quality Review"
              statement={statements.review}
              sig={review_sig}
              onSign={(item) => this.handleSign(item, "review")}
              item={{}}
              disabled={review_sig ? false : !userIsHqAdmin}
            />
          </>
        )}
      </div>
    );
  }
}

export default connect(
  "selectMissionsByRoute",
  "selectMissionsRawHazardsResidualRisk",
  "doMissionsApprovalSave",
  "doMissionsApprovalReview",
  "selectMissionsApprovalIsSaving",
  "selectMissionsApprovalIsLoading",
  "selectMissionsAdjudicationsItems",
  "doMissionsApprovalFetch",
  "doMissionsFetch",
  "selectMissionsIsLoading",
  "selectMissionsAlertsAssocAdjudications",
  "selectTokenRolesJoined",

  "selectCoaApprovalDocsItems",
  "selectCoaDocsByMission",
  Approvals
);
