import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
    name: "mmsPlatforms",
    uid: "slug",
    prefetch: true,
    routeParam: null,
    getTemplate: "/orgs/:orgSlug/mms/:mmsSlug/platforms",
    putTemplate: "/orgs/:orgSlug/mms/:mmsSlug/platforms/:item.id",
    postTemplate: "",
    deleteTemplate: "/orgs/:orgSlug/mms/:mmsSlug/platforms/:item.id",
    fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN"]
});