import React from "react";
import TrainingListItem from "./training-list-item";
import { sortBy } from "lodash";
import Loader from "../../../app-components/loader";

class TrainingList extends React.Component {
  render() {
    const { items, enableEditing, isLoading } = this.props;
    if (isLoading) return <Loader />;
    const sorted = sortBy(items, ["service_date"]);
    if (!sorted.length) {
      return (
        <ul
          className="list-group"
          style={{
            height: items.length >= 3 ? 165 : "auto",
            overflowY: "scroll",
          }}
        >
          <li className="list-group-item">No Training Records...</li>
        </ul>
      );
    } else {
      return (
        <ul
          className="list-group"
          style={{
            height: items.length >= 3 ? 165 : "auto",
            overflowY: "scroll",
          }}
        >
          {sorted.map((item, i) => {
            return (
              <TrainingListItem
                item={item}
                key={i}
                enableEditing={enableEditing}
              />
            );
          })}
        </ul>
      );
    }
  }
}

export default TrainingList;
