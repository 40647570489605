import React from "react";
import NotesCard from "../../../_shared/notes-card/note-card";

class NotesTab extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col">
          <NotesCard />
        </div>
      </div>
    );
  }
}

export default NotesTab;
