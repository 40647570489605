import olMap from "ol/Map.js";
import { transform } from "ol/proj";
import { transformExtent } from "ol/proj";
import View from "ol/View";
import {
  defaults as defaultControls,
  FullScreen,
  ScaleLine,
  Zoom
} from "ol/control";
// import ScaleBar from "ol/control/ScaleLine";
// import Zoom from "ol/control/Zoom";

function lon2tile(lon, zoom) {
  return Math.floor(((lon + 180) / 360) * Math.pow(2, zoom));
}
function lat2tile(lat, zoom) {
  return Math.floor(
    ((1 -
      Math.log(
        Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)
      ) /
        Math.PI) /
      2) *
      Math.pow(2, zoom)
  );
}

const createOlMap = (options, geoProjection, mapProjection) => {
  const view = new View({
    center: transform(options.center, geoProjection, mapProjection),
    zoom: options.zoom,
    rotation: options.rotation,
    minZoom: options.minZoom,
    maxZoom: options.maxZoom
  });
  return new olMap({
    controls: defaultControls().extend([
      new ScaleLine({ units: "us" }),
      new Zoom(),
      new FullScreen()
    ]),
    layers: options.layers || [],
    target: options.target,
    view: view,
    loadTilesWhileAnimating: false,
    loadTilesWhileInteracting: false
  });
};

export { createOlMap, transform, transformExtent, lon2tile, lat2tile };
