import React from "react";
import { connect } from "redux-bundler-react";

const ImageDialog = ({
    doDialogClose,
    params,
}) => {
    return (
        <div className="modal-title">
            <div className="modal-content" onMouseLeave={(e) => {
                doDialogClose();
            }}>
                <div className="modal-header d-block">
                    <h5 className="float-center">
                        {params.data.make} - {params.data.model}
                        <button
                            className="float-right close"
                            type="button"
                            aria-label="close"
                            onClick={() => {
                                doDialogClose();
                            }}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </h5>
                </div>
                <img src={`data:image/jpeg;base64,${params.value}`} />

            </div>
        </div>
    );
};

export default connect(
    "doDialogClose",
    ImageDialog
)