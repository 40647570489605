import React from "react";
import Loader from "../../../app-components/loader";
import classnames from "classnames"
import "./item-board.css"

export const ItemPageNav = ({ children, className, style }) => {
    let itemPageNavCls = classnames({
        "item-page-nav row py-2 d-flex border-bottom align-items-center": true,
        [className]: className
    })
    return (
        <div className={itemPageNavCls} style={style}>
            {children.map((child, idx) => {
                return (<div className="col-4" key={idx}>
                    {child}
                </div>)
            })}
        </div>
    )
}

export const ItemPageBody = ({ children, className, style }) => {
    let itemPageBodyCls = classnames({
        "row mt-3": true,
        [className]: className
    })
    return (
        <div className={itemPageBodyCls} style={style}>
            {children}
        </div>
    )
}

const ItemPage = ({ children, className, style, itemFlags, itemType }) => {
    const { _shouldFetch } = itemFlags
    let itemPageCls = classnames({
        "container-fluid": true,
        "d-flex justify-content-around": _shouldFetch,
        [className]: className
    })

    if (_shouldFetch) {
        return (
            <div className={itemPageCls} style={{ marginTop: "80px", width: "85vw", height: "75vh" }}>
                <div className="d-flex flex-column mx-auto my-auto align-middle">
                    <Loader />
                    <h4>Loading {itemType}...</h4>
                </div>
            </div >
        )
    }
    return (
        <div className={itemPageCls} style={style}>
            <div className="d-flex flex-column">
                {children}
            </div>
        </div>
    );
}

export default ItemPage;