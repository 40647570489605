import React from "react";
import { connect } from "redux-bundler-react";
import { transferNewItem } from "../../models";
import SchemaForm from "../../app-components/schema-form/schema-form";

class TransfersNew extends React.Component {
  constructor(props) {
    super(props);

    let pending = null,
      inProgress = null;
    const domains = this.props.domainsItemsByGroup["transfer_status"];
    domains.forEach((d) => {
      if (d.val === "Pending") pending = d.id;
      if (d.val === "In-Progress") inProgress = d.id;
    });

    this.state = {
      name: "",
      from_org_id: props.orgsByRoute.id,
      from_status_id: inProgress,
      to_status_id: pending,
      hq_status_id: pending,
      return_status_id: pending,
    };
    this.save = this.save.bind(this);
  }

  save() {
    const { doTransfersSave, orgsByRoute, doUpdateRelativeUrl } = this.props;
    if (this.form.isValid()) {
      const data = this.form.serialize();
      doTransfersSave(
        data,
        (newItem) => {
          doUpdateRelativeUrl(
            `/${orgsByRoute.slug}/transfers/${newItem.slug}/equipment`
          );
        },
        true
      );
    }
  }

  render() {
    const { transfersIsSaving: isSaving, domainsItemsByGroup } = this.props;
    return (
      <div className="container mt-3">
        <div className="row">
          <div className="col-sm-12 col-lg-9">
            <div className="card">
              <div className="card-header">
                <i className="mdi mdi-swap-horizontal"></i> New Property
                Transfer
              </div>
              <div className="card-body">
                <SchemaForm
                  ref={(el) => {
                    this.form = el;
                  }}
                  inline={true}
                  displayOnly={false}
                  schema={transferNewItem}
                  data={this.state}
                  domains={domainsItemsByGroup}
                />
                <div className="float-right">
                  <button
                    disabled={isSaving}
                    onClick={() => {
                      window.history.back();
                    }}
                    className="btn btn-secondary mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={isSaving}
                    onClick={this.save}
                    className="btn btn-primary"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "doTransfersSave",
  "selectOrgsByRoute",
  "selectOrgsByRoute",
  "selectDomainsItemsByGroup",
  TransfersNew
);
