import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";
import { marked } from "marked";
import purify from "dompurify";
import { find } from "lodash";
import { format } from "date-fns";
import {
  constructTimeRemainingString,
  renderName,
  setHyperlinkTargetsOnPage,
} from "./_shared/utils";

import ItemPage, {
  ItemPageNav,
  ItemPageBody,
} from "../_shared/item-board/item-page";
import CommentsSection from "../_shared/item-board/comments-section";
import ItemPageComment from "../_shared/item-board/item-page-comment";
import JobItemDocsCard from "./job-item-docs-card";
import JobItemEditor, { loadDataToNewForm } from "./job-item-editor";
import JobItemPocCard from "./job-item-poc-card";
import "../_shared/item-board/item-board.css";

const JobItemPage = ({
  jobsCommentsFlags,
  jobsCurrentUserView,
  jobsCurrentPageItem,
  jobsCommentsItems,
  jobsViewsItems,
  jobsDocsItems,
  ...props
}) => {
  const {
    tokenPayload,
    doUpdateRelativeUrl,
    doJobsInitUrlParams,
    doJobsNewFormStateUpdate,
  } = props;
  const [state, setState] = useState({
    jobData: {
      ...jobsCurrentPageItem,
      comments: jobsCommentsItems,
      reducedComments: [],
      attachments: [],
      poc: [],
    },
    commentText: "",
    commentsBoxReduced: true,
    activeUserView: jobsCurrentUserView,
    editingMode: false,
    commentCols: { created_by: tokenPayload.sub, body: "", job_id: "" },
  });
  const toBoardBtnClicked = async (e) => {
    await doUpdateRelativeUrl("/job-board");
  };
  const editBtnClicked = async (value) => {
    await loadDataToNewForm(doJobsNewFormStateUpdate, jobsCurrentPageItem);
    setState({
      ...state,
      editingMode: typeof value === "boolean" ? value : !state.editingMode,
    });
  };

  const favoritedBtnClicked = (e) => {
    const { jobData, activeUserView } = state;
    const { doJobsViewsSave } = props;
    let currentVal = activeUserView && activeUserView.favorited ? 1 : 0;
    let nextVal = 0;
    if (currentVal === 1) nextVal = 0;
    else nextVal = 1;

    let ratingsAttrs = {
      job_id: jobData.id,
      created_by: tokenPayload.sub,
      favorited: nextVal,
      deleted: 0,
    };
    if (activeUserView)
      doJobsViewsSave(
        Object.assign({}, { ...ratingsAttrs, id: activeUserView.id })
      );
    else doJobsViewsSave(Object.assign({ ...ratingsAttrs }));
  };
  const renderFavoriteBtn = () => {
    const { activeUserView } = state;
    const { jobsViewsFlags, jobsViewsIsSaving, jobsViewsIsLoading } = props;
    let userFavoritedJob =
      activeUserView && activeUserView.favorited ? activeUserView.favorited : 0;
    let favoritedBtnCls = classnames({
      "btn btn-sm d-block ml-auto mr-0": true,
      "btn-success": userFavoritedJob === 1,
      "btn-outline-success": userFavoritedJob === 0,
    });

    if (jobsViewsFlags._shouldFetch || jobsViewsIsSaving || jobsViewsIsLoading)
      return (
        <div className="d-block ml-auto mr-0 text-success spinner-border" />
      );
    return (
      <button
        className={favoritedBtnCls}
        type="button"
        onClick={favoritedBtnClicked}
      >
        <span
          className={`mdi mdi-bookmark${
            userFavoritedJob ? "-check" : "-outline"
          } mr-2 `}
          style={{ fontSize: "16px" }}
        />
        {userFavoritedJob ? "Favorited" : "Add to Favorites"}
      </button>
    );
  };
  const renderExpirationCard = () => {
    const { jobData } = state;
    let { expiration_date, timeRemainingString, timeRemainingCls } =
      constructTimeRemainingString(jobData.published_date);

    let expStr = expiration_date.toLocaleString();
    let expStrSplit = expStr.split(",");
    const postIsDrafted = jobData && jobData.published === 0;
    const iconCls = classnames({
      "mdi mt-0 mr-2": true,
      "mdi-update": !postIsDrafted,
      "mdi-email-edit-outline": postIsDrafted,
    });
    return (
      <div
        className="alert alert-secondary d-flex flex-lg-row flex-xl-row flex-md-column flex-sm-column flex-column mx-auto border border-secondary my-auto"
        style={{ maxWidth: "fit-content", padding: ".25rem" }}
      >
        <div className="alert-header d-flex flex-row justify-content-lg-start px-2 py-2 py-md-0 py-sm-0 py-sm-0 ">
          <span className={iconCls} style={{ fontSize: "larger" }} />
          <span className={timeRemainingCls} style={{ fontSize: "larger" }}>
            {postIsDrafted ? "DRAFTED" : timeRemainingString}
          </span>
        </div>
        {!postIsDrafted && (
          <div className="ml-lg-auto ml-xl-auto mx-md-auto mx-sm-auto mx-auto my-auto d-flex flex-column px-2 py-md-0 py-sm-0 py-sm-0 text-right">
            <small style={{ fontWeight: "lighter", width: "12rem" }}>
              Expires on {expStrSplit}
            </small>
          </div>
        )}
      </div>
    );
  };
  const renderJobDates = () => {
    const { jobData } = state;
    let startStr = jobData.date_start
      ? format(new Date(jobData.date_start), "MM/dd/yyyy")
      : null;
    let endStr = jobData.date_end
      ? format(new Date(jobData.date_end), "MM/dd/yyyy")
      : null;
    let jobDateColCls = classnames({
      "ml-auto mr-0": true,
      "col-4": startStr,
      "col-auto": !startStr,
    });
    return (
      <div className={jobDateColCls}>
        <div className="card border-0 mr-2">
          <div className="d-flex flex-row">
            <h6 className="mb-0">
              <span
                className="mdi mdi-calendar mr-1"
                style={{ fontSize: "16px" }}
              />
              <span className="mr-2">Schedule</span>
            </h6>
            {startStr && jobData.date_start_tentative === 1 && (
              <span className="badge bg-info ml-auto h-auto p-1 d-flex align-items-center">
                Tentative
              </span>
            )}
          </div>
          <hr className="my-1" />
          {startStr ? (
            <div className="d-flex flex-row flex-wrap ml-2">
              <div className="d-flex flex-column">
                <small className="text-left h-auto font-weight-light">
                  Start
                </small>
                <span className="font-weight-bold">{startStr}</span>
              </div>
              {endStr && (
                <div className="d-flex align-items-end mx-2">
                  <span className="font-weight-bold">-</span>
                </div>
              )}
              {endStr && (
                <div className="d-flex flex-column">
                  <small className="text-left h-auto font-weight-light">
                    End
                  </small>
                  <span className="font-weight-bold">{endStr}</span>
                </div>
              )}
            </div>
          ) : (
            <span className="badge bg-info ml-auto h-auto p-1 d-flex align-items-center">
              TBD
            </span>
          )}
        </div>
      </div>
    );
  };
  const renderJobLocation = () => {
    const { jobData } = state;
    const { addr, published } = jobData;
    let noAddr = published === 1 && addr.length === 0;
    let locationDescription = addr
      ? addr
      : "For further information, contact the publisher or point-of-contact.";
    return (
      <div className="col-5 ml-0 mr-auto">
        <div className="card border-0 p-1 w-auto">
          <h6 className="mb-0">
            <span
              className="mdi mdi-map-marker-outline mr-1"
              style={{ fontSize: "16px" }}
            />
            <span className="mr-2">Location</span>
          </h6>
          <hr className="my-1" />
          <div className={`text-break ml-2 ${noAddr ? "text-info" : ""}`}>
            {noAddr && (
              <span
                className="mdi mdi-alert-circle-outline mr-2"
                style={{ fontSize: "16px" }}
              ></span>
            )}
            {locationDescription}
          </div>
        </div>
      </div>
    );
  };
  const renderMainCard = () => {
    const { jobData } = state;
    const {
      body = "",
      title,
      tags,
      notes,
      created_by_name,
      published,
    } = jobData;
    let tagsSplit = tags && tags.length > 0 ? tags.split("~") : [];
    const jobIsDrafted = published === 0;
    return (
      <div
        className="item-page-main-card card h-auto"
        style={{ marginTop: "5px" }}
      >
        <div className="flex-grow-1 flex-fill px-3 pt-3 pb-1">
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex">
              <h3 className="d-inline mx-3 w-75">{title}</h3>
              {!jobIsDrafted && (
                <div
                  className="card d-inline p-1 ml-auto mb-auto "
                  style={{ width: "18rem", maxWidth: "fit-content" }}
                >
                  <h6 className="mb-0">
                    <span className="mdi mdi-account mr-1" />
                    <span className="mr-2">Published by</span>
                    <span className="ml-auto text-primary">
                      {created_by_name}
                    </span>
                  </h6>
                </div>
              )}
            </div>
          </div>
          <div className="card-body pb-0">
            <div className="row pt-4 pb-2">
              {renderJobLocation()}
              {renderJobDates()}
            </div>
            <div className="d-flex flex-row mt-auto">
              {tagsSplit.length > 0 && (
                <span className="mr-3 font-weight-light">Tags:</span>
              )}
              {tagsSplit.map((tag, idx) => {
                return (
                  <div key={idx} className="badge badge-light p-1 mx-1 my-1">
                    {tag}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <hr className="w-75 mx-auto" />
        <div className="flex-fill px-2 py-3 mx-3">
          <h5 className="ml-4 card-title text-break">Job Description</h5>
          {jobData && (
            <p
              className="mx-4"
              dangerouslySetInnerHTML={{
                __html: purify.sanitize(marked(body ? body : "")),
              }}
            />
          )}
          {jobData &&
            jobData.requirements &&
            jobData.requirements.length > 0 && (
              <div className="mx-4 alert alert-secondary font-weight-bold">
                <span className="mdi mdi-asterisk text-danger mr-2" />
                <span className="text-danger">Requirements</span>
                <hr />
                <p className="mb-0 ml-3" style={{ whiteSpace: "pre-wrap" }}>
                  {jobData.requirements}
                </p>
              </div>
            )}
          {notes && notes.length > 0 && (
            <div className="mx-4 alert alert-info font-weight-bold ">
              <span
                className="mdi mdi-alert-circle-outline mr-2"
                style={{ fontSize: "larger" }}
              />
              <span>Notes</span>
              <hr />
              <p className="mb-0 ml-3">{notes}</p>
            </div>
          )}
          <div className="mx-4 alert alert-secondary font-weight-bold item-attachments-section">
            <span className="mdi mdi-attachment mr-2" />
            <span>Attachments</span>
            <hr />
            <JobItemDocsCard />
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    doJobsInitUrlParams();
    setHyperlinkTargetsOnPage("item-page-main-card");
    if (jobsCurrentPageItem && jobsCurrentPageItem.published === 0)
      editBtnClicked(true);
  }, []);

  useEffect(() => {
    if (jobsCurrentPageItem) {
      setState({
        ...state,
        jobData: {
          ...state.jobData,
          ...jobsCurrentPageItem,
          comments: jobsCommentsItems,
          reducedComments:
            jobsCommentsItems.length > 5
              ? jobsCommentsItems.slice(jobsCommentsItems.length - 5)
              : jobsCommentsItems,
          attachments: jobsDocsItems,
        },
        commentCols: { ...state.commentCols, job_id: jobsCurrentPageItem.id },
      });
      if (jobsCurrentPageItem.published === 0) editBtnClicked(true);
    }
  }, [jobsCurrentPageItem]);

  useEffect(() => {
    if (jobsCommentsItems.length > 0) {
      setState({
        ...state,
        jobData: {
          ...state.jobData,
          reducedComments:
            jobsCommentsItems.length > 5
              ? jobsCommentsItems.slice(jobsCommentsItems.length - 5)
              : jobsCommentsItems,
          comments: jobsCommentsItems,
        },
      });
      setHyperlinkTargetsOnPage("comments-section-card");
    }
  }, [JSON.stringify(jobsCommentsItems)]);

  useEffect(() => {
    if (jobsViewsItems.length > 0) {
      setState({
        ...state,
        jobData: { ...state.jobData },
        activeUserView: find(jobsViewsItems ? jobsViewsItems : [], {
          created_by: tokenPayload.sub,
        }),
      });
    }
  }, [JSON.stringify(jobsViewsItems)]);

  useEffect(() => {
    if (jobsCurrentUserView) {
      setState({
        ...state,
        activeUserView: jobsCurrentUserView,
      });
    }
  }, [jobsCurrentUserView]);

  useEffect(() => {
    if (jobsDocsItems) {
      setState({
        ...state,
        jobData: { ...state.jobData, attachments: jobsDocsItems },
      });
    }
  }, [JSON.stringify(jobsDocsItems)]);

  const { jobData } = state;
  const {
    jobsCommentsIsLoading,
    doJobsCommentsSave,
    doJobsCommentsDelete,
    jobsFlags,
  } = props;
  const commentProps = {
    commentCols: state.commentCols,
    parentItemData: jobData,
    sendFunc: doJobsCommentsSave,
    deleteFunc: doJobsCommentsDelete,
  };
  return (
    <ItemPage
      itemType="Job"
      style={{ marginTop: "55px" }}
      tokenPayload={tokenPayload}
      itemFlags={jobsFlags}
      viewsItems={jobsViewsItems}
    >
      <ItemPageNav>
        <button
          className="btn btn-ghost-primary"
          type="button"
          onClick={toBoardBtnClicked}
        >
          <span className="mdi mdi-arrow-up-left mr-2" />
          <span className="d-xl-inline d-lg-inline d-none"> Return to </span>
          Job Board
        </button>
        <div className="d-lg-block mx-auto d-none">
          {renderExpirationCard()}
        </div>
        {jobData && jobData.created_by === tokenPayload.sub ? (
          <button
            className="btn btn-ghost-primary d-block ml-auto mr-0"
            type="button"
            onClick={editBtnClicked}
          >
            {state.editingMode ? (
              <span className="mdi mdi-arrow-u-left-top mr-2" />
            ) : (
              <span className="mdi mdi-pencil-outline mr-2" />
            )}
            {state.editingMode ? "Return to Page" : "Edit"}
          </button>
        ) : (
          renderFavoriteBtn()
        )}
      </ItemPageNav>
      <ItemPageBody>
        {state.editingMode ? (
          <JobItemEditor state={state} setState={setState} />
        ) : (
          <div className="d-flex flex-column col-lg-6 col offset-lg-3">
            <div
              className="d-flex ml-auto flex-row font-weight-bold"
              style={{ fontSize: "12px" }}
            >
              <span className="mdi mdi-autorenew" />
              <span className="mr-2">Last updated by</span>
              <span className="text-primary mr-2">
                {jobData.last_updated_by_name}
              </span>
              <span className="mr-1 font-weight-lighter">
                , {new Date(jobData.last_updated_date).toLocaleString()}
              </span>
            </div>
            {jobData && renderMainCard()}
            <div className="d-xs-block d-md-block d-sm-block d-lg-none d-xl-none">
              {jobData && <JobItemPocCard state={state} />}
            </div>
            <CommentsSection
              {...commentProps}
              renderName={renderName}
              boardUrl={{
                name: "job-board",
                params: [],
                pattern: "/job-board",
              }}
              commentsItems={jobsCommentsItems}
              commentsIsLoading={jobsCommentsIsLoading}
              commentsBoxReduced={state.commentsBoxReduced}
              commentsFlags={jobsCommentsFlags}
            >
              {!jobsCommentsIsLoading && !jobsCommentsFlags._shouldFetch && (
                <ItemPageComment
                  {...commentProps}
                  isForm={true}
                  body={state.commentText}
                />
              )}
            </CommentsSection>
          </div>
        )}
        {!state.editingMode && (
          <div className="d-none d-lg-flex flex-column col-3">
            {jobData && <JobItemPocCard state={state} />}
          </div>
        )}
      </ItemPageBody>
    </ItemPage>
  );
};
export default connect(
  "doUpdateRelativeUrl",
  "doJobsCommentsDelete",
  "doJobsCommentsSave",
  "selectJobsNewFormState",
  "doJobsViewsSave",
  "doJobsInitUrlParams",
  "selectJobsFlags",
  "selectJobsCurrentPageItem",
  "selectTokenPayload",
  "doJobsNewFormStateUpdate",
  "selectJobsCommentsItems",
  "selectJobsCommentsIsLoading",
  "selectJobsCommentsFlags",
  "selectJobsDocsItems",
  "selectJobsViewsItems",
  "selectJobsViewsFlags",
  "selectJobsViewsIsSaving",
  "selectJobsViewsIsLoading",
  "selectJobsCurrentUserView",
  JobItemPage
);
