import React from "react";
import { connect } from "redux-bundler-react";
import SearchInput from "../_shared/search-input/search-input";
import Table from "../../app-components/table/table";
import { sortBy, keyBy } from "lodash";
import classnames from "classnames";
import MemberButton from "./import-from-member-button";
import RoleFilter from "../../app-containers/context-providers/role-filter";
import Loader from "../../app-components/loader";
import HiddenPersonnel from "./hidden-personnel";

class Personnel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    const { doPersonnelFetch, personnelIsLoading } = this.props;
    if (!personnelIsLoading) {
      doPersonnelFetch();
    }
  }

  handleSearch(e) {
    this.setState({
      searchTerm: e.target.value,
    });
  }

  handleSelect(slug) {
    const { doUpdateRelativeUrl, orgsByRoute } = this.props;
    doUpdateRelativeUrl(`/${orgsByRoute.slug}/personnel/${slug}`);
  }

  render() {
    const {
      personnelItems: items,
      doPersonnelUpgrade,
      doPersonnelSave,
      personnelIsSaving,
      personnelIsLoading,
      domainsItemsByGroup,
    } = this.props;
    const duties = keyBy(domainsItemsByGroup["duties"], "id");
    const approvalRoleStyles = {
      "Aircrew Training Program Manager": {
        abbr: "ATPM",
        badgeColorCls: "badge-teal",
      },
      "Backup ATPM": {
        abbr: "BATPM",
        badgeColorCls: "badge-orange",
      },
      "Mission Briefing Officer": {
        abbr: "MBO",
        badgeColorCls: "badge-indigo",
      },
      Commander: {
        abbr: "CDR",
        badgeColorCls: "badge-purple",
      },
      Directory: {
        abbr: "DIR",
        badgeColorCls: "badge-info",
      },
    };
    const dutiesStyles = {
      RP: "badge-indigo",
      RPI: "badge-teal",
      SRP: "badge-cyan",
    };

    const config = {
      "": {
        dataKey: "parent_id",
        dataType: "string",
        renderFn: (config, item) => {
          const hasParent = !!item[config.dataKey];
          const iconClass = classnames({
            "ml-2": true,
            mdi: true,
            "mdi-account-switch": hasParent,
            "mdi-account-edit": !hasParent,
            "text-success": hasParent,
            "text-info": !hasParent,
          });

          const hiddenIcon = classnames({
            "ml-2": true,
            mdi: true,
            "mdi-eye": item.hidden,
            "mdi-eye-off": !item.hidden,
            "text-muted": true,
          });
          return (
            <>
              <span
                title={
                  hasParent ? "Linked to User Profile" : "Manually Added Record"
                }
              >
                <i className={iconClass}></i>
              </span>
              <RoleFilter allowRoles={[`HQ.ADMIN`, `:ORG.ADMIN`]}>
                <button
                  title="Hide Personnel"
                  className="bg-transparent border-0 outline-0"
                  onClick={() =>
                    doPersonnelSave({ ...item, hidden: !item.hidden })
                  }
                >
                  <i className={hiddenIcon}></i>
                </button>
              </RoleFilter>
            </>
          );
        },
      },
      Name: {
        dataKey: "name",
        dataType: "string",
      },
      "E-Mail": {
        dataKey: "email",
        dataType: "string",
      },
      Phone: {
        dataKey: "phone",
        dataType: "string",
      },
      "Approval Roles": {
        dataKey: "approval_roles",
        dataType: "string",
        renderFn: (config, item) => {
          if (item.approval_roles) {
            return (
              <>
                {item.approval_roles.map((role, key) => {
                  let style = approvalRoleStyles[role];
                  if (!style) return <span />;
                  return (
                    <span
                      key={key}
                      className={`badge badge-pill ${style.badgeColorCls} ml-2`}
                    >
                      {style.abbr}
                    </span>
                  );
                })}
              </>
            );
          }
        },
      },
      "Flight Roles": {
        dataKey: "flight_roles",
        dataType: "string",
        renderFn: (config, item) => {
          let flightRoleObjs = [];
          if (item.flight_roles) {
            let idsSplit = item.flight_roles.split(".");
            idsSplit.forEach((id) => {
              if (duties[id]) flightRoleObjs.push(duties[id]);
            });
            flightRoleObjs = sortBy(flightRoleObjs, (obj) =>
              parseInt(obj.display_order)
            );
          }
          return (
            <>
              {flightRoleObjs.map((obj, key) => {
                let badgeColorCls = dutiesStyles[obj.val];
                return (
                  <span
                    key={key}
                    className={`badge badge-pill ${badgeColorCls} ml-2`}
                  >
                    {obj.val}
                  </span>
                );
              })}
            </>
          );
        },
      },
      "HQ Member": {
        dataKey: "has_hq_membership",
        dataType: "boolean",
        renderFn: (config, item) => {
          return (
            <p
              className={`m-0 text-${
                item.has_hq_membership ? "success" : "danger"
              }`}
            >
              {item.has_hq_membership ? "YES" : "NO"}
            </p>
          );
        },
      },
    };
    if (personnelIsSaving || personnelIsLoading) {
      return (
        <div className="container-fluid mt-4">
          <Loader opt="dissolve-cube" />
        </div>
      );
    }

    const { searchTerm } = this.state;
    const matcher = new RegExp(searchTerm, "ig");
    const filtered = items.filter((item) => {
      const testString = Object.values(item).join(" ");
      return testString.match(matcher);
    });

    const sorted = sortBy(filtered, ["name"]).filter((item) => !item.hidden);
    return (
      <div className="container-fluid mt-4">
        <SearchInput
          onChange={this.handleSearch}
          value={this.state.searchTerm}
        />
        <div className="card">
          <div className="card-header">
            <i className="mdi mdi-account-multiple-outline"></i> Personnel
          </div>
          <div className="card-body">
            <div className="d-flex">
              <div className="float-right ml-auto">
                <RoleFilter allowRoles={["HQ.ADMIN", ":ORG.ADMIN"]}>
                  <span className="mr-2">
                    <MemberButton onSelect={doPersonnelUpgrade} />
                  </span>
                </RoleFilter>
              </div>
            </div>
            <Table
              config={config}
              items={sorted}
              onRowSelect={this.handleSelect}
            />
            <div className="float-right">
              {/* <RoleFilter allowRoles={[":ORG.ADMIN"]}>
                <a
                  className="btn btn-outline-primary"
                  href={`/${orgsByRoute.slug}/personnel/new`}
                >
                  Add New
                </a>
              </RoleFilter> */}
            </div>
            <span className="mr-3 text-muted">
              <small>
                All org members will automatically get a personnel record, add
                additional personnel if they won't be using this application
              </small>
            </span>
          </div>
        </div>
        <HiddenPersonnel />
      </div>
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "doPersonnelSave",
  "doPersonnelUpgrade",
  "doPersonnelFetch",
  "selectPersonnelItems",
  "selectOrgsByRoute",
  "selectPersonnelIsLoading",
  "selectApprovalRolesByLoggedIn",
  "selectApprovalRolesItems",
  "selectDomainsItemsByGroup",
  Personnel
);
