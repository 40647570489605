import React from "react";
import { connect } from "redux-bundler-react";
import Select from "./select-input";

class SelectInputDomain extends React.Component {
  constructor(props) {
    super(props);
    this.isValid = this.isValid.bind(this);
  }
  isValid() {
    return this.select.isValid();
  }
  render() {
    const { schema, domainsItems: domains } = this.props;
    const domainGroup = schema.domain;
    const options = domains
      .filter((d) => {
        return d.grp === domainGroup;
      })
      .map((d) => {
        return {
          value: d.id,
          label: d.tooltip ? `${d.val} (${d.tooltip})` : d.val,
        };
      });

    options.sort((a, b) => {
      let s = 0;
      if (a.label === b.label) return s;
      s = a.label > b.label ? 1 : -1;
      return s;
    });

    return (
      <Select
        ref={(el) => {
          this.select = el;
        }}
        {...this.props}
        options={options}
      />
    );
  }
}

export default connect("selectDomainsItems", SelectInputDomain);
