import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "personnelTraining",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/personnel/:personnelSlug/training",
  putTemplate: "/orgs/:orgSlug/personnel/:personnelSlug/training/:item.id",
  postTemplate: "/orgs/:orgSlug/personnel/:personnelSlug/training",
  deleteTemplate: "/orgs/:orgSlug/personnel/:personnelSlug/training/:item.id",
  fetchActions: [
    "URL_UPDATED",
    "AUTH_LOGGED_IN",
    "PERSONNELTRAINING_SAVE_FINISHED"
  ]
});
