import { createSelector } from "redux-bundler";
import createRestBundle from "./create-rest-bundle";
import { pick, sortBy } from "lodash";
export default createRestBundle({
  name: "platforms",
  uid: "slug",
  initialData: {
    _isSaving: false,
    _dataByOrgProfile: [],
  },
  prefetch: true,
  staleAfter: 30000,
  persist: false,
  routeParam: "platformsSlug",
  getTemplate: "/platforms",
  putTemplate: "/platforms/:item.id",
  postTemplate: "/platforms",
  deleteTemplate: "/platforms/:item.id",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN"],
  forceFetchActions: [
    "PLATFORMS_SAVE_FINISHED",
    "FILE_IO_UPLOAD_FINISHED",
    "FILE_IO_DELETE_FINISHED",
  ],

  reduceFurther: (state, { type, payload }) => {
    switch (type) {
      case "PLATFORMS_FETCH_BY_ORGPROFILE_START":
      case "PLATFORMS_FETCH_BY_ORGPROFILE_ERROR":
      case "PLATFORMS_FETCH_BY_ORGPROFILE_FINISH":
        return Object.assign({}, state, payload);
      default:
        return state;
    }
  },
  addons: {
    doPlatformsDownloadAsCsv: () => ({ store }) => {
      let headerLabels = ['Make', 'Model', 'AWR Expiration Date', 'AWR Number', 'USACE Approved', 'Benign Approved', 'Group Type', 'Active Count'];
      let items = store.selectPlatformsItems();
      items = sortBy(items, ["usace_approved"]).reverse();
      let csvData = headerLabels.join(',') + "\n"
      items.forEach(row => {
        if (row.id) {
          let make = row.make;
          let model = row.model;
          let awr_date = row.awr_expire_date ? new Date(row.awr_expire_date).toLocaleDateString() : '';
          let awr_number = row.awr_number;
          let usace_approved = !!row.usace_approved;
          let benign_approved = !!row.benign_approved;
          let group_type = row.group_type;
          let active_count = row.active_count;
          csvData += `${make},${model},${awr_date},${awr_number},${usace_approved},${benign_approved},${group_type},${active_count}\n`
        }
      });
      let csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) navigator.msSaveBlob(csvBlob, "export.csv");
      else {
        let link = document.createElement("a");
        if (link.download !== undefined) {
          let url = URL.createObjectURL(csvBlob);
          link.setAttribute("href", url);
          link.setAttribute("download", "export.csv");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    // Need this function because we are saving a platform from the /platforms page, where we can't get the platform ID from the URL slug
    doPlatformsPut:
      (item, callback, deferCallback) =>
        ({ dispatch, store, apiPut }) => {
          let platform = item;
          delete platform.s3_key;
          delete platform.filename;
          dispatch({
            type: "PLATFORMS_SAVE_STARTED",
            payload: { _isSaving: true },
          });
          apiPut(`/platforms/${item.id}`, item, (err, response, body) => {
            if (err || response.statusCode !== 200) {
              dispatch({
                type: "PLATFORMS_SAVE_ERROR",
                payload: {
                  _err: { err: err, response: response },
                  notification: {
                    statusCode: response.statusCode,
                  },
                  _isSaving: false,
                },
              });
            } else {
              // Make sure we're sending save_finished when we're done
              dispatch({
                type: "PLATFORMS_SAVE_FINISHED",
                payload: {
                  _isSaving: false,
                },
              });
              const data =
                typeof body === "string" ? JSON.parse(body)[0] : body[0];
              const updatedItem = Object.assign({}, item, data);

              if (deferCallback && callback) callback(updatedItem);
            }
            // if we get a callback, go ahead and fire it
            if (!deferCallback && callback) callback();
          });
        },

    doPlatformsFetchByOrgAndProfile:
      () =>
        ({ dispatch, store, apiGet }) => {
          let url = store.selectApiRoot();
          let profileId = store.selectProfileId();
          dispatch({
            type: "PLATFORMS_FETCH_BY_ORGPROFILE_START",
            payload: { byOrgIsFetching: true },
          });
          apiGet(`${url}/orgs/${profileId}/platforms`, (err, res, body) => {
            if (err) {
              dispatch({
                type: "PLATFORMS_FETCH_BY_ORGPROFILE_ERROR",
                payload: { byOrgIsFetching: false, err },
              });
            } else {
              dispatch({
                type: "PLATFORMS_FETCH_BY_ORGPROFILE_FINISH",
                payload: {
                  _dataByOrgProfile: JSON.parse(body),
                  byOrgIsFetching: false,
                },
              });
            }
          });
        },

    selectPlatformsByOrgProfile: (state) => state.platforms._dataByOrgProfile,

    selectPlatformsIdByOrgProfile: createSelector(
      "selectPlatformsByOrgProfile",
      (platforms) => {
        return platforms && platforms.length > 0
          ? platforms.map((item) => item.platform_id)
          : [];
      }
    ),

    selectPlatformAwrDocs: createSelector(
      "selectPlatformsByRoute",
      (platformsByRoute) => {
        return platformsByRoute && platformsByRoute.s3_key
          ? [
            {
              s3_key: platformsByRoute.s3_key,
              filename: platformsByRoute.filename,
            },
          ]
          : [];
      }
    ),
    selectPlatformConfigurationDocs: createSelector(
      "selectPlatformsByRoute",
      (platformsByRoute) => {
        return platformsByRoute && platformsByRoute.configuration_s3_key
          ? [
            {
              s3_key: platformsByRoute.configuration_s3_key,
              filename: platformsByRoute.configuration_filename,
            },
          ]
          : [];
      }
    ),

    selectPlatformsAll: createSelector(
      "selectPlatformsItems",
      "selectPlatformsGcsItems",
      "selectPlatformsPayloadsItems",
      (platformsItems, platformsGcsItems, platformsPayloadsItems) => {
        return platformsItems.concat(platformsGcsItems, platformsPayloadsItems);
      }
    ),
  },
});
