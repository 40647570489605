import React from "react";
import {
  addDays,
  addWeeks,
  addMonths,
  addYears,
  formatDistanceStrict
} from "date-fns";
import classnames from "classnames";
import RoleFilter from "../../../app-containers/context-providers/role-filter";
import ReadOnly from "../../_shared/read-only-notification";

const today = new Date();

const dateFns = {
  Years: addYears,
  Months: addMonths,
  Weeks: addWeeks,
  Days: addDays
};

class TrainingListItem extends React.Component {
  render() {
    const { item, enableEditing } = this.props;
    let { title, completed_date, expires_in, expires_in_uom } = item;
    let expired = false;
    let expiration_date = null;
    let expires_notice = "";
    if (completed_date) {
      completed_date = new Date(completed_date);
    }
    if (expires_in && expires_in_uom && completed_date) {
      expiration_date = dateFns[expires_in_uom](completed_date, expires_in);
      expires_notice = `Expires in ${formatDistanceStrict(
        today,
        expiration_date
      )}`;
      if (expiration_date < today) {
        expires_notice = `Expired ${formatDistanceStrict(
          today,
          expiration_date
        )} ago`;
        expired = true;
      }
    }
    const iconClass = classnames({
      mdi: true,
      "mdi-checkbox-marked-circle": !expired,
      "text-success": !expired,
      "mdi-checkbox-blank-circle": expired,
      "text-danger": expired,
      "icon-inline": true
    });
    return (
      <li className="list-group-item">
        <div className="float-right">
          <RoleFilter allowRoles={[":ORG.ADMIN"]} alt={ReadOnly}>
            <div className="pointer">
              <i
                className="mdi mdi-pencil text-info"
                onClick={() => {
                  enableEditing(item);
                }}
              ></i>
            </div>
          </RoleFilter>
        </div>
        <div
          className="overflow-ellipsis"
          title={title}
        >{`${completed_date.toLocaleDateString()} - ${title}`}</div>
        <div className="overflow-ellipsis" title={expires_notice}>
          <small className={expired ? "text-danger" : ""}>
            <i className={iconClass}></i>
            {expires_notice}
          </small>
        </div>
      </li>
    );
  }
}

export default TrainingListItem;
