import React from "react";
import { connect } from "redux-bundler-react";
import FaqsEditor from "./faqs-editor";

class Faqs extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { routeInfo, tokenRolesJoined } = this.props;
        return (
            <FaqsEditor tokenRolesJoined={tokenRolesJoined} routeInfo={routeInfo} />
        )
    }
}

export default connect(
    "selectTokenRolesJoined",
    Faqs
);