import React from "react";
import { connect } from "redux-bundler-react";

export default connect(
  "selectDashboardCountsItemsObject",
  ({
    dashboardCountsItemsObject: counts,
    title,
    icon,
    countClass,
    countGroup,
    bgcolor = "info",
    txtcolor = "white",
  }) => {
    if (!counts) return null;
    if (!counts[countGroup]) return null;
    if (!counts[countGroup][countClass]) return null;

    let currentGroupData = counts[countGroup][countClass];
    let data = {};

    if (countGroup === "total" && countClass !== "approval_role_distinct") {
      // remove hq data from dashboard counts
      let hqData = counts["hq"][countClass];

      Object.keys(currentGroupData).forEach((key) => {
        let hqDataValue = hqData[key] ? hqData[key] : 0;
        data[key] = currentGroupData[key] - hqDataValue;
      });
    } else {
      data = currentGroupData;
    }
    delete data.null;
    const total = Object.keys(data).reduce((prev, curr) => {
      return prev + data[curr];
    }, 0);

    return (
      <div className={`card text-${txtcolor} bg-${bgcolor}`}>
        <div className="card-body">
          <div className="h3 mb-4">
            <span className="float-right">
              <i className={icon}></i>
            </span>
            {title}
          </div>

          <div className="text-value hidden">{`${total} ${countGroup.toUpperCase()}`}</div>

          {Object.keys(data).map((key, i) => {
            const pct = (data[key] / total) * 100;
            return (
              <div key={i} className="mb-2">
                <small className="text-uppercase font-weight-bold">
                  {`${key} (${data[key]})`}
                </small>
                <div
                  className={`progress progress-${txtcolor} progress-xs mt-3`}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${pct}%` }}
                    aria-valuenow={pct}
                    aria-valuemin="0"
                    aria-valuemax={total}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);
