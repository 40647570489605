import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { findIndex } from 'lodash'

const DropdownItem = ({ opt, className, children, onClick, style = {} }) => {
    let dropdownItemStyle = { ...style, cursor: "pointer", userSelect: "none" };
    if (opt.type === "header") {
        return (
            <div className="dropdown-header" style={{ fontFamily: "Segoe UI" }}>
                <span className="mr-2">
                    <i className="mr-2" />{opt.text}
                </span>
            </div>
        )
    }
    if (opt.type === "divider") return <div className="dropdown-divider"></div>

    return (
        <div className={className} name={opt.name ? opt.name : ""} style={dropdownItemStyle} onClick={onClick}>
            {children}
        </div>
    )
}

const Dropdown = ({ feedbackForm, form, btnGroupCls, onToggleShow, btnCls = "btn-sm", selectedVal, title, stateValueAsTitle, options, displayValues = false,
    disabled, showCount = false, show = false, width = "11rem", maxHeight = "300px", direction = "dropdown-menu-right", style }) => {
    const [state, setState] = useState({
        show: show,
        selectedIdx: findIndex(options, (o) => o.displayValue === selectedVal),
        selectedVal: selectedVal
    })

    useEffect(() => {
        setState({ ...state, selectedIdx: findIndex(options, (o) => o.displayValue === selectedVal) })
    }, [selectedVal])


    const toggleShow = (e) => {
        e.stopPropagation()
        e.persist();
        if (onToggleShow) onToggleShow(!state.show)
        setState({ ...state, show: !state.show });

    }
    const renderDropdownItemInnerContent = (opt) => {
        return (
            <span className="mr-2">
                {opt.icon ? <i className={`${opt.icon} mr-2`} /> : null}{opt.text}{opt.component ? opt.component() : null}
            </span>
        )
    }

    const renderOptions = () => {
        return options.map((opt, idx) => {
            let dropdownItemCls = classnames({
                "dropdown-item dropdown-item-styled": !displayValues,
                [opt.itemCls]: opt.itemCls && !displayValues
            })
            return (
                <DropdownItem key={idx} className={dropdownItemCls} opt={opt} onClick={(e) => {
                    e.stopPropagation();
                    e.persist();
                    setState({ ...state, selectedIdx: idx })
                    if (opt.handler) opt.handler(opt.update)
                    if (opt.onClick) opt.onClick(e)
                    if (opt.exitOnClick) toggleShow(e)
                }}>
                    {opt && renderDropdownItemInnerContent(opt)}
                    {displayValues ? <div className="ml-2">{opt.displayValue}</div> : ''}
                </DropdownItem >
            )
        })
    }

    const renderTitle = () => {
        if (title.type === "icon") return <span className={title.value} style={{ ...title.style, pointerEvents: "none" }} />
        else {
            if (stateValueAsTitle && options[state.selectedIdx]) return renderDropdownItemInnerContent(options[state.selectedIdx])
            else return title;
        }
    }

    const btnGroupClass = classnames({ 'board-dropdown': true, [btnGroupCls]: btnGroupCls !== null, "btn-group": true, show: state.show });
    const btnClass = classnames({ [btnCls]: true, "btn": true, "dropdown-toggle": true })
    const dropdownMenuClass = classnames({ "board-dropdown-menu dropdown-menu position-absolute": true, show: state.show, [direction]: true, "p-1": displayValues, "overflow-auto": true });
    let dropdownStyle = { willChange: "transform", width: width, maxHeight: maxHeight }
    const toggleBtnStyle = { display: state.show ? "block" : "none", position: "fixed", zIndex: 999, top: 0, bottom: 0, left: 0, right: 0 }
    return (
        <div className={btnGroupClass} style={style} >
            <button className={btnClass} type="button" data-toggle="dropdown" onClick={toggleShow} disabled={disabled}  >
                {renderTitle()}
                {showCount ? <span className="badge badge-light ml-2">{options.length}</span> : null}
            </button>
            <div onClick={toggleShow} style={toggleBtnStyle} />
            <div className={dropdownMenuClass} x-placement="bottom-start" style={dropdownStyle}>
                {options ? renderOptions() : null}
                {form ? form(toggleShow) : null}
                {feedbackForm ? feedbackForm(toggleShow) : null}
            </div>
        </div>
    );
}
export default Dropdown;