import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import TabContainer from "../missions/_shared/tab-container/tab-container";
import Platforms from "./aircraft";
import PlatformsGcs from "./gcs";
import PlatformsPayloads from "./payloads";
import PlatformsNew from "./platforms-new";
import { platformsGcs, platforms, platformsPayloads } from "../../models";

const AllPlatforms = ({
  doDialogOpen,
  activeTab,
  platformGcsIsLoading,
  platformPayloadsIsLoading,
  platformIsLoading,
  doPlatformsSave,
  doPlatformsGcsSave,
  doPlatformsPayloadsSave,
  domainsItems,
  doPlatformsDownloadAsCsv,
  ...props
}) => {
  const [selectedPayloadType, setSelectedPayloadType] = useState("All");
  const togglePayloadType = (e) => {
    setSelectedPayloadType(e.currentTarget.name);
  };
  const AddNewButton = () => {
    return (
      <div className="d-flex justify-content-end mb-2">
        <button
          className="btn btn-outline-primary"
          onClick={() => {
            doDialogOpen({
              content: PlatformsNew,
              props: {
                ...props,
                doSave:
                  activeTab === 0
                    ? doPlatformsSave
                    : activeTab === 1
                    ? doPlatformsGcsSave
                    : doPlatformsPayloadsSave,
                isSaving:
                  activeTab === 0
                    ? platformIsLoading
                    : activeTab === 1
                    ? platformGcsIsLoading
                    : platformPayloadsIsLoading,
                schema:
                  activeTab === 0
                    ? platforms
                    : activeTab === 1
                    ? platformsGcs
                    : platformsPayloads,
              },
            });
          }}
        >
          Add New
        </button>
      </div>
    );
  };
  const tabConfig = {
    Aircraft: (props) => {
      return (
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-end float-right w-100">
            <AddNewButton />
            <button
              className="btn btn-primary mb-2 ml-2"
              onClick={doPlatformsDownloadAsCsv}
            >
              <span className="mdi mdi-download icon-inline" /> CSV
            </button>
          </div>
          <Platforms {...props} />
        </div>
      );
    },
    GCS: (props) => {
      return (
        <div className="d-flex flex-column">
          <AddNewButton />
          <PlatformsGcs {...props} />
        </div>
      );
    },
    Payloads: (props) => {
      return (
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <div className="d-flex my-auto">
              <button
                name="All"
                className={`btn btn-${
                  selectedPayloadType !== "All" ? "outline-" : ""
                }primary mr-2`}
                onClick={togglePayloadType}
              >
                All
              </button>
              <button
                name="Cameras"
                className={`btn btn-${
                  selectedPayloadType !== "Cameras" ? "outline-" : ""
                }primary mr-2`}
                onClick={togglePayloadType}
              >
                Cameras
              </button>
              <button
                name="Gimbals"
                className={`btn btn-${
                  selectedPayloadType !== "Gimbals" ? "outline-" : ""
                }primary mr-2`}
                onClick={togglePayloadType}
              >
                Gimbals
              </button>
              <button
                name="Sensors"
                className={`btn btn-${
                  selectedPayloadType !== "Sensors" ? "outline-" : ""
                }primary mr-2`}
                onClick={togglePayloadType}
              >
                Sensors
              </button>
            </div>
            <AddNewButton />
          </div>

          <PlatformsPayloads {...props} payloadType={selectedPayloadType} />
        </div>
      );
    },
  };
  return (
    <div className="container-fluid mt-4">
      <TabContainer config={tabConfig} />
    </div>
  );
};

export default connect(
  "selectActiveTab",
  "doPlatformsGcsSave",
  "doPlatformsSave",
  "doPlatformsPayloadsSave",
  "selectPlatformsGcsIsLoading",
  "selectPlatformsPayloadsIsLoading",
  "selectPlatformsIsLoading",
  "doDialogOpen",
  "selectDomainsItems",
  "doPlatformsDownloadAsCsv",
  AllPlatforms
);
