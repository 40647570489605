import React from "react";
import { connect } from "redux-bundler-react";
import ApprovalItem from "./approval-item";
import { sortBy } from "lodash";

const statements = {
  plan: "I have verified all referenced documents and reviewed each hazard on the attached DD Form 2977 with the RPI. This mission can be conducted within the assessed risk.",
  "Mission Briefing Officer":
    "By signing, I certify that I have mission approval authority for the highest assessed risk and that the mission packet is complete and suitable for the planned mission.",
  "Mission Approval":
    "I have reviewed this mission plan.  This mission can be completed within the assessed risk.  This mission is approved to be completed within the planned parameters.",
};

class ApprovalRouting extends React.Component {
  render() {
    const {
      missionsApprovalItems,
      missionsApprovalNotesItemsByMissionApprovalId: notes,
      planSig,
      onSign,
      showCheckbox,
      doMissionsApprovalDelete,
      doMissionsApprovalNotesSave,
      doMissionsApprovalFetch,
      doMissionsFetch,
      hasCoaApproval,
      orgIsTraining,
    } = this.props;
    const sorted = sortBy(missionsApprovalItems, ["routing_order"]);
    return (
      <>
        {sorted.map((item, i) => {
          if (!item.id) return null;

          let active = true;
          if (i === 0) {
            if (!planSig) active = false;
          } else {
            if (!sorted[i - 1].sig) active = false;
          }

          let title =
            item.approval_role === "Aircrew Training Program Manager"
              ? "ATPM / Director / Commander"
              : item.approval_role;
          let coaApproved = orgIsTraining ? active : hasCoaApproval;
          return (
            <ApprovalItem
              key={i}
              title={title}
              statement={statements[item.approval_role]}
              sig={item.sig}
              onSign={onSign}
              item={item}
              active={
                item.approval_role === "Aircrew Training Program Manager"
                  ? coaApproved
                  : active
              }
              notes={notes[item.id]}
              showCheckbox={showCheckbox}
              doSave={doMissionsApprovalNotesSave}
              doDelete={doMissionsApprovalDelete}
              doFetch={() => {
                doMissionsFetch();
                doMissionsApprovalFetch();
              }}
            />
          );
        })}
      </>
    );
  }
}

export default connect(
  "doMissionsApprovalNotesSave",
  "doMissionsApprovalDelete",
  "selectMissionsApprovalItems",
  "selectMissionsApprovalNotesItemsByMissionApprovalId",
  "doMissionsApprovalFetch",
  "doMissionsFetch",
  "selectOrgIsTraining",
  ApprovalRouting
);
