import React, { useEffect, useState, useRef } from "react";
import classnames from "classnames";
import { connect } from "redux-bundler-react";
import SchemaForm from "../../app-components/schema-form/schema-form";
import { aircraftRequest, gcsRequest, payloadsRequest } from "../../models";
import { isEmpty, capitalize } from "lodash";
import Loader from "../../app-components/loader";
import FileUploadCard from "../_shared/file-upload-card/file-upload-card";
const EquipmentRequestDetails = ({
  doEquipmentRequestChangeStatus,
  doEquipmentRequestInitUrlParams,
  equipmentRequestByRoute,
  doUpdateRelativeUrl,
  routeInfo,
  equipmentRequestSelected,
  equipmentRequestAdminIsViewing: adminIsViewing,
  doEquipmentRequestSave,
  doEquipmentRequestUpdateSelected,
  equipmentRequestUserIsAdmin: userIsAdmin,
  equipmentRequestDocsIsLoading,
  doFileIoUpload,
  doFileIoDelete,
  equipmentRequestDeactivationTypes,
}) => {
  const {
    id,
    type,
    reviewed_on,
    reviewed_by_name,
    create_date,
    profile_name,
    action,
    status,
  } = { ...equipmentRequestSelected, ...equipmentRequestByRoute };
  const formRef = useRef(0);
  const [state, setState] = useState({
    editing: false,
    allowSaveComment: false,
  });
  const actionIsDeactivate =
    action === "Display Only" ||
    equipmentRequestDeactivationTypes.map((type) => type.val).includes(action);
  const requestIsNew = status === "New";
  const model =
    type === "aircraft"
      ? aircraftRequest
      : type === "gcs"
      ? gcsRequest
      : payloadsRequest;

  const onBackBtnClicked = () => {
    const { orgSlug } = routeInfo.params;
    doEquipmentRequestUpdateSelected(null, () => {
      if (adminIsViewing) doUpdateRelativeUrl(`/${orgSlug}/equipment-requests`);
      else {
        let directory = type === "payload" ? "payloads" : type;
        doUpdateRelativeUrl(`/${orgSlug}/${directory}`);
      }
    });
  };

  // *********** Handle page reload ***********
  useEffect(doEquipmentRequestInitUrlParams, []);

  useEffect(() => {
    if (!equipmentRequestSelected) {
      doEquipmentRequestUpdateSelected(equipmentRequestByRoute);
    }
  }, [equipmentRequestByRoute]);
  // ********************************************

  const onApprovalChanged = (e) => {
    const { orgSlug } = routeInfo.params;
    doEquipmentRequestChangeStatus(e.currentTarget.name, () => {
      doUpdateRelativeUrl(`/${orgSlug}/equipment-requests`);
    });
  };
  const toggleEditing = (e) =>
    setState({ ...state, editing: e.currentTarget.name === "edit" });
  const renderStatusMessage = () => {
    const iconCls = classnames({
      "mdi mr-2": true,
      "mdi-new-box text-primary": requestIsNew,
      "mdi-checkbox-marked-circle-outline text-success": status === "Approved",
      "mdi-close text-danger": status === "Rejected",
    });
    let message = "";
    if (requestIsNew) {
      message = `Submitted By ${profile_name}, ${new Date(
        create_date
      ).toLocaleString()}`;
    } else if (["Approved", "Rejected"].includes(status)) {
      message = `Approved By ${reviewed_by_name}, ${new Date(
        reviewed_on
      ).toLocaleString()}`;
    }
    return (
      <div className="col-4 text-wrap">
        <h6 className=" col-form-label text-center">
          <span className={iconCls} style={{ fontSize: "16px" }} />
          {message}
        </h6>
      </div>
    );
  };

  const renderActionBtns = () => {
    const urlOrgLevelCheckRegExp = new RegExp(
      "/:orgSlug/(payloads|aircraft|gcs)/equipment-requests"
    ); //check if url of details page comes from an org's equipment page
    const urlOrgLevelCheck =
      routeInfo.pattern.search(urlOrgLevelCheckRegExp) !== -1;
    const allowApproval = userIsAdmin && !urlOrgLevelCheck; // allow approving/rejecting if user has admin permissions and they accessed it from equipment-requests page
    let showApprovalBtns = allowApproval && !state.editing; // show approve/reject buttons if above is true and they have clicked 'Edit'
    return (
      <div className="row mb-2">
        <div className="col-4">
          <button
            className="btn btn-outline-primary ml-0 mr-auto"
            onClick={onBackBtnClicked}
          >
            <span className="mdi mdi-arrow-left mr-lg-2" />
            <span className="d-none d-lg-inline">Back</span>
          </button>
        </div>
        {renderStatusMessage()}
        <div className="col-4 ml-auto d-flex justify-content-end">
          <button
            name="edit"
            className="btn btn-outline-primary mb-auto"
            disabled={state.editing || !requestIsNew}
            onClick={toggleEditing}
          >
            <span className="mdi mdi-lock-reset mr-lg-2" />
            <span className="d-none d-lg-inline">Unlock</span>
          </button>
          {!requestIsNew || showApprovalBtns ? (
            <>
              <button
                name="reject"
                className={`btn btn${
                  status === "Rejected" ? "" : "-outline"
                }-danger ml-2 mb-auto`}
                disabled={!requestIsNew}
                onClick={onApprovalChanged}
              >
                <span className="mdi mdi-close mr-lg-2" />
                <span className="d-none d-lg-inline">Decline</span>
              </button>
              <button
                name="approve"
                className={`btn btn${
                  status === "Approved" ? "" : "-outline"
                }-success ml-2 mb-auto`}
                disabled={!requestIsNew}
                onClick={onApprovalChanged}
              >
                <span className="mdi mdi-check mr-lg-2" />
                <span className="d-none d-lg-inline">Approve</span>
              </button>
            </>
          ) : (
            state.editing && (
              <>
                <button
                  name="cancel"
                  className="btn btn-secondary ml-2 mb-auto"
                  disabled={!state.editing}
                  onClick={toggleEditing}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary ml-2 mb-auto"
                  onClick={onSave}
                >
                  Save
                </button>
              </>
            )
          )}
        </div>
      </div>
    );
  };

  const onSave = () => {
    const { serialize, isValid } = formRef.current;
    if (isValid) {
      const data = serialize();
      let dataObject = {
        ...equipmentRequestSelected,
        ...data,
        title: null,
      };
      delete dataObject.image;
      doEquipmentRequestSave(dataObject);
      setState({ ...state, editing: false });
    }
  };
  const renderReviewerSection = () => {
    let disabled =
      !requestIsNew || !userIsAdmin || (!state.editing && userIsAdmin);
    return (
      <div className="row">
        <div className="col">
          <label className="col-sm-3 text-right col-form-label align-top">
            Reviewer Comment
          </label>
          <textarea
            disabled={disabled}
            rows={5}
            cols={40}
            value={equipmentRequestSelected.comment || ""}
            onChange={(e) => {
              setState({ ...state, allowSaveComment: true });
              doEquipmentRequestUpdateSelected({
                ...equipmentRequestSelected,
                comment: e.target.value,
              });
            }}
          />
        </div>
      </div>
    );
  };
  const formIsDisplayOnly = !state.editing;
  if (isEmpty(equipmentRequestSelected)) return <Loader />;
  return (
    <div className="container-fluid mt-4">
      <div className="card mb-3">
        <div className="card-header">
          <div className="row">
            <div className="col-3">
              <span className="mdi mdi-list-status mr-2" />
              {adminIsViewing ? "Equipment" : capitalize(type)} Requests
            </div>

            {action && (
              <div className="col-6 mr-3">
                <h5 className="m-auto text-center">
                  {action === "Add" && (
                    <span className="mdi mdi-plus-circle text-success mr-2" />
                  )}
                  {actionIsDeactivate && action !== "Display Only" && (
                    <span className="mdi mdi-delete-outline text-danger mr-2" />
                  )}
                  <span>{`Request to ${
                    actionIsDeactivate ? "Deactivate: " : ""
                  } ${capitalize(action)} ${capitalize(type)}`}</span>
                </h5>
              </div>
            )}
          </div>
        </div>
        <div className="card-body d-flex flex-column">
          <div className="container-fluid">
            {renderActionBtns()}
            <div className="row">
              <div className="col">
                <SchemaForm
                  ref={(el) => (formRef.current = el)}
                  displayOnly={formIsDisplayOnly}
                  inline={true}
                  data={equipmentRequestSelected}
                  schema={model}
                />
              </div>
            </div>
            <hr />
            {renderReviewerSection()}
          </div>
        </div>
      </div>
      {actionIsDeactivate && (
        <FileUploadCard
          allowRoles={["*.*"]}
          isLoading={equipmentRequestDocsIsLoading}
          items={[
            {
              s3_key: equipmentRequestSelected.s3_key,
              filename: equipmentRequestSelected.filename,
            },
          ]}
          header={true}
          title={"Deactivation Documents"}
          searchable={false}
          onUpload={doFileIoUpload}
          onRemove={doFileIoDelete}
          rel="deactivation_docs"
          data={{
            deactivation_id: id,
          }}
          disabledEditing={!!equipmentRequestSelected.s3_key}
        />
      )}
    </div>
  );
};

export default connect(
  "selectEquipmentRequestSelected",
  "selectEquipmentRequestUserIsAdmin",
  "doUpdateRelativeUrl",
  "doEquipmentRequestChangeStatus",
  "doEquipmentRequestSave",
  "doEquipmentRequestUpdateSelected",
  "selectEquipmentRequestAdminIsViewing",
  "selectEquipmentRequestByRoute",
  "selectRouteInfo",
  "doEquipmentRequestInitUrlParams",
  "selectEquipmentRequestDocsIsLoading",
  "doFileIoUpload",
  "doFileIoDelete",
  "selectEquipmentRequestDeactivationTypes",
  EquipmentRequestDetails
);
