import React, { useReducer } from "react";
import { connect } from "redux-bundler-react";
import FlightPersonnelPicker from "./flight-personnel-picker";
import { find, sortBy } from "lodash";
import AddNewSelector from "../add-new-selector";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "UPDATE_INPUT":
      return { ...state, ...payload };
    default:
      return state;
  }
};

const FormGroup = (props) => {
  const {
    personnel,
    domainsItems: domains,
    missionsPersonnelItems,
    missionsPersonnelApproved,
    missionsPersonnelAdjudicated,
    disabled,
    personnelItems,
    missionsPersonnelIsLoading,
    doMissionsPersonnelFetch,
    doMissionsPersonnelSave,
  } = props;
  let initialState = {};
  personnel.forEach((p) => {
    initialState[p.id] = p.flight_role_id;
  });
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChange = (e) => {
    const { onChange } = props;
    const id = e.target.name;
    const flight_role_id = e.target.value;
    let tempState = { ...state, [id]: flight_role_id };
    dispatch({
      type: "UPDATE_INPUT",
      payload: { [id]: flight_role_id },
    });
    onChange(
      Object.keys(tempState).map((key) => {
        return {
          id: key,
          flight_role_id: tempState[key],
        };
      })
    );
  };

  const pic = find(domains, { val: "Remote Pilot" });
  const vo = find(domains, { val: "Visual Observer" });
  const na = find(domains, { val: "N/A" });

  if (missionsPersonnelIsLoading) {
    return (
      <div className="form-group row d-flex justify-content-center w-100">
        <i className="mdi mdi-loading mdi-spin mdi-48px" />
      </div>
    );
  }
  return (
    <div className="form-group row">
      <label className="col-md-3 col-form-label text-right">Personnel</label>
      <div className="col-md-9 col-form-label">
        {missionsPersonnelApproved.map((p, i) => {
          return (
            <FlightPersonnelPicker
              key={i}
              onChange={handleChange}
              personnel={p}
              pic={pic}
              vo={vo}
              na={na}
              role={state[p.id]}
              disabled={disabled}
            />
          );
        })}
        <div
          className={`w-100 ${
            missionsPersonnelAdjudicated.length > 0 ? `border-top` : ""
          } my-2`}>
          {missionsPersonnelAdjudicated.length > 0 && (
            <p className="text-muted mb-1">
              <small>Added post approval:</small>
            </p>
          )}
          {missionsPersonnelAdjudicated.map((p, i) => {
            return (
              <div className={i === 0 ? "mt-1" : ""}>
                <FlightPersonnelPicker
                  key={i}
                  onChange={handleChange}
                  personnel={p}
                  pic={pic}
                  vo={vo}
                  na={na}
                  role={state[p.id]}
                  disabled={disabled}
                />
              </div>
            );
          })}
        </div>
        <AddNewSelector
          disabled={disabled}
          doSave={doMissionsPersonnelSave}
          doFetch={doMissionsPersonnelFetch}
          items={personnelItems}
          ignoreItems={missionsPersonnelItems}
        />
      </div>
    </div>
  );
};

export default connect(
  "selectDomainsItems",
  "selectMissionsPersonnelItems",
  "selectPersonnelItems",
  "selectMissionsPersonnelIsLoading",
  "doMissionsPersonnelFetch",
  "doMissionsPersonnelSave",
  "selectMissionsPersonnelApproved",
  "selectMissionsPersonnelAdjudicated",
  FormGroup
);
