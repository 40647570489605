import React from "react";
import classnames from "../../../../../utils/classnames";

export default ({ item, doUpdateInnerRoute }) => {
  const cls = classnames({
    "breadcrumb-item": true,
    "text-indigo": !item.active,
    active: item.active
  });

  const handleClick = () => {
    if (!item.active) {
      doUpdateInnerRoute(item.url);
    }
  };

  return (
    <li style={{ cursor: "pointer" }} onClick={handleClick} className={cls}>
      {item.label}
    </li>
  );
};
