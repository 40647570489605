import React from "react";

const EquipmentCostRenderer = (props) => {
  let equip = props.data.equipment_status;
  if (!equip || !equip.length) return null;
  return (
    <span>
      {equip
        .reduce((prev, curr) => {
          return curr.cost + prev;
        }, 0)
        .toLocaleString("en-US", { style: "currency", currency: "USD" })}
    </span>
  );
};

export default EquipmentCostRenderer;
