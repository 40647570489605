import React from "react";

export default () => {
  return (
    <div style={{ textAlign: "center", width: "100%" }}>
      <h5 className="text-muted">
        <i className="mdi mdi-check-bold pr-2"></i>Looks like you're all caught
        up!
      </h5>
    </div>
  );
};
