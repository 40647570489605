import React from "react";

const TrackingRenderer = (props) => {
  const { tracking_no } = props.data;
  let href = null;
  if (tracking_no && tracking_no.length) {
    if (tracking_no.indexOf("UPS:") !== -1)
      href = `https://www.ups.com/track?loc=en_us&tracknum=${tracking_no.replace(
        "UPS:",
        ""
      )}`;
    if (tracking_no.indexOf("FEDEX:") !== -1)
      href = `https://www.fedex.com/apps/fedextrack/?tracknumbers=${tracking_no.replace(
        "FEDEX:",
        ""
      )}`;
    if (tracking_no.indexOf("DHL:") !== -1)
      href = `https://www.dhl.com/en/express/tracking.html?brand=DHL&AWB=${tracking_no.replace(
        "DHL:",
        ""
      )}`;
    if (tracking_no.indexOf("USPS:") !== -1)
      href = `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${tracking_no.replace(
        "USPS:",
        ""
      )}`;
    if (href)
      return (
        <span>
          <a href={href} target="_blank" rel="noopener noreferrer">
            {tracking_no}
          </a>
        </span>
      );
    return <span>{tracking_no}</span>;
  } else {
    return <span></span>;
  }
};

export default TrackingRenderer;
