import React, { useState, useEffect } from "react";

const TagInputContainer = ({ tagsBoxMaxWidth = "25rem", tags, onChange, placeholder="Enter search tags...", reservedTags=[] }) => {
  const [state, setState] = useState({
    inputText: "",
    tags: tags,
    initialTags: [...tags],
  });
  const newTagSubmitted = (string) => {
    if (string.length > 0) {
      setState({
        ...state,
        tags: [...state.tags, string],
        inputText: "",
      });
    }
  };
  const keyDownHandler = (e) => {
    if (["~"].includes(e.key)) e.preventDefault();
    if (e.key === "Enter" && !e.shiftKey) {
      // Submit on 'Enter' Key
      e.preventDefault();
      newTagSubmitted(e.currentTarget.value);
    }
  };
  const addBtnClicked = (e) => {
    newTagSubmitted(e.currentTarget.parentElement.previousSibling.value);
  };
  const textInputChanged = (e) => {
    setState({ ...state, inputText: e.currentTarget.value });
  };
  const deleteTagClicked = (e) => {
    let idx = parseInt(e.target.name);
    let tagsCopy = [...state.tags];
    tagsCopy.splice(idx, 1);
    setState({ ...state, tags: tagsCopy });
  };
  const renderTags = () => {
    if (Object.keys(state.tags).length === 0) return null;
    return (
      <div
        className="d-flex flex-wrap h-100 mr-auto mt-2"
        style={{
          maxWidth: tagsBoxMaxWidth,
          maxHeight: "7rem",
          overflowY: "auto",
        }}
      >
        {Object.keys(state.tags).map((num, idx) => {
          return (
            <div
              key={idx}
              className="badge badge-aliceblue d-flex align-items-center p-0 pl-2 m-1 "
            >
              <span className="py-2 pr-1" style={{ fontSize: 12 }}>
                {state.tags[num]}
              </span>
              {!reservedTags.includes(state.tags[num]) &&
                <button
                  type="button"
                  className="close badge-close  pl-1 pr-1 py-2"
                  name={num}
                  onClick={deleteTagClicked}
                  aria-label="Close"
                  >
                  <span
                    aria-hidden="true"
                    style={{ fontSize: 16 }}
                    className="d-flex align-items-center"
                  >
                    &times;
                  </span>
                </button>
              }
            </div>
          );
        })}
      </div>
    );
  };
  useEffect(() => {
    if (tags) setState({ ...state, initialTags: [...tags] });
  }, []);

  useEffect(() => {
    onChange({
      tags: Object.values(state.tags),
      _changesMade: state.tags.length !== state.initialTags.length,
    });
  }, [JSON.stringify(state.tags)]);

  return (
    (<div className="d-flex justify-content-start flex-column w-100">
      <div
        className="input-group mr-auto mb-auto"
        style={{ maxWidth: "fit-content" }}
      >
        <input
          placeholder={placeholder}
          value={state.inputText}
          type="text"
          onChange={textInputChanged}
          onKeyDown={keyDownHandler}
          pattern="\w|\d"
          maxLength={15}
          className="form-control"
        />
        <div className="input-group-append">
          <button
            type="button"
            className="btn btn-primary"
            disabled={
              state.inputText.length === 0 ||
              !/\w/.test(state.inputText) ||
              Object.values(state.tags).includes(state.inputText) ||
              reservedTags.includes(state.inputText)
            }
            onClick={addBtnClicked}
          >
            +
          </button>
        </div>
      </div>
      {renderTags()}
    </div>)
  );
};
export default TagInputContainer;
