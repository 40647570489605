import React from "react";
import classnames from "../../utils/classnames";

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.renderButton = this.renderButton.bind(this);
  }

  toggleShow() {
    this.setState({
      show: !this.state.show,
    });
  }

  renderButton() {
    const { label, iconClass } = this.props;
    return (
      <div className="dropdown-toggle btn-link">
        {iconClass ? <i className={`${iconClass} pr-1`}></i> : null}
        {label}
      </div>
    );
  }

  render() {
    const { children, hasNotification, notificationColor } = this.props;
    const { show } = this.state;

    const itemClass = classnames({
      "nav-item": true,
      dropdown: true,
      "px-2": true,
    });

    const dropdownMenuClass = classnames({
      "dropdown-menu": true,
      "dropdown-menu-right": true,
      show: show,
    });

    return (
      <li className={itemClass} onClick={this.toggleShow}>
        {this.renderButton()}
        {hasNotification && (
          <div
            style={{
              position: "absolute",
              left: 16,
              top: 0,
              width: 6,
              height: 6,
              borderRadius: "50%",
            }}
            className={`bg-${notificationColor}`}
          />
        )}
        <div
          onClick={this.toggleShow}
          style={{
            display: show ? "block" : "none",
            position: "fixed",
            zIndex: 999,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        ></div>
        <div
          className={dropdownMenuClass}
          x-placement="bottom-start"
          style={{
            position: "absolute",
            willChange: "transform",
            top: "0px",
            right: "0px",
            transform: "translate3d(0px, 26px, 0px)",
          }}
        >
          {children}
        </div>
      </li>
    );
  }
}

export default Dropdown;
