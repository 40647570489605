import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "maintenance",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/aircraft/:aircraftSlug/maintenance",
  putTemplate: "/orgs/:orgSlug/aircraft/:aircraftSlug/maintenance/:item.id",
  postTemplate: "/orgs/:orgSlug/aircraft/:aircraftSlug/maintenance",
  deleteTemplate: "/orgs/:orgSlug/aircraft/:aircraftSlug/maintenance/:item.id",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN", "MAINTENANCE_DELETE"]
});
