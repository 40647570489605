import React, { useEffect, useState } from "react";
import AdjudicationsList from "../approvals-tab/adjudications-list";
import AdjudicationNotesForm from "../approvals-tab/adjudication-notes-form";
import { connect } from "redux-bundler-react";

const AlertItem = ({
  item,
  isFetching,
  doSave,
  doFetch,
  editable,
  doFileIoUpload,
  alwaysCollapsed, // prop that forces the collapsed state of the alert / adjudication
}) => {
  const [expanded, setExpanded] = useState(false);

  if (editable) {
    return (
      <div
        className={`alert alert-${item.type}`}
        role="alert"
        key={item.variable}>
        <h5 className="alert-heading">
          <>
            {item.title}
            {!expanded && <small>{` - ${item.variable}`}</small>}
          </>
        </h5>
        {expanded && <p>{item.body}</p>}
        <div className="d-flex w-100 p-relative justify-content-center align-items-center">
          <hr className={alwaysCollapsed ? "mb-2 w-100" : "m-0 w-100"} />
          {!alwaysCollapsed && (
            <button
              className="btn btn-ghost-dark rounded p-0"
              onClick={() => setExpanded(!expanded)}>
              <i
                className={`mdi mdi-chevron-${
                  expanded ? "up" : "down"
                } mdi-18px`}
              />{" "}
            </button>
          )}
        </div>
        {!expanded && item.adjudications.length > 0 && (
          <AdjudicationsList overrideClass="mt-0" items={item.adjudications} />
        )}
        {expanded && <b>{item.variable}</b>}
        {expanded && (
          <>
            {item.adjudications.length > 0 && (
              <>
                <hr /> <AdjudicationsList items={item.adjudications} />
              </>
            )}
            <hr />
            <AdjudicationNotesForm
              alert={item}
              onSave={doSave}
              doFetch={doFetch}
              isFetching={isFetching}
              doFileIoUpload={doFileIoUpload}
            />
          </>
        )}
      </div>
    );
  } else {
    return (
      <div
        className={`alert alert-${item.type}`}
        role="alert"
        key={item.variable}>
        <h5 className="alert-heading">{item.title}</h5>
        <p>{item.body}</p>
        <hr />
        <b>{item.variable}</b>
      </div>
    );
  }
};
export default connect(
  "selectFlightsTabRouterPath",
  "doFileIoUpload",
  AlertItem
);
