import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
    name: "jobsComments",
    uid: "id",
    prefetch: true,
    persist: false,
    routeParam: null,
    getTemplate: "/job-board/:jobSlug/comments",
    putTemplate: "/job-board/:jobSlug/comments/:item.id",
    postTemplate: "/job-board/:jobSlug/comments",
    deleteTemplate: "/job-board/:jobSlug/comments/:item.id",
    fetchActions: [],
    forceFetchActions: ["JOBS_FETCH_STARTED", "JOBSCOMMENTS_SAVE_FINISHED", "JOBSCOMMENTS_DELETE_FINISHED"],
});