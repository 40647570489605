import React from "react";
import { connect } from "redux-bundler-react";
import { find, isEmpty } from "lodash";
import { payloads } from "../../models";
import Loader from "../../app-components/loader";
import DeleteButton from "../_shared/delete-button/delete-button";
import SchemaForm from "../../app-components/schema-form/schema-form";
import PlatformCard from "../_shared/platforms-card/platforms-card";
import MissionsCard from "../_shared/missions-card/missions-card";
import FileUploadCard from "../_shared/file-upload-card/file-upload-card";
import RoleFilter from "../../app-containers/context-providers/role-filter";
import OrgFilter from "../../app-containers/context-providers/org-filter";
import ReadOnly from "../_shared/read-only-notification";
import NotesCard from "../_shared/notes-card/note-card";
import ApprovedComponentsCard from "../_shared/approved-components-card";
import payloadsDeactivationDialog from "./payloads-deactivation-dialog";
class PayloadsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.save = this.save.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.enableEditing = this.enableEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.openDeactivationDialog = this.openDeactivationDialog.bind(this);
  }

  save() {
    const { doPayloadsSave } = this.props;
    if (this.form.isValid()) {
      const data = this.form.serialize();
      this.setState(
        {
          editing: false,
        },
        () => {
          doPayloadsSave(data);
        }
      );
    }
  }

  enableEditing() {
    this.setState({
      editing: true,
    });
  }

  cancelEditing() {
    const { payloadsByRoute } = this.props;
    this.setState(
      {
        editing: false,
      },
      () => {
        this.form.reset({ data: payloadsByRoute, schema: payloads });
      }
    );
  }

  handleDelete() {
    const {
      doPayloadsDelete,
      doUpdateRelativeUrl,
      routeParams,
      payloadsByRoute,
    } = this.props;
    this.setState(
      {
        editing: false,
      },
      () => {
        doPayloadsDelete(payloadsByRoute, () => {
          doUpdateRelativeUrl(`/${routeParams.orgSlug}/payloads`);
        });
      }
    );
  }

  openDeactivationDialog() {
    const { doDialogOpen } = this.props;
    doDialogOpen({
      content: payloadsDeactivationDialog,
      props: this.props,
      size: "xl",
    });
    this.cancelEditing();
  }

  renderForm() {
    const { editing } = this.state;
    const { payloadsByRoute, userIsAtpm } = this.props;
    if (isEmpty(payloadsByRoute)) return <Loader />;
    return (
      <SchemaForm
        ref={(el) => {
          this.form = el;
        }}
        inline={true}
        displayOnly={!editing}
        schema={payloads}
        data={payloadsByRoute}
        conditionalInputs={[
          {
            name: "parent_id",
            readOnly: !userIsAtpm,
          },
        ]}
      />
    );
  }

  renderActions() {
    const { editing } = this.state;
    const { equipmentRequestItems, payloadsByRoute } = this.props;
    if (!editing) return null;
    return (
      <div>
        <div className="float-left">
          <button
            className="btn btn-sm btn-dark mr-2"
            onClick={this.openDeactivationDialog}
            disabled={!!find(equipmentRequestItems, { existing_id: payloadsByRoute.id })}
          >
            Request Deactivation
          </button>
        </div>
        <div className="float-right">
          <button
            onClick={this.cancelEditing}
            className="btn btn-sm btn-secondary mr-2"
          >
            Cancel
          </button>
          <button onClick={this.save} className="btn btn-sm btn-success">
            Save
          </button>
        </div>
        <OrgFilter allowOrgs={["hq"]}>
          <RoleFilter allowRoles={["HQ.MEMBER", "HQ.ADMIN"]}>
            <DeleteButton onDelete={this.handleDelete} />
          </RoleFilter>
        </OrgFilter>
      </div>
    );
  }

  render() {
    const { editing } = this.state;
    const {
      payloadsByRoute,
      payloadsPlatformsItems,
      payloadsPlatformsIsLoading,
      doPayloadsPlatformsSave,
      doPayloadsPlatformsDelete,
      payloadsDocsItems,
      payloadsDocsIsLoading,
      payloadsMissionsItems,
      payloadsMissionsIsLoading,
      doFileIoUpload,
      doFileIoDelete,
    } = this.props;
    return (
      <div className="container mt-3">
        <div className="row">
          <div className="col-sm-7">
            <div className="card">
              <div className="card-header">
                <div className="float-right">
                  <RoleFilter
                    allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
                    alt={ReadOnly}
                  >
                    <button
                      disabled={editing}
                      className="btn btn-sm btn-ghost-primary"
                      onClick={this.enableEditing}
                    >
                      <i className="mdi mdi-pencil icon-inline"></i>
                      Edit
                    </button>
                  </RoleFilter>
                </div>
                <i className="mdi mdi-camera"></i> Payload Details
              </div>
              <div className="card-body">
                {this.renderForm()}
                {this.renderActions()}
              </div>
            </div>
          </div>
          <div className="col-sm-5">
            <ApprovedComponentsCard componentTypeToList="aircraft" />
            <PlatformCard
              allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
              isLoading={payloadsPlatformsIsLoading}
              items={payloadsPlatformsItems}
              onAdd={doPayloadsPlatformsSave}
              onRemove={doPayloadsPlatformsDelete}
            />
            <FileUploadCard
              allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
              isLoading={payloadsDocsIsLoading}
              items={payloadsDocsItems}
              header={true}
              searchable={false}
              onUpload={doFileIoUpload}
              onRemove={doFileIoDelete}
              rel="payload_docs"
              data={{ payload_id: payloadsByRoute ? payloadsByRoute.id : null }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <MissionsCard
              isLoading={payloadsMissionsIsLoading}
              items={payloadsMissionsItems}
            />
            <NotesCard />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "doFileIoUpload",
  "doFileIoDelete",
  "doDialogOpen",
  "doPayloadsSave",
  "doPayloadsDelete",
  "selectRouteParams",
  "selectEquipmentRequestItems",
  "selectPayloadsByRoute",
  "selectPayloadsPlatformsItems",
  "selectPayloadsPlatformsIsLoading",
  "doPayloadsPlatformsSave",
  "doPayloadsPlatformsDelete",
  "selectPayloadsDocsItems",
  "selectPayloadsDocsIsLoading",
  "selectPayloadsMissionsItems",
  "selectPayloadsMissionsIsLoading",
  "selectUserIsAtpm",
  PayloadsDetails
);
