import React from "react";
import classnames from "classnames";
import ComponentTree from "../component-tree";
import "../documentation.css";
const Card = ({ childComponents = [], title, className }) => {
  let cardClsName = classnames({
    "card": true,
    [className]: className
  });
  return (
    <div className={cardClsName}>
      <div className="card-body">
        {title && <h6 className="card-title">{title}</h6>}
        {childComponents.map((c, idx) => {
          return (
            <div key={idx}>
              <ComponentTree obj={c}/>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default Card;