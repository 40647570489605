import React from "react";
import { connect } from "redux-bundler-react";

const DeleteDialog = ({
  returnUrl,
  sendFunc,
  deleteFunc,
  doUpdateRelativeUrl,
  doDialogClose,
  deleteText,
  title,
  data,
  dataType,
  deleteType,
}) => {
  const handleConfirm = async (e) => {
    if (deleteType === "normalDelete") {
      let args = {
        ...data,
        marked_as_deleted: 1,
        body: ` This ${dataType} was deleted on ${new Date().toLocaleString()}`,
      };
      if (dataType !== "comment") args.title = "[deleted]";
      await sendFunc(Object.assign({}, { ...args }));
    } else if (deleteType === "permanentDelete") {
      await deleteFunc(Object.assign({}, { ...data, deleted: 1 }));
      if (dataType !== "comment") doUpdateRelativeUrl(returnUrl);
    }
    await doDialogClose();
  };
  return (
    <div className="modal-content mx-auto">
      <div className="px-4 py-3">
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
          <button
            className="close"
            type="button"
            aria-label="close"
            onClick={() => {
              doDialogClose();
            }}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body" style={{ overflow: "visible" }}>
          {deleteText}
        </div>
        <div className="modal-footer">
          <button
            className="form-control btn btn-sm btn-secondary"
            type="button"
            name="no"
            onClick={() => {
              doDialogClose();
            }}
          >
            No
          </button>
          <button
            className="form-control btn btn-sm btn-primary"
            type="button"
            name="yes"
            onClick={handleConfirm}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect("doUpdateRelativeUrl", "doDialogClose", DeleteDialog);
