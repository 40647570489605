import React, { useEffect, useReducer, useState } from "react";
import { connect } from "redux-bundler-react";
import ConfigurationTile from "../inventory-tab/configuration-tile";
import "../inventory-tab/inventory.css";
import Loader from "../../../../app-components/loader";
const SelectConfigurationModal = ({
  doDialogClose,
  missionInventoryByConfiguration,
  missionsConfigurationIsFetching,
  doUpdateInnerRoute,
  raw,
  flight,
  doMissionsConfigurationsSetActive,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const innerNav = (e) => {
    const path = e.currentTarget.dataset.path;
    doDialogClose(() => doUpdateInnerRoute(path));
  };
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Select Flight Configuration</h5>
        <button
          onClick={doDialogClose}
          className="close"
          type="button"
          aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        {missionsConfigurationIsFetching ? (
          <Loader />
        ) : (
          <div
            className="row"
            style={{ flexWrap: "nowrap", overflowX: "auto" }}>
            {missionInventoryByConfiguration.map((invConfig, key) => {
              return (
                <div
                  onClick={() => setSelectedIndex(key)}
                  className={`p-2 selectable-config ${
                    selectedIndex === key ? "selected" : ""
                  }`}
                  key={key}>
                  <ConfigurationTile
                    configuration={Object.values(invConfig)[0]}
                    configurationId={Object.keys(invConfig)[0]}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="modal-footer">
        <button
          className={"btn btn-sm btn-primary"}
          onClick={(e) =>
            doMissionsConfigurationsSetActive(
              Object.values(missionInventoryByConfiguration[selectedIndex])[0],
              () => innerNav(e)
            )
          }
          data-path={
            flight
              ? `/raw/${raw.id}/flights/${flight.id}`
              : `/raw/${raw.id}/flights/new`
          }
          type="button">
          {" "}
          Proceed
        </button>
      </div>
    </div>
  );
};

export default connect(
  "doDialogClose",
  "selectMissionsConfigurationIsFetching",
  "selectMissionInventoryByConfiguration",
  "doMissionsConfigurationsSetActive",
  "doMissionsConfigurationsFetch",
  SelectConfigurationModal
);
