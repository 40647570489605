import React from "react";
import classnames from "../../utils/classnames";
import { formatDistance, isBefore } from "date-fns";

const MissionListItem = ({ mission }) => {
  const renderApprovalStatus = () => {
    const { next_approval_role } = mission;
    let mboDone,
      atpmDone,
      maaDone = false;
    switch (next_approval_role) {
      case "Aircrew Training Program Manager":
        mboDone = true;
        break;
      case "Mission Approval Authority":
        mboDone = true;
        atpmDone = true;
        break;
      case "approved":
        mboDone = true;
        atpmDone = true;
        maaDone = true;
        break;
      default:
        break;
    }

    const mboIconClass = classnames({
      mdi: true,
      "mdi-checkbox-blank-circle-outline": !mboDone,
      "mdi-checkbox-marked-circle-outline": mboDone,
      "pr-1": true,
    });

    const atpmIconClass = classnames({
      mdi: true,
      "mdi-checkbox-blank-circle-outline": !atpmDone,
      "mdi-checkbox-marked-circle-outline": atpmDone,
      "pr-1": true,
    });

    // const maaIconClass = classnames({
    //   mdi: true,
    //   "mdi-checkbox-blank-circle-outline": !maaDone,
    //   "mdi-checkbox-marked-circle-outline": maaDone,
    //   "pr-1": true,
    // });

    return (
      <>
        <span
          className={`badge badge-pill badge-${
            mboDone ? "success" : "secondary"
          } mr-1`}>
          <i className={mboIconClass}></i>MBO
        </span>
        <span
          className={`badge badge-pill badge-${
            atpmDone ? "success" : "secondary"
          } mr-1`}>
          <i className={atpmIconClass}></i>ATPM
        </span>
        {/* <span
          className={`badge badge-pill badge-${
            maaDone ? "success" : "secondary"
          } mr-1`}
        >
          <i className={maaIconClass}></i>MAA
        </span> */}
      </>
    );
  };

  const now = new Date();
  const startDateRelative = formatDistance(new Date(mission.date_start), now);
  const lastEditedRelative = formatDistance(
    new Date(mission.last_updated_date),
    now
  );
  const isBeforeNow = isBefore(new Date(mission.date_start), now);

  const descriptionString = `Submitted ${lastEditedRelative} ago, ${
    isBeforeNow
      ? `started ${startDateRelative} ago`
      : `plan start in ${startDateRelative}`
  }`;

  return (
    <li className="list-group-item">
      <div className="row">
        <span className="col-lg-3">
          <a href={mission.url}>{`${mission.org_name} - ${mission.name}`}</a>
        </span>
        <span className="col-lg text-muted pl-3">{descriptionString}</span>
        <span className="col-lg">
          <span className="float-right">{renderApprovalStatus()}</span>
        </span>
      </div>
    </li>
  );
};

// MISSIONS LIST
export default ({ items: missions }) => {
  const renderEmptyState = () => {
    if (!missions || !missions.length)
      return (
        <li className="list-group-item">
          <span className="m-auto">No Missions Right Now</span>
        </li>
      );
    return null;
  };
  const renderMissions = () => {
    if (!missions || !missions.length) return null;
    return (
      <>
        <p>Total: ({missions.length})</p>
        {missions.map((mission, i) => {
          return <MissionListItem key={i} mission={mission} />;
        })}
      </>
    );
  };
  return (
    <ul className="list-group">
      {renderMissions()}
      {renderEmptyState()}
    </ul>
  );
};
