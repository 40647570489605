import createMatcher from "feather-route-matcher";
import DailyRawHome from "./daily-raw-home";
import DailyRawForm from "./daily-raw-form";
import FlightForm from "./flight-new";
import FlightDetails from "./flight-details";
import FlightDebrief from "./flight-debrief";
import FlightIncident from "./flight-incident/incident-form";
import { createSelector } from "redux-bundler";

const innerRoutes = createMatcher({
  "/": DailyRawHome,
  "/raw/new": DailyRawForm,
  "/raw/:rawId": DailyRawForm,
  "/raw/:rawId/flights/new": FlightForm,
  "/raw/:rawId/flights/:flightId": FlightDetails,
  "/raw/:rawId/flights/:flightId/debrief": FlightDebrief,
  "/raw/:rawId/flights/:flightId/debrief/incident": FlightIncident,
});

// breadcrumbs should look like
// Flights
// Flights / New Raw
// Flights / Date
// Flights / Date / New Flight
// FLights / Date / Flight Name
// Flights / Date / Flight Name / Reporting
// Flights / Date / Flight Name / Reporting / Incident

export default {
  name: "flightsTabRouter",

  getReducer: () => {
    const initialData = {
      path: "/",
    };
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "FLIGHTS_TAB_ROUTER_UPDATE_PATH":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doFlightsTabRouterUpdatePath:
    (path) =>
    ({ dispatch }) => {
      dispatch({
        type: "FLIGHTS_TAB_ROUTER_UPDATE_PATH",
        payload: {
          path: path,
        },
      });
    },

  selectFlightsTabRouterPath: (state) => {
    return state.flightsTabRouter.path;
  },

  selectFlightsTabRouterParams: createSelector(
    "selectFlightsTabRouterData",
    (data) => {
      return data.params;
    }
  ),

  selectFlightsTabRouterData: createSelector(
    "selectFlightsTabRouterPath",
    (path) => {
      return innerRoutes(path);
    }
  ),

  selectFlightsTabRouterComponent: createSelector(
    "selectFlightsTabRouterData",
    (data) => {
      return data.page;
    }
  ),

  selectFlightsTabBreadcrumbs: createSelector(
    "selectFlightsTabRouterData",
    "selectMissionsDailyRawItemsObject",
    "selectMissionsFlightsItemsObject",
    (data, raws, flights) => {
      const raw = data.params && data.params.rawId && raws[data.params.rawId];
      const flight =
        data.params && data.params.flightId && flights[data.params.flightId];
      const parts = data.pattern.split("/");
      const breadcrumbs = [];
      breadcrumbs.push({
        label: "Flights",
        url: "/",
        active: data.pattern === "/",
      });
      for (var i = 2; i < parts.length; i++) {
        if (i === 2) {
          if (parts[i] === "new") {
            breadcrumbs.push({
              label: "New Daily RAW",
              url: null,
              active: true,
            });
          } else {
            breadcrumbs.push({
              label: new Date(raw.date).toLocaleDateString(),
              url: `/raw/${raw.id}`,
              active: data.pattern === "/raw/:rawId",
            });
          }
        } else if (i === 4) {
          if (parts[i] === "new") {
            breadcrumbs.push({
              label: "New Flight",
              url: null,
              active: true,
            });
          } else {
            breadcrumbs.push({
              label: flight.name,
              url: `/raw/${raw.id}/flights/${flight.id}`,
              active: data.pattern === "/raw/:rawId/flights/:flightId",
            });
          }
        } else if (i === 5) {
          if (parts[i] === "debrief") {
            breadcrumbs.push({
              label: "Flight Debrief",
              url: `/raw/${raw.id}/flights/${flight.id}/debrief`,
              active: data.pattern === "/raw/:rawId/flights/:flightId/debrief",
            });
          }
        } else if (i === 6) {
          if (parts[i] === "incident") {
            breadcrumbs.push({
              label: "Incident",
              url: `/raw/${raw.id}/flights/${flight.id}/debrief/incident`,
              active:
                data.pattern ===
                "/raw/:rawId/flights/:flightId/debrief/incident",
            });
          }
        }
      }
      return breadcrumbs;
    }
  ),
};
