import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { connect } from "redux-bundler-react";
import Loader from "../../app-components/loader";

const TransferAtpmModal = ({
  doDialogClose,
  profileActive,
  doAtpmTransferSave,
  doFetchByApprovalRole,
  domainsItemsByGroup,
  backupAtpms,
  atpmDashboardIsFetching,
}) => {
  const [added, setAdded] = useState([]);
  const [date, setDate] = useState();

  useEffect(() => {
    let backupId = domainsItemsByGroup.approval_role.filter(
      (role) => role.val === "Backup ATPM"
    )[0].id;
    doFetchByApprovalRole(backupId);
  }, []);

  const save = () => {
    let transferData = {
      backup_atpm_id: added.value,
      atpm_id: profileActive.id,
      create_date: new Date().toLocaleDateString(),
      expiration_date: date,
    };
    doAtpmTransferSave(transferData, doDialogClose);
  };

  if (atpmDashboardIsFetching)
    return (
      <div style={{ marginTop: "50%", marginBottom: "50%" }}>
        <Loader opt="dissolving-cube" />
      </div>
    );
  else
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Temporary ATPM Transfer</h5>
          <button
            onClick={doDialogClose}
            className="close"
            type="button"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="w-100 d-flex flex-column mb-2">
            <div className="mb-2">User to Transfer Privileges</div>
            <ReactSelect
              name="personnel"
              options={backupAtpms.map((person) => ({
                label: person.name,
                value: person.parent_id,
              }))}
              onChange={setAdded}
            />
          </div>
          <div className="w-100 d-flex flex-column">
            <div className="mb-2">Expiration Date</div>
            <input
              type="date"
              className="form-control"
              name="expiration_date"
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
        </div>

        <div className="modal-footer">
          <button
            onClick={doDialogClose}
            className="btn btn-sm btn-secondary"
            type="button"
          >
            Close
          </button>
          <button
            onClick={save}
            className="btn btn-sm btn-success"
            type="button"
          >
            Save
          </button>
        </div>
      </div>
    );
};

export default connect(
  "doDialogClose",
  "doPersonnelSave",
  "selectPersonnelNoAtpm",
  "selectProfileId",
  "selectPersonnelItems",
  "selectProfileActive",
  "doAtpmTransferSave",
  "doFetchByApprovalRole",
  "selectDomainsItemsByGroup",
  "selectBackupAtpms",
  "selectAtpmDashboardIsFetching",
  TransferAtpmModal
);
