import React from "react";
import DailyRawPickerItem from "./daily-raw-picker-item";

class DailyRawPicker extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.renderHelpText = this.renderHelpText.bind(this);
  }

  handleChange(e) {
    const { onChange } = this.props;
    if (onChange) onChange(e);
  }

  renderHelpText() {
    const { helpText } = this.props;
    if (!helpText) return null;
    return (
      <span className="help-block">
        <small>{helpText}</small>
      </span>
    );
  }

  render() {
    const { name, title, value, options, disabled } = this.props;
    return (
      <div className="form-group row">
        <label className="col-md-3 col-form-label text-right">{title}</label>
        <div className="col-md-9">
          {options.map((opt, i) => {
            return (
              <DailyRawPickerItem
                key={i}
                name={name}
                checked={value === opt.value}
                label={opt.label}
                value={opt.value}
                onChange={this.handleChange}
                disabled={disabled}
              />
            );
          })}
          {this.renderHelpText()}
        </div>
      </div>
    );
  }
}

export default DailyRawPicker;
