import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "missionsDailyRaw",
  uid: "id",
  prefetch: true,
  routeParam: "missionsSlug",
  getTemplate: "/orgs/:orgSlug/missions/:missionsSlug/dailyraw",
  putTemplate: "/orgs/:orgSlug/missions/:missionsSlug/dailyraw/:item.id",
  postTemplate: "/orgs/:orgSlug/missions/:missionsSlug/dailyraw",
  deleteTemplate: "/orgs/:orgSlug/missions/:missionsSlug/dailyraw/:item.id",
  fetchActions: ["AUTH_LOGGED_IN"],
  forceFetchActions: ["ACTIVE_TAB_UPDATE", "URL_UPDATED"],
});
