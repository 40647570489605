import React from "react";
import { connect } from "redux-bundler-react";
import { differenceInMinutes } from "date-fns";
import MissionStatusFilter from "../../_shared/mission-status-filter";
import selectConfigModal from "./select-config-modal";

class DailyRawListItem extends React.Component {
  constructor(props) {
    super(props);
    this.innerNav = this.innerNav.bind(this);
  }

  innerNav(e) {
    const path = e.currentTarget.dataset.path;
    const { doUpdateInnerRoute } = this.props;
    doUpdateInnerRoute(path);
  }

  render() {
    const {
      raw,
      missionsFlightsItems,
      doDialogOpen,
      doUpdateInnerRoute,
      doMissionsConfigurationsSetActive,
      missionInventoryByConfiguration,
    } = this.props;
    const score = Object.values(raw).reduce((p, c, i) => {
      if (typeof c === "number") return p + c;
      return p;
    }, 0);
    const flights = missionsFlightsItems.filter((flight) => {
      return flight.daily_raw_id === raw.id;
    });
    let useConfiguration =
      missionInventoryByConfiguration &&
      missionInventoryByConfiguration.length > 0;
    return (
      <li className="list-group-item">
        <div className="clearfix">
          <div className="float-right">
            <button
              className="btn btn-sm btn-secondary mr-2"
              onClick={this.innerNav}
              data-path={`/raw/${raw.id}`}>
              View RAW
            </button>
            <MissionStatusFilter
              denyStatus={{
                mission_status: ["complete", "archived"],
              }}
              alt={() => {
                return (
                  <button
                    className="btn btn-sm btn-primary disabled"
                    disabled={true}>
                    Add Flight
                  </button>
                );
              }}>
              <button
                className="btn btn-sm btn-primary"
                data-path={`/raw/${raw.id}/flights/new`}
                onClick={
                  useConfiguration
                    ? () => {
                        doDialogOpen({
                          content: selectConfigModal,
                          props: { raw, doUpdateInnerRoute },
                          size: "lg",
                        });
                      }
                    : this.innerNav
                }>
                Add Flight
              </button>
            </MissionStatusFilter>
          </div>
          <div>
            {`${new Date(raw.date).toDateString()} - RAW Score ${score}`}
          </div>
        </div>

        <div>
          <ul className="list-group mt-2">
            {flights.map((flight, i) => {
              const flightName = flight.name || "Unnamed Flight";
              let activeConfig =
                useConfiguration &&
                missionInventoryByConfiguration
                  .map((item) => item[flight.configuration_id])
                  .filter((item) => !!item)[0];
              return (
                <li
                  className="list-group-item list-group-item-action pointer"
                  key={i}>
                  <span className="float-right">
                    <button
                      onClick={this.innerNav}
                      data-path={`/raw/${raw.id}/flights/${flight.id}/debrief`}
                      className="btn btn-link">
                      Report Faults / Damage / Incident
                    </button>
                  </span>
                  <span>
                    <button
                      onClick={
                        useConfiguration
                          ? (e) =>
                              doMissionsConfigurationsSetActive(
                                activeConfig,
                                () => this.innerNav(e)
                              )
                          : this.innerNav
                      }
                      data-path={`/raw/${raw.id}/flights/${flight.id}`}
                      className="btn btn-link">
                      {`Flight ${i + 1} : ${flightName} : ${differenceInMinutes(
                        new Date(flight.end_time),
                        new Date(flight.start_time)
                      )} Minutes`}
                    </button>
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </li>
    );
  }
}

export default connect(
  "selectMissionsFlightsItems",
  "doDialogOpen",
  "selectMissionsConfigurationsItems",
  "doMissionsConfigurationsSetActive",
  "selectMissionInventoryByConfiguration",
  DailyRawListItem
);
