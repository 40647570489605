import Collection from "ol/Collection";
import XYZ from "ol/source/XYZ";
import TileArcGISRest from "ol/source/TileArcGISRest";
import ImageArcGISRest from "ol/source/ImageArcGISRest";
import ImageWMS from "ol/source/ImageWMS";
import VectorSrc from "ol/source/Vector";
import Tile from "ol/layer/Tile";
import Image from "ol/layer/Image";
import Vector from "ol/layer/Vector";
import GeoJSON from "ol/format/GeoJSON";
import vectorStyleCreator from "./vector-style";
import xhr from "xhr";
import EsriJSON from "ol/format/EsriJSON.js";
import { tile } from "ol/loadingstrategy";
import { createXYZ } from "ol/tilegrid";

const esrijsonFormat = new EsriJSON();

const origin = window.location.origin;

const isCrossOrigin = url => {
  if (!!window.MSInputMethodContext && !!document.documentMode) return null;
  if (url) {
    if (url.indexOf(origin) === -1) return "anonymous";
  } else {
    return null;
  }
};

function formatLabeledUrl(url) {
  const delim = url.indexOf("?") === -1 ? "?" : "&";
  return `${url}${delim}label=true`;
}

function getXyzSource(options) {
  const labelsOn = !!options.labelDefault;
  const url = labelsOn ? formatLabeledUrl(options.url) : options.url;
  return new XYZ({
    url: url,
    crossOrigin: isCrossOrigin(options.url)
  });
}

function getArcGISTiledSource(options) {
  return new TileArcGISRest({
    url: options.url,
    params: {
      LAYERS: "show:" + options.layerList
    },
    crossOrigin: isCrossOrigin(options.url)
  });
}

function getArcGISImageSource(options) {
  return new ImageArcGISRest({
    url: options.url,
    crossOrigin: isCrossOrigin(options.url)
  });
}

function getWmsSource(options) {
  const params = options.params || options;
  params.LAYERS = options.layerList || options.LAYERS;
  return new ImageWMS({
    url: options.url,
    params: params,
    crossOrigin: isCrossOrigin(options.url)
  });
}

function getLocalVectorSource(options) {
  return new VectorSrc({
    features: options.features || new Collection()
  });
}

function getRemoteVectorSource(options) {
  const src = new VectorSrc({
    format: new GeoJSON(),
    url: options.url
  });
  src.on("addfeature", e => {
    /**
     * Code that was being used to register data for the identify tool
     */
    // const uid = options.uid;
    // const g = new GeoJSON();
    // const geojson = g.writeFeatureObject(e.feature, { featureProjection: 'EPSG:3857', dataProjection: 'EPSG:4326' });
  });
  return src;
}

function getEsriVectorSource(options) {
  let serviceUrl = options.url;
  const layer = options.layerList;
  const trailingSlashMatch = /\/$/;

  if (!trailingSlashMatch.test(serviceUrl)) serviceUrl += "/";

  const src = new VectorSrc({
    loader: function(extent, resolution, projection) {
      const g = encodeURIComponent(
        `{"xmin":${extent[0]},"ymin":${extent[1]},"xmax":${extent[2]},"ymax":${
          extent[3]
        },"spatialReference":{"wkid":102100}}`
      );
      const url = `${serviceUrl}${layer}/query/?f=json&returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=${g}&geometryType=esriGeometryEnvelope&inSR=102100&outFields=*&outSR=102100`;
      xhr.get(url, (err, response, body) => {
        if (err) return console.log(err);
        const data = JSON.parse(body);
        const features = esrijsonFormat.readFeatures(data, {
          featureProjection: projection
        });
        if (features.length > 0) {
          src.addFeatures(features);
        }
      });
    },
    strategy: tile(
      createXYZ({
        tileSize: 512
      })
    )
  });
  return src;
}

function getTileLayer(options, source) {
  return new Tile({
    source: source,
    visible: options.visible,
    zIndex: options.zIndex || 0,
    opacity: options.opacity || 1,
    minResolution: options.minResolution || undefined,
    maxResolution: options.maxResolution || undefined
  });
}

function getImageLayer(options, source) {
  return new Image({
    source: source,
    visible: options.visible,
    zIndex: options.zIndex || 0,
    opacity: options.opacity || 1,
    minResolution: options.minResolution || undefined,
    maxResolution: options.maxResolution || undefined
  });
}

function getLocalVectorLayer(options, source) {
  return new Vector({
    altitudeMode: "clampToGround",
    source: source,
    visible: options.visible,
    style: vectorStyleCreator(options),
    zIndex: options.zIndex || 0,
    opacity: options.opacity || 1,
    minResolution: options.minResolution || undefined,
    maxResolution: options.maxResolution || undefined
  });
}

function getRemoteVectorLayer(options, source) {
  const lyr = new Vector({
    altitudeMode: "clampToGround",
    source: source,
    visible: options.visible,
    style: vectorStyleCreator(options),
    zIndex: options.zIndex || 0,
    opacity: options.opacity || 1,
    renderMode: "image",
    minResolution: options.minResolution || undefined,
    maxResolution: options.maxResolution || undefined
  });
  return lyr;
}

function getEsriVectorLayer(options, source) {
  const lyr = new Vector({
    altitudeMode: "clampToGround",
    source: source,
    visible: options.visible,
    style: vectorStyleCreator(options),
    zIndex: options.zIndex || 0,
    opacity: options.opacity || 1,
    renderMode: "image",
    minResolution: options.minResolution || undefined,
    maxResolution: options.maxResolution || undefined
  });
  return lyr;
}

function getSource(options) {
  switch (options.serviceType) {
    case "XYZ":
      return getXyzSource(options);
    case "ArcGIS":
      return getArcGISTiledSource(options);
    case "ArcGISImage":
      return getArcGISImageSource(options);
    case "WMS":
      return getWmsSource(options);
    case "LocalVector":
      return getLocalVectorSource(options);
    case "RemoteVector":
      return getRemoteVectorSource(options);
    case "EsriFeatureService":
      return getEsriVectorSource(options);
    default:
      return null;
  }
}

function getLayer(options, source) {
  if (!source) source = getSource(options);
  switch (options.serviceType) {
    case "XYZ":
      return getTileLayer(options, source);
    case "ArcGIS":
      return getTileLayer(options, source);
    case "ArcGISImage":
      return getImageLayer(options, source);
    case "WMS":
      return getImageLayer(options, source);
    case "LocalVector":
      return getLocalVectorLayer(options, source);
    case "RemoteVector":
      return getRemoteVectorLayer(options, source);
    case "EsriFeatureService":
      return getEsriVectorLayer(options, source);
    default:
      return null;
  }
}

export { getSource, getLayer };
