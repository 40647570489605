import { createSelector } from "redux-bundler";
import createRestBundle from "./create-rest-bundle";
import { find, without } from "lodash";

export default createRestBundle({
  name: "payloads",
  uid: "slug",
  prefetch: true,
  staleAfter: 30000,
  persist: false,
  routeParam: "payloadsSlug",
  getTemplate: "/orgs/:orgSlug/payloads",
  putTemplate: "/orgs/:orgSlug/payloads/:item.id",
  postTemplate: "/orgs/:orgSlug/payloads",
  deleteTemplate: "/orgs/:orgSlug/payloads/:item.id",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN", "TRANSFERS_SAVE_FINISHED"],
  forceFetchActions: ["PAYLOADS_SAVE_FINISHED"],
  addons: {
    selectPayloadsItemsNotDeactivated: createSelector(
      "selectPayloadsItems",
      "selectEquipmentRequestDeactivationTypes",
      (payloadsItems, deactivationTypes) => {
        return payloadsItems.filter(g => !deactivationTypes.map(t => t.val).includes(g.deactivated))
      }
    ),
    selectPayloadsItemsNotInConfiguration: createSelector(
      "selectPayloadsItemsNotDeactivated",
      "selectMissionsConfigurationsActiveRow",
      "selectAircraftItems",
      "selectAircraftConfigurationItems",
      "selectMissionsAircraftItems",
      (
        payloadsItemsNotDeactivated,
        missionsConfigurationsActiveRow,
        aircraftItems,
        aircraftConfigurationItems,
        missionsAircraftItems
      ) => {
        let availablePayloads = [];
        if (!missionsConfigurationsActiveRow && !missionsAircraftItems)
          return payloadsItemsNotDeactivated;
        let missionAircraft = aircraftItems.filter((aircraft) => {
          if (missionsConfigurationsActiveRow) {
            return missionsConfigurationsActiveRow.aircraft_ids
              .split(".")
              .includes(aircraft.id);
          } else if (
            missionsAircraftItems &&
            missionsAircraftItems.length > 0
          ) {
            return missionsAircraftItems[0].id;
          } else return [];
        });

        let availableConfigs = aircraftConfigurationItems.filter(
          (item) =>
            !!find(
              missionAircraft,
              (obj) => item.aircraft_id === obj.platform_id
            )
        );
        if (availableConfigs && availableConfigs.length > 0) {
          availableConfigs.forEach((item) => {
            if (item.payload_id) {
              let obj = find(payloadsItemsNotDeactivated, { parent_id: item.payload_id });
              if (obj) {
                availablePayloads.push(obj);
              }
            }
          });
        }
        if (
          missionsConfigurationsActiveRow &&
          missionsConfigurationsActiveRow.payloads_ids
        ) {
          return availablePayloads.filter(
            (a) =>
              !missionsConfigurationsActiveRow.payloads_ids
                .split(".")
                .includes(a.id)
          );
        } else return availablePayloads;
      }
    ),
  },
});
