import React from "react";
import MissionsTable from "./missions-table";
import Loader from "../../../app-components/loader";

class MissionCard extends React.Component {
  constructor(props) {
    super(props);
    this.renderTable = this.renderTable.bind(this);
  }

  renderTable() {
    const { items, isLoading } = this.props;
    if (isLoading) return <Loader />;
    if (!items.length) {
      return (
        <ul className="list-group">
          <li className="list-group-item">No Associated Missions...</li>
        </ul>
      );
    } else {
      return <MissionsTable items={items} />;
    }
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <span className="float-right text-muted">
            <small>Double click to open</small>
          </span>
          <i className="mdi mdi-airplane-takeoff icon-inline" /> Associated
          Missions
        </div>
        <div className="card-body">{this.renderTable()}</div>
      </div>
    );
  }
}

export default MissionCard;
