import React from "react";
import { connect } from "redux-bundler-react";
import { isEmpty } from "lodash";
import { transfer } from "../../models";
import Loader from "../../app-components/loader";
import SchemaForm from "../../app-components/schema-form/schema-form";
import FileUploadCard from "../_shared/file-upload-card/file-upload-card";
import RoleFilter from "../../app-containers/context-providers/role-filter";
import NotesCard from "../_shared/notes-card/note-card";
import SelectorCard from "../_shared/selector-card/card";
import Stepper from "./stepper";
import DangerZone from "./danger-zone";

class TransferDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.save = this.save.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.enableEditing = this.enableEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
  }

  save() {
    const { doTransfersSave } = this.props;
    if (this.form.isValid()) {
      const data = this.form.serialize();
      this.setState(
        {
          editing: false,
        },
        () => {
          doTransfersSave(data);
        }
      );
    }
  }

  enableEditing() {
    this.setState({
      editing: true,
    });
  }

  cancelEditing() {
    const { transfersByRoute } = this.props;
    this.setState(
      {
        editing: false,
      },
      () => {
        this.form.reset({ data: transfersByRoute, schema: transfer });
      }
    );
  }

  renderForm() {
    const { editing } = this.state;
    const { transfersByRoute, domainsItemsByGroup } = this.props;
    if (isEmpty(transfersByRoute)) return <Loader />;
    return (
      <SchemaForm
        ref={(el) => {
          this.form = el;
        }}
        inline={true}
        displayOnly={!editing}
        schema={transfer}
        data={transfersByRoute}
        domains={domainsItemsByGroup}
      />
    );
  }

  renderActions() {
    const { editing } = this.state;
    if (!editing) return null;
    return (
      <div>
        <div className="float-right">
          <button
            onClick={this.cancelEditing}
            className="btn btn-sm btn-secondary mr-2"
          >
            Cancel
          </button>
          <button onClick={this.save} className="btn btn-sm btn-success">
            Save
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { editing } = this.state;
    const {
      transfersByRoute,
      transfersEquipmentItems,
      transfersEquipmentIsLoading,
      doTransfersEquipmentSave,
      doTransfersEquipmentDelete,
      transfersDocsItems,
      transfersDocsIsLoading,
      transfers4900DocsItems,
      transfers4900DocsIsLoading,
      transfersAllEquipment,
      doFileIoUpload,
      doFileIoDelete,
    } = this.props;
    if (!transfersByRoute) return null;
    return (
      <div className="container mt-3">
        <Stepper />
        <div className="row">
          <div className="col">
            <NotesCard />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-7">
            <div className="card">
              <div className="card-header">
                <div className="float-right">
                  <RoleFilter allowRoles={[":ORG.ADMIN"]} alt={null}>
                    <button
                      disabled={editing}
                      className="btn btn-sm btn-ghost-primary"
                      onClick={this.enableEditing}
                    >
                      <i className="mdi mdi-pencil icon-inline"></i>
                      Edit
                    </button>
                  </RoleFilter>
                </div>
                <i className="mdi mdi-swap-horizontal"></i> Property Transfer
                Details
              </div>
              <div className="card-body">
                {this.renderForm()}
                {this.renderActions()}
              </div>
            </div>
          </div>
          <div className="col-sm-5">
            <FileUploadCard
              title="4900 Documents"
              required
              allowRoles={[":ORG.ADMIN"]}
              isLoading={transfers4900DocsIsLoading}
              items={transfers4900DocsItems}
              header={true}
              searchable={false}
              onUpload={doFileIoUpload}
              onRemove={doFileIoDelete}
              rel="transfer_4900_docs"
              data={{
                transfer_id: transfersByRoute ? transfersByRoute.id : null,
              }}
            />
            <FileUploadCard
              allowRoles={[":ORG.ADMIN"]}
              isLoading={transfersDocsIsLoading}
              items={transfersDocsItems}
              header={true}
              searchable={false}
              onUpload={doFileIoUpload}
              onRemove={doFileIoDelete}
              rel="transfer_docs"
              data={{
                transfer_id: transfersByRoute ? transfersByRoute.id : null,
              }}
            />
            <SelectorCard
              allowRoles={[":ORG.ADMIN"]}
              allowEditing={transfersByRoute.from_status === "In-Progress"}
              items={transfersEquipmentItems}
              isLoading={transfersEquipmentIsLoading}
              pickFrom={transfersAllEquipment}
              onAdd={doTransfersEquipmentSave}
              onRemove={doTransfersEquipmentDelete}
              title="Transfer Equipment"
              iconClass="mdi mdi-swap-horizontal"
              itemRenderFn={(item) => {
                return (
                  <div className="d-flex-flex-column">
                    {`${item.type}: ${item.name}`}
                    <p className="m-0 text-muted">
                      {item.serial_no ? `SN: ${item.serial_no}` : ""}
                    </p>
                  </div>
                );
              }}
              pickerRenderFn={(item) => {
                return `${item.type}: ${item.name}`;
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <DangerZone />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "doFileIoUpload",
  "doFileIoDelete",
  "doTransfersSave",
  "doTransfersDelete",
  "doTransfersEquipmentSave",
  "doTransfersEquipmentDelete",
  "selectRouteParams",
  "selectTransfersByRoute",
  "selectTransfersEquipmentItems",
  "selectTransfersEquipmentIsLoading",
  "selectTransfersDocsItems",
  "selectTransfersDocsIsLoading",
  "selectTransfers4900DocsItems",
  "selectTransfers4900DocsIsLoading",
  "selectTransfersAllEquipment",
  "selectDomainsItemsByGroup",
  TransferDetails
);
