import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";

import JobNewAttachmentsSection from "./job-new-attachments-section";
import JobNewGeneralSection from "./job-new-general-section";
import JobNewPocSection from "./job-new-poc-section";
import { defaultJobParams } from "./job-new";
import { find, cond } from "lodash";

const JobNewForm = ({
  doJobsNewFormStateUpdate,
  jobsByRoute,
  actions,
  jobsNewFormState,
  doUpdateRelativeUrl,
  doJobsSave,
  tokenPayload,
}) => {
  const [state, setState] = useState({ ...jobsByRoute, ...jobsNewFormState });

  const buildArgs = async (e) => {
    const {
      title,
      body,
      startDate,
      endDate,
      tags,
      addr,
      notes,
      poc_name,
      poc_phone,
      poc_email,
      alt_poc_name,
      alt_poc_email,
      alt_poc_phone,
      requirements,
      id,
    } = jobsNewFormState;
    const tokenKeyCloakId = tokenPayload.sub;
    let args = {
      ...jobsByRoute,
      ...jobsNewFormState,
      id: undefined,
      title: title,
      body: body,
      tags: tags.length > 0 ? tags.join("~") : "",
      date_start: startDate,
      date_end: endDate,
      addr: addr,
      poc: [poc_name, poc_email, poc_phone].join("~"),
      alt_poc: [alt_poc_name, alt_poc_email, alt_poc_phone].join("~"),
      notes: notes,
      requirements: requirements,
      last_comment_date: new Date(2000, 1, 1, 12, 0, 0),
      create_date: new Date(),
      created_by: tokenKeyCloakId,
      date_start_tentative: jobsNewFormState.datesTentative === true ? 1 : 0,
    };
    if (e.currentTarget.name === "publish") {
      args = {
        ...args,
        published_date: new Date(),
        published: 1,
        expired: 0,
      };
    }
    if (e.currentTarget.name === "draft") {
      args = { ...args, published_date: null, published: 0, id: id };
    }
    if (e.currentTarget.name === "save") {
      args = { ...args, id: id };
    }
    return args;
  };

  const handleJobAction = async (e) => {
    const { id } = jobsNewFormState;
    let actionDetails = find(actions, { name: e.currentTarget.name });
    if (e.currentTarget.name === "cancel") {
      if (actionDetails && actionDetails.onAction) {
        actionDetails.onAction();
      }
      return;
    }
    let args = await buildArgs(e);

    if (!id) {
      await doJobsSave(Object.assign({}, args));
      doJobsNewFormStateUpdate(defaultJobParams);
      doUpdateRelativeUrl("/job-board");
    } else {
      await doJobsSave(Object.assign({}, args));

      if (actionDetails && actionDetails.onAction) {
        actionDetails.onAction();
      }
      doJobsNewFormStateUpdate(defaultJobParams);
    }

    return;
  };

  useEffect(() => {
    if (jobsNewFormState) {
      setState({ ...state, ...jobsByRoute, ...jobsNewFormState });
    }
  }, [jobsNewFormState]);

  const renderLastSavedText = () => {
    if (state.published === 0) {
      return (
        <div className="d-flex flex-row">
          <span>
            <span className="font-weight-bold mr-2">Status:</span>
            Drafted
          </span>
          <span className="font-weight-bold ml-auto mr-2">Last saved on:</span>
          {new Date(state.last_updated_date).toLocaleString()}
        </div>
      );
    }
  };
  return (
    <div className="w-100 d-flex flex-column" style={{ minHeight: "75vh" }}>
      <div className="alert alert-info mt-auto mb-2 text-center">
        <h5>
          <span className="mdi mdi-alert-outline mr-3" />
          Be sure to save any changes before leaving page
        </h5>
      </div>
      <div className="col-sm-8 d-flex flex-column align-self-center justify-content-center">
        <div className="d-flex justify-content-between my-3">
          <div className="clearfix ml-auto">
            {actions.map((act, idx) => {
              let isInvalid = () => false;
              if (act.disabled) isInvalid = cond(act.disabled);
              return (
                <button
                  key={idx}
                  className={act.className}
                  type="button"
                  name={act.name}
                  onClick={handleJobAction}
                  disabled={isInvalid(jobsNewFormState)}
                >
                  {act.text}
                </button>
              );
            })}
          </div>
        </div>

        {renderLastSavedText()}
        <div
          className="card d-flex flex-column"
          style={{ overflow: "visible" }}
        >
          <ul className="list-group list-group-flush">
            <JobNewGeneralSection />
            <JobNewPocSection />
            <JobNewAttachmentsSection />
          </ul>
        </div>
      </div>
    </div>
  );
};
export default connect(
  "doJobsNewFormStateUpdate",
  "selectJobsNewFormState",
  "selectJobsByRoute",
  "doJobsSave",
  "selectTokenPayload",
  "doUpdateRelativeUrl",
  JobNewForm
);
