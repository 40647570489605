import React from "react";
import { connect } from "redux-bundler-react";
import Loader from "../../app-components/loader";
import Login from "./login";
import Register from "./register";
import ModalContainer from "../modal-container/container";

class AuthProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      img: `${import.meta.env.VITE_URL_BASE_PATH}/bg${Math.ceil(
        Math.random() * 6
      )}_r.jpg`,
    };
  }

  renderLoader() {
    const { isLoggedIn, profileIsLoading, isLoggingIn } = this.props;
    if (!isLoggingIn || !isLoggedIn) return null;
    return <Loader opt="dissolve-cube" />;
  }

  renderLogin() {
    const { isLoggedIn, isLoggingIn } = this.props;
    if (isLoggedIn || isLoggingIn) return null;
    return <Login />;
  }

  renderRegister() {
    const { profileIsLoading, isLoggedIn, isLoggingIn, profileFetchCount } =
      this.props;
    if (!isLoggedIn) return null;
    if (profileIsLoading || isLoggingIn) return null;
    if (profileFetchCount === 0) return null;
    return <Register />;
  }

  renderWithBackground() {
    const { img } = this.state;
    const bgStyle = {
      backgroundImage: `url(${img})`,
    };
    return (
      <div
        className="app cover bg-image flex-row align-items-center"
        style={bgStyle}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              {this.renderLogin()}
              {this.renderRegister()}
              {this.renderLoader()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderApp() {
    const { isLoggedIn, profileIsLoading, profileItems, children } = this.props;
    if (isLoggedIn) {
      if (profileIsLoading || !profileItems.length) {
        return this.renderWithBackground();
      }
      return <>{children}</>;
    } else {
      return this.renderWithBackground();
    }
  }

  render() {
    return (
      <>
        <ModalContainer />
        {this.renderApp()}
      </>
    );
  }
}

export default connect(
  "selectIsLoggedIn",
  "selectIsLoggingIn",
  "selectPublicFolder",
  "selectProfileIsLoading",
  "selectProfileFetchCount",
  "selectProfileItems",
  AuthProvider
);
