import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import {
  subYears,
  subMonths,
  subDays,
  addDays,
  startOfMonth,
  endOfMonth,
} from "date-fns";

import "react-datepicker/dist/react-datepicker.css";

export default ({ title, onChange, fromDate, toDate, column }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const [from, setFrom] = useState(fromDate || null);
  const handleFromChange = (newFrom) => {
    onChange({ [column]: { from: newFrom, to: to } });
    setFrom(newFrom);
  };

  const [to, setTo] = useState(toDate || null);
  const handleToChange = (newTo) => {
    onChange({ [column]: { from: from, to: newTo } });
    setTo(newTo);
  };

  const handleClear = () => {
    onChange({ [column]: { from: null, to: null } });
    setFrom(null);
    setTo(null);
  };

  let fromControl = useRef(null);
  const openFromControl = () => {
    if (!to && fromControl) fromControl.current.setOpen(true);
  };

  const hasCondition = !!from || !!to;

  return (
    <div className="card mb-0">
      <div className="card-header" onDoubleClick={toggleExpanded}>
        {title}
        <i
          className={`mdi mdi-filter-variant text-${
            hasCondition ? "primary" : "muted"
          } pl-2`}
        ></i>
        <div className="card-header-actions">
          <span onClick={toggleExpanded}>
            <i
              className={`mdi mdi-${
                expanded ? "chevron-down" : "chevron-right"
              }`}
            ></i>
          </span>
        </div>
      </div>
      {expanded ? (
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <div className="">
                <DatePicker
                  className="form-control"
                  todayButton="Today"
                  selected={from}
                  onChange={handleFromChange}
                  onBlur={openFromControl}
                  shouldCloseOnSelect={true}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  selectsStart
                  startDate={from}
                  endDate={to}
                />
                <div>To</div>
                <DatePicker
                  ref={fromControl}
                  className="form-control"
                  todayButton="Today"
                  selected={to}
                  onChange={handleToChange}
                  shouldCloseOnSelect={true}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  selectsEnd
                  startDate={from}
                  endDate={to}
                  minDate={from}
                />
                <small className="form-text text-muted">
                  Search for records within this date range
                </small>
                <button
                  style={{ position: "absolute", bottom: "0" }}
                  className="btn btn-sm btn-secondary"
                  onClick={handleClear}
                >
                  Clear
                </button>
              </div>
            </div>
            <div className="col-6">
              <ul className="list-group">
                <li
                  className="list-group-item list-group-item-action overflow-ellipsis pointer"
                  style={{ padding: "5px 10px" }}
                  onClick={() => {
                    const now = new Date();
                    const newFrom = subYears(now, 1);
                    setFrom(newFrom);
                    setTo(now);
                    onChange({ [column]: { from: newFrom, to: now } });
                  }}
                >
                  <small>In the last year</small>
                </li>
                <li
                  className="list-group-item list-group-item-action overflow-ellipsis pointer"
                  style={{ padding: "5px 10px" }}
                  onClick={() => {
                    const now = new Date();
                    const newFrom = subMonths(now, 6);
                    setFrom(newFrom);
                    setTo(now);
                    onChange({ [column]: { from: newFrom, to: now } });
                  }}
                >
                  <small>In the last 6 months</small>
                </li>
                <li
                  className="list-group-item list-group-item-action overflow-ellipsis pointer"
                  style={{ padding: "5px 10px" }}
                  onClick={() => {
                    const now = new Date();
                    const newFrom = subDays(now, 30);
                    setFrom(newFrom);
                    setTo(now);
                    onChange({ [column]: { from: newFrom, to: now } });
                  }}
                >
                  <small>In the last 30 days</small>
                </li>
                <li
                  className="list-group-item list-group-item-action overflow-ellipsis pointer"
                  style={{ padding: "5px 10px" }}
                  onClick={() => {
                    const now = new Date();
                    const newFrom = startOfMonth(now);
                    const newTo = endOfMonth(now);
                    setFrom(newFrom);
                    setTo(newTo);
                    onChange({ [column]: { from: newFrom, to: newTo } });
                  }}
                >
                  <small>This month</small>
                </li>
                <li
                  className="list-group-item list-group-item-action overflow-ellipsis pointer"
                  style={{ padding: "5px 10px" }}
                  onClick={() => {
                    const now = new Date();
                    const newTo = addDays(now, 30);
                    setFrom(now);
                    setTo(newTo);
                    onChange({ [column]: { from: now, to: newTo } });
                  }}
                >
                  <small>30 days out</small>
                </li>
                <li
                  className="list-group-item list-group-item-action overflow-ellipsis pointer"
                  style={{ padding: "5px 10px" }}
                  onClick={() => {
                    const now = new Date();
                    const newTo = addDays(now, 60);
                    setFrom(now);
                    setTo(newTo);
                    onChange({ [column]: { from: now, to: newTo } });
                  }}
                >
                  <small>60 days out</small>
                </li>
                <li
                  className="list-group-item list-group-item-action overflow-ellipsis pointer"
                  style={{ padding: "5px 10px" }}
                  onClick={() => {
                    const now = new Date();
                    const newTo = addDays(now, 90);
                    setFrom(now);
                    setTo(newTo);
                    onChange({ [column]: { from: now, to: newTo } });
                  }}
                >
                  <small>90 days out</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
