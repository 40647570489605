import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";

export default createRestBundle({
  name: "transfers",
  uid: "slug",
  prefetch: true,
  staleAfter: 30000,
  persist: false,
  routeParam: "transferSlug",
  getTemplate: "/orgs/:orgSlug/transfers",
  putTemplate: "/orgs/:orgSlug/transfers/:item.slug",
  postTemplate: "/orgs/:orgSlug/transfers",
  deleteTemplate: "/orgs/:orgSlug/transfers/:item.slug",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN"],
  forceFetchActions: ["TRANSFERS_SAVE_FINISHED"],
  addons: {
    doTransfersDownloadAsCsv: () => ({ store }) => {
      const ignoreFields = ["last_updated_by", "deleted", "equipment_status", "slug"];
      const data = store.selectTransfersItems();
      if (!data || !data.length) return null;
      const csvRows = [];

      const fixCommas = (value) => {
        if (value && typeof value === "string" && value.indexOf(",") !== -1)
          return `"${value}"`;
        return value;
      };

      const setHeaders = (item) => {
        const head = [];
        Object.keys(item).forEach((key) => {
          if (!key.includes('id')) { 
            if(ignoreFields.indexOf(key) === -1) {
            head.push(fixCommas(key.toUpperCase()));
          }
        }
        });
        csvRows.push(head.join(","));
      };

      data.forEach((item, i) => {
        if (i === 0) setHeaders(item);
        const row = [];
        Object.keys(item).forEach((key) => {
          if (!key.includes('id')) { 
            if(ignoreFields.indexOf(key) === -1) {
               row.push(fixCommas(item[key]));
            }
          }
              });
        csvRows.push(row.join(","));
      });

      const csv = csvRows.join("\n");
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, "export.csv");
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "export.csv");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    selectTransfersAllEquipment: createSelector(
      "selectOrgsByRoute",
      "selectAllEquipmentItems",
      "selectTransfersEquipmentItemsObject",
      (org, allEquipmentItems, transfersEquipmentItemsObject) => {
        if (!org) return [];
        const beingTransfered = Object.keys(transfersEquipmentItemsObject);
        return allEquipmentItems
          .filter((item) => {
            if (beingTransfered.indexOf(item.id) !== -1) return false;
            if (item.org_id !== org.id) return false;
            return true;
          })
          .sort((a, b) => {
            if (a.type > b.type) return 1;
            if (a.type < b.type) return -1;
            return 0;
          });
      }
    ),
  },
});
