import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";
import CopyMissionDialog from "./copy-mission-dialog";
import MissionStatusFilter from "../../_shared/mission-status-filter";

class ActionsDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.copyMission = this.copyMission.bind(this);
  }

  toggleShow(e) {
    this.setState({
      show: !this.state.show,
    });
  }

  copyMission() {
    const { doDialogOpen } = this.props;
    doDialogOpen({ content: CopyMissionDialog, props: {} });
  }

  render() {
    const {
      doPrintTestLegacy,
      doMissionsApprovalDelete,
      doMissionsFetch,
      doMissionsApprovalFetch,
      doMissionsApprovalNotesFetch,
    } = this.props;
    const { show } = this.state;
    const btnGroupClass = classnames({
      "btn-group": true,
      show: show,
    });
    const dropdownMenuClass = classnames({
      "dropdown-menu": true,
      "dropdown-menu-right": true,
      show: show,
    });
    return (
      <div className={btnGroupClass}>
        <button
          onClick={this.toggleShow}
          className="btn btn-sm btn-info dropdown-toggle"
          type="button"
          data-toggle="dropdown"
        >
          Mission Actions
        </button>
        <div
          onClick={this.toggleShow}
          style={{
            display: show ? "block" : "none",
            position: "fixed",
            zIndex: 999,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        ></div>
        <div
          className={dropdownMenuClass}
          x-placement="bottom-start"
          style={{
            position: "absolute",
            willChange: "transform",
            top: "0px",
            right: "0px",
            transform: "translate3d(0px, 26px, 0px)",
          }}
          title="Copying a mission has been temporarily disabled while we work to improve the feature."
        >
          <button
            className="dropdown-item dropdown-item-styled"
            onClick={this.copyMission}
          >
            <i className="mdi mdi-new-box mr-2"></i> Copy to New Mission
          </button>
          <div
            className="dropdown-item dropdown-item-styled"
            onClick={doPrintTestLegacy}
          >
            <i className="mdi mdi-printer mr-2"></i> Print (Legacy)
          </div>
          <MissionStatusFilter
            denyStatus={{
              mission_status: ["complete", "archived"],
            }}
          >
            <div
              className="dropdown-item dropdown-item-styled"
              onClick={() =>
                doMissionsApprovalDelete(
                  {},
                  () => {
                    doMissionsApprovalFetch();
                    doMissionsApprovalNotesFetch();
                    doMissionsFetch();
                  },
                  true
                )
              }
            >
              <i className="mdi mdi-lock-reset mr-2"></i> Unlock
            </div>
          </MissionStatusFilter>
        </div>
      </div>
    );
  }
}

export default connect(
  "doPrintTestLegacy",
  "doMissionsFetch",
  "doMissionsApprovalDelete",
  "doMissionsApprovalFetch",
  "doMissionsApprovalNotesFetch",
  "doMissionsSave",
  "doUpdateRelativeUrl",
  "doDialogOpen",
  "selectMissionsByRoute",
  "selectOrgsByRoute",
  ActionsDropdown
);
