import React from "react";
import { Provider } from "redux-bundler-react";
import { getNavHelper } from "internal-nav-helper";
import AppFilters from "./app-containers/app-filters";

class App extends React.Component {
  render() {
    const { store } = this.props;
    return (
      <Provider store={store}>
        <div onClick={getNavHelper(store.doUpdateRelativeUrl)}>
          <AppFilters />
        </div>
      </Provider>
    );
  }
}

export default App;
