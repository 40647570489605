import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Draw from "ol/interaction/Draw";
import Modify from "ol/interaction/Modify";
import Select from "ol/interaction/Select";
import GeoJSON from "ol/format/GeoJSON";
import Circle from "ol/style/Circle";
import { debounce } from "lodash";
import { createSelector } from "redux-bundler";


const geoJSON = new GeoJSON({
  datProjection: "EPSG:4326",
  featureProjection: "EPSG:3857"
});

export default {
  name: "mapLz",

  getReducer: () => {
    const initialData = {
      layer: null,
      draw: null,
      modify: null,
      select: null,
      version: 0,
      actions: [],
      _isLoading: false,
      _shouldAddToMap: false,
      _shouldInitialize: true,
      _shouldLoadFromMission: false
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "MAP_LZ_ADD_TO_MAP_STARTED":
        case "MAP_LZ_ADD_TO_MAP_FINISHED":
        case "MAP_LZ_INITIALIZE_STARTED":
        case "MAP_LZ_INITIALIZE_FINISHED":
        case "MAP_LZ_LOAD_FROM_MISSION_STARTED":
        case "MAP_LZ_LOAD_FROM_MISSION_FINISHED":
        case "MAP_LZ_UPDATE_ACTIONS":
          return Object.assign({}, state, payload);
        case "MAP_INITIALIZED":
          return Object.assign({}, state, { _shouldAddToMap: true });
        case "MISSIONSLZ_FETCH_FINISHED":
          return Object.assign({}, state, { _shouldLoadFromMission: true });
        default:
          return state;
      }
    };
  },

  doMapLzAddToMap: () => ({ dispatch, store }) => {
    dispatch({
      type: "MAP_LZ_ADD_TO_MAP_STARTED",
      payload: {
        _shouldAddToMap: false
      }
    });

    const map = store.selectMap();
    const lzLayer = store.selectMapLzLayer();
    map.addLayer(lzLayer);

    dispatch({
      type: "MAP_LZ_ADD_TO_MAP_FINISHED"
    });
  },

  doMapLzInitialize: () => ({ dispatch, store }) => {
    dispatch({
      type: "MAP_LZ_INITIALIZE_STARTED",
      payload: {
        _shouldInitialize: false
      }
    });

    const src = new VectorSource();
    const layer = new VectorLayer({
      source: src,
      style: new Style({
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: "#ffcc33"
          }),
          stroke: new Stroke({
            color: "#ffffff",
            width: 3
          })
        })
      })
    });
    const draw = new Draw({
      source: src,
      type: "Point"
    });
    const modify = new Modify({
      source: src
    });
    const select = new Select({
      layers: [layer]
    });

    src.on("addfeature", (e) => {
      if (e.feature.values_.src !== 'mission' && e.feature.values_.src !== undefined) e.feature.values_.src = 'new';
      if (!e.feature.values_.undo) store.doMapLzUpdateActions(store.selectMapLzLayer(), store.selectMapLzSelect(), e, 'add');
      store.doMapLzSave(e);
    });
    src.on("changefeature", e => {
      debounce(store.doMapLzSave, 500);
    });
    src.on("removefeature", store.doMapLzDelete);
    dispatch({
      type: "MAP_LZ_INITIALIZE_FINISHED",
      payload: {
        layer: layer,
        draw: draw,
        modify: modify,
        select: select
      }
    });
  },

  doMapLzClearSelected: () => ({ store }) => {
    const selectInteraction = store.selectMapLzSelect();
    const features = selectInteraction.getFeatures();
    features.clear();
  },

  doMapLzDeleteSelected: () => ({ store }) => {
    const layer = store.selectMapLzLayer();
    const source = layer.getSource();
    const selectInteraction = store.selectMapLzSelect();
    const features = selectInteraction.getFeatures();
    features.forEach(feature => {
      store.doMapLzUpdateActions(layer, selectInteraction, feature, 'delete');
      source.removeFeature(feature);
    });
    features.clear();
  },

  doMapLzAddInteraction: () => ({ store }) => {
    const draw = store.selectMapLzDraw();
    const modify = store.selectMapLzModify();
    const select = store.selectMapLzSelect();
    store.doMapInteractionsAdd([draw, modify, select]);
  },

  doMapLzLoadFromMission: () => ({ dispatch, store }) => {
    dispatch({
      type: "MAP_LZ_LOAD_FROM_MISSION_STARTED",
      payload: {
        _shouldLoadFromMission: false,
        _isLoading: true
      }
    });

    const layer = store.selectMapLzLayer();
    const src = layer.getSource();
    src.clear();

    const data = store.selectMissionsLzItems();
    if (data && data.length) {
      const features = geoJSON.readFeatures({
        type: "FeatureCollection",
        features: data.map(f => {
          return {
            type: "Feature",
            geometry: JSON.parse(f.shape),
            properties: { id: f.id, src: 'mission' }
          };
        })
      });
      src.addFeatures(features);
    }

    dispatch({
      type: "MAP_LZ_LOAD_FROM_MISSION_FINISHED",
      payload: {
        _isLoading: false
      }
    });
  },

  doMapLzUpdateLoadFromMission: () => ({ dispatch, store }) => {
    dispatch({
      type: "MAP_LZ_LOAD_FROM_MISSION_STARTED",
      payload: {
        _shouldLoadFromMission: false,
        _isLoading: true
      }
    });

    const layer = store.selectMapLzLayer();
    const src = layer.getSource();
    src.clear();

    const data = store.selectMissionsLzItems();
    if (data && data.length) {
      const features = geoJSON.readFeatures({
        type: "FeatureCollection",
        features: data.map(f => {
          return {
            type: "Feature",
            geometry: JSON.parse(f.shape),
            properties: { id: f.id, src: f.src, undo: f.undo }
          };
        })
      });
      src.addFeatures(features);
    }

    dispatch({
      type: "MAP_LZ_LOAD_FROM_MISSION_FINISHED",
      payload: {
        _isLoading: false
      }
    });
  },

  doMapLzSave: (e, undo) => ({ dispatch, store }) => {
    let feature = e.feature ? e.feature : e;
    const isLoading = store.selectMapLzIsLoading();
    if (isLoading) return;
    const geojson = JSON.parse(geoJSON.writeFeature(feature));
    const data = {
      id:
        geojson.properties && geojson.properties.id
          ? geojson.properties.id
          : null,
      shape: JSON.stringify(geojson.geometry),
      src: 'saved',
      undo: undo
    };
    store.doMissionsLzSave(data, store.doMapLzUpdateLoadFromMission, true);
    store.doMapInteractionsClear();
    store.doMapLzClearActions();
    store.doMapLzAddInteraction();
  },

  doMapLzUndoAdd: () => ({ dispatch, store }) => {
    let history = store.selectMapLzActions();
    let lastAction = history.pop();
    dispatch({
      type: "MAP_LZ_UPDATE_ACTIONS",
      payload: {
        actions: [...history]
      }
    })
    if (lastAction.type === 'delete') {
      store.doMapLzSave(lastAction.feature, 'undo');
    } else {
      if (lastAction.feature.feature.values_.src !== 'new') return;
      lastAction && lastAction.layer && lastAction.layer.getSource().removeFeature(lastAction.feature.feature);
    }
  },

  doMapLzDelete: e => ({ dispatch, store }) => {
    const isLoading = store.selectMapLzIsLoading();
    if (isLoading) return;
    const geojson = JSON.parse(geoJSON.writeFeature(e.feature));
    const data = {
      id:
        geojson.properties && geojson.properties.id
          ? geojson.properties.id
          : null,
      shape: JSON.stringify(geojson.geometry)
    };
    store.doMissionsLzDelete(data);
  },

  doMapLzClearActions: () => ({ dispatch, store }) => {
    dispatch({
      type: "MAP_LZ_UPDATE_ACTIONS",
      payload: {
        actions: []
      }
    })
  },
  doMapLzUpdateActions: (layer, interaction, feature, type) => ({ dispatch, store }) => {
    let history = store.selectMapLzActions();
    dispatch({
      type: "MAP_LZ_UPDATE_ACTIONS",
      payload: {
        actions: [...history, { layer, interaction, feature, type }]
      }
    })
  },

  selectMapLzActions: state => state.mapLz.actions,

  selectMapLzInteractionsActive: createSelector(
    "selectMapLzDraw",
    "selectMapInteractions",
    (draw, interactions) => {
      return interactions.indexOf(draw) !== -1;
    }
  ),

  selectMapLzIsLoading: state => {
    return state.mapLz._isLoading;
  },

  selectMapLzLayer: state => {
    return state.mapLz.layer;
  },

  selectMapLzDraw: state => {
    return state.mapLz.draw;
  },

  selectMapLzModify: state => {
    return state.mapLz.modify;
  },

  selectMapLzSelect: state => {
    return state.mapLz.select;
  },

  selectMapLzShouldLoad: state => {
    return state.mapLz._shouldLoadFromMission;
  },

  reactLzShouldInitialize: state => {
    if (state.mapLz._shouldInitialize)
      return { actionCreator: "doMapLzInitialize" };
  },

  reactLzShouldLoadFromMission: state => {
    if (state.mapLz._shouldLoadFromMission)
      return { actionCreator: "doMapLzLoadFromMission" };
  },

  reactLzShouldAddToMap: state => {
    if (state.mapLz._shouldAddToMap)
      return { actionCreator: "doMapLzAddToMap" };
  }
};
