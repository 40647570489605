import React from "react";
import List from "./list";
import Picker from "./picker";
import RoleFilter from "../../../app-containers/context-providers/role-filter";
import ReadOnly from "../../_shared/read-only-notification";

class SelectorCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: props.editing || false,
    };
    this.enableEditing = this.enableEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.renderPicker = this.renderPicker.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  enableEditing() {
    this.setState({
      editing: true,
    });
  }

  cancelEditing() {
    this.setState({
      editing: false,
    });
  }

  renderPicker() {
    const { editing } = this.state;
    const { items, onAdd, pickFrom, itemRenderFn, pickerRenderFn } = this.props;
    if (!editing) return null;
    const pickedIds = items.map((item) => {
      return item.id;
    });
    return (
      <Picker
        items={pickFrom.filter((item) => {
          return pickedIds.indexOf(item.id) === -1;
        })}
        itemRenderFn={pickerRenderFn ? pickerRenderFn : itemRenderFn}
        onAdd={(val) => {
          onAdd(val);
        }}
      />
    );
  }

  renderActions() {
    const { editing } = this.state;
    const { hideDoneButton } = this.props;
    if (!editing || hideDoneButton) return null;
    return (
      <div className="float-right mt-2">
        <button
          onClick={this.cancelEditing}
          className="btn btn-sm btn-secondary"
        >
          Done
        </button>
      </div>
    );
  }

  render() {
    const { editing } = this.state;
    const {
      items,
      isLoading,
      onRemove,
      title,
      iconClass,
      itemRenderFn,
      allowRoles,
      allowEditing,
    } = this.props;
    return (
      <div className="card">
        <div className="card-header">
          <div className="float-right">
            {allowEditing ? (
              <RoleFilter allowRoles={allowRoles} alt={ReadOnly}>
                <button
                  disabled={editing}
                  className="btn btn-sm btn-ghost-primary"
                  onClick={this.enableEditing}
                >
                  <i className="mdi mdi-pencil icon-inline"></i>
                  Edit
                </button>
              </RoleFilter>
            ) : null}
          </div>
          <i className={iconClass} /> {title}
        </div>
        <div className="card-body">
          <List
            items={items}
            editing={editing}
            isLoading={isLoading}
            onRemove={onRemove}
            itemRenderFn={itemRenderFn}
          />
          {this.renderPicker()}
          {this.renderActions()}
        </div>
      </div>
    );
  }
}

export default SelectorCard;
/**
 * Use like
 * <SelectorCard items={[]} pickFrom={[]} onAdd={fn()} onRemove={fn()} title={""} iconClass={""} itemRenderFn={fn(item)} />
 */
