export default [
  {
    id: "MapBoxAerial",
    name: "Satellite",
    url: "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoidXNhY2UiLCJhIjoiY2wwbXV1bGIzMTlycDNpcmtnNHJhMDNhYiJ9.tKNUaXSTYmou55_dwqtQtA",
    attributions:
      'Imagery from <a href="https://mapbox.com/about/maps/">MapBox</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    subdomains: "abcd",
    serviceType: "XYZ",
  },
  {
    id: "SectionalCharts",
    name: "Sectionals",
    url: "https://tiles.arcgis.com/tiles/ssFJjBXIUyZDrSYZ/arcgis/rest/services/VFR_Sectional/MapServer/tile/{z}/{y}/{x}",
    attributions: "",
    serviceType: "XYZ",
  },
  {
    id: "MapBoxStreets",
    name: "Street Map",
    url: "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidXNhY2UiLCJhIjoiY2wwbXV1bGIzMTlycDNpcmtnNHJhMDNhYiJ9.tKNUaXSTYmou55_dwqtQtA",
    attributions:
      'Imagery from <a href="https://mapbox.com/about/maps/">MapBox</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    subdomains: "abcd",
    serviceType: "XYZ",
  },
];
