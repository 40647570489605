import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";
import { differenceInDays } from "date-fns";
import drone from "./drone.jpg";

class AircraftCard extends React.Component {
  render() {
    const { item, orgsByRoute, directory = "aircraft", renderPendingBanner } = this.props;
    const imgUrl = !!item.image_url ? item.image_url : drone;
    const operational = item.operational;
    let awrStatus = new Date(item.awr_expire_date) >= new Date();

    const operationalIconClass = classnames({
      "mdi mdi-24px": true,
      "text-success mdi-check-circle-outline": operational === "FMC",
      "text-warning mdi-circle-half-full": operational === "PMC",
      "text-danger mdi-close-circle-outline":
        operational === "NMC" || !operational,
    });

    const awrIconClass = classnames({
      "mdi mdi-24px": true,
      "text-success mdi-check-circle-outline": awrStatus,
      "text-danger mdi-close-circle-outline": !awrStatus,
    });
    const approvedIconClass = classnames({
      "mdi mdi-24px": true,
      "text-danger mdi-close-circle-outline": !item.usace_approved,
      "text-success  mdi-check-circle-outline": item.usace_approved === true,
    });

    const alertHeaderClass = classnames({
      "alert w-100 rounded-top text-center":
        item.isRequest || item.deactivation_type || renderPendingBanner,
      "alert-primary": !item.deactivation_type && item.status === "New",
      "alert-info": item.deactivation_type === "Display Only",
      "alert-warning": renderPendingBanner,
      "px-0": true,
      "m-0": true,
    });
    const requestStatusIconClass = classnames({
      "mdi mr-1": true,
      "mdi-update": item.status === "New" || renderPendingBanner,
      "mdi-lock-outline": item.deactivation_type
    });
    const linkClass = classnames({
      "text-center d-flex": item.isRequest || item.iconBackground,
    });
    let actionText = "";
    if (item.action === "Add") {
      actionText = "Add Request";
    }
    else {
      if (item.deactivation_type === 'Display Only') actionText = "Display Only";
      else actionText = "Pending Deactivation";
    }


    let lessThan30DaysOld =
      differenceInDays(Date.now(), new Date(item.create_date)) <= 30;

    return (
      <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
        <a
          className={linkClass}
          href={`/${orgsByRoute.slug}/${directory}/${item.slug}`}
          style={{ textDecoration: "none", color: "black" }}
        >
          <div
            className="d-flex flex-column w-100 bg-white p-3"
            style={{
              minHeight: "100%",
              position: "relative",
              borderRadius: 8,
              overflow: "hidden",
            }}
          >
            {(item.isRequest || item.deactivation_type || renderPendingBanner) && (
              <div
                style={{
                  width: "250px",
                  height: "250px",
                  top: "50px",
                  right: "-55px",
                  position: "absolute",
                }}
              >
                <div
                  style={{
                    transform: "rotate(45deg)",
                  }}
                  className={alertHeaderClass}
                >
                  <h6 className="mb-0">
                    <span className={requestStatusIconClass} /> {actionText}
                  </h6>
                </div>
              </div>
            )}
            <div
              className="d-flex w-100 border-bottom mb-1"
              style={{ height: 150 }}
            >
              <div className="w-50">
                {false ? (
                  <span
                    className=" ms ms-drone-wing h-100 w-100 d-flex align-items-center justify-content-center"
                    style={{ fontSize: "36px" }}
                  />
                ) : (
                  <div className="w-100 d-flex justify-content-center pb-2 pr-2">
                    <img
                      style={{
                        boxShadow: "0 0 8px #c8ced3",
                        // padding: 8,
                        borderRadius: 8,
                        // marginTop: 8,
                        height: 120,
                        width: "100%",
                      }}
                      src={
                        item.image
                          ? `data:image/jpeg;base64,${item.image}`
                          : imgUrl
                      }
                    />
                  </div>
                )}
              </div>
              <div className="w-50">
                <div className="d-flex flex-column ml-2">
                  <div className="d-flex align-items-center mb-1">
                    <i className={approvedIconClass} />{" "}
                    <p className="pl-2 m-0">
                      {item.usace_approved ? "Approved" : "Unapproved"}
                    </p>
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <i className={awrIconClass} />{" "}
                    <p className="pl-2 m-0">
                      {awrStatus ? "AWR Current" : "AWR Expired"}
                    </p>
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <i className={operationalIconClass} />{" "}
                    <p className="pl-2 m-0">{operational ?? "NMC"}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column" style={{ height: 100 }}>
              <div className="row border-right-0 p-0">
                <div
                  className="mx-auto text-value"
                  style={{ textAlign: "center" }}
                  title={item.name}
                >
                  {lessThan30DaysOld && (
                    <span className="mdi mdi-new-box text-primary mr-1" />
                  )}
                  {item.name}
                </div>
              </div>
              <div
                className="overflow-ellipsis d-flex flex-column"
                style={{ textAlign: "center" }}
              >
                <div className="text-uppercase text-muted small">
                  {`${item.make} ${item.model}`}
                </div>
                <div className="d-flex justify-content-center text-uppercase text-muted small">
                  <span className="font-weight-bolder">SN: </span>
                  <div
                    className="overflow-ellipsis"
                    style={{ direction: "rtl" }}
                  >
                    {item.serial_no}
                  </div>
                </div>
                {item.barcode && (
                  <div className="text-uppercase text-muted small">
                    <span className="font-weight-bolder">BARCODE: </span>
                    <span className="overflow-ellipsis">{item.barcode}</span>
                  </div>
                )}
                {item.hq_avn_barcode && (
                  <div className="text-uppercase text-muted small">
                    <span className="font-weight-bolder">HQ BARCODE: </span>
                    <span className="overflow-ellipsis">
                      {item.hq_avn_barcode}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default connect("selectOrgsByRoute", "selectPublicFolder", AircraftCard);
