import createRestBundle from "./create-rest-bundle";
export default createRestBundle({
    name: "messagesComments",
    uid: "id",
    prefetch: true,
    persist: true,
    routeParam: null,
    getTemplate: "/orgs/:orgSlug/message-board/:messageSlug/comments",
    putTemplate: "/orgs/:orgSlug/message-board/:messageSlug/comments/:item.id",
    postTemplate: "/orgs/:orgSlug/message-board/:messageSlug/comments",
    deleteTemplate: "/orgs/:orgSlug/message-board/:messageSlug/comments/:item.id",

    fetchActions: [],
    forceFetchActions: ["MESSAGES_FETCH_STARTED", "MESSAGES_ADJACENT_PAGE_LOADING", "MESSAGESCOMMENTS_SAVE_FINISHED", "MESSAGESCOMMENTS_DELETE_FINISHED"],
    reduceFurther: (state, { type, payload }) => {
        switch (type) {
            case "MESSAGES_ADJACENT_PAGE_LOADING":
                return Object.assign({}, state, { ...payload, _isLoading: true, _shouldFetch: true });
            default:
                return state;
        }
    },
});