import React from "react";
import { connect } from "redux-bundler-react";
import { find, isEmpty } from "lodash";
import { gcs } from "../../models";
import Loader from "../../app-components/loader";
import DeleteButton from "../_shared/delete-button/delete-button";
import SchemaForm from "../../app-components/schema-form/schema-form";
import PlatformCard from "../_shared/platforms-card/platforms-card";
import GcsSoftwareCard from "./software-card/software-card";
import MissionsCard from "../_shared/missions-card/missions-card";
import FileUploadCard from "../_shared/file-upload-card/file-upload-card";
import RoleFilter from "../../app-containers/context-providers/role-filter";
import OrgFilter from "../../app-containers/context-providers/org-filter";
import ReadOnly from "../_shared/read-only-notification";
import NotesCard from "../_shared/notes-card/note-card";
import ApprovedComponentsCard from "../_shared/approved-components-card";
import gcsDeactivationDialog from "./gcs-deactivation-dialog";

class GcsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.save = this.save.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.enableEditing = this.enableEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.openDeactivationDialog = this.openDeactivationDialog.bind(this);
  }

  save() {
    const { doGcsSave } = this.props;
    if (this.form.isValid()) {
      const data = this.form.serialize();
      this.setState(
        {
          editing: false,
        },
        () => {
          doGcsSave(data);
        }
      );
    }
  }

  enableEditing() {
    this.setState({
      editing: true,
    });
  }

  cancelEditing() {
    const { gcsByRoute } = this.props;
    this.setState(
      {
        editing: false,
      },
      () => {
        this.form.reset({ data: gcsByRoute, schema: gcs });
      }
    );
  }

  handleDelete() {
    const { doGcsDelete, doUpdateRelativeUrl, routeParams, gcsByRoute } =
      this.props;
    this.setState(
      {
        editing: false,
      },
      () => {
        doGcsDelete(gcsByRoute, () => {
          doUpdateRelativeUrl(`/${routeParams.orgSlug}/gcs`);
        });
      }
    );
  }

  openDeactivationDialog() {
    const { doDialogOpen } = this.props;
    doDialogOpen({
      content: gcsDeactivationDialog,
      props: this.props,
      size: "xl",
    });
    this.cancelEditing();
  }

  renderForm() {
    const { editing } = this.state;
    const { gcsByRoute, userIsAtpm } = this.props;
    if (isEmpty(gcsByRoute)) return <Loader />;
    return (
      <SchemaForm
        ref={(el) => {
          this.form = el;
        }}
        inline={true}
        displayOnly={!editing}
        schema={gcs}
        data={gcsByRoute}
        conditionalInputs={[
          {
            name: "parent_id",
            readOnly: !userIsAtpm,
          },
        ]}
      />
    );
  }

  renderActions() {
    const { editing } = this.state;
    const { equipmentRequestItems, gcsByRoute } = this.props;
    if (!editing) return null;
    return (
      <div>
        <div className="float-left">
          <button
            className="btn btn-sm btn-dark mr-2"
            onClick={this.openDeactivationDialog}
            disabled={!!find(equipmentRequestItems, { existing_id: gcsByRoute.id })}
          >
            Request Deactivation
          </button>
        </div>
        <div className="float-right">
          <button
            onClick={this.cancelEditing}
            className="btn btn-sm btn-secondary mr-2"
          >
            Cancel
          </button>
          <button onClick={this.save} className="btn btn-sm btn-success">
            Save
          </button>
        </div>
        <OrgFilter allowOrgs={["hq"]}>
          <RoleFilter allowRoles={["HQ.MEMBER", "HQ.ADMIN"]}>
            <DeleteButton onDelete={this.handleDelete} />
          </RoleFilter>
        </OrgFilter>
      </div>
    );
  }

  render() {
    const { editing } = this.state;
    const {
      gcsByRoute,
      gcsPlatformsItems,
      gcsPlatformsIsLoading,
      doGcsPlatformsSave,
      doGcsPlatformsDelete,
      gcsDocsItems,
      gcsDocsIsLoading,
      gcsMissionsItems,
      gcsMissionsIsLoading,
      gcsSoftwareItems,
      gcsSoftwareIsLoading,
      doFileIoUpload,
      doFileIoDelete,
    } = this.props;
    return (
      <div className="container mt-3">
        <div className="row">
          <div className="col-sm-7">
            <div className="card">
              <div className="card-header">
                <div className="float-right">
                  <RoleFilter
                    allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
                    alt={ReadOnly}
                  >
                    <button
                      disabled={editing}
                      className="btn btn-sm btn-ghost-primary"
                      onClick={this.enableEditing}
                    >
                      <i className="mdi mdi-pencil icon-inline"></i>
                      Edit
                    </button>
                  </RoleFilter>
                </div>
                <i className="ms ms-drone-remote"></i> Ground Control Station
                Details
              </div>
              <div className="card-body">
                {this.renderForm()}
                {this.renderActions()}
              </div>
            </div>
          </div>
          <div className="col-sm-5">
            <ApprovedComponentsCard componentTypeToList="aircraft" />
            <PlatformCard
              allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
              isLoading={gcsPlatformsIsLoading}
              items={gcsPlatformsItems}
              onAdd={doGcsPlatformsSave}
              onRemove={doGcsPlatformsDelete}
            />
            <GcsSoftwareCard
              isLoading={gcsSoftwareIsLoading}
              items={gcsSoftwareItems}
            />
            <FileUploadCard
              allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
              isLoading={gcsDocsIsLoading}
              items={gcsDocsItems}
              header={true}
              searchable={false}
              onUpload={doFileIoUpload}
              onRemove={doFileIoDelete}
              rel="gcs_docs"
              data={{ gcs_id: gcsByRoute ? gcsByRoute.id : null }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <MissionsCard
              isLoading={gcsMissionsIsLoading}
              items={gcsMissionsItems}
            />
            <NotesCard />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "doFileIoUpload",
  "doFileIoDelete",
  "doGcsSave",
  "doGcsDelete",
  "doDialogOpen",
  "doGcsPlatformsSave",
  "selectEquipmentRequestItems",
  "doGcsPlatformsDelete",
  "selectRouteParams",
  "selectGcsByRoute",
  "selectGcsPlatformsItems",
  "selectGcsPlatformsIsLoading",
  "selectGcsDocsItems",
  "selectGcsDocsIsLoading",
  "selectGcsMissionsItems",
  "selectGcsMissionsIsLoading",
  "selectGcsSoftwareItems",
  "selectGcsSoftwareIsLoading",
  "selectUserIsAtpm",
  GcsDetails
);
