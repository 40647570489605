import { marked } from "marked";
import { createSelector } from "redux-bundler";
import purify from "dompurify";
import { countBy } from "lodash";
import { roleAbbrevReverse } from "../modules/_shared/helper";

export default {
  name: "readFile",

  getReducer() {
    const initialData = {
      data: [],
      isFetching: false,
      isPosting: false,
      docs: [],
      shouldFetch: false,
      all: [],
      trackingData: [],
      tableData: [],
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "READ_FILE_FETCH_STARTED":
        case "READ_FILE_FETCH_ERROR":
        case "READ_FILE_FETCH_FINISHED":
        case "READ_FILE_SAVE_STARTED":
        case "READ_FILE_SAVE_FINISHED":
        case "READ_FILE_SAVE_ERROR":
        case "READ_FILE_READ_SAVE_STARTED":
        case "READ_FILE_READ_SAVE_FINISHED":
        case "READ_FILE_READ_SAVE_ERROR":
        case "READ_FILE_READ_DELETE_STARTED":
        case "READ_FILE_READ_DELETE_ERROR":
        case "READ_FILE_READ_DELETE_FINISHED":
        case "READFILE_DOCS_FETCH_STARTED":
        case "READFILE_DOCS_FETCH_ERROR":
        case "READFILE_DOCS_FETCH_FINISHED":
        case "READ_FILE_FETCHALL_STARTED":
        case "READ_FILE_FETCHALL_ERROR":
        case "READ_FILE_FETCHALL_FINISHED":
          return Object.assign({}, state, payload);
        case "AUTH_LOGGED_IN":
          return { ...state, shouldFetch: true, shouldFetchAll: true };
        default:
          return state;
      }
    };
  },
  doReadFileSave:
    (state, callback) =>
      ({ dispatch, store, apiPost, apiPut }) => {
        dispatch({
          type: "READ_FILE_SAVE_STARTED",
          payload: { isPosting: true },
        });
        if (state.id) {
          apiPut(
            `${store.selectApiRoot()}/readFile/${state.id}`,
            state,
            (err, res, body) => {
              if (err) {
                dispatch({
                  type: "READ_FILE_SAVE_ERROR",
                  payload: { isPosting: false, err },
                });
              } else {
                dispatch({
                  type: "READ_FILE_SAVE_FINISHED",
                  payload: { isPosting: false },
                });
                callback && callback(body);
              }
            }
          );
        } else {
          apiPost(
            `${store.selectApiRoot()}/readFile`,
            state,
            (err, res, body) => {
              if (err) {
                dispatch({
                  type: "READ_FILE_SAVE_ERROR",
                  payload: { isPosting: false, err },
                });
              } else {
                dispatch({
                  type: "READ_FILE_SAVE_FINISHED",
                  payload: { isPosting: false },
                });
                callback && callback(body);
              }
            }
          );
        }
      },
  doReadFileDelete:
    (id, callback) =>
      ({ dispatch, store, apiPost, apiDelete }) => {
        dispatch({
          type: "READ_FILE_DELETE_STARTED",
          payload: { isDeleting: true },
        });
        apiDelete(
          `${store.selectApiRoot()}/readFile/${id}`,
          {},
          (err, res, body) => {
            if (err) {
              dispatch({
                type: "READ_FILE_DELETE_ERROR",
                payload: { isDeleting: false, err },
              });
            } else {
              dispatch({
                type: "READ_FILE_DELETE_FINISHED",
                payload: { isDeleting: false },
              });
              callback && callback();
            }
          }
        );
      },

  doReadFileFetchAll:
    (callback) =>
      ({ dispatch, store, apiGet }) => {
        let url = import.meta.env.VITE_API_ROOT_BASE;
        dispatch({
          type: "READ_FILE_FETCHALL_STARTED",
          payload: { isAllFetching: true, shouldFetchAll: false },
        });
        apiGet(`${url}/v2/readFile`, (err, response, body) => {
          if (err) {
            dispatch({
              type: "READ_FILE_FETCHALL_ERROR",
              payload: { isAllFetching: false, err },
            });
          } else {
            const data = JSON.parse(body);
            dispatch({
              type: "READ_FILE_FETCHALL_FINISHED",
              payload: { all: data, isAllFetching: false },
            });
            callback && callback();
          }
        });
      },

  doReadFileFetch:
    (callback) =>
      ({ dispatch, store, apiGet }) => {
        let url = import.meta.env.VITE_API_ROOT_BASE;
        dispatch({
          type: "READ_FILE_FETCH_STARTED",
          payload: { isFetching: true, shouldFetch: false },
        });
        apiGet(
          `${url}/v2/readFile/${store.selectTokenPayload().sub}`,
          (err, response, body) => {
            if (err) {
              dispatch({
                type: "READ_FILE_FETCH_ERROR",
                payload: { isFetching: false, err },
              });
            } else {
              const data = JSON.parse(body);
              dispatch({
                type: "READ_FILE_FETCH_FINISHED",
                payload: { data: data, isFetching: false },
              });
              callback && callback();
            }
          }
        );
      },
  doReadFileTableFetch:
    (callback) =>
      ({ dispatch, store, apiGet }) => {
        let url = import.meta.env.VITE_API_ROOT_BASE;
        dispatch({
          type: "READ_FILE_FETCH_STARTED",
          payload: { isFetching: true, shouldFetch: false },
        });
        apiGet(
          `${url}/v2/readFileTable`,
          (err, response, body) => {
            if (err) {
              dispatch({
                type: "READ_FILE_FETCH_ERROR",
                payload: { isFetching: false, err },
              });
            } else {
              const data = JSON.parse(body);
              dispatch({
                type: "READ_FILE_FETCH_FINISHED",
                payload: { tableData: data, isFetching: false },
              });
              callback && callback();
            }
          }
        );
      },
  //returns unread read file itmes
  doReadFileTrackingFetch:
    (callback) =>
      ({ dispatch, store, apiGet }) => {
        let url = import.meta.env.VITE_API_ROOT_BASE;
        dispatch({
          type: "READ_FILE_FETCH_STARTED",
          payload: { isFetching: true, shouldFetch: false },
        });
        apiGet(
          `${url}/v2/readFileTracking`,
          (err, response, body) => {
            if (err) {
              dispatch({
                type: "READ_FILE_FETCH_ERROR",
                payload: { isFetching: false, err },
              });
            } else {
              const data = JSON.parse(body);
              dispatch({
                type: "READ_FILE_FETCH_FINISHED",
                payload: { trackingData: data, isFetching: false },
              });
              callback && callback();
            }
          }
        );
      },
  doReadFileMarkAsRead:
    (state, cb) =>
      ({ dispatch, store, apiPost, apiPut }) => {
        dispatch({
          type: "READ_FILE_READ_SAVE_STARTED",
          payload: { isPosting: true },
        });
        apiPost(
          `${store.selectApiRoot()}/readFileTracking`,
          state,
          (err, res, body) => {
            if (err) {
              dispatch({
                type: "READ_FILE_READ_SAVE_ERROR",
                payload: { isPosting: false, err },
              });
            } else {
              dispatch({
                type: "READ_FILE_READ_SAVE_FINISHED",
                payload: { isPosting: false },
              });
              store.doReadFileFetch();
              cb && cb();
            }
          }
        );
      },

  doReadFileMarkAsUnread:
    (id) =>
      ({ dispatch, store, apiDelete }) => {
        dispatch({
          type: "READ_FILE_READ_DELETE_STARTED",
          payload: { isDeleting: true },
        });
        apiDelete(
          `${store.selectApiRoot()}/readFileTracking/${id}`,
          {},
          (err, res, body) => {
            if (err) {
              dispatch({
                type: "READ_FILE_READ_DELETE_ERROR",
                payload: { isDeleting: false, err },
              });
            } else {
              dispatch({
                type: "READ_FILE_READ_DELETE_FINISHED",
                payload: { isDeleting: false },
              });
              store.doReadFileFetch();
            }
          }
        );
      },

  doReadFileDocsFetch:
    (id, callback) =>
      ({ dispatch, store, apiGet }) => {
        dispatch({
          type: "READFILE_DOCS_FETCH_STARTED",
          payload: { isDocsFetching: true },
        });
        apiGet(`/readFile/${id}/docs`, (err, response, body) => {
          if (err || response.statusCode !== 200) {
            dispatch({
              type: "READFILE_DOCS_FETCH_ERROR",
              payload: {
                _err: { err: err, response: response },
                notification: {
                  statusCode: response.statusCode,
                },
                isDocsFetching: false,
              },
            });
          } else {
            const data = JSON.parse(body);
            dispatch({
              type: "READFILE_DOCS_FETCH_FINISHED",
              payload: { docs: data, isDocsFetching: false },
            });
            callback && callback();
          }
        });
      },
  selectReadFileDocs: (state) => state.readFile.docs,
  selectReadFileItems: (state) => state.readFile.data,
  selectReadFileAll: (state) => state.readFile.all,
  selectReadFileAllObject: (state) => {
    let readFileObject = {};
    state.readFile.all.forEach((item) => {
      if (readFileObject[item.id]) {
        if (item.profile_id) readFileObject[item.id].push(item.profile_id);
      } else {
        if (item.profile_id) readFileObject[item.id] = [item.profile_id];
        else readFileObject[item.id] = [];
      }
    });
    return readFileObject;
  },
  selectReadFileIsLoading: (state) => state.readFile.isFetching,
  selectReadFileIsAllLoading: (state) => state.readFile.isAllFetching,
  selectReadFileIsSaving: (state) => state.readFile.isPosting,
  selectReadFileIsDeleting: (state) => state.readFile.isDeleting,

  selectReadFileItemsParsed: createSelector("selectReadFileItems", (items) => {
    return items.map((item) => {
      if (item.description) item.html = purify.sanitize(marked(item.description));
      return item;
    });
  }),

  selectReadFileTrackingData: state => state.readFile.trackingData,
  selectReadFileTableData: state => state.readFile.tableData,
  // this is just the items that are unread by users, but they still have to go through the filtering afterword of roles etc
  // selectReadFileUnreadItems: createSelector(
  //   "selectReadFileTrackingData",
  //   "selectReadFileTableData",
  //   (trackingData, tableData) => {
  //     return groupBy(trackingData, 'keycloak_id');
  //   }
  // )
  selectReadFileCountObject: createSelector(
    "selectReadFileItems",
    "selectProfileApprovalRoles",
    "selectDomainsItems",
    "selectTokenKeyCloakId",
    "selectEfTwentyItems",
    "selectPlatformsIdByOrgProfile",
    (
      items,
      profileApprovalRoles,
      domains,
      tokenKeyCloakId,
      efTwentyItems,
      platformsIdByOrgProfile
    ) => {
      let countArr = items.filter((item) => {
        let { allow_roles, allow_flight_roles, allow_aircraft } = item;
        let unread = item.keycloak_id !== tokenKeyCloakId;
        if (allow_aircraft) {
          let allowPlatforms = allow_aircraft
            ? JSON.parse(allow_aircraft)
            : ["*"];
          let userInAircraftOrgList =
            platformsIdByOrgProfile.some((item) =>
              allowPlatforms.includes(item)
            ) || allowPlatforms.includes("*");
          // if user is in org with aircraft check other filters
          if (userInAircraftOrgList && unread) {
            if (allow_roles) {
              let fullRoleNames = JSON.parse(allow_roles).map(
                (role) => roleAbbrevReverse[role]
              );
              let roleIds = domains
                .filter((item) => fullRoleNames.includes(item.val))
                .map((item) => item.id);
              return (
                unread &&
                profileApprovalRoles &&
                profileApprovalRoles.some((r) => roleIds.includes(r))
              );
            } else if (allow_flight_roles) {
              let allowRoles = allow_flight_roles
                ? JSON.parse(allow_flight_roles)
                : ["*"];
              let flightDuties =
                efTwentyItems && efTwentyItems.length > 0
                  ? efTwentyItems[0].duties_id
                  : "";
              flightDuties = flightDuties ? flightDuties.split(".") : [];
              let dutiesNames = domains
                .filter((d) => flightDuties.includes(d.id))
                .map((item) => item.val);
              return (
                unread &&
                (dutiesNames.some((r) => allowRoles.indexOf(r) >= 0) ||
                  allowRoles.includes("*"))
              );
            } else return true; // if no flight roles or approval roles return true
          } else return false; // if there are aircraft limitations, and youre not in it, return false
        } else if (allow_roles) {
          let fullRoleNames = JSON.parse(allow_roles).map(
            (role) => roleAbbrevReverse[role]
          );
          let roleIds = domains
            .filter((item) => fullRoleNames.includes(item.val))
            .map((item) => item.id);
          return (
            unread &&
            profileApprovalRoles &&
            profileApprovalRoles.some((r) => roleIds.includes(r))
          );
        } else if (allow_flight_roles) {
          let allowRoles = allow_flight_roles
            ? JSON.parse(allow_flight_roles)
            : ["*"];
          let flightDuties =
            efTwentyItems && efTwentyItems.length > 0
              ? efTwentyItems[0].duties_id
              : "";
          flightDuties = flightDuties ? flightDuties === 'string' ? flightDuties.split(".") : flightDuties : [];
          let dutiesNames = domains
            .filter((d) => flightDuties.includes(d.id))
            .map((item) => item.val);
          return (
            unread &&
            (dutiesNames.some((r) => allowRoles.indexOf(r) >= 0) ||
              allowRoles.includes("*"))
          );
        } else return unread;
      });
      let initialStatusObject = countBy(countArr, "initial_status");
      if (!initialStatusObject.red) initialStatusObject.red = 0;
      if (!initialStatusObject.yellow) initialStatusObject.yellow = 0;
      return initialStatusObject;
    }
  ),

  selectReadFileShouldFetch: (state) => state.readFile.shouldFetch,
  selectReadFileShouldFetchAll: (state) => state.readFile.shouldFetchAll,

  reactReadFileShouldFetch: createSelector(
    "selectReadFileShouldFetch",
    "selectReadFileIsLoading",
    (shouldFetch, isLoading) => {
      if (isLoading) return null;
      if (shouldFetch)
        return {
          actionCreator: "doReadFileFetch",
        };
    }
  ),
  reactReadFileShouldFetchAll: createSelector(
    "selectReadFileShouldFetchAll",
    "selectReadFileIsAllLoading",
    (shouldFetchAll, isAllLoading) => {
      if (isAllLoading) return null;
      if (shouldFetchAll)
        return {
          actionCreator: "doReadFileFetchAll",
        };
    }
  ),
};
