import React from 'react';
import FileUploadCard from "../_shared/file-upload-card/file-upload-card";
import { connect } from 'redux-bundler-react';

const JobItemDocsCard = ({ jobsDocsItems, jobsByRoute, jobsDocsIsLoading }) => {
    return (
        <FileUploadCard rel="jobs_docs" items={jobsDocsItems} data={{ job_id: jobsByRoute ? jobsByRoute.id : null }}
            allowRoles={["*.*"]} searchable={false} header={false} disabledEditing={true} isLoading={jobsDocsIsLoading}
        />
    )
}

export default connect(
    'selectJobsDocsItems',
    'selectJobsByRoute',
    'selectJobsDocsIsLoading',
    JobItemDocsCard);