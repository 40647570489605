import { groupBy } from "lodash";
import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";

export default createRestBundle({
  name: "missionsAircraft",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/missions/:missionsSlug/aircraft",
  putTemplate: "/orgs/:orgSlug/missions/:missionsSlug/aircraft/:item.id",
  postTemplate: "",
  deleteTemplate: "/orgs/:orgSlug/missions/:missionsSlug/aircraft/:item.id",
  fetchActions: ["AUTH_LOGGED_IN"],
  forceFetchActions: ["ACTIVE_TAB_UPDATE"],
  addons: {
    selectMissionsAircraftApproved: createSelector(
      "selectMissionsAircraftItems",
      (missionsAircraftItems) => {
        return missionsAircraftItems.filter((item) => !item.adjudicated);
      }
    ),
    selectMissionsAircraftAdjudicated: createSelector(
      "selectMissionsAircraftItems",
      "selectMissionsConfigurationsActiveRow",
      (items, activeConfiguration) => {
        if (activeConfiguration)
          return items.filter(
            (item) =>
              !!item.adjudicated &&
              activeConfiguration.aircraft_ids.includes(item.id)
          );
        return items.filter((item) => !!item.adjudicated);
      }
    ),
  },
});
