import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";

const RatingsBox = ({ tokenPayload, itemData, itemIdColName, ratingVal, activeUserRating, doMessagesRatingsSave, messagesRatingsIsLoading, messagesRatingsFlags }) => {
    const calculateRating = () => {
        return ratingVal ? ratingVal : 0
    }
    const ratingBtnClicked = (e) => {

        let rating = 0;
        if (e.currentTarget.name === "undoBtn") rating = 0;
        if (e.currentTarget.name === "likeBtn") rating = 1;
        if (e.currentTarget.name === "dislikeBtn") rating = -1;

        let ratingsAttrs = {
            [itemIdColName]: itemData.id, keycloak_id: tokenPayload.sub,
            rating: rating, is_shared: itemData.is_shared
        }
        if (activeUserRating) doMessagesRatingsSave(Object.assign({}, { ...ratingsAttrs, id: activeUserRating.id }));
        else doMessagesRatingsSave(Object.assign({ ...ratingsAttrs }));

    }
    const renderRatingValue = () => {
        let helpRating = calculateRating();
        let badgeColor = 'primary';
        let sign = ' ';
        if (helpRating > 0) {
            sign = '+';
            badgeColor = 'success';
        }
        else if (helpRating < 0) {
            sign = '-';
            badgeColor = 'danger';
        }
        if (Math.abs(helpRating) > 999) {
            helpRating = 999;
        }

        return (
            <h4 className={`order-2 p-2 my-auto text-${badgeColor}`}>
                <span>{sign}{Math.abs(helpRating)}</span>
            </h4>
        )
    }

    let likeBtnCls = classnames({
        "like-btn btn btn-link btn-sm order-1 text-success": true,
        "selected disabled": activeUserRating && activeUserRating.rating === 1,
    })
    let undoBtnCls = classnames({
        "undo-btn btn btn-sm btn-link text-primary": true,
        "order-1": (!activeUserRating || (activeUserRating && activeUserRating.rating !== 1)),
        "order-3": (!activeUserRating || (activeUserRating && activeUserRating.rating !== -1))
    })
    let dislikeBtnCls = classnames({
        "dislike-btn btn btn-link btn-sm order-3 text-danger": true,
        "selected disabled": activeUserRating && activeUserRating.rating === -1
    })

    let disableLikeBtn = activeUserRating && activeUserRating.rating === 1;
    let disableDislikeBtn = activeUserRating && activeUserRating.rating === -1;
    const ratingCardStyle = {
        borderRadius: '0.25rem 0 0 0.25rem',
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
        minWidth: '3.75rem',
        maxWidth: '3.75rem',
        height: '116px'
    }
    return (
        <div className="card border-right-0" style={ratingCardStyle}>

            {messagesRatingsIsLoading || messagesRatingsFlags._shouldFetch ? <div className="m-auto text-center text-primary spinner-border" /> :
                <div className="card-body d-flex justify-content-around px-1 py-2">
                    <div className="btn-group-vertical ml-1">
                        {(!activeUserRating || (activeUserRating && activeUserRating.rating !== -1)) && <button name="likeBtn" type="button" className={likeBtnCls} onClick={ratingBtnClicked} disabled={disableLikeBtn}><span className="mdi mdi-thumb-up-outline" /></button>}
                        {renderRatingValue()}
                        {((activeUserRating && activeUserRating.rating !== 0)) && <button name="undoBtn" type="button" className={undoBtnCls} onClick={ratingBtnClicked}><span className="mdi mdi-arrow-u-left-bottom-bold" /></button>}
                        {(!activeUserRating || (activeUserRating && activeUserRating.rating !== 1)) && <button name="dislikeBtn" type="button" className={dislikeBtnCls} onClick={ratingBtnClicked} disabled={disableDislikeBtn}><span className="mdi mdi-thumb-down-outline" /></button>}
                    </div>
                </div>}
        </div>

    )
}

export default connect(
    "selectTokenPayload",
    "doMessagesRatingsSave",
    "selectMessagesRatingsIsLoading",
    "selectMessagesRatingsFlags",
    RatingsBox);