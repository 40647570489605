import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";
import { uniq, flatten } from "lodash";
export default createRestBundle({
  name: "library",
  uid: "s3_key",
  prefetch: true,
  staleAfter: 900000,
  persist: false,
  routeParam: null,
  getTemplate: "/library",
  putTemplate: "/library/:item.id",
  postTemplate: "/library",
  deleteTemplate: "/library/:item.id",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN"],
  forceFetchActions: ["FILE_IO_UPLOAD_FINISHED", "FILE_IO_DELETE_FINISHED"],
  addons: {
    selectLibraryItemsAllCategories: createSelector(
      "selectLibraryItems",
      (libraryItems) => {
        return uniq(flatten(libraryItems.map(item => {
          return item.categories && item.categories.length > 0 ? item.categories.split('~,~') : []
        })));
      }
    )
  }
});
