import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import SelectorCard from "../../_shared/selector-card-mission-filter/card";
import configurationModal from "./modals/configuration-modal";
import { keyBy } from "lodash";
import "./inventory.css";
import ConfigurationTile from "./configuration-tile";
import MissionStatusFilter from "../../_shared/mission-status-filter";
import Loader from "../../../../app-components/loader";

const Inventory = (props) => {
  const {
    aircraftItemsApproved,
    missionsAircraftItems,
    missionsAircraftIsLoading,
    doMissionsAircraftSave,
    doMissionsAircraftDelete,
    doMissionsAircraftFetch,

    batteriesItems,
    missionsBatteriesItems,
    missionsBatteriesIsLoading,
    doMissionsBatteriesSave,
    doMissionsBatteriesDelete,
    doMissionsBatteriesFetch,

    gcsItemsNotDeactivated: gcsItems,
    missionsGcsItems,
    missionsGcsIsLoading,
    doMissionsGcsSave,
    doMissionsGcsDelete,
    doMissionsGcsFetch,

    mmsItems,
    missionsMmsItems,
    missionsMmsIsLoading,
    doMissionsMmsSave,
    doMissionsMmsDelete,
    doMissionsMmsFetch,

    payloadsItemsNotDeactivated: payloadsItems,
    missionsPayloadsItems,
    missionsPayloadsIsLoading,
    doMissionsPayloadsSave,
    doMissionsPayloadsDelete,
    doMissionsPayloadsFetch,

    doMissionsAlertFetch,

    doDialogOpen,
    domainsItems,
    doMissionsConfigurationsFetch,
    doMissionsConfigurationsFetchTable,
    missionInventoryByConfiguration,
    missionsConfigurationIsFetching,
    missionsByRoute,
  } = props;

  const [payloadTypesObject, setPayloadTypesObject] = useState(
    keyBy(
      domainsItems.filter((item) => item.grp === "payload_type"),
      "id"
    )
  );

  useEffect(() => {
    //fetch all data if the bundler isnt triggering the fetch
    if (!missionsAircraftIsLoading) doMissionsAircraftFetch();
    if (!missionsBatteriesIsLoading) doMissionsBatteriesFetch();
    if (!missionsGcsIsLoading) doMissionsGcsFetch();
    if (!missionsPayloadsIsLoading) doMissionsPayloadsFetch();
    if (!missionsMmsIsLoading) doMissionsMmsFetch();
    doMissionsAlertFetch();
    doMissionsConfigurationsFetch();
    doMissionsConfigurationsFetchTable();
  }, []);

  // update this for deployment. date needs to be date ov v1.6.4
  if (new Date(missionsByRoute.create_date) < new Date("1/29/2024")) {
    return (
      <div className="row">
        <div className="col">
          <SelectorCard
            allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
            allowStatus={{
              approval_status: ["draft"],
            }}
            items={missionsAircraftItems}
            isLoading={missionsAircraftIsLoading}
            pickFrom={aircraftItemsApproved}
            onAdd={(item) =>
              doMissionsAircraftSave(
                item,
                () => {
                  doMissionsAircraftFetch();
                  doMissionsAlertFetch();
                },
                true
              )
            }
            onRemove={(item) =>
              doMissionsAircraftDelete(
                item,
                () => {
                  doMissionsAircraftFetch();
                  doMissionsAlertFetch();
                },
                true
              )
            }
            title="Aircraft"
            iconClass="mdi mdi-airplane"
            itemRenderFn={(item) => {
              return `${item.name} ${
                item.adjudicated ? " - Added Post Approval" : ""
              }`;
            }}
          />
          <SelectorCard
            allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
            allowStatus={{
              approval_status: ["draft"],
            }}
            items={missionsBatteriesItems}
            isLoading={missionsBatteriesIsLoading}
            pickFrom={batteriesItems}
            onAdd={(item) =>
              doMissionsBatteriesSave(item, doMissionsBatteriesFetch, true)
            }
            onRemove={(item) =>
              doMissionsBatteriesDelete(item, doMissionsBatteriesFetch, true)
            }
            title="Batteries"
            iconClass="mdi mdi-battery"
            itemRenderFn={(item) => {
              return `${item.name} ${
                item.adjudicated ? " - Added Post Approval" : ""
              }`;
            }}
          />
        </div>
        <div className="col">
          <SelectorCard
            allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
            allowStatus={{
              approval_status: ["draft"],
            }}
            items={missionsGcsItems}
            isLoading={missionsGcsIsLoading}
            pickFrom={gcsItems}
            onAdd={(item) => doMissionsGcsSave(item, doMissionsGcsFetch, true)}
            onRemove={(item) =>
              doMissionsGcsDelete(item, doMissionsGcsFetch, true)
            }
            title="Ground Control Stations"
            iconClass="ms ms-drone-remote"
            itemRenderFn={(item) => {
              return `${item.name} ${
                item.adjudicated ? " - Added Post Approval" : ""
              }`;
            }}
          />
          <SelectorCard
            allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
            allowStatus={{
              approval_status: ["draft"],
            }}
            items={missionsPayloadsItems}
            isLoading={missionsPayloadsIsLoading}
            pickFrom={payloadsItems}
            onAdd={(item) =>
              doMissionsPayloadsSave(item, doMissionsPayloadsFetch, true)
            }
            onRemove={(item) =>
              doMissionsPayloadsDelete(item, doMissionsPayloadsFetch, true)
            }
            title="Payloads"
            iconClass="mdi mdi-camera"
            itemRenderFn={(item) => {
              let payloadType =
                item.payload_type_id && payloadTypesObject[item.payload_type_id]
                  ? payloadTypesObject[item.payload_type_id].val
                  : "";
              if (payloadType.includes("Still Camera")) payloadType = "Camera";
              else if (payloadType.includes("Gimbal")) payloadType = "Gimbal";
              else payloadType = "Sensor";
              return `
              ${item.name} - (${payloadType})
              ${item.adjudicated ? " - Added Post Approval" : ""}`;
            }}
          />
          <SelectorCard
            allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
            allowStatus={{
              approval_status: ["draft"],
            }}
            items={missionsMmsItems}
            isLoading={missionsMmsIsLoading}
            pickFrom={mmsItems}
            onAdd={(item) => doMissionsMmsSave(item, doMissionsMmsFetch, true)}
            onRemove={(item) =>
              doMissionsMmsDelete(item, doMissionsMmsFetch, true)
            }
            title="Mobile Map Servers"
            iconClass="mdi mdi-laptop"
            itemRenderFn={(item) => {
              return `${item.name} ${
                item.adjudicated ? " - Added Post Approval" : ""
              }`;
            }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-100 h-100">
        <div className="d-flex w-100 align-items-center justify-content-between pb-2">
          <h5 className="m-0">Mission Plan Configurations</h5>
          <MissionStatusFilter
            allowStatus={{
              approval_status: ["draft"],
            }}
            alt={null}
          >
            <button
              className="btn btn-outline-success"
              onClick={() => {
                doDialogOpen({
                  content: configurationModal,
                  props: {
                    aircraftItems: aircraftItemsApproved,
                    batteriesItems,
                    gcsItems,
                    payloadsItems,
                    mmsItems,
                  },
                  size: "xl",
                });
              }}
            >
              Select System Configuration
            </button>
          </MissionStatusFilter>
        </div>
        <div className="row">
          {missionsConfigurationIsFetching ? (
            <div
              className="d-flex p-4 align-items-center justify-content-center w-100"
              style={{ height: 500 }}
            >
              <Loader />
            </div>
          ) : !missionInventoryByConfiguration ||
            Object.keys(missionInventoryByConfiguration).length === 0 ? (
            <div
              className="d-flex p-4 align-items-center justify-content-center w-100"
              style={{ height: 500 }}
            >
              <h4 className="text-muted">
                Please select your system configuration with the button above.
              </h4>
            </div>
          ) : (
            missionInventoryByConfiguration.map((invConfig, key) => {
              return (
                <ConfigurationTile
                  configuration={Object.values(invConfig)[0]}
                  configurationId={Object.keys(invConfig)[0]}
                  key={key}
                  index={key}
                  // onEdit={() => {
                  //   doDialogOpen({
                  //     content: configurationModal,
                  //     props: {
                  //       aircraftItems: aircraftItemsApproved,
                  //       batteriesItems,
                  //       gcsItems,
                  //       payloadsItems,
                  //       mmsItems,
                  //       configuration: Object.values(invConfig)[0],
                  // configurationId: Object.keys(invConfig)[0],
                  //     },
                  //     size: "xl",
                  //   });
                  // }}
                />
              );
            })
          )}
        </div>
        <div className="row mx-1">
          <p>
            <b>Note: </b> Any items denoted in{" "}
            <b className="text-danger">red</b> were added after mission
            approval.
          </p>
        </div>
      </div>
    );
  }
};

export default connect(
  "selectAircraftItemsApproved",
  "selectMissionsAircraftItems",
  "selectMissionsAircraftIsLoading",
  "doMissionsAircraftSave",
  "doMissionsAircraftDelete",

  "selectBatteriesItems",
  "selectMissionsBatteriesItems",
  "selectMissionsBatteriesIsLoading",
  "doMissionsBatteriesSave",
  "doMissionsBatteriesDelete",

  "selectGcsItemsNotDeactivated",
  "selectMissionsGcsItems",
  "selectMissionsGcsIsLoading",
  "doMissionsGcsSave",
  "doMissionsGcsDelete",

  "selectMmsItems",
  "selectMissionsMmsItems",
  "selectMissionsMmsIsLoading",
  "doMissionsMmsSave",
  "doMissionsMmsDelete",

  "selectPayloadsItemsNotDeactivated",
  "selectMissionsPayloadsItems",
  "selectMissionsPayloadsIsLoading",
  "doMissionsPayloadsSave",
  "doMissionsPayloadsDelete",
  "doMissionsAircraftFetch",
  "doMissionsBatteriesFetch",
  "doMissionsGcsFetch",
  "doMissionsMmsFetch",
  "doMissionsPayloadsFetch",

  "doMissionsAlertFetch",
  "doDialogOpen",
  "selectDomainsItems",
  "selectMissionsConfigurationsItems",
  "doMissionsConfigurationsFetch",
  "selectMissionInventoryByConfiguration",
  "selectMissionsConfigurationIsFetching",
  "doMissionsConfigurationsFetchTable",
  "selectMissionsByRoute",
  Inventory
);
