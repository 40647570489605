import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import loginGovIcon from "../../img/login-gov.svg";
import Loader from "../../app-components/loader";
import MaintenanceCard from "./maintenance-card";
import dodMessageDialog from "../dod-message-dialog";

const Login = ({
  doCheckServerStatus,
  downtimeIsLoading,
  downtimeItems,
  downtimeError,
  serverError,
  isCheckingStatus,
  doKeycloakAuthenticateCode,
  doKeycloakAuthenticate,
  doDialogOpen,
}) => {
  useEffect(() => {
    doCheckServerStatus();
    if (!sessionStorage.getItem("isDodAcknowledged")) {
      doDialogOpen({
        content: dodMessageDialog,
        props: {},
        size: "lg",
      });
    }
  }, []);

  let activeDowntimeMessages =
    downtimeItems &&
    Array.isArray(downtimeItems) &&
    downtimeItems.length > 0 &&
    downtimeItems.filter((item) => !!item.lock_application);

  if (downtimeIsLoading || isCheckingStatus) return <Loader />;
  return (
    <div
      className="card p-4"
      style={{ backgroundColor: "rgba(255,255,255,0.8)" }}
    >
      <div className="card-body">
        <>
          <h1 className="nasa text-primary" style={{ fontSize: "4em" }}>
            MARS
          </h1>
          <h3>Management information system</h3>
          <h3>for Aviation & Remote Systems</h3>
        </>
        {activeDowntimeMessages && activeDowntimeMessages.length > 0 ? (
          activeDowntimeMessages.map((d) => {
            return <MaintenanceCard message={d} />;
          })
        ) : serverError || downtimeError ? (
          <MaintenanceCard
            message={{
              title: "MARS Under Maintenance",
              description:
                "MARS is currently undergoing maintenance. Please be patient while we continue to make improvements!",
              start_time: null,
              end_time: null,
            }}
            isEditable={false}
          />
        ) : (
          <div className="col-6 float-right text-right mt-5">
            <div className="text-right mt-5">
              <h4>
                <u>Login Options</u>
              </h4>
            </div>
            <div>
              <button
                onClick={doKeycloakAuthenticateCode}
                className="btn btn-lg  px-4"
                type="button"
              >
                <img src={loginGovIcon} height="16" />
              </button>
            </div>
            <div>
              <button
                onClick={doKeycloakAuthenticate}
                className="btn btn-lg px-4"
                type="button"
              >
                <div className="row mdi mdi-credit-card-chip">
                  <b style={{ marginLeft: "6px" }}>Government ID (CAC Card)</b>
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(
  "doKeycloakAuthenticate",
  "doKeycloakAuthenticateCode",
  "doCheckServerStatus",
  "selectDowntimeItems",
  "selectDowntimeIsLoading",
  "selectDowntimeError",
  "selectServerError",
  "selectIsCheckingStatus",
  "doDialogOpen",
  Login
);
