import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import FileUploadButton from "../approvals-tab/adjudication-file-upload";

// This is the Adjudication Form for all alerts on the page.
const Adjudication = ({
  alerts,
  doFileIoUpload,
  doMissionsAdjudicationsFetch: doFetch,
  doMissionsAdjudicationsPost,
}) => {
  const [note, setNote] = useState("");
  const [file, setFile] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  function _onSave() {
    setIsFetching(true);
    for (const alert of alerts) {
      let object = {
        mission_id: alert.mission_id,
        variable_id: alert.variable_id,
        note,
      };
      doPost(object);
    }
  }

  function doPost(item) {
    doMissionsAdjudicationsPost(item, (body) => {
      setNote("");
      if (!file) doFetch();
      if (file) {
        doFileIoUpload(
          null,
          file,
          "adjudication_docs",
          {
            adjudication_id: body[0].id,
          },
          () => {
            setFile(null);
            doFetch();
          }
        );
      }

      setIsFetching(false);
    });
  }

  return (
    <div>
      <h6 className="alert-subheading">Adjudicate All</h6>
      <div className="d-flex flex-column w-100 bg-white mb-2 border">
        <textarea
          className="form-control mb-2 px-2"
          rows="2"
          placeholder="If this is intended, explain here why this is necessary."
          onChange={(e) => setNote(e.target.value)}
          value={note}
          style={{ borderWidth: 0 }}
        />
        {!file && (
          <p className="text-muted mx-2 border-top">
            <small>
              {" "}
              It is expected that the ATPM will give you written approval for
              this action. This is your accountability for this change. Please
              attach that to each adjudication on this flight.
            </small>
          </p>
        )}
        {file && (
          <div className="d-flex p-1 align-items-center mr-2 border-top">
            <i className="mdi mdi-file mdi-18px text-info" />
            <p className="my-0 ml-1 text-muted">
              <small>{file.name}</small>
            </p>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end w-100 pr-2 pb-2">
        <FileUploadButton onFileChange={setFile} file={file} />
        <button
          onClick={_onSave}
          disabled={isFetching}
          className={`btn btn-sm btn-success ml-1`}>
          {isFetching ? (
            <i className="mdi mdi-loading mdi-spin mdi-14px" />
          ) : (
            "Save to All"
          )}
        </button>
      </div>
    </div>
  );
};
export default connect(
  "doMissionsAdjudicationsSave",
  "doMissionsAdjudicationsFetch",
  "doFileIoUpload",
  "doMissionsAdjudicationsPost",
  Adjudication
);
