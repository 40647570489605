import React from "react";
import { connect } from "redux-bundler-react";
import { gcs, gcsRequest } from "../../models";
import SchemaForm from "../../app-components/schema-form/schema-form";

class GcsNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      org_id: props.orgsByRoute.id
    };
    this.save = this.save.bind(this);
  }

  save() {
    const { doGcsSave, doEquipmentRequestSave, orgsByRoute, doUpdateRelativeUrl, profileActiveData, equipmentRequestUserIsAdmin: userIsAdmin } = this.props;
    if (this.form.isValid()) {
      const data = this.form.serialize();
      if (userIsAdmin) {
        doGcsSave(
          data,
          (newItem) => {
            doUpdateRelativeUrl(`/${orgsByRoute.slug}/gcs/${newItem.slug}`);
          },
          true
        );
      }
      else {
        doEquipmentRequestSave(
          {
            ...data,
            created_by: profileActiveData.keycloak_id,
            status: "New",
            action: "Add",
            type: "gcs",
            org_id: orgsByRoute.id,
            org_name: orgsByRoute.name,
            profile_id: profileActiveData.id,
            platform_id: data.parent_id
          }, () => doUpdateRelativeUrl(`/${orgsByRoute.slug}/gcs`), true)
      }
    }
  }

  render() {
    const { gcsIsSaving: isSaving, equipmentRequestUserIsAdmin: userIsAdmin } = this.props;
    return (
      <div className="container mt-3">
        <div className="row">
          <div className="col-sm-12 col-lg-9">
            <div className="card">
              <div className="card-header">
                <i className="ms ms-drone-remote"></i> {userIsAdmin ? "Create" : "Request"} Ground Control
                Station
              </div>
              <div className="card-body">
                <SchemaForm
                  ref={(el) => {
                    this.form = el;
                  }}
                  inline={true}
                  displayOnly={false}
                  schema={userIsAdmin ? gcs : gcsRequest}
                  data={this.state}
                />
                <div className="float-right">
                  <button
                    disabled={isSaving}
                    onClick={() => {
                      window.history.back();
                    }}
                    className="btn btn-secondary mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={isSaving}
                    onClick={this.save}
                    className="btn btn-primary"
                  >
                    {userIsAdmin ? "Save" : "Request"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doEquipmentRequestSave",
  "doUpdateRelativeUrl",
  "doGcsSave",
  "selectEquipmentRequestUserIsAdmin",
  "selectProfileActiveData",
  "selectOrgsByRoute",
  "selectOrgsIsSaving",
  GcsNew
);
