import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
    name: "equipmentRequestDocs",
    uid: "s3_key",
    prefetch: true,
    routeParam: null,
    getTemplate: "/orgs/:orgSlug/equipment-requests/:equipmentRequestSlug/docs",
    fetchActions: [
        "URL_UPDATED",
        "AUTH_LOGGED_IN",
        "FILE_IO_UPLOAD_FINISHED",
        "FILE_IO_DELETE_FINISHED"
    ],
});