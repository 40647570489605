import React from "react";
import { connect } from "redux-bundler-react";

class WeatherWidget extends React.Component {
  constructor(props) {
    super(props);
    this.renderError = this.renderError.bind(this);
    this.renderWeather = this.renderWeather.bind(this);
  }
  renderError() {
    const { weather } = this.props;
    if (!weather) return null;
    const { error } = weather;
    if (!error) return null;
    return <div>{error}</div>;
  }

  renderWeather() {
    const { weather } = this.props;
    if (!weather) return <div>Loading Weather...</div>;
    const {
      error,
      temperature,
      condition,
      wind_speed,
      wind_gusting,
      wind_heading
    } = weather;
    if (error) return null;
    const tempC = temperature;
    const tempF = (tempC * 9) / 5 + 32;
    return (
      <div>
        {`${condition} ${Math.round(tempF)}°F / ${Math.round(
          tempC
        )}°C    Winds ${wind_speed}mph Gusting to ${wind_gusting}mph  `}
        <i
          className="mdi mdi-navigation pl-2"
          style={{
            display: "inline-block",
            transform: `rotate(${wind_heading - 180}deg)`
          }}
        ></i>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderError()}
        {this.renderWeather()}
      </>
    );
  }
}

export default connect(
  "selectWeather",
  WeatherWidget
);
