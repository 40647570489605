import React from "react";
import { connect } from "redux-bundler-react";
import SearchInput from "../_shared/search-input/search-input";
import Table from "../../app-components/table/table";
import { sortBy } from "lodash";
import classnames from "classnames";
import MemberButton from "./import-from-member-button";
import RoleFilter from "../../app-containers/context-providers/role-filter";
import Loader from "../../app-components/loader";

class HiddenPersonnel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSearch(e) {
    this.setState({
      searchTerm: e.target.value,
    });
  }

  handleSelect(slug) {
    const { doUpdateRelativeUrl, orgsByRoute } = this.props;
    doUpdateRelativeUrl(`/${orgsByRoute.slug}/personnel/${slug}`);
  }

  render() {
    const {
      personnelItems: items,
      personnelIsSaving,
      doPersonnelSave,
    } = this.props;

    const config = {
      "": {
        dataKey: "parent_id",
        dataType: "string",
        renderFn: (_, item) => {
          const hiddenIcon = classnames({
            "ml-2": true,
            mdi: true,
            "mdi-eye": item.hidden,
            "mdi-eye-off": !item.hidden,
            "text-muted": true,
          });
          return (
            <RoleFilter allowRoles={[`HQ.ADMIN`, `:ORG.ADMIN`]}>
              <button
                title="Show Personnel"
                className="bg-transparent border-0 outline-0"
                onClick={() =>
                  doPersonnelSave({ ...item, hidden: !item.hidden })
                }
              >
                <i className={hiddenIcon}></i>
              </button>
            </RoleFilter>
          );
        },
      },
      Name: {
        dataKey: "name",
        dataType: "string",
      },
      "E-Mail": {
        dataKey: "email",
        dataType: "string",
      },
      Phone: {
        dataKey: "phone",
        dataType: "string",
      },
    };

    if (personnelIsSaving) {
      return (
        <div className="container-fluid mt-4">
          <Loader opt="dissolve-cube" />
        </div>
      );
    }

    const sorted = sortBy(items, ["name"]).filter((item) => !!item.hidden);
    return (
      <div className="card">
        <div className="card-header">
          <i className="mdi mdi-account-multiple-outline"></i> Hidden Personnel
        </div>
        <div className="card-body">
          <Table
            config={config}
            items={sorted}
            onRowSelect={this.handleSelect}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "doPersonnelSave",
  "doPersonnelUpgrade",
  "selectPersonnelItems",
  "selectOrgsByRoute",
  "selectPersonnelIsLoading",
  "selectApprovalRolesByLoggedIn",
  HiddenPersonnel
);
