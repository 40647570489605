import React from "react";

const LoadingList = () => {
  return (
    <div className="d-flex flex-column">
      <div className="skeleton-box mb-2" />
      <div className="skeleton-box mb-2" />
      <div className="skeleton-box mb-2" />
    </div>
  );
};

export default LoadingList;
