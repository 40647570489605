import React from "react";
import { connect } from "redux-bundler-react";
import Loader from "../../app-components/loader";
import AllEquipmentGrid from "./all-equipment-grid";

class AllEquipment extends React.Component {
  componentDidMount() {
    const { doAllEquipmentFetch, allEquipmentIsLoading } = this.props;
    if (!allEquipmentIsLoading) {
      doAllEquipmentFetch();
    }
  }
  render() {
    const {
      allEquipmentIsLoading,
      allEquipmentItems,
      doAllEquipmentDownloadAsCsv,
    } = this.props;
    if (allEquipmentIsLoading) {
      return (
        <div className="container-fluid mt-4">
          <Loader opt="dissolve-cube" />
        </div>
      );
    }
    return (
      <div className="container-fluid mt-4">
        <div className="card">
          <div className="card-header">
            <div className="float-right">
              <button
                onClick={doAllEquipmentDownloadAsCsv}
                className="btn btn-sm btn-ghost-primary"
                disabled={!allEquipmentItems.length}
              >
                <i className="mdi mdi-download icon-inline"></i>CSV
              </button>
            </div>
            <i className="mdi mdi-format-list-text"></i>All Equipment
          </div>
          <div className="card-body p-0">
            <AllEquipmentGrid />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "selectAllEquipmentIsLoading",
  "selectAllEquipmentItems",
  "doAllEquipmentDownloadAsCsv",
  "doAllEquipmentFetch",
  AllEquipment
);
