import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";

export default createRestBundle({
  name: "domainsEditable",
  uid: "id",
  prefetch: true,
  staleAfter: 900000,
  persist: false,
  routeParam: null,
  getTemplate: "/domains",
  putTemplate: "/domains/:item.id",
  postTemplate: "/domains",
  deleteTemplate: "/domains/:item.id",
  fetchActions: [
    "AUTH_LOGGED_IN",
    "DOMAINSEDITABLE_SAVE_FINISHED",
    "DOMAINSEDITABLE_DELETE_FINISHED"
  ],
  addons: {
    selectDomainsEditableGroups: createSelector(
      "selectDomainsEditableItems",
      items => {
        const groups = [];
        items.forEach(item => {
          if (groups.indexOf(item.grp) === -1) groups.push(item.grp);
        });
        return groups;
      }
    )
  }
});
