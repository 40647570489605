import React from "react";
import { connect } from "redux-bundler-react";
import TrainingForm from "./training-form";
import TrainingList from "./training-list";
import RoleFilter from "../../../app-containers/context-providers/role-filter";
import ReadOnly from "../../_shared/read-only-notification";

class TrainingCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      item: {},
    };
    this.enableEditing = this.enableEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderList = this.renderList.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.renderDelete = this.renderDelete.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
  }

  save() {
    const { doPersonnelTrainingSave } = this.props;
    const item = this.form.serialize();
    doPersonnelTrainingSave(item);
    this.cancelEditing();
  }

  delete() {
    const { doPersonnelTrainingDelete } = this.props;
    const item = this.form.serialize();
    doPersonnelTrainingDelete(item);
    this.cancelEditing();
  }

  enableEditing(item) {
    this.setState({
      editing: true,
      item: item ? item : {},
    });
  }

  cancelEditing() {
    this.setState({
      editing: false,
      item: {},
    });
  }

  renderForm() {
    const { trainingItemsObject } = this.props;
    const { editing, item } = this.state;
    if (!editing) return null;
    return (
      <TrainingForm
        item={item}
        trainingItemsObject={trainingItemsObject}
        ref={(el) => {
          this.form = el;
        }}
      />
    );
  }

  renderList() {
    const { editing } = this.state;
    const { items, isLoading } = this.props;
    if (editing) return null;
    return (
      <TrainingList
        isLoading={isLoading}
        items={items}
        enableEditing={this.enableEditing}
      />
    );
  }

  renderDelete() {
    const { editing, item } = this.state;
    if (!editing) return null;
    if (!item.hasOwnProperty("id")) return null;
    if (!item.id) return null;
    return (
      <div className="float-left mt-2">
        <button onClick={this.delete} className="btn btn-sm btn-danger">
          Delete
        </button>
      </div>
    );
  }

  renderActions() {
    const { editing } = this.state;
    if (!editing) return null;
    return (
      <div className="float-right mt-2">
        <button
          onClick={this.cancelEditing}
          className="btn btn-sm btn-secondary mr-2"
        >
          Cancel
        </button>
        <button onClick={this.save} className="btn btn-sm btn-success">
          Save
        </button>
      </div>
    );
  }

  render() {
    const { editing } = this.state;
    return (
      <div className="card">
        <div className="card-header">
          <div className="float-right">
            <RoleFilter allowRoles={[":ORG.ADMIN"]} alt={ReadOnly}>
              <button
                disabled={editing}
                className="btn btn-sm btn-ghost-primary"
                onClick={() => {
                  this.enableEditing();
                }}
              >
                <i className="mdi mdi-plus-circle-outline icon-inline"></i>
                Add New
              </button>
            </RoleFilter>
          </div>
          <i className="mdi mdi-book-open-page-variant icon-inline" /> Flight
          Training Folder
        </div>
        <div
          className="card-body"
          style={{ height: this.props.items.length >= 3 ? 205 : "auto" }}
        >
          {this.renderForm()}
          {this.renderList()}
          {this.renderDelete()}
          {this.renderActions()}
        </div>
      </div>
    );
  }
}

export default connect(
  "doPersonnelTrainingSave",
  "doPersonnelTrainingDelete",
  "selectTrainingItemsObject",
  TrainingCard
);
