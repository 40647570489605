import React from "react";
import { connect } from "redux-bundler-react";
import purify from "dompurify";
import NavBarItemDrawing from "./components/nav-bar-item-drawing";
import NumberedListGroup from "./components/numbered-list-group";
import ListGroup from "./components/list-group";
import SectionRow from "./components/section-row";
import SideBarItemDrawing from "./components/side-bar-item-drawing";
import RedirectButton from "./components/redirect-button";
import Chapter from "./components/chapter";
import Section from "./components/section";
import ButtonGroupDrawing from "./components/button-group-drawing";
import ButtonDrawing from "./components/button-drawing";
import Alert from "./components/alert";
import Row from "./components/row";
import RAW from "../missions/details/raw-tab/risk-assessment-matrix";
import Card from "./components/card";
import LoginGovIcon from "../../img/login-gov.svg"
import FileUploadInstructions from "./components/file-upload-card-instructions";
import AddMissionCard from "../missions/_shared/add-mission-card"

const ComponentTree = ({ documentationMarked: marked, obj }) => {
  let props = obj.props ? obj.props : {};
  if (typeof obj === 'string') {
    return <p dangerouslySetInnerHTML={{ __html: purify.sanitize(marked.parse(obj)) }} />;
  }
  switch (obj.componentClass) {
    case "Header":
      return (
        <div className="row">
          <div className="col">
            <h1 className="display-6" >{props.title}</h1>
            {obj.childComponents && obj.childComponents.map(child => {
              return <ComponentTree obj={child} documentationMarked={marked} />;
            })}
          </div>
        </div>
      );
    case "AddMissionCard":
      return <div className="display-only ml-0 position-relative d-inline-block w-75"  {...obj} {...props}>
        <AddMissionCard/>
      </div>
    case "FileUploadInstructions":
      return <FileUploadInstructions {...obj} {...props} />;
    case "Image":
      if (props.src === "LoginGovIcon") return <img {...obj} {...props} src={LoginGovIcon} height="16" />;
      return null;
    case "Base64Image":
      return <div {...obj} {...props} />;
    case "Alert":
      return <Alert {...obj} {...props} />;
    case "Stepper":
      return (
        <div className={obj.className}>
          <Stepper transfer={{
            complete_by: {},
            complete_date: {},
            ship_by: {},
            ship_date: {},
            from_org_id: {},
            to_org_id: {},
            from_status_id: {},
            receive_by: {},
            receive_date: {}
          }} />
        </div>
      );
    case "Chapter":
      return <Chapter {...obj} {...props} />;
    case "Section":
      return <Section {...obj} {...props} />;
    case "RedirectButton":
      return <RedirectButton {...obj} {...props} />;
    case "ListGroup":
      return <ListGroup {...obj} {...props} />;
    case "NumberedListGroup":
      return <NumberedListGroup {...obj} {...props} />;
    case "ButtonDrawing":
      return <ButtonDrawing {...obj} {...props} />;
    case "ButtonGroupDrawing":
      return <ButtonGroupDrawing {...obj} {...props} />;
    case "SideBarItemDrawing":
      return <SideBarItemDrawing {...obj} {...props} />;
    case "NavBarItemDrawing":
      return <NavBarItemDrawing {...obj} {...props} />;
    case "SectionRow":
      return <SectionRow {...obj} {...props} />;
    case "Row":
      return <Row {...obj} {...props} />;
    case "Card":
      return <Card {...obj} {...props} />;
    case "Paragraph":
      return (
        <p {...obj} {...props} >
          {
            obj.childComponents.map((child, key) => {
              if (typeof child === 'string') {
                return <p key={key} dangerouslySetInnerHTML={{ __html: purify.sanitize(marked.parse(child)) }} />;
              }
              else {
                let childProps = child.props ? child.props : {};
                return <ComponentTree documentationMarked={marked} obj={{ ...child, ...childProps }} />;
              }
            })
          }
        </p>
      );
    case "RAW":
      return (
        <div {...obj} {...props}>
          <RAW />
        </div>
      );
    default:
      return (
        <div {...obj} {...props}>
          {obj.childComponents && obj.childComponents.map(child => {
            return <ComponentTree obj={child} documentationMarked={marked} />;
          })}
        </div>
      );
  }
}

export default connect("selectDocumentationMarked", ComponentTree);
