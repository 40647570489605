import { connect } from "redux-bundler-react";
import Maintenance from "../context-providers/maintenance-card";
import OrgList from "./org-list";
import { useEffect, useState } from "react";

const OrgListPage = ({
  doDowntimeUpcomingFetch,
  doReadFileFetch,
  orgsItems: orgs,
  orgsForUser,
  orgsIsLoading,
  downtimeUpcomingItems: messages,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    doDowntimeUpcomingFetch();
    doReadFileFetch();
  }, []);

  const updateSearch = (e) => {
    const val = e.currentTarget.value;
    setSearchTerm(val);
  };

  if (!orgsForUser) return null;

  const myOrgs = orgs
    .filter((org) => {
      const isMember = orgsForUser.indexOf(org) !== -1;
      const hasRequested = org.has_requested;
      return isMember || hasRequested;
    })
    .sort((orgA, orgB) => {
      const memberA = orgsForUser.indexOf(orgA) !== -1;
      const memberB = orgsForUser.indexOf(orgB) !== -1;
      if (memberA) {
        if (memberB) {
          // member of both
          return orgA.name > orgB.name ? 1 : -1;
        } else {
          // member of a but not b
          return -1;
        }
      } else {
        if (memberB) {
          // member of b but not a
          return 1;
        } else {
          // not a member of either
          return orgA.name > orgB.name ? 1 : -1;
        }
      }
    });

  const otherOrgs = orgs.filter((org) => {
    const isMember = orgsForUser.indexOf(org) !== -1;
    const hasRequested = org.has_requested;
    const matcher = new RegExp(searchTerm, "ig");
    return (
      matcher.test(Object.values(org).join(" ")) && !(isMember || hasRequested)
    );
  });

  return (
    <div className="container" style={{ marginTop: "80px" }}>
      {messages &&
        messages.length > 0 &&
        messages.map((m) => <Maintenance message={m} />)}
      <h5 className="mb-4 text-muted">
        Welcome to the MIS for Aviation & Remote Systems, choose your
        organization to get started
      </h5>
      <h5>My Organizations</h5>
      <div className="row">
        <OrgList orgs={myOrgs} loading={orgsIsLoading} />
      </div>
      <h5>Find Organizations</h5>
      <div className="row">
        <div className="col-lg-12 mb-4">
          <input
            className="form-control"
            type="text"
            onChange={updateSearch}
            value={searchTerm}
            placeholder="Search Organizations..."
          ></input>
        </div>
      </div>
      <div className="row">
        <OrgList orgs={otherOrgs} loading={orgsIsLoading} />
      </div>
    </div>
  );
};

export default connect(
  "selectOrgsItems",
  "selectOrgsForUser",
  "selectOrgsIsLoading",
  "doOrgsShouldLoadItems",
  "selectDowntimeUpcomingItems",
  "doDowntimeUpcomingFetch",
  "doReadFileFetch",
  OrgListPage
);
