import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "efTwentyTwo",
  uid: "id",
  initialData: {
    _isSigning: false,
  },
  prefetch: true,
  routeParam: null,
  getTemplate: "/profile/:id/efTwentyTwo",
  postTemplate: "/profile/:id/efTwentyTwo",
  putTemplate: "/profile/:id/efTwentyTwo/:item.id",
  fetchActions: [
    "URL_UPDATED",
    "AUTH_LOGGED_IN",
    "EFTWENTYTWO_SAVE_FINISHED",
    "EFTWENTY_FETCH_FINISHED",
    "PROFILECURRENCY_FETCH_FINISHED",
  ],
  urlParamSelectors: ["selectProfileActiveData"],
  reduceFurther: (state, { type, payload }) => {
    switch (type) {
      case "EFTWENTYTWO_SIGN_STARTED":
      case "EFTWENTYTWO_SIGN_ERROR":
      case "EFTWENTYTWO_SIGN_FINISHED":
        return Object.assign({}, state, payload);
      default:
        return state;
    }
  },
  addons: {
    doEfTwentyTwoEntrySign:
      (item, callback, deferCallback) =>
      ({ dispatch, store, apiPut }) => {
        let profileId = store.selectProfileActiveData().id;
        dispatch({
          type: "EFTWENTYTWO_SIGN_STARTED",
          payload: { _isSigning: true },
        });
        apiPut(
          `/profile/${profileId}/efTwentyTwo/${item.id}/approval`,
          item,
          (err, response, body) => {
            if (err || response.statusCode !== 200) {
              dispatch({
                type: "EFTWENTYTWO_SIGN_ERROR",
                payload: {
                  _err: { err: err, response: response },
                  notification: {
                    statusCode: response.statusCode,
                  },
                  _isSigning: false,
                },
              });
            } else {
              // Make sure we're sending save_finished when we're done
              dispatch({
                type: "EFTWENTYTWO_SIGN_FINISHED",
                payload: {
                  _isSigning: false,
                },
              });

              if (deferCallback && callback) callback();
            }
            // if we get a callback, go ahead and fire it
            if (!deferCallback && callback) callback();
          }
        );
      },
  },
});
