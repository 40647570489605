import React, { useEffect, useReducer, useState } from "react";
import ReactSelect from "react-select";
import { connect } from "redux-bundler-react";
import FileUploadCard from "../_shared/file-upload-card/file-upload-card";
import purify from "dompurify";
import { marked } from "marked";
import DeleteButton from "../_shared/delete-button/delete-button";
import { find, sortBy } from "lodash";
import { roleAbbrevReverse, roleAbbreviations } from "../_shared/helper";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "UPDATE_INPUT":
      return { ...state, ...payload };
    default:
      return state;
  }
};

const ReadFileDialog = ({
  doDialogClose,
  doReadFileSave,
  doReadFileFetch,
  readFileIsSaving,
  domainsItemsByGroup,
  data = {},
  doReadFileDocsFetch,
  readFileDocs,
  doFileIoUpload,
  doFileIoDelete,
  doReadFileDelete,
  platformsItems,
  doPlatformsFetch,
}) => {
  const [preview, setPreview] = useState(false);

  /** Array of strings */
  const [makes, setMakes] = useState([]);
  /** Array of arrays */
  const [models, setModels] = useState([]);
  const [aircrafts, setAircrafts] = useState([1]);
  // go through each platform , get maek at ID append to makes
  // after creating makes array, go through initData allow_aircraft

  const initState = {
    initial_status: "red",
    ...data,
    allow_roles: data.allow_roles
      ? JSON.parse(data.allow_roles).map((r) => ({
          value: r,
          label: roleAbbrevReverse[r],
        }))
      : [],
    allow_flight_roles: data.allow_flight_roles
      ? JSON.parse(data.allow_flight_roles).map((r) => ({
          value: r,
          label: r,
        }))
      : [],
  };
  const [state, dispatch] = useReducer(reducer, initState);

  useEffect(() => {
    if (data.id) {
      doReadFileDocsFetch(data.id);

      let initMakes = data.allow_aircraft
        ? JSON.parse(data.allow_aircraft).map((id) => {
            return find(platformsItems, (obj) => obj.id === id).make;
          })
        : [];

      initMakes = [...new Set(initMakes)];

      let initModels = [];

      if (data.allow_aircraft) {
        JSON.parse(data.allow_aircraft).map((id) => {
          let platform = find(platformsItems, (obj) => obj.id === id);
          let idx = initMakes.indexOf(platform.make);
          if (initMakes.includes(platform.make)) {
            initModels[idx] = initModels[idx]
              ? [
                  ...initModels[idx],
                  {
                    value: platform.model,
                    label: platform.model,
                    platform_id: platform.id,
                    make: platform.make,
                  },
                ]
              : (initModels[idx] = [
                  {
                    value: platform.model,
                    label: platform.model,
                    platform_id: platform.id,
                    make: platform.make,
                  },
                ]);
          } else
            initModels.push([
              {
                value: platform.model,
                label: platform.model,
                platform_id: platform.id,
                make: platform.make,
              },
            ]);
        });
      }
      setMakes(initMakes);
      setModels(initModels);
      setAircrafts(new Array(initMakes.length).fill(1));
    }
    doPlatformsFetch();
  }, []);

  const _handleSelect = (obj, field) => {
    dispatch({
      type: "UPDATE_INPUT",
      payload: { [field]: obj ? obj : [] },
    });
  };

  const _handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      payload: { [e.target.name]: e.target.value },
    });
  };

  const _onSave = (keepOpen) => {
    let allow_aircraft = [];
    models.forEach((item, idx) => {
      if (!item || !item.length) {
        let allAircraftAtMake = platformsItems
          .filter((p) => p.make && p.make === makes[idx])
          .map((x) => ({ value: x.id }));
        allow_aircraft = [...allow_aircraft, ...allAircraftAtMake];
      } else {
        allow_aircraft = [
          ...allow_aircraft,
          ...item.map((i) => ({ value: i.platform_id })),
        ];
      }
    });

    let postData = {
      ...state,
      allow_roles:
        state.allow_roles.length === 0
          ? null
          : JSON.stringify(state.allow_roles.map((r) => r.value)),

      allow_flight_roles:
        state.allow_flight_roles.length === 0
          ? null
          : JSON.stringify(state.allow_flight_roles.map((r) => r.value)),

      allow_aircraft:
        allow_aircraft.length === 0
          ? null
          : JSON.stringify(allow_aircraft.map((r) => r.value)),
    };

    doReadFileSave(postData, (data) => {
      // if not adding files, just close the modal and refetch
      !keepOpen && doDialogClose();
      // if adding files, fetch latest data and docs
      doReadFileFetch();
      postData.id && doReadFileDocsFetch(postData.id);
      if (!postData.id && data && data[0].id) {
        dispatch({
          type: "UPDATE_INPUT",
          payload: { id: data[0].id },
        });
      }
    });
  };

  let platformMakes = [...new Set(platformsItems.map((item) => item.make))]
    .map((val) => !makes.includes(val) && { value: val, label: val })
    .filter((x) => !!x);
  let platformModels = platformsItems.map((item) => ({
    value: item.model,
    label: item.model,
    make: item.make,
    platform_id: item.id,
  }));

  return (
    <div className="modal-content ">
      <div className="modal-header">
        <h5 className="modal-title">New Read File</h5>
        <button
          className="close"
          type="button"
          aria-label="Close"
          onClick={doDialogClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body" style={{ overflow: "visible" }}>
        <div className="d-flex flex-column">
          <label>
            <b>Title</b>
          </label>
          <input
            className="form-control"
            name="title"
            onChange={_handleChange}
            value={state.title}
          />
        </div>

        <div className="d-flex flex-column mt-2">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <label className="m-0">
              <b>Description</b>
            </label>{" "}
            <button
              className="btn btn-sm btn-secondary ml-4"
              onClick={() => setPreview(!preview)}
            >
              Toggle {preview ? "Editor" : "Preview"}
            </button>
          </div>
          {preview ? (
            <div
              dangerouslySetInnerHTML={{
                __html: purify.sanitize(marked(state.description)),
              }}
            />
          ) : (
            <textarea
              className="form-control"
              name="description"
              onChange={_handleChange}
              value={state.description}
              rows={4}
            />
          )}
        </div>
        <div className="d-flex flex-column mt-2">
          <label>
            <b>Approval Roles</b>
          </label>
          <ReactSelect
            name="allow_roles"
            isMulti
            options={domainsItemsByGroup.approval_role.map((item) => ({
              value: roleAbbreviations[item.val],
              label: item.val,
            }))}
            onChange={(obj) => _handleSelect(obj, "allow_roles")}
            value={state.allow_roles}
          />
          <small className="mt-1">
            Leaving this dropdown empty will send this message to all users in
            MARS.
          </small>
        </div>
        <div className="d-flex flex-column mt-2">
          <label>
            <b>Pilot Roles</b>
          </label>
          <ReactSelect
            name="allow_flight_roles"
            isMulti
            options={domainsItemsByGroup.duties.map((item) => ({
              value: item.val,
              label: item.val,
            }))}
            onChange={(obj) => _handleSelect(obj, "allow_flight_roles")}
            value={state.allow_flight_roles}
          />
          <small className="mt-1">
            Leaving this dropdown empty will send this message to all users in
            MARS.
          </small>
        </div>
        <div className="d-flex flex-column mt-2">
          <label className="d-flex w-100 justify-content-between">
            <b>Aircraft</b>
            <button
              className="btn btn-outline-success py-0 px-1"
              onClick={() => setAircrafts([...aircrafts, 1])}
            >
              <i className="mdi mdi-plus mdi-14px mr-1" /> Add Aircraft
            </button>
          </label>
          {aircrafts.map((__, idx) => {
            return (
              <div className="d-flex mb-2 w-100" key={idx}>
                <div className="d-flex flex-column w-100 mr-1">
                  <label>Make</label>
                  <ReactSelect
                    name="allow_make"
                    options={sortBy(platformMakes, "label")}
                    onChange={(obj) => {
                      let tempMakes = makes;

                      if (!tempMakes[idx]) tempMakes.push(obj.value);
                      else tempMakes[idx] = obj.value;
                      setMakes([...tempMakes]);
                      if (!models[idx]) setModels([...models, []]);
                    }}
                    value={{
                      label: makes[idx],
                      value: makes[idx],
                    }}
                  />
                </div>
                <div className="d-flex flex-column w-100 ml-1">
                  <label>Model</label>
                  <ReactSelect
                    isMulti
                    name="allow_model"
                    options={sortBy(
                      makes[idx]
                        ? platformModels.filter((p) => p.make === makes[idx])
                        : platformModels,
                      "label"
                    )}
                    onChange={(obj) => {
                      let tempModels = models;
                      if (!tempModels[idx]) tempModels.push(obj);
                      else tempModels[idx] = obj;
                      setModels([...tempModels]);
                    }}
                    value={models[idx]}
                  />
                  <small className="mt-1">
                    If no models are selected, all aircraft at the associated
                    Make will be selected.
                  </small>
                </div>
              </div>
            );
          })}
        </div>
        <div className="d-flex flex-column mt-2" style={{ width: 200 }}>
          <label>
            <b>Initial Status</b>
          </label>
          <div className="btn-group">
            <button
              className={`btn ${
                state.initial_status === "red"
                  ? "btn-danger"
                  : "btn-outline-danger"
              } btn-sm`}
              onClick={() =>
                dispatch({
                  type: "UPDATE_INPUT",
                  payload: { initial_status: "red" },
                })
              }
            >
              <i className="mdi mdi-alert-outline mdi-18px" />
            </button>
            <button
              className={`btn ${
                state.initial_status === "yellow"
                  ? "btn-warning"
                  : "btn-outline-warning"
              } btn-sm`}
              onClick={() =>
                dispatch({
                  type: "UPDATE_INPUT",
                  payload: { initial_status: "yellow" },
                })
              }
            >
              <i className="mdi mdi-alert-circle-outline mdi-18px" />
            </button>
          </div>
        </div>
        {state.id && (
          <div className="mt-2">
            <FileUploadCard
              header
              allowRoles={["*.*"]}
              title="Associated Documents"
              items={readFileDocs}
              onUpload={(url, file, rel, data) =>
                doFileIoUpload(url, file, rel, data, () =>
                  doReadFileDocsFetch(state.id)
                )
              }
              onRemove={(url, s3_key, rel, admin) =>
                doFileIoDelete(url, s3_key, rel, admin, () =>
                  doReadFileDocsFetch(state.id)
                )
              }
              postUrl="/readFile/docs"
              removeUrl="/readFile/docs"
              rel="read_file_docs"
              data={{
                read_file_id: state.id,
              }}
              scrollable
            />
          </div>
        )}
      </div>
      <div className="modal-footer">
        {state.id && (
          <DeleteButton
            onDelete={() =>
              doReadFileDelete(state.id, () => doReadFileFetch(doDialogClose))
            }
          />
        )}
        {!state.id && (
          <button
            onClick={() => _onSave(true)}
            className="btn btn-sm btn-info"
            type="button"
          >
            {readFileIsSaving ? (
              <i className="mdi mdi-loading mdi-spin mdi-18px"></i>
            ) : (
              "Save and Add Files"
            )}
          </button>
        )}
        <button
          onClick={() => _onSave()}
          className="btn btn-sm btn-success"
          type="button"
        >
          {readFileIsSaving ? (
            <i className="mdi mdi-loading mdi-spin mdi-18px"></i>
          ) : (
            "Save and Close"
          )}
        </button>
      </div>
    </div>
  );
};

export default connect(
  "doOrgsFetch",
  "doDialogClose",
  "selectOrgsItemsAsOptionsWithId",
  "selectDomainsItemsByGroup",
  "selectReadFileIsSaving",
  "doReadFileSave",
  "doReadFileDelete",
  "doReadFileFetch",
  "doReadFileDocsFetch",
  "selectReadFileDocs",
  "doFileIoUpload",
  "doFileIoDelete",
  "selectReadFileItems",
  "selectPlatformsItems",
  "doPlatformsFetch",
  ReadFileDialog
);
