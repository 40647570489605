import React from "react";
import { connect } from "redux-bundler-react";
import StepperWidget from "./stepper-widget";
import RoleFilter from "../../app-containers/context-providers/role-filter";
import formatRelative from "date-fns/esm/formatRelative";
import isDate from "date-fns/isDate";

const CompleteText = ({ by, date }) => {
  const relDate = isDate(new Date(date))
    ? formatRelative(new Date(date), new Date())
    : "";
  return (
    <small style={{ whiteSpace: "nowrap" }} title={`${by} - ${relDate}`}>
      {by}
      <br />
      {relDate}
    </small>
  );
};

export default connect(
  "selectOrgsByRoute",
  "selectTransfersDocsItems",
  "selectTransfers4900DocsItems",
  "selectTransfersEquipmentItems",
  "selectTransfersStatusStep",
  "selectTransfersByRoute",
  "selectDomainsItemsByGroup",
  "selectTokenUsername",
  "doTransfersSave",
  ({
    orgsByRoute: org,
    transfersDocsItems: docs,
    transfers4900DocsItems,
    transfersEquipmentItems: equipment,
    transfersStatusStep: currentStep,
    transfersByRoute: transfer,
    tokenUsername: username,
    domainsItemsByGroup,
    doTransfersSave,
  }) => {
    if (!transfer) return null;
    const { transfer_status } = domainsItemsByGroup;
    const pending = transfer_status.filter((d) => {
      return d.val === "Pending";
    })[0];
    const inProgress = transfer_status.filter((d) => {
      return d.val === "In-Progress";
    })[0];
    const complete = transfer_status.filter((d) => {
      return d.val === "Complete";
    })[0];
    return (
      <div className="row" style={{ marginBottom: "30px" }}>
        <div className="col">
          <StepperWidget
            title="Step 1"
            description={`Ship Equipment
- Losing Org`}
            helperText="Check your equipment list, make any changes. Enter your tracking number below. Once the equipment is packed and shipped, mark it shipped here.  You must add at least some equipment and upload a signed 4900 to the 4900 Documents to mark the transfer as shipped."
            completeText={
              <CompleteText by={transfer.ship_by} date={transfer.ship_date} />
            }
            complete={currentStep > 1}
            enabled={
              transfers4900DocsItems.length >= 1 &&
              equipment.length >= 1 &&
              currentStep === 1 &&
              transfer.from_org_id === org.id
            }
            actionTitle="Shipped"
            action={() => {
              transfer.from_status_id = complete.id;
              transfer.to_status_id = pending.id;
              transfer.ship_date = new Date();
              transfer.ship_by = username;
              doTransfersSave(transfer);
            }}
            undoEnabled={
              currentStep === 2 &&
              transfer.from_org_id === org.id &&
              transfer.ship_by === username
            }
            undoActionTitle="Undo"
            undoAction={() => {
              transfer.from_status_id = inProgress.id;
              transfer.to_status_id = pending.id;
              transfer.ship_date = null;
              transfer.ship_by = null;
              doTransfersSave(transfer);
            }}
          />
        </div>
        <div className="col">
          <StepperWidget
            title="Step 2"
            description={`Shipment Received
- Gaining Org`}
            helperText="Note when the equipment arrives, then do an inventory check."
            completeText={
              <CompleteText
                by={transfer.receive_by}
                date={transfer.receive_date}
              />
            }
            complete={currentStep > 2}
            actionTitle="Accept"
            enabled={currentStep === 2 && transfer.to_org_id === org.id}
            action={() => {
              transfer.to_status_id = inProgress.id;
              transfer.receive_date = new Date();
              transfer.receive_by = username;
              doTransfersSave(transfer);
            }}
            undoEnabled={
              currentStep === 3 &&
              transfer.to_org_id === org.id &&
              transfer.receive_by === username
            }
            undoActionTitle="Undo"
            undoAction={() => {
              transfer.to_status_id = pending.id;
              transfer.receive_date = null;
              transfer.receive_by = null;
              doTransfersSave(transfer);
            }}
          />
        </div>
        <div className="col">
          <StepperWidget
            title="Step 3"
            description={`Transfer Complete
- Gaining Org`}
            helperText="Once the inventory is verified, upload PBO signed 4900 to 4900 Documents and select Complete"
            completeText={
              <CompleteText
                by={transfer.complete_by}
                date={transfer.complete_date}
              />
            }
            complete={currentStep > 3}
            actionTitle="Complete"
            enabled={currentStep === 3 && transfer.to_org_id === org.id}
            action={() => {
              transfer.to_status_id = complete.id;
              transfer.complete_date = new Date();
              transfer.complete_by = username;
              doTransfersSave(transfer);
            }}
            undoEnabled={
              currentStep === 4 &&
              transfer.to_org_id === org.id &&
              transfer.complete_by === username
            }
            undoActionTitle="Undo"
            undoAction={() => {
              transfer.to_status_id = inProgress.id;
              transfer.complete_date = null;
              transfer.complete_by = null;
              doTransfersSave(transfer);
            }}
          />
        </div>
        {transfer.type === "Temporary" ? (
          <div className="col">
            <StepperWidget
              title="Step 4"
              description={`Confirm Return
- Losing Org`}
              helperText="Once the transfer terms are complete and the equipment has been returned, confirm the return here and the equipment will be moved back to the losing org."
              completeText={
                <CompleteText
                  by={transfer.return_by}
                  date={transfer.return_date}
                />
              }
              complete={transfer.return_status_id === complete.id}
              actionTitle="Complete"
              enabled={
                transfer.to_status_id === complete.id &&
                transfer.return_status_id !== complete.id &&
                transfer.from_org_id === org.id
              }
              action={() => {
                transfer.return_status_id = complete.id;
                transfer.return_date = new Date();
                transfer.return_by = username;
                doTransfersSave(transfer);
              }}
            />
          </div>
        ) : null}
        <RoleFilter allowRoles={["HQ.MEMBER", "HQ.ADMIN"]}>
          <div className="col">
            <StepperWidget
              title="Review"
              description={`Transfer Reviewed
- HQ Aviation`}
              helperText="Verify that the property transfer has been properly recorded to include a completed 4900"
              completeText={
                <CompleteText
                  by={transfer.review_by}
                  date={transfer.review_date}
                />
              }
              complete={currentStep > 4}
              actionTitle="Verify"
              enabled={
                transfer.type === "Temporary"
                  ? transfer.return_status_id === complete.id
                  : currentStep === 4
              }
              action={() => {
                transfer.hq_status_id = complete.id;
                transfer.review_date = new Date();
                transfer.review_by = username;
                doTransfersSave(transfer);
              }}
              showRevertButton={true}
              revertAction={() => {
                transfer.to_status_id = inProgress.id;
                transfer.complete_date = null;
                transfer.complete_by = null;
                doTransfersSave(transfer);
              }}
            />
          </div>
        </RoleFilter>
      </div>
    );
  }
);
