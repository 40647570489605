export default {
  name: "mapInteractions",
  getReducer: () => {
    const initialData = {
      activeInteractions: []
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "MAP_INTERACTIONS_ADDED":
        case "MAP_INTERACTIONS_CLEARED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doMapInteractionsClear: () => ({ dispatch, store }) => {
    const map = store.selectMap();
    const interactions = store.selectMapInteractions();
    interactions.forEach(interaction => {
      map.removeInteraction(interaction);
    });
    store.doMapLzClearSelected();
    dispatch({
      type: "MAP_INTERACTIONS_CLEARED",
      payload: {
        activeInteractions: []
      }
    });
  },

  doMapInteractionsAdd: interactions => ({ dispatch, store }) => {
    store.doMapInteractionsClear();
    const map = store.selectMap();
    try {
      interactions.forEach(interaction => {
        map.addInteraction(interaction);
      });
      dispatch({
        type: "MAP_INTERACTIONS_ADDED",
        payload: {
          activeInteractions: interactions
        }
      });
    } catch (e) {
      console.log(e);
    }
  },

  selectMapInteractions: state => {
    return state.mapInteractions.activeInteractions;
  }
};
