export const customComparator = (valueA, valueB) =>
  valueA && valueB && valueA.toLowerCase().localeCompare(valueB.toLowerCase());

export const costComparator = (equipArr1, equipArr2) => {
  let valueA = equipArr1.reduce((prev, curr) => {
    return curr.cost + prev;
  }, 0);
  let valueB = equipArr2.reduce((prev, curr) => {
    return curr.cost + prev;
  }, 0);

  return valueA - valueB;
};

export const roleAbbreviations = {
  "Mission Briefing Officer": "MBO",
  "Aircrew Training Program Manager": "ATPM",
  // "Mission Approval Authority": "MAA",
  Commander: "CDR",
  Director: "DIR",
  "Backup ATPM": "BATPM",
};

export const roleColors = {
  "Mission Briefing Officer": "indigo",
  "Aircrew Training Program Manager": "teal",
  // "Mission Approval Authority": "cyan",
  Commander: "purple",
  Director: "info",
  "Backup ATPM": "orange",
};

export const roleAbbrevReverse = {
  MBO: "Mission Briefing Officer",
  ATPM: "Aircrew Training Program Manager",
  BATPM: "Backup ATPM",
  CDR: "Commander",
  DIR: "Director",
  // MAA: "Mission Approval Authority",
};
