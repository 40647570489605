import React from "react";
import ComponentTree from "../component-tree";
import "../documentation.css";
import classnames from "classnames";
const ButtonGroupDrawing = ({ className, childComponents }) => {
  const btnGrpCls = classnames({
    "btn-group": true,
    [className]: className
  });
  return (
    <div className={btnGrpCls}>
      {childComponents.map(btn => {
        return <ComponentTree obj={btn}/>
      })}
    </div>
  );
}
export default ButtonGroupDrawing;