import React from "react";

export default ({ name, label, value, checked, onChange, disabled }) => {
  return (
    <div className="form-check">
      <label className="form-check-label">
        <input
          onChange={onChange}
          className="form-check-input"
          type="radio"
          value={value}
          checked={checked}
          name={name}
          disabled={disabled}
        />
        {label}
      </label>
    </div>
  );
};
