import React, { useCallback, useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import ValuePicker from "../_shared/checkbox-search";
import TextSearch from "../_shared/text-search";
import DateRangeSearch from "../_shared/date-range-search";
import NumberRangeSearch from "../_shared/number-range-search";
import MissionsReportGrid from "./missions-report-grid";
import "../_shared/filter.css";

export default connect(
  "selectMissionsReportParameters",
  "selectMissionsReportItems",
  "selectMissionsReportIsStatsLoading",
  "doMissionsReportUpdateQueryString",
  "doMissionsReportDownloadAsCsv",
  "doMissionsReportFetch",
  ({
    missionsReportParameters,
    missionsReportItems,
    missionsReportIsStatsLoading,
    doMissionsReportUpdateQueryString,
    doMissionsReportDownloadAsCsv,
    doMissionsReportFetch,
  }) => {
    const [signatureRange, setSignatureRange] = useState({});
    const [approver, setApprover] = useState({});
    const [sorted, setSorted] = useState(missionsReportItems);
    const _setSignatureRange = (value) =>
      setSignatureRange((state) => ({ ...state, ...value }));

    const _setApprover = (value) =>
      setApprover((state) => ({ ...state, ...value }));

    const _isSignature = (column) =>
      ["plan_sig", "maa", "mbo", "atpm", "review_sig"].indexOf(column) !== -1;

    const _setSorted = useCallback((data) => {
      setSorted(data);
    }, []);

    /** this effect updates the query string on mount, previously this was in each checkbox component, and caused multiple instantanous fetch stats calls */
    useEffect(() => {
      let executeEffect = !!missionsReportParameters;
      if (executeEffect) {
        let query = {};
        missionsReportParameters.forEach((parameter) => {
          let { options, column } = parameter;
          if (options) {
            query[column] = [];
            const checkedKeys = options.reduce((prev, cur) => {
              if (!prev.hasOwnProperty(cur.id))
                prev[cur.id] = !!cur.checkedByDefault;
              return prev;
            }, {});
            Object.keys(checkedKeys).forEach((key) => {
              if (checkedKeys[key]) query[column].push(key);
            });
          }
        });
        doMissionsReportUpdateQueryString(query);
      }
      return () => (executeEffect = false);
    }, [missionsReportParameters]);

    const renderControls = () => {
      if (!missionsReportParameters || missionsReportIsStatsLoading)
        return null;
      const controls = missionsReportParameters.map((parameter, i) => {
        if (parameter.type === "checkbox-search") {
          return (
            <ValuePicker
              key={i}
              column={parameter.column}
              onChange={doMissionsReportUpdateQueryString}
              title={parameter.title}
              options={parameter.options}
            />
          );
        } else if (parameter.type === "date-range-search") {
          return (
            <DateRangeSearch
              key={i}
              column={parameter.column}
              onChange={
                _isSignature(parameter.column)
                  ? _setSignatureRange
                  : doMissionsReportUpdateQueryString
              }
              title={parameter.title}
            />
          );
        } else if (parameter.type === "number-range-search") {
          return (
            <NumberRangeSearch
              key={i}
              column={parameter.column}
              onChange={doMissionsReportUpdateQueryString}
              title={parameter.title}
              min={Number(parameter.min)}
              max={Number(parameter.max)}
            />
          );
        } else if (parameter.type === "text-search") {
          return (
            <TextSearch
              key={i}
              column={parameter.column}
              onChange={
                _isSignature(parameter.column)
                  ? _setApprover
                  : doMissionsReportUpdateQueryString
              }
              title={parameter.title}
            />
          );
        } else {
          return null;
        }
      });
      return controls;
    };

    return (
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-3 filter">{renderControls()}</div>
          <div className="col-9">
            <div className="card">
              <div className="card-header">
                <div className="float-right">
                  <button
                    onClick={() => doMissionsReportDownloadAsCsv(sorted)}
                    className="btn btn-sm btn-ghost-primary"
                    disabled={!missionsReportItems.length}>
                    <i className="mdi mdi-download icon-inline"></i>CSV
                  </button>
                </div>
                Query Mission Records ({sorted.length})
              </div>
              <div className="card-body p-0">
                <MissionsReportGrid
                  signatureRangeObject={signatureRange}
                  approverObject={approver}
                  sorted={sorted}
                  setSorted={_setSorted}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
