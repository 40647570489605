import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import TabContainer from "../_shared/tab-container/tab-container";
import Details from "./details-tab/details-tab";
import Inventory from "./inventory-tab/inventory-tab";
import PersonnelTab from "./personnel-tab/personnel-tab";
import MapTab from "./map-tab/map-tab";
import Raw from "./raw-tab/raw";
import ApprovalsTab from "./approvals-tab/approvals";
import DocumentsTab from "./documents-tab/documents";
import FlightsTab from "./flights-tab/flights-tab";
import DebriefTab from "./debrief-tab/debrief-tab";
import Loader from "../../../app-components/loader";
import MissionAreaTab from "./mission-area-tab/mission-area";
import FiguresTab from "./figures-tab/figures-tab";
import NotesTab from "./notes-tab/notes-tab";
import ActionsDropdown from "./mission-actions/actions-dropdown";
import CloseOutButton from "./mission-actions/close-out-button";
import MissionHelpButton from "./mission-actions/mission-help";
import PrintButton from "../../../app-components/print-button/print-button";
import MissionPrintModal from "../../../app-components/print-button/mission-print-modal";
import Adjudications from "./adjudications-tab/adjudications";
const tabConfig = {
  "Mission Details": Details,
  Inventory: Inventory,
  Personnel: PersonnelTab,
  "Area of Interest": MapTab,
  "Mission Area": MissionAreaTab,
  "Risk Assessment": Raw,
  Figures: FiguresTab,
  Documents: DocumentsTab,
  Notes: NotesTab,
  Signatures: ApprovalsTab,
  Flights: {
    component: FlightsTab,
    disabled: (mission) => {
      return (
        ["draft", "pending approval"].indexOf(mission.approval_status) !== -1
      );
    },
  },
  Debrief: {
    component: DebriefTab,
    disabled: (mission) => {
      return (
        ["draft", "pending approval"].indexOf(mission.approval_status) !== -1
      );
    },
  },
  Adjudications: Adjudications,
};

const MissionDetails = (props) => {
  const {
    missionsByRoute: mission,
    missionsIsLoading,
    doMissionsFetch,
  } = props;

  useEffect(() => {
    if (!missionsIsLoading) doMissionsFetch();
  }, []);

  if (missionsIsLoading || !mission)
    return (
      <div>
        <Loader opt="dissolve-cube" />
      </div>
    );
  else {
    return (
      <div className="container-fluid mt-4">
        <div className="clearfix">
          <div className="float-right">
            {/** Temporarily disabling printing if mission is not approved */}

            <PrintButton
              modal={MissionPrintModal}
              className="btn-sm mt-auto mb-0"
              state={mission}
              resultFilePrefix="MISSION-PACKET">
              <i className="mdi mdi-printer mr-2" />
              Print Mission Packet...
            </PrintButton>

            <MissionHelpButton />
            <CloseOutButton />
            <span className="pl-3">
              <ActionsDropdown />
            </span>
          </div>
          <h2>{`Mission Packet: ${
            mission.slug.toUpperCase() || "loading..."
          }`}</h2>
        </div>
        <TabContainer config={tabConfig} mission={mission} />
      </div>
    );
  }
};

export default connect(
  "selectRouteParams",
  "selectMissionsByRoute",
  "doMissionsFetch",
  "selectMissionsIsLoading",
  MissionDetails
);
