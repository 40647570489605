import React, { useEffect, useReducer } from "react";
import { connect } from "redux-bundler-react";
import ReactSelect from "react-select";
import EquipmentCard from "../equipment-card";
import DeleteButton from "../../../../_shared/delete-button/delete-button";
import { find, sortBy } from "lodash";

const Row = ({ title, items }) => {
  return (
    <div className="d-flex flex-column">
      <h6 className="w-100 border-bottom pb-2">{title}</h6>
      <div
        className="w-100 pb-2"
        style={{
          display: "grid",
          gridTemplateColumns: items.length === 0 ? "1fr" : "1fr 1fr 1fr 1fr",
          gap: 16,
        }}
      >
        {items.length === 0 && (
          <p className="m-3 text-muted w-100">
            There are currently no {title.toLowerCase()} selected. Selected{" "}
            {title.toLowerCase()} will show here.
          </p>
        )}
        {items.map((item) => (
          <EquipmentCard title={title} item={item} key={title} />
        ))}
      </div>
    </div>
  );
};
const reducer = (state, { type, payload }) => {
  switch (type) {
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, payload);
    case "UPDATE_INPUT":
      return { ...state, ...payload };
    default:
      return state;
  }
};
/**
 *
 * This modal should be a good ui that creates a configuration in mission_configurations table, needs to also in a callback populate mission_aircraft batteries etc...
 */
const ConfigurationModal = ({
  doDialogClose,
  aircraftItems,
  batteriesItems,
  payloadsItems,
  gcsItems,
  mmsItems,
  doMissionsConfigurationsSave,
  configuration,
  missionsConfigurationIsPosting,
  missionsConfigurationIsDeleting,
  doMissionsConfigurationsDelete,
  doMissionsConfigurationsFetch,
  configurationId,
  aircraftConfigurationItems,
}) => {
  let initialState = {
    aircraft_ids: null,
    batteries_ids: [],
    payloads_ids: [],
    gcs_ids: [],
    mms_ids: [],
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  let availableGcs = [];
  let availablePayloads = [];
  if (state.aircraft_ids) {
    let selectedAircraft = find(aircraftItems, {
      id: state.aircraft_ids.value,
    });
    let availableConfigs = [];
    if (selectedAircraft)
      availableConfigs = aircraftConfigurationItems.filter(
        (item) => item.aircraft_id === selectedAircraft.platform_id
      );
    if (availableConfigs && availableConfigs.length > 0) {
      availableConfigs.forEach((item) => {
        if (item.payload_id) {
          let arr = payloadsItems.filter(
            (g) => g.parent_id === item.payload_id
          );
          if (arr && arr.length > 0) {
            arr.forEach((obj) => {
              if (obj) {
                availablePayloads.push({
                  value: obj.id,
                  label: obj.name,
                });
              }
            });
          }
        }
        if (item.gcs_id) {
          let arr = gcsItems.filter((g) => g.parent_id === item.gcs_id);
          if (arr && arr.length > 0) {
            arr.forEach((obj) => {
              availableGcs.push({
                value: obj.id,
                label: obj.name,
              });
            });
          }
        }
      });
    }
  }

  useEffect(() => {
    if (configuration) {
      let newState = {};
      Object.keys(configuration).forEach((key) => {
        if (key === "Aircraft") {
          configuration[key].forEach((item) => {
            newState["aircraft_ids"] = {
              value: item.equipment_id,
              label: item.equipment_name,
            };
          });
        } else if (key === "Batteries") {
          configuration[key].forEach((item) => {
            if (newState["batteries_ids"])
              newState["batteries_ids"].push({
                value: item.equipment_id,
                label: item.equipment_name,
              });
            else
              newState["batteries_ids"] = [
                { value: item.equipment_id, label: item.equipment_name },
              ];
          });
        } else if (key === "GCS") {
          configuration[key].forEach((item) => {
            if (newState["gcs_ids"])
              newState["gcs_ids"].push({
                value: item.equipment_id,
                label: item.equipment_name,
              });
            else
              newState["gcs_ids"] = [
                { value: item.equipment_id, label: item.equipment_name },
              ];
          });
        } else if (key === "MMS") {
          configuration[key].forEach((item) => {
            if (newState["mms_ids"])
              newState["mms_ids"].push({
                value: item.equipment_id,
                label: item.equipment_name,
              });
            else
              newState["mms_ids"] = [
                { value: item.equipment_id, label: item.equipment_name },
              ];
          });
        } else if (key === "Payloads") {
          configuration[key].forEach((item) => {
            if (newState["payloads_ids"])
              newState["payloads_ids"].push({
                value: item.equipment_id,
                label: item.equipment_name,
              });
            else
              newState["payloads_ids"] = [
                { value: item.equipment_id, label: item.equipment_name },
              ];
          });
        }
      });
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: newState,
      });
    }
  }, [configuration]);

  let arrToOptions = (arr, type) => {
    if (type === "aircraft") {
      return sortBy(
        arr.map((i) => ({
          value: i.id,
          label: `${i.make} ${i.model}`,
          name: i.name,
        })),
        "label"
      );
    } else
      return sortBy(
        arr.map((i) => ({ value: i.id, label: i.name })),
        "label"
      );
  };
  const handleSelect = (obj, field) => {
    dispatch({
      type: "UPDATE_INPUT",
      payload: { [field]: obj ? obj : [] },
      name: obj.name,
    });
  };

  const _onSave = () => {
    let postObject = {};
    Object.keys(state).forEach((key) => {
      if (key === "aircraft_ids" && state[key])
        postObject[key] = state[key].value;
      else if (state[key] && state[key].length > 0)
        postObject[key] = state[key].map((obj) => obj.value).join(".");
    });
    if (configurationId)
      postObject = { ...postObject, configuration_id: configurationId };
    doMissionsConfigurationsSave(postObject, doMissionsConfigurationsFetch);
  };
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Equipment Configuration Wizard</h5>
        <button
          onClick={doDialogClose}
          className="close"
          type="button"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="d-flex w-100">
          <div className="d-flex flex-column w-25 border-right pr-3">
            <div className="w-100 d-flex flex-column">
              <h4 className="border-bottom pb-2">Add Equipment</h4>
              <h6 className="text-muted">
                <small>
                  Equipment shown in dropdowns are those that are USACE approved
                  and approved for use with other selected equipment.
                </small>
              </h6>
            </div>
            <div className="d-flex flex-column w-100 mb-2">
              <label className="mb-1">Aircraft</label>
              <ReactSelect
                className="w-100"
                getOptionLabel={(option) => (
                  <span className="d-flex flex-column">
                    {option.label}
                    <small className="text-muted">{option.name}</small>
                  </span>
                )}
                options={arrToOptions(aircraftItems, "aircraft")}
                menuPosition="fixed"
                value={state.aircraft_ids}
                onChange={(obj) => handleSelect(obj, "aircraft_ids")}
              />
            </div>
            <div className="d-flex flex-column w-100 mb-2">
              <label className="mb-1">Batteries</label>
              <ReactSelect
                className="w-100"
                options={arrToOptions(batteriesItems)}
                menuPosition="fixed"
                isMulti
                value={state.batteries_ids}
                onChange={(obj) => handleSelect(obj, "batteries_ids")}
                isDisabled={!state.aircraft_ids}
              />
            </div>
            <div className="d-flex flex-column w-100 mb-2">
              <label className="mb-1">Payloads</label>
              <ReactSelect
                className="w-100"
                options={availablePayloads}
                menuPosition="fixed"
                isMulti
                value={state.payloads_ids}
                onChange={(obj) => handleSelect(obj, "payloads_ids")}
                isDisabled={!state.aircraft_ids}
              />
            </div>
            <div className="d-flex flex-column w-100 mb-2">
              <label className="mb-1">GCS</label>
              <ReactSelect
                className="w-100"
                options={availableGcs}
                menuPosition="fixed"
                isMulti
                value={state.gcs_ids}
                onChange={(obj) => handleSelect(obj, "gcs_ids")}
                isDisabled={!state.aircraft_ids}
              />
            </div>
            <div className="d-flex flex-column w-100 mb-2">
              <label className="mb-1">MMS</label>
              <ReactSelect
                className="w-100"
                options={arrToOptions(mmsItems)}
                menuPosition="fixed"
                isMulti
                value={state.mms_ids}
                onChange={(obj) => handleSelect(obj, "mms_ids")}
                isDisabled={!state.aircraft_ids}
              />
            </div>
          </div>
          <div className="d-flex flex-column w-75 p-3">
            <Row
              title="Aircraft"
              items={state.aircraft_ids ? [state.aircraft_ids] : []}
            />
            <Row title="Batteries" items={state.batteries_ids} />
            <Row title="Payloads" items={state.payloads_ids} />
            <Row title="GCS" items={state.gcs_ids} />
            <Row title="MMS" items={state.mms_ids} />
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button
          onClick={doDialogClose}
          className="btn btn-sm btn-secondary"
          type="button"
        >
          Close
        </button>
        {configurationId && (
          <DeleteButton
            onDelete={() =>
              doMissionsConfigurationsDelete(
                configurationId,
                doMissionsConfigurationsFetch
              )
            }
          />
        )}
        <button
          onClick={_onSave}
          className={
            configuration ? "btn btn-sm btn-primary" : "btn btn-sm btn-success"
          }
          type="button"
        >
          {missionsConfigurationIsPosting ? (
            <i className="mdi mdi-14px mdi-loading mdi-spin" />
          ) : configuration ? (
            "Update Configuration"
          ) : (
            "Create Configuration"
          )}
        </button>
      </div>
    </div>
  );
};

export default connect(
  "doDialogClose",
  "doMissionsConfigurationsSave",
  "doMissionsConfigurationsDelete",
  "doMissionsConfigurationsFetch",
  "selectMissionsConfigurationIsFetching",
  "selectMissionsConfigurationIsPosting",
  "selectMissionsConfigurationIsDeleting",
  "selectAircraftConfigurationItems",
  ConfigurationModal
);
