import React from "react";

class SidebarNavTitle extends React.Component {
  render() {
    const { title } = this.props;
    return <li className="nav-title">{title}</li>;
  }
}

export default SidebarNavTitle;
