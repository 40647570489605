import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import FigurePreview from "./figure-preview";

export default connect(
  "doMissionsFiguresDelete",
  "doDialogOpen",
  ({ figure, doMissionsFiguresDelete, doDialogOpen }) => {
    const [isConfirming, setConfirming] = useState(false);
    return (
      <div className="col-sm-6 col-lg-4">
        <div className="brand-card pointer">
          <div
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 999,
            }}
          >
            {isConfirming ? (
              <div className="btn-group">
                <button
                  onClick={() => {
                    setConfirming(false);
                    doMissionsFiguresDelete(figure);
                  }}
                  className="btn btn-sm btn-danger"
                >
                  Confirm
                </button>
                <button
                  onClick={() => {
                    setConfirming(false);
                  }}
                  className="btn btn-sm btn-secondary"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <button
                onClick={() => {
                  setConfirming(true);
                }}
                className="btn btn-sm btn-danger"
              >
                Delete
              </button>
            )}
          </div>
          <div
            className="brand-card-header"
            style={{
              backgroundImage: `url(${figure.img})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              height: "15rem",
            }}
            onClick={() => {
              doDialogOpen({
                content: FigurePreview,
                props: { figure: figure },
                size: "xl",
              });
            }}
          />
          <div
            className="brand-card-body"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          >
            <div>{figure.caption}</div>
          </div>
        </div>
      </div>
    );
  }
);
