import React from "react";
import { connect } from "redux-bundler-react";
import { isEmpty } from "lodash";
import { debrief } from "../../../../models";
import SchemaForm from "../../../../app-components/schema-form/schema-form";
import MissionStatusFilter from "../../_shared/mission-status-filter";

class Debrief extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.renderForm = this.renderForm.bind(this);
    this.save = this.save.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.enableEditing = this.enableEditing.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  save() {
    const { doMissionsDebriefSave } = this.props;
    if (this.form.isValid()) {
      const data = this.form.serialize();
      this.setState(
        {
          editing: false,
        },
        () => {
          doMissionsDebriefSave(data);
        }
      );
    }
  }

  enableEditing() {
    this.setState({
      editing: true,
    });
  }

  cancelEditing() {
    const { missionsDebriefItems, missionsByRoute } = this.props;
    const debriefObj =
      missionsDebriefItems && missionsDebriefItems.length
        ? missionsDebriefItems[0]
        : {};
    if (isEmpty(debriefObj)) debriefObj.mission_id = missionsByRoute.id;
    this.setState(
      {
        editing: false,
      },
      () => {
        this.form.reset({ data: debriefObj, schema: debrief });
      }
    );
  }

  // handleDelete(){
  //   const { doMissionsDebriefDelete, doUpdateRelativeUrl, routeParams, missionsByRoute } = this.props;
  //   this.setState({
  //     editing: false
  //   }, () => {
  //     doMissionsDebriefDelete(, () => {
  //       doUpdateRelativeUrl(`/${routeParams.orgSlug}/planner`);
  //     }, true);
  //   })
  // }

  renderActions() {
    const { editing } = this.state;
    if (!editing) return null;
    return (
      <div>
        <div className="float-right">
          <button
            onClick={this.cancelEditing}
            className="btn btn-sm btn-secondary mr-2"
          >
            Cancel
          </button>
          <button onClick={this.save} className="btn btn-sm btn-success">
            Save
          </button>
        </div>
      </div>
    );
  }

  renderForm() {
    const { editing } = this.state;
    const { missionsDebriefItems, missionsByRoute } = this.props;
    const debriefObj =
      missionsDebriefItems && missionsDebriefItems.length
        ? missionsDebriefItems[0]
        : {};
    if (isEmpty(debriefObj)) {
      debriefObj.mission_id = missionsByRoute.id;
      return (
        <SchemaForm
          ref={(el) => {
            this.form = el;
          }}
          inline={true}
          displayOnly={!editing}
          schema={debrief}
          data={debriefObj}
        />
      );
    } else {
      return (
        <SchemaForm
          ref={(el) => {
            this.form = el;
          }}
          inline={true}
          displayOnly={!editing}
          schema={debrief}
          data={debriefObj}
        />
      );
    }
  }

  render() {
    const { editing } = this.state;
    return (
      <div>
        <div className="clearfix mb-2">
          <div className="float-right">
            <MissionStatusFilter
              denyStatus={{
                mission_status: ["complete", "archived"],
              }}
            >
              <button
                disabled={editing}
                className="btn btn-sm btn-ghost-primary"
                onClick={this.enableEditing}
              >
                <i className="mdi mdi-pencil icon-inline"></i>
                Edit
              </button>
            </MissionStatusFilter>
          </div>
        </div>

        {this.renderForm()}

        <div className="clearfix">{this.renderActions()}</div>
      </div>
    );
  }
}

export default connect(
  "selectMissionsByRoute",
  "selectMissionsDebriefItems",
  "doMissionsDebriefSave",
  "doMissionsDebriefDelete",
  "doUpdateRelativeUrl",
  Debrief
);
