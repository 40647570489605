import { connect } from "redux-bundler-react";
import Grid from "../_shared/grid";
import { useEffect } from "react";
import StatusCell from "../missions/details/coa-tab/status-cell";
import coaUploadModal from "./coa-upload-modal";
import tokml from "tokml";
import PrintButton from "../../app-components/print-button/print-button";
import { sortBy } from "lodash";
import { customComparator } from "../_shared/helper";

const CoaBacklog = ({
  coaDocsItems,
  doCoaDocsFetch,
  doFileIoDownload,
  profilesAll,
  doFetchProfilesAll,
  doCoaDocsSave,
  doFileIoUpload,
  doDialogOpen,
  coaApprovalDocsItems,
  doFetchAllMissionsAoi,
  allMissionsAoiItems,
  doCoaBacklogDownloadCsv,
}) => {
  useEffect(() => {
    doCoaDocsFetch();
    doFetchProfilesAll();
  }, []);

  useEffect(() => {
    doFetchAllMissionsAoi();
  }, [coaDocsItems]);

  const config = {
    columnDefs: [
      {
        headerName: "COA",
        field: "",
        width: 100,
        cellRenderer: (params) => {
          if (!!params.data.is_reviewed) {
            return (
              <div className="btn-group">
                <button
                  className="btn btn-sm btn-outline-info"
                  onClick={() =>
                    doDialogOpen({
                      content: coaUploadModal,
                      props: {
                        docId: params.data.id,
                        missionId: params.data.mission_id,
                      },
                    })
                  }
                  title="Upload Approved COA"
                >
                  <i className="mdi mdi-upload"></i>
                </button>
                <button
                  className="btn btn-sm btn-info"
                  onClick={() => {
                    doFileIoDownload(params.data.s3_key, () => {
                      doCoaDocsSave({
                        ...params.data,
                        is_reviewed: new Date(),
                      });
                    });
                  }}
                  title="Download"
                >
                  <i className="mdi mdi-download"></i>
                </button>
              </div>
            );
          }
          return (
            <button
              className="btn btn-sm btn-info"
              onClick={() => {
                doFileIoDownload(params.data.s3_key, () => {
                  doCoaDocsSave({ ...params.data, is_reviewed: new Date() });
                });
              }}
              title="Download"
            >
              <i className="mdi mdi-download"></i>
            </button>
          );
        },
      },
      {
        headerName: "KML",
        field: "",
        width: 75,
        cellRenderer: (params) => {
          let missionAoi = allMissionsAoiItems.filter(
            (item) => item.mission_id === params.data.mission_id
          );
          missionAoi =
            missionAoi &&
            missionAoi[0] &&
            missionAoi[0].shape &&
            JSON.parse(missionAoi[0].shape);
          if (missionAoi) {
            const kmlString = tokml(missionAoi);

            // Create a Blob from the KML string
            const blob = new Blob([kmlString], {
              type: "application/vnd.google-earth.kml+xml",
            });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            return (
              <a
                className="btn btn-sm btn-info"
                title="Download"
                href={url} // Use the generated URL here
                download={`${params.data.mission_name}_KML.kml`}
              >
                <i className="mdi mdi-download"></i>
              </a>
            );
          }
        },
      },
      {
        headerName: "DD-2977",
        field: "",
        width: 100,
        cellRenderer: (params) => {
          return (
            <PrintButton
              className=""
              buttonRenderFn={(clickFn) => {
                return (
                  <button
                    className="btn btn-sm btn-info"
                    onClick={clickFn}
                    title="Download"
                  >
                    <i className="mdi mdi-download"></i>
                  </button>
                );
              }}
              state={{ slug: params.data.mission_slug }}
              missionDetatchedFromOrg={true}
              immediatePrint={true}
              options={[
                {
                  resultFileName: `${params.data.mission_name}_2977.pdf`,
                  file: "risk-assessment.template.json",
                },
              ]}
            />
          );
        },
      },
      {
        headerName: "Mission Name",
        field: "mission_name",
        cellRenderer: (params) => {
          return (
            <a
              href={`/${
                params.data.org_name && params.data.org_name.toLowerCase()
              }/mission/${params.data.mission_slug}`}
            >
              {params.data.mission_name}
            </a>
          );
        },
      },
      {
        headerName: "Org Name",
        field: "org_name",
        width: 100,
      },
      {
        headerName: "Reviewed",
        field: "is_reviewed",
        comparator: customComparator,
        cellRenderer: (params) => {
          return <StatusCell status={params.data.is_reviewed} />;
        },
      },
      {
        headerName: "Submitted",
        field: "is_submitted",
        comparator: customComparator,
        cellRenderer: (params) => {
          if (params.data.is_submitted)
            return <StatusCell status={params.data.is_submitted} />;
          else
            return (
              <button
                className="btn btn-outline-success btn-sm"
                onClick={(e) =>
                  doCoaDocsSave(
                    { ...params.data, is_submitted: new Date() },
                    doCoaDocsFetch
                  )
                }
              >
                Mark as Submitted
              </button>
            );
        },
      },
      {
        headerName: "Completed",
        field: "is_completed",
        sortable: false,
        cellRenderer: (params) => {
          let approvalDoc = coaApprovalDocsItems.filter(
            (doc) => doc.coa_doc_id === params.data.id
          );
          let isCompleted =
            approvalDoc && approvalDoc.length > 0 && approvalDoc[0].create_date;
          if (isCompleted) return <StatusCell status={isCompleted} />;
        },
      },
      {
        headerName: "Submit Date",
        field: "create_date",
        cellRenderer: (params) => (
          <p className="m-0">{params.data.create_date.split("T")[0]}</p>
        ),
      },
      {
        headerName: "Submitted By",
        field: "last_updated_by",
        cellRenderer: (params) => {
          let userProfile = profilesAll?.filter(
            (p) => p.keycloak_id === params.data.last_updated_by
          );
          return (
            <p className="m-0">
              {userProfile && userProfile.length > 0 && userProfile[0].name}
            </p>
          );
        },
      },
    ],
  };

  let sorted = sortBy(coaDocsItems, ["is_reviewed", "is_submitted"]).reverse();

  return (
    <div className="container-fluid" style={{ marginTop: "80px" }}>
      <div className="card w-75 mx-auto">
        <div className="card-header">
          <div className="float-right">
            <button
              onClick={doCoaBacklogDownloadCsv}
              className="btn btn-sm btn-ghost-primary"
              disabled={!sorted.length}
            >
              <i className="mdi mdi-download icon-inline"></i>CSV
            </button>
          </div>
          <i className="mdi mdi-format-list-text"></i>COA Backlog
        </div>
        <div className="card-body">
          <Grid
            config={config}
            data={sorted}
            style={{ height: "calc(100vh - 120px)", width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(
  "selectCoaDocsItems",
  "selectCoaApprovalDocsItems",
  "doCoaDocsFetch",
  "doFetchAllMissionsAoi",
  "doFileIoDownload",
  "selectProfilesAll",
  "doFetchProfilesAll",
  "doCoaDocsSave",
  "doDialogOpen",
  "selectAllMissionsAoiItems",
  "doCoaBacklogDownloadCsv",
  CoaBacklog
);
