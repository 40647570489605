import classnames from "classnames";
const SideBarItemDrawing = ({ title, iconCls, className }) => {
  const _className = classnames({
    "menu-item-drawing sidebar-item-drawing badge": true,
    [className]: className
  });
  const _iconCls = classnames({
    [iconCls]: iconCls,
    "nav-icon": true,
  });
  return (
    <span className={_className}>
      <i className={_iconCls} /> {title}
    </span>
  );
}
export default SideBarItemDrawing;