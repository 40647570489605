import React from "react";
import PlatformsListItem from "./platforms-list-item";
import Loader from "../../../app-components/loader";

export default ({ items, editing, isLoading, onRemove }) => {
  if (isLoading) return <Loader />;
  if (!items.length) {
    return (
      <ul className="list-group">
        <li className="list-group-item">No Associated Platforms...</li>
      </ul>
    );
  } else {
    return (
      <ul className="list-group">
        {items.map((item, i) => {
          return (
            <PlatformsListItem
              item={item}
              editing={editing}
              key={i}
              onRemove={onRemove}
            />
          );
        })}
      </ul>
    );
  }
};
