import React from "react";
import { connect } from "redux-bundler-react";
import SoftwareList from "./software-list";
import SoftwarePicker from "./software-picker";
import RoleFilter from "../../../app-containers/context-providers/role-filter";
import ReadOnly from "../../_shared/read-only-notification";

class GcsSoftwareCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false
    };
    this.enableEditing = this.enableEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.renderPicker = this.renderPicker.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  enableEditing() {
    this.setState({
      editing: true
    });
  }

  cancelEditing() {
    this.setState({
      editing: false
    });
  }

  renderPicker() {
    const { editing } = this.state;
    const { doGcsSoftwareSave } = this.props;
    if (!editing) return null;
    return (
      <SoftwarePicker
        onAdd={item => {
          doGcsSoftwareSave(item);
        }}
      />
    );
  }

  renderActions() {
    const { editing } = this.state;
    if (!editing) return null;
    return (
      <div className="float-right mt-2">
        <button
          onClick={this.cancelEditing}
          className="btn btn-sm btn-secondary"
        >
          Done
        </button>
      </div>
    );
  }

  render() {
    const { editing } = this.state;
    const { items, isLoading } = this.props;
    return (
      <div className="card">
        <div className="card-header">
          <div className="float-right">
            <RoleFilter
              allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
              alt={ReadOnly}
            >
              <button
                disabled={editing}
                className="btn btn-sm btn-ghost-primary"
                onClick={this.enableEditing}
              >
                <i className="mdi mdi-pencil icon-inline"></i>
                Edit
              </button>
            </RoleFilter>
          </div>
          <i className="mdi mdi-code-tags icon-inline" /> Associated Software
        </div>
        <div className="card-body">
          <SoftwareList isLoading={isLoading} items={items} editing={editing} />
          {this.renderPicker()}
          {this.renderActions()}
        </div>
      </div>
    );
  }
}

export default connect(
  "doGcsSoftwareSave",
  GcsSoftwareCard
);
