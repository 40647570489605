import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "training",
  uid: "id",
  prefetch: true,
  staleAfter: 900000,
  persist: true,
  routeParam: null,
  getTemplate: "/training",
  putTemplate: "/training/:item.id",
  postTemplate: "/training",
  deleteTemplate: "/training/:item.id",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN"]
});
