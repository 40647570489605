import { find } from "lodash";
import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";

export default createRestBundle({
  name: "profile",
  uid: "id",
  initialData: {
    _profilesByOrg: [],
    _profilesByOrgIsFetching: false,
    _shouldCurrencyFetch: false,
    _profileCurrency: [],
    _profileCurrencyIsFetching: false,
    _approvalRoles: [],
    _profilesAll: [],
    _profilesAllIsFetching: false,
  },
  prefetch: true,
  staleAfter: 900000,
  persist: false,
  routeParam: "profileSlug",
  getTemplate: "/profile",
  putTemplate: "/profile/:item.slug",
  postTemplate: "/profile",
  deleteTemplate: "",
  fetchActions: ["AUTH_LOGGED_IN"],
  forceFetchActions: ["PROFILE_SAVE_FINISHED"],
  reduceFurther: (state, { type, payload }) => {
    switch (type) {
      case "PROFILECURRENCY_FETCH_FINISHED":
      case "PROFILECURRENCY_FETCH_ERROR":
      case "PROFILECURRENCY_FETCH_STARTED":
      case "PROFILESBYORG_FETCH_STARTED":
      case "PROFILESBYORG_FETCH_FINISHED":
      case "PROFILESBYORG_FETCH_ERROR":
      case "PROFILEAPPROVALROLES_FETCH_STARTED":
      case "PROFILEAPPROVALROLES_FETCH_ERROR":
      case "PROFILEAPPROVALROLES_FETCH_FINISHED":
      case "PROFILES_ALL_FETCH_STARTED":
      case "PROFILES_ALL_FETCH_ERROR":
      case "PROFILES_ALL_FETCH_FINISHED":
        return Object.assign({}, state, payload);
      case "PROFILEACTIVE_FETCH_FINISHED":
      case "EFTWENTY_SAVE_FINISHED":
      case "EFTWENTYAPPROVAL_DELETE_FINISHED":
      case "EFTWENTYAPPROVAL_SAVE_FINISHED":
        return { ...state, _shouldCurrencyFetch: true };
      default:
        return state;
    }
  },
  addons: {
    selectProfileActive: createSelector(
      "selectProfileItems",
      (profileItems) => {
        if (profileItems.length < 1) return null;
        return profileItems[0];
      }
    ),
    selectProfileId: createSelector("selectProfileActive", (profileActive) => {
      if (!profileActive) return null;
      return profileActive.id;
    }),

    doFetchProfileApprovalRoles:
      (callback) =>
      ({ dispatch, store, apiGet }) => {
        let id = store.selectProfileId();
        dispatch({
          type: "PROFILEAPPROVALROLES_FETCH_STARTED",
        });
        apiGet(`/profile/${id}/approvalRoles`, (err, response, body) => {
          if (err || response.statusCode !== 200) {
            dispatch({
              type: "PROFILEAPPROVALROLES_FETCH_ERROR",
              payload: {
                _err: {
                  err: err,
                  response: response,
                },
                notification: {
                  statusCode: response.statusCode,
                },
              },
            });
          } else {
            const data = JSON.parse(body);
            dispatch({
              type: "PROFILEAPPROVALROLES_FETCH_FINISHED",
              payload: {
                _approvalRoles: data[0],
              },
            });
            callback && callback();
          }
        });
      },

    doFetchProfileCurrency:
      (id, callback) =>
      ({ dispatch, store, apiGet }) => {
        dispatch({
          type: "PROFILECURRENCY_FETCH_STARTED",
          payload: {
            _shouldCurrencyFetch: false,
            _profileCurrencyIsFetching: true,
          },
        });
        apiGet(`/profile/${id}/currency`, (err, response, body) => {
          if (err || response.statusCode !== 200) {
            dispatch({
              type: "PROFILECURRENCY_FETCH_ERROR",
              payload: {
                _err: {
                  err: err,
                  response: response,
                  _profileCurrencyIsFetching: false,
                },
                notification: {
                  statusCode: response.statusCode,
                },
              },
            });
          } else {
            const data = JSON.parse(body);
            dispatch({
              type: "PROFILECURRENCY_FETCH_FINISHED",
              payload: {
                _profileCurrency: data,
                _profileCurrencyIsFetching: false,
              },
            });
            callback && callback();
          }
        });
      },

    doFetchProfilesAll:
      () =>
      ({ dispatch, store, apiGet }) => {
        dispatch({
          type: "PROFILES_ALL_FETCH_STARTED",
          payload: {
            _profilesAllIsFetching: true,
          },
        });
        apiGet(`/profilesAll`, (err, response, body) => {
          if (err || response.statusCode !== 200) {
            dispatch({
              type: "PROFILES_ALL_FETCH_ERROR",
              payload: {
                _err: { err: err, response: response },
                _profilesAllIsFetching: false,
                notification: {
                  statusCode: response.statusCode,
                },
              },
            });
          } else {
            const data = JSON.parse(body);
            dispatch({
              type: "PROFILES_ALL_FETCH_FINISHED",
              payload: {
                _profilesAll: data,
                _profilesAllIsFetching: false,
              },
            });
          }
        });
      },
    doFetchProfilesByOrg:
      () =>
      ({ dispatch, store, apiGet }) => {
        dispatch({
          type: "PROFILESBYORG_FETCH_STARTED",
          payload: {
            _profilesByOrgIsFetching: true,
          },
        });
        let orgSlug = store.selectOrgsByRoute().slug;
        apiGet(`/orgs/${orgSlug}/profiles`, (err, response, body) => {
          if (err || response.statusCode !== 200) {
            dispatch({
              type: "PROFILESBYORG_FETCH_ERROR",
              payload: {
                _err: { err: err, response: response },
                _profilesByOrgIsFetching: false,
                notification: {
                  statusCode: response.statusCode,
                },
              },
            });
          } else {
            const data = JSON.parse(body);
            dispatch({
              type: "PROFILESBYORG_FETCH_FINISHED",
              payload: {
                _profilesByOrg: data,
                _profilesByOrgIsFetching: false,
              },
            });
          }
        });
      },

    selectProfilesAll: (state) => state.profile._profilesAll,
    selectProfilesAllIsFetching: (state) =>
      state.profile._profilesAllIsFetching,
    selectProfilesByOrg: (state) => state.profile._profilesByOrg,
    selectProfilesByOrgIsFetching: (state) =>
      state.profile._profilesByOrgIsFetching,
    //the view outputs rows for each org a user is in, but the currency stats are identical per row, so we can just select the first one.
    selectProfileCurrency: (state) =>
      state.profile && state.profile._profileCurrency.length > 0
        ? state.profile._profileCurrency[0]
        : {},
    selectProfileApprovalRolesRaw: (state) => state.profile._approvalRoles,
    selectProfileApprovalRoles: createSelector(
      "selectProfileApprovalRolesRaw",
      "selectDomainsItems",
      (roles, domains) => {
        return (
          roles &&
          roles.approval_roles &&
          roles.approval_roles.map(
            (r) => find(domains, (obj) => obj.val === r).id
          )
        );
      }
    ),
    selectProfileCurrencyIsFetching: (state) =>
      state && state.profile && state.profile._profileCurrencyIsFetching,
    reactProfileCurrencyShouldFetch: (state) => {
      if (state.profile._shouldCurrencyFetch) {
        return {
          actionCreator: "doFetchProfileCurrency",
          args: [state.profileActive.data.id],
        };
      }
    },
  },
});
