import React from "react";
import ListItem from "./list-item";
import Loader from "../../../app-components/loader";

export default ({ items, editing, isLoading, onRemove, itemRenderFn }) => {
  if (isLoading) return <Loader />;
  if (!items.length) {
    return (
      <ul className="list-group">
        <li className="list-group-item">No Associated Items...</li>
      </ul>
    );
  } else {
    return (
      <ul className="list-group">
        {items.map((item, i) => {
          return (
            <ListItem
              item={item}
              editing={editing}
              key={i}
              onRemove={onRemove}
              itemRenderFn={itemRenderFn}
            />
          );
        })}
      </ul>
    );
  }
};
