import React, { useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
// import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-balham.css";

export default ({ config, data, style, isLoading }) => {
  const grid = useRef();

  useEffect(() => {
    // if (isLoading && grid.current) grid.current.api.showLoadingOverlay();
    // if (!isLoading && grid.current) grid.current.api.hideOverlay();
    window.grid = grid;
  }, [grid, isLoading]);

  return (
    <div className="ag-theme-quartz" style={style}>
      <AgGridReact ref={grid} {...config} rowData={data}></AgGridReact>
    </div>
  );
};
