import React from "react";
export default ({
  title,
  description,
  helperText,
  completeText = "Done!",
  enabled,
  actionTitle,
  action,
  undoEnabled,
  undoActionTitle,
  undoAction,
  complete,
  showRevertButton = false,
  revertAction,
}) => {
  const handleAction = () => {
    if (action && typeof action === "function") action();
  };
  const handleRevertAction = () => {
    if (revertAction && typeof revertAction === "function") revertAction();
  };
  const handleUndoAction = () => {
    if (undoAction && typeof undoAction === "function") undoAction();
  };
  return (
    <div
      className={`card text-white ${complete ? "bg-teal" : "bg-gray"}`}
      style={{ height: "100%" }}
    >
      <div
        className="card-body"
        style={{ position: "relative", minHeight: "18em" }}
      >
        <div className="text-value-lg">{title}</div>
        <div style={{ whiteSpace: "pre" }}>{description}</div>
        <hr />
        <div className="mb-3">
          <small className="text-muted">{helperText}</small>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            left: "10px",
          }}
        >
          {actionTitle ? (
            <>
              <div className="float-right" style={{ maxWidth: "50%" }}>
                {complete ? (
                  <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                    {completeText}
                  </div>
                ) : (
                  <button
                    onClick={handleAction}
                    style={complete ? { opacity: 1 } : {}}
                    className="btn btn-sm btn-primary"
                    disabled={complete || !enabled}
                  >
                    <i className="mdi mdi-checkbox-blank-circle-outline pr-2"></i>
                    {actionTitle}
                  </button>
                )}
              </div>
              <div>
                {undoActionTitle ? (
                  <>
                    {!complete || !undoEnabled ? null : (
                      <button
                        className="btn btn-sm btn-secondary mr-1"
                        onClick={handleUndoAction}
                        disabled={!complete || !undoEnabled}
                      >
                        <i className="mdi mdi-undo-variant pr-2"></i>Undo
                      </button>
                    )}
                  </>
                ) : null}
                {showRevertButton ? (
                  <button
                    className="btn btn-sm btn-secondary mr-1"
                    onClick={handleRevertAction}
                    disabled={complete || !enabled}
                  >
                    <i className="mdi mdi-backup-restore pr-2"></i>Needs Work
                  </button>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
