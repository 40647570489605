import React from "react";
import { connect } from "redux-bundler-react";
import { aircraftHq, aircraftRequest } from "../../models";
import SchemaForm from "../../app-components/schema-form/schema-form";

class AircraftNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.save = this.save.bind(this);
  }

  save() {
    const { doAircraftSave, doEquipmentRequestSave, orgsByRoute, doUpdateRelativeUrl, profileActiveData, equipmentRequestUserIsAdmin: userIsAdmin } = this.props;
    if (this.form.isValid()) {
      const data = this.form.serialize();
      if (userIsAdmin) {
        doAircraftSave(
          data,
          (newItem) => {
            doUpdateRelativeUrl(`/${orgsByRoute.slug}/aircraft/${newItem.slug}`);
          },
          true
        );
      }
      else {
        doEquipmentRequestSave(
          {
            ...data,
            created_by: profileActiveData.keycloak_id,
            status: "New",
            action: "Add",
            type: "aircraft",
            org_id: orgsByRoute.id,
            org_name: orgsByRoute.name,
            profile_id: profileActiveData.id
          },
          () => {
            doUpdateRelativeUrl(`/${orgsByRoute.slug}/aircraft`)
          }, true);
      }
    }
  }

  render() {
    const { orgsByRoute, aircraftIsSaving: isSaving, equipmentRequestUserIsAdmin:userIsAdmin } = this.props;
    return (
      <div className="container mt-3">
        <div className="row">
          <div className="col-sm-12 col-lg-9">
            <div className="card">
              <div className="card-header">
                <i className="ms ms-drone-remote"></i> {userIsAdmin? "Create":"Request"} Aircraft
              </div>
              <div className="card-body">
                <SchemaForm
                  ref={(el) => {
                    this.form = el;
                  }}
                  inline={true}
                  displayOnly={false}
                  schema={userIsAdmin ? aircraftHq : aircraftRequest}
                  data={{ ...this.state, org_name: orgsByRoute.name}}
                />
                <div className="float-right">
                  <button
                    disabled={isSaving}
                    onClick={() => {
                      window.history.back();
                    }}
                    className="btn btn-secondary mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={isSaving}
                    onClick={this.save}
                    className="btn btn-primary"
                  >
                    {userIsAdmin ? "Save" : "Request"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "doAircraftSave",
  "doEquipmentRequestSave",
  "selectOrgsByRoute",
  "selectAircraftIsSaving",
  "selectProfileActiveData",
  "selectEquipmentRequestUserIsAdmin",
  AircraftNew
);
