import React from "react";
import { connect } from "redux-bundler-react";

class PlatformsPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      approval_role_id: "",
      approval_role: "",
      id: props.personnelByRoute.id
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
  }

  handleSelect(e) {
    const { domainsItems: domains } = this.props;
    const role = domains.filter(d => {
      return d.id === e.target.value;
    })[0];
    this.setState({
      approval_role_id: role.id,
      approval_role: role.val
    });
  }

  handleAddClick() {
    const { onAdd } = this.props;
    if (onAdd) {
      onAdd(this.state);
      this.setState({
        approval_role_id: "",
        approval_role: ""
      });
    }
  }

  render() {
    const { approval_role_id, approval_role } = this.state;
    const { domainsItems: domains } = this.props;
    const roles = domains
      .filter(d => {
        return d.grp === "approval_role";
      })
      .sort();
    return (
      <div className="input-group mt-2">
        <select
          onChange={this.handleSelect}
          value={approval_role_id}
          className="form-control form-control-sm"
        >
          <option value="" disabled hidden>
            Please Choose...
          </option>
          {roles.map((item, i) => {
            return <option key={i} value={item.id}>{`${item.val}`}</option>;
          })}
        </select>
        <div className="input-group-append">
          <button
            disabled={approval_role === ""}
            onClick={this.handleAddClick}
            className="btn btn-sm btn-primary"
          >
            Add
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  "selectPersonnelByRoute",
  "selectDomainsItems",
  PlatformsPicker
);
