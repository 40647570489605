import React from "react";
import Dropdown from "../../_shared/item-board/dropdown";
import { connect } from "redux-bundler-react";
import { findIndex } from "lodash";
const ForumSelectDropdown = ({
  value,
  options,
  btnGroupCls = "mr-auto nasa",
  btnCls = "px-lg-3 py-lg-2 btn-primary",
  messagesForum,
  doUpdateRelativeUrl,
  doMessagesUpdateQueryString,
  messagesUserMemberships,
}) => {
  const defaultHandler = (update) => {
    doMessagesUpdateQueryString(update);
    if (update._forum !== "SHARED") {
      doUpdateRelativeUrl(`/${update._forum.toLowerCase()}/message-board`);
    }
  };
  let orgOptions = options;
  if (!options) {
    orgOptions = messagesUserMemberships.map((orgName) => {
      return {
        update: { _forum: orgName },
        displayValue: orgName,
        text: orgName,
        handler: defaultHandler,
        exitOnClick: true,
      };
    });
    orgOptions = [
      {
        update: { _forum: "SHARED" },
        displayValue: "SHARED",
        text: "SHARED",
        handler: defaultHandler,
        exitOnClick: true,
      },
      { type: "header", text: "My Orgs" },
      ...orgOptions,
    ];
  }

  let foundIndex = findIndex(
    orgOptions,
    (o) => o.value === (value ? value : messagesForum)
  );
  return (
    <Dropdown
      btnGroupCls={btnGroupCls}
      btnCls={btnCls}
      direction="dropdown-menu-left"
      title="Org"
      options={orgOptions}
      stateValueAsTitle={true}
      selectedVal={messagesForum}
      style={{ lineHeight: 1.3333, fontSize: "18px" }}
      selectedIdx={foundIndex}
    />
  );
};
export default connect(
  "selectMessagesForum",
  "selectMessagesUserMemberships",
  "doMessagesUpdateQueryString",
  "doUpdateRelativeUrl",
  ForumSelectDropdown
);
