import React from "react";
import { connect } from "redux-bundler-react";

class TrainingPicker extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(e) {
    const { onChange } = this.props;
    onChange(e);
  }

  render() {
    const { value, trainingItems, dataKey } = this.props;
    return (
      <div className="mb-2">
        <label>Training</label>
        <select
          onChange={this.handleSelect}
          value={value}
          className="form-control"
          data-key={dataKey}
        >
          <option value="" disabled hidden>
            Please Choose...
          </option>
          {trainingItems.map((item, i) => {
            return <option key={i} value={item.id}>{`${item.title}`}</option>;
          })}
        </select>
      </div>
    );
  }
}

export default connect(
  "selectTrainingItems",
  TrainingPicker
);
