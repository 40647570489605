import React from "react";
import classnames from "classnames";
import "../documentation.css";

const FileUploadInstructions = ({ style = {}, className  }) => {
  let _className = classnames({
    "list-group numbered": true,
    [className]: className
  })
  return (
    <ol className={_className} style={style}>
      <li className="list-group-item">
        <p>
          Click 
          <button class="btn btn-sm btn-ghost-primary mx-2 display-only">
            <span class="mdi mdi-pencil">
              Edit
            </span>
          </button>
          .
        </p>
      </li>
      <li className="list-group-item">
        <p>
          Click
          <button class="btn btn-ghost-primary mx-2 display-only">
            <span class="mdi mdi-folder-open"></span>
          </button>
          . A file upload dialog will appear and you will be able to choose a file from the local file system.
        </p>
      </li>
      <li className="list-group-item">
        <p>
          Once you choose a file, click
          <button class="btn btn-sm btn-primary mx-2 display-only">
            <span class="mdi mdi-cloud-upload pr-1">
              Upload
            </span>
          </button>
          .
        </p>
      </li>
      <li className="list-group-item">
        <p>
          Click
          <button class="btn btn-sm btn-secondary mx-2 display-only">
            Done
          </button>
          .
        </p>
      </li>
    </ol>
  )
}

export default FileUploadInstructions;