import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "missionsFlightsMishaps",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate:
    "/orgs/:orgSlug/missions/:missionsSlug/flights/:flightId/mishaps",
  putTemplate:
    "/orgs/:orgSlug/missions/:missionsSlug/flights/:flightId/mishaps/:item.id",
  postTemplate:
    "/orgs/:orgSlug/missions/:missionsSlug/flights/:flightId/mishaps",
  deleteTemplate:
    "/orgs/:orgSlug/missions/:missionsSlug/flights/:flightId/mishaps/:item.id",
  fetchActions: [
    "AUTH_LOGGED_IN",
    "FLIGHTS_TAB_ROUTER_UPDATE_PATH",
    "MISSIONSFLIGHTS_UPDATED_ITEM",
  ],
  forceFetchActions: ["URL_UPDATED"],
});
