import React, { useEffect, useState } from "react"
import { connect } from "redux-bundler-react"
import { find } from "lodash"
import "../message-board.css"
import classnames from "classnames"
const PersonnelContactLink = ({ board, forumUserPermissionsItems, hideOrgName = false, routeInfo, data, user, boxDirection = 'right' }) => {
    const [state, setState] = useState({
        userPermissions: {}
    })
    const renderUserPageLink = () => {
        let canAccess = data.org_name === routeInfo.params.orgSlug.toUpperCase();
        let url = ''
        if (user && data && canAccess) {
            if (user && user.slugs && user.slugs[data.org_name]) url = `/${routeInfo.params.orgSlug}/personnel/${user.slugs[data.org_name]}`
        }
        let linkClsName = classnames({
            "personnel-link-name": true,
            "not-sharing": !data.show_contact_info && data.is_shared
        })
        let linkAttrs = {}
        let innerHtml = data.created_by_name
        if ((canAccess && url && data.created_by_name !== "[deleted]")) {
            linkAttrs.href = url
        }
        if (data.is_shared && !hideOrgName) {
            innerHtml = `${data.created_by_name}  (${data.org_name})`
        }
        return (
            <a className={linkClsName} {...linkAttrs}>
                <strong>{innerHtml}</strong>
            </a>)
    }
    useEffect(() => {
        if (forumUserPermissionsItems) {
            setState({ userPermissions: find(forumUserPermissionsItems, { created_by: user.keycloak_id, forum_name: board }) })
        }
    }, [])
    const renderUserContactInfoBox = () => {
        if (!data.created_by_name) return null;
        const tooltipStyle = {
            'right': {
                transform: `translate3d(${data.created_by_name.length}ch, -17%, 0px)`,
            },
            'left': {
                transform: `translate3d(${-25 + -data.created_by_name.length}ch, -17%, 0px)`,
            }
        }
        let emailStr = user.email
        let phoneNumStr = 'Not Found';
        if (user && user.phone) {
            if (user.phone.length === 10) {
                phoneNumStr = `(${user.phone.slice(0, 3)}) ${user.phone.slice(3, 6)}-${user.phone.slice(6)}`
            }
            else phoneNumStr = user.phone
        }
        if (!data.is_shared || (state.userPermissions && state.userPermissions.show_contact_info)) {
            return (
                <div className="personnel-contact-tooltip p-2 bg-dark text-light text-left ml-2" style={tooltipStyle[boxDirection]} >
                    <i className="mdi mdi-phone mr-2" />Phone:
                    <div className="ml-2">{phoneNumStr}</div>
                    <i className="mdi mdi-email mr-2" />Email:
                    <div className="ml-2">{emailStr}</div>
                </div>
            )
        }
    }
    return (
        <>
            {renderUserPageLink()}
            {renderUserContactInfoBox()}
        </>
    )
}
export default connect("selectRouteInfo", "selectForumUserPermissionsItems", PersonnelContactLink)