import React from "react";
import { connect } from "redux-bundler-react";
import Loader from "../../app-components/loader";
import LoadingList from "../../modules/atpm-dashboard/cards/loading-list";

const AlertContainer = ({
  tab,
  profileAlertItems: alertItems,
  profileAlertIsFetching,
}) => {
  let alerts = alertItems.filter((item) => item.tab === tab);
  if (profileAlertIsFetching) return <LoadingList />;
  return (
    alerts &&
    alerts.length > 0 &&
    alerts.map((item) => {
      return (
        <div
          className={`alert alert-${item.type}`}
          role="alert"
          key={item.title}>
          <h5 className="alert-heading">{item.title}</h5>
          <p>{item.body}</p>
          {item.variable && (
            <>
              <hr />
              <b>{item.variable}</b>
            </>
          )}
        </div>
      );
    })
  );
};

export default connect(
  "selectProfileAlertItems",
  "selectProfileAlertIsFetching",
  AlertContainer
);
