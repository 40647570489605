import { find } from "lodash";
import React from "react";
import { connect } from "redux-bundler-react";

function getLastWordAfterCharacters(str, charCount) {
  const substring = str.substring(0, charCount);
  const words = substring.split(" ");
  words.pop();
  const shortenedString = words.join(" ");
  return shortenedString + "...";
}

const roleColors = {
  MBO: "indigo",
  ATPM: "teal",
  // MAA: "cyan",
  BATPM: "orange",

  CDR: "purple",
  DIR: "info",
};

const roleColorPilot = {
  RP: "indigo",
  RPI: "teal",
  SRP: "cyan",
};

const ReadFileItem = ({
  item,
  newItem,
  onAdd,
  onEdit,
  onView,
  tokenRolesJoined,
  platformsItems,
}) => {
  let userIsHqAdmin = tokenRolesJoined.includes("HQ.ADMIN");
  if (newItem) {
    return (
      <div className="read-file-item" onClick={onAdd}>
        <div className="d-flex align-items-center text-info px-2">
          <i className="mdi mdi-plus mdi-24px mr-3" /> Add Read File
        </div>
      </div>
    );
  } else {
    let aircrafts = item.allow_aircraft
      ? JSON.parse(item.allow_aircraft).map((craftId) => {
          let platform = find(platformsItems, (obj) => obj.id === craftId);
          return `${platform.make} ${platform.model}`;
        })
      : [];
    return (
      <div
        className={`read-file-item read-file-status-${
          !item.keycloak_id && item.initial_status
        }`}>
        {userIsHqAdmin && onEdit && (
          <button className="btn btn-ghost" onClick={onEdit}>
            <i className="mdi mdi-pencil-outline mdi-18px" />
          </button>
        )}
        <div
          className="d-flex flex-column ml-2 justify-content-between w-100"
          onClick={onView}>
          <div className="d-flex flex-column">
            <p className="mb-1 mr-2">
              <b>{item.title}</b>
            </p>
            <div className={item.allow_flight_roles ? "d-flex mb-1" : "d-flex"}>
              {item.allow_roles &&
                JSON.parse(item.allow_roles).map((role) => (
                  <div
                    className={`d-flex mr-2 align-items-center badge badge-pill badge-${roleColors[role]}`}
                    key={role}>
                    <i className="mdi mdi-account-check pr-1"></i>
                    {role}
                  </div>
                ))}
            </div>
            <div className={item.allow_aircraft ? "d-flex mb-1" : "d-flex"}>
              {item.allow_flight_roles &&
                JSON.parse(item.allow_flight_roles).map((role) => (
                  <span
                    key={role}
                    className={`d-flex mr-2 align-items-center badge badge-pill badge-${roleColorPilot[role]}`}
                    title={role}>
                    {role}
                  </span>
                ))}
            </div>
            <div className="d-flex mb-1">
              {aircrafts.length > 0 && (
                <span className="d-flex align-items-center badge badge-pill badge-success mr-2">
                  <i className="mdi mdi-airplane pr-1" />
                  <p className="m-0"> {aircrafts.length} Aircraft</p>
                </span>
              )}
            </div>
          </div>
          <p className="text-muted m-0">
            {item.description && item.description.length > 100
              ? getLastWordAfterCharacters(item.description, 100)
              : item.description}
          </p>
        </div>
        <div
          className="d-flex flex-column"
          style={{ width: 200 }}
          onClick={onView}>
          <p className="m-0">
            <i>Posted on: {item.create_date.split("T")[0]}</i>
          </p>
          {item.read_date && (
            <p className="m-0">
              <i>Read on: {item.read_date.split("T")[0]}</i>
            </p>
          )}
        </div>
      </div>
    );
  }
};

export default connect(
  "doReadFileMarkAsRead",
  "doReadFileMarkAsUnread",
  "selectTokenKeyCloakId",
  "selectReadFileIsSaving",
  "selectReadFileIsDeleting",
  "selectTokenRolesJoined",
  "selectPlatformsItems",
  ReadFileItem
);
