import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import MissionCardRegion from "../_shared/mission-card-region";

const Planner = (props) => {
  const { missionsItems: items, doMissionsFetch, missionsIsLoading } = props;
  useEffect(() => {
    doMissionsFetch();
  }, []);
  const filtered = items.filter((item) => {
    return (
      ["draft", "pending approval"].indexOf(item.approval_status) !== -1 &&
      item.archived === 0
    );
  });
  return (
    <div className="container-fluid mt-4">
      <MissionCardRegion
        isLoading={missionsIsLoading}
        items={filtered}
        allowAdd={true}
      />
    </div>
  );
};

export default connect(
  "doMissionsFetch",
  "selectMissionsItems",
  "selectMissionsIsLoading",
  Planner
);
