import React from "react"
import ApprovalRoleTags from "./approval-role-tags";
import PersonnelContactLink from "./personnel-contact-link"
import { uniq } from "lodash";

export const setHyperlinkTargetsOnPage = (className, value = "_blank") => {
    let hyperlinks = document.querySelectorAll(`${className} a`)
    if (hyperlinks) {
        hyperlinks.forEach(link => {
            link.setAttributeNS(null, "target", value)
        })
    }
}

export const renderUserApprovalRoles = (data, permissions, parentItemData = null,) => {
    let uniq_approval_roles = uniq(data.created_by_approval_roles)
    // If the commenter has data for their approval roles, include that
    let rolesStr = ''
    if (uniq_approval_roles.length > 0) {
        rolesStr = uniq_approval_roles.join('.');
    }
    else if (parentItemData && parentItemData.people && parentItemData.people[data.created_by]) {
        let person = parentItemData.people[data.created_by];
        if (person.approval_roles && person.approval_roles[data.org_name]) {
            rolesStr = person.approval_roles[data.org_name]
        }
        else rolesStr = ''
    }
    if ((!data.is_shared || (permissions && permissions.show_approval_roles)) && rolesStr.length > 0) {
        return (
            <div className="d-none d-lg-inline pr-2">
                <ApprovalRoleTags rolesStr={rolesStr} />
            </div>
        )
    }
    return null
}

export const renderName = (item, routeInfo, user, permissions) => {
    if (!user) {
        user = item && item.people && item.people[item.created_by] ? item.people[item.created_by] : { phone: '', email: '', name: item.created_by_name, slug: {} }
    }

    let userHasPersonnelPageAccess = item && item.org_name === routeInfo.params.orgSlug.toUpperCase()
    if (!item.marked_as_deleted) {
        return <PersonnelContactLink board="message-board" boxDirection="left" data={item} user={user} userHasAccess={userHasPersonnelPageAccess} hideOrgName={true} permissions={permissions} />
    }
    else return <strong className="d-block text-wrap">{item.marked_as_deleted ? '[deleted]' : item.created_by_name}</strong>
}