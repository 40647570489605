import React from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "pdfjs-dist/build/pdf.worker.min.js";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

const maxWidth = 800;

export default function PdfViewer({
  width,
  zoom,
  pdfFile = "/sectional-legend.pdf",
}) {
  return (
    <Document file={pdfFile} options={options} wrap={false} margin={0}>
      <Page
        pageNumber={1}
        width={width ? Math.min(width, maxWidth) : maxWidth}
        scale={zoom}
      />
    </Document>
  );
}
