import React from "react";
import classnames from "classnames";
import { connect } from "redux-bundler-react";
import efSeventyEight from "./templates/ef-seventyeight.template.json";
import efSeventySeven from "./templates/ef-seventyseven.template.json";
import efSeventySix from "./templates/ef-seventysix.template.json";
import efTwenty from "./templates/ef-twenty.template.json";
import efTwentyTwo from "./templates/ef-twentytwo.template.json";
import gradeSlipTemplate from "./templates/grade-slip.template.json";
import healthAssessmentTemplate from "./templates/health-assessment.template.json";
import riskAssessmentTemplate from "./templates/risk-assessment.template.json";

const getTemplate = (file) => {
  switch (file) {
    case "ef-seventyeight.template.json":
      return efSeventyEight;
    case "ef-seventyseven.template.json":
      return efSeventySeven;
    case "ef-seventysix.template.json":
      return efSeventySix;
    case "ef-twenty.template.json":
      return efTwenty;
    case "ef-twentytwo.template.json":
      return efTwentyTwo;
    case "grade-slip.template.json":
      return gradeSlipTemplate;
    case "health-assessment.template.json":
      return healthAssessmentTemplate;
    case "risk-assessment.template.json":
      return riskAssessmentTemplate;
  }
};

const PrintButton = ({
  className,
  state,
  options = [],
  doDialogOpen,
  doPrintUpdateTemplate,
  doPrintResourcesFetch,
  children,
  modal,
  missionDetatchedFromOrg = false,
  immediatePrint = false,
  buttonRenderFn,
}) => {
  let btnClsName = classnames({
    "ml-auto btn btn-outline-primary": true,
    [className]: className,
  });
  const _onClick = async (idx = 0) => {
    if (modal) doDialogOpen({ content: modal, props: {}, size: "lg" });
    else if (options.length === 1) {
      let printChoice = options.length === 1 ? options[0] : options[idx];
      // let file = await import(
      //   /* @vite-ignore */ `/src/app-components/print-button/templates/${printChoice.file}`
      // );
      let file = getTemplate(printChoice.file);
      if (state.rows) {
        state.rows = state.rows.filter((r) => {
          if (r.deleted !== 1) return r;
        });
      }
      doPrintUpdateTemplate(
        state,
        [
          {
            ...file,
            _filename: printChoice.file,
            _resultFileName: printChoice.resultFileName,
            stateFields: printChoice.stateFields,
          },
        ],
        true,
        printChoice.resultFileName,
        () => {
          let apiEndpoint = file ? file["api-endpoint"] : null;
          doPrintResourcesFetch(
            apiEndpoint,
            missionDetatchedFromOrg,
            immediatePrint
          );
        }
      );
    }
  };

  if (buttonRenderFn) {
    return buttonRenderFn(_onClick);
  } else if (options.length === 1) {
    return (
      <button className={btnClsName} onClick={_onClick}>
        <i className="mdi mdi-printer mr-2" />
        Print {options[0].resultFileName}
      </button>
    );
  } else {
    return (
      <button className={btnClsName} onClick={_onClick}>
        {children}
      </button>
    );
  }
};
export default connect(
  "doDialogOpen",
  "doPrintResourcesFetch",
  "doPrintUpdateTemplate",
  PrintButton
);
