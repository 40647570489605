import React from "react";
import { connect } from "redux-bundler-react";
import { isEmpty } from "lodash";
import { missionAreaData } from "../../../../models";
import SchemaForm from "../../../../app-components/schema-form/schema-form";
import DeleteButton from "../../../_shared/delete-button/delete-button";
import RoleFilter from "../../../../app-containers/context-providers/role-filter";
import ReadOnly from "../../../_shared/read-only-notification";
import MissionStatusFilter from "../../_shared/mission-status-filter";

class MissionArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.renderForm = this.renderForm.bind(this);
    this.save = this.save.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.enableEditing = this.enableEditing.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    const {
      doMissionsAreaDataFetch,
      missionsAreaDataIsLoading,
      doMissionsAlertFetch,
    } = this.props;
    if (!missionsAreaDataIsLoading) doMissionsAreaDataFetch();
    doMissionsAlertFetch();
  }

  save() {
    const { doMissionsAreaDataSave, doMissionsAlertFetch } = this.props;
    if (this.form.isValid()) {
      const data = this.form.serialize();
      this.setState(
        {
          editing: false,
        },
        () => {
          doMissionsAreaDataSave(data, doMissionsAlertFetch, true);
        }
      );
    }
  }

  enableEditing() {
    this.setState({
      editing: true,
    });
  }

  cancelEditing() {
    const { missionsAreaDataItem } = this.props;
    this.setState(
      {
        editing: false,
      },
      () => {
        this.form.reset({
          data: missionsAreaDataItem,
          schema: missionAreaData,
        });
      }
    );
  }

  handleDelete() {
    const {
      doMissionsAreaDataDelete,
      doUpdateRelativeUrl,
      routeParams,
      missionsAreaDataItem,
    } = this.props;
    this.setState(
      {
        editing: false,
      },
      () => {
        doMissionsAreaDataDelete(
          missionsAreaDataItem,
          () => {
            doUpdateRelativeUrl(`/${routeParams.orgSlug}/planner`);
          },
          true
        );
      }
    );
  }

  /**
   * @todo - only show the delete button when in draft mode
   */
  renderActions() {
    const { editing } = this.state;
    if (!editing) return null;
    return (
      <div>
        <div className="float-right">
          <button
            onClick={this.cancelEditing}
            className="btn btn-sm btn-secondary mr-2"
          >
            Cancel
          </button>
          <button onClick={this.save} className="btn btn-sm btn-success">
            Save
          </button>
        </div>
        <DeleteButton onDelete={this.handleDelete} />
      </div>
    );
  }

  renderForm() {
    const { editing } = this.state;
    const { missionsAreaDataItem } = this.props;
    if (isEmpty(missionsAreaDataItem)) return null;
    return (
      <SchemaForm
        ref={(el) => {
          this.form = el;
        }}
        inline={true}
        displayOnly={!editing}
        schema={missionAreaData}
        data={missionsAreaDataItem}
      />
    );
  }

  render() {
    const { editing } = this.state;
    return (
      <div>
        <div className="clearfix mb-2">
          <div className="float-right">
            <RoleFilter
              allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
              alt={ReadOnly}
            >
              <MissionStatusFilter
                allowStatus={{
                  approval_status: ["draft"],
                }}
                alt={ReadOnly}
              >
                <button
                  disabled={editing}
                  className="btn btn-sm btn-ghost-primary"
                  onClick={this.enableEditing}
                >
                  <i className="mdi mdi-pencil icon-inline"></i>
                  Edit
                </button>
              </MissionStatusFilter>
            </RoleFilter>
          </div>
        </div>
        {this.renderForm()}

        <div className="clearfix">{this.renderActions()}</div>
      </div>
    );
  }
}

export default connect(
  "selectRouteParams",
  "selectMissionsAreaDataItem",
  "doMissionsAreaDataSave",
  "doMissionsAreaDataDelete",
  "doMissionsAlertFetch",
  "doUpdateRelativeUrl",
  "doMissionsAreaDataFetch",
  "selectMissionsAreaDataIsLoading",
  MissionArea
);
