import React from "react";

class FlightPersonnelPicker extends React.Component {
  render() {
    const { personnel, pic, vo, na, onChange, role, disabled } = this.props;
    const controlId = Math.random() * 548;
    return (
      <div className="row">
        <span className="col-sm-3 overflow-ellipsis" title={personnel.name}>
          <strong>{`${personnel.name}`}</strong>
        </span>
        <div className="form-check form-check-inline col-sm-3">
          <input
            onChange={onChange}
            className="form-check-input"
            id={`${controlId}-1`}
            type="radio"
            value={pic.id}
            checked={role === pic.id}
            name={`${personnel.id}`}
            disabled={disabled}
          />
          <label
            className="form-check-label overflow-ellipsis"
            htmlFor={`${controlId}-1`}
          >
            Remote Pilot
          </label>
        </div>
        <div className="form-check form-check-inline col-sm-3">
          <input
            onChange={onChange}
            className="form-check-input"
            id={`${controlId}-2`}
            type="radio"
            value={vo.id}
            checked={role === vo.id}
            name={`${personnel.id}`}
            disabled={disabled}
          />
          <label
            className="form-check-label overflow-ellipsis"
            htmlFor={`${controlId}-2`}
          >
            Visual Observer
          </label>
        </div>
        <div className="form-check form-check-inline col-sm-2">
          <input
            onChange={onChange}
            className="form-check-input"
            id={`${controlId}-3`}
            type="radio"
            value={na.id}
            checked={role === na.id}
            name={`${personnel.id}`}
            disabled={disabled}
          />
          <label
            className="form-check-label overflow-ellipsis"
            htmlFor={`${controlId}-3`}
          >
            N/A
          </label>
        </div>
      </div>
    );
  }
}

export default FlightPersonnelPicker;
