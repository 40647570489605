import React from "react";
import BacklogMissionListItem from "./backlog-mission-list-item";
import { sortBy } from "lodash";

// Renders a table of missions that are in the approval process.
export default ({ items: missions }) => {
  if (!missions || !missions.length)
    return (
      <li className="list-group-item">
        <span className="m-auto">No Missions Right Now</span>
      </li>
    );
  let sortedMissions =
    missions && missions.length > 0
      ? sortBy(missions, (obj) => new Date(obj.last_updated_date)).reverse()
      : missions;
  return (
    <>
      <strong>
        <p>Total: ({missions.length})</p>
      </strong>
      <table className="table">
        <thead className="thead">
          <tr scope="row">
            <th scope="col" className="mx-auto">
              ORG - Mission
            </th>
            <th scope="col" className="mx-auto">
              Preparer Signature Date
            </th>
            <th scope="col" className="mx-auto">
              Mission Start
            </th>
            <th scope="col" className="mx-auto">
              Approvals
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedMissions.map((mission, i) => {
            return <BacklogMissionListItem key={i} mission={mission} />;
          })}
        </tbody>
      </table>
    </>
  );
};
