import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "personnelMissions",
  uid: "id",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/personnel/:personnelSlug/missions",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN"]
});
