import Map from "../_shared/map/map";
import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import "../missions/details/map-tab/popup.css";
import "./dashboard.css";
import { sum } from "lodash";

function formatDate(inputDate) {
  var parts = inputDate.split("-");
  var formattedDate = parts[1] + "/" + parts[2] + "/" + parts[0];
  return formattedDate;
}

const MetricCard = ({ items, total, keys, title }) => {
  return (
    <div
      className="d-flex flex-column mt-2 p-2 bg-white"
      style={{ borderRadius: 12 }}
    >
      <div
        className="d-flex w-100 align-items-center justify-content-center"
        style={{ opacity: 0.7 }}
      >
        <i
          className={`mdi ${
            title === "Missions"
              ? `mdi-calendar-text nav-icon`
              : title === "Aircraft"
              ? "mdi-airplane"
              : "mdi-account-multiple-outline"
          } mdi-18px text-info`}
        />
      </div>
      <div className="d-flex flex-column align-items-center">
        <p style={{ fontSize: 32 }} className="m-0 text-info">
          <b>{total}</b>
        </p>
        <h6 style={{ fontSize: 24 }} className="mb-1">
          Total {title}
        </h6>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: keys.map(() => "1fr").join(" "),
          gap: 4,
        }}
      >
        {keys.map((key) => {
          return (
            <div className="d-flex flex-column align-items-center" key={key}>
              <p style={{ fontSize: 24 }} className="m-0 text-muted">
                <b>{items[key] ?? 0}</b>
              </p>
              <p style={{ fontSize: 16 }}>{key}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const MissionListItem = ({
  id,
  dashboardMapSelectedMission,
  mission_status,
  mission_name,
  startDate,
  endDate,
  org_name,
}) => {
  return (
    <li
      key={id}
      className={`mission-list-item ${
        id === dashboardMapSelectedMission ? "selected" : ""
      }`}
    >
      <div
        className={`mission-marker mission-${
          mission_status !== "active" ? "upcoming" : mission_status
        }`}
      />
      <div className="d-flex flex-column align-items-start justify-content-start">
        <h6 className="m-0">
          {mission_name}
          <small className="text-muted pl-1">
            <b>{org_name}</b>
          </small>
        </h6>
        <p className="m-0 text-muted">
          {startDate} - {endDate}
        </p>
      </div>
    </li>
  );
};
const NewDashboard = ({
  doDashboardMapInitialize,
  dashboardMapInitializeProps,
  dashboardAoiGeoJSONCentroids,
  dashboardMapSelectedMission,
  dashboardCountsItemsObject,
}) => {
  const [activeTab, setActiveTab] = useState("all-tab");
  let allMissions = dashboardAoiGeoJSONCentroids
    ? dashboardAoiGeoJSONCentroids.features
    : [];
  let activeMissions = dashboardAoiGeoJSONCentroids
    ? dashboardAoiGeoJSONCentroids.features.filter(
        (item) => item.properties.mission_status === "active"
      )
    : [];
  let upcomingMissions = dashboardAoiGeoJSONCentroids
    ? dashboardAoiGeoJSONCentroids.features.filter(
        (item) => item.properties.mission_status !== "active"
      )
    : [];
  return (
    <div className="mb-4">
      <div
        style={{
          position: "relative",
          display: "flex",
          height: "50vh",
        }}
      >
        <div style={{ position: "relative", width: "calc(100% - 350px)" }}>
          <Map
            initializeFn={doDashboardMapInitialize}
            initializeProps={dashboardMapInitializeProps}
          />
          <div id="dashboard-popup" style={{ display: "none" }}>
            <button
              id="dashboard-popup-closer"
              className="ol-popup-closer"
            ></button>
            <div id="dashboard-popup-content"></div>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            backgroundColor: "white",
            height: "100%",
            width: 350,
          }}
        >
          <ul className="nav nav-tabs" style={{ flexWrap: "nowrap" }}>
            <li className="nav-item w-100" role="presentation">
              <button
                className={`nav-link w-100 ${
                  activeTab === "all-tab" ? "active" : ""
                }`}
                style={{ outline: "none" }}
                onClick={(e) => setActiveTab(e.target.id)}
                id="all-tab"
                data-bs-toggle="tab"
                data-bs-target="#all"
                type="button"
                role="tab"
                aria-controls="all"
                aria-selected={activeTab === "all-tab"}
              >
                All
              </button>
            </li>
            <li className="nav-item w-100" role="presentation">
              <button
                className={`nav-link w-100 ${
                  activeTab === "active-tab" ? "active" : ""
                }`}
                style={{ outline: "none" }}
                onClick={(e) => setActiveTab(e.target.id)}
                id="active-tab"
                data-bs-toggle="tab"
                data-bs-target="#active"
                type="button"
                role="tab"
                aria-controls="active"
                aria-selected={activeTab === "active-tab"}
              >
                Active
              </button>
            </li>
            <li className="nav-item w-100" role="presentation">
              <button
                className={`nav-link w-100 ${
                  activeTab === "upcoming-tab" ? "active" : ""
                }`}
                style={{ outline: "none" }}
                onClick={(e) => setActiveTab(e.target.id)}
                id="upcoming-tab"
                data-bs-toggle="tab"
                data-bs-target="#upcoming"
                type="button"
                role="tab"
                aria-controls="upcoming"
                aria-selected={activeTab === "upcoming-tab"}
              >
                Upcoming
              </button>
            </li>
          </ul>
          <div
            className="tab-content border-0"
            id="missionContent"
            style={{ height: "calc(100% - 39px)", overflowY: "auto" }}
          >
            <div
              className={`tab-pane fade ${
                activeTab === "all-tab" ? "show active" : ""
              }`}
              id="all"
              role="tabpanel"
              aria-labelledby="all-tab"
            >
              <ul className="p-0 m-0" style={{ listStyleType: "none" }}>
                {allMissions.map((item, i) => {
                  let {
                    mission_name,
                    date_start,
                    date_end,
                    id,
                    mission_status,
                    org_name,
                  } = item.properties;
                  let startDate = formatDate(date_start.split("T")[0]);
                  let endDate = formatDate(date_end.split("T")[0]);
                  return (
                    <MissionListItem
                      startDate={startDate}
                      endDate={endDate}
                      dashboardMapSelectedMission={dashboardMapSelectedMission}
                      mission_name={mission_name}
                      id={id}
                      mission_status={mission_status}
                      org_name={org_name}
                      key={id}
                    />
                  );
                })}
              </ul>
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "active-tab" ? "show active" : ""
              }`}
              id="active"
              role="tabpanel"
              aria-labelledby="active-tab"
            >
              <ul className="p-0 m-0" style={{ listStyleType: "none" }}>
                {activeMissions.map((item, i) => {
                  let {
                    mission_name,
                    date_start,
                    date_end,
                    id,
                    mission_status,
                    org_name,
                  } = item.properties;
                  let startDate = formatDate(date_start.split("T")[0]);
                  let endDate = formatDate(date_end.split("T")[0]);
                  return (
                    <MissionListItem
                      startDate={startDate}
                      endDate={endDate}
                      dashboardMapSelectedMission={dashboardMapSelectedMission}
                      mission_name={mission_name}
                      id={id}
                      mission_status={mission_status}
                      org_name={org_name}
                      key={id}
                    />
                  );
                })}
              </ul>
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "upcoming-tab" ? "show active" : ""
              }`}
              id="upcoming"
              role="tabpanel"
              aria-labelledby="upcoming-tab"
            >
              <ul className="p-0 m-0" style={{ listStyleType: "none" }}>
                {upcomingMissions.map((item, i) => {
                  let {
                    mission_name,
                    date_start,
                    date_end,
                    id,
                    mission_status,
                    org_name,
                  } = item.properties;
                  let startDate = formatDate(date_start.split("T")[0]);
                  let endDate = formatDate(date_end.split("T")[0]);
                  return (
                    <MissionListItem
                      startDate={startDate}
                      endDate={endDate}
                      dashboardMapSelectedMission={dashboardMapSelectedMission}
                      mission_name={mission_name}
                      id={id}
                      mission_status={mission_status}
                      org_name={org_name}
                      key={id}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: 12,
        }}
      >
        <MetricCard
          title="Personnel"
          items={dashboardCountsItemsObject?.total?.duty ?? {}}
          total={sum(
            Object.values(dashboardCountsItemsObject?.total?.duty ?? {})
          )}
          keys={["RP", "RPI", "SRP"]}
        />
        <MetricCard
          title="Aircraft"
          items={dashboardCountsItemsObject?.total?.operational ?? {}}
          total={sum(
            Object.values(dashboardCountsItemsObject?.total?.operational ?? {})
          )}
          keys={["FMC", "NMC", "PMC"]}
        />
        <MetricCard
          items={{
            Active: activeMissions.length,
            Upcoming: upcomingMissions.length,
          }}
          total={allMissions.length}
          keys={["Active", "Upcoming"]}
          title="Missions"
        />
      </div>
    </div>
  );
};

export default connect(
  "doDashboardMapInitialize",
  "selectDashboardMapInitializeProps",
  "doDashboardAoiFetch",
  "selectDashboardAoiItems",
  "selectDashboardAoiGeoJSONCentroids",
  "selectDashboardMapSelectedMission",
  "selectDashboardAoiFlightDuties",
  "selectDashboardAoiCrewmemberCount",
  "selectAircraftItemsOperational",
  "selectAircraftItems",
  "selectDashboardCountsItemsObject",
  NewDashboard
);
