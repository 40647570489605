import React from "react";
import { connect } from "redux-bundler-react";
import { missionNewItem } from "../../../models";
import SchemaForm from "../../../app-components/schema-form/schema-form";

class MissionNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      org_id: props.orgsByRoute.id,
    };
    this.save = this.save.bind(this);
  }

  save() {
    const { doMissionsSave, orgsByRoute, doUpdateRelativeUrl } = this.props;
    if (this.form.isValid()) {
      const data = this.form.serialize();
      data.archived = 0;
      doMissionsSave(
        data,
        (newItem) => {
          doUpdateRelativeUrl(`/${orgsByRoute.slug}/mission/${newItem.slug}`);
        },
        true
      );
    }
  }

  render() {
    const { missionsIsSaving } = this.props;
    return (
      <div className="container mt-3">
        <div className="row">
          <div className="col-sm-12 col-lg-9">
            <div className="card">
              <div className="card-header">
                <i className="mdi mdi-map"></i> New Mission
              </div>
              <div className="card-body">
                <SchemaForm
                  ref={(el) => {
                    this.form = el;
                  }}
                  inline={true}
                  displayOnly={false}
                  schema={missionNewItem}
                  data={this.state}
                />
                <div className="float-right">
                  <button
                    disabled={missionsIsSaving}
                    onClick={() => {
                      window.history.back();
                    }}
                    className="btn btn-secondary mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={missionsIsSaving}
                    onClick={this.save}
                    className="btn btn-primary"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doMissionsSave",
  "doUpdateRelativeUrl",
  "selectOrgsByRoute",
  "selectMissionsIsSaving",
  MissionNew
);
