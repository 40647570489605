import React from "react";
import classnames from "classnames";
import "../documentation.css";
const ButtonDrawing = ({ btnCls, iconCls, value, isDropdown, noMargins, style = {} }) => {
  const fullBtnCls = classnames({
    "mx-2": !noMargins,
    "display-only": true,
    [btnCls]: btnCls
  });
  return (
    <button className={fullBtnCls} style={style}>
      {iconCls && <i className={iconCls} />}
      {value}
      {isDropdown && <i class="mdi mdi-menu-down" />}
    </button>
  );
}
export default ButtonDrawing;