import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import { format } from "date-fns";

import JobQueryPanel from "./job-board-query-panel";
import ItemBoard, { ItemBoardBody, ItemBoardHeader } from "../_shared/item-board/item-board";
import { constructTimeRemainingString, renderName } from "./_shared/utils";
import List from "../_shared/item-list/item-list";

import "../_shared/item-board/item-board.css"

const JobBoardHome = ({
    jobsItems: items,
    doJobsSave: sendFunc,
    doJobsDelete: deleteFunc,
    doJobsInitUrlParams,
    jobsPageIdx: pageIdx,
    jobsIsLoading: isLoading,
    jobsFlags: flags,
    doJobsUpdateLeavingPage,
    jobsLeavingPage,
    doJobsFetchCommonTags,
    doJobsFetchUserRoles,
    jobsState,
    doJobsUpdateQueryString: doItemsUpdateQueryString,
    jobsTotalQueried: totalQueriedItems
}) => {
    useEffect(() => {
        doJobsInitUrlParams();
        doJobsFetchUserRoles();
        doJobsFetchCommonTags();
        doJobsUpdateLeavingPage();
    }, []);

    useEffect(() => {
        return () => {
            doJobsUpdateLeavingPage();
        }
    }, [jobsLeavingPage]);

    const renderLocation = (item) => {
        if (item.addr === '') return null
        return (
            <div className="d-block px-1 py-0 text-truncate text-right mr-2 font-weight-normal">
                <span className='mdi mdi-map-marker-outline ml-auto mr-2' />
                <span>{item.addr}</span>
            </div>)
    }
    const renderJobDates = (item) => {
        let startStr = item.date_start ? format(new Date(item.date_start), 'MM/dd/yyyy') : null;
        let endStr = item.date_end ? format(new Date(item.date_end), 'MM/dd/yyyy') : null;
        let specificDates = startStr && endStr;
        return (
            <div className={`text-truncate text-right d-${specificDates ? 'sm-inline d-none' : 'none'} mr-2 font-weight-normal`} >
                <span className='mdi mdi-calendar ml-auto mr-2' style={{ fontSize: "16px" }} />
                {specificDates ? `${startStr} - ${endStr}` : null}
            </div>)
    }
    const renderJobAboutInfo = (item) => {
        return (
            <div className="text-right text-truncate px-1 ">
                <strong className="text-primary">{item.created_by_name}</strong>
            </div>
        )
    }
    const renderJobDateInfo = (item) => {
        return (
            <div className="text-right px-1 ">
                <span className="d-none d-lg-inline text-primary">{new Date(item.published_date).toLocaleString()}</span>
                <span className="d-inline d-lg-none text-primary">{new Date(item.published_date).toLocaleDateString()}</span>
            </div>
        )
    }
    const renderJobExpirationInfo = (item) => {
        if (item.published) {
            let { timeRemainingString, timeRemainingCls, expired } = constructTimeRemainingString(item.published_date)
            let expStr = `${expired ? 'Expired' : 'Expires in'} ${timeRemainingString} ${expired ? 'ago' : ''}`
            return (
                <small className={timeRemainingCls + " ml-auto d-none d-lg-inline px-1"} style={{ fontSize: "12px" }}>
                    <span className='mdi mdi-update mt-0 mr-2' />
                    {expStr}
                </small>
            )
        }
        else return null
    }

    const itemContentDetailsCol = (item) => {
        return (
            <div className="d-none d-lg-flex col-lg-auto px-0 flex-column justify-content-center ">
                {renderLocation(item)}
                {renderJobDates(item)}
                {(item.date_start_tentative === 1 || item.requirements.length > 0) &&
                    <div className="d-flex flex-row ml-auto mr-2">
                        {item.date_start_tentative === 1 ? (
                            <span className={`d-none d-lg-flex badge bg-info p-1 ml-auto mb-auto ${item.requirements.length > 0 ? 'mr-2' : ''}`}>
                                Dates Tentative
                            </span>
                        ) : null}
                        {item.requirements.length > 0 ? <span className="badge badge-danger d-none d-lg-inline align-items-center p-1 ml-auto mb-auto">Requirements</span> : null}
                    </div>
                }
            </div>
        )
    }
    const renderNewIcon = (item) => {
        if (item.is_recent) return <span className="item-is-recent-tag" />
        return null
    }
    const itemCardRowLayout = {
        rowCls:"justify-content-between",
        cardInfo: { style: { height: "90px" } },
        titleInfo: { wrapperCls: "col-6 col-lg-5" },
        bodyInfo: { wrapperCls: "col-6 col-lg-auto px-0 h-100" },

        aboutItemInfo: {
            items: [
                { component: renderJobAboutInfo },
                { component: renderJobDateInfo },
                { component: renderJobExpirationInfo }
            ], wrapperCls: "col-6 col-lg-auto border-left px-0 justify-content-center", wrapperStyle: { fontWeight: "lighter", width: "12rem" }
        }
    }

    let boardProps = { doItemsUpdateQueryString, totalQueriedItems, pageIdx };
    let listProps = {
        itemProps: {
            itemType: "Job",
            itemCardRowLayout, dateAttr: "published_date",
            renderName, sendFunc, deleteFunc,
            renderNewIcon, itemContentDetailsCol,
        },
        boardUrl: { params: [], pattern: "/job-board" },
        minHeight: "80vh", items, flags, isLoading
    }

    return (
        <ItemBoard style={{ marginTop: "55px" }} {...boardProps} >
            <ItemBoardHeader>
                <h3 className="mx-auto"><span className="nasa mr-2">MARS</span>Job Board</h3>
            </ItemBoardHeader>
            <ItemBoardBody>
                <JobQueryPanel>
                    {jobsState._jobsMostRecentCount > 0 &&
                        <div className="d-flex flex-row justify-content-center p-0" style={{ marginTop: "-.75rem" }}>
                            <span className="mdi mdi-bell-outline mr-2" style={{ fontSize: "16px" }} />
                            <h6 className="d-none d-lg-inline mt-auto">Since the start of the previous business day,</h6>
                            <h5 className="mx-2">{jobsState._jobsMostRecentCount}</h5>
                            <h6 className="mt-auto">{` new job${jobsState._jobsMostRecentCount > 1 ? 's' : ''} ${jobsState._jobsMostRecentCount > 1 ? "have" : "has"} been published.`}</h6>
                        </div>
                    }
                </JobQueryPanel>
                <List  {...listProps} />
            </ItemBoardBody>
        </ItemBoard>
    )
}
export default connect(
    "selectJobsTotalQueried",
    "selectJobsLeavingPage",
    "selectJobsState",
    "selectJobsFlags",
    "selectJobsItems",
    "selectJobsIsLoading",
    "selectJobsPageIdx",
    "doJobsInitUrlParams",
    "doJobsNewFormStateUpdate",
    "doJobsFetchCommonTags",
    "doJobsSave",
    "doJobsDelete",
    "doJobsUpdateLeavingPage",
    "doJobsFetchUserRoles",
    "doJobsUpdateQueryString",
    JobBoardHome
);