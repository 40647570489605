import createRestBundle from "./create-rest-bundle";
// import { createSelector } from "redux-bundler";

export default createRestBundle({
  name: "transfersEquipment",
  uid: "id",
  prefetch: true,
  staleAfter: 30000,
  persist: false,
  routeParam: "",
  getTemplate: "/orgs/:orgSlug/transfers/:transferSlug/equipment",
  putTemplate: "/orgs/:orgSlug/transfers/:transferSlug/equipment/:item.id",
  postTemplate: "",
  deleteTemplate: "/orgs/:orgSlug/transfers/:transferSlug/equipment/:item.id",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN"],
  forceFetchActions: ["TRANSFERSEQUPIMENT_SAVE_FINISHED"],
  addons: {},
});
