import pkg from "../../package.json";
import Url from "url-parse";
import { createSelector } from "redux-bundler";

export default {
  name: "nestedUrl",

  getReducer: () => {
    const initialData = {
      previousRoute: "/",
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "SET_PREVIOUS_ROUTE":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doSetPreviousRoute:
    () =>
    ({ store, dispatch }) => {
      dispatch({
        type: "SET_PREVIOUS_ROUTE",
        payload: { previousRoute: window.location.pathname },
      });
    },

  doUpdateUrlWithHomepage:
    (path) =>
    ({ store }) => {
      if (!pkg || !pkg.homepage) return store.doUpdateRelativeUrl(path);
      store.doUpdateRelativeUrl(`${pkg.homepage}${path}`);
    },

  selectPreviousRoute: (state) => state.nestedUrl.previousRoute,

  selectHomepage: (state) => {
    if (!pkg || !pkg.homepage) return "";
    const url = new Url(pkg.homepage);
    return url.pathname;
  },

  selectPathnameMinusHomepage: createSelector(
    "selectPathname",
    "selectHomepage",
    (pathname, homepage) => {
      const matcher = new RegExp(homepage);
      return pathname.replace(matcher, "");
    }
  ),

  selectPublicFolder: createSelector("selectHomepage", (homepage) => {
    if (process.env.NODE_ENV !== "production") return "";
    return `${homepage}/`;
  }),
};
