import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import "./documentation.css";
import RoleFilter from "../../app-containers/context-providers/role-filter";

const DocumentationModalNav = ({ documentationCurrentPage:currentPage, documentationState, doDocumentationChangePage }) => {
  const { pageKey, focusedSectionKey, pages:statePages, pageKeysOrdered } = documentationState;

  const changePage = (e) => {
    doDocumentationChangePage(e.currentTarget.name, false, (selectedSection) => {
      let selectedSectionLink = document.getElementsByName(e.currentTarget.name)[0];
      let selectedSectionElement = document.getElementById(e.currentTarget.name);
      let selectedSectionIsChapter = !selectedSection.hasOwnProperty("parentPage");
      document.querySelectorAll(".documentation-nav .sub-nav-item button").forEach(item => {
        item.classList.remove("selected");
      });
      selectedSectionLink.classList.add("selected");
      if (selectedSectionIsChapter) window.scrollTo(0, 0);
      else if (selectedSection.parentPage && selectedSectionElement) {
        selectedSectionElement.scrollIntoView();
      }
    });
  }
  useEffect(() => {
    let sectionState = statePages[focusedSectionKey];
    let navLink = document.getElementsByName(currentPage._key);
    if (sectionState.sections.length > 0) window.scrollTo(0, 0);
    else navLink[0] && navLink[0].scrollIntoView();
  }, [currentPage]);
  const renderAccordion = (item) => {
    let itemState = statePages[item._key];
    if (itemState) {
      return (
        <div className={`collapse ${itemState.subMenuShowing ? "show" : ""}`}>
          <ul className="sidebar-nav ml-3 pl-4">
            {
              item.sections.map((childKey, childIdx) => {
                let section = statePages[childKey];
                return (
                  <RoleFilter allowRoles={section.allowRoles ? section.allowRoles : ["*.*"]}>
                    <li className="nav-item sub-nav-item" key={childIdx}>
                      <button className="btn btn-link nav-link " key={childIdx} name={childKey} onClick={changePage}>{section.title}</button>
                    </li>
                  </RoleFilter>
                );
              })
            }
          </ul>
        </div>
      )
    }
  }
  return (
    <div className="sidebar documentation-nav">
      <div className="sidebar-header border-bottom">
        <h6 className="my-auto"><span className="nasa">MARS</span> Documentation</h6>
      </div>
      <ul className="sidebar-nav pl-2" style={{overflowY:"auto"} }>
        {pageKeysOrdered.map((key, idx) => {
          let chapter = statePages[key];
          if (!chapter.parentPage) {
            let parentLinkIsCurrent = key === pageKey;
            let hasSections = chapter.sections.length > 0;
            return (
              <RoleFilter allowRoles={chapter.allowRoles ? chapter.allowRoles : ["*.*"]}>
                <li className="nav-item" key={idx}>
                  <button className={`btn btn-link nav-link w-100 d-flex ${parentLinkIsCurrent? "on-page":""}`} name={key} onClick={changePage}>
                    <span className="ml-0 mr-auto">
                      {chapter.title}
                    </span>
                    {hasSections && <i className={`mdi mdi-chevron-${statePages[key].subMenuShowing ? "up" : "down"} ml-2 mr-0`} style={{ fontSize: "16px" }} />}
                  </button>
                  {hasSections && renderAccordion(chapter)}
                </li>
              </RoleFilter>
            );
          }
        })}
      </ul>
    </div>
  )
}
export default connect(
  "doDocumentationChangePage",
  "selectDocumentationState",
  "selectDocumentationCurrentPage",
  DocumentationModalNav
);

