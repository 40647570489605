import React from "react";

export default ({ notes, active, setActive }) => {
  const handleNew = () => {
    setActive({
      id: null,
      version: "",
      note: ""
    });
  };

  const handleSelect = note => {
    setActive(note);
  };

  notes.sort((a, b) => {
    if (a.version > b.version) return -1;
    if (a.version < b.version) return 1;
    return 0;
  });

  return (
    <div>
      <div className="clearfix mb-3">
        <div className="float-right">
          <button className="btn btn-primary" onClick={handleNew}>
            <i className="mdi mdi-note-plus pr-1"></i>New
          </button>
        </div>
      </div>
      <ul className="list-group">
        {notes.map((note, i) => {
          return (
            <li
              onClick={() => {
                handleSelect(note);
              }}
              key={note.version + i}
              className={`list-group-item ${note === active ? "active" : ""}`}
            >
              {note.version}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
