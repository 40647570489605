import React from "react";
import { connect } from "redux-bundler-react";
import pkg from "../../../package.json";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showall: false,
      dontshow: props.aboutDontshow,
    };
    this.clear = this.clear.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

  handleCheck(e) {
    this.setState({
      dontshow: e.target.checked,
    });
  }

  clear() {
    const { doAboutClear } = this.props;
    const { dontshow } = this.state;
    doAboutClear(dontshow);
  }

  render() {
    const { releaseNotesItemsParsed } = this.props;
    const { dontshow, showall } = this.state;
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">About MARS</h5>
          <button
            onClick={this.clear}
            className="close"
            type="button"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="modal-body">
          <h5>
            {dontshow ? null : (
              <span className="badge badge-success mr-2">NEW</span>
            )}
            Current Version: {pkg.version}
          </h5>
          {releaseNotesItemsParsed
            .filter((item) => {
              if (!showall && item.version.indexOf(pkg.version) === -1)
                return null;
              return item;
            })
            .map((item, i) => {
              if (!item) return null;
              return (
                <div key={i}>
                  <h3>{item.version}</h3>
                  <div dangerouslySetInnerHTML={{ __html: item.html }} />
                </div>
              );
            })}
          {!showall ? (
            <button
              onClick={() => {
                this.setState({ showall: true });
              }}
              className="btn btn-sm btn-secondary"
            >
              Show All Notes from Previous Versions
            </button>
          ) : null}
        </div>

        <div className="modal-footer">
          <label className="form-label mr-3">
            Don't Show on Startup
            <input
              className="form-input ml-2"
              type="checkbox"
              checked={dontshow}
              onChange={this.handleCheck}
            ></input>
          </label>
          <button
            onClick={this.clear}
            className="btn btn-sm btn-secondary"
            type="button"
          >
            Close
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  "selectAboutDontshow",
  "selectReleaseNotesItemsParsed",
  "doAboutClear",
  About
);
