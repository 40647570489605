import React from "react";
import classnames from "classnames";
import { connect } from "redux-bundler-react";
import Dropdown from "../item-board/dropdown";
import DeleteDialog from "../item-board/delete-dialog";

import "./item-list.css";

const ItemCard = ({
  renderName,
  renderNewIcon,
  doDialogClose,
  commentCountComponent,
  dateAttr,
  sendFunc,
  deleteFunc,
  itemType,
  boardUrl,
  children,
  itemData,
  itemCardRowLayout,
  doUpdateRelativeUrl,
  routeInfo,
  isUsers,
  userIsATPM,
  userIsHqAdmin,
  itemCardPrependedCol,
  includeDeleteBtnDropdown,
  doDialogOpen,
  onItemClick,
  updateUrl=true
}) => {
  const { titleInfo, cardInfo, rowCls } = itemCardRowLayout;
  const itemClicked = (e) => {
    if (e.target.classList.contains("dropdown-toggle")) {
      e.persist();
      e.preventDefault();
      return;
    }
    if (["A", "BUTTON"].includes(e.target.nodeName)) {
      e.preventDefault();
      return;
    } else {
      let destUrl = boardUrl.pattern;
      boardUrl.params.forEach((param) => {
        destUrl = destUrl.replace(`:${param}`, routeInfo.params[param]);
      });
      if (updateUrl) doUpdateRelativeUrl(`${destUrl}/${itemData.slug}`);
      onItemClick && onItemClick(e)
      return;
    }
  };

  const deleteBtnClicked = (update) => {
    let deleteTitle = `Deleting ${itemType}`;
    let deleteType = "normalDelete";
    let deleteText = `Are you sure? Deleting this ${itemType.toLowerCase()} will only mark it as deleted. Associated items (comments, ratings, etc.) will still be viewable.`;
    if (update.type === "permDelete") {
      deleteType = "permanentDelete";
      deleteText = `Are you sure? Deleting this ${itemType.toLowerCase()} will remove it from the ${itemType.toLowerCase()} board. Associated items (comments, ratings, etc.) will not be viewable.`;
      deleteTitle = `Deleting ${itemType} Permanently`;
    }
    doDialogOpen({
      content: DeleteDialog,
      props: {
        data: { ...itemData },
        deleteType: deleteType,
        deleteText: deleteText,
        title: deleteTitle,
        dataType: itemType.toLowerCase(),
        sendFunc: sendFunc,
        deleteFunc: deleteFunc,
        returnUrl: routeInfo.url,
        doUpdateRelativeUrl: doUpdateRelativeUrl,
        doDialogClose: doDialogClose,
      },
    });
  };
  const renderDeleteBtnDropdown = () => {
    if (!isUsers && !userIsHqAdmin && !userIsATPM) return null;
    let options = [
      {
        update: { type: "normalDelete" },
        displayValue: "normalDelete",
        text: "Delete",
        handler: deleteBtnClicked,
        exitOnClick: true,
        itemCls: "text-danger",
      },
    ];
    if (userIsATPM) {
      options.push({
        update: { type: "permDelete" },
        displayValue: "permDelete",
        text: "Delete Permanently",
        handler: deleteBtnClicked,
        exitOnClick: true,
        itemCls: "text-danger",
      });
    }
    return (
      <Dropdown
        btnGroupCls="d-none d-sm-inline delete-dropdown-card"
        btnCls="btn-sm btn-outline-danger"
        title={{ type: "icon", value: "mdi mdi-delete-forever" }}
        options={options}
        direction="dropdown-menu-right"
      />
    );
  };
  const titleColCls = classnames({
    "px-0 d-flex": true,
    [titleInfo.wrapperCls]: true,
  });
  const { bodyInfo, aboutItemInfo } = itemCardRowLayout;
  const cardBodyColCls = classnames({
    "px-1 h-100": true,
    [bodyInfo.wrapperCls]: true,
  });
  const aboutItemInfoColCls = classnames({
    "d-flex flex-column": true,
    [aboutItemInfo.wrapperCls]: true,
  });
  const itemRowCls = classnames({
    "row mx-0 h-100 w-100": true,
    [rowCls]:rowCls
  })
  return (
    <div
      className="item-card list-group-item list-group-item-action d-flex"
      onClick={itemClicked}
      name={itemData.slug}
      style={cardInfo.style}
    >
      {renderNewIcon ? renderNewIcon(itemData) : null}
      <div className="mx-0 px-0 w-100 h-100" style={{ pointerEvents: "none" }}>
        <div className={itemRowCls}>
          {itemCardPrependedCol ? itemCardPrependedCol(itemData) : null}
          <div className={titleColCls}>
            <div className="d-block mb-3 text-left text-break text-truncate font-weight-bold align-self-center px-1">
              {itemData.title}
            </div>
          </div>
          <div className={cardBodyColCls}>
            <div className="row mx-0 h-100 justify-content-end w-100">
              {children}
              <div
                className={aboutItemInfoColCls}
                style={aboutItemInfo.wrapperStyle}
              >
                <div className="text-right text-truncate px-0 text-primary">
                  {renderName ? (
                    renderName(itemData, routeInfo)
                  ) : (
                    <strong className="text-primary">
                      {itemData.created_by_name}
                    </strong>
                  )}
                </div>
                <div className="text-right px-1 text-truncate">
                  <span className="d-none d-md-inline">
                    {new Date(itemData[dateAttr]).toLocaleString()}
                  </span>
                  <span className="d-inline d-md-none">
                    {new Date(itemData[dateAttr]).toLocaleDateString()}
                  </span>
                </div>
                {commentCountComponent ? commentCountComponent(itemData) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {includeDeleteBtnDropdown && renderDeleteBtnDropdown()}
    </div>
  );
};

export default connect(
  "doUpdateRelativeUrl",
  "selectRouteInfo",
  "doDialogOpen",
  "doDialogClose",
  ItemCard
);
