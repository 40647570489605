import React, { useReducer } from "react";
import { connect } from "redux-bundler-react";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "UPDATE_INPUT":
      return { ...state, ...payload };
    default:
      return state;
  }
};

const Downtime = ({ doDialogClose, doDowntimeSave, data }) => {
  const [state, dispatch] = useReducer(reducer, { ...data });

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      payload: { [e.target.name]: e.target.value },
    });
  };

  const handleCheck = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      payload: { [e.target.name]: e.target.checked },
    });
  };

  const _submit = () => {
    state.start_time = new Date(state.start_time);
    state.end_time = new Date(state.end_time);
    doDowntimeSave(state, doDialogClose);
  };

  return (
    <div className="modal-content mx-auto">
      <div className="modal-header">
        <h5 className="modal-title">Admin Message</h5>
        <button
          className="close"
          type="button"
          aria-label="Close"
          onClick={doDialogClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body" style={{ overflow: "visible" }}>
        <div className="d-flex flex-column">
          <label className="mb-2" htmlFor="title">
            Title
          </label>
          <input
            className="form-control"
            name="title"
            value={state.title}
            onChange={handleChange}
          />
        </div>
        <div className="d-flex flex-column mt-2">
          <label className="mb-2" htmlFor="description">
            Description
          </label>
          <textarea
            className="form-control"
            name="description"
            value={state.description}
            onChange={handleChange}
          />
        </div>
        <div className="d-flex w-100 mt-2">
          <div className="d-flex flex-column mr-2 w-100">
            <label className="mb-2" htmlFor="start_time">
              Start Time
            </label>
            <input
              type="datetime-local"
              className="form-control"
              name="start_time"
              value={state.start_time}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex flex-column ml-2 w-100">
            <label className="mb-2" htmlFor="end_time">
              End Time
            </label>
            <input
              type="datetime-local"
              className="form-control"
              name="end_time"
              value={state.end_time}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="d-flex align-items-center mt-2">
          <input
            type="checkbox"
            name="lock_application"
            value={state.lock_application}
            onChange={handleCheck}
            checked={state.lock_application}
            id="lock_application"
          />
          <label htmlFor="lock_application" className="ml-2 mb-0">
            Selecting this will lock users from logging in between the start and
            end time marked above.
          </label>
        </div>
      </div>

      <div className="modal-footer">
        <button
          onClick={doDialogClose}
          className="btn btn-sm btn-secondary"
          type="button"
        >
          Close
        </button>
        <button
          onClick={_submit}
          className="btn btn-sm btn-success"
          type="button"
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default connect("doDialogClose", "doDowntimeSave", Downtime);
