import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "platformsDocs",
  uid: "s3_key",
  prefetch: true,
  routeParam: null,
  getTemplate: "/platforms/:platformsSlug/docs",
  fetchActions: [
    "AUTH_LOGGED_IN",
    "FILE_IO_UPLOAD_FINISHED",
    "FILE_IO_DELETE_FINISHED",
    "AIRCRAFT_FETCH_FINISHED",
    "PLATFORMS_FETCH_FINISHED",
    "URL_UPDATED",
  ],
  urlParamSelectors: ["selectAircraftByUrlPlatformSlug"],
});
