
import { omitBy, groupBy, mapValues } from "lodash";
import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";
export default createRestBundle({
    name: "gradeSlipEntry",
    uid: "id",
    prefetch: true,
    persist: true,
    routeParam: null,
    getTemplate: "/profile/:id/gradeSlip/entries",
    putTemplate: "/profile/:id/gradeSlip/entries/:item.id",
    deleteTemplate: "/profile/:id/gradeSlip/entries/:item.id",
    postTemplate: "/profile/:id/gradeSlip/entries",
    urlParamSelectors: ["selectProfileActiveData"],
    fetchActions: ["PROFILECURRENCY_FETCH_FINISHED", "GRADESLIPENTRY_SAVE_FINISHED", "GRADESLIPENTRY_DELETE_FINISHED"],
    addons: {
        selectGradeSlipEntryShouldFetch: state => state.gradeSlipEntry._shouldFetch,
        selectGradeSlipEntryItemsObjectByForm: createSelector(
            "selectGradeSlipEntryItemsObject",
            "selectGradeSlipSelectedForm",
          (gradeSlipEntryItemsObject, gradeSlipSelectedForm) => {
                if (!gradeSlipSelectedForm) return {};
                return omitBy(gradeSlipEntryItemsObject, (value, key) => value.grade_slip_id !== gradeSlipSelectedForm.id);
            }
        ),
        selectGradeSlipEntryItemsPrintableAll:createSelector(
          "selectGradeSlipEntryItemsObject",
          "selectGradeSlipState",
          (gradeSlipEntryItemsObject, gradeSlipState) => {
            let allGradeSlipEntryItemsByForm = groupBy(Object.values(gradeSlipEntryItemsObject), 'grade_slip_id');
            
            let { _flightModeOptionsObj, _taskOptionsObj } = gradeSlipState;
            
            return mapValues(allGradeSlipEntryItemsByForm, entryItems => {
              entryItems.sort((a, b) => {
                if (new Date(a.date) > new Date(b.date)) return 1;
                else if (new Date(a.date) < new Date(b.date)) return -1;
                else return 0
              })
              let gradeSlipEntries = entryItems.map(item => {

                let taskExists = _taskOptionsObj[item.task];
                let flightModeExists = _flightModeOptionsObj[item.flight_mode];

                let task_str = taskExists ? taskExists.label : '';
                let grade_str = item.grade ? item.grade.toUpperCase() : '';
                let flight_mode_str = flightModeExists ? flightModeExists.label : '';
                if (grade_str === "NA") grade_str = "N/A";
                if (taskExists && taskExists.label === "FREE TEXT ENTRY") {
                  task_str = item.task_variable;
                }
                return { ...item, task_str: task_str, grade_str: grade_str, flight_mode_str: flight_mode_str };
              });
              return gradeSlipEntries;
            } )
          }
        ),
        selectGradeSlipEntryItemsPrintable: createSelector(
            "selectGradeSlipEntryItemsObjectByForm",
            "selectGradeSlipState",
            (gradeSlipEntryItemsObjectByForm, gradeSlipState) => {
                let { _flightModeOptionsObj, _taskOptionsObj } = gradeSlipState;
                let items = Object.values(gradeSlipEntryItemsObjectByForm).sort((a, b) => {
                    if (new Date(a.date) > new Date(b.date)) return 1;
                    else if (new Date(a.date) < new Date(b.date)) return -1;
                    else return 0
                })
                let gradeSlipEntries = items.map(item => {

                    let taskExists = _taskOptionsObj[item.task];
                    let flightModeExists = _flightModeOptionsObj[item.flight_mode];

                    let task_str = taskExists ? taskExists.label : '';
                    let grade_str = item.grade ? item.grade.toUpperCase() : '';
                    let flight_mode_str = flightModeExists ? flightModeExists.label : '';
                    if (grade_str === "NA") grade_str = "N/A";
                    if (taskExists && taskExists.label === "FREE TEXT ENTRY") {
                        task_str = item.task_variable;
                    }
                    return { ...item, task_str: task_str, grade_str: grade_str, flight_mode_str: flight_mode_str };
                });
                return gradeSlipEntries;
            }
        )
    }
});