import React from "react";
import { connect } from "redux-bundler-react";
import efTwentyTwo from "../modals/ef-twentytwo";

const EfTwentyTwoCard = ({
  doNestedDialogOpen,
  efTwentyTwoItems,
  profileAlertItems,
}) => {
  let alert = profileAlertItems.filter(
    (alert) =>
      ["SQC Missing", "Signatures Missing"].indexOf(alert.alert_type) !== -1
  );
  let formStatus = alert && alert.length ? alert[0].type : "";
  return (
    <div
      className={`card border border-${formStatus} position-relative`}
      style={{ width: "18rem" }}
    >
      {["danger", "warning"].indexOf(formStatus) !== -1 && (
        <div className="position-absolute" style={{ top: 16, right: 16 }}>
          <i className={`mdi mdi-alert-outline text-${formStatus} mdi-18px`} />
        </div>
      )}
      <div className="card-body p-3">
        <h5 className="card-title pb-2 border-bottom">EF-7122</h5>
        <button
          className="btn btn-link p-0 mt-2"
          onClick={() =>
            doNestedDialogOpen({
              content: efTwentyTwo,
              props: { data: efTwentyTwoItems, scrollable: true },
              size: "xl",
            })
          }
        >
          View Form
        </button>
      </div>
    </div>
  );
};

export default connect(
  "selectEfTwentyTwoItems",
  "doNestedDialogOpen",
  "selectProfileAlertItems",
  EfTwentyTwoCard
);
