import React from "react";

const BenignToggle = (props) => {
  const _onChange = (e) => {
    props.doSave({
      ...props.data,
      benign_approved: !props.data.benign_approved,
    });
  };
  return (
    <div className="custom-control custom-switch d-flex align-items-center justify-content-center">
      <input
        type="checkbox"
        className="custom-control-input"
        id={`${props.data.id}_benign`}
        checked={props.data.benign_approved}
        value={props.data.benign_approved ?? false}
        onChange={_onChange}
      />
      <label
        className="custom-control-label"
        htmlFor={`${props.data.id}_benign`}
      >
        {/* {props.data.benign_approved ? "Yes" : "No"} */}
      </label>
    </div>
  );
};
export default BenignToggle;
