import React, { useState, useEffect } from "react";

const CheckBox = ({ checked, value, onChange, onOnly, label }) => {
  const handleChange = (e) => {
    onChange(e, value);
  };
  const handleOnly = (e) => {
    onOnly(value);
  };
  return (
    <div className="form-check checkbox hover-target">
      <span
        onClick={handleOnly}
        style={{ position: "absolute", right: 0 }}
        className="show-on-hover link">
        only
      </span>
      <label
        className="form-check-label overflow-ellipsis"
        style={{
          display: "inline-block",
          paddingRight: "40px",
          width: "100%",
        }}
        title={label}>
        <input
          className="form-check-input"
          type="checkbox"
          checked={checked}
          onChange={handleChange}
        />
        {label}
      </label>
    </div>
  );
};

export default ({ title, options, onChange, column }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  // where options is an array of object { id: ..., val: ... }
  const checkedKeys = options.reduce((prev, cur) => {
    if (!prev.hasOwnProperty(cur.id)) prev[cur.id] = !!cur.checkedByDefault;
    return prev;
  }, {});

  const [checked, setChecked] = useState({});

  const updateQueryString = (obj) => {
    const query = { [column]: [] };
    Object.keys(obj).forEach((key) => {
      if (obj[key]) query[column].push(key);
    });
    return query;
  };
  // set the checked object when props refresh
  useEffect(() => {
    setChecked(checkedKeys);
    return () => {
      // clean up so our old options don't show up
      setChecked({});
    };
  }, [setChecked, options]);

  const handleCheck = (e, key) => {
    const isChecked = e.target.checked;
    setChecked({
      ...checked,
      [key]: isChecked,
    });
    onChange(
      updateQueryString({
        ...checked,
        [key]: isChecked,
      })
    );
  };

  const handleOnly = (key) => {
    Object.keys(checked).forEach((chkKey) => {
      checked[chkKey] = false;
    });
    setChecked({
      ...checked,
      [key]: true,
    });
    onChange(
      updateQueryString({
        ...checked,
        [key]: true,
      })
    );
  };

  const handleAll = (e) => {
    Object.keys(checked).forEach((chkKey) => {
      checked[chkKey] = e.target.checked;
    });
    setChecked({ ...checked });
    onChange(updateQueryString({ ...checked }));
  };

  /** this should be in the mission-report as a one time useeffect, not for every single filter.... */
  /** commenting this out reduces the number of calls made on mount of component. But removing this also means we had to add onChange({}) to each handleX fn */

  // useEffect(() => {
  //   const query = { [column]: [] };
  // Object.keys(checked).forEach((key) => {
  //   if (checked[key]) query[column].push(key);
  // });
  //   onChange(query);
  // }, [checked, onChange]);

  const checkedValues = Object.values(checked);
  let allChecked = true;
  for (var i = 0; i < checkedValues.length; i++) {
    if (!checkedValues[i]) {
      allChecked = false;
      break;
    }
  }

  let hasCondition = false;
  for (i = 0; i < checkedValues.length; i++) {
    if (checkedValues[i]) {
      hasCondition = true;
      break;
    }
  }

  return (
    <div className="card mb-0">
      <div className="card-header" onDoubleClick={toggleExpanded}>
        {title}
        <i
          className={`mdi mdi-filter-variant text-${
            hasCondition ? "primary" : "muted"
          } pl-2`}></i>
        <div className="card-header-actions">
          <span onClick={toggleExpanded}>
            <i
              className={`mdi mdi-${
                expanded ? "chevron-down" : "chevron-right"
              }`}></i>
          </span>
        </div>
      </div>
      {expanded ? (
        <div className="card-body">
          <div className="form-check checkbox">
            <label
              className="form-check-label overflow-ellipsis"
              style={{
                display: "inline-block",
                paddingRight: "40px",
                width: "100%",
              }}
              title="Select or Deselect all options">
              <input
                className="form-check-input"
                type="checkbox"
                checked={allChecked}
                onChange={handleAll}
              />
              Select All
            </label>
          </div>
          <p />
          <div className="">
            {options.map((opt, i) => {
              return (
                <CheckBox
                  key={i}
                  checked={checked[opt.id]}
                  value={opt.id}
                  label={opt.val}
                  onChange={handleCheck}
                  onOnly={handleOnly}
                />
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};
