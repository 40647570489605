import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "faqs",
  uid: "id",
  prefetch: true,
  staleAfter: 1000 * 60 * 60 * 12,

  persist: false,
  routeParam: null,
  getTemplate: "/faqs",
  putTemplate: "/faqs/:item.id",
  postTemplate: "/faqs",
  deleteTemplate: "/faqs/:item.id",

  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN"],
  forceFetchActions: ["FAQS_SAVE_FINISHED", "FAQS_DELETE_FINISHED"],
  reduceFurther: (state, { type, payload }) => {
    switch (type) {
      case "FAQS_CONTENT_UPDATE":
        return Object.assign({}, state, payload);
      default:
        return state;
    }
  },
  addons: {


    doFaqsContentInfoUpdate: (item, callback, deferCallback) => ({
      dispatch,
      store,
      apiPut
    }) => {
      dispatch({
        type: "FAQS_CONTENT_UPDATE",
        payload: {
          updatedContentDate: new Date(),
          _isSaving: true
        }
      });

      let url = store.selectFaqsPostUrl();
      apiPut(`${url}/${item.id}`, item, (err, response, body) => {
        if (err || response.statusCode !== 200) {
          dispatch({
            type: "FAQS_ERROR",
            payload: {
              _err: { err: err, response: response },
              notification: {
                statusCode: response.statusCode
              },
              _isSaving: false
            }
          });
        } else {
          // Make sure we're sending save_finished when we're done
          dispatch({
            type: "FAQS_SAVE_FINISHED",
            payload: {
              _isSaving: false
            }
          });

          if (deferCallback && callback) callback();
        }
        // if we get a callback, go ahead and fire it
        if (!deferCallback && callback) callback();
      });
    },


  }
});