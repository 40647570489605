import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import ValuePicker from "../_shared/checkbox-search";
import Grid from "../../_shared/grid";
import "../_shared/filter.css";
import DateRangeSearch from "../_shared/date-range-search";
import NumberRangeSearch from "../_shared/number-range-search";
import TextSearch from "../_shared/text-search";
import { customComparator } from "../../_shared/helper"
import { reduce } from "lodash";
const config = {
  columnDefs: [
    {
      resizable: true,
      sortable: true,
      headerName: "Orgs",
      field: "org_name",
      comparator: customComparator,
    },
    {
      resizable: true,
      sortable: true,
      headerName: "Name",
      field: "name",
      comparator: customComparator,
    },
    {
      resizable: true,
      sortable: true,
      headerName: "Make",
      field: "make",
      comparator: customComparator,
    },
    {
      resizable: true,
      sortable: true,
      headerName: "Model",
      field: "model",
      comparator: customComparator,
    },
    {
      resizable: true,
      sortable: true,
      headerName: "Status",
      field: "deactivated",
      comparator: customComparator,
    },
    {
      resizable: true,
      sortable: true,
      headerName: "Form Factor",
      field: "form_factor",
      comparator: customComparator,
    },
    {
      resizable: true,
      sortable: true,
      headerName: "Operational",
      field: "operational",
      comparator: customComparator
    },
    {
      resizable: true,
      sortable: true,
      headerName: "Serial No.",
      field: "serial_no",
      comparator: customComparator
    },
    {
      resizable: true,
      sortable: true,
      headerName: "Barcode",
      field: "barcode",
      comparator: customComparator
    }
  ]
}


const GcsReport = ({ gcsReportItems, gcsReportIsLoading, gcsReportParameters, doGcsReportUpdateQueryString, gcsReportIsStatsLoading, doGcsReportDownloadAsCsv }) => {
  useEffect(() => {
    if (gcsReportParameters) {
      let query = reduce(gcsReportParameters, (result, para) => {
        if (para.hasOwnProperty("options")) {
          result[para.column] = reduce(para.options, (optionsResult, opt) => {
            if (opt.checkedByDefault) optionsResult += opt.val;
            return optionsResult;
          }, "");
        }
        return result;
      }, {});
      doGcsReportUpdateQueryString(query);
    }
  }, [gcsReportParameters]);
  const renderControls = () => {
    if (gcsReportIsStatsLoading || !gcsReportParameters) return null;

    const controls = gcsReportParameters.map((para, idx) => {
          let defaultProps = { column: para.column, onChange: doGcsReportUpdateQueryString, title: para.title };
          switch (para.type) {
            case "checkbox-search":
              return <ValuePicker key={idx} {...defaultProps} options={para.options} />
            case "date-range-search":
              return <DateRangeSearch key={idx} {...defaultProps} />
            case "number-range-search":
              return <NumberRangeSearch key={idx} {...defaultProps} min={Number(para.min)} max={Number(para.max)} />
            case "text-search":
              return <TextSearch key={idx} {...defaultProps} />
            default:
              return null;
          }
    });
    return controls;
  }
  return (
    <div className="container-fluid mt-4">
      <div className="row">
        <div className="col-3 filter">
          {renderControls() }
        </div>
        <div className="col-9">
          <div className="card">
            <div className="card-header">
              <div className="float-right">
                <button
                  className="btn btn-sm btn-ghost-primary float-right"
                  onClick={doGcsReportDownloadAsCsv}
                  disabled={gcsReportItems.length===0}
                >
                  <i className="mdi mdi-download icon-inline" /> CSV
                </button>
              </div>
              Query GCS Records
            </div>
            <div className="card-body p-0">
              <Grid
                isLoading={gcsReportIsLoading}
                data={gcsReportItems}
                config={config}
                style={ { height:`${window.innerHeight*0.8}px`, width:"100%"} }

              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default connect(
  "selectGcsReportIsLoading",
  "selectGcsReportItems",
  "selectGcsReportParameters",
  "doGcsReportUpdateQueryString",
  "doGcsReportDownloadAsCsv",
  "selectGcsReportIsStatsLoading",
  GcsReport
);