import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "missionsDocs",
  uid: "s3_key",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/missions/:missionsSlug/docs",
  fetchActions: [
    "AUTH_LOGGED_IN",
    "FILE_IO_UPLOAD_FINISHED",
    "FILE_IO_DELETE_FINISHED",
  ],
  forceFetchActions: ["ACTIVE_TAB_UPDATE", "URL_UPDATED"],
});
