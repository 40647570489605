import React from "react";
import ComponentTree from "../component-tree";
import classnames from "classnames";
import "../documentation.css";
const Row = ({ className, childComponents = []}) => {
  const rowCls = classnames({
    "row": true,
    [className]: className
  });
  return (
    <div className={rowCls}>
      {childComponents.map(c => {
        let colCls = c.className ? c.className : "";
        if (!colCls.includes("col")) {
          colCls = "col " + colCls
        }
        return <ComponentTree obj={{ ...c, className: colCls }} />
      })}
    </div>
  );
}
export default Row;