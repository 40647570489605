import React, { useRef } from "react";

const FileUploadButton = ({ onFileChange, file }) => {
  const fileInputRef = useRef(null);

  const onChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      onFileChange(selectedFile);
    }
  };

  return (
    <div className="d-flex">
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={onChange}
      />
      <div className="d-flex flex-column">
        <div className="btn-group">
          <button
            className={`btn btn-primary btn-sm d-flex align-items-center`}
            onClick={() => fileInputRef.current.click()}>
            <span className="mdi mdi-14px mdi-paperclip mr-2"></span>
            {!!file ? "Change File" : "Attach File"}
          </button>
          {file && (
            <button
              className="btn btn-sm btn-outline-danger"
              onClick={() => onFileChange(null)}>
              <span className="mdi mdi-14px mdi-trash-can-outline mr-2"></span>
              Remove File
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileUploadButton;
