import React from "react";
import { connect } from "redux-bundler-react";
import Loader from "../../../app-components/loader";
import BacklogMissionListItem from "../../backlog/backlog-mission-list-item";
import LoadingList from "./loading-list";

const SignaturesCard = ({ isLoading, backlogItems, doUpdateRelativeUrl }) => {
  let recentBacklog = backlogItems.slice(0, 3);
  return (
    <div className="currency border-signatures">
      <div className="custom-card-title signatures">
        <h5 className="text-black m-0">Mission Signatures</h5>

        <button
          className="btn btn-sm btn-ghost-dark"
          onClick={() => {
            doUpdateRelativeUrl(`/backlog`);
          }}
        >
          <i className="mdi mdi-format-list-checks icon-inline mdi-18px"></i>
          Go to Backlog
        </button>
      </div>
      <div className="currency-body">
        {!recentBacklog || recentBacklog.length === 0 ? (
          <div className="d-flex flex-column h-100 align-items-center justify-content-center">
            <p className="text-muted text-center h5">
              No signatures that require your attentions!
            </p>
          </div>
        ) : isLoading ? (
          <div className="mt-2">
            <LoadingList />
          </div>
        ) : (
          <table className="table">
            <thead className="thead">
              <tr scope="row">
                <th scope="col" className="mx-auto border-top-0">
                  ORG - Mission
                </th>
                <th scope="col" className="mx-auto border-top-0">
                  Preparer Signature Date
                </th>
                <th scope="col" className="mx-auto border-top-0">
                  Mission Start
                </th>
                <th scope="col" className="mx-auto border-top-0">
                  Approvals
                </th>
              </tr>
            </thead>
            <tbody>
              {recentBacklog.map((item, idx) => (
                <BacklogMissionListItem mission={item} key={idx} />
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default connect(
  "selectBacklogItems",
  "selectProfilesByOrgIsFetching",
  "doUpdateRelativeUrl",
  SignaturesCard
);
