import React from "react";
import classnames from "classnames";
import { connect } from "redux-bundler-react";
import AlertContainer from "../../details/_shared/alert-container";

/***
 * Config should look like
 * {
 *  "Tab Title": Component,
 *  "Tab Title 1": Component1
 * }
 */

class TabContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleTabClick = this.handleTabClick.bind(this);
    this.renderTabs = this.renderTabs.bind(this);
    this.renderTabContent = this.renderTabContent.bind(this);
  }

  handleTabClick(e) {
    const idx = Number(e.currentTarget.dataset.idx);
    this.props.doUpdateActiveTab(idx);
  }

  renderTabs() {
    const { config, mission, activeTab } = this.props;
    return Object.keys(config).map((key, i) => {
      let disabled = false;
      if (typeof config[key] === "object")
        disabled = config[key].disabled(mission);
      const cls = classnames({
        "nav-link": true,
        active: i === activeTab,
        disabled: disabled,
        "text-secondary": disabled,
      });

      return (
        <li
          className="nav-item"
          key={i}
          data-idx={i}
          onClick={(e) => {
            if (!disabled) this.handleTabClick(e);
          }}>
          <div className={cls}>{key}</div>
        </li>
      );
    });
  }

  renderTabContent() {
    const { config, activeTab } = this.props;
    return Object.values(config).map((comp, i) => {
      if (i !== activeTab) return null;
      if (typeof comp === "object") comp = comp.component;
      let tab = Object.keys(config)[i];
      const Comp = comp;
      return (
        <div key={i} className="tab-content">
          <div className="tab-pane active">
            <AlertContainer tab={tab} />
            <Comp />
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <>
        <ul className="nav nav-tabs">{this.renderTabs()}</ul>
        {this.renderTabContent()}
      </>
    );
  }
}

export default connect("doUpdateActiveTab", "selectActiveTab", TabContainer);
