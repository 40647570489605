import React from "react";
import { connect } from "redux-bundler-react";
import HazardCard from "./hazard-card";
import HazardCatCard from "./hazard-cat-card";

class MapHazards extends React.Component {
  constructor(props) {
    super(props);
    this.renderInnerHazards = this.renderInnerHazards.bind(this);
    this.renderOuterHazards = this.renderOuterHazards.bind(this);
  }

  renderInnerHazards() {
    const { hazardsClassified } = this.props;
    if (!hazardsClassified)
      return <div className="mb-3">No hazards found within AOI</div>;
    const { aoi } = hazardsClassified;
    if (!Object.keys(aoi).length)
      return <div className="mb-3">No hazards found within AOI</div>;
    const items = [];
    Object.keys(aoi).forEach((cls) => {
      aoi[cls].forEach((item) => {
        items.push(item);
      });
    });
    return (
      <>
        <p>
          All hazards located within the area of interest MUST be addressed in
          the risk assessment worksheet below.
        </p>
        <div className="row">
          {items.map((item, i) => {
            return <HazardCard item={item} key={i} />;
          })}
        </div>
      </>
    );
  }

  renderOuterHazards() {
    const { hazardsClassified } = this.props;
    if (!hazardsClassified) return `No hazards found within 5 miles of AOI`;
    const { other } = hazardsClassified;
    if (!Object.keys(other).length)
      return `No hazards found within 5 miles of AOI`;
    const items = [];
    Object.keys(other).forEach((cls) => {
      other[cls].forEach((item) => {
        items.push(item);
      });
    });
    return (
      <div className="row">
        {items.map((item, i) => {
          return <HazardCard item={item} key={i} />;
        })}
      </div>
    );
  }

  render() {
    return (
      <div>
        <h5>Hazards within AOI</h5>
        {this.renderInnerHazards()}
        <h5>Hazards within 5 mi of AOI</h5>
        {this.renderOuterHazards()}
      </div>
    );
  }
}

export default connect("selectHazardsClassified", MapHazards);
