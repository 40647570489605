import React from "react";
import MissionCard from "./mission-card";
import AddMissionCard from "./add-mission-card";
import { sortBy } from "lodash";
import Loader from "../../../app-components/loader";
import RoleFilter from "../../../app-containers/context-providers/role-filter";
import { connect } from "redux-bundler-react";

class MissionCardRegion extends React.Component {
  constructor(props) {
    super(props);
    this.renderAddMissionCard = this.renderAddMissionCard.bind(this);
    this.renderCards = this.renderCards.bind(this);
    this.renderEmpty = this.renderEmpty.bind(this);
    this.renderLoader = this.renderLoader.bind(this);
  }

  renderAddMissionCard() {
    const { allowAdd } = this.props;
    if (!allowAdd) return null;
    return (
      <RoleFilter allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}>
        <AddMissionCard />
      </RoleFilter>
    );
  }

  renderEmpty(msg) {
    return (
      <div className="col-sm-6 col-lg-4">
        <div className="brand-card">
          <div className="brand-card-header bg-facebook">
            <i className="mdi mdi-map-plus"></i>
          </div>
          <div className="brand-card-body">
            <div>
              <div className="text-value">{msg}</div>
              <div className="text-uppercase text-muted small">{`..................`}</div>
              <div className="text-uppercase text-muted small">{`.............`}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderLoader() {
    return (
      <div className="col-sm-6 col-lg-4">
        <div className="brand-card" style={{ height: "190px" }}>
          <div className="brand-card-header bg-facebook">
            <Loader opt="dissolve-cube" color="#ffffff" />
          </div>
          <div className="brand-card-body">
            <div>
              <div className="text-value">Refreshing List</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCards() {
    const { items, allowAdd } = this.props;
    if (!items || !items.length) {
      if (allowAdd) return null;
      return this.renderEmpty("No Missions Yet");
    }
    const sorted = sortBy(items, ["date_start", "name"]);
    return (
      <>
        {sorted.map((item, i) => {
          return <MissionCard item={item} key={i} />;
        })}
      </>
    );
  }

  render() {
    if (this.props.isLoading || this.props.missionsIsSaving)
      return this.renderLoader();
    else
      return (
        <div className="row">
          {this.renderAddMissionCard()}
          {this.renderCards()}
        </div>
      );
  }
}

export default connect("selectMissionsIsSaving", MissionCardRegion);
