
import React, { useEffect } from "react";
import FaqsGroupListItem from "./faqs-group-list-item";
import CreateNewGroupListItem from "./create-new-group-list-item"
import classnames from "classnames";
import { connect } from "redux-bundler-react";

const FaqsGroupList = ({ faqsItems: faqs, faqsRatingsItems: faqsRatings, dispatch, state, faqsIsLoading: isLoading, faqsIsSaving: isSaving, handleCRUD }) => {

    const itemDragHandler = (e, currentItem) => {
        //Here, 'currentItem' is the item being dragged/moved
        dispatch({ type: "HANDLE_DRAG_ITEM_OCCURING" })
        e.dataTransfer.setData("text/plain", JSON.stringify(currentItem))
        e.dataTransfer.effectAllowed = "copyMove"
        dispatch({ type: "HANDLE_DRAG_OCCURING", value: true })
    }

    // Handles closure of a drag operation; Set 'dragOccuring' to false and handle a failed drop operation
    const itemDragEndHandler = (e) => {
        dispatch({ type: "HANDLE_DRAG_OCCURING", value: false })

        //If Drop failed, then replace the current items to the items before the drag occured
        if (e.dataTransfer.dropEffect == "none") {
            dispatch({ type: "HANDLE_UPDATE_ITEMS", items: { ...state.itemsBeforeDrag } })
        }
    }

    // Dragged item is successfully dropped in dropbox
    const dropBoxOnDrop = (e, currentItem) => {
        e.preventDefault();

        // FAQ dropped at location of currentItem's dropbox (above it)
        const { items, activeTab, orderedTabNames } = state
        let numItemsInCategory = Object.keys(items).filter(key => items[key].category == orderedTabNames[activeTab]).length
        let data = e.dataTransfer.getData("text/plain");
        let draggedItem = JSON.parse(data)

        // If no category change and the last item in current tab isn't being dropped in the last dropbox, proceed with moving the item
        if (currentItem.category == draggedItem.category && currentItem.display_order == -1 && draggedItem.display_order == numItemsInCategory - 1) {
            return;
        }
        else moveItem(draggedItem, currentItem)
        dispatch({ type: "HANDLE_DRAG_OCCURING", value: false })
    }


    const dropBoxOnMouseOver = (e) => {       // Would be better to replace with CSS 
        e.currentTarget.style.opacity = 1;
        e.target.style.cursor = "pointer"
    }
    const dropBoxOnMouseLeave = (e) => {       // Would be better to replace with CSS 
        e.currentTarget.style.opacity = 0;
    }


    const dropBoxOnDragOver = (e) => {        // Would be better to replace with CSS 
        e.preventDefault();
        e.dataTransfer.dropEffect = "move"
        e.currentTarget.style.opacity = 1;
    }

    // Dropbox functions as 'Insert' button. Insert new FAQ at location of currentItem's dropbox (above it)
    const dropBoxOnMouseDown = (e, currentItem) => {
        e.preventDefault();
        dispatch({ type: "HANDLE_INSERT_ITEM", currentItem: currentItem })
        dispatch({ type: "HANDLE_UNSAVED_ITEMS" })
    }

    const handleNew = (id) => {
        dispatch({ type: "HANDLE_NEW_ITEM", itemId: id })
    }

    const adjustDisplayOrder = () => {
        const { items, categoryViewMode } = state

        if (!categoryViewMode) {
            return Object.keys(items).filter((key) => items[key].deleted == 0)
        }

        // Sort items by their display_order, least to greatest
        let itemKeys = Object.keys(items)
        itemKeys.sort((a, b) => {
            if (items[a].display_order > items[b].display_order) return 1;
            if (items[a].display_order < items[b].display_order) return -1;
            return 0;
        });
        return itemKeys.filter((key) => items[key].deleted == 0)
    }

    const moveItem = (src, dest) => {
        dispatch({ type: "HANDLE_MOVE_ITEMS", src: src, dest: dest })
    }

    const renderFaqItems = () => {

        const { items, orderedTabNames, activeTab, sortOperation, sortDirection, categoryViewMode } = state
        let category = orderedTabNames[activeTab]
        let itemKeys = adjustDisplayOrder();
        if (categoryViewMode) itemKeys = itemKeys.filter((key) => items[key].category == category)

        let sortOpTranslate = { "Helpfulness": "helpfulness", "Most Recent": "last_updated_content_date", "Views": "view_count" }
        if (sortOperation) {
            let sortOpState = sortOpTranslate[sortOperation]

            itemKeys = itemKeys.sort((a, b) => {
                let itemA = items[a][sortOpState];
                let itemB = items[b][sortOpState];

                if (sortOpState == "last_updated_content_date") {
                    itemA = new Date(itemA);
                    itemB = new Date(itemB)
                }

                if (itemA > itemB) {
                    if (sortDirection == "Descending") return 1;
                    else return -1
                }
                if (itemA < itemB) {
                    if (sortDirection == "Descending") return -1;
                    else return 1
                }
                return 0;

            });
        }
        return itemKeys
            .map((key) => {
                let tempId = Number(new Date()).toString()
                return (
                    <FaqsGroupListItem dispatch={dispatch} state={state}
                        key={items[key].id ? items[key].id : tempId}
                        _key={items[key].id ? items[key].id : tempId}          // a prop that can be legally gotten from the child
                        insertNew={handleNew}
                        moveItem={moveItem}
                        itemDragHandler={itemDragHandler}
                        itemDragEndHandler={itemDragEndHandler}

                        dropBoxOnDragOver={dropBoxOnDragOver}
                        dropBoxOnDrop={dropBoxOnDrop}
                        dropBoxOnMouseOver={dropBoxOnMouseOver}
                        dropBoxOnMouseLeave={dropBoxOnMouseLeave}
                        dropBoxOnMouseDown={dropBoxOnMouseDown}
                        faqsRatings={faqsRatings}
                        handleCRUD={handleCRUD}
                    />
                );
            })
    }
    const renderCreateNewItem = () => {
        if (state.mode == "editor") {
            return (
                <CreateNewGroupListItem state={state} handleNew={handleNew}
                    dropBoxOnDragOver={dropBoxOnDragOver}
                    dropBoxOnDrop={dropBoxOnDrop}
                    dropBoxOnMouseOver={dropBoxOnMouseOver}
                    dropBoxOnMouseLeave={dropBoxOnMouseLeave} />
            )
        }
        return null
    }

    useEffect(() => {
        if (!isSaving && !isLoading) {
            dispatch({ type: "HANDLE_RESET_ALL_ITEMS_RENDER_STATUS" })
        }
    }, [isSaving, isLoading])


    // Handles process of fetching FAQ ratings from database
    useEffect(() => {
        dispatch({ type: "RECALC_FAQS_RATINGS" })
    }, [faqsRatings,]);

    // Handles process of fetching FAQs from database
    useEffect(() => {
        // For every FAQ Item fetched from database, set 'fromStorage' to true
        let itemsFromStorage = faqs
            .filter(item => {
                if (item.statusCode == 404) return -1;
                return 1;
            })
            .map(item => {
                return [item.id, { ...item, status: "saved", fromStorage: true, lastSaveState: { display_order: item.display_order, question: item.question, answer: item.answer, status: "saved", reverted: true } }]
            })

        // If FAQ Items are found in database, initialize them into state.items

        itemsFromStorage = Object.fromEntries(new Map(itemsFromStorage));

        dispatch({ type: "HANDLE_BUILD_ITEM_ARRAY", items: { ...itemsFromStorage }, keepSelection: true })
        dispatch({ type: "RECALC_FAQS_RATINGS" })
        dispatch({ type: "HANDLE_SAVING_TAB_DATA", value: false })

    }, [faqs,]);


    const { mode } = state
    const listGroupCls = classnames({
        "faqItemList flex-column list-group mx-auto h-100": mode == "editor",
        "accordion": mode == "preview"
    })

    // Preview Mode, but no FAQS found
    if (mode == "preview" && Object.keys(faqs).length == 0) {
        return (
            <div className="col-sm-6 col-lg-4 mx-auto">
                <div className="brand-card" style={{ height: "190px" }}>
                    <div className="brand-card-header bg-facebook">
                        <i className="mdi mdi-alert-circle-outline" />
                    </div>
                    <div className="brand-card-body">
                        <div>
                            <div className="text-value">Nothing to show</div>
                        </div>
                    </div>
                </div>
            </div>)
    }
    return (
        <div className="container-fluid my-2" >
            <div className={listGroupCls} >
                {renderFaqItems()}
                {renderCreateNewItem()}
            </div>
        </div>
    );

}


export default connect(
    "selectFaqsRatingsItems",
    "selectFaqsIsSaving",
    "selectFaqsIsLoading",
    "selectFaqsItems",
    FaqsGroupList);