import classNames from "classnames";
import React from "react";
import { connect } from "redux-bundler-react";
import Grid from "../../_shared/grid";

const EquipmentModal = ({ doDialogClose, transfer }) => {
  const cls = (item) => {
    const { type, operational } = item;
    return classNames({
      mdi: ["aircraft", "batteries", "payload"].indexOf(type) !== -1,
      ms: type === "gcs",
      "mdi-airplane": type === "aircraft",
      "mdi-car-battery": type === "batteries",
      "mdi-camera": type === "payload",
      "ms-drone-remote": type === "gcs",
      "text-success": operational === "FMC",
      "text-warning": operational === "PMC",
      "text-danger": operational === "NMC",
      "text-muted": !operational,
      "mr-1": true,
    });
  };
  const config = {
    columnDefs: [
      {
        resizable: true,
        sortable: true,
        headerName: "Type",
        field: "type",
        filter: "agTextColumnFilter",
        width: 75,
        cellRenderer: (params) => {
          return <i className={`${cls(params.data)} mdi-18px`} />;
        },
      },
      {
        resizable: true,
        sortable: true,
        headerName: "Make",
        field: "make",
        filter: "agTextColumnFilter",
        width: 100,
      },
      {
        resizable: true,
        sortable: true,
        headerName: "Model",
        field: "model",
        filter: "agTextColumnFilter",
        width: 100,
      },
      {
        resizable: true,
        sortable: true,
        headerName: "Serial #",
        field: "serial_no",
        filter: "agTextColumnFilter",
      },
    ],
  };
  return (
    <div className="modal-content ">
      <div className="modal-header">
        <h5 className="modal-title">{`${transfer.from_org} -> ${transfer.to_org} Equipment Status`}</h5>
        <button
          className="close"
          type="button"
          aria-label="Close"
          onClick={doDialogClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body" style={{ overflow: "visible" }}>
        <Grid
          config={config}
          data={transfer.equipment_status}
          style={{ height: `${300}px`, width: "100%" }}
        />
      </div>

      <div className="modal-footer">
        <button
          onClick={doDialogClose}
          className="btn btn-sm btn-secondary"
          type="button"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default connect("doDialogClose", EquipmentModal);
