import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { connect } from "redux-bundler-react";

const AssociatedItemModal = ({
  doDialogClose,
  items,
  data,
  onItemClicked,
  type,
  displayOnly,
  doAircraftConfigurationDelete,
}) => {
  const [values, setValues] = useState([]);
  const selectStyle = {
    multiValueRemove: (style, state) => {
      return {
        ...style,
        ...{
          visibility: "hidden",
          width: "4px",
        },
      };
    },
  };

  let options = items
    .map((i) => ({
      ...i,
      value:
        type === "GCS"
          ? i.gcs_id
          : type === "Aircraft"
          ? i.platform_id
          : i.payload_id,
      label: `${i.make} ${i.model}`,
    }))
    .filter((x) => !x.selected);

  useEffect(() => {
    let selectedValues = items
      .filter((i) => (type !== "Aircraft" ? i.selected : true))
      .map((x) => ({
        value: x.id,
        label: `${x.make} ${x.model}`,
      }));
    setValues(selectedValues);
  }, [items]);

  const handleSelect = (values, option) => {
    setValues(values);
    if (option.action === "remove-value") {
      //delete from config
      doAircraftConfigurationDelete({ id: option.removedValue.value });
    } else if (option.action === "select-option") {
      let item = option.option;
      onItemClicked(item, data);
    }
  };
  return (
    <div className="modal-content ">
      <div className="modal-header">
        <h5 className="modal-title">{`Platforms ${type} Associations`}</h5>
        <button
          className="close"
          type="button"
          aria-label="Close"
          onClick={doDialogClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body" style={{ overflow: "visible" }}>
        <div className="d-flex flex-column w-100">
          <label className="mb-1 w-100"> Associated {type}</label>
          <ReactSelect
            styles={displayOnly && selectStyle}
            isMulti
            options={displayOnly ? [] : options}
            value={values}
            onChange={handleSelect}
            isClearable={false}
          />
        </div>
      </div>

      <div className="modal-footer">
        <button
          onClick={doDialogClose}
          className="btn btn-sm btn-secondary"
          type="button"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default connect(
  "doDialogClose",
  "doAircraftConfigurationDelete",
  AssociatedItemModal
);
