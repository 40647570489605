import React from "react";

class SoftwarePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      version: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
  }

  handleChange(e) {
    const key = e.currentTarget.dataset.key;
    const val = e.currentTarget.value;
    this.setState({
      [key]: val
    });
  }

  handleAddClick() {
    const { title, version } = this.state;
    const { onAdd } = this.props;
    if (onAdd) {
      this.setState(
        {
          title: "",
          version: ""
        },
        () => {
          onAdd({ title: title, version: version });
        }
      );
    }
  }

  render() {
    const { title, version } = this.state;
    return (
      <div className="row mt-2">
        <div className="col">
          <input
            className="form-control form-control-sm"
            type="text"
            value={title}
            data-key="title"
            onChange={this.handleChange}
          />
          <span className="help-block">
            <small>Title</small>
          </span>
        </div>
        <div className="col">
          <input
            className="form-control form-control-sm"
            type="text"
            value={version}
            data-key="version"
            onChange={this.handleChange}
          />
          <span className="help-block">
            <small>Version</small>
          </span>
        </div>
        <div className="col-3">
          <div className="float-right">
            <button
              disabled={title === "" || version === ""}
              onClick={this.handleAddClick}
              className="btn btn-sm btn-primary"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SoftwarePicker;
