import React from "react";
import { connect } from "redux-bundler-react";
import SearchInput from "../_shared/search-input/search-input";
import subDays from "date-fns/subDays";
import RoleFilter from "../../app-containers/context-providers/role-filter";
import Loader from "../../app-components/loader";
import TransfersGrid from "./transfers-grid";

class Transfers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      showOld: false,
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSearch(e) {
    this.setState({
      searchTerm: e.target.value,
    });
  }

  handleSelect(slug) {
    const { doUpdateRelativeUrl, orgsByRoute } = this.props;
    doUpdateRelativeUrl(`/${orgsByRoute.slug}/transfers/${slug}`);
  }

  render() {
    const {
      transfersItems: items,
      orgsByRoute,
      transfersIsSaving,
      doTransfersDownloadAsCsv,
    } = this.props;

    if (transfersIsSaving) {
      return (
        <div className="container-fluid mt-4">
          <Loader opt="dissolve-cube" />
        </div>
      );
    }

    const { searchTerm, showOld } = this.state;
    const matcher = new RegExp(searchTerm, "ig");
    const filtered = items.filter((item) => {
      const testString = Object.values(item).join(" ");
      let pass = testString.match(matcher);
      if (!showOld && item.review_date) {
        pass = subDays(new Date(), 30) < new Date(item.review_date);
      }
      return pass;
    });

    // sort in-progress, then pending, then complete, then by ship_date
    const sortOrder = {
      Pending: 0,
      "In-Progress": 1,
      Complete: 2,
    };
    const sorted = filtered.sort((a, b) => {
      if (sortOrder[a.to_status] === 2 && sortOrder[a.hq_status] === 0) {
        return -1;
      }
      if (sortOrder[a.to_status] > sortOrder[b.to_status]) return 1;
      if (sortOrder[a.to_status] < sortOrder[b.to_status]) return -1;
      if (a.ship_date > b.ship_date) return 1;
      if (a.ship_date < b.ship_date) return -1;
      return 0;
    });

    return (
      <div className="container-fluid mt-4">
        <SearchInput
          onChange={this.handleSearch}
          value={this.state.searchTerm}
        />
        <div className="card">
          <div className="card-header">
            <div className="float-right">
              <button
                onClick={doTransfersDownloadAsCsv}
                className="btn btn-sm btn-ghost-primary"
                disabled={!items.length}
              >
                <i className="mdi mdi-download icon-inline"></i>CSV
              </button>
            </div>
            <i className="mdi mdi-swap-horizontal"></i> Property Transfers
          </div>
          <div className="card-body">
            <div className="float-right mb-1">
              <RoleFilter allowRoles={[":ORG.ADMIN"]}>
                <a
                  className="btn btn-outline-primary"
                  href={`/${orgsByRoute.slug}/transfers/new`}
                >
                  Create Transfer
                </a>
              </RoleFilter>
            </div>
            <div style={{ overflowX: "scroll", width: "100%" }}>
              <TransfersGrid data={sorted} />
            </div>
            <div>
              {!showOld ? (
                <p>
                  Showing transfers with a review date open or within the past
                  30 days, click{" "}
                  <span
                    className="link"
                    onClick={() => {
                      this.setState({ showOld: true });
                    }}
                  >
                    here
                  </span>{" "}
                  to show all
                </p>
              ) : (
                <p>Showing all transfers.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "selectTransfersItems",
  "selectOrgsByRoute",
  "selectTransfersIsSaving",
  "doTransfersDownloadAsCsv",
  Transfers
);
