import React, { memo, useState } from "react";
import FileUploadButton from "./adjudication-file-upload";

const AdjudicationNotesForm = memo(function AdjudicationNotesForm({
  alert,
  onSave,
  doFetch,
  isFetching,
  doFileIoUpload,
}) {
  const [note, setNote] = useState("");
  const [file, setFile] = useState(null);

  const _onSave = () => {
    let object = {
      mission_id: alert.mission_id,
      variable_id: alert.variable_id,
      note,
    };
    onSave(
      object,
      (updatedItem) => {
        if (!file) doFetch();
        setNote("");
        if (file) {
          doFileIoUpload(
            null,
            file,
            "adjudication_docs",
            {
              adjudication_id: updatedItem.id,
            },
            () => {
              setFile(null);
              doFetch();
            }
          );
        }
      },
      true
    );
  };
  return (
    <div>
      <h6 className="alert-subheading">New Adjudication</h6>
      <div className="d-flex flex-column w-100 bg-white mb-2">
        <textarea
          className="form-control mb-2 px-2"
          rows="2"
          placeholder="If this is intended, explain here why this is necessary."
          onChange={(e) => setNote(e.target.value)}
          value={note}
          style={{
            borderRadius: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderTopWidth: 0,
          }}
        />
        {!file && (
          <p className="text-muted mx-2">
            <small>
              {" "}
              It is expected that the ATPM will give you written approval for
              this action. This is your accountability for this change. Please
              attach that to each adjudication on this flight.
            </small>
          </p>
        )}
        {file && (
          <div className="d-flex p-1 align-items-center mr-2">
            <i className="mdi mdi-file mdi-18px text-info" />
            <p className="my-0 ml-1 text-muted">
              <small>{file.name}</small>
            </p>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end w-100 pr-2 pb-2">
        <FileUploadButton onFileChange={setFile} file={file} />
        <button
          onClick={_onSave}
          disabled={isFetching}
          className={`btn btn-sm btn-success ml-1`}>
          {isFetching ? (
            <i className="mdi mdi-loading mdi-spin mdi-14px" />
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
});

export default AdjudicationNotesForm;
