import React from "react";
import { connect } from "redux-bundler-react";

export default connect(({ onRemove, item, editing }) => {
  const renderDeleteButton = () => {
    if (!editing) return null;
    return (
      <div className="float-right">
        <button
          className="btn btn-sm btn-ghost-danger"
          onClick={() => {
            onRemove(item);
          }}
        >
          <i className="mdi mdi-delete"></i>
        </button>
      </div>
    );
  };
  return (
    <li className="list-group-item">
      {renderDeleteButton()}
      {`${item.make} ${item.model}`}
    </li>
  );
});
