import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
    name: "mmsMissions",
    uid: "id",
    prefetch: true,
    routeParam: null,
    getTemplate: "/orgs/:orgSlug/mms/:mmsSlug/missions",
    fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN"]
});
