import { createSelector } from "redux-bundler";
import { groupBy } from "lodash";

export default {
  name: "missionsConfigurations",

  getReducer() {
    const initialData = {
      data: [],
      isFetching: false,
      isPosting: false,
      shouldFetch: false,
      config: null,
      tableData: [],
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "MISSIONS_CONFIGURATIONS_FETCH_STARTED":
        case "MISSIONS_CONFIGURATIONS_FETCH_ERROR":
        case "MISSIONS_CONFIGURATIONS_FETCH_FINISHED":
        case "MISSIONS_CONFIGURATIONS_SAVE_STARTED":
        case "MISSIONS_CONFIGURATIONS_SAVE_FINISHED":
        case "MISSIONS_CONFIGURATIONS_SAVE_ERROR":
        case "MISSIONS_CONFIGURATIONS_DELETE_STARTED":
        case "MISSIONS_CONFIGURATIONS_DELETE_FINISHED":
        case "MISSIONS_CONFIGURATIONS_DELETE_ERROR":
        case "SET_ACTIVE_MISSION_CONFIG":
          return Object.assign({}, state, payload);
        case "CLEAR_ACTIVE_MISSION_CONFIG":
          return { ...state, config: null };
        case "AUTH_LOGGED_IN":
          return { ...state, shouldFetch: true, shouldFetchAll: true };
        default:
          return state;
      }
    };
  },
  doMissionsConfigurationsSave:
    (state, callback) =>
      ({ dispatch, store, apiPost, apiPut }) => {
        let { selectApiRoot, selectOrgsByRoute, selectMissionsByRoute } = store;
        let apiRoot = selectApiRoot();
        let orgSlug = selectOrgsByRoute().slug;
        let missionsSlug = selectMissionsByRoute().slug;
        dispatch({
          type: "MISSIONS_CONFIGURATIONS_SAVE_STARTED",
          payload: { isPosting: true },
        });
        if (state.configuration_id) {
          apiPut(
            `${apiRoot}/orgs/${orgSlug}/missions/${missionsSlug}/configurations/${state.configuration_id}`,
            state,
            (err, res, body) => {
              if (err) {
                dispatch({
                  type: "MISSIONS_CONFIGURATIONS_SAVE_ERROR",
                  payload: { isPosting: false, err },
                });
              } else {
                dispatch({
                  type: "MISSIONS_CONFIGURATIONS_SAVE_FINISHED",
                  payload: { isPosting: false },
                });
                callback && callback();
                store.doDialogClose();
              }
            }
          );
        } else {
          apiPost(
            `${apiRoot}/orgs/${orgSlug}/missions/${missionsSlug}/configurations`,
            state,
            (err, res, body) => {
              if (err) {
                dispatch({
                  type: "MISSIONS_CONFIGURATIONS_SAVE_ERROR",
                  payload: { isPosting: false, err },
                });
              } else {
                dispatch({
                  type: "MISSIONS_CONFIGURATIONS_SAVE_FINISHED",
                  payload: { isPosting: false },
                });
                callback && callback();
                store.doDialogClose();
              }
            }
          );
        }
      },
  doMissionsConfigurationsDelete:
    (id, callback) =>
      ({ dispatch, store, apiDelete }) => {
        let { selectApiRoot, selectOrgsByRoute, selectMissionsByRoute } = store;
        let apiRoot = selectApiRoot();
        let orgSlug = selectOrgsByRoute().slug;
        let missionsSlug = selectMissionsByRoute().slug;
        dispatch({
          type: "MISSIONS_CONFIGURATIONS_DELETE_STARTED",
          payload: { isDeleting: true },
        });
        apiDelete(
          `${apiRoot}/orgs/${orgSlug}/missions/${missionsSlug}/configurations/${id}`,
          {},
          (err, res, body) => {
            if (err) {
              dispatch({
                type: "MISSIONS_CONFIGURATIONS_DELETE_ERROR",
                payload: { isDeleting: false, err },
              });
            } else {
              dispatch({
                type: "MISSIONS_CONFIGURATIONS_DELETE_FINISHED",
                payload: { isDeleting: false },
              });
              callback && callback();
              store.doDialogClose();
            }
          }
        );
      },

  doMissionsConfigurationsFetchTable:
    (callback) =>
      ({ store, dispatch, apiGet }) => {
        let { selectApiRoot, selectOrgsByRoute, selectMissionsByRoute } = store;
        let apiRoot = selectApiRoot();
        let orgSlug = selectOrgsByRoute().slug;
        let missionsSlug = selectMissionsByRoute().slug;
        dispatch({
          type: "MISSIONS_CONFIGURATIONS_FETCH_STARTED",
          payload: { isFetching: true, shouldFetch: false },
        });
        apiGet(
          `${apiRoot}/orgs/${orgSlug}/missions/${missionsSlug}/configurationsTable`,
          (err, response, body) => {
            if (err) {
              dispatch({
                type: "MISSIONS_CONFIGURATIONS_FETCH_ERROR",
                payload: { isFetching: false, err },
              });
            } else {
              const data = JSON.parse(body);
              dispatch({
                type: "MISSIONS_CONFIGURATIONS_FETCH_FINISHED",
                payload: { tableData: data, isFetching: false },
              });
              callback && typeof callback === "function" && callback();
            }
          }
        );
      },

  doMissionsConfigurationsFetch:
    (callback) =>
      ({ dispatch, store, apiGet }) => {
        let { selectApiRoot, selectOrgsByRoute, selectMissionsByRoute } = store;
        let apiRoot = selectApiRoot();
        let orgSlug = selectOrgsByRoute().slug;
        let missionsSlug = selectMissionsByRoute().slug;
        dispatch({
          type: "MISSIONS_CONFIGURATIONS_FETCH_STARTED",
          payload: { isFetching: true, shouldFetch: false },
        });
        apiGet(
          `${apiRoot}/orgs/${orgSlug}/missions/${missionsSlug}/configurations`,
          (err, response, body) => {
            if (err) {
              dispatch({
                type: "MISSIONS_CONFIGURATIONS_FETCH_ERROR",
                payload: { isFetching: false, err },
              });
            } else {
              const data = JSON.parse(body);
              dispatch({
                type: "MISSIONS_CONFIGURATIONS_FETCH_FINISHED",
                payload: { data: data, isFetching: false },
              });
              callback && typeof callback === "function" && callback();
            }
          }
        );
      },

  doMissionsConfigurationsSetActive:
    (config, cb) =>
      ({ dispatch }) => {
        dispatch({
          type: "SET_ACTIVE_MISSION_CONFIG",
          payload: {
            config: {
              ...config,
              configurationId: config["Aircraft"][0].configuration_id,
            },
          },
        });
        cb && cb();
      },

  doMissionsConfigurationsClearActive:
    () =>
      ({ dispatch }) => {
        dispatch({
          type: "CLEAR_ACTIVE_MISSION_CONFIG",
        });
      },

  selectMissionsConfigurationsActive: (state) =>
    state.missionsConfigurations.config,

  selectMissionsConfigurationsItems: (state) =>
    state.missionsConfigurations.data,
  selectMissionsConfigurationsTableData: (state) =>
    state.missionsConfigurations.tableData,
  selectMissionsConfigurationIsFetching: (state) =>
    state.missionsConfigurations.isFetching,
  selectMissionsConfigurationIsPosting: (state) =>
    state.missionsConfigurations.isPosting,
  selectMissionsConfigurationIsDeleting: (state) =>
    state.missionsConfigurations.isDeleting,

  selectMissionInventoryByConfiguration: createSelector(
    "selectMissionsConfigurationsItems",
    (items) => {
      let itemsByConfig = groupBy(items, "configuration_id");
      return Object.keys(itemsByConfig).map((configId) => {
        return { [configId]: groupBy(itemsByConfig[configId], "type") };
      });
    }
  ),

  selectMissionsConfigurationsActiveRow: createSelector(
    "selectMissionsConfigurationsActive",
    "selectMissionsConfigurationsTableData",
    (missionsConfigurationsActive, missionsConfigurationsTableData) => {
      if (
        missionsConfigurationsActive &&
        missionsConfigurationsActive.configurationId
      ) {
        return missionsConfigurationsTableData.filter(
          (item) => item.id === missionsConfigurationsActive.configurationId
        )[0];
      } else return null;
    }
  ),
};
