import React from "react";
import { connect } from "redux-bundler-react";
import Body from "./body/body";
import Sidebar from "./sidebar/sidebar";

class Main extends React.Component {
  render() {
    return (
      <div className="app-body">
        <Sidebar />
        <Body />
      </div>
    );
  }
}

export default connect(Main);
