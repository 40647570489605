import React from "react";
import { connect } from "redux-bundler-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FlightPersonnel from "./flight-personnel/flight-personnel-form-group";
import FlightAircraft from "./flight-aircraft/flight-aircraft-form-group";
import FlightGcs from "./flight-gcs/flight-gcs-form-group";
import FlightMms from "./flight-mms/flight-mms-form-group";
import FlightBatteries from "./flight-batteries/flight-batteries-form-group";
import FlightPayloads from "./flight-payloads/flight-payloads-form-group";
import MissionStatusFilter from "../../_shared/mission-status-filter";
import ConfigurationTile from "../inventory-tab/configuration-tile";
import { findIndex } from "lodash";

class FlightsNew extends React.Component {
  constructor(props) {
    super(props);

    const {
      missionsDailyRawItemsObject,
      missionsFlightsItemsObject,
      missionsFlightsAircraftItems,
      missionsFlightsGcsItems,
      missionsFlightsMmsItems,
      missionsFlightsBatteriesItems,
      missionsFlightsPayloadsItems,
      missionsFlightsPersonnelItems,
      rawId,
      flightId,
      missionsConfigurationsActive,
    } = props;
    // need to update all missionsAircraft
    const raw = missionsDailyRawItemsObject[rawId];

    let item = {};
    if (flightId) item = missionsFlightsItemsObject[flightId];
    // neeed to add here any items existing in preselected config
    if (missionsConfigurationsActive) {
      this.state = {
        _selectedConfigId: missionsConfigurationsActive.configurationId,
      };
    }
    this.state = {
      ...this.state,
      _editable: !(item && item.hasOwnProperty("id")),
      id: item && item.hasOwnProperty("id") ? item.id : null,
      daily_raw_id: raw.id,
      name: item && item.hasOwnProperty("name") && item.name ? item.name : "",
      aircraft:
        item && item.hasOwnProperty("id") ? missionsFlightsAircraftItems : [],
      gcs: item && item.hasOwnProperty("id") ? missionsFlightsGcsItems : [],
      mms: item && item.hasOwnProperty("id") ? missionsFlightsMmsItems : [],
      personnel:
        item && item.hasOwnProperty("id") ? missionsFlightsPersonnelItems : [],
      payloads:
        item && item.hasOwnProperty("id") ? missionsFlightsPayloadsItems : [],
      batteries:
        item && item.hasOwnProperty("id") ? missionsFlightsBatteriesItems : [],
      start_time:
        item && item.hasOwnProperty("start_time") ? item.start_time : raw.date,
      end_time:
        item && item.hasOwnProperty("end_time") ? item.end_time : raw.date,
      notes:
        item && item.hasOwnProperty("notes") && item.notes ? item.notes : "",
    };
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.goBack = this.goBack.bind(this);
    this.delete = this.delete.bind(this);
    this.enableEditing = this.enableEditing.bind(this);
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
    this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
    this.handlePersonnelChange = this.handlePersonnelChange.bind(this);
    this.handleAircraftChange = this.handleAircraftChange.bind(this);
    this.handleGcsChange = this.handleGcsChange.bind(this);
    this.handleMmsChange = this.handleMmsChange.bind(this);
    this.handleBatteriesChange = this.handleBatteriesChange.bind(this);
    this.handlePayloadsChange = this.handlePayloadsChange.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.renderDelete = this.renderDelete.bind(this);
    this.renderEditButton = this.renderEditButton.bind(this);
    this.renderConfigurationCards = this.renderConfigurationCards.bind(this);
  }

  componentDidMount() {
    const { doMissionsAlertFetch } = this.props;
    doMissionsAlertFetch();
  }

  save() {
    const { doMissionsFlightsSave, doUpdateInnerRoute } = this.props;
    const flight = {};
    Object.keys(this.state).forEach((key) => {
      if (key[0] !== "_") flight[key] = this.state[key];
    });
    doMissionsFlightsSave(
      { ...flight, configuration_id: this.state._selectedConfigId },
      () => {
        doUpdateInnerRoute("/");
      },
      true
    );
  }

  delete() {
    const { doMissionsFlightsDelete, doUpdateInnerRoute } = this.props;
    const flight = {};
    Object.keys(this.state).forEach((key) => {
      if (key[0] !== "_") flight[key] = this.state[key];
    });
    doMissionsFlightsDelete(flight, () => {
      doUpdateInnerRoute("/");
    });
  }

  cancel() {
    const { id } = this.state;
    if (!id) return this.goBack();
    this.setState({ _editable: false });
  }

  goBack() {
    const { doUpdateInnerRoute } = this.props;
    doUpdateInnerRoute("/");
  }

  enableEditing() {
    this.setState({ _editable: true });
  }

  handleStartTimeChange(d) {
    this.setState({
      start_time: d,
    });
  }

  handleEndTimeChange(d) {
    this.setState({
      end_time: d,
    });
  }

  handlePersonnelChange(personnel) {
    this.setState({
      personnel: personnel,
    });
  }

  handleAircraftChange(aircraft) {
    this.setState({
      aircraft: aircraft,
    });
  }

  handleGcsChange(gcs) {
    this.setState({
      gcs: gcs,
    });
  }
  handleMmsChange(mms) {
    this.setState({
      mms: mms,
    });
  }

  handleBatteriesChange(batteries) {
    this.setState({
      batteries: batteries,
    });
  }

  handlePayloadsChange(payloads) {
    this.setState({
      payloads: payloads,
    });
  }

  handleNotesChange(e) {
    this.setState({
      notes: e.target.value,
    });
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value,
    });
  }

  renderDelete() {
    const { id } = this.state;
    if (!id) return null;
    return (
      <button onClick={this.delete} className="btn btn-sm btn-danger">
        Delete
      </button>
    );
  }

  renderActions() {
    const { _editable } = this.state;
    const {
      missionsAlertsByTab,
      missionsAdjudicationsByTab,
      missionsAlertIsLoading,
      missionsFlightsIsSaving,
    } = this.props;
    let missionsFlightAlertItems = missionsAlertsByTab.flights
      ? missionsAlertsByTab.flights
      : [];
    let missionsFlightAdjudicationsItems = missionsAdjudicationsByTab.flights
      ? missionsAdjudicationsByTab.flights
      : [];
    let adjudicated = [];
    missionsFlightAlertItems.forEach((item) => {
      missionsFlightAdjudicationsItems.forEach((alert) => {
        if (
          alert.variable_id === item.variable_id &&
          !adjudicated.includes(item.variable_id)
        )
          adjudicated.push(item.variable_id);
      });
    });
    let disabled =
      missionsAlertIsLoading ||
      adjudicated.length < missionsFlightAlertItems.length;
    if (_editable) {
      return (
        <div>
          <div className="float-right">
            <div className="d-flex align-items-center">
              {disabled && (
                <p className="text-danger m-0 mr-2">
                  <b>All alerts must be adjudicated to save this flight.</b>
                </p>
              )}
              <button
                onClick={this.cancel}
                className="btn btn-sm btn-secondary mr-2"
              >
                Cancel
              </button>
              <button
                onClick={this.save}
                className={`btn btn-sm btn-${
                  disabled ? "secondary" : "success"
                }`}
                disabled={disabled}
              >
                {missionsFlightsIsSaving ? (
                  <i className="mdi mdi-loading mdi-spin mdi-14px" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
          {this.renderDelete()}
        </div>
      );
    } else {
      return (
        <div className="float-right">
          <button onClick={this.goBack} className="btn btn-sm btn-secondary">
            Back
          </button>
        </div>
      );
    }
  }

  renderConfigurationCards() {
    const {
      missionInventoryByConfiguration,
      doMissionsConfigurationsSetActive,
    } = this.props;
    if (!missionInventoryByConfiguration) return null;
    return (
      <div className="d-flex justify-content-center">
        {missionInventoryByConfiguration.map((invConfig, key) => {
          return (
            <div
              onClick={
                this.state._editable
                  ? () =>
                      doMissionsConfigurationsSetActive(
                        Object.values(invConfig)[0],
                        () =>
                          this.setState({
                            _selectedConfigId: Object.keys(invConfig)[0],
                            aircraft: [],
                            gcs: [],
                            mms: [],
                            personnel: [],
                            payloads: [],
                            batteries: [],
                          })
                      )
                  : null
              }
              className={`p-1 selectable-config ${
                this.state._selectedConfigId === Object.keys(invConfig)[0]
                  ? !this.state._editable
                    ? "selected disabled"
                    : "selected"
                  : !this.state._editable
                  ? "disabled"
                  : ""
              }`}
              key={key}
            >
              <ConfigurationTile
                configuration={Object.values(invConfig)[0]}
                configurationId={Object.keys(invConfig)[0]}
                key={key}
              />
            </div>
          );
        })}{" "}
      </div>
    );
  }

  renderEditButton() {
    const { id, _editable } = this.state;
    if (!id) return null;
    return (
      <>
        <div className="clearfix">
          <div className="float-right">
            <MissionStatusFilter
              denyStatus={{
                mission_status: ["complete", "archived"],
              }}
            >
              <button
                disabled={_editable}
                className="btn btn-sm btn-ghost-primary"
                onClick={this.enableEditing}
              >
                <i className="mdi mdi-pencil icon-inline"></i>
                Edit
              </button>
            </MissionStatusFilter>
          </div>
        </div>
        <hr />
      </>
    );
  }

  render() {
    const {
      _editable,
      name,
      daily_raw_id,
      aircraft,
      gcs,
      mms,
      personnel,
      payloads,
      batteries,
      start_time,
      end_time,
      notes,
    } = this.state;
    const start = start_time ? new Date(start_time) : null;
    const end = end_time ? new Date(end_time) : null;
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setSeconds(0);
    end.setMilliseconds(0);
    const diff =
      start && end
        ? `${Math.round((end.getTime() - start.getTime()) / 1000 / 60)} Minutes`
        : "";
    const { missionsDailyRawItemsObject } = this.props;

    const raw = missionsDailyRawItemsObject[daily_raw_id];
    return (
      <div className="row justify-content-center">
        <div className="col-lg-12">
          {this.renderEditButton()}
          {this.renderConfigurationCards()}
          <div>
            <div className="form-horizontal">
              <div className="form-group row">
                <label className="col-md-3 col-form-label text-right">
                  Flight Name
                </label>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    name="name"
                    value={name}
                    onChange={this.handleNameChange}
                    type="text"
                    disabled={!_editable}
                  />
                </div>
              </div>

              <FlightPersonnel
                onChange={this.handlePersonnelChange}
                personnel={personnel}
                disabled={!_editable}
              />

              <FlightAircraft
                onChange={this.handleAircraftChange}
                aircraft={aircraft}
                disabled={!_editable}
              />

              <FlightBatteries
                onChange={this.handleBatteriesChange}
                batteries={batteries}
                disabled={!_editable}
              />

              <FlightPayloads
                onChange={this.handlePayloadsChange}
                payloads={payloads}
                disabled={!_editable}
              />

              <FlightGcs
                onChange={this.handleGcsChange}
                gcs={gcs}
                disabled={!_editable}
              />
              <FlightMms
                onChange={this.handleMmsChange}
                mms={mms}
                disabled={!_editable}
              />

              <div className="form-group row">
                <label className="col-md-3 col-form-label text-right">
                  Start Time
                </label>
                <div className="col-md-9">
                  <DatePicker
                    className="form-control"
                    data-key="start_time"
                    onChange={this.handleStartTimeChange}
                    selected={start}
                    showTimeSelect
                    timeFormat="HH:mm"
                    dateFormat="MMMM d, yyyy HH:mm"
                    timeIntervals={1}
                    timeCaption="Time"
                    minDate={new Date(raw.date)}
                    maxDate={new Date(raw.date)}
                    disabled={!_editable}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-md-3 col-form-label text-right">
                  End Time
                </label>
                <div className="col-md-9">
                  <DatePicker
                    className="form-control"
                    data-key="end_time"
                    onChange={this.handleEndTimeChange}
                    selected={end}
                    showTimeSelect
                    timeFormat="HH:mm"
                    dateFormat="MMMM d, yyyy HH:mm"
                    timeIntervals={1}
                    timeCaption="Time"
                    minDate={new Date(raw.date)}
                    maxDate={new Date(new Date(raw.date).getTime() + 86400000)}
                    disabled={!_editable}
                  />
                  <span className="ml-2">{`Flight time: ${diff}`}</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-md-3 col-form-label text-right">
                  Notes
                </label>
                <div className="col-md-9">
                  <textarea
                    className="form-control"
                    name="notes"
                    value={notes}
                    onChange={this.handleNotesChange}
                    type="textarea"
                    rows="4"
                    disabled={!_editable}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-9">{this.renderActions()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "selectMissionsDailyRawItemsObject",
  "selectMissionsFlightsItemsObject",
  "selectMissionsFlightsPersonnelItems",
  "selectMissionsFlightsAircraftItems",
  "selectMissionsFlightsGcsItems",
  "selectMissionsFlightsMmsItems",
  "selectMissionsFlightsBatteriesItems",
  "selectMissionsFlightsPayloadsItems",
  "doMissionsFlightsSave",
  "selectMissionsFlightsIsSaving",
  "doMissionsFlightsDelete",
  "selectMissionsAlertsByTab",
  "selectMissionsAdjudicationsByTab",
  "doMissionsAlertFetch",
  "selectMissionsAlertIsLoading",
  "selectMissionsConfigurationsActive",
  "selectMissionInventoryByConfiguration",
  "selectMissionsConfigurationsItems",
  "doMissionsConfigurationsSetActive",
  FlightsNew
);
