import { forwardRef, useState } from "react";

const ImageUploader = forwardRef(({ onUpload, displayOnly, src }, ref) => {
  const [image, setImage] = useState(
    src ? `data:image/jpeg;base64,${src}` : null
  );

  return (
    <div style={{ position: "relative" }}>
      <input
        ref={ref}
        style={{
          position: "absolute",
          opacity: 0,
          height: 100,
          width: "100%",
          cursor: !displayOnly ? "pointer" : "default",
        }}
        type="file"
        onChange={(e) => {
          onUpload({
            property: "image",
            value: e.target.files[0],
          });
          setImage(URL.createObjectURL(e.target.files[0]));
        }}
        disabled={displayOnly}
      />
      {image ? (
        <div className="d-flex flex-column align-items-center">
          <img
            src={image ? image : null}
            style={{ height: 100, width: "auto" }}
          />
          {!displayOnly && (
            <p>
              <i>Click to Edit Image</i>
            </p>
          )}
        </div>
      ) : (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            height: 100,
            border: !image && "1px dashed gray",
            borderRadius: 16,
          }}
        >
          <i className="mdi mdi-image mdi-48px text-secondary" />
          {!displayOnly && (
            <p>
              <i>Click to Upload Image</i>
            </p>
          )}
        </div>
      )}
    </div>
  );
});

export default ImageUploader;
