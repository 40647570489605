import React from "react";
import { connect } from "redux-bundler-react";
import Loader from "../../app-components/loader";
import EmptyBacklog from "./empty-backlog";
import BacklogMissionList from "./backlog-mission-list";

class Backlog extends React.Component {
  componentDidMount() {
    const { doBacklogFetch, backlogIsLoading } = this.props;
    if (!backlogIsLoading) doBacklogFetch();
  }
  renderContent = () => {
    const { backlogIsLoading, backlogItems } = this.props;
    if (backlogIsLoading) return <Loader />;
    if (!backlogItems || !backlogItems.length) return <EmptyBacklog />;
    return <BacklogMissionList items={backlogItems} />;
  };
  render() {
    return (
      <div className="container-fluid" style={{ marginTop: "80px" }}>
        <div className="card w-75 mx-auto">
          <div className="card-header">
            <i className="mdi mdi-format-list-checks mr-2"></i>My Backlog
          </div>
          <div className="card-body">
            <p>
              Your backlog is a list of missions that are waiting on review and
              approval. All missions shown are from organizations where you have
              been designated an MBO, ATPM, Commander or Director.
            </p>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doBacklogFetch",
  "selectBacklogIsLoading",
  "selectBacklogItems",
  Backlog
);
