import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";

export default createRestBundle({
  name: "equipmentRequestCounts",
  getTemplate: "/orgs/:orgSlug/equipment-requests/countsByOrg",
  fetchActions: ["AUTH_LOGGED_IN", "URL_UPDATED"],
  forceFetchActions: ["EQUIPMENTREQUEST_STATUS_CHANGING", "EQUIPMENTREQUEST_SAVE_FINISHED", "EQUIPMENTREQUEST_DELETE_FINISHED"],
  addons: {
    selectEquipmentRequestCountsTotals: createSelector(
      "selectEquipmentRequestCountsItems",
      (equipmentRequestCountsItems) => {
        let totalAdd = 0;
        let totalDeactivate = 0;
        equipmentRequestCountsItems.forEach(item => {
          if (item.add_count) totalAdd += parseInt(item.add_count);
          if (item.deactivate_count) totalDeactivate += parseInt(item.deactivate_count);
        })
        let totalNew = equipmentRequestCountsItems && equipmentRequestCountsItems.length > 0 ? equipmentRequestCountsItems[0].status_count : 0;
        return {
          _totalNew: totalNew,
          _totalAdd: totalAdd,
          _totalDeactivate: totalDeactivate
        }
      }
    )
  }
})