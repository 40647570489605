import { sortBy } from "lodash";
import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";

export default createRestBundle({
  name: "payloadsReport",
  uid: "id",
  routeParam: "",
  getTemplate: "/orgs/:orgSlug/reports/payloads?:queryString",
  prefetch: false,
  persist: false,
  fetchActions: [],
  forceFetchActions: [],
  initialData: {
    _queryString: {},
    _isLoading: false,
    _isStatsLoading: false,
    _lastStatsFetch: null,
    _stats: null,
    _lastStatsResource: null,
    _statsStaleAfter: 30000,
    _shouldFetchStats: true

  },


  reduceFurther: (state, { type, payload }) => {
    switch (type) {
      case "PAYLOADSREPORT_FETCH_STATS_STARTED":
      case "PAYLOADSREPORT_FETCH_STATS_ABORTED":
      case "PAYLOADSREPORT_FETCH_STATS_ERROR":
      case "PAYLOADSREPORT_UPDATE_QUERY_STRING":
        return Object.assign({}, state, payload);
      case "PAYLOADSREPORT_FETCH_STATS_FINISHED":
        return Object.assign({}, payload);
      case "URL_UPDATED":
        return Object.assign({}, state, { _shouldFetchStats: true });
      default:
        return state;
    }
  },
  urlParamSelectors: ["selectPayloadsReportFormattedQueryString"],
  addons: {
    doPayloadsReportDownloadAsCsv: () => ({ store }) => {
      const ignoreFields = ["id", "org_id", "parent_id", "payload_type_id", "deleted", "create_date", "last_updated_date", "slug"];
      const data = store.selectPayloadsReportItems();
      if (!data || !data.length) return null;
      const csvRows = [];
      const fixCommas = (value) => {
        if (value && typeof value === "string" && value.indexOf(",") !== -1) {
          return `"${value}"`;
        }
        return value;
      }
      const setHeaders = (item) => {
        let head = [];

        Object.keys(item).forEach(key => {
          if (ignoreFields.indexOf(key) === -1) {
            if (key === "deactivated") key = "status";
            head.push(fixCommas(key.toUpperCase()));
          }
        })
        csvRows.push(head.join(","));
      }
      data.forEach((item, i) => {
        if (i === 0) setHeaders(item);
        let row = [];
        Object.keys(item).forEach(key => {
          if (ignoreFields.indexOf(key) === -1) {
            row.push(fixCommas(item[key]));
          }
        });
        csvRows.push(row.join(","));
      });

      let csv = csvRows.join("\n");
      let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) navigator.msSaveBlob(blob, "export.csv");
      else {
        let link = document.createElement("a");
        if (link.download !== undefined) {
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "export.csv");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    selectPayloadsReportIsStatsStale: createSelector(
      "selectAppTime",
      "selectPayloadsReportLastStatsFetch",
      "selectPayloadsReportStaleAfter",
      (appTime, payloadsReportLastStatsFetch, payloadsReportStaleAfter) => {
        return appTime - new Date(payloadsReportLastStatsFetch) > payloadsReportStaleAfter;
      }
    ),
    selectPayloadsReportIsStatsLoading: state => {
      return state.payloadsReport._isStatsLoading;
    },
    selectPayloadsReportLastStatsResource: state => {
      return state.payloadsReport._lastStatsResource;
    },
    selectPayloadsReportStaleAfter: state => {
      return state.payloadsReport._statsStaleAfter;
    },
    selectPayloadsReportLastStatsFetch: state => {
      return state.payloadsReport._lastStatsFetch;
    },

    doPayloadsReportFetchStats: () => ({ dispatch, store, apiGet }) => {

      dispatch({
        type: "PAYLOADSREPORT_FETCH_STATS_STARTED",
        payload: {
          _shouldFetchStats: false,
          _isLoading: true,
          _isStatsLoading: true
        }
      });
      const aircraftReportFlags = store.selectPayloadsReportFlags();
      const statsIsStale = store.selectPayloadsReportIsStatsStale();
      const url = store.selectPayloadsReportFetchStatsUrl();
      const lastResource = store.selectPayloadsReportLastStatsResource();
      if (url.includes(":orgSlug") || (!statsIsStale && url === lastResource)) {
        dispatch({
          type: "PAYLOADSREPORT_FETCH_STATS_ABORTED",
          payload: { _isStatsLoading: false }
        });
      }
      else {
        apiGet(url, (err, res, body) => {
          if (err || res.statusCode !== 200) {
            dispatch({
              type: "PAYLOADSREPORT_FETCH_STATS_ERROR",
              payload: {
                _err: { err, res },
                _isStatsLoading: false,
                notification: {
                  statusCode: res.statusCode
                }
              }
            });
          }
          else {
            const result = JSON.parse(body);
            dispatch({
              type: "PAYLOADSREPORT_FETCH_STATS_FINISHED",
              payload: {
                ...aircraftReportFlags,
                _stats: result,
                _queryString: {},
                _isStatsLoading: false,
                _isLoading: false,
                _lastStatsFetch: new Date(),
                _lastStatsResource: url
              }
            });
          }
        })
      }
    },
    selectPayloadsReportStats: (state) => {
      return state.payloadsReport._stats;
    },
    selectPayloadsReportFetchStatsUrl: createSelector(
      "selectOrgsByRoute",
      (orgsByRoute) => {
        let pattern = "/orgs/:orgSlug/reports/payloads/stats";
        if (orgsByRoute) return pattern.replace(":orgSlug", orgsByRoute.slug);
        return pattern;
      }
    ),
    selectPayloadsReportFormattedQueryString: createSelector(
      "selectPayloadsReportQueryString",
      (queryString) => {
        return {
          queryString: Object.keys(queryString).map(k => {
            return `${k}=${encodeURIComponent(queryString[k])}`
          }).join("&")
        }
      }
    ),
    selectPayloadsReportStatsFormatted: createSelector(
      "selectPayloadsReportStats",
      "selectOrgsByRoute",
      (payloadsReportStats, orgsByRoute) => {
        if (!orgsByRoute) return null;
        if (payloadsReportStats && payloadsReportStats.orgs) {
          return {
            ...payloadsReportStats, orgs: payloadsReportStats.orgs.map(o => Object.assign({}, { id: o, val: o, checkedByDefault: o === orgsByRoute.name }))
          }
        }
        return [];
      }
    ),

    selectPayloadsReportParameters: createSelector(
      "selectPayloadsReportStatsFormatted",
      "selectDomainsItemsByGroup",
      "selectOrgsByRoute",
      (payloadsReportStatsFormatted, domainsItemsByGroup, orgsByRoute) => {
        if (!orgsByRoute || !payloadsReportStatsFormatted) return null;
        const { orgs } = payloadsReportStatsFormatted;
        return [
          {
            type: "checkbox-search",
            column: "org_name",
            title: "Organization",
            options: orgs
          },
          {
            type: "text-search",
            column: "name",
            title: "Name"
          },
          {
            type: "text-search",
            column: "make",
            title: "Make"
          },
          {
            type: "text-search",
            column: "model",
            title: "Model"
          },
          {
            type: "text-search",
            column: "serial_no",
            title: "Serial No."
          },
          {
            type: "checkbox-search",
            column: "deactivated",
            title: "Status",
            options: sortBy(domainsItemsByGroup["equipment_status"], ["display_order"]).map(t => {
              return { id: t.val, val: t.val }
            })
          },
          {
            type: "checkbox-search",
            column: "payload_type",
            title: "Payload Type",
            options: sortBy(domainsItemsByGroup["payload_type"], ["display_order"]).map(t => {
              return { id: t.val, val: t.val }
            })
          }
        ];
      }
    ),

    selectPayloadsReportQueryString: (state) => {
      return state.payloadsReport._queryString;
    },
    selectPayloadsReportDisableExport: createSelector(
      "selectPayloadsReportItems",
      (payloadsReportItems) => {
        return !payloadsReportItems.length;
      }
    ),
    doPayloadsReportUpdateQueryString: (update) => ({ dispatch, store }) => {
      const payloadsReportQueryString = store.selectPayloadsReportQueryString();
      dispatch({
        type: "PAYLOADSREPORT_UPDATE_QUERY_STRING",
        payload: {
          _queryString: {
            ...payloadsReportQueryString,
            ...update
          },
          _shouldFetch: true
        }
      });
    },
    reactPayloadsReportShouldFetchStats: state => {
      if (state.payloadsReport._shouldFetchStats) {
        return { actionCreator: "doPayloadsReportFetchStats" }
      }
    }
  }



});