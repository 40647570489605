import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Circle from "ol/style/Circle";
import Txt from "ol/style/Text";

window.olStyle = {
  Style: Style,
  Fill: Fill,
  Stroke: Stroke,
  Circle: Circle,
  Text: Txt
};

const defaultVectorStyle = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.2)"
  }),
  stroke: new Stroke({
    color: "rgba(0, 0, 0, 0.5)",
    lineDash: [10, 10],
    width: 2
  }),
  image: new Circle({
    radius: 3,
    stroke: new Stroke({
      color: "rgba(0, 0, 0, 0.7)"
    }),
    fill: new Fill({
      color: "rgba(255, 255, 255, 0.2)"
    })
  })
});

export default options => {
  if (options.styleFn) {
    return eval(options.styleFn); // eslint-disable-line no-eval
  }

  if (options.style) {
    return options.style;
  }

  if (options.styleAttributes) {
    return (feature, resolution) => {
      const fillColor = feature.get("fillColor");
      const strokeColor = feature.get("strokeColor");
      const strokeWidth = feature.get("strokeWidth");
      const strokeDash = feature.get("strokeDash");
      const markerRadius = feature.get("markerRadius");
      const markerColor = feature.get("markerColor");
      const markerStrokeColor = feature.get("markerStrokeColor");
      const markerStrokeWidth = feature.get("markerStrokeWidth");
      const markerFillColor = feature.get("markerFillColor");

      const style = new Style();
      if (fillColor) {
        style.setFill(
          new Fill({
            color: fillColor
          })
        );
      }
      if (strokeColor || strokeWidth || strokeDash) {
        style.setStroke(
          new Stroke({
            color: strokeColor || "rgba(0, 0, 0, 0.5)",
            lineDash: strokeDash ? strokeDash.split(",") : [10, 10],
            width: strokeWidth || 2
          })
        );
      }
      if (
        markerColor ||
        markerRadius ||
        markerStrokeColor ||
        markerStrokeWidth ||
        markerFillColor
      ) {
        style.setImage(
          new Circle({
            radius: markerRadius || 3,
            stroke: new Stroke({
              color: markerStrokeColor || "rgba(0, 0, 0, 0.7)",
              width: markerStrokeWidth || 0
            }),
            fill: new Fill({
              color: markerFillColor || "rgba(255, 255, 255, 0.2)"
            })
          })
        );
      }
      return style;
    };
  }

  return defaultVectorStyle;
};
