import React from "react";
import { connect } from "redux-bundler-react";
import classnames from "classnames";

class LayerToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.handleBasemapSelect = this.handleBasemapSelect.bind(this);
    this.renderBasemapToggles = this.renderBasemapToggles.bind(this);
    this.handleLayerSelect = this.handleLayerSelect.bind(this);
    this.renderLayerToggles = this.renderLayerToggles.bind(this);
  }

  toggleShow() {
    this.setState({
      show: !this.state.show,
    });
  }

  handleBasemapSelect(e) {
    const { doBasemapsChange } = this.props;
    const idx = e.currentTarget.dataset.idx;
    doBasemapsChange(Number(idx));
  }

  renderBasemapToggles() {
    const { basemaps, basemapsActiveIdx: idx } = this.props;
    return basemaps.map((basemap, i) => {
      return (
        <div key={i} className="dropdown-item dropdown-item-styled">
          <div className="form-check">
            <label className="form-check-label">
              <input
                onChange={this.handleBasemapSelect}
                name="basemap"
                data-idx={i}
                key={i}
                className="form-check-input mr-2"
                type="radio"
                checked={i === idx}
                value={basemap.name}
              />
              {basemap.name}
            </label>
          </div>
        </div>
      );
    });
  }

  handleLayerSelect(e) {
    const {
      airspaceActiveLayerNames: activeLayerNames,
      doAirspaceSetActiveLayers,
    } = this.props;
    const layerName = e.currentTarget.dataset.layername;
    const idx = activeLayerNames.indexOf(layerName);
    if (idx === -1) {
      return doAirspaceSetActiveLayers([...activeLayerNames, layerName]);
    } else {
      activeLayerNames.splice(idx, 1);
      return doAirspaceSetActiveLayers(activeLayerNames);
    }
  }

  renderLayerToggles() {
    const {
      airspaceLayerNames: layerNames,
      airspaceActiveLayerNames: activeLayerNames,
    } = this.props;
    return Object.keys(layerNames).map((layerName, i) => {
      return (
        <div key={i} className="dropdown-item dropdown-item-styled">
          <div className="form-check">
            <label className="form-check-label">
              <input
                onChange={this.handleLayerSelect}
                data-layername={layerName}
                checked={activeLayerNames.indexOf(layerName) !== -1}
                className="form-check-input mr-2"
                type="checkbox"
              />
              {layerNames[layerName]}
            </label>
          </div>
        </div>
      );
    });
  }

  render() {
    const { show } = this.state;
    const { maxHeight } = this.props;

    const btnGroupClass = classnames({
      "btn-group": true,
      show: show,
    });
    const dropdownMenuClass = classnames({
      "dropdown-menu": true,
      "dropdown-menu-right": true,
      show: show,
    });
    return (
      <div className={btnGroupClass}>
        <button
          onClick={this.toggleShow}
          className="btn btn-primary dropdown-toggle"
          type="button"
          data-toggle="dropdown"
        >
          Layers
        </button>
        <div
          onClick={this.toggleShow}
          style={{
            display: show ? "block" : "none",
            position: "fixed",
            zIndex: 999,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        ></div>
        <div
          className={dropdownMenuClass}
          x-placement="bottom-start"
          style={{
            position: "absolute",
            willChange: "transform",
            top: "0px",
            right: "0px",
            transform: "translate3d(0px, 26px, 0px)",
          }}
        >
          <div className="dropdown-header bg-light">
            <strong>Basemaps</strong>
          </div>

          {this.renderBasemapToggles()}

          <div className="dropdown-header bg-light">
            <strong>Layers</strong>
          </div>

          <div style={{ maxHeight: maxHeight, overflowY: "auto" }}>
            {this.renderLayerToggles()}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doBasemapsChange",
  "selectBasemapsActiveIdx",
  "selectBasemaps",
  "selectAirspaceLayerNames",
  "selectAirspaceActiveLayerNames",
  "doAirspaceSetActiveLayers",
  LayerToggle
);
