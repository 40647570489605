import React from "react";

const AwrDateCell = (props) => {
  const { data } = props;
  const { awr_expire_date } = data;

  let today = new Date();
  let future = new Date();
  future.setDate(future.getDate() + 30);

  if (!awr_expire_date) return <p className="m-0">{``}</p>;
  if (today > new Date(awr_expire_date)) {
    return (
      <p className="m-0 text-danger">
        Expired! {awr_expire_date.split("T")[0]}
      </p>
    );
  } else if (future > new Date(awr_expire_date)) {
    return (
      <p className="m-0 text-warning">
        Expires soon! {awr_expire_date.split("T")[0]}
      </p>
    );
  } else {
    return <p className="m-0">{awr_expire_date.split("T")[0]}</p>;
  }
};

export default AwrDateCell;
