import React from "react";
import classnames from "classnames";
class ActionsDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.toggleShow = this.toggleShow.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
  }

  toggleShow = () => {
    this.setState({ show: !this.state.show });
  };

  renderBtnGroup = (key, opt) => {
    const { state, dispatch } = this.props;

    let icon = (item) => {
      if (item.icon) return <i className={`${item.icon} mr-2`} />;
      return null;
    };
    const _onClick = (e) => {
      if (opt.exitOnClick) this.toggleShow();
      if (opt.dispatchType)
        dispatch({ type: opt.dispatchType, value: e.target.value });
    };

    return (
      <div key={key}>
        <h6 className="dropdown-header">{opt.text}</h6>
        <div className="btn-group mr-1 w-100 p-2" role="group">
          {opt.items.map((item, idx) => {
            return (
              <button
                key={idx}
                type="button"
                className={`btn btn-${
                  item.text === state[opt.value] ? "primary" : "outline-primary"
                }`}
                value={item.text}
                onClick={_onClick}
              >
                {icon(item)}
                {item.text}
              </button>
            );
          })}
        </div>
        <div className="dropdown-divider"></div>
      </div>
    );
  };

  renderOptions = () => {
    const { state, dispatch, options } = this.props;
    let icon = (opt) => {
      if (opt.icon) return <i className={`${opt.icon} mr-2`} />;
      return null;
    };
    const checkmark = (opt) => {
      if (opt.value) {
        if (opt.text === state[opt.value]) {
          return (
            <span
              className="d-block float-right mdi mdi-check"
              style={{ fontSize: "1.2rem" }}
            />
          );
        }
      }
      return null;
    };
    return options.map((opt, idx) => {
      if (opt.type == "select") return this.renderBtnGroup(idx, opt);
      return (
        <div
          key={idx}
          className="dropdown-item dropdown-item-styled"
          name={opt.name ? opt.name : ""}
          style={{ cursor: "pointer", userSelect: "none" }}
          onClick={(e) => {
            if (opt.dispatchType)
              dispatch({ type: opt.dispatchType, value: opt.text });
            opt.onClick(e);
            if (opt.exitOnClick) this.toggleShow();
          }}
        >
          {icon(opt)}
          {opt.text}
          {checkmark(opt)}
        </div>
      );
    });
  };

  render() {
    const {
      title,
      size = "btn-sm",
      bg = "btn-info",
      margin,
      disabled,
      stateValueAsTitle,
      state,
    } = this.props;
    const { show } = this.state;
    const btnSizeTransforms = {
      "btn-sm": "translate3d(0px, 36px, 0px)",
      "btn-lg": "translate3d(0px, 42px, 0px)",
    };
    const btnGroupClass = classnames({
      "btn-group": true,
      [margin]: true,
      show: show,
    });
    const btnClass = classnames({
      btn: true,
      "dropdown-toggle": true,
      [size]: true,
      [bg]: true,
    });
    const dropdownMenuClass = classnames({
      "dropdown-menu": true,
      "dropdown-menu-right": true,
      show: show,
    });

    const toggleBtnStyle = {
      display: show ? "block" : "none",
      position: "fixed",
      zIndex: 999,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };
    const dropdownStyle = {
      position: "absolute",
      willChange: "transform",
      top: "0px",
      left: "0px",
      transform: btnSizeTransforms[size],
      width: "11rem",
    };

    return (
      <div className={btnGroupClass}>
        <button
          className={btnClass}
          type="button"
          data-toggle="dropdown"
          onClick={this.toggleShow}
          disabled={disabled}
        >
          {stateValueAsTitle && state[stateValueAsTitle]
            ? state[stateValueAsTitle]
            : title}
        </button>
        <div onClick={this.toggleShow} style={toggleBtnStyle} />
        <div
          className={dropdownMenuClass}
          x-placement="bottom-start"
          style={dropdownStyle}
        >
          {this.renderOptions()}
        </div>
      </div>
    );
  }
}

export default ActionsDropdown;
