import React from "react";

class SidebarNavTitleDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded });
  }

  renderOptions() {
    const { expanded } = this.state;
    const { options } = this.props;
    if (!expanded) return null;
    return (
      <ul className="dropdown-menu show" style={{ position: "relative" }}>
        {options.map((opt, i) => {
          return (
            <li className="dropdown-item" key={i}>
              {opt}
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    const { title } = this.props;
    return (
      <li className="nav-title" onClick={this.toggleExpanded}>
        <span className="float-right">
          <i className="mdi mdi-chevron-down" />
        </span>
        {title}
        {this.renderOptions()}
      </li>
    );
  }
}

export default SidebarNavTitleDropdown;
