import { createSelector } from "redux-bundler";
import createRestBundle from "./create-rest-bundle";
import { find } from "lodash";

export default createRestBundle({
  name: "gcs",
  uid: "slug",
  prefetch: true,
  staleAfter: 30000,
  persist: false,
  routeParam: "gcsSlug",
  getTemplate: "/orgs/:orgSlug/gcs",
  putTemplate: "/orgs/:orgSlug/gcs/:item.slug",
  postTemplate: "/orgs/:orgSlug/gcs",
  deleteTemplate: "/orgs/:orgSlug/gcs/:item.slug",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN", "TRANSFERS_SAVE_FINISHED"],
  forceFetchActions: ["GCS_SAVE_FINISHED"],
  addons: {
    selectGcsItemsNotDeactivated: createSelector(
      "selectGcsItems",
      "selectEquipmentRequestDeactivationTypes",
      (gcsItems, deactivationTypes) => {
        return gcsItems.filter(g => !deactivationTypes.map(t => t.val).includes(g.deactivated))
      }
    ),
    selectGcsItemsNotInConfiguration: createSelector(
      "selectGcsItemsNotDeactivated",
      "selectMissionsConfigurationsActiveRow",
      "selectAircraftItems",
      "selectAircraftConfigurationItems",
      "selectMissionsAircraftItems",
      (
        gcsItemsNotDeactivated,
        missionsConfigurationsActiveRow,
        aircraftItems,
        aircraftConfigurationItems,
        missionsAircraftItems
      ) => {
        if (!missionsConfigurationsActiveRow && !missionsAircraftItems)
          return gcsItemsNotDeactivated;
        let availableGcs = [];
        let missionAircraft = aircraftItems.filter((aircraft) => {
          if (missionsConfigurationsActiveRow) {
            return missionsConfigurationsActiveRow.aircraft_ids
              .split(".")
              .includes(aircraft.id);
          } else if (
            missionsAircraftItems &&
            missionsAircraftItems.length > 0
          ) {
            return missionsAircraftItems[0].id;
          } else return [];
        });

        let availableConfigs = aircraftConfigurationItems.filter(
          (item) =>
            !!find(
              missionAircraft,
              (obj) => item.aircraft_id === obj.platform_id
            )
        );
        if (availableConfigs && availableConfigs.length > 0) {
          availableConfigs.forEach((item) => {
            if (item.gcs_id) {
              let obj = find(gcsItemsNotDeactivated, { parent_id: item.gcs_id });
              if (obj) {
                availableGcs.push(obj);
              }
            }
          });
        }
        if (
          missionsConfigurationsActiveRow &&
          missionsConfigurationsActiveRow.gcs_ids
        ) {
          return availableGcs.filter(
            (a) =>
              !missionsConfigurationsActiveRow.gcs_ids.split(".").includes(a.id)
          );
        } else return availableGcs;
      }
    ),
  },
});
