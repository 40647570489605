import React from "react";
import { createRoot } from "react-dom/client";
import getStore from "./app-bundles";
import cache from "./utils/cache";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./css/bootstrap/css/bootstrap.min.css";
import "./css/mapskin/css/mapskin.min.css";
import "./css/mdi/css/materialdesignicons.min.css";
import "./css/nasalization/nasalization.css";
import "./css/coreui/style.min.css";
import "./css/loader.css";
import "./css/index.css";

cache.getAll().then((initialData) => {
  const store = getStore(initialData);

  window.store = store;
  const container = document.getElementById("root");
  const root = createRoot(container); // createRoot(container!) if you use TypeScript

  root.render(<App store={store} />);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
