import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import ValuePicker from "../_shared/checkbox-search";
import TextSearch from "../_shared/text-search";
import DateRangeSearch from "../_shared/date-range-search";
import NumberRangeSearch from "../_shared/number-range-search";
import BatteriesReportGrid from "./battery-report-grid";

export default connect(
  "selectBatteriesReportParameters",
  "selectBatteriesReportItems",
  "selectBatteriesReportIsStatsLoading",
  "doBatteriesReportUpdateQueryString",
  "doBatteriesReportDownloadAsCsv",
  ({
    batteriesReportParameters,
    batteriesReportItems,
    batteriesReportIsStatsLoading,
    doBatteriesReportUpdateQueryString,
    doBatteriesReportDownloadAsCsv,
  }) => {
    /** this effect updates the query string on mount, previously this was in each checkbox component, and caused multiple instantanous fetch stats calls */
    useEffect(() => {
      let executeEffect = !!batteriesReportParameters;
      if (executeEffect) {
        let query = {};
        batteriesReportParameters.forEach((parameter) => {
          let { options, column } = parameter;
          if (options) {
            query[column] = [];
            const checkedKeys = options.reduce((prev, cur) => {
              if (!prev.hasOwnProperty(cur.id))
                prev[cur.id] = !!cur.checkedByDefault;
              return prev;
            }, {});
            Object.keys(checkedKeys).forEach((key) => {
              if (checkedKeys[key]) query[column].push(key);
            });
          }
        });
        doBatteriesReportUpdateQueryString(query);
      }
      return () => (executeEffect = false);
    }, [batteriesReportParameters]);

    const renderControls = () => {
      if (!batteriesReportParameters || batteriesReportIsStatsLoading)
        return null;
      const controls = batteriesReportParameters.map((parameter, i) => {
        if (parameter.type === "checkbox-search") {
          return (
            <ValuePicker
              key={i}
              column={parameter.column}
              onChange={doBatteriesReportUpdateQueryString}
              title={parameter.title}
              options={parameter.options}
            />
          );
        } else if (parameter.type === "date-range-search") {
          return (
            <DateRangeSearch
              key={i}
              column={parameter.column}
              onChange={doBatteriesReportUpdateQueryString}
              title={parameter.title}
            />
          );
        } else if (parameter.type === "number-range-search") {
          return (
            <NumberRangeSearch
              key={i}
              column={parameter.column}
              onChange={doBatteriesReportUpdateQueryString}
              title={parameter.title}
              min={Number(parameter.min)}
              max={Number(parameter.max)}
            />
          );
        } else if (parameter.type === "text-search") {
          return (
            <TextSearch
              key={i}
              column={parameter.column}
              onChange={doBatteriesReportUpdateQueryString}
              title={parameter.title}
            />
          );
        } else {
          return null;
        }
      });
      return controls;
    };

    return (
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-3">{renderControls()}</div>
          <div className="col-9">
            <div className="card">
              <div className="card-header">
                <div className="float-right">
                  <button
                    onClick={doBatteriesReportDownloadAsCsv}
                    className="btn btn-sm btn-ghost-primary"
                    disabled={!batteriesReportItems.length}>
                    <i className="mdi mdi-download icon-inline"></i>CSV
                  </button>
                </div>
                Query Batteries Records
              </div>
              <div className="card-body p-0">
                <BatteriesReportGrid />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
