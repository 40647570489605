import React from "react";
import { connect } from "redux-bundler-react";
import "../../_shared/item-board/item-board.css"
import FileUploadCard from "../../_shared/file-upload-card/file-upload-card";
const JobNewAttachmentsSection = ({ jobsDocsIsLoading, jobsByRoute, jobsDocsItems, doFileIoUpload, doFileIoDelete }) => {
    return (
        <li className="list-group-item">

            <div className="card-body">
                <span className="card-title d-flex flex-row">
                    <h5 className="mr-2">Attachments(s)</h5>
                    <h6 className="my-auto font-italic font-weight-lighter ml-2">Optional</h6>
                </span>
                <div className="job-board-attachments-body">
                    <FileUploadCard
                        isLoading={jobsDocsIsLoading}
                        allowRoles={["*.*"]}
                        items={jobsDocsItems}
                        searchable={true}
                        header={true}
                        postUrl="/job-board/:jobsSlug/docs"
                        removeUrl="/job-board/:jobsSlug/docs"
                        onUpload={doFileIoUpload}
                        onRemove={doFileIoDelete}
                        rel="jobs_docs"
                        data={{ job_id: jobsByRoute ? jobsByRoute.id : null }}
                    />
                </div>
            </div>
        </li>
    )
}

export default connect(
    "selectJobsByRoute",
    "selectJobsDocsItems",
    "selectJobsDocsIsLoading",
    "doFileIoUpload",
    "doFileIoDelete",
    "selectFileIoIsUploading",
    "selectFileIoIsDeleting",
    JobNewAttachmentsSection)