import React from "react";
import { connect } from "redux-bundler-react";
import MissionHelp from "../../../../app-containers/help/mission-help";

export default connect("doDialogOpen", ({ doDialogOpen }) => {
  const help = () => {
    doDialogOpen({
      content: MissionHelp,
      props: {},
    });
  };
  return (
    <span className="pl-3">
      <button className="btn btn-sm btn-info pr-2" onClick={help}>
        Mission Planning Help
      </button>
    </span>
  );
});
