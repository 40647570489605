import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "personnel",
  uid: "slug",
  prefetch: true,
  staleAfter: 30000,
  persist: false,
  routeParam: "personnelSlug",
  getTemplate: "/orgs/:orgSlug/personnel",
  putTemplate: "/orgs/:orgSlug/personnel/:item.slug",
  postTemplate: "/orgs/:orgSlug/personnel",
  deleteTemplate: "/orgs/:orgSlug/personnel/:item.slug",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN"],
  forceFetchActions: ["PERSONNEL_UPGRADE_FINISHED", "PERSONNEL_SAVE_FINISHED"],
  addons: {
    doPersonnelUpgrade:
      (member) =>
      ({ dispatch, store, apiPost }) => {
        dispatch({
          type: "PERSONNEL_SAVE_STARTED",
          payload: {
            _isSaving: true,
          },
        });

        const org = store.selectOrgsByRoute();

        apiPost(
          `/orgs/${org.slug}/personnel/upgrade`,
          member,
          (err, response, body) => {
            if (err || response.statusCode !== 200) {
              dispatch({
                type: "PERSONNEL_SAVE_ERROR",
                payload: {
                  _err: { err: err, response: response },
                  notification: {
                    statusCode: response.statusCode,
                  },
                  _isSaving: false,
                },
              });
            } else {
              // Make sure we're sending save_finished when we're done
              dispatch({
                type: "PERSONNEL_SAVE_FINISHED",
                payload: {
                  _isSaving: false,
                },
              });

              dispatch({ type: "PERSONNEL_UPGRADE_FINISHED" });
            }
          }
        );
      },
    selectPersonnelNotHidden: (state) => {
      const items = [];
      Object.keys(state.personnel).forEach((key) => {
        if (key[0] !== "_") {
          if (!state.personnel[key].hidden) items.push(state.personnel[key]);
        }
      });
      return items;
    },
    selectPersonnelNoAtpm: (state) => {
      const items = [];
      Object.keys(state.personnel).forEach((key) => {
        if (key[0] !== "_") {
          if (!state.personnel[key].atpm_id) items.push(state.personnel[key]);
        }
      });
      return items;
    },
  },
});
