import React from "react";
import xhr from "xhr";
import { connect } from "redux-bundler-react";
import Loader from "../../../app-components/loader";

class ApiDataLayer extends React.Component {
  state = {
    isLoading: true
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    const { apiRoot: root, tokenRaw: token, path } = this.props;
    if (!token) return null;
    const options = {
      url: path.indexOf("http") !== -1 ? `${path}` : `${root}${path}`,
      withCredentials: path.indexOf("goauth") !== -1
    };
    if (token) {
      options.headers = {
        Authorization: "Bearer " + token
      };
    }
    xhr.get(options, (err, response, body) => {
      if (!err && response.statusCode === 200) {
        const data = JSON.parse(body);
        this.setState({ data: data, isLoading: false });
      }
    });
  };

  render() {
    const { isLoading, data } = this.state;
    const { component: Comp } = this.props;
    if (isLoading) return <Loader />;
    return <Comp items={data} isLoading={isLoading} />;
  }
}

export default connect("selectTokenRaw", "selectApiRoot", ApiDataLayer);
