import React from "react";
import DomainsGroupListItem from "./domains-group-list-item";

class DomainsGroupList extends React.Component {
  render() {
    const { groups, onSelect, activeGroup } = this.props;
    return (
      <ul className="list-group">
        {groups.sort().map(group => {
          return (
            <DomainsGroupListItem
              key={group}
              group={group}
              onSelect={onSelect}
              active={group === activeGroup}
            />
          );
        })}
      </ul>
    );
  }
}

export default DomainsGroupList;
