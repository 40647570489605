import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "profileDocs",
  uid: "s3_key",
  prefetch: true,
  routeParam: null,
  getTemplate: "/profile/:id/docs",
  fetchActions: [
    "URL_UPDATED",
    "AUTH_LOGGED_IN",
    "FILE_IO_UPLOAD_FINISHED",
    "FILE_IO_DELETE_FINISHED",
  ],
  urlParamSelectors: ["selectProfileActive"],
  reduceFurther: (state, { type, payload }) => {
    switch (type) {
      case "PROFILEDOCSTEMP_FETCH_STARTED":
      case "PROFILEDOCSTEMP_FETCH_ABORTED":
      case "PROFILEDOCSTEMP_FETCH_ERROR":
      case "PROFILEDOCSTEMP_FETCH_FINISHED":
        return Object.assign({}, state, payload);
      case "PROFILEACTIVE_FETCH_FINISHED":
        return { ...state, _shouldTempFetchDocs: true };
      case "DIALOG_CLOSED":
      case "URL_UPDATED":
        return { ...state, _tempProfileDocs: null };
      default:
        return state;
    }
  },
  addons: {
    doProfileDocsTempFetch:
      (id, callback) =>
      ({ dispatch, store, apiGet }) => {
        dispatch({
          type: "PROFILEDOCSTEMP_FETCH_STARTED",
          payload: { _shouldTempFetchDocs: false },
        });
        apiGet(`/profile/${id}/docs`, (err, response, body) => {
          if (err || response.statusCode !== 200) {
            dispatch({
              type: "PROFILEDOCSTEMP_FETCH_STARTED",
              payload: {
                _err: { err: err, response: response },
                notification: {
                  statusCode: response.statusCode,
                },
              },
            });
          } else {
            const data = JSON.parse(body);
            dispatch({
              type: "PROFILEDOCSTEMP_FETCH_STARTED",
              payload: { _tempProfileDocs: data },
            });
            callback && callback();
          }
        });
      },
    selectProfileDocsTemp: (state) => state.profileDocs._tempProfileDocs,
    reactShouldFetchProfileDocsTemp: (state) => {
      if (state.profileDocs._shouldTempFetchDocs) {
        return {
          actionCreator: "doProfileDocsTempFetch",
          args: [state.profileActive.data.id],
        };
      }
    },
  },
});
