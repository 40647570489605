import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { connect } from "redux-bundler-react";
import FileUploadCard from "../../_shared/file-upload-card/file-upload-card";

const AwrUploadModal = ({
  doDialogClose,
  doFileIoDelete,
  doFileIoUpload,
  platformsIsLoading,
  platformsAll,
  data,
  doSave,
}) => {
  let today = new Date();
  let year = today.getFullYear();
  let mon = today.getMonth();
  let date = today.getDate();
  // if current platform doesnt already have a date just set it 2 years out.
  // if it does have a date we have to reformat the timestamp to read into the datepicker
  let awrDate = data.awr_expire_date
    ? new Date(data.awr_expire_date.split("T")[0].replaceAll("-", "/"))
    : new Date(year + 2, mon, date);

  const [expDate, setExpDate] = useState(awrDate);
  const [currentFile, setCurrentFile] = useState([]);
  const [awrNum, setAwrNum] = useState(data.awr_number);

  const save = () => {
    doSave(
      { ...data, awr_expire_date: expDate, awr_number: awrNum },
      doDialogClose
    );
  };

  useEffect(() => {
    let currentPlatform = platformsAll.filter((item) => item.id === data.id);
    if (
      currentPlatform &&
      currentPlatform.length &&
      currentPlatform[0].s3_key
    ) {
      setCurrentFile(currentPlatform);
    } else setCurrentFile([]);
  }, [platformsAll]);
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Upload AWR</h5>
        <button
          onClick={doDialogClose}
          className="close"
          type="button"
          aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div className="modal-body">
        <FileUploadCard
          allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
          isLoading={platformsIsLoading}
          items={currentFile}
          header={true}
          searchable={true}
          onUpload={doFileIoUpload}
          onRemove={doFileIoDelete}
          rel="platforms_awr_docs"
          data={{ platform_id: data.id }}
          disabledEditing={currentFile.length > 0}
        />
        <div className="d-flex flex-column">
          <label>Expiration Date</label>
          <ReactDatePicker
            className="form-control"
            onChange={(e) => setExpDate(e.toJSON())}
            selected={new Date(expDate)}
            placeholderText="Enter Expiration Date..."
          />
        </div>
        <div className="d-flex flex-column mt-2">
          <label>AWR Number</label>
          <input
            className="form-control"
            value={awrNum}
            onChange={(e) => setAwrNum(e.target.value)}
            placeholder="Enter AWR Number..."
          />
        </div>
      </div>

      <div className="modal-footer">
        <button
          onClick={doDialogClose}
          className="btn btn-sm btn-secondary"
          type="button">
          Close
        </button>
        <button onClick={save} className="btn btn-sm btn-success" type="button">
          Save
        </button>
      </div>
    </div>
  );
};

export default connect(
  "doDialogClose",
  "doFileIoDelete",
  "doFileIoUpload",
  "selectPlatformsByRoute",
  "selectPlatformsIsLoading",
  "selectFileIoIsUploading",
  AwrUploadModal
);
